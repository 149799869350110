<template>
  <v-dialog v-model="show" transition="dialog-top-transition" max-width="600">
    <v-card class="pa-8">
      <span class="d-flex justify-end">
        <v-icon class="pa-3" @click="close"> mdi-close-circle-outline </v-icon>
      </span>
      <v-card-text>
        <div class="text-h5 pb-2 pt-2 text-center font-weight-bold">
          Novo Status:
        </div>
      </v-card-text>
      
      

      <v-card-actions class="d-block justify-center pb-4">
        <v-alert
        v-if="consultaSelecionada.status == 'LAUDADO'"
          type="warning"
          
          class="d-block justify-center maiuscula pb-4"
          variant="tonal"
        >
        Essa consulta já foi laudada, se alterar o status for para "DESISTENTE" o <strong> laudo será perdido!</strong>
      </v-alert>
        <div class="pb-4">
          <v-select
            :items="status"
            filled
            outlined
            label="Status"
            v-model="consultaSelecionada.status"
          >
          </v-select>
          <v-select :items="avisos" filled outlined label="Enviar mensagem">
          </v-select>
          <v-progress-linear
            v-if="loading"
            color="blue"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </div>

        <div class="d-flex justify-center">
          <v-btn
            text
            depressed
            class="grey lighten-2 grey--text texte-darken-6 d-flex justify-center pa-4"
            @click="alterarStatusEHorario"
          >
            Ok
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConsultasMixin from '@/mixins/ConsultasMixin';
import moment from 'moment';
export default {
  mixins: [ConsultasMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    status: ['Desistente', 'Ausente', 'Em espera', 'Atendido'],
    avisos: [
      'Olá! Faltam 30 minutos para o seu exame.',
      'Olá! Você já pode se encaminhar para a clínica.',
      'Olá! Você está atrasado para o seu exame.',
      'Confirmar comparecimento.',
    ],
    loading: false,
  }),

  methods: {
    close() {
      this.$emit('close');
      this.loading = false;
    },

    async alterarStatusEHorario() {
      try {
        this.loading = true;
        await this.alterarStatus();

        const statusSelecionado = this.consultaSelecionada.status.toLowerCase();

        if (
          statusSelecionado === 'desistente' ||
          statusSelecionado === 'DESISTENTE'
        ) {
          let id = this.consultaSelecionada.consultaId || this.consultaSelecionada.id
          
          await this.deleteConsulta(id);
        }

        await this.enviarHorarioAoMudarStatus(statusSelecionado);
        this.loading = false;
        this.close();

        this.getMetricas({
          data: this.dataSelecionada,
          medicoId: this.usuarioLogado.loginId,
        });

        // Fazer algo se deu sucesso
        this.mostraModalNovoStatus = false;
      } catch (error) {
        // Exibir popup de erro
      } finally {
        await this.getConsultaByDataMedico({
          medicoId: this.medicoSelecionado.id,
          data: this.dataSelecionada,
        });
        this.adicionaEspacos();
      }
      this.close();
    },
    async enviarHorarioAoMudarStatus(status) {
      const agora = moment().format('HH:mm:ss');
      this.consultaSelecionada.id = this.consultaSelecionada.consultaId;
      const consultaID = this.consultaSelecionada.consultaId;
      if (status === 'em espera') {
        await this.patchConsulta([
          {
            op: 'replace',
            path: 'horaChegada',
            value: agora,
          },
        ]);
        return;
      }

      if (status === 'atendido') {
        await this.patchConsulta([
          {
            op: 'replace',
            path: 'horaSaida',
            value: agora,
          },
        ]);
      }
    },
  },
};
</script>

<style></style>
