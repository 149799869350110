<template>
  <v-container fluid>
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">
        ULTRASSONOGRAFIA TRANSVAGINAL (HISTERECTOMIA)
      </h2>
    </v-card>
    <v-alert
      v-if="!!laudoSelecionado.laudoJson.folhas"
      outlined
      type="warning"
      border="left"
      text=""
    >
      Esse <strong>laudo</strong> teve a impressão
      <strong>personalizada.</strong>
      <v-btn text @click="adcionarConteudoEditorPersonalizado"
        >Clique aqui para visualizar</v-btn
      >
    </v-alert>
    <v-row class="pt-5" no-gutters>
      <template v-if="clicouNoPersonalizado">
        <v-col
          cols="12"
          class="form-group"
          v-for="(index, folha) in quantidadeFolhasEditorPersonalizado"
          :key="index"
        >
          <h2>Folha {{ folha + 1 }}</h2>
          <v-btn plain small @click="removeFolha(index)" class="pl-0">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <vue-editor v-model="conteudoEditorPersonalizado[folha]" />
        </v-col>

        <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
      </template>
      <template v-else>
        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Relatório:</h4>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span>Ausência da imagem do útero.</span>
        </v-col>
        <br />

        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Ovários</h4>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exOvdirDois ? '' : 'text--disabled'
          }`"
        >
          <span>Ovário direito</span>
          <v-select
            :items="[
              'não visualizado',
              'localizado em região parauterina',
              'localizado em região retrouterina',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exOvdirDois"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioDireito"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioDireito === 'outro'"
            class="mx-2 flex-grow-0"
            dense
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            validate-on-blur
            :disabled="!camposExibidos.exOvdirDois"
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ovarioDireitoOutros"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exOvdirDois"
            @click="
              camposExibidos.exOvdirDois = true;
              laudoSelecionado.laudoJson.ovarioDireito = undefined;
              laudoSelecionado.laudoJson.ovarioDireitoOutros = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exOvdirDois"
            @click="
              camposExibidos.exOvdirDois = false;
              laudoSelecionado.laudoJson.ovarioDireito = undefined;
              laudoSelecionado.laudoJson.ovarioDireitoOutros = undefined;
            "
          >
            <v-icon>mdi-eye</v-icon></v-btn
          >

          <span>,</span>

          <v-select
            :items="['a forma é típica e os limites bem definidos', 'outro']"
            class="mx-2 flex-grow-0"
            dense
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioDireito2"
            :disabled="!camposExibidos.exOvdir"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioDireito2 == 'outro'"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            validate-on-blur
            :disabled="!camposExibidos.exOvdir"
            filled
            outlined
            class="mx-2 flex-grow-0"
            dense
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ovarioDireito2Outros"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exOvdir"
            @click="
              camposExibidos.exOvdir = true;
              laudoSelecionado.laudoJson.ovarioDireito2 = undefined;
              laudoSelecionado.laudoJson.ovarioDireito2Outros = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exOvdir"
            @click="
              camposExibidos.exOvdir = false;
              laudoSelecionado.laudoJson.ovarioDireito2 = undefined;
              laudoSelecionado.laudoJson.ovarioDireito2Outros = undefined;
            "
          >
            <v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exImgAne ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'O parênquima exibe ecogenicidade habitual.',
              'O parênquima  tem ecotextura difusamente heterogênea.',
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo',
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo',
              'Apresenta imagem anecóica com debris, medindo',
              'Apresenta imagem anecóica multiseptada medindo',
              'Apresenta imagem heterogênea medindo',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            :disabled="!camposExibidos.exImgAne"
            v-model="laudoSelecionado.laudoJson.imagemAnecoica"
          >
          </v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exImgAne"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemAnecoicaCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
          >
            cm no seu maior diâmetro.
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exImgAne"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.finoSeptoCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
          >
            cm no seu maior diâmetro.
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem anecóica com debris, medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exImgAne"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaDebrisCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem anecóica com debris, medindo'
            "
          >
            cm no seu maior diâmetro.
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exImgAne"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaMultiseptadaCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
          >
            cm no seu maior diâmetro.
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem heterogênea medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exImgAne"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaheterogenea1Cm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem heterogênea medindo'
            "
          >
            x
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem heterogênea medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exImgAne"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem heterogênea medindo'
            "
          >
            cm
          </span>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.imagemAnecoica == 'outro'"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exImgAne"
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaOutro"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exImgAne"
            @click="
              camposExibidos.exImgAne = true;
              laudoSelecionado.laudoJson.imagemAnecoica = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exImgAne"
            @click="
              camposExibidos.exImgAne = false;
              laudoSelecionado.laudoJson.imagemAnecoica = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <!-- <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exOvaEsqLocalizado ? '' : 'text--disabled'
          }`"
        >
          <span>Ovário esquerdo</span>
          <v-select
            class="mx-2 flex-grow-0"
            dense
            :items="['não visualizado', 'localizado em região']"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exOvaEsqLocalizado"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdo1"
          >
          </v-select>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exOvaEsqLocalizado"
            @click="
              camposExibidos.exOvaEsqLocalizado = true;
              laudoSelecionado.laudoJson.ovarioEsquerdo1 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exOvaEsqLocalizado"
            @click="
              camposExibidos.exOvaEsqLocalizado = false;
              laudoSelecionado.laudoJson.ovarioEsquerdo1 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col> -->

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exOvaEsq ? '' : 'text--disabled'
          }`"
        >
          <span>Ovário esquerdo</span>
          <v-select
            :items="[
              'não visualizado',
              'localizado em região parauterina',
              'localizado em região retrouterina',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exOvaEsq"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdo2"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioEsquerdo2 == 'outro'"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdo2Outro"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exOvaEsq"
            @click="
              camposExibidos.exOvaEsq = true;
              laudoSelecionado.laudoJson.ovarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdo2Outro = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exOvaEsq"
            @click="
              camposExibidos.exOvaEsq = false;
              laudoSelecionado.laudoJson.ovarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdo2Outro = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span>,</span>
          <v-select
            :items="['a forma é típica e os limites bem definidos', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exOvEsq"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdo3"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioEsquerdo3 == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exOvEsq"
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdo3Outro"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exOvEsq"
            @click="
              camposExibidos.exOvEsq = true;
              laudoSelecionado.laudoJson.ovarioEsquerdo3Outro = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdo3 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exOvEsq"
            @click="
              camposExibidos.exOvEsq = false;
              laudoSelecionado.laudoJson.ovarioEsquerdo3Outro = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdo3 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exParenq ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'O parênquima exibe ecogenicidade habitual.',
              'O parênquima  tem ecotextura difusamente heterogênea.',
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo',
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo',
              'Apresenta imagem anecóica com debris, medindo',
              'Apresenta imagem anecóica multiseptada medindo',
              'Apresenta imagem heterogênea medindo',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exParenq"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemAnecoica2"
          >
          </v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exParenq"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemAnecoicaCm2"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
          >
            cm no seu maior diâmetro.
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exParenq"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.finoSeptoCm2"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
          >
            cm no seu maior diâmetro.
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem anecóica com debris, medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exParenq"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaDebrisCm2"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem anecóica com debris, medindo'
            "
          >
            cm no seu maior diâmetro.
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exParenq"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaMultiseptadaCm2"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
          >
            cm no seu maior diâmetro.
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem heterogênea medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exParenq"
            v-mask="''"
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-model="laudoSelecionado.laudoJson.anecoicaMultiseptadaCm3"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem heterogênea medindo'
            "
          >
            x
          </span>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem heterogênea medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exParenq"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaCm2"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem heterogênea medindo'
            "
          >
            cm
          </span>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.imagemAnecoica2 == 'outro'"
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exParenq"
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaOutro2"
          ></v-textarea>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exParenq"
            @click="
              camposExibidos.exParenq = true;
              laudoSelecionado.laudoJson.imagemAnecoica2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exParenq"
            @click="
              camposExibidos.exParenq = false;
              laudoSelecionado.laudoJson.imagemAnecoica2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exAusMas ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="['Ausência de massas pélvicas.', 'Outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exAusMas"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.localizacao"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.localizacao == 'Outro'"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exAusMas"
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.localizacaoOutro"
          ></v-textarea>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exAusMas"
            @click="
              camposExibidos.exAusMas = true;
              laudoSelecionado.laudoJson.localizacao = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exAusMas"
            @click="
              camposExibidos.exAusMas = false;
              laudoSelecionado.laudoJson.localizacao = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exSacDoug ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Presença de líquido livre em fundo de saco de Douglas',
              'Ausência de líquido livre em fundo de saco de Douglas',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exSacDoug"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.sacoDouglas"
          >
          </v-select>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exSacDoug"
            @click="
              camposExibidos.exSacDoug = true;
              laudoSelecionado.laudoJson.sacoDouglas = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exSacDoug"
            @click="
              camposExibidos.exSacDoug = false;
              laudoSelecionado.laudoJson.sacoDouglas = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Impressão:</h4>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span>Histerectomia total prévia.</span>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exHistTotal ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="['Ausência de outras alterações ecográficas.', 'Outro']"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exHistTotal"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.histerectomiaTotalPrevia"
          >
          </v-select>

          <v-textarea
            v-if="
              laudoSelecionado.laudoJson.histerectomiaTotalPrevia === 'Outro'
            "
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exHistTotal"
            filled
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.histerectomiaTotalPreviaOutro"
          ></v-textarea>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exHistTotal"
            @click="
              camposExibidos.exHistTotal = true;
              laudoSelecionado.laudoJson.histerectomiaTotalPrevia = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exHistTotal"
            @click="
              camposExibidos.exHistTotal = false;
              laudoSelecionado.laudoJson.histerectomiaTotalPrevia = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Conclusão</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exMedOvDir ? '' : 'text--disabled'
          }`"
        >
          <span>Medidas do ovário direito:</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exMedOvDir"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito1"
          >
          </v-text-field>
          <span>x</span>

          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exMedOvDir"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito2"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exMedOvDir"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito3"
          >
          </v-text-field>
          <span>cm e volume de cm³.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exMedOvDir"
            @click="
              camposExibidos.exMedOvDir = true;
              laudoSelecionado.laudoJson.medidasOvarioDireito1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito3 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exMedOvDir"
            @click="
              camposExibidos.exMedOvDir = false;
              laudoSelecionado.laudoJson.medidasOvarioDireito1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito3 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exMedOvEsq ? '' : 'text--disabled'
          }`"
        >
          <span>Medidas do ovário esquerdo:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            :disabled="!camposExibidos.exMedOvEsq"
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo1"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            required
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exMedOvEsq"
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo2"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exMedOvEsq"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo3"
          >
          </v-text-field>
          <span>cm e volume de cm³.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exMedOvEsq"
            @click="
              camposExibidos.exMedOvEsq = true;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo3 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exMedOvEsq"
            @click="
              camposExibidos.exMedOvEsq = false;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo3 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            required
            validate-on-blur
            filled
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionais"
          >
          </v-textarea>
        </v-col>
      </template>

      <v-col cols="12" class="d-flex justify-start align-center pt-6">
        <v-btn
          v-if="podeEditar"
          color="primary"
          :loading="carregamentoDoLaudo"
          @click="
            () => {
              laudoId ? editarLaudo() : salvarLaudo();
            }
          "
        >
          salvar laudo
        </v-btn>

        <v-btn
          color="primary"
          v-if="podeImprimir && !podeEditar"
          @click="imprimirLaudo"
          class="ml-5"
          :loading="loadingImprimir"
        >
          Imprimir
        </v-btn>

        <v-btn
          color="primary"
          v-if="!podeEditar"
          class="ml-5"
          @click="podeEditar = true"
        >
          Editar
        </v-btn>

        <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
          Cancelar
        </v-btn>

        <v-btn
          color="primary"
          v-if="podePersonalizar"
          @click="adcionarConteudoEditorPersonalizado"
          class="ml-5"
        >
          {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
        </v-btn>
      </v-col>
    </v-row>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    VueEditor,
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      quantidadeFolhasEditorPersonalizado: 0,
      carregamentoDoLaudo: false,
      laudoId: null,
      podeEditar: true,
      voltando: false,
      componentKey: 0,
      nomeBotao: '',
      loadingImprimir: false,
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      camposExibidos: {
        exOvdir: true,
        exOvdirDois: true,
        exImgAne: true,
        exOvEsq: true,
        exParenq: true,
        exAusMas: true,
        exSacDoug: true,
        exHistTotal: true,
        exMedOvDir: true,
        exMedOvEsq: true,
        exOvaEsqLocalizado: true,
        exOvaEsq: true,
      },

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,

      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 2,5 cm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    podeImprimir() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },

    temIdDoLaudo() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },

  methods: {
    removeFolha(value) {
      let indice = value - 1;

      if (this.laudoSelecionado.laudoJson.folhas.length != 1) {
        this.conteudoEditorPersonalizado.splice(indice, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.$forceUpdate();
    },
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudoSelecionado.laudoJson.folhas) {
        this.conteudoEditorPersonalizado =
          this.laudoSelecionado.laudoJson.folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }

      let todos = [];

      this.conteudoEditorPersonalizado = [];

      if (this.camposExibidos.exOvdirDois) {
        todos.push(`<h4>Relatório</h4>`);
      }
      todos.push('\n');

      if (this.camposExibidos.exOvdirDois) {
        todos.push(`Ausência da imagem do útero.\n`);
      }
      if (this.camposExibidos.exOvdirDois) {
        todos.push(`\n<h4>Ovários</h4>`);
      }
      todos.push('\n');
      if (
        this.camposExibidos.exOvdirDois &&
        this.laudoSelecionado.laudoJson.ovarioDireito
      ) {
        todos.push(
          `Ovário direito: ${this.laudoSelecionado.laudoJson.ovarioDireito}`
        );
        if (
          this.laudoSelecionado.laudoJson.ovarioDireito === 'outro' &&
          this.laudoSelecionado.laudoJson.ovarioDireitoOutros
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.ovarioDireitoOutros}, `
          );
        }
        if (this.laudoSelecionado.laudoJson.ovarioDireito2) {
          todos.push(` ${this.laudoSelecionado.laudoJson.ovarioDireito2}`);
        }
        if (
          this.laudoSelecionado.laudoJson.ovarioDireito2 === 'outro' &&
          this.laudoSelecionado.laudoJson.ovarioDireito2Outros
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.ovarioDireito2Outros}`
          );
        }
        todos.push('\n');
      }

      if (
        this.camposExibidos.exImgAne &&
        this.laudoSelecionado.laudoJson.imagemAnecoica
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.imagemAnecoica}`);
        if (
          this.laudoSelecionado.laudoJson.imagemAnecoica ===
            'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo' &&
          this.laudoSelecionado.laudoJson.imagemAnecoicaCm
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.imagemAnecoicaCm} cm no seu maior diâmetro.`
          );
        }
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica ===
          'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo' &&
        this.laudoSelecionado.laudoJson.finoSeptoCm
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.finoSeptoCm} cm no seu maior diâmetro.`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica ===
          'Apresenta imagem anecóica com debris, medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaDebrisCm
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaDebrisCm} cm no seu maior diâmetro.`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica ===
          'Apresenta imagem anecóica multiseptada medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm} cm no seu maior diâmetro.`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica ===
          'Apresenta imagem heterogênea medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaheterogenea1Cm
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaheterogenea1Cm} x ${this.laudoSelecionado.laudoJson.imagemHeterogeneaCm} cm.`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica === 'outro' &&
        this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro}.`
        );
      }
      todos.push('\n');

      // if (
      //   this.camposExibidos.exOvaEsqLocalizado &&
      //   this.laudoSelecionado.laudoJson.ovarioEsquerdo1
      // ) {
      //   todos.push(
      //     `Ovário esquerdo: ${this.laudoSelecionado.laudoJson.ovarioEsquerdo1}.\n`
      //   );
      // }

      if (
        this.camposExibidos.exOvaEsq &&
        this.laudoSelecionado.laudoJson.ovarioEsquerdo2
      ) {
        todos.push(
          `Ovário esquerdo: ${this.laudoSelecionado.laudoJson.ovarioEsquerdo2}, `
        );
        if (
          this.laudoSelecionado.laudoJson.ovarioEsquerdo2 === 'outro' &&
          this.laudoSelecionado.laudoJson.ovarioEsquerdo2Outro
        ) {
          todos.push(
            `${this.laudoSelecionado.laudoJson.ovarioEsquerdo2Outro} `
          );
        }
        if (this.laudoSelecionado.laudoJson.ovarioEsquerdo3) {
          todos.push(`${this.laudoSelecionado.laudoJson.ovarioEsquerdo3}`);
        }
        if (
          this.laudoSelecionado.laudoJson.ovarioEsquerdo3 === 'outro' &&
          this.laudoSelecionado.laudoJson.ovarioEsquerdo3Outro
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.ovarioEsquerdo3Outro}`
          );
        }
      }
      todos.push('\n');

      if (
        this.camposExibidos.exParenq &&
        this.laudoSelecionado.laudoJson.imagemAnecoica2
      ) {
        todos.push(
          `${
            this.laudoSelecionado.laudoJson.imagemAnecoica2 != 'outro'
              ? this.laudoSelecionado.laudoJson.imagemAnecoica2
              : this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro2
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.imagemAnecoica2 ===
            'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo' &&
          this.laudoSelecionado.laudoJson.imagemAnecoicaCm2
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.imagemAnecoicaCm2} cm no seu maior diâmetro.`
          );
        }
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica2 ===
          'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo' &&
        this.laudoSelecionado.laudoJson.finoSeptoCm2
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.finoSeptoCm2} cm no seu maior diâmetro.`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica2 ===
          'Apresenta imagem anecóica com debris, medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaDebrisCm2
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaDebrisCm2} cm no seu maior diâmetro.`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica2 ===
          'Apresenta imagem anecóica multiseptada medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm2
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm2} cm no seu maior diâmetro.`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica2 ===
          'Apresenta imagem heterogênea medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm3
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm3} x ${this.laudoSelecionado.laudoJson.imagemHeterogeneaCm2} cm.`
        );
      }
      // if (
      //   this.laudoSelecionado.laudoJson.imagemAnecoica2 === 'outro' &&
      //   this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro2
      // ) {
      //   todos.push(
      //     ` ${this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro2}.`
      //   );
      // }
      todos.push('\n');

      if (this.laudoSelecionado.laudoJson.localizacao) {
        todos.push(
          `${
            this.laudoSelecionado.laudoJson.localizacao != 'Outro'
              ? this.laudoSelecionado.laudoJson.localizacao
              : this.laudoSelecionado.laudoJson.localizacaoOutro
          }\n`
        );
      }

      if (
        this.camposExibidos.exSacDoug &&
        this.laudoSelecionado.laudoJson.sacoDouglas
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.sacoDouglas}\n`);
      }

      if (this.laudoSelecionado.laudoJson.histerectomiaTotalPrevia) {
        todos.push(`\n<h4>Impressão</h4>`);
      }
      todos.push('\n');

      if (this.camposExibidos.exHistTotal) {
        todos.push(`Histerectomia total prévia.\n`);
      }

      if (
        this.camposExibidos.exHistTotal &&
        this.laudoSelecionado.laudoJson.histerectomiaTotalPrevia
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.histerectomiaTotalPrevia}\n`
        );
        if (
          this.laudoSelecionado.laudoJson.histerectomiaTotalPrevia ===
            'Outro' &&
          this.laudoSelecionado.laudoJson.histerectomiaTotalPreviaOutro
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.histerectomiaTotalPreviaOutro}\n`
          );
        }
      }

      if (this.camposExibidos.exMedOvDir) {
        todos.push(`\n<h4>Conclusão</h4>`);
      }
      todos.push('\n');

      if (
        this.camposExibidos.exMedOvDir &&
        this.laudoSelecionado.laudoJson.medidasOvarioDireito1
      ) {
        todos.push(
          `Medidas do ovário direito: ${this.laudoSelecionado.laudoJson.medidasOvarioDireito1}`
        );

        if (this.laudoSelecionado.laudoJson.medidasOvarioDireito2) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioDireito2}`
          );
        }
        if (this.laudoSelecionado.laudoJson.medidasOvarioDireito3) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioDireito3} cm e volume de cm³.\n`
          );
        }
      }

      if (
        this.camposExibidos.exMedOvEsq &&
        this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo1
      ) {
        todos.push(
          `Medidas do ovário esquerdo: ${
            this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo1
          }${
            this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo2
              ? ' x ' + this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo2
              : ''
          }${
            this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo3
              ? ' x ' + this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo3
              : ''
          } cm e volume de cm³.\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.comentariosAdcionais) {
        todos.push(`${this.laudoSelecionado.laudoJson.comentariosAdcionais}\n`);
      }

      while (todos.length > 0) {
        const teste = todos.splice(0, 30);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },
    forceReRender() {
      this.componentKey++;
    },

    ...mapGetters('Offline', ['requisicao', 'dados']),

    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          this.carregamentoDoLaudo = true;

          const exameId = this.$route.query.exame;

          this.laudoSelecionado.examePorConsultaId = exameId;
          this.consultaSelecionada.status = 'LAUDADO';

          if (navigator.onLine) {
            const { data } = await this.postLaudo();
            this.laudoId = data;
            await this.alterarStatus();

            if (!Number.isInteger(data)) throw new Error(data);

            this.getLaudoByExamePorConsultaId(exameId);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
              requisicaoSalvar: this.postLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'POST_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', {
              laudo: this.laudoSelecionado,
              consulta: this.consultaSelecionada,
            });
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        this.carregamentoDoLaudo = false;
        return;
      } finally {
        this.carregamentoDoLaudo = false;
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            await this.patchLaudo(this.laudoSelecionado);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
            // this.podeImprimir = false;
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    juntaPersonalizadoAoLaudo() {
      this.laudoSelecionado.laudoJson.folhas = this.conteudoEditorPersonalizado;
      this.laudoSelecionado.laudoJson.titulo =
        'ULTRASSONOGRAFIA TRANSVAGINAL (HISTERECTOMIA)';
    },
    desativaLoading() {
      this.loadingImprimir = false;
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      this.loadingImprimir = true;
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.transvaginalhisterectomia',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },
    formataLaudo() {
      if (this.exibeObs) {
        this.laudoSelecionado.laudoJson.exibeObs = false;
      } else {
        this.laudoSelecionado.laudoJson.exibeObs = true;
      }
    },
    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },
  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;

    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      // this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
