<template lang="">
  <div>
    <v-container fluid style="max-width: 95%">
      <v-card class="pa-5 my-5" elevation="3">
        <h2 class="text-center">Pagamento</h2>
        <form>
          <h3 class="pb-8">INFORMAÇÕES GERAIS</h3>

          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="nameField"
                label="Nome completo"
                required
                filled
                outlined
                validate-on-blur
                readonly
                validate-on-blur
                v-model="consultaSelecionada.paciente.nome"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pb-2">
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                class="code"
                ref="dataDeNascimentoField"
                label="Data de nascimento"
                required
                filled
                outlined
                v-mask="'##/##/####'"
                readonly
                validate-on-blur
                v-model="consultaSelecionada.paciente.dataNascimento"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="generoField"
                label="Gênero"
                required
                filled
                outlined
                validate-on-blur
                readonly
                validate-on-blur
                v-model="consultaSelecionada.paciente.genero"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                class="code"
                ref="cpfField"
                v-mask="'###.###.###-##'"
                label="CPF"
                required
                filled
                outlined
                readonly
                validate-on-blur
                v-model="consultaSelecionada.paciente.cpf"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                class="code"
                ref="telefonePrincipalField"
                label="Telefone 1 (Whatsapp)"
                required
                filled
                outlined
                v-mask="'(##)#####-####'"
                readonly
                validate-on-blur
                v-model="consultaSelecionada.paciente.telefonePrincipal"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" class="pt-0 pb-4 ">
              <v-text-field
                class="maiuscula"
                ref="emailField"
                label="Email"
                required
                filled
                outlined
                readonly
                validate-on-blur
                v-model="consultaSelecionada.paciente.email"
              ></v-text-field>
            </v-col>
          </v-row>

          <h3 class="pb-8">INFORMAÇÕES DO EXAME</h3>
          <template>
            <v-row class="pa-0">
              <h4 class="pl-3 pb-2 grey--text">EXAME 1</h4>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  class="maiuscula"
                  ref="generoField"
                  label="Médico Executante"
                  required
                  filled
                  outlined
                  validate-on-blur
                  readonly
                  validate-on-blur
                  v-model="consultaSelecionada.medicoExecutante.nome"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  class="code"
                  ref="generoField"
                  label="Exame"
                  required
                  filled
                  outlined
                  validate-on-blur
                  readonly
                  validate-on-blur
                  v-model="consultaSelecionada.valorExameConvenio.exame.nome"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  class="code"
                  ref="generoField"
                  label="Data"
                  required
                  filled
                  outlined
                  validate-on-blur
                  readonly
                  validate-on-blur
                  v-model="consultaSelecionada.data"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  class="code"
                  ref="generoField"
                  label="Horário"
                  required
                  filled
                  outlined
                  validate-on-blur
                  readonly
                  validate-on-blur
                  v-model="consultaSelecionada.hora"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  class="maiuscula"
                  ref="generoField"
                  label="Médico Solicitante"
                  required
                  filled
                  outlined
                  validate-on-blur
                  readonly
                  validate-on-blur
                  v-model="consultaSelecionada.medicoSolicitante.nome"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  class="maiuscula"
                  ref="generoField"
                  label="Convênio"
                  required
                  filled
                  outlined
                  validate-on-blur
                  readonly
                  validate-on-blur
                  v-model="consultaSelecionada.valorExameConvenio.convenio.nome"
                ></v-text-field>
              </v-col>
            </v-row>

            <h3 class="text-center pb-8 pt-8">FORMA DE PAGAMENTO</h3>
            <v-row class="d-flex justify-center">
              <v-col cols="12" md="6">
                <v-text-field
                  class="code"
                  ref="generoField"
                  label="Exame 1"
                  required
                  filled
                  outlined
                  validate-on-blur
                  readonly
                  validate-on-blur
                  v-model="consultaSelecionada.valorExameConvenio.exame.nome"
                ></v-text-field>

                <v-text-field
                  class="code"
                  ref="generoField"
                  label="Desconto"
                  required
                  filled
                  outlined
                  validate-on-blur
                  readonly
                  validate-on-blur
                ></v-text-field>

                <v-text-field
                  class="code"
                  ref="generoField"
                  label="Total"
                  required
                  filled
                  outlined
                  validate-on-blur
                  readonly
                  validate-on-blur
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="text-center mb-5">
              <v-col cols="12" md="12">
                <v-btn large class="grey darken-1 white--text">CONFIRMAR PAGAMENTO</v-btn>
              </v-col>
            </v-row>
          </template>
        </form>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import ConsultasMixin from '../../../mixins/ConsultasMixin';
export default {
  mixins: [ConsultasMixin],
  data() {
    return {};
  },
  mounted() {
    this.getConsultaById(this.$route.query.id).then(() => {
    });
  },
};
</script>
<style lang=""></style>
