<template lang="">
  <div>
    <ListaLaudos></ListaLaudos>
  </div>
</template>
<script>
import ListaLaudos from "../laudos/ListaLaudos.vue";
export default {
  components: { ListaLaudos },
};
</script>
<style lang=""></style>
