<template>
  <v-container fluid>
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">FICHA AMBULATORIAL DA MULHER</h2>
    </v-card>

    <v-row class="pt-5" no-gutters>
      <template v-if="clicouNoPersonalizado">
        <v-col
          cols="12"
          class="form-group"
          v-for="(index, folha) in conteudoEditorPersonalizado"
          :key="index"
        >
          <h2>Folha {{ folha + 1 }}</h2>
          <vue-editor v-model="conteudoEditorPersonalizado[folha]" />
        </v-col>

        <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
      </template>
      <template v-else>
        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Queixas"
            v-model="laudoSelecionado.laudoJson.queixas"
          ></v-textarea>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeAp ? '' : 'text--disabled'
          }`"
        >
          <span>AP:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.Ap"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeAp"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeAp"
            @click="camposExibidos.exibeAp = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeAp = false;
              laudoSelecionado.laudoJson.ap = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeAf ? '' : 'text--disabled'
          }`"
        >
          <span>AF:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.Af"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeAf"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeAf"
            @click="camposExibidos.exibeAf = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeAf = false;
              laudoSelecionado.laudoJson.af = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Medicações"
            v-model="laudoSelecionado.laudoJson.medicacoes"
          ></v-textarea>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeAlergias ? '' : 'text--disabled'
          }`"
        >
          <span>Alergias:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.Alergias"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeAlergias"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeAlergias"
            @click="camposExibidos.exibeAlergias = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeAlergias = false;
              laudoSelecionado.laudoJson.Alergias = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeCirurgia ? '' : 'text--disabled'
          }`"
        >
          <span>Cirurgia:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.cirurgia"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeCirurgia"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeCirurgia"
            @click="camposExibidos.exibeCirurgia = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeCirurgia = false;
              laudoSelecionado.laudoJson.cirurgia = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDataDum ? '' : 'text--disabled'
          }`"
        >
          <span>DUM:</span>
          <v-text-field
            v-model="laudoSelecionado.laudoJson.dataDum"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeDataDum"
            @keydown="forceReRender"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-mask="'##/##/####'"
          ></v-text-field>
          <span>.</span>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeDataDum"
            @click="
              camposExibidos.exibeDataDum = true;
              laudoSelecionado.laudoJson.dataDum = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeDataDum = false;
              laudoSelecionado.laudoJson.dataDum = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeColpocitopatologiaOntotica
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Última Colpocitopatologia Oncótica:</span>
          <v-text-field
            v-model="laudoSelecionado.laudoJson.colpocitopatologiaOntotica"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColpocitopatologiaOntotica"
            @keydown="forceReRender"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-mask="'##/##/####'"
          ></v-text-field>
          <span>.</span>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeColpocitopatologiaOntotica"
            @click="
              camposExibidos.exibeColpocitopatologiaOntotica = true;
              laudoSelecionado.laudoJson.colpocitopatologiaOntotica = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeColpocitopatologiaOntotica = false;
              laudoSelecionado.laudoJson.colpocitopatologiaOntotica = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeSexarca ? '' : 'text--disabled'
          }`"
        >
          <span>Sexarca:</span>
          <v-text-field
            v-model="laudoSelecionado.laudoJson.sexarca"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeSexarca"
            @keydown="forceReRender"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-mask="'##/##/####'"
          ></v-text-field>
          <span>.</span>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeSexarca"
            @click="
              camposExibidos.exibeSexarca = true;
              laudoSelecionado.laudoJson.sexarca = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeSexarca = false;
              laudoSelecionado.laudoJson.sexarca = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeMenarca ? '' : 'text--disabled'
          }`"
        >
          <span>Menarca:</span>
          <v-text-field
            v-model="laudoSelecionado.laudoJson.menarca"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMenarca"
            @keydown="forceReRender"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-mask="'##/##/####'"
          ></v-text-field>
          <span>.</span>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeMenarca"
            @click="
              camposExibidos.exibeMenarca = true;
              laudoSelecionado.laudoJson.menarca = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeMenarca = false;
              laudoSelecionado.laudoJson.menarca = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Procedimento em Colo"
            v-model="laudoSelecionado.laudoJson.procedimentoEmColo"
          ></v-textarea>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeG ? '' : 'text--disabled'
          }`"
        >
          <span>G:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.g"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeG"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>

          <span>P:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.p"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeG"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>

          <span>A:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.a"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeG"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeG"
            @click="camposExibidos.exibeG = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeG = false;
              laudoSelecionado.laudoJson.g = undefined;
              laudoSelecionado.laudoJson.p = undefined;
              laudoSelecionado.laudoJson.a = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Passado Obstétrico"
            v-model="laudoSelecionado.laudoJson.passadoObstetrico"
          ></v-textarea>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeCorrimento ? '' : 'text--disabled'
          }`"
        >
          <span>Corrimento:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.corrimento"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeCorrimento"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeCorrimento"
            @click="camposExibidos.exibeCorrimento = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeCorrimento = false;
              laudoSelecionado.laudoJson.corrimento = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDispareunia ? '' : 'text--disabled'
          }`"
        >
          <span>Dispareunia:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.dispareunia"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeDispareunia"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeDispareunia"
            @click="camposExibidos.exibeDispareunia = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeDispareunia = false;
              laudoSelecionado.laudoJson.dispareunia = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeSinosorragia ? '' : 'text--disabled'
          }`"
        >
          <span>Sinosorragia:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.sinosorragia"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeSinosorragia"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeSinosorragia"
            @click="camposExibidos.exibeSinosorragia = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeSinosorragia = false;
              laudoSelecionado.laudoJson.sinosorragia = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>AO EXAME ESPECULAR:</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeColo ? '' : 'text--disabled'
          }`"
        >
          <span>Colo:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.colo"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeColo"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeColo"
            @click="camposExibidos.exibeColo = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeColo = false;
              laudoSelecionado.laudoJson.colo = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Conteúdo"
            v-model="laudoSelecionado.laudoJson.conteudo"
          ></v-textarea>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibePa ? '' : 'text--disabled'
          }`"
        >
          <span>PA:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.pa"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibePa"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibePa"
            @click="camposExibidos.exibePa = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibePa = false;
              laudoSelecionado.laudoJson.pa = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibePeso ? '' : 'text--disabled'
          }`"
        >
          <span>PESO:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.peso"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibePeso"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibePeso"
            @click="camposExibidos.exibePeso = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibePeso = false;
              laudoSelecionado.laudoJson.peso = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeAfu ? '' : 'text--disabled'
          }`"
        >
          <span>AFU:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.afu"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeAfu"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeAfu"
            @click="camposExibidos.exibeAfu = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeAfu = false;
              laudoSelecionado.laudoJson.afu = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeBcf ? '' : 'text--disabled'
          }`"
        >
          <span>BCF:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.bcf"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeBcf"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeBcf"
            @click="camposExibidos.exibeBcf = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeBcf = false;
              laudoSelecionado.laudoJson.bcf = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeHd ? '' : 'text--disabled'
          }`"
        >
          <span>HD:</span>
          <v-text-field
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.hd"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeHd"
            dense
            class="mx-2 flex-grow-0"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exibeHd"
            @click="camposExibidos.exibeHd = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exibeHd = false;
              laudoSelecionado.laudoJson.hd = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Solicitado"
            v-model="laudoSelecionado.laudoJson.solicitado"
          ></v-textarea>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Conduta"
            v-model="laudoSelecionado.laudoJson.conduta"
          ></v-textarea>
        </v-col>
      </template>

      <v-col cols="12" class="d-flex justify-start align-center pt-6">
        <v-btn
          v-if="podeEditar"
          color="primary"
          :loading="carregamentoDoLaudo"
          @click="
            () => {
              laudoId ? editarLaudo() : salvarLaudo();
            }
          "
        >
          salvar laudo
        </v-btn>

        <v-btn
          color="primary"
          v-if="podeImprimir && !podeEditar"
          @click="imprimirLaudo"
          class="ml-5"
        >
          Imprimir
        </v-btn>

        <v-btn
          color="primary"
          v-if="!podeEditar"
          class="ml-5"
          @click="podeEditar = true"
        >
          Editar
        </v-btn>

        <v-btn
          color="primary"
          v-if="podePersonalizar && !podeEditar"
          @click="adcionarConteudoEditorPersonalizado"
          class="ml-5"
        >
          {{ this.clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
        </v-btn>

        <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
          Cancelar
        </v-btn>
      </v-col>
    </v-row>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    VueEditor,
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      carregamentoDoLaudo: false,
      podeEditar: true,
      laudoId: null,
      voltando: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      camposExibidos: {
        exibeColoUterino: true,
        exibeNome: true,
        exibeQueixas: true,
        exibeAp: true,
        exibeAf: true,
        exibeAlergias: true,
        exibeCirurgia: true,
        exibeDataDum: true,
        exibeColpocitopatologiaOntotica: true,
        exibeSexarca: true,
        exibeMenarca: true,
        exibeG: true,
        exibeCorrimento: true,
        exibeDispareunia: true,
        exibeSinosorragia: true,
        exibeColo: true,
        exibePa: true,
        exibePeso: true,
        exibeAfu: true,
        exibeBcf: true,
        exibeHd: true,
      },
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 2,5 cm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    podeImprimir() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    adcionarConteudoEditorPersonalizado() {
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudoSelecionado.laudoJson.folhas) {
        this.conteudoEditorPersonalizado =
          this.laudoSelecionado.laudoJson.folhas;
        this.clicouNoPersonalizado = true;

        return;
      }
      let todos = [];

      this.conteudoEditorPersonalizado = [];
      if (
        this.camposExibidos.exibeNome &&
        this.laudoSelecionado.laudoJson.nome
      ) {
        todos.push(
          `Nome: ${this.laudoSelecionado.laudoJson.nome} Idade:${this.laudoSelecionado.laudoJson.idade}.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.queixas) {
        todos.push(`Queixas: ${this.laudoSelecionado.laudoJson.queixas}\n`);
      }
      if (this.camposExibidos.exibeAp && this.laudoSelecionado.laudoJson.Ap) {
        todos.push(`AP: ${this.laudoSelecionado.laudoJson.Ap}\n`);
      }
      if (this.camposExibidos.exibeAf && this.laudoSelecionado.laudoJson.Af) {
        todos.push(`AF: ${this.laudoSelecionado.laudoJson.Af}\n`);
      }
      if (this.laudoSelecionado.laudoJson.medicacoes) {
        todos.push(
          `Medicações: ${this.laudoSelecionado.laudoJson.medicacoes}\n`
        );
      }
      if (
        this.camposExibidos.exibeAlergias &&
        this.laudoSelecionado.laudoJson.Alergias
      ) {
        todos.push(`Alergias: ${this.laudoSelecionado.laudoJson.Alergias}\n`);
      }
      if (
        this.camposExibidos.exibeCirurgia &&
        this.laudoSelecionado.laudoJson.cirurgia
      ) {
        todos.push(`Cirurgia: ${this.laudoSelecionado.laudoJson.cirurgia}\n`);
      }
      if (
        this.camposExibidos.exibeDataDum &&
        this.laudoSelecionado.laudoJson.dataDum
      ) {
        todos.push(`DUM: ${this.laudoSelecionado.laudoJson.dataDum}\n`);
      }
      if (
        this.camposExibidos.exibeColpocitopatologiaOntotica &&
        this.laudoSelecionado.laudoJson.colpocitopatologiaOntotica
      ) {
        todos.push(
          `Última Colpocitopatologia Oncótica: ${this.laudoSelecionado.laudoJson.colpocitopatologiaOntotica}\n`
        );
      }
      if (
        this.camposExibidos.exibeSexarca &&
        this.laudoSelecionado.laudoJson.sexarca
      ) {
        todos.push(`Sexarca: ${this.laudoSelecionado.laudoJson.sexarca}\n`);
      }
      if (
        this.camposExibidos.exibeMenarca &&
        this.laudoSelecionado.laudoJson.menarca
      ) {
        todos.push(`Menarca: ${this.laudoSelecionado.laudoJson.menarca}\n`);
      }
      if (this.laudoSelecionado.laudoJson.procedimentoEmColo) {
        todos.push(
          `Procedimento em Colo: ${this.laudoSelecionado.laudoJson.procedimentoEmColo}\n`
        );
      }

      if (this.camposExibidos.exibeG && this.laudoSelecionado.laudoJson.g) {
        todos.push(
          `G: ${this.laudoSelecionado.laudoJson.g} P: ${this.laudoSelecionado.laudoJson.p} A: ${this.laudoSelecionado.laudoJson.a}\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.passadoObstetrico) {
        todos.push(
          `Passado Obstétrico: ${this.laudoSelecionado.laudoJson.passadoObstetrico}\n`
        );
      }

      if (
        this.camposExibidos.exibeCorrimento &&
        this.laudoSelecionado.laudoJson.corrimento
      ) {
        todos.push(
          `Corrimento: ${this.laudoSelecionado.laudoJson.corrimento}\n`
        );
      }
      if (
        this.camposExibidos.exibeDispareunia &&
        this.laudoSelecionado.laudoJson.dispareunia
      ) {
        todos.push(
          `Dispareunia: ${this.laudoSelecionado.laudoJson.dispareunia}\n`
        );
      }
      if (
        this.camposExibidos.exibeSinosorragia &&
        this.laudoSelecionado.laudoJson.sinosorragia
      ) {
        todos.push(
          `Sinosorragia: ${this.laudoSelecionado.laudoJson.sinosorragia}\n`
        );
      }
      if (this.camposExibidos.exibeColo) {
        todos.push(`AO EXAME ESPECULAR:\n`);
      }

      if (
        this.camposExibidos.exibeColo &&
        this.laudoSelecionado.laudoJson.colo
      ) {
        todos.push(`Colo: ${this.laudoSelecionado.laudoJson.colo}\n`);
      }

      if (this.laudoSelecionado.laudoJson.conteudo) {
        todos.push(`Conteúdo: ${this.laudoSelecionado.laudoJson.conteudo}\n`);
      }

      if (this.camposExibidos.exibePa && this.laudoSelecionado.laudoJson.pa) {
        todos.push(`PA: ${this.laudoSelecionado.laudoJson.pa}\n`);
      }
      if (
        this.camposExibidos.exibePeso &&
        this.laudoSelecionado.laudoJson.peso
      ) {
        todos.push(`Peso: ${this.laudoSelecionado.laudoJson.peso}\n`);
      }
      if (this.camposExibidos.exibeAfu && this.laudoSelecionado.laudoJson.afu) {
        todos.push(`AFU: ${this.laudoSelecionado.laudoJson.afu}\n`);
      }
      if (this.camposExibidos.exibeBcf && this.laudoSelecionado.laudoJson.bcf) {
        todos.push(`BCF: ${this.laudoSelecionado.laudoJson.bcf}\n`);
      }
      if (this.camposExibidos.exibeHd && this.laudoSelecionado.laudoJson.hd) {
        todos.push(`HD: ${this.laudoSelecionado.laudoJson.hd}\n`);
      }
      if (this.laudoSelecionado.laudoJson.solicitado) {
        todos.push(
          `Solicitado: ${this.laudoSelecionado.laudoJson.solicitado}\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.conduta) {
        todos.push(`Conduta: ${this.laudoSelecionado.laudoJson.conduta}\n`);
      }

      while (todos.length > 0) {
        const teste = todos.splice(0, 12);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.clicouNoPersonalizado = true;
    },
    forceReRender() {
      this.componentKey++;
    },
    ...mapGetters('Offline', ['requisicao', 'dados']),

    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        return;
      } finally {
        try {
          this.carregamentoDoLaudo = true;

          const exameId = this.$route.query.exame;

          this.laudoSelecionado.examePorConsultaId = exameId;
          this.consultaSelecionada.status = 'LAUDADO';

          if (navigator.onLine) {
            const { data } = await this.postLaudo();
            this.laudoId = data;

            await this.alterarStatus();

            if (!Number.isInteger(data)) throw new Error(data);

            this.getLaudoByExamePorConsultaId(exameId);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
              requisicaoSalvar: this.postLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'POST_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', {
              laudo: this.laudoSelecionado,
              consulta: this.consultaSelecionada,
            });
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        return;
      } finally {
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            await this.patchLaudo(this.laudoSelecionado);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
            // this.podeImprimir = false;
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    juntaPersonalizadoAoLaudo() {
      this.laudoSelecionado.laudoJson.folhas = this.conteudoEditorPersonalizado;
      this.laudoSelecionado.laudoJson.titulo = 'CONSULTA GINECOLOGICA';
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.consultaGinecologica',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.consultaGinecologica',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
    },
    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },

  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;
    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      // this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
