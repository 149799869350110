<template lang="">
  <div>
    <LaudoObstetricaDoppler></LaudoObstetricaDoppler>
  </div>
</template>
<script>
import LaudoObstetricaDoppler from '../components/laudos/formularios/exames/obstetricaComDoppler/LaudoObstetricaDoppler.vue';
export default {
  components: {
    LaudoObstetricaDoppler,
  },
};
</script>
<style lang=""></style>
