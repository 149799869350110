<template lang="">
  <div>
    <v-row>
      <v-col>
        <template>
          <div class="d-flex ml-12 mt-8">
            <v-btn @click="voltar">
              <v-icon>mdi-arrow-left</v-icon>
              voltar
            </v-btn>
          </div>
        </template>
        <Paciente></Paciente>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Paciente from "../components/paciente/Paciente.vue";
export default {
  components: {
    Paciente,
  },

  methods: {
    voltar() {
      this.$router.push("/pacientes");
    },
  },
};
</script>
<style lang=""></style>
