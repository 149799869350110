<template>
  <section class="mt-2">
    <h3>Risco para pré-eclâmpsia:</h3>

    <v-row class="pt-2" no-gutters>
      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Artéria uterina direita:</span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>IP:</span>
        <v-text-field
          class="mx-2 flex-grow-0"
          v-mask="'##,#'"
          dense
          required
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.arteriaUterinaDireita"
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Artéria uterina esquerda:</span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>IP:</span>
        <v-text-field
          v-mask="'##,#'"
          class="mx-2 flex-grow-0"
          required
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.arteriaUterinaEsquerda"
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Média do IP:</span>
        <v-text-field
          v-mask="'##,#'"
          class="mx-2 flex-grow-0"
          dense
          required
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.medidaIP"
        ></v-text-field>
        <span>(Percentil </span>
        <v-text-field
          v-mask="'##'"
          dense
          class="mx-2 flex-grow-0"
          required
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.percentil"
        ></v-text-field>
        <span>%).</span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Risco basal para pré-eclâmpsia - história materna - 1:</span>
        <v-text-field
          class="mx-2 flex-grow-0"
          required
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.riscoBasalPreEclampsia"
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>
          Risco corrigido para pré-eclâmpsia - história materna, pressão
          arterial média e artéria uterina - 1:
        </span>
        <v-text-field
          class="mx-2 flex-grow-0"
          dense
          required
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.riscoCorrigidoPreEclampsia"
        ></v-text-field>
        <span>.</span>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
export default {
  mixins: [LaudosMixin],
  props: {
    laudoIndex: {
      type: Number,
      required: true,
    },
  },
  components: { FormInfoLaudo },
  computed: {
    laudo() {
      return this.$store.state.Laudo.laudos[this.laudoIndex];
    },
  },
  watch: {
    laudo: {
      handler(value) {
        this.$store.commit('Laudo/SET_LAUDO_BY_INDEX', {
          index: this.laudoIndex,
          model: this.laudo,
        });
      },
      deep: true,
    },
  },
};
</script>
