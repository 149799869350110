<template lang="">
  <div>
    <LaudoDoppler></LaudoDoppler>
  </div>
</template>
<script>
import LaudoDoppler from "../components/laudos/formularios/exames/doppler/LaudoDoppler.vue";
export default {
  components: {
    LaudoDoppler,
  },
};
</script>
<style lang=""></style>
