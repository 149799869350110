<template>
  <section class="mt-1">
    <v-row class="pt-1" no-gutters>
      <v-col cols="12" class="d-flex justify-start align-center pb-2">
        <span>Diâmetro biparietal</span>
        <v-text-field
          v-mask="'#,#'"
          class="mx-2 flex-grow-0"
          dense
          clearable
          required
          validate-on-blur
          v-model="laudo.diametroBiparietalPrimeiroTrimestre"
        ></v-text-field>
        <span>mm</span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Circunferência cefálica:</span>
        <v-text-field
          v-mask="'##,#'"
          class="mx-2 flex-grow-0"
          dense
          clearable
          required
          validate-on-blur
          v-model="laudo.circunferenciaCefalicaPrimeiroTrimestre"
        />
        <span>mm</span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Circunferência abdominal:</span>
        <v-text-field
          v-mask="'##,#'"
          class="mx-2 flex-grow-0"
          dense
          clearable
          required
          validate-on-blur
          v-model="laudo.circunferenciaAbdominalPrimeiroTrimestre"
        ></v-text-field>
        <span>mm</span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Fêmur:</span>
        <v-text-field
          v-mask="'#,#'"
          class="mx-2 flex-grow-0"
          dense
          clearable
          required
          validate-on-blur
          v-model="laudo.femurPrimeiroTrimestre"
        />
        <span>mm</span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Peso:</span>
        <v-text-field
          v-mask="'###'"
          class="mx-2 flex-grow-0"
          dense
          clearable
          required
          validate-on-blur
          v-model="laudo.pesoPrimeiroTrimestre"
        ></v-text-field>
        <span>mm</span>
      </v-col>

      <h3>Morfologia fetal:</h3>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Crânio</span>
        <v-select
          :items="[
            'ossos da calota craniana presentes',
            'ossos da calota craniana não visualizados',
            'avaliação limitada',
          ]"
          class="mx-2 flex-grow-0"
          dense
          clearable
          required
          validate-on-blur
          v-model="laudo.cranioPrimeiroTrimestre"
        ></v-select>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Sistema nervoso central</span>
        <v-select
          :items="[
            'sem alterações visíveis nessa idade gestacional',
            'ancéfalo desorganizado',
            'avaliação limitada',
          ]"
          class="mx-2 flex-grow-0"
          dense
          required
          clearable
          validate-on-blur
          v-model="laudo.sistemaNervosoCentralPrimeiroTrimistre"
        ></v-select>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Translucência intracraniana</span>
        <v-select
          :items="['presente medindo', 'obliterada', 'avaliação limitada']"
          class="mx-2 flex-grow-0"
          required
          clearable
          dense
          validate-on-blur
          v-model="laudo.translucenciaInstracranianaPrimeiroTrimistre"
        />
        <v-text-field
          v-if="
            laudo.translucenciaInstracranianaPrimeiroTrimistre ==
            'presente medindo'
          "
          class="mx-2 flex-grow-0"
          v-mask="'#,#'"
          required
          dense
          validate-on-blur
          v-model="laudo.translucenciaInstracranianaPrimeiroTrimistreMedindo"
        />

        <span
          v-if="
            laudo.translucenciaInstracranianaPrimeiroTrimistre ==
            'presente medindo'
          "
          >mm
        </span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Órbitas</span>
        <v-select
          :items="['presentes', 'não visualizadas', 'avaliação limitada']"
          required
          class="mx-2 flex-grow-0"
          dense
          clearable
          validate-on-blur
          v-model="laudo.orbitasPrimeiroTrimistre"
        ></v-select>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Perfil fetal</span>
        <v-select
          :items="[
            'aparentemante normal',
            'atípico',
            'avaliação limitada',
            'osso nasal ausente',
            'avaliação limitada',
          ]"
          class="mx-2 flex-grow-0"
          dense
          required
          clearable
          validate-on-blur
          v-model="laudo.perfilFetalPrimeiroTrimistre"
        />
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Estômago</span>
        <v-select
          :items="[
            'presente',
            'não visualizado durante o exame',
            'aumentado',
            'pequeno',
            'avaliação limitada',
          ]"
          class="mx-2 flex-grow-0"
          dense
          required
          clearable
          validate-on-blur
          v-model="laudo.estomagoPrimeiroTrimistre"
        />
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Bexiga</span>
        <v-select
          :items="[
            'presente',
            'não visualizado durante o exame',
            'aumentado',
            'avaliação limitada',
          ]"
          class="mx-2 flex-grow-0"
          dense
          required
          clearable
          validate-on-blur
          v-model="laudo.bexigaPrimeiroTrimistre"
        />
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Parede abdominal</span>
        <v-select
          :items="[
            'íntegra',
            'com onfalocele',
            'com gastrosquise',
            'com defeito de fechamento',
            'avalição limitada',
          ]"
          class="mx-2 flex-grow-0"
          dense
          required
          clearable
          validate-on-blur
          v-model="laudo.paredeAbdominalPrimeiroTrimistre"
        ></v-select>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Cordão umbilical</span>
        <v-select
          :items="[
            'três vasos e bem inserido no abdomen fetal',
            'dois vasos e bem inserido no abdomen fetal',
          ]"
          class="mx-2 flex-grow-0"
          dense
          required
          clearable
          validate-on-blur
          v-model="laudo.cordaoUmbilicalPrimeiroTrimistre"
        />
        <v-select
          :items="[
            'inserido na porção central da placenta',
            'inserido na periferia da placenta',
          ]"
          class="mx-2 flex-grow-0"
          dense
          clearable
          required
          validate-on-blur
          v-model="laudo.cordaoUmbilicalInseridoNa"
        ></v-select>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Membros superiores</span>
        <v-select
          :items="[
            'dois membros visíveis',
            'apenas o membro direito totalmente visível',
            'apenas o membro esquerdo totalmente visível',
            'avaliação limitada',
          ]"
          class="mx-2 flex-grow-0"
          dense
          required
          clearable
          validate-on-blur
          v-model="laudo.membrosSuperioresPrimeiroTrimistre"
        ></v-select>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Membros inferiores</span>
        <v-select
          :items="[
            'dois membros visíveis',
            'apenas o membro direito totalmente visível',
            'apenas o membro esquerdo totalmente visível',
            'avaliação limitada',
          ]"
          class="mx-2 flex-grow-0"
          dense
          clearable
          required
          validate-on-blur
          v-model="laudo.membrosInferioresPrimeiroTrimistre"
        />
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Artérias renais</span>
        <v-select
          :items="[
            'ambas visualizadas',
            'ambas não visualizadas',
            'apenas uma visualizada',
            'de difícil visualização',
            'avaliação limitada',
          ]"
          class="mx-2 flex-grow-0"
          dense
          required
          clearable
          validate-on-blur
          v-model="laudo.artereiasRenaisPrimeiroTrimistre"
        ></v-select>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';

export default {
  mixins: [LaudosMixin],
  props: {
    laudoIndex: {
      type: Number,
      required: true,
    },
  },
  components: { FormInfoLaudo },
  computed: {
    laudo() {
      return this.$store.state.Laudo.laudos[this.laudoIndex];
    },
  },
  watch: {
    laudo: {
      handler(value) {
        this.$store.commit('Laudo/SET_LAUDO_BY_INDEX', {
          index: this.laudoIndex,
          model: this.laudo,
        });
      },
      deep: true,
    },
  },
};
</script>
