<template lang="">
    <div>
      <Laudo4d></Laudo4d>
    </div>
  </template>
  <script>
  import Laudo4d from '../components/laudos/formularios/exames/4d/Laudo4d.vue';
  export default {
    components: {
        Laudo4d,
    },
  };
  </script>
  <style lang=""></style>
  