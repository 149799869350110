import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import CalendarioMixin from '@mixins/CalendarioMixin';
import MedicoMixin from './MedicoMixin';
import { EMBRION_API_URL } from '../data/enviroment';

export default {
  mixins: [CalendarioMixin, MedicoMixin],
  data() {
    return {
      urlBase: EMBRION_API_URL,
      nomesExames: [],
      linkExame: '',
      nomesConvenios: [],
      examesDaConsulta: [],
      todasConsultas: [],

      pacientesPorDiaEMedicoNome: [],
      formasPagamento: [
        'A vista',
        'Convênio',
        'Pix',
        'CARTAO',
        'Cortesia',
        'Retorno',
      ],
      quantidadeParcelas: [
        'Preço à vista',
        'Em 2x',
        'Em 3x',
        'Em 4x',
        'Em 5x',
        'Em 6x',
        'Em 7x',
        'Em 8x',
        'Em 9x',
        'Em 10x',
        'Em 11x',
        'Em 12x',
      ],
      selecaoBandeira: [
        'Mastercard',
        'Visa',
        'Elo',
        'American Express',
        'Hipercard',
        'Alelo',
      ],
      tipoDesconto: ['Porcentagem (%)', 'Valor em real (R$)'],
    };
  },
  computed: {
    ...mapState({
      consultas: 'consultas',
      consultaSelecionada: 'consultaSelecionadaState',
      consultasSelecionadas: 'consultasSelecionadasState',
    }),
    ...mapGetters([
      'listaConsultas',
      'consultas',
      'examesDaConsulta',
      'consultaSelecionada',
      'consultasSelecionadas',
      'nomesPacientesPorDia',
      'listaConvenios',
      'listaConsultas',
      'convenioSelecionado',
      'medicoSelecionado',
      'exameSelecionado',
      'listaExames',
    ]),
    ...mapGetters('Auth', ['headers', 'nivelPermissao']),
    ...mapGetters([]),
  },
  methods: {
    ...mapActions({
      getConsultas: 'getConsultas',
      getConsultaById: 'getConsultaById',
      getConsultaByData: 'getConsultaByData',
      getConsultaByDataMedico: 'getConsultaByDataMedico',
      getConsultaByMedicoId: 'getConsultaByMedicoId',
      getConsultaByPacienteId: 'getConsultaByPacienteId',
      getValorConsulta: 'getValorConsulta',
      getConvenios: 'getConvenios',
      getExames: 'getExames',
      postExamePorConsulta: 'postExamePorConsulta',
      deleteExamePorConsulta: 'deleteExamePorConsulta',
      setConsultasSelecionadas: 'setConsultasSelecionadas',
      setConsultaSelecionada: 'setConsultaSelecionada',
      setConvenioSelecionado: 'setConvenioSelecionado',
      setExameSelecionado: 'setExameSelecionado',
      resetaConsultaSelecionada: 'resetaConsultaSelecionada',
      resetaConsultasSelecionadas: 'resetaConsultasSelecionadas',
      setMedicoExecutanteConsulta: 'setMedicoExecutanteConsulta',
      deleteConsulta: 'deleteConsulta',
      getConsultas: 'getConsultas',
      getExamesPorConsultaSelecionada: 'getExamesPorConsultaSelecionada',
      efetuarPagamento: 'efetuarPagamento',
      setConsultasPorPaciente: 'setConsultasPorPaciente',
      getAgendamentos: 'getAgendamentos',
      adicionaEspacos: 'adicionaEspacos',
      getConsultasAtendidasOuLaudadas: 'getConsultasAtendidasOuLaudadas',
      getListaDeConsultas: 'getListaDeConsultas',
      getTodasConsultas: 'getTodasConsultas',
      laudoExists: 'laudoExists',
      getdatasBloqueadas: 'getdatasBloqueadas',
      postdataBloqueada: 'postdataBloqueada',
      desbloquearData: 'desbloquearData',
      getValorProcedimento: 'getValorProcedimento',
      atualizaValorPagamento: 'atualizaValorPagamento',
      efetuarPagamentoModal: 'efetuarPagamentoModal',
      getListaNomesPacientes: 'getListaNomesPacientes',
    }),

    async setConsultasByMedicoId(medicoId, data) {
      let dataFomatada = moment(data).format('YYYY-MM-DD');

      try {
        await this.getConsultaByDataMedico({ medicoId, dataFomatada });
        let consultas = this.consultasSelecionadas.filter(
          consulta => consulta.medicoExecutanteId === medicoId
        );
        this.setConsultasSelecionadas(consultas);
        return consultas;
      } catch (error) {
      } finally {
        this.adicionaEspacos();
      }
    },
    async getValorProcedimento(params) {
      let valor;
      let listaExames = params.exames
        .map(e => e.valorExameConvenio.exame.nome)
        .join('&Exames=');

      const response = await axios
        .get(
          `${this.urlBase}MapaAssociacaoExames?Exames=${listaExames}&ConvenioId=${params.convenioId}`,
          { headers: this.headers }
        )
        .then(resp => {
          valor = resp.data[0].valor;
        })
        .catch(() => {
          valor =
            'O procedimento não está cadastrado no sistema, não foi possivel calcular';
        });

      return valor;
    },
    async getConsultaPorId(id, paciente) {
      let consulta;

      let resp = await axios
        .get(`${this.urlBase}Consulta/byId?id=1949`, {
          headers: this.headers,
        })
        .then(response => {
          consulta = response;
          consulta.paciente = paciente;
          this.setConsultaSelecionada(consulta);
        })
        .catch(e => {});

      return consulta;
    },
    async laudoExists(exameId) {
      let existe = false;
      await axios
        .get(
          `${this.urlBase}Laudos/ByExamePorConsultaId?examePorConsultaId=${exameId}`,
          { headers: this.headers }
        )
        .then(resp => {
          if (resp.status == 200) {
            existe = true;
          }
        })
        .catch(e => {
          existe = false;
        });

      return existe;
    },
    async setConsultasPorPaciente(pacienteId, medicoId, data) {
      let dataFomatada = moment(data).format('YYYY-MM-DD');
      await this.getConsultaByDataMedico({ medicoId, dataFomatada });
      let consultas = this.consultasSelecionadas.filter(
        consulta => consulta.paciente.id === pacienteId
      );
      this.setConsultasSelecionadas(consultas);
    },

    async getTodasConsultas() {
      return await this.getConsultas();
    },

    getExameByNome(nome) {
      for (let exame of this.consultaSelecionada.exames) {
        if (exame.valorExameConvenio.exame.nome == nome) {
          return exame;
        }
      }
    },
    getListaNomesPacientes(){
      
      this.pacientesPorDiaEMedicoNome = this.nomesPacientesPorDia
      
      

    },

    getNomesExames() {
      return axios
        .get(`${this.urlBase}Exame`, { headers: this.headers })
        .then(resp => {
          let exames = resp.data;
          this.nomesExames = exames.map(exame => exame.nome);
        });
    },

    getNomesConvenios() {
      axios
        .get(`${this.urlBase}Convenio`, { headers: this.headers })
        .then(resp => {
          let convenios = resp.data;
          this.nomesConvenios = convenios.map(convenio => convenio.nome);
        });
    },
    async deleteConsulta(consulta) {
      const resp = await axios.delete(
        `${this.urlBase}Consulta?Id=${consulta}`,
        {
          headers: this.headers,
        }
      );
      return resp;
    },

    getExamesPorConsultaSelecionada(idConsulta) {
      return axios
        .get(`${this.urlBase}ExamePorConsulta?consultaId=${idConsulta}`, {
          headers: this.headers,
        })
        .then(resp => {
          let consulta = this.consultaSelecionada;
          consulta.exames = resp.data;
          this.examesDaConsulta = consulta.exames.map(c => ({
            text: c.valorExameConvenio.exame.nome,
            value: c.valorExameConvenio.exame.nome.toLowerCase(),
          }));
        });
    },

    setaConvenioSelecionado(nome) {
      return this.getConvenios().then(() => {
        for (let convenio of this.listaConvenios) {
          if (convenio.nome == nome) this.setConvenioSelecionado(convenio);
        }
      });
    },

    // Parametos não são obrigátorios
    // nome_da_funcao(parametos) {
    //  Formula a ser execultada
    // },

    getConsultByMedicoEData(medico, data) {
      let dataFomatada = moment(data, 'DD/MM/YYYY').format('YYYY/MM/DD');

      return axios
        .get(
          `${this.urlBase}Consulta/byDataMedicoId?Data=${dataFomatada}&medicoId=${medico}`,
          { headers: this.headers }
        )
        .then(resp => {
          this.pacientesPorDiaEMedicoNome = [
            ...new Set(resp.data.map(p => p.nome)),
          ];
        }).catch(()=>{
          return
        });
    },
    async getConsultaByPacienteId(pacienteId) {
      let consultaInfo = [];
      try {
        const response = await axios
          .get(`${this.urlBase}Consulta/byPaciente?pacienteId=${pacienteId}`, {
            headers: this.headers,
          })
          .then(resp => {
            consultaInfo = resp.data.map(consulta => ({
              data: consulta.data,
              hora: consulta.hora,
              status: consulta.status,
            }));
          });
        return consultaInfo;
      } catch (error) {
        console.error('Erro ao buscar as consultas:', error);
        throw error;
      }
    },
    async getdatasBloqueadas(context, medicoId) {
      const dataSelecionada = moment(context.dataSelecionada, 'DD/MM/YYYY');
      let mes = dataSelecionada.format('MM');
      let ano = dataSelecionada.format('YYYY');
     

      const resposta = await axios.get(
        `${this.urlBase}DatasBloqueadas?mes=${mes}&ano=${ano}&medicoId=${this.medicoSelecionado.id}`,
        {
          headers: this.headers,
        }
      );
      

      let dataSelecionadaBloqueada = false;
      const datasBloqueadas = resposta.data.map(d => {
        const data = moment(d.data, 'YYYY-MM-DD');
        
        if (
          this.medicoSelecionado.id === d.medicoId &&
          data.isSame(dataSelecionada)
        )
          dataSelecionadaBloqueada = true;

        return {
          id: d.id,
          data,
          medicoId: d.medicoId,
        };
      });

      context.$store.commit(
        'Calendario/SET_DATA_SELECIONADA_BLOQUEADA',
        dataSelecionadaBloqueada
      );
      context.$store.commit('Calendario/SET_DATAS_BLOQUEADAS', datasBloqueadas);

      
    },
    async postdataBloqueada(data, medicoId) {
      let dataFomatada = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
      
      

      const resp = await axios.post(
        `${this.urlBase}DatasBloqueadas`,
        { data: dataFomatada, medicoId: this.medicoSelecionado.id },
        { headers: this.headers }
      );
      await this.getdatasBloqueadas(this);
      return resp;
    },

    async desbloquearData(data, medicoId) {
      
      let dataFomatada = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
      const resp = await axios.delete(
        `${this.urlBase}DatasBloqueadas?data=${dataFomatada}&medicoId=${medicoId}`,
        { headers: this.headers }
      );
      await this.getdatasBloqueadas(this);
      return resp;
    },

    setaExameSelecionado(nome) {
      return this.getExames().then(() => {
        for (let exame of this.listaExames) {
          if (exame.nome == nome) this.setExameSelecionado(exame);
        }
      });
    },

    async postConsulta(consulta, examesProc) {
      const exames = consulta.exames;
      const pagamento = this.consultaSelecionada.formaDePagamento;
      delete this.consultaSelecionada.pagamento;
      delete consulta.exames;
      consulta.horaChegada = '';
      consulta.horaSaida = '';
      let retorno = 200;

      try {
        await axios
          .post(`${this.urlBase}Consulta`, consulta, { headers: this.headers })
          .then(resp => {
            consulta = resp.data;

            let cont = 0;

            let exames = examesProc.map(exame => {
              exame.consultaId = consulta.id;

              return { exame };
            });

            let tamLista = exames.length;
            for (var exame of exames) {
              axios
                .post(`${this.urlBase}ExamePorConsulta`, exame.exame, {
                  headers: this.headers,
                })
                .then(response => {
                  cont++;

                  if (tamLista == cont) {
                    this.salvaPagamento(consulta.id, pagamento);
                  }
                });
            }
          });
      } catch (error) {
        retorno = 400;
      } finally {
      }
      return retorno;
    },
    async salvaPagamento(idConsulta, pagamento) {
      let qtdParcelas;
      if (this.consultaSelecionada.quantidadeDeParcelas === 'Preço à vista') {
        qtdParcelas = 1;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 2x') {
        qtdParcelas = 2;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 3x') {
        qtdParcelas = 3;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 4x') {
        qtdParcelas = 4;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 5x') {
        qtdParcelas = 5;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 6x') {
        qtdParcelas = 6;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 7x') {
        qtdParcelas = 7;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 8x') {
        qtdParcelas = 8;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 9x') {
        qtdParcelas = 9;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 10x') {
        qtdParcelas = 10;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 11x') {
        qtdParcelas = 11;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 12x') {
        qtdParcelas = 12;
      }
      let dataFomatada = moment(this.consultaSelecionada.data).format(
        'YYYY-MM-DD'
      );
      const respPag = await axios.patch(`${this.urlBase}Pagamento`, null, {
        params: {
          consultaId: idConsulta,
          formaDePagamento: pagamento,
          dataPagamento: dataFomatada,
          desconto: this.consultaSelecionada.desconto,
          statusPagamento: 'Em débito',
          parcelamento: qtdParcelas,
          nomeCartao: this.consultaSelecionada.nomeNocartao,
          bandeira: this.consultaSelecionada.bandeira

        },
        headers: this.headers,
      });
    },
    // async atualizaValorPagamento(idConsulta) {
    //   const respPag = await axios
    //     .patch(`${this.urlBase}Pagamento`, null, {
    //       params: {
    //         consultaId: idConsulta,
    //       },
    //       headers: this.headers,
    //     })
    //     .then(() => console.log('atualizou'));
    // },
    async postExamePorConsulta(bodyPost) {
      var resp = await axios.post(`${this.urlBase}ExamePorConsulta`, bodyPost, {
        headers: this.headers,
      });
      return resp;
    },
    async deleteExamePorConsulta(idConsulta) {
      const resp = await axios.delete(
        `${this.urlBase}ExamePorConsulta?Id=${idConsulta}`,
        {
          headers: this.headers,
        }
      );
      return resp;
    },

    async postConsultaOffline(consulta, consultaSelecionada, urlBase, headers) {
      const exames = consulta.exames;
      const pagamento = consultaSelecionada.formaDePagamento;
      delete consultaSelecionada.pagamento;
      delete consulta.exames;
      consulta.horaChegada = '';
      consulta.horaSaida = '';
      await axios
        .post(`${urlBase}Consulta`, consulta, { headers: headers })
        .then(resp => {
          consulta = resp.data;

          // if(resp.status === 401){

          //   this.$router.push("/");
          //   alert("Sua sessão expirou, faça o login novamente!")
          //   return;
          // }
          if (resp.status != 200) {
            alert('Erro: ', resp.status);
            return;
          }

          exames.map(exame => {
            exame.consultaId = consulta.id;

            delete exame.valorExameConvenio;
            axios.post(`${urlBase}ExamePorConsulta`, exame, {
              headers: headers,
            });
            axios.patch(`${urlBase}Pagamento`, null, {
              params: {
                consultaId: consulta.id,
                formaDePagamento: pagamento,
                dataPagamento: consultaSelecionada.data,
                desconto: consultaSelecionada.desconto,
                statusPagamento: 'Em débito',
                
              },
              headers: headers,
            });
          });
        });
    },
    async atualizaValorPagamento(idConsulta) {
      let qtdParcelas;
      if (this.consultaSelecionada.quantidadeDeParcelas === 'Preço à vista') {
        qtdParcelas = 1;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 2x') {
        qtdParcelas = 2;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 3x') {
        qtdParcelas = 3;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 4x') {
        qtdParcelas = 4;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 5x') {
        qtdParcelas = 5;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 6x') {
        qtdParcelas = 6;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 7x') {
        qtdParcelas = 7;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 8x') {
        qtdParcelas = 8;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 9x') {
        qtdParcelas = 9;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 10x') {
        qtdParcelas = 10;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 11x') {
        qtdParcelas = 11;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 12x') {
        qtdParcelas = 12;
      }
      
      let dataFomatada = moment(
        this.consultaSelecionada.data,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
      

      const respPag = await axios.patch(`${this.urlBase}Pagamento`, null, {
        params: {
          consultaId: idConsulta,
          formaDePagamento: this.consultaSelecionada.formaDePagamento,
          dataPagamento: this.consultaSelecionada.pagamento.dataDoPagamento          ,
          desconto: this.consultaSelecionada.desconto,
          statusPagamento: this.consultaSelecionada.pagamento.statusPagamento,
          parcelamento: qtdParcelas,
          nomeCartao: this.consultaSelecionada.nomeNocartao,
          bandeira: this.consultaSelecionada.bandeira
        },
        headers: this.headers,
      });
    },
    async efetuarPagamentoModal(consultaId) {
      let qtdParcelas;
      if (this.consultaSelecionada.quantidadeDeParcelas === 'Preço à vista') {
        qtdParcelas = 1;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 2x') {
        qtdParcelas = 2;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 3x') {
        qtdParcelas = 3;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 4x') {
        qtdParcelas = 4;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 5x') {
        qtdParcelas = 5;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 6x') {
        qtdParcelas = 6;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 7x') {
        qtdParcelas = 7;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 8x') {
        qtdParcelas = 8;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 9x') {
        qtdParcelas = 9;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 10x') {
        qtdParcelas = 10;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 11x') {
        qtdParcelas = 11;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 12x') {
        qtdParcelas = 12;
      }

      const respPag = await axios.patch(`${this.urlBase}Pagamento`, null, {
        params: {
          consultaId: consultaId,
          formaDePagamento: this.consultaSelecionada.pagamento.formaDePagamento,
          dataPagamento: this.consultaSelecionada.data,
          desconto: this.consultaSelecionada.pagamento.desconto,
          statusPagamento: 'PAGO',
          parcelamento: qtdParcelas,
        },
        headers: this.headers,
      });
    },

    async efetuarPagamento(consultaId) {
      let qtdParcelas;
      if (this.consultaSelecionada.quantidadeDeParcelas === 'Preço à vista') {
        qtdParcelas = 1;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 2x') {
        qtdParcelas = 2;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 3x') {
        qtdParcelas = 3;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 4x') {
        qtdParcelas = 4;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 5x') {
        qtdParcelas = 5;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 6x') {
        qtdParcelas = 6;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 7x') {
        qtdParcelas = 7;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 8x') {
        qtdParcelas = 8;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 9x') {
        qtdParcelas = 9;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 10x') {
        qtdParcelas = 10;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 11x') {
        qtdParcelas = 11;
      } else if (this.consultaSelecionada.quantidadeDeParcelas === 'Em 12x') {
        qtdParcelas = 12;
      }
      axios.patch(`${this.urlBase}Pagamento`, null, {
        params: {
          consultaId: consultaId,
          statusPagamento: 'PAGO',
          parcelamento: qtdParcelas,
        },
        headers: this.headers,
      });
    },

    formatConsultaPatch(consulta) {
      delete consulta.exames;
      delete consulta.data
      let patchBody = Object.keys(consulta).map(key => {
        return {
          path: key,
          op: 'replace',
          value: consulta[key],
        };
      });
      return patchBody;
    },

    async getRecibo() {
      const cpf = String(this.consultaSelecionada.paciente.cpf).replace(
        /[^\d]+/g,
        ''
      );
      let soma = 0;

      this.consultaSelecionada.exames.map(
        exame => (soma += exame.valorExameConvenio.valor)
      );

      try {
        const { data } = await axios.get(`${this.urlBase}Pdfcreate/PdfRecibo`, {
          responseType: 'blob',
          params: {
            nome: this.consultaSelecionada.paciente.nome,
            cpf: cpf,
            rg: this.consultaSelecionada.paciente.rgs,
            consultaId: this.consultaSelecionada.id,
            valor: soma,
          },
        });

        const temp = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = temp;

        const agora = moment().format('DD-MM-YYYY_HH-mm');
        link.setAttribute('download', `Recibo-${agora}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error(JSON.stringify(error, undefined, 2));
      }
    },

    // getRecibo() {
    //   let cpf = this.consultaSelecionada.paciente.cpf.replaceAll('.', '');
    //   cpf = cpf.replaceAll('-', '');
    //   // return axios.get(
    //   //   `${urlBase}/Pdfcreate/PdfRecibo?Nome=${this.consultaSelecionada.paciente.nome}&cpf=${this.consultaSelecionada.paciente.cpf}1&rg=${this.consultaSelecionada.paciente.rgs}&valor=${this.consultaSelecionada.valorExameConvenio.valor}`
    //   // );
    //   let soma = 0;
    //   this.consultaSelecionada.exames.map(
    //     exame => (soma += exame.valorExameConvenio.valor)
    //   );

    //   window.open(
    //     `${this.urlBase}Pdfcreate/PdfRecibo?Nome=${this.consultaSelecionada.paciente.nome}&cpf=${cpf}1&rg=${this.consultaSelecionada.paciente.rgs}&valor=${soma}`
    //   );
    // },

    formatConsultaPut(consulta) {
      let patchBody = [
        'medicoExecutanteId',
        'medicoSolicitanteId',
        'pacienteId',
      ].map(key => {
        return {
          path: key,
          op: 'replace',
          value: consulta[key],
        };
      });
      return patchBody;
    },

    putConsulta(consulta) {
      axios.put(
        `${this.urlBase}Consulta?id=${this.consultaSelecionada.id}`,
        consulta,
        { headers: this.headers }
      );
    },

    patchConsulta(consulta) {
      return axios.patch(
        `${this.urlBase}Consulta?id=${this.consultaSelecionada.id}`,
        consulta,
        { headers: this.headers }
      );
    },
    patchConsultaOffline(consulta, consultaSelecionada, urlBase, headers) {
      axios.patch(`${urlBase}Consulta?id=${consultaSelecionada.id}`, consulta, {
        headers: headers,
      });
    },
    patchPagamento(consulta) {
      let parcelamento = this.consultaSelecionada.parcelamento;
      let parcela =
        parcelamento === 'Preço à vista'
          ? 1
          : parcelamento === 'Em 2x'
          ? 2
          : parcelamento === 'Em 3x'
          ? 3
          : parcelamento === 'Em 4x'
          ? 4
          : parcelamento === 'Em 5x'
          ? 5
          : parcelamento === 'Em 6x'
          ? 6
          : parcelamento === 'Em 7x'
          ? 7
          : parcelamento === 'Em 8x'
          ? 8
          : parcelamento === 'Em 9x'
          ? 9
          : parcelamento === 'Em 10x'
          ? 10
          : parcelamento === 'Em 11x'
          ? 11
          : parcelamento === 'Em 12x'
          ? 12
          : 1;
      axios.patch(`${this.urlBase}Pagamento?consultaId=${this.getConsultaId()}
          &formaDePagamento=${this.consultaSelecionada.pagamento}
          &desconto=${this.consultaSelecionada.descontoSolicitado}
          &dataPagamento=${this.consultaSelecionada.data}
          &statusPagamento=Em débito
          &parcelamento=${parcela}`);
    },

    /*
    Adiciona consultas em ordem pelo horario e adiciona
    uma consulta vazia caso nao haja consulta no horario determinado
    */
    adicionaEspacos() {
      this.ordenaConsultas();

      const agenda = this.consultasSelecionadas;

      let dataControle = new Date(2021, 1, 1, 6, 0);
      let novaAgenda = [];
      let countConsulta = 0;
      while (dataControle.getHours() < 23) {
        let hora = `${
          dataControle.getHours().toString().length == 1
            ? '0' + dataControle.getHours().toString()
            : dataControle.getHours()
        }:${
          dataControle.getMinutes().toString()[0] === '0'
            ? dataControle.getMinutes().toString() + '0'
            : dataControle.getMinutes()
        }`;
        if (
          countConsulta < agenda.length &&
          agenda[countConsulta].hora === hora
        ) {
          novaAgenda.push(agenda[countConsulta]);
          countConsulta++;
        } else {
          novaAgenda.push({
            exames: [],
            hora: hora,
            nome: '',
            medico: { nome: '' },
          });
        }
        dataControle = new Date(dataControle.getTime() + 15 * 60000);
      }
      this.setConsultasSelecionadas(novaAgenda);
    },
    async getAgendamentos() {
      const data = moment(this.dataSelecionada, 'DD/MM/YYYY').format(
        'YYYY/MM/DD'
      );
      const idmedico = this.medicoSelecionado.id;

      window.open(
        `${this.urlBase}ExamePorConsulta/CreateExcel?data=${data}&medicoId=${idmedico}`
      );
      link.click();

      link.remove();
    },

    addConsultasAtendidasOuLaudadas() {
      let date = new Date();
      let dia = date.getDate();
      let mes = date.getMonth();
      let ano = date.getFullYear();
      if (dia < 10) {
        dia = `0${dia}`;
      }
      let data = `${dia}/${mes + 1}/${ano}`;

      const listaConsultasAtendidasOuLaudadas = [];

      return this.getConsultas().then(() => {
        this.listaConsultas.map(consulta => {
          const statusNormalizados = consulta.status;
          if (
            statusNormalizados === 'ATENDIDO' ||
            statusNormalizados === 'LAUDADO'
          ) {
            if (this.nivelPermissao === 2 || consulta.data === data)
              listaConsultasAtendidasOuLaudadas.push(consulta);
          }
        });

        this.listaConsultasAtendidasOuLaudadas =
          listaConsultasAtendidasOuLaudadas;
      });
    },
    getConsultasAtendidasOuLaudadas() {
      let date = new Date();
      let dia = date.getDate();
      let mes = date.getMonth();
      let ano = date.getFullYear();
      if (dia < 10) {
        dia = `0${dia}`;
      }
      let data = `${dia}/${mes + 1}/${ano}`;

      const lista2 = [];

      this.getConsultas().then(() => {
        this.listaConsultas.map(consulta => {
          const statusNormalizados = consulta.status;
          if (
            statusNormalizados === 'ATENDIDO' ||
            statusNormalizados === 'LAUDADO'
          ) {
            if (this.nivelPermissao === 2 || consulta.data === data)
              lista2.push(consulta);
          }
        });

        lista2;
      });
      return lista2;
    },
    async alterarStatus() {
      let response = null;
      let id =
        this.consultaSelecionada.consultaId || this.consultaSelecionada.id;
      try {
        this.listaConsultasAtendidasOuLaudadas = [];
        response = await axios.patch(
          `${this.urlBase}Consulta?Id=${id}`,
          [
            {
              path: 'status',
              op: 'replace',
              value: this.consultaSelecionada.status,
            },
          ],
          { headers: this.headers }
        );

        // this.addConsultasAtendidasOuLaudadas();

        //return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(response);
      } finally {
        return response;
      }
    },

    async alterarStatusOffline(consulta, urlBase, headers) {
      try {
        const response = await axios.patch(
          `${urlBase}Consulta/?Id=${consulta.id}`,
          [
            {
              path: 'status',
              op: 'replace',
              value: consulta.status,
            },
          ],
          { headers: headers }
        );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(response);
      }
    },

    ordenaConsultas() {
      this.setConsultasSelecionadas(
        [...this.consultasSelecionadas].sort((a, b) =>
          a.hora.localeCompare(b.hora)
        )
      );
    },

    async criarMapaAssociacaoExames(model) {
      const response = await axios.post(
        `${this.urlBase}MapaAssociacaoExames?convenio=${this.convenioSelecionado.id}&codigoProcedimento=${model.procedimento}&valor=${model.valor}`,
        model.exames,
        { headers: this.headers }
      );
      return response;
    },

    async editarMapaAssociacaoExames(model, id){
      const response = await axios.patch(`${this.urlBase}MapaAssociacaoExames/EditaAssociacao?id=${id}&convenio=${this.convenioSelecionado.id}&codigoProcedimento=${model.codigoProcedimento
      }&valor=${model.valor}`,
        model.exames,
        {headers: this.headers},
      );
      return response;
      

    },

    async consultarMapaAssociacaoExames(codigoProcedimento) {
      try {
        const response = await axios.get(`api/MapaAssociacaoExames/`);
      } catch (error) {
        return Promise.reject(response);
      }
    },
  },
};
