<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <h3 v-if="via == '2' && indexFolha === 0">2ª VIA DO LAUDO</h3>
      <br /><br />
      
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <!-- <h3><strong>CONVÊNIO:</strong> {{ convenio.nome }}</h3> -->

          <br /> </div>

        <br />
        <br />
        <br />
        <h3>ULTRASSONOGRAFIA MORFOLÓGICA</h3>
        <h3>PERFIL CERVICAL</h3>
        <br />
        <br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          {{ elemento }}
          <p></p>
        </h4>
        <span v-else-if="elemento === 'obs'" style="font-size: 10pt">
          OBS: No momento do exame não se observam anomalias morfológicas
          fetais, entretanto não podemos descartar malformações que não tem
          expressão ecográfica ou se apresentam de forma tardia (sensibilidade
          do exame 85%). A critério clínico, complementar avaliação cardiológica
          com especialista em ecocardiografia fetal.
        </span>
        <div v-else-if="elemento === 'circunCef'" class="mt-n4 div-duas-span" style="">
          <span style="flex: 1;">{{ laudo.circunferenciaCefalica ? 'Circunferência cefálica: ' + laudo.circunferenciaCefalica  + ' mm.': '' }}</span>
          <span style="flex: 1;">{{ laudo.ccca ? 'CC/CA: ' + laudo.ccca + '%' : '' }}</span>
      </div>
      <div v-else-if="elemento === 'diame'" class="mt-n4 div-duas-span" >
          <span style="flex: 1;">{{ laudo.diametroBiparietal? 'Diâmetro biparietal: ' +
                  laudo.diametroBiparietal +
                ' mm.':''}}</span>
          <span style="flex: 1;"> {{ laudo.flcc? 'FL/CC:  ' +
                  laudo.flcc +
                ' %':''}}</span>
      </div>
      <div v-else-if="elemento === 'circunAbdm'" class="div-duas-span" >
          <span style="flex: 1;">{{ laudo.circunferenciaAbdominal? 'Circunferência abdominal: ' +
                  laudo.circunferenciaAbdominal +
                ' mm.':''}}</span>
          <span style="flex: 1;"> {{ laudo.flca? 'FL/CA:  ' +
                  laudo.flca +
                ' %':''}}</span>
      </div>
      <div v-else-if="elemento === 'compUmer'" class="div-duas-span" >
          <span style="flex: 1;">{{ laudo.comprimentoUmero? 'Comprimento  do úmero: ' +
                  laudo.comprimentoUmero +
                ' mm.':''}}</span>
          <span style="flex: 1;"> {{ laudo.ic? 'IC:  ' +
                  laudo.ic +
                ' %':''}}</span>
      </div>

      <div v-else-if="elemento === 'cranio'">
          <div  v-if="typeof(laudo.cranio) == 'string'">
            <strong>Crânio: </strong>
            <span> {{ laudo.cranio }}.</span>

          </div>
          <div v-else>
            <strong>Crânio: </strong>
          <span v-for="(item, index) in laudo.cranio" :key="index">
            <span>{{ index == 0? (item.toLowerCase() == 'outro'?laudo.comentariosAdcionaiscranio:(item == 'falha de continuidade no crânio, na região'? item + ' ' + laudo.cranioFalta  + ' com protrusão de tecido nervoso e meninges':item)) :(item == 'outro'?laudo.comentariosAdcionaisCoracao
            :(item == 'falha de continuidade no crânio, na região'? item + ' ' + laudo.cranioFalta  + ' com protrusão de tecido nervoso e meninges':item)) }}</span>
            <span>{{
              index != laudo.cranio.length - 1 ? ', ' : '.'
            }}</span> </span
          >
            

          </div>

          
          
        </div>
        
        <div v-else-if="elemento === 'encefalo'">
          <div  v-if="typeof(laudo.encefalo) == 'string'">
            <strong>Encéfalo:</strong>
            <span> {{ laudo.encefalo }}.</span>

          </div>
          <div v-else>
            <strong>Encéfalo:</strong>
            <span v-for="(item, index) in laudo.encefalo" :key="index" >
            

            <span> {{ index == 0? (item.toLowerCase() == 'outro'?laudo.comentariosAdcionaisEncefalo:(item == 'presença de cistos de plexo coroide, medindo' || item == 'átrio dos ventrículos laterais dilatados, medindo '?
            item + laudo.encefaloMedindo + ' mm'
            :
            item ).toLowerCase()) :(item == 'outro'?laudo.comentariosAdcionaisEncefalo:
            (item == 'presença de cistos de plexo coroide, medindo' || item == 'átrio dos ventrículos laterais dilatados, medindo '?
            item + laudo.encefaloMedindo + ' mm'
            :
            item )
            
            ) }}</span>
            <span>{{
              index != laudo.encefalo.length - 1 ? '. ' : ''
            }}</span> </span>.
            

          </div>
          <!-- <strong>Encéfalo:</strong>
          {{ laudo.encefalo != 'outro' ? laudo.encefalo : laudo.comentariosAdcionaisEncefalo
          }}{{
            laudo.encefalo == 'átrio dos ventrículos laterais dilatados, medindo '
            || laudo.encefalo == 'presença de cistos de plexo coroide, medindo '
            ? laudo.encefaloMedindo + ' mm' : ''
          }}. -->
        </div>
        <div v-else-if="elemento === 'face'">
          <div  v-if="typeof(laudo.face) == 'string'">
            <strong>Face:</strong>
            <span> {{ laudo.face }}.</span>

          </div>
          <div v-else>
            <strong>Face:</strong>
            <span v-for="(item, index) in laudo.face" :key="index" >
            

            <span> {{ index == 0? (item.toLowerCase() == 'outro'?laudo.comentariosAdcionaisFace:item.toLowerCase()) :(item == 'outro'?laudo.comentariosAdcionaisFace:item) }}</span>
            <span>{{
              index != laudo.face.length - 1 ? '. ' : ''
            }}</span> </span>.
            

          </div>
        
         
          
        </div>
        <div v-else-if="elemento === 'abdomen'">
          <div  v-if="typeof(laudo.abdomeUm) == 'string'">
            <strong>Abdômen:</strong>
            <span> {{ laudo.abdomeUm }}.</span>

          </div>
          <div v-else>
            <strong>Abdômen: </strong>
            <span v-for="(item, index) in laudo.abdomeUm" :key="index" >
            

            <span> {{ index == 0? (item.toLowerCase() == 'outro'?laudo.abdomeOutro:item.toLowerCase()) :(item == 'outro'?laudo.abdomeOutro:item) }}</span>
            <span>{{
              index != laudo.abdomeUm.length - 1 ? '. ' : ''
            }}</span> </span>.
            

          </div>
          
          
        </div>

        <div v-else-if="elemento === 'sistemaUrinario'">
          <div  v-if="typeof(laudo.sistemaUrinario) == 'string'">
            <strong>Sistema Urinário:</strong>
            <span> {{ laudo.sistemaUrinario }}.</span>

          </div>
          <div v-else>
            <strong>Sistema Urinário: </strong>
            <span v-for="(item, index) in laudo.sistemaUrinario" :key="index" >
            

            <span> {{ index == 0? (item.toLowerCase() == 'outro'?laudo.sistemaUrinarioOutro:item.toLowerCase()) :(item == 'outro'?laudo.sistemaUrinarioOutro:item) }}</span>
            <span>{{
              index != laudo.sistemaUrinario.length - 1 ? '. ' : ''
            }}</span> </span>
            

          </div>
          
         
        </div>
        <div v-else-if="elemento === 'coluna'">
          <div  v-if="typeof(laudo.coluna) == 'string'">
            <strong>Coluna:</strong>
            <span> {{ laudo.coluna }}.</span>

          </div>
          <div v-else>
            <strong>Coluna: </strong>
            <span v-for="(item, index) in laudo.coluna" :key="index" >
            

            <span> {{ index == 0? (item.toLowerCase() == 'outro'?laudo.comentariosAdcionaisColuna:item.toLowerCase()) :(item == 'outro'?laudo.comentariosAdcionaisColuna:item) }}</span>
            <span>{{
              index != laudo.coluna.length - 1 ? '. ' : ''
            }}</span> </span>
            

          </div>
          
           
        </div>
        <div v-else-if="elemento === 'sexoGenitaliaExternaMorfologicamente'">
          <strong>Sexo - </strong>genitália externa morfologicamente
          {{ laudo.sexoGenitaliaExternaMorfologicamente }}.
        </div>
        <div v-else-if="elemento === 'torax'">
          <div  v-if="typeof(laudo.torax) == 'string'">
            <strong>Tórax:</strong>
            <span> {{ laudo.torax }}.</span>

          </div>
          <div v-else>
            <strong>Tórax: </strong>
            <span v-for="(item, index) in laudo.torax" :key="index" >
            

            <span> {{ index == 0? (item.toLowerCase() == 'outro'?laudo.comentariosAdcionaisTorax:item.toLowerCase()) :(item == 'outro'?laudo.comentariosAdcionaisTorax:item) }}</span>
            <span>{{
              index != laudo.torax.length - 1 ? '. ' : ''
            }}</span> </span>
            

          </div>
          
        </div>
        <div v-else-if="elemento === 'membros'">
          <div  v-if="typeof(laudo.membros) == 'string'">
            <strong>Membros:</strong>
            <span> {{ laudo.membros }}.</span>

          </div>
          <div v-else>
            <strong>Membros: </strong>
            <span v-for="(item, index) in laudo.membros" :key="index" >
            

            <span> {{ index == 0? (item.toLowerCase() == 'outro'?laudo.membrosOutro:item.toLowerCase()) :(item == 'outro'?laudo.membrosOutro:item) }}</span>
            <span>{{
              index != laudo.membros.length - 1 ? '. ' : ''
            }}</span> </span>
            

          </div>
         
         
        </div>
        

        <div v-else-if="elemento === 'coracao'">
          <strong>Coração: </strong>
          <span v-for="(item, index) in laudo.coracao" :key="index">
            <span>{{ index == 0? (item.toLowerCase() == 'outro'?laudo.comentariosAdcionaisCoracao:item.toLowerCase()) :(item == 'outro'?laudo.comentariosAdcionaisCoracao:item) }}</span>
            <span>{{
              index != laudo.coracao.length - 1 ? '. ' : ''
            }}</span> </span
          >.
        </div>

        <div v-else-if="elemento === 'fetoVivo'">
          <span>Gestação única com feto vivo.</span>
        </div>

        <p v-else-if="elemento === 'espaço'"></p>

        <div v-else>
          {{ elemento }}
        </div>
      </div>

      <div v-if="indexFolha + 1 == tamanhoElemento.length">
        <br />
      </div>
      <br />

      <br />

      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong>Dra {{ medico.nome == 'Adriana Melo (curso)'? 'Adriana Melo': medico.nome }}</strong> </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
    <folha
      v-for="(elementos2, indexFolha2) in tamanhoElemento2"
      :key="indexFolha2"
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3>PERFIL CERVICAL</h3>
      <br />
      <br />
      <div
        v-for="(elemento2, indexElemento2) in elementos2"
        :key="indexElemento2"
      >
        <h4 v-if="titulos.includes(elemento2)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          {{ elemento2 }}
          <p></p>
          <p></p>
        </h4>
        <div v-else>
          {{ elemento2 }}
        </div>
      </div>
      <br />
      <br />

      <footer v-if="indexFolha2 + 1 == tamanhoElemento2.length">
        <span
          ><strong>Dra {{ medico.nome == 'Adriana Melo (curso)'? 'Adriana Melo': medico.nome}}</strong> </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';
export default {
  mixins: [ImprecoesMixins],

  methods: {
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
  },
  mounted() {
    if(this.$route.query.via) this.via = this.$route.query.via;
    let folha = [];
    let folha2 = [];

    if (this.laudo.situacao) folha.push(`Situação: ${this.laudo.situacao}.`);
    if (this.laudo.apresentacao)
      folha.push(`Apresentação: ${this.laudo.apresentacao}.`);
    if (this.laudo.dorsoFetal)
      folha.push(`Dorso Fetal: ${this.laudo.dorsoFetal}.`);

    if (folha.length > 0) folha.splice(0, 0, 'Estática');
    this.estatica = [...folha];
    folha = [];

    if (this.laudo.movimentosFetaisPresentes)
      folha.push(`Movimentos fetais: ${this.laudo.movimentosFetaisPresentes}.`);
    if (this.laudo.batimentosCardiacosFetais)
      folha.push(
        `Batimentos cardíacos fetais: ${this.laudo.batimentosCardiacosFetais}.`
      );
    if (this.laudo.frequenciaBpm)
      folha.push(`Frequência de ${this.laudo.frequenciaBpm} bpm.`);
    if (this.laudo.comentariosAdcionais001)
      folha.push(` ${this.laudo.comentariosAdcionais001}.`);

    if (folha.length > 0) folha.splice(0, 0, 'Vitalidade');
    this.vitalidade = [...folha];
    folha = [];

    if (this.laudo.diametroBiparietal || this.laudo.flcc)
      folha.push(`diame`);

    // if (this.laudo.diametroBiparietal || this.laudo.flcc)
    //   folha.push(`Diâmetro biparietal: ${this.laudo.diametroBiparietal} mm. ${this.laudo.flcc}`);
      if (this.laudo.circunferenciaCefalica || this.laudo.ccca)
      folha.push(
        `circunCef`
      );
      ;
    if (this.laudo.circunferenciaAbdominal || this.laudo.flca)
      folha.push(
        `circunAbdm`
      );
     
    
    if (this.laudo.comprimentoUmero || this.laudo.ic)
      folha.push(`compUmer`);
    if (this.laudo.comprimentoUlna)
      folha.push(`Comprimento da ulna: ${this.laudo.comprimentoUlna} mm.`);
    if (this.laudo.comprimentoRadio)
      folha.push(`Comprimento do rádio: ${this.laudo.comprimentoRadio} mm.`);
    if (this.laudo.comprimentoFemur)
      folha.push(`Comprimento do femur: ${this.laudo.comprimentoFemur} mm.`);
 
    if (this.laudo.comprimentoTibia)
      folha.push(`Comprimento da tíbia: ${this.laudo.comprimentoTibia} mm.`);
    if (this.laudo.comprimentoFibula)
      folha.push(`Comprimento da fíbula: ${this.laudo.comprimentoFibula} mm.`);
    if (this.laudo.comprimentoPe)
      folha.push(`Comprimento do pé: ${this.laudo.comprimentoPe} mm.`);
    if (this.laudo.diametroCerebelo)
      folha.push(`Diâmetro do cerebelo: ${this.laudo.diametroCerebelo} mm.`);
    if (this.laudo.cisternaMagna)
      folha.push(`Cisterna magna: ${this.laudo.cisternaMagna} mm.`);
    if (this.laudo.pregaCutaneaOccipital)
      folha.push(
        `Prega cutânea occipital: ${this.laudo.pregaCutaneaOccipital} mm.`
      );
    if (this.laudo.atrioVentriculoLateral)
      folha.push(
        `Átrio do ventrículo lateral: ${this.laudo.atrioVentriculoLateral} mm.`
      );
    if (this.laudo.ossoNasal)
      folha.push(`Osso nasal: ${this.laudo.ossoNasal} mm.`);
    if (this.laudo.distanciaInterobitariaInterna)
      folha.push(
        `Distância interorbitária interna: ${this.laudo.distanciaInterobitariaInterna} mm.`
      );
    if (this.laudo.distanciaInterobitariaExterna)
      folha.push(
        `Distância interorbitária externa: ${this.laudo.distanciaInterobitariaExterna} mm.`
      );
    if (this.laudo.pesoFetal)
      folha.push(`Peso fetal: ${this.laudo.pesoFetal} g.`);

    if (folha.length > 0) folha.splice(0, 0, 'Biometria fetal');
    this.biometriaFetal = [...folha];
    folha = [];

    if (this.laudo.cranio) folha.push('cranio');

    if (this.laudo.encefalo) folha.push('encefalo');
    if (this.laudo.face) folha.push('face');
    if (this.laudo.coluna) folha.push('coluna');
    if (this.laudo.torax) folha.push('torax');
    if (this.laudo.coracao) folha.push('coracao');

    if (folha.length > 0) folha.splice(0, 0, 'Morfologia fetal');

    this.morfologiaFetal = [...folha];
    let tamanhoTotal =
      this.morfologiaFetal.length +
      this.biometriaFetal.length +
      this.vitalidade.length +
      this.estatica.length;
    let diferancaFolha = 29 - tamanhoTotal;
    if (diferancaFolha > 0) {
      for (let j = 0; j < diferancaFolha; j++) {
        folha.push(`espaço`);
      }
    }
    folha = [];

    // if (this.laudo.coracaoUm) folha.push(`Não examinado.`);
    // if (this.laudo.coracaoDois)
    //   folha.push(
    //     `Aparentemente normal com aorta abdominal à esquerda da coluna e presença de veia cava inferior situada à direita.`
    //   );
    // if (this.laudo.coracaoTres)
    //   folha.push(
    //     `Corte de quatro câmaras de aspecto ecografico normal para a idade gestacional.`
    //   );
    // if (this.laudo.coracaoQuatro)
    //   folha.push(
    //     `Corte dos três vasos e traqueia visibilizado e de aspecto normal. Timo visivel.`
    //   );
    // if (this.laudo.coracaoCinco)
    //   folha.push(`Quatro câmaras assimetricas com dominância esquerda.`);
    // if (this.laudo.coracaoSeis)
    //   folha.push(`Quatro câmaras assimétricas com dominância direita.`);
    // if (this.laudo.coracaoSete)
    //   folha.push(`Presença de dois vasos no corte 3VT.`);
    // if (this.laudo.coracaoOito)
    //   folha.push(`Presença  de quatro vasos no corte 3VT.`);
    // if (this.laudo.coracaoNove)
    //   folha.push(`Traqueia localizada entre a aorta e pulmonar.`);
    // if (this.laudo.coracaoDez) folha.push(`Pulmonar menor que a  aorta.`);
    // if (this.laudo.coracaoOnze) folha.push(`Dimensão da aorta similar a VCS.`);
    // if (this.laudo.coracaoDoze)
    //   folha.push(`Aorta originando-se do ventrículo direito.`);
    // if (this.laudo.coracaoTreze)
    //   folha.push(`Pulmonar originando-se do ventrículo esquerdo.`);
    // if (this.laudo.coracaoQuatorze) folha.push(`Veia ázigos dilatada.`);
    // if (this.laudo.coracaoQuinze)
    //   folha.push(
    //     `Outro. ${
    //       this.laudo.coracaoQuinze == 'Outro.' ? this.laudo.outroQuinze : ''
    //     }`
    //   );

    if (this.laudo.abdomeUm) folha.push('abdomen');
    if (this.laudo.sistemaUrinario) folha.push('sistemaUrinario');
    if (this.laudo.diametroLongitudinalRimDireito)
      folha.push(
        `Diâmetro longitudinal do rim direito: ${this.laudo.diametroLongitudinalRimDireito} mm.`
      );
    if (this.laudo.diametroLongitudinalRimEsquerdo)
      folha.push(
        `Diâmetro longitudinal do rim esquerdo: ${this.laudo.diametroLongitudinalRimEsquerdo} mm.`
      );
    if (this.laudo.pelveRenalDireita)
      folha.push(`Pelve renal direita: ${this.laudo.pelveRenalDireita} mm.`);
    if (this.laudo.pelveRenalEsquerda)
      folha.push(`Pelve renal esquerda: ${this.laudo.pelveRenalEsquerda} mm.`);

    if (this.laudo.sexoGenitaliaExternaMorfologicamente)
      folha.push('sexoGenitaliaExternaMorfologicamente');

    if (this.laudo.membros) folha.push('membros');

    if (this.laudo.liquidoAmniotico) folha.push('liquidoAmniotico');

    if (this.laudo.liquidoAmnioticoOutro2)
      folha.push(` ${this.laudo.liquidoAmnioticoOutro2}.`);

    if (folha.length > 0) folha.splice(0, 0, '');
    this.abdomen = [...folha];
    folha = [];

    // if (this.laudo.localizacao)
    //   folha.push(
    //     `Localização: ${this.laudo.localizacao}${
    //       this.laudo.localizacao == 'outro' ? this.laudo.localizacaoOutro : ''
    //     }.`
    //   );
    if (this.laudo.localizacao) folha.push('localizacao');
    if (this.laudo.espessura)
      folha.push(`Espessura: ${this.laudo.espessura} mm.`);
    if (this.laudo.textura) folha.push(`Textura: ${this.laudo.textura}.`);
    if (this.laudo.grauUm) folha.push(`Grau: ${this.laudo.grauUm}.`);
    if (this.laudo.comentariosAdcionaisDois)
      folha.push(` ${this.laudo.comentariosAdcionaisDois}.`);

    if (folha.length > 0) folha.splice(0, 0, 'Placenta');
    this.placenta = [...folha];
    folha = [];

    if (this.laudo.insercao) folha.push(`Inserção: ${this.laudo.insercao}.`);
    if (this.laudo.vasos) folha.push(`Vasos: ${this.laudo.vasos}.`);
    if (this.laudo.comentariosAdcionaisTres)
      folha.push(` ${this.laudo.comentariosAdcionaisTres}.`);

    if (folha.length > 0) folha.splice(0, 0, 'Cordão umbilical');
    this.cordaoUmbilical = [...folha];
    folha = [];

    if (this.laudo.crescimentoFetalUm) folha.push('fetoVivo');

    if (
      this.laudo.idadeGestacionalBiometriaFetalSemanas ||
      this.laudo.idadeGestacionalBiometriaFetalDias
    )
      folha.push(`Idade gestacional baseado na biometria fetal atual de
      ${
        this.laudo.idadeGestacionalBiometriaFetalSemanas
          ? this.laudo.idadeGestacionalBiometriaFetalSemanas
          : ''
      }
      ${
        this.laudo.idadeGestacionalBiometriaFetalSemanas
          ? this.laudo.idadeGestacionalBiometriaFetalSemanas > 1
            ? ' semanas'
            : ' semana'
          : ''
      }${
        this.laudo.idadeGestacionalBiometriaFetalSemanas &&
        this.laudo.idadeGestacionalBiometriaFetalDias
          ? ' e '
          : ''
      }${
        this.laudo.idadeGestacionalBiometriaFetalDias
          ? this.laudo.idadeGestacionalBiometriaFetalDias
          : ''
      }${
        this.laudo.idadeGestacionalBiometriaFetalDias
          ? this.laudo.idadeGestacionalBiometriaFetalDias > 1
            ? ' dias'
            : ' dia'
          : ''
      }${this.laudo.compativelIncompativel ? ', ' : ''}${
        this.laudo.compativelIncompativel
          ? this.laudo.compativelIncompativel
          : ''
      }`);
    if (this.laudo.dataProvavelParto)
      folha.push(`Data provável do parto: ${this.dataFormatada(this.laudo.dataProvavelParto)}.`);
    if (this.laudo.crescimentoFetalUm)
      folha.push(`${this.laudo.crescimentoFetalUm}`);
    if (this.laudo.exameLimitado) folha.push(`${this.laudo.exameLimitado}`);
    if (this.laudo.comentariosAdcionaisQuatro)
      folha.push(` ${this.laudo.comentariosAdcionaisQuatro}`);

    folha.push(`espaço`);
    folha.push(`espaço`);
    folha.push(`espaço`);
    if (this.laudo.camposExibidos.exibeobs == true) folha.push(`obs`);

    if (folha.length > 0) folha.splice(0, 0, 'Conclusão');
    this.conclusao = [...folha];

    const todos = [
      ...this.estatica,
      ...this.vitalidade,
      ...this.biometriaFetal,
      ...this.morfologiaFetal,
      ...this.abdomen,
      ...this.placenta,
      ...this.cordaoUmbilical,
      ...this.conclusao,
    ];

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 32));
    }
    if (this.laudo.coloUterino)
      folha2.push(
        `Colo uterino ${this.laudo.coloUterino}, com menor comprimento de  ${this.laudo.coloMedidaEmRepouco} mm (colo avaliado por três minutos).`
      );

    // if (this.laudo.coloPassouAMedir)
    //   folha2.push(
    //     `Após três minutos de avaliação, o colo ${this.laudo.coloPassouAMedir} ${this.laudo.cmdois} cm.`
    //   );

    if (
      this.laudo.orificioCervicalInterno &&
      this.laudo.orificioCervicalInterno == 'aberto'
    )
      folha2.push(
        ` Orifício cervical interno ${this.laudo.orificioCervicalInterno} com ${this.laudo.orificioCervicalInterno2} mm.`
      );
      if (
      this.laudo.orificioCervicalInterno &&
      this.laudo.orificioCervicalInterno != 'aberto'
    )
      folha2.push(
        ` Orifício cervical interno ${this.laudo.orificioCervicalInterno}.`
      );

    if (this.laudo.protusaoBolsaAmniotica)
      folha2.push(
        `${this.laudo.protusaoBolsaAmniotica} de protrusão da bolsa amniótica no momento do exame.`
      );
    if (this.laudo.sinalDeSludge)
      folha2.push(`Sinal de sludge ${this.laudo.sinalDeSludge}.`);

    if (
      this.laudo.polipoEndocervicalMedindo &&
      this.laudo.polipoEndocervicalMedindo ==
        'Observamos pólipo endocervical medindo'
    )
      folha2.push(
        `${this.laudo.polipoEndocervicalMedindo} ${this.laudo.polipoEndocervicalMedindoCm} mm.`
      );
    if (folha2.length > 0) folha2.splice(0, 0, '');
    this.coloUterinoz = [...folha2];
    folha2 = [];

    if (this.laudo.coloUterino2)
      folha2.push(`Colo uterino ${this.laudo.coloUterino2}.`);

    if (this.laudo.comentarioAdcionalPerfilCervical)
      folha2.push(`${this.laudo.comentarioAdcionalPerfilCervical}.`);
    if (folha2.length > 0) folha2.splice(0, 0, 'Conclusão');
    this.conclusao = [...folha2];
    folha2 = [];

    const todos2 = [...this.coloUterinoz, ...this.conclusao];

    while (todos2.length > 0) {
      this.tamanhoElemento2.push(todos2.splice(0, 30));
    }
  },

  data() {
    return {
      estatica: [],
      vitalidade: [],
      biometriaFetal: [],
      morfologiaFetal: [],
      abdomen: [],
      via: '',
      placenta: [],
      cordaoUmbilical: [],
      conclusao: [],
      tamanhoElemento: [],
      tamanhoElemento2: [],
      titulos: [
        'Estática',
        'Vitalidade',
        'Biometria fetal',
        'Abdômen',
        'Morfologia fetal',
        'Placenta',
        'Cordão umbilical',
        'Conclusão',
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    text-align: justify;
    margin-left: 0px;
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  div {
    margin-left: 0px;
  }

  h4 {
    text-align: justify;
    margin-left: 0px;
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    font-family: 'Calibri';
    margin-left: 0px;

    margin-top: 1x;
    padding: 2px;
  }
  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }
  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    margin-left: 0px;
    line-height: 5px;
  }
  .div-duas-span{
    display: flex; margin-top: -5px; margin-bottom: 3px;
  }
  strong {
  }
  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
