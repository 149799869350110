<template lang="">
  <div>
    <LaudoNeurosonografia></LaudoNeurosonografia>
  </div>
</template>
<script>
import LaudoNeurosonografia from '../components/laudos/formularios/exames/neurosonografia/LaudoNeurosonografia.vue';
export default {
  components: {
    LaudoNeurosonografia,
  },
};
</script>
<style lang=""></style>
