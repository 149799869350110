<template>
  <v-dialog
    v-model="show"
    transition="dialog-top-transition"
    width="900px"
    persistent
  >
    <v-card class="px-6 py-6 d-flex flex-column align-center">
      <v-icon class="pa-3 align-self-start ml-auto" @click="close">
        mdi-close-circle-outline
      </v-icon>
      <h2 class="pb-8 text-center">INSERIR/ALTERAR CONTAS</h2>

      <v-row class="flex-grow-1 justify-center">
        <v-col cols="6" md="6">
          <v-text-field
            class="maiuscula"
            label="Título"
            validate-on-blur
            filled
            outlined
            required
            v-model="dividaSelecionada.titulo"
            :rules="[value => !!value || 'Campo obrigatório']"
          />
        </v-col>
        <v-col cols="6" md="3">
          <v-text-field
            class="maiuscula"
            label="Valor (R$)"
            required
            validate-on-blur
            filled
            outlined
            @change="formatarValor"
            v-model="dividaSelecionada.valor"
            :rules="[value => !!value || 'Campo obrigatório']"
          />
        </v-col>
        <v-col v-if="repetirLancamento" cols="6" md="3">
          <v-text-field
            class="maiuscula"
            label="Dia vencimento"
            required
            validate-on-blur
            filled
            outlined
            v-mask="['##']"
            v-model="dividaSelecionada.diaVencimento"
            :rules="[
              value =>
                validaDiaVencimento(value) ||
                'Digite um número válido entre 1 e 31',
            ]"
          />
        </v-col>
        <v-col cols="2" md="2">
          <v-select
            v-if="!repetirLancamento"
            class="maiuscula"
            label="Pago"
            :items="pago"
            validate-on-blur
            
            filled
            outlined
            v-model="dividaSelecionada.pago"
            :rules="[value => !!value || 'Campo obrigatório']"
          />
        </v-col>
        <v-col cols="2" md="3">
          <v-select
            v-if="!repetirLancamento"
            class="maiuscula"
            label="Tipo de pagamento"
            validate-on-blur
            filled
            outlined
            :items="tipoPagamento"
            v-model="dividaSelecionada.tipoPagamento"
            :rules="[value => !!value || 'Campo obrigatório']"
          />
        </v-col>
        <v-col cols="2" md="4">
          <v-text-field
            v-if="!repetirLancamento"
            class="maiuscula"
            label="Data de Vencimento"
            validate-on-blur
            filled
            outlined
            type="date"
            v-model="dividaSelecionada.dataVencimento"
            :rules="[value => !!value || 'Campo obrigatório']"
          />
        </v-col>
        <v-col cols="2" md="4">
          <v-text-field
            v-if="!repetirLancamento"
            class="maiuscula"
            label="Data de Pagamento"
            validate-on-blur
            filled
            type="date"
            outlined
            v-model="dividaSelecionada.dataPagamento"
            :rules="[value => !!value || 'Campo obrigatório']"
          />
        </v-col>
        <v-col class="ml-12">
          <v-checkbox
            v-model="repetirLancamento"
            class="d-flex justify-content-start align-items-center mt-n5"
            label="Conta fixa"
            validate-on-blur
          ></v-checkbox>
        </v-col>
      </v-row>

      <br />
      <v-row v-if="!repetirLancamento && !dividaSelecionada.id" class="mt-auto">
        <v-btn class="ml-auto" color="primary" @click="salvarConta"
          >SALVAR CONTA</v-btn
        >
      </v-row>
      <v-row v-if="repetirLancamento && !dividaSelecionada.id" class="mt-auto">
        <v-btn class="ml-auto" color="primary" @click="salvarContaFixa"
          >SALVAR CONTA</v-btn
        >
      </v-row>
      <v-row v-if="dividaSelecionada.id" class="mt-auto">
        <v-btn class="ml-auto" color="primary" @click="editarConta"
          >SALVAR EDIÇÃO</v-btn
        >
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
import ConsultasMixin from '@/mixins/ConsultasMixin';
import PagamentosMixin from '@/mixins/PagamentosMixin';
import moment from 'moment';
export default {
  mixins: [ConsultasMixin, PagamentosMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modelo: {
      usuario: null,
      senha: null,
    },

    
    repetirLancamento: false,
    tipoPagamento: ['À VISTA', 'PIX', 'APORTE'],
    pago: ['NÃO','SIM'],

    modal: {
      diaVencimento: null,
    },

    modal: {
      titulo: '',
      pago: '',
      dataPagamento: '',
      dataVencimento: '',
      tipoPagamento: '',
      valor: '',
    },
  }),
  computed: {
    ...mapGetters(['dividaSelecionada']),

    dividaSelecionada() {
      return this.$store.getters['Financeiro/dividaSelecionada'] || {};
    },
  },

  methods: {
    ...mapActions('Auth', ['login']),
    close() {
      this.$emit('close');
    },
    async editarConta() {
      this.patchAtualizarConta(this.dividaSelecionada).then((resp)=>{
        if (resp.status === 200) {
            alert('CONTA ATUALIZADA');
            this.dividaSelecionada.tipoPagamento = '';
            this.dividaSelecionada.dataPagamento = '';
            this.dividaSelecionada.titulo = '';
            this.dividaSelecionada.pago = [];
            this.dividaSelecionada.valor = '';
            this.close();
          }
        })
        .catch(() => {
          alert('CONTA NÃO EDITADA, POR FAVOR VERIFICAR');
        });

     
    },
    async salvarConta() {
      this.dividaSelecionada.dataVencimento = moment(this.dividaSelecionada.dataVencimento).format('YYYY-MM-DD');
      this.dividaSelecionada.dataPagamento = moment(this.dividaSelecionada.dataPagamento).format('YYYY-MM-DD');
      await this.postContasAPagar(this.dividaSelecionada)

        .then(resp => {
          if (resp.status === 200) {
            alert('CONTA CADASTRADA');
            this.dividaSelecionada.tipoPagamento = '';
            this.dividaSelecionada.dataPagamento = '';
            this.dividaSelecionada.titulo = '';
            this.dividaSelecionada.pago = [];
            this.dividaSelecionada.valor = '';
            this.close();
          }
        })
        .catch(() => {
          alert('CONTA NÃO SALVA, POR FAVOR VERIFICAR');
        });
    },
    async salvarContaFixa() {
      this.dividaSelecionada.nomeConta = this.dividaSelecionada.titulo;
      delete this.dividaSelecionada.dataPagamento;
      delete this.dividaSelecionada.dataVencimento;
      delete this.dividaSelecionada.pago;
      delete this.dividaSelecionada.tipoPagamento;

      await this.postEnviaContaFixa(this.dividaSelecionada)
        .then(resp => {
          if (resp.status === 200) {
            alert('CONTA FIXA CADASTRADA');

            const diaVencimentoString =
              this.dividaSelecionada.diaVencimento.toString();

            this.dividaSelecionada.valor = '';
            this.close();
          }
        })
        .catch(() => {
          alert('CONTA FIXA NÃO SALVA, POR FAVOR VERIFICAR');
        });
    },

    formatarValor() {
      let valorFormatado = parseFloat(
        this.dividaSelecionada.valor.replace(/[^\d.]/g, '')
      ).toFixed(2);
      this.dividaSelecionada.valor = valorFormatado;
    },
    validaDiaVencimento(value) {
      const numero = parseInt(value, 10);

      if (isNaN(numero) || numero < 1 || numero > 31) {
        return 'Digite um número válido entre 1 e 31';
      }

      return true;
    },
  },
  mounted() {},
};
</script>

<style></style>
