<template lang="">
  <div>
    <LaudoTransvaginalUteroComTridimensional></LaudoTransvaginalUteroComTridimensional>
  </div>
</template>
<script>
import LaudoTransvaginalUteroComTridimensional from '../components/laudos/formularios/exames/transvaginalUteroComTridimensional/LaudoTransvaginalUteroComTridimensional.vue';
export default {
  components: {
    LaudoTransvaginalUteroComTridimensional,
  },
};
</script>
<style lang=""></style>
