import { mapActions, mapGetters } from "vuex";
import { EMBRION_API_URL } from "../data/enviroment";
export default {
  data() {
    return {
      urlBase: EMBRION_API_URL,
    };
  },
  computed: {
    ...mapGetters(["metricas"]),
    ...mapGetters(["tempoMedio"]),
  },
  methods: {
    ...mapActions({
      getMetricas: "getMetricas",
      getTempoMedio: "getTempoMedio",
    }),
  },
};
