<template lang="">
  <div>
    <v-container fluid style="max-width: 95%" :key="componentKey">
      <v-card class="pa-5 mt-5">
        <v-row>
          <v-col cols="6" md="6">
            <div class="px-3">
              <div class="pb-3">
                <h3>Dados do paciente</h3>
              </div>
              <h4>
                Nome:
                <span class="fonte">{{
                  this.consultaSelecionada.paciente.nome
                }}</span>
              </h4>
              <h4>
                Idade:
                <span class="fonte">{{ this.idade }}</span>
              </h4>
              <h4>
                Data de nascimento:
                <span class="fonte">{{
                  this.consultaSelecionada.paciente.dataNascimento
                }}</span>
              </h4>
            </div>
          </v-col>
          <v-col cols="6" md="6">
            <div class="px-3">
              <div class="pb-3">
                <h3>Dados da consulta</h3>
              </div>
              <h4>
                Data:
                <span class="fonte"> {{ this.consultaSelecionada.data }}</span>
              </h4>
              <h4>
                Médico:
                <span class="fonte">
                  {{ this.consultaSelecionada.medicoExecutante.nome }}</span
                >
              </h4>
              <!-- <h4>
                Exame:
                <span class="fonte"> </span>
              </h4> -->
              <div class="pt-3">
                <h4>
                  Médico solicitante:
                  <span class="fonte maiuscula">
                  
                    {{
                      this.medicoSolicitante(
                        consultaTeste
                      )
                    }}</span
                  >
                </h4>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <!-- <v-row class="d-flex justify-center pt-4">
        <v-col cols="12" md="12" sm="12" class="">
          <v-select
            class="pa-0 ma-0"
            v-on:blur=""
            :items="examesDaConsulta"
            filled
            outlined
            label="Exame"
            @click.stop
            clearable
            @change="handleExameChange"
          ></v-select>
        </v-col>
      </v-row> -->

      <!-- <template router :to="''"></template> -->
    </v-container>
  </div>
</template>
<script>
import ConsultasMixin from '../../../mixins/ConsultasMixin';
import PacienteMixin from '../../../mixins/PacienteMixin';
import { mapActions, mapGetters } from 'vuex';
export default {
  mixins: [ConsultasMixin, PacienteMixin],
  data() {
    return {
      idade: null,
      componentKey: 0,
      link: [],
    };
  },
  mounted() {
    this.idadePaciente();
    
    
  },
  computed: {
    ...mapGetters(['consultaSelecionada']),

    consultaTeste() {
      let med = this.$store.getters['consultaSelecionada'].medicoSolicitante
      let resultado
      if( typeof med === 'object'){
        resultado = med.nome
      }else{
        resultado = med
      }
      
      return resultado;
    },
  }
  ,

  methods: {
    handleExameChange(nome) {
      if (nome == 'TRANSLUCÊNCIA NUCAL ') {
        this.linkExame = '/translucenciaNucal';
      }

      if (nome == 'PÉLVICA TRANSVAGINAL COM DOPPLER E PREPARO INTESTINAL') {
        this.linkExame = '/pelvicaTransvaginalDopplerPreparoIntestinal';
      }

      if (nome == 'MORFOLOGICA') {
        this.linkExame = '/morfologica';
      }

      if (nome == 'PÉLVICA TRANSABDOMINAL') {
        this.linkExame = '/pelvicaTransabdominal';
      }

      if (nome == 'TRANSVAGINAL HISTERECTOMIA') {
        this.linkExame = '/transvaginalHisterectomia';
      }

      if (nome == 'ECOCARDIOGRAMA') {
        this.linkExame = '/ecocardiodopplerFetal';
      }

      if (nome == 'TRANSVAGINAL') {
        this.linkExame = '/transvaginalUtero';
      }
      if (nome == 'TRANSVAGINAL COM DOPPLER') {
        this.linkExame = '/transvaginalUteroComDoppler';
      }
      if (nome == 'TRANSVAGINAL TRIDIMENSIONAL') {
        this.linkExame = '/transvaginalUteroComTridimensional';
      }
      if (nome == 'TRANSVAGINAL PARA AVALIAÇÃO DE RESERVA OVARIANA') {
        this.linkExame = '/transvaginalUteroParaAvaliacaoDeReservaOvariana';
      }
      if (nome == 'TRANSVAGINAL PARA PESQUISA DE ENDOMETRIOSE') {
        this.linkExame = '/transvaginalUteroParaPesquisaDeEndometriose';
      }
      if (nome == 'AMNIOCENTESE') {
        this.linkExame = '/amniocentese';
      }
      if (nome == 'PESSÁRIO') {
        this.linkExame = '/pessario';
      }
      if (nome == 'NEUROSONOGRAFIA') {
        this.linkExame = '/neurosonografia';
      }
      if (nome == 'PÉLVICA') {
        this.linkExame = '/pelvica';
      }
      if (nome == 'SERIADA') {
        this.linkExame = '/seriada';
      }
      if (nome == 'VILO CORIAL') {
        this.linkExame = '/viloCorial';
      }
      if (nome == 'RETORNO') {
        this.linkExame = '/retorno';
      }
      if (nome == 'INSERCAO DE DIU GUIADO POR TRANSVAGINAL') {
        this.linkExame = '/insercaoDeDiuGuiadoPorTransvaginal';
      }
      if (nome == 'PERFIL BIOFÍSICO FETAL') {
        this.linkExame = '/perfilBiofisicoFetal';
      }

      if (nome == 'PERFIL CERVICAL') {
        this.linkExame = '/perfilCervical';
      }

      if (nome == 'LAUDO PERSONALIZADO') {
        this.linkExame = '/personalizado';
      }

      if (nome == 'LAUDO CONSULTA GINECOLOGICA') {
        this.linkExame = '/consultaGinecologica';
      }

      if (nome == 'DOPPLER') {
        this.linkExame = '/doppler';
      }

      if (nome == 'MAMA') {
        this.linkExame = '/mama';
      }

      if (nome == 'AXILA') {
        this.linkExame = 'axila';
      }

      if (nome == 'AVALIAÇÃO MORFOLÓGICA DO PRIMEIRO TRIMESTRE') {
        this.linkExame = 'morfologica1Trimestre';
      }

      if (nome == 'OBSTETRICA 1ª TRIMESTRE') {
        this.linkExame = 'obstetrica1Trimestre';
      }
      if (nome == 'OBSTETRICA') {
        this.linkExame = 'obstetrica';
      }
      if (nome == 'OBSTÉTRICA COM DOPPLER') {
        this.linkExame = 'obstetricaDoppler';
      }
      if (nome == 'OBSTÉTRICA COM PERFIL CERVICAL') {
        this.linkExame = 'obstetricaPerfilCervical';
      }
      if (nome == 'OBSTÉTRICA COM DOPPLER E PERFIL CERVICAL') {
        this.linkExame = 'obstetricaDopplerPerfilCervical';
      }
    },

    forceRerender() {
      this.componentKey++;
    },

    idadePaciente() {
      if (this.consultaSelecionada.paciente.dataNascimento != undefined) {
        let idade = this.consultaSelecionada.paciente.dataNascimento.split('/');
        this.idade = this.calculaIdade(idade[2], idade[1], idade[0]);
        return this.calculaIdade(idade[2], idade[1], idade[0]);
      }
    },
    medicoSolicitante(val) {
      if (val == 'null') {
        return;
      } else {
        return val;
      }
    },

    calculaIdade(ano_aniversario, mes_aniversario, dia_aniversario) {
      const hoje = new Date();
      const nascimento = new Date(
        ano_aniversario,
        mes_aniversario,
        dia_aniversario
      );

      var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
      if (
        new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
        new Date(
          hoje.getFullYear(),
          nascimento.getMonth(),
          nascimento.getDate()
        )
      )
        diferencaAnos--;
      return diferencaAnos;
    },
  },
};
</script>
<style lang=""></style>
