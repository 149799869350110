<template>
  <v-container fluid style="max-width: 100%" class="px-0">
    <v-row class="d-flex mt-2 justify-space-between" dense>
      <v-col cols="12" md="12" sm="12" class="pa-0">
        <v-select
          :items="nomesMedicosExecutantes"
          label="Selecione um médico"
          clear-icon="$clear"
          clearable
          filled
          outlined
          @change="handleMedicoChange"
          v-model="medicoSelect"
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-title
                :id="attrs['aria-labelledby']"
                v-text="item"
              ></v-list-item-title>
            </v-list-item>
          </template>
          
          <template v-slot:item.calories="{ item }">
            <v-chip dark>
              {{ item }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-card class="grey lighten-4 pa-0 ma-0">
      <v-container class="fluid grey lighten-4 pa-0 ma-0">
        <v-row v-if="dataSelecionadaBloqueada">
          <v-col class="text-center">
            <span>
              Data bloqueada, por favor desbloqueie antes para marcar uma
              consulta
            </span>
          </v-col>
        </v-row>
        <v-data-table
          v-else
          class="grey lighten-4 pt-2 px-0 clique maiuscula"
          height="450px"
          hide-default-footer
          :headers="headersTable"
          :items="consultasSelecionadas"
          :items-per-page="-1"
          dense
          v-bind="attrs"
          v-on="on"
          @click:row="handleClick"
        >
          <template v-slot:item.hora="{ item }">
            {{ item.hora }}
            <!-- <span >&nbsp;&nbsp; gemelar</span> -->
            <v-chip
              class="ma-2"
              color="green"
              style="font-size: 10px; width: 70px; height: 25px"
              v-if="item.gemelar === 'true'"
            >
              Gemelar
            </v-chip>
          </template>
          <template v-slot:item.convenio="{ item }">
            
            <div v-if="item.nome !== ''">

            
            <span v-if="item.pagamento.formaDePagamento == 'Cortesia'">
              cortesia
            </span>
            <span v-else>
              <!-- {{ item.exames[0].valorExameConvenio.convenio.nome }} -->
              {{ extrairNomesConvenio(item.exames).join(', ') }}
            </span>
          </div>
          <div v-else>
            {{ item.convenio }}
            

          </div>
           
            
          </template>
          <template v-slot:item.exames="{ item }">
            <!-- {{ item.exames }}
            {{ typeof item.exames }} -->
            <!-- <abbr
              v-for="(exame, index) in item.exames"
              :title="`${exame}`"
              :key="index"
            >
              <span v-if="item.exames.length == 1">
                {{ exame }}
              </span>
              <span v-else>
                {{ getSigla(exame) }}
                <span v-if="item.exames.length != index + 1">/</span>
              </span>
            </abbr> CODIGO REFERENTE AS SIGLAS DOS EXAMES-->
            <span
              v-for="(exame, index) in item.exames"
              :title="`${exame}`"
              :key="index"
            >
              {{ exame.valorExameConvenio.exame.nome
}} <br />
            </span>
          </template>

          <template
            v-slot:item.action="{ item }"
            v-if="valorStatus === 'status'"
          >
            <v-dialog max-width="600" scrollasable>
              <template #activator="{ on, attrs }">
                <v-btn
                  x-small
                  depressed
                  plain
                  v-bind="attrs"
                  v-on="on"
                  router
                  :to="''"
                  @click="
                    setConsultaSelecionada(item);
                    modal.active = true;
                  "
                  ><v-icon class="">mdi-square-edit-outline</v-icon></v-btn
                >
              </template>
              <component
                :is="modal.modalComponent"
                v-bind="{ show: modal.active }"
                @close="closeModal"
              />
            </v-dialog>
          </template>

          <template v-slot:item.status="{ item }">
            <v-chip
              v-if="item.status || item.statusPagamento"
              :color="getColor(item.status, item.statusPagamento)"
              small
              style="width: 75px"
              width="50px"
              class="tamanho"
            >
              <span style="font-size: 11px">{{ item.status }}</span>
            </v-chip>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <v-row>
      <v-col cols="12" md="12" sm="12" class="py-5 px-0">
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="700"
          v-model="dialog"
        >
          <template v-slot:activator="{ on, attrs }"> </template>
          <template v-slot:default="dialog">
            <v-card style="overflow: hidden">
              <span class="d-flex justify-end">
                <v-icon
                  class="pa-3"
                  router
                  :to="'/recepcao'"
                  @click="dialog.value = false"
                  >mdi-close-circle-outline</v-icon
                >
              </span>
              <v-card-text>
                <div class="text-h5 pb-2 pt-2 text-center font-weight-bold">
                  Novo Agendamento:
                </div>
                <v-row class="d-flex justify-center pt-6">
                  <v-col cols="12" md="8" sm="12">
                    <VAutocomplete
                      ref="pacientesAgendamento"
                      label="Paciente"
                      :items="listaPacientes"
                      item-text="nome"
                      item-value="id"
                      :search-input.sync="searchPaciente"
                      filled
                      outlined
                      clearable
                      :disabled="loading"
                      :loading="loading"
                      v-model="selected"
                    />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="justify-center pb-12">
                <v-btn
                  text
                  depressed
                  class="grey darken-1 grey--text text--lighten-4 text-center mx-2 pa-5 font-weight-bold"
                  width="430px"
                  @click="redirecionaNovoCadastro"
                  v-if="mostrarBotaoNovoCadastro"
                  :disabled="loading"
                  large
                >
                  Novo Cadastro
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>

    <LoadingDialog :loading="loadingDialog" />
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import LoadingDialog from '../LoadingDialog.vue';
import axios from 'axios';
import ConsultasMixin from '@mixins/ConsultasMixin';
import MedicoMixin from '@mixins/MedicoMixin';
import CalendarioMixin from '@mixins/CalendarioMixin';
import PacienteMixin from '@mixins/PacienteMixin';
import MetricasMixin from '@mixins/MetricasMixin';
import ModalAlteraStatus from '@components/consultas/formularios/ModalAlteraStatus.vue';

export default {
  components: { LoadingDialog },
  mixins: [
    ConsultasMixin,
    MedicoMixin,
    CalendarioMixin,
    PacienteMixin,
    MetricasMixin,
  ],
  data() {
    return {
      pacienteAgendamento: false,
      searchPaciente: '',
      expanded: [],
      singleExpand: false,
      pacienteParaAgendamento: {},
      valorStatus: '',
      status: ['Desistente', 'Ausente', 'Em espera', 'Atendido'],
      avisos: [
        'Olá! Faltam 30 minutos para o seu exame.',
        'Olá! Você já pode se encaminhar para a clínica.',
        'Olá! Você está atrasado para o seu exame.',
        'Confirmar comparecimento.',
      ],
      dialog: false,
      loadingDialog: false,
      modal: {
        active: false,
        modalComponent: ModalAlteraStatus,
      },
      mostraModalNovoStatus: false,
      on: '',
      attrs: '',
      selected: null,
      medicoSelect: null,
      loading: false,
    };
  },
  watch: {
    '$store.state.calendario.dataSelecionada': function () {
      this.loadingDialog = true;
      if (this.medicoSelecionado.id != undefined)
        this.handleMedicoChange(this.medicoSelecionado.nome);
      this.getMetricas({
        data: this.dataSelecionada,
        medicoId: this.medicoSelecionado.id,
      });
      
      this.getdatasBloqueadas(this);
      this.loadingDialog = false;
    },
    selected(value) {
      if (!value) return;
      this.irParaNovaConsulta(value);
    },
    searchPaciente(value) {
      if (!value) return;
      this.searchPaciente = value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
  },
  computed: {
    ...mapGetters('Auth', ['usuarioLogado'], ['headers']),
    ...mapGetters('Calendario', ['dataSelecionadaBloqueada']),
    headersTable() {
      return [
        { text: 'Horário', value: 'hora', width: '20%' },
        { text: 'Médico', value: 'medico.nome', align: ' d-none' },
        {
          text: 'Exame',
          value: 'exames',
          width: '25%',
        },
        { text: 'Convênio', value: 'convenio', width: '18%' ,sortable: false },
        { text: 'Paciente', value: 'paciente.nome', width: '45%' },
        { text: 'Status', value: this.valorStatus, width: '15%' },
        { text: '', value: 'action', sortable: false },
      ];
    },
    dataSelecionadaBloqueada() {
      return this.$store.getters['Calendario/dataSelecionadaBloqueada'];
    },
    mostrarBotaoNovoCadastro() {
      let temPacidente = false;

      if (this.$refs.pacientesAgendamento) {
        temPacidente =
          this.$refs.pacientesAgendamento.filteredItems.length !== 0;
      }

      return !this.selected || !temPacidente;
    },
  },
  methods: {
    ...mapActions('Consulta', ['patchConsulta']),
    getSigla(value) {
      if (value === 'TRANSLUCÊNCIA NUCAL') {
        return 'TN';
      }
      if (value === 'DOPPLER') {
        return 'DP';
      }
      if (value === 'PERFIL CERVICAL') {
        return 'PC';
      }
      if (value === 'COLO UTERINO') {
        return 'CUT';
      }
      if (value === 'AVALIAÇÃO MORFOLÓGICA DO PRIMEIRO TRIMESTRE') {
        return 'M1T';
      }

      return value;
    },
    extrairNomesConvenio(lista) {
      const nomesConvenio = [];
      
      lista.forEach(item => {
        const nomeConvenio = item.valorExameConvenio.convenio.nome;
        
        if (!nomesConvenio.includes(nomeConvenio)) {
          nomesConvenio.push(nomeConvenio);
        }
      });
      
      return nomesConvenio;
    },
    handleMedicoChange(nome) {
      this.loadingDialog = true;

      if (nome === null || nome === undefined) {
        this.resetaMedicoSelecionado();
        return;
      }
      let medico = this.getMedicoByNome(nome);
      this.setMedicoSelecionado(medico);
      if(medico.id){
      this.getConsultaByDataMedico({
        medicoId: medico.id,
        data: this.dataSelecionada,
      }).then(() => {
        this.adicionaEspacos();
        this.loadingDialog = false;
        this.getdatasBloqueadas(this);
        this.getListaNomesPacientes()
      }).catch(()=>{
        alert("Erro: atualize a página")
      });
      // this.getConsultaByDataMedico(medico.id, this.dataSelecionada).then(() =>
      //   this.adicionaEspacos()
      
      this.getMetricas({
        data: this.dataSelecionada,
        medicoId: this.medicoSelecionado.id,
      });
    }
  },
    closeModal() {
      this.modal.active = false;
    },
    // se você buscar um paciente que não está cadastrado, o botão para cadastrar o paciente aparece
    handleBotaoCadastro() {
      const size = this.$refs.pacientesAgendamento.filteredItems.length;
      this.pacienteAgendamento = size == 0;
    },
    getHoraAtual() {
      let data = new Date();
      let hora = data.getHours(); // 0-23
      let min = data.getMinutes(); // 0-59
      let seg = data.getSeconds(); // 0-59
      let mseg = data.getMilliseconds(); // 0-999
      return `${hora}:${min}:${seg}`;
    },
    async irParaNovaConsulta(pacienteID) {
      this.loading = true;
      await this.getPacienteById(pacienteID);
      this.redirecionaParaAgendamento();
    },
    // seleciona o paciente de acordo com o paciente selecionado no v-select
    handlePacienteChange(pacienteNome) {
      this.setPacienteByNome(pacienteNome).then(() => {
        if (this.pacienteById.id != undefined) {
          this.getPacienteById(this.pacienteById.id);
          this.redirecionaParaAgendamento();
        }
      });
    },
    async enviarHorarioAoMudarStatus(status) {
      const agora = moment().format('HH:mm:ss');
      const consultaID = this.consultaSelecionada.id;

      if (status === 'em espera') {
        this.patchConsulta({
          consultaID,
          path: 'horaChegada',
          value: agora,
        });
        return;
      }

      if (status === 'atendido') {
        this.patchConsulta({
          consultaID,
          path: 'horaSaida',
          value: agora,
        });
      }
    },
    async alterarStatusEHorario() {
      try {
        await this.alterarStatus();

        const status = this.consultaSelecionada.status.toLowerCase();
        await this.enviarHorarioAoMudarStatus(status);

        this.getMetricas({
          data: this.dataSelecionada,
          medicoId: this.usuarioLogado.loginId,
        });

        // Fazer algo se deu sucesso
        this.mostraModalNovoStatus = false;

        if (status === 'Desistente' || status === 'DESISTENTE') {
          const teste = await this.deleteConsulta(this.consultaSelecionada.id);
        }
      } catch (error) {
        // Exibir popup de erro
      } finally {
        await this.getConsultaByDataMedico({
          data: this.dataSelecionada,
          medicoId: this.usuarioLogado.loginId,
        });
        this.adicionaEspacos();
      }
    },
    // verifica o nome da rota que você está e faz uma mudança no status exibido de acordo com a rota
    currentRouteName() {
      if (this.$route.name === 'HomeRecepcao') {
        this.valorStatus = 'status';
      } else {
        this.valorStatus = 'statusPagamento';
      }
    },
    getColor(status, statusPagamento) {
      if (this.valorStatus === 'status') {
        return this.getColorStatus(status);
      } else if (this.valorStatus === 'statusPagamento') {
        return this.getColorStatusPagamento(statusPagamento);
      }
    },
    getColorStatusPagamento: function (statusPagamento) {
      if (statusPagamento === 'Pago') {
        return 'green accent-2';
      } else if (statusPagamento === 'Em análise') {
        return 'orange accent-2';
      } else if (statusPagamento === 'Pendente') {
        return 'red accent-2';
      }
    },
    getColorStatus: function (status) {
      if (status === 'Atendido' || status === 'ATENDIDO') {
        return 'green accent-2';
      } else if (status === 'Em espera' || status === 'EM ESPERA') {
        return 'deep-orange accent-2';
      } else if (status === 'Em atendimento' || status === 'EM ATENDIMENTO') {
        return 'amber accent-2';
      } else if (status === 'Ausente' || status === 'AUSENTE') {
        return 'red accent-2';
      } else if (status === 'Desistente' || status === 'DESISTENTE') {
        return 'blue-grey lighten-2';
      } else if (status === 'Laudado' || status === 'LAUDADO') {
        return 'blue darken-2';
      }
    },
    async handleClick(event) {
     
      event.consultaId = event.id 
      
      event.data = moment(event.data, ).format('DD-MM-YYYY')
     
      if (event.id == undefined) {
        this.pacienteParaAgendamento = event;
        this.dialog = true;
        this.pacienteAgendamento = true;
      } else if (this.$route.name == 'HomePagamento') {
        this.$router.push({
          name: 'Agendamento',
          query: {
            id: event.id,
            pagamento: true,
          },
        });
      } else {
        // await this.getPacienteById(80);

        await this.setConsultaSelecionada(event);
        this.$router.push({ name: 'Agendamento', query: { id: event.id } });
      }
    },
    setSelected(event) {
      this.setPacienteByNome(event);
    },
    redirecionaParaAgendamento() {
      this.loading = false;

      this.$router.push({
        name: 'Agendamento',
        query: {
          hora: this.pacienteParaAgendamento.hora,
          medico: this.medicoSelecionado.id,
          data: this.dataSelecionada,
          paciente: this.selected,
        },
      });
    },
    redirecionaNovoCadastro() {
      this.$router.push(
        `/cadastrar-paciente/?hora=${this.pacienteParaAgendamento.hora}&medico=${this.medicoSelecionado.id}&data=${this.dataSelecionada}`
      );
    },
    async getTeste() {
      let resp = await axios.get(`${this.urlBase}Convenio`, {
        headers: this.headers,
      });
      return resp;
    },
    async verificaLogin() {
      try {
        let resp = await axios.get(`${this.urlBase}Convenio`, {
          headers: this.headers,
        });
      } catch (e) {
        this.redirecionaLogin();

        return;
      }
    },
    redirecionaLogin() {
      this.$router.push('/');
    },
  },
  beforeMount() {
    this.currentRouteName();
  },
  async mounted() {
    this.getPacientes();
    this.getNomesPacientes();
   
    await this.verificaLogin();
  },
  created() {
    const data = moment().format('DD/MM/YYYY');
   

    this.getNomesMedicosExecutanteSolicitante().then(() => {
      //this.medicoSelect = this.nomesMedicosExecutantes[0];
      if (!!this.medicoSelecionado.nome) {
        this.medicoSelect = this.medicoSelecionado.nome;
        this.handleMedicoChange(this.medicoSelecionado.nome);
      } else {
        this.medicoSelect = this.nomesMedicosExecutantes[0];
        this.handleMedicoChange(this.nomesMedicosExecutantes[0]);
      }
    });
    try {
      this.getConsultaByDataMedico({
        medicoId: this.medicoSelecionado.id ? this.medicoSelecionado.id : 1,
        data,
      }).then(() => this.adicionaEspacos());
    } catch {
      this.adicionaEspacos();
    }
  },
  beforeDestroy() {
    //this.resetaMedicoSelecionado();
  },
};
</script>
<style lang="scss" scoped>
.v-data-table::v-deep td {
  font-size: 12px !important;
}
</style>
