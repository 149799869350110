const initialState = () => ({
  consultaPagamentos: {},
  consultasPagamentos: [],
  consultasDividas: [],
  valorTotalDia: '',
  valoresReceber: [],
  totalValoresReceber: '',
  contaSelecionada: {},
  pagamentosProducao: [],
  valorTotalProd: '',
  dividaSelecionada: {},
});

const RESET_STORE = 'RESET_STORE';
const SET_CONSULTA_PAGAMENTOS = 'SET_CONSULTA_PAGAMENTOS';
const SET_CONSULTAS_PAGAMENTOS = 'SET_CONSULTAS_PAGAMENTOS';
const SET_CONSULTAS_DIVIDAS = 'SET_CONSULTAS_DIVIDAS';
const SET_VALOR_TOTAL = 'SET_VALOR_TOTAL';
const SET_VALORES_RECEBER = 'SET_VALORES_RECEBER';
const SET_TOTAL_VALORES_RECEBER = 'SET_TOTAL_VALORES_RECEBER'
const RESET_CONTA_SELECIONADA = 'RESET_CONTA_SELECIONADA'
const SET_CONTA_SELECIONADA = 'SET_CONTA_SELECIONADA'
const SET_PAGAMENTOS_PRODUCAO = 'SET_PAGAMENTOS_PRODUCAO'
const SET_VALOR_TOTAL_PROD = 'SET_VALOR_TOTAL_PROD'

const RESET_DIVIDA_SELECIONADA = 'RESET_DIVIDA_SELECIONADA';

const SET_DIVIDA_SELECIONADA = 'SET_DIVIDA_SELECIONADA';
export default {
  namespaced: true,
  state: initialState(),
  getters: {
    consultaPagamentos: state => state.consultaPagamentos,
    consultasPagamentos: state => state.consultasPagamentos,
    consultasDividas: state => state.consultasDividas,
    valorTotalDia: state => state.valorTotalDia,
    valoresReceber: state => state.valoresReceber,
    totalValoresReceber: state => state.totalValoresReceber,
    contaSelecionada: state => state.contaSelecionada,
    pagamentosProducao: state => state.pagamentosProducao,
    valorTotalProd: state => state.valorTotalProd,
    dividaSelecionada: state => state.dividaSelecionada,
  },
  mutations: {
    [RESET_STORE](state, _) {
      state.consultaPagamentos = {};
      state.consultasPagamentos = [];
      state.valorTotalDia = '';
      state.valoresReceber = [];
    },
    [RESET_CONTA_SELECIONADA](state, _) {
      state.contaSelecionada = {};
    },
    [SET_CONTA_SELECIONADA](state, payload) {
      state.contaSelecionada = { ...payload };
    },

    [SET_VALORES_RECEBER](state, payload) {
      state.valoresReceber = [...payload];
    },
    [SET_TOTAL_VALORES_RECEBER](state, payload) {
      state.totalValoresReceber = payload;
    },

    [SET_CONSULTA_PAGAMENTOS](state, payload) {
      state.consultaPagamentos = { ...payload };
    },
    [SET_CONSULTAS_PAGAMENTOS](state, payload) {
      state.consultasPagamentos = [...payload];
      state.valorTotalDia = payload.valorTotal;
    },
    [SET_CONSULTAS_DIVIDAS](state, payload) {
      state.consultasDividas = [...payload];
    },
    [SET_DIVIDA_SELECIONADA](state, payload) {
      state.dividaSelecionada = payload;
    },
    [RESET_DIVIDA_SELECIONADA](state, _) {
      state.dividaSelecionada = {};
    },
    [SET_VALOR_TOTAL](state, payload) {
      state.valorTotalDia = payload;
    },
    [SET_PAGAMENTOS_PRODUCAO](state, payload) {
      state.pagamentosProducao = [...payload];
      
    },
    [SET_VALOR_TOTAL_PROD](state, payload) {
      state.valorTotalProd = payload;
    },
    
  },
  actions: {
    async getRequisicao({ commit, rootState }) {
      const { token } = rootState.Auth.usuarioLogado;
      const { data } = await PacienteEmbrion.getRequisicao({ token });
      commit(SET_PACIENTES, data);
    },
    // async getDados({ commit, rootState }) {
    //   const { token } = rootState.Auth.usuarioLogado;
    //   const { data } = await PacienteEmbrion.getDados({ token });
    //   commit(SET_PACIENTES, data);
    // },
  },
};
