<template lang="">
  <div>
    <LaudoPelvica></LaudoPelvica>
  </div>
</template>
<script>
import LaudoPelvica from '../components/laudos/formularios/exames/pelvica/LaudoPelvica.vue';
export default {
  components: {
    LaudoPelvica,
  },
};
</script>
<style lang=""></style>
