<template>
  <v-row class="pa-0 ma-0" no-gutters>
    <v-col :cols="cols" :md="md">
      <v-row>
        <v-col cols="12" md="12">
          <FormularioPaciente
            :exibePaciente="exibePaciente"
            :exibirBotao="exibirBotao"
            :exibirMenu="exibirMenu"
            v-on:emitterexbbot="novoValor => (exibirBotao = novoValor)"
            v-on:emitterexbpac="novoValor2 => (exibePaciente = novoValor2)"
            v-on:emitterexmen="novoValor3 => (exibirMenu = novoValor3)"
          ></FormularioPaciente>
        </v-col>
      </v-row>
    </v-col>

    <template v-if="exibirMenu">
      <v-col cols="1" md="1" class="mt-8">
        <div style="position: fixed">
          <v-row>
            <v-col>
              <v-btn
                block
                class="pa-md-7 pa-2 grey darken-1"
                @click="editarPaciente"
              >
                <h2
                  class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
                >
                  EDITAR
                </h2>
                <router-view></router-view>
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="this.$route.name == !'ScreenPaciente'">
            <v-col>
              <v-btn block class="pa-md-7 pa-2 grey darken-1">
                <h2
                  class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
                >
                  HISTÓRICO CONSULTAS
                </h2>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn block class="pa-md-7 pa-2 grey darken-1">
                <h2
                  class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
                >
                  WHATSAPP
                </h2>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn block class="pa-md-7 pa-2 grey darken-1" @click="email">
                <h2
                  class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
                >
                  ENVIAR EMAIL
                </h2>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                block
                class="pa-md-7 pa-2 grey darken-1"
                @click="consultarConsultas"
              >
                <h2
                  class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
                >
                  CONSULTAS
                </h2>
              </v-btn>
              <v-dialog
                transition="dialog-bottom-transition"
                max-width="900"
                v-model="dialog"
              >
                <v-card style="overflow: hidden">
                  <span class="d-flex justify-end">
                    <v-icon class="pa-3" @click="dialog = false"
                      >mdi-close-circle-outline</v-icon
                    ></span
                  >
                  <v-card-text cols="12" md="8">
                    <div class="text-h5 pb-0 pt-1 text-center font-weight-bold">
                      Consultas
                    </div>
                    <v-row class="d-flex justify-center pt-2">
                      <v-col>
                        <v-data-table
                          v-if="exibirConsultas"
                          :headers="headers1"
                          :items="consultaInfo"
                          :items-per-page="-1"
                          :loading="loadingProc"
                          class="elevation-1 maiuscula pa-0 ma-5"
                        >
                          <template v-slot:item.data="{ item }">
                            {{ formatarData(item.data) }}
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row v-if="this.$route.name == !'ScreenPaciente'">
            <v-col>
              <v-btn
                block
                class="pa-md-7 pa-2 grey darken-1"
                @click="getDeclaracao"
              >
                <h2
                  class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
                >
                  DECLARAÇÃO
                </h2>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </template>
  </v-row>
</template>
<script>
import FormularioPaciente from './formularios/FormularioPaciente.vue';
import PacienteMixin from '@mixins/PacienteMixin';
import ConsultasMixin from '@mixins/ConsultasMixin';
import { format } from 'date-fns';


export default {
  mixins: [PacienteMixin, ConsultasMixin],
  components: { FormularioPaciente },
  data() {
    return {
      exibePaciente: true,
      exibirBotao: this.$route.query.id == undefined,
      exibirMenu: true,
      exibirConsultas: false,
      consultaInfo: [],
      loadingProc: false,
      dialog: false,
      cols: '9',
      md: '9',
      headers1: [
        {
          text: 'Data',
          align: 'start',
          sortable: false,
          value: 'data',
        },
        { text: 'Hora', value: 'hora' },
        { text: 'Status', value: 'status' },
      ],
    };
  },
  watch: {
    exibirMenu(val) {
      if (this.exibirMenu) {
        this.cols = 9;
        this.md = 9;
      }
    },
  },

  methods: {
    formatarData(data) {
    if (!data) {
      return '';
    }

    // Aqui você pode especificar o formato desejado, 'dd-MM-yyyy'
    return format(new Date(data), 'DD/MM/YYYY');
  },
    async consultarConsultas() {
      this.dialog = true;
      this.exibirConsultas = true;
      this.loadingProc = true;

      const pacienteId = this.pacientes.pacienteSelecionado.id;

      try {
        const consultaInfo = await this.getConsultaByPacienteId(pacienteId);

        this.consultaInfo = consultaInfo;
      } catch (error) {
        console.error('Erro ao consultar consultas:', error);
      } finally {
        this.loadingProc = false;
      }
    },

    editarPaciente() {
      this.exibePaciente = false;
      this.exibirBotao = true;
      this.exibirMenu = false;
      (this.cols = '12'), (this.md = '12');
    },
    currentRouteName() {
      if (this.$route.name === 'CadastrarPaciente') {
        this.exibirMenu = false;
        this.exibePaciente = false;
        (this.cols = '12'), (this.md = '12');
      }
    },
    email() {
      this.$router.push(`/email?email=${this.pacienteById.email}`);
    },
    colunas() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          this.cols = 12;
        case 'sm':
          this.cols = 12;
      }
    },
  },
  mounted() {
    this.currentRouteName();
    this.colunas();
  },
};
</script>
