<template>
  <nav class="navbar">
    <v-app-bar flat app class="cyan darken-2" v-if="appBar">
      <v-app-bar-nav-icon class="white--text" @click="drawer = !drawer">
      </v-app-bar-nav-icon>
      <h1 class="white--text pl-3">{{ areaNome }}</h1>
      <v-spacer></v-spacer>
      <v-select
        v-if="this.isAdmin"
        @change="mudaArea"
        v-model="areaSelecionada"
        :items="permissao"
        label="Áreas"
        class="mt-3 pl-3 mx-0 flex-grow-0"
        style="text-align: center; width: 200px"
        dense
      >
      </v-select>
      <h1 class="white--text pl-3">{{ data }}</h1>
    </v-app-bar>

    <v-navigation-drawer
      rail
      app
      class="cyan darken-2 height"
      :width="drawer ? 50 : 300"
      fixed
    >
      <!-- <header class="py-4 px-8">
        <h1 class="py-3 text-h5 font-weight-bold white--text text--darken-3">
          {{ areaNome }}
        </h1>
        <v-avatar color="black lighten-1" size="80">FOTO</v-avatar>
        -provavelmente vai sair esse avatar
      </header> -->

      <v-list class="ml-n5" @click="drawer = !drawer">
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6 ml-2 white--text text-center">
              <!-- {{ areaNome }} -->
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="link in links"
          @click="checkRouteChange(link.route)"
          :key="link.text"
          

        >
        <!-- router
          :to="link.route"
          link -->
          <v-list-item link>
            <abbr :title="link.text">
              <v-list-item-icon>
                <v-icon color="white">mdi-{{ link.icon }}</v-icon>
              </v-list-item-icon>
            </abbr>

            <v-list-item-title class="text-h6 white--text texte-darken-6">
              {{ link.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item>

        <!-- <v-list density="compact" nav>
          <v-list-item  v-for="link in links" :key="link.text" :prepend-icon="link.icon" :title="link.text" :value="link.text"></v-list-item>
        </v-list> -->
        <!--
        <div class="py-4">
          <v-btn
            small
            depressed
            class="grey lighten-2 black--text texte-darken-3"
            @click="sair"
            router
            :to="''"
          >
            <v-icon>mdi-logout</v-icon>
            <span>Sair</span>
          </v-btn>
        </div> -->
      </v-list>
      <template v-slot:append>
        <v-list class="ml-0">
          <v-list-item link @click="sair">
            <v-list-item-icon @click="sair">
              <v-icon color="white">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              @click="sair"
              class="text-h6 white--text texte-darken-6"
            >
              Sair
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <!-- <div class="pa-2">
            <v-btn
            small
            depressed
            class="grey lighten-2 black--text texte-darken-3"
            @click="sair"
            router
            :to="''"
          >
            <v-icon>mdi-logout</v-icon>
            <span>Sair</span>
          </v-btn>
          </div> -->
      </template>
    </v-navigation-drawer>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import MedicoMixin from '../../mixins/MedicoMixin';

export default {
  mixins: [MedicoMixin],
  data() {
    return {
      links: [],
      drawer: false,
      data: '',
      appBar: true,
      areaSelecionada: '',
      areaNome: '',
      permissao: [
        {
          text: 'Financeiro',
          value: 3,
        },
        {
          text: 'Recepção',
          value: 1,
        },
        {
          text: 'Médico',
          value: 5,
        },
        // {
        //   text: 'Digitador',
        //   value: 4,
        // },
      ],
    };
  },
  computed: {
    ...mapGetters('Auth', ['estaAutenticado', 'nivelPermissao', 'isAdmin']),
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 200;
        case 'sm':
          return 200;
        case 'md':
          return 250;
        case 'lg':
          return 300;
      }
    },
    // nivelPermissao() {
    //   return this.nivelPermissaoAtual;
    // },
    tamanho: 300,
  },
  watch: {
    '$store.state.Auth.nivelPermissao': function () {
      this.nomesMenu();
    },

    areaSelecionada(newValue) {
      if (!this.permissao.find(item => item.value === newValue)) {
        // Se o valor não estiver em permissao, defina-o de volta para o valor padrão (neste caso, 2 para "Médico")
        this.areaSelecionada = 5;
      }
    },
  },
  created() {
    let permissao = this.nivelPermissao == 5 ? 3 : this.nivelPermissao;

    this.areaSelecionada = this.permissao[permissao].value;

    //  if (!this.areaSelecionada && this.permissao.length > 0) {
    //    this.areaSelecionada = 2;
  },
  methods: {
    sair() {
      this.$router.push('/');

      this.appBar = false;
      this.drawer = false;
      this.resetaMedicoSelecionado();
      this.$store.commit('Auth/RESET_ALL');
    },

    mudaArea() {
      this.$store.commit('Auth/SET_NIVEL_PERMISSAO', this.areaSelecionada);
      this.nomesMenu();

      // Encontre a rota correspondente à área selecionada
      const newRoute = this.links.find(
        link => link.text === this.areaNome
      )?.route;

      if (newRoute) {
        this.$router.push(newRoute);
      }
    },
    async checkRouteChange(route) {
      
      if(this.$router.app._route.fullPath.includes("/laudo/")){
        try {
        const confirmNavigation = await this.confirmNavigation();
      if (confirmNavigation) {
        this.drawer = !this.drawer; 
        this.$router.push(route); 
      
      }
      else{
       
        return
      }
        
      } catch (error) {
       
        
      }finally{

      }

      }else{
        this.$router.push(route); 
      }
      
      
      // Se não houver confirmação, a navegação será cancelada.
    },
    confirmNavigation() {
      return new Promise((resolve) => {
        const confirmMessage = "Deseja realmente trocar de menu?";
        const userConfirmed = window.confirm(confirmMessage);
        resolve(userConfirmed);
      });
    },


    nomesMenu() {
      if (this.nivelPermissao == 1) {
        this.areaNome = 'RECEPÇÃO';
        this.links = [
          { text: 'Ínicio', route: '/recepcao', icon: 'home' },
          {
            text: 'Pacientes',
            route: '/pacientes',
            icon: 'account-injury-outline',
          },
          // { text: "Laudos", route: "/laudos" },
          // { text: 'Pagamentos', route: '/pagamento', icon: 'cash-check' },
          {
            text: 'Procedimentos',
            route: '/procedimentos',
            icon: 'clipboard-list-outline',
          },
          {
            text: 'Cadastrar usuário',
            route: '/cadastrarUsuario',
            icon: 'account-plus',
          },
        ];
        this.areaSelecionada = 1;
        this.$router.push('recepcao');
      } else if (this.nivelPermissao == 5) {
        this.areaNome = 'ÁREA MÉDICA';
        this.links = [
          { text: 'Agenda', route: '/medico', icon: 'home' },
          {
            text: 'Pacientes',
            route: '/pacientes',
            icon: 'account-injury-outline',
          },
          
          { text: 'Laudos', route: '/laudos', icon: 'note-multiple-outline' },
          {
            text: 'Produção',
            route: '/producao',
            icon: 'account-arrow-up-outline',
          },
          {
            text: 'Valores a receber',
            route: '/financeiro',
            icon: 'cash-plus',
          },
          {
            text: 'Valores a pagar',
            route: '/valoresAPagar',
            icon: 'cash-minus',
          },
          {
            text: 'Relatórios',
            route: '/relatorios',
            icon: 'chart-box-plus-outline',
          },
        ];
        this.areaSelecionada = 4;
        this.$router.push('medico');
      } else if ([2, 5].includes(this.nivelPermissao)) {
        this.areaNome = 'ÁREA MÉDICA';
        this.links = [
          { text: 'Agenda', route: '/medico', icon: 'home' },
          {
            text: 'Pacientes',
            route: '/pacientes',
            icon: 'account-injury-outline',
          },
          { text: 'Laudos', route: '/laudos', icon: 'note-multiple-outline' },
          {
            text: 'Valores a receber',
            route: '/financeiro',
            icon: 'cash-plus',
          },
          {
            text: 'Valores a pagar',
            route: '/valoresAPagar',
            icon: 'cash-minus',
          },
          {
            text: 'Relatórios',
            route: '/relatorios',
            icon: 'chart-box-plus-outline',
          },
        ];
        this.$router.push('medico');
        this.areaSelecionada = this.nivelPermissao;
      } else if (this.nivelPermissao == 3) {
        this.areaNome = 'FINANCEIRO';
        this.links = [
          {
            text: 'Valores a receber',
            route: '/financeiro',
            icon: 'cash-plus',
          },
          {
            text: 'Valores a pagar',
            route: '/valoresAPagar',
            icon: 'cash-minus',
          },
          {
            text: 'Produção',
            route: '/producao',
            icon: 'account-arrow-up-outline',
          },
         
          {
            text: 'Procedimentos',
            route: '/procedimentos',
            icon: 'clipboard-list-outline',
          },
          {
            text: 'Ocorrências',
            route: '/ocorrencias',
            icon: 'clipboard-plus',
          },
        ];
        this.areaSelecionada = 3;
        this.$router.push('financeiro');
      } else if (this.nivelPermissao == 4) {
        this.areaNome = 'ÁREA DIGITADOR';
        this.links = [
          { text: 'Agenda', route: '/medico', icon: 'home' },
          {
            text: 'Pacientes',
            route: '/pacientes',
            icon: 'account-injury-outline',
          },
          { text: 'Laudos', route: '/laudos', icon: 'note-multiple-outline' },
        ];
        this.areaSelecionada = 4;
        this.$router.push('medico');
      }
    },
    drawerCase() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          this.drawer = false;
        case 'sm':
          this.drawer = false;
        case 'md':
          this.drawer = false;
        case 'lg':
          this.drawer = true;
      }
    },
    formataData() {
      let date = new Date();
      let dia = date.getDate();
      let mes = date.getMonth();
      let ano = date.getFullYear();

      let meses = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];
      this.data = `${dia} de ${meses[mes]} de ${ano}`;
    },
  },
  mounted() {
    // let permissao = this.nivelPermissao == 5 ? 3 : this.nivelPermissao;
    // console.log(this.areaSelecionada);
    // this.areaSelecionada = this.permissao[permissao].value;
    // console.log(this.areaSelecionada);

    this.appBar = this.estaAutenticado;
    this.drawer = this.estaAutenticado;

    this.nomesMenu();
    this.formataData();
    this.drawerCase();
  },
};
</script>
<style lang="scss">
.height {
  height: 100vh;
}
</style>
