<template lang="">
  <div>
    <ListaDePacientes></ListaDePacientes>
  </div>
</template>
<script>
import ListaDePacientes from "../components/paciente/ListaDePacientes.vue";
export default {
  name: "ScreenPacientesLista",
  components: {
    ListaDePacientes,
  },
};
</script>
<style lang=""></style>
