import Http from '@services/Http';
import { EMBRION_API_URL } from '@/data/enviroment';

export default {
  async patchConsulta({ token, data, consultaID }) {
    return Http(EMBRION_API_URL, token).patch(
      `/Consulta?id=${consultaID}`,
      data
    );
  },
};
