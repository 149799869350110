<template>
  <v-container fluid>
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">
        ULTRASSONOGRAFIA PÉLVICA TRANSVAGINAL COM DOPPLER PARA PESQUISA DE
        ENDOMETRIOSE PROFUNDA
      </h2>
    </v-card>

    <v-alert
      v-if="!!laudoSelecionado.laudoJson.folhas"
      outlined
      type="warning"
      border="left"
      text=""
    >
      Esse <strong>laudo</strong> teve a impressão
      <strong>personalizada.</strong>
      <v-btn text @click="adcionarConteudoEditorPersonalizado"
        >Clique aqui para visualizar</v-btn
      >
    </v-alert>

    <v-row class="pt-5" no-gutters>
      <template v-if="clicouNoPersonalizado">
        <v-col
          cols="12"
          class="form-group"
          v-for="(index, folha) in quantidadeFolhasEditorPersonalizado"
          :key="index"
        >
          <h2>Folha {{ folha + 1 }}</h2>
          <v-btn plain small @click="removeFolha(index)" class="pl-0">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <vue-editor v-model="conteudoEditorPersonalizado[folha]" />
        </v-col>

        <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
      </template>
      <template v-else>
        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDataDum ? '' : 'text--disabled'
          }`"
        >
          <span>DUM:</span>
          <v-text-field
            v-model="laudoSelecionado.laudoJson.dataDum"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeDataDum"
            @keydown="forceReRender"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-mask="'##/##/####'"
          ></v-text-field>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeDataDum"
            @click="
              camposExibidos.exibeDataDum = true;
              laudoSelecionado.laudoJson.dataDum = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeDataDum = false;
              laudoSelecionado.laudoJson.dataDum = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeDiaCicloMenstrual ? '' : 'text--disabled'
          }`"
        >
          <v-text-field
            v-mask="''"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeDiaCicloMenstrual"
            v-model="laudoSelecionado.laudoJson.diaCicloMenstrual"
          >
          </v-text-field>
          <span>º dia do ciclo menstrual.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeDiaCicloMenstrual"
            @click="
              camposExibidos.exibeDiaCicloMenstrual = true;
              laudoSelecionado.laudoJson.diaCicloMenstrual = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeDiaCicloMenstrual = false;
              laudoSelecionado.laudoJson.diaCicloMenstrual = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeUteroPosicao ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="['Sem', 'Em']"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeUteroPosicao"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.uteroPosicao"
          >
          </v-select>
          <span>uso de contraceptivos hormonais.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeUteroPosicao"
            @click="
              camposExibidos.exibeUteroPosicao = true;
              laudoSelecionado.laudoJson.uteroPosicao = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeUteroPosicao = false;
              laudoSelecionado.laudoJson.uteroPosicao = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeUretra ? '' : 'text--disabled'
          }`"
        >
          <span>Urétra:</span>
          <v-select
            :items="[
              'retilínea',
              'sem coleções',
              'cistos periuretrais',
              'outro',
            ]"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeUretra"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.uretra"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.uretra == 'outro'"
            required
            validate-on-blur
            filled
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeUretra"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.uretraOutros"
          ></v-textarea>

          <v-select
            :items="[
              'tortuosa',
              'sem coleções',
              'cistos periuretrais',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeUretra"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.uretra2"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.uretra2 == 'outro'"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeUretra"
            validate-on-blur
            filled
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.uretra2Outros"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeUretra"
            @click="
              camposExibidos.exibeUretra = true;
              laudoSelecionado.laudoJson.uretra = undefined;
              laudoSelecionado.laudoJson.uretraOutros = undefined;
              laudoSelecionado.laudoJson.uretra2 = undefined;
              laudoSelecionado.laudoJson.uretra2Outros = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeUretra = false;
              laudoSelecionado.laudoJson.uretra = undefined;
              laudoSelecionado.laudoJson.uretraOutros = undefined;
              laudoSelecionado.laudoJson.uretra2 = undefined;
              laudoSelecionado.laudoJson.uretra2Outros = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeBexigaCheia1 ? '' : 'text--disabled'
          }`"
        >
          <span>Bexiga: cheia,</span>
          <v-select
            :items="['paredes finas', 'paredes espessadas', 'outro']"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeBexigaCheia1"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.bexigaCheia1"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.bexigaCheia1 == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeBexigaCheia1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.bexigaCheia1Outros"
          ></v-textarea>

          <span>e</span>

          <v-select
            :items="['regulares', 'irregulares', 'outro']"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeBexigaCheia1"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.bexigaCheia2"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.bexigaCheia2 == 'outro'"
            required
            validate-on-blur
            filled
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.bexigaCheia2Outros"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeBexigaCheia1"
          ></v-textarea>

          <span>com conteúdo</span>

          <v-select
            :items="['anecóico', 'anecóico com debris', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeBexigaCheia1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.bexigaCheia3"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.bexigaCheia3 == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeBexigaCheia1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.bexigaChei32Outros"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeBexigaCheia1"
            @click="
              camposExibidos.exibeBexigaCheia1 = true;
              laudoSelecionado.laudoJson.bexigaCheia1 = undefined;
              laudoSelecionado.laudoJson.bexigaCheia1Outros = undefined;
              laudoSelecionado.laudoJson.bexigaCheia2 = undefined;
              laudoSelecionado.laudoJson.bexigaCheia2Outros = undefined;
              laudoSelecionado.laudoJson.bexigaCheia3 = undefined;
              laudoSelecionado.laudoJson.bexigaChei32Outros = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeBexigaCheia1 = false;
              laudoSelecionado.laudoJson.bexigaCheia1 = undefined;
              laudoSelecionado.laudoJson.bexigaCheia1Outros = undefined;
              laudoSelecionado.laudoJson.bexigaCheia2 = undefined;
              laudoSelecionado.laudoJson.bexigaCheia2Outros = undefined;
              laudoSelecionado.laudoJson.bexigaCheia3 = undefined;
              laudoSelecionado.laudoJson.bexigaChei32Outros = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span
            :class="`d-flex justify-start align-center ${
              camposExibidos.exibeVagina1new ? '' : 'text--disabled'
            }`"
            >Vagina:</span
          >
          <v-select
            :items="[
              'com características ecográficas habituais',
              'com paredes espessadas',
              'outro',
            ]"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeVagina1new"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.vagina1"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.vagina1 == 'outro'"
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeVagina1new"
            filled
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.vagina1Outros"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeVagina1new"
            @click="
              camposExibidos.exibeVagina1new = true;
              laudoSelecionado.laudoJson.vagina1 = undefined;
              laudoSelecionado.laudoJson.vagina1Outros = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeVagina1new = false;
              laudoSelecionado.laudoJson.vagina1 = undefined;
              laudoSelecionado.laudoJson.vagina1Outros = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span>,</span>

          <v-select
            :items="['sem evidências de cistos', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeVagina1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.vagina2"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.vagina2 == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeVagina1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.vagina2Outros"
          ></v-textarea>
          <span>.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeVagina1"
            @click="camposExibidos.exibeVagina1 = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="camposExibidos.exibeVagina1 = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span
            :class="` ${
              camposExibidos.exibeColoUterino1new ? '' : 'text--disabled'
            }`"
            >Colo uterino:</span
          >
          <v-select
            :items="['antevertido', 'retrovertido', 'outro']"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino1new"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloUterino1"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.coloUterino1 == 'outro'"
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino1new"
            filled
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.coloUterino1Outros"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeColoUterino1new"
            @click="
              camposExibidos.exibeColoUterino1new = true;
              laudoSelecionado.laudoJson.coloUterino1 = undefined;
              laudoSelecionado.laudoJson.coloUterino1Outros = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeColoUterino1new = false;
              laudoSelecionado.laudoJson.coloUterino1 = undefined;
              laudoSelecionado.laudoJson.coloUterino1Outros = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span>,</span>

          <v-select
            :items="[
              'com forma normal',
              'aumentado de volume',
              'reduzido de volume por conização prévia',
              'outro',
            ]"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino1"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloUterino2"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.coloUterino2 == 'outro'"
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino1"
            filled
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.coloUterino2Outros"
          ></v-textarea>

          <span
            :class="` ${
              camposExibidos.exibeColoUterino1 ? '' : 'text--disabled'
            }`"
            >e canal endocervical</span
          >

          <v-select
            :items="[
              'sem alterações ecográficas',
              'apresentando imagem hiperecóica com pedículo vascular medindo',
              'outro',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino1"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.canalEndocervical"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.canalEndocervical == 'outro'"
            required
            validate-on-blur
            filled
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino1"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.canalEndocervicalOutros"
          ></v-textarea>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.canalEndocervical ==
              'apresentando imagem hiperecóica com pedículo vascular medindo'
            "
            required
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoUterino1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.canalEndocervicalMedindo"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.canalEndocervical ==
              'apresentando imagem hiperecóica com pedículo vascular medindo'
            "
            >cm.</span
          >
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeColoUterino1"
            @click="
              camposExibidos.exibeColoUterino1 = true;
              laudoSelecionado.laudoJson.coloUterino2 = undefined;
              laudoSelecionado.laudoJson.coloUterino2Outros = undefined;
              laudoSelecionado.laudoJson.canalEndocervical = undefined;
              laudoSelecionado.laudoJson.canalEndocervicalMedindo = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeColoUterino1 = false;
              laudoSelecionado.laudoJson.coloUterino2 = undefined;
              laudoSelecionado.laudoJson.coloUterino2Outros = undefined;
              laudoSelecionado.laudoJson.canalEndocervical = undefined;
              laudoSelecionado.laudoJson.canalEndocervicalMedindo = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeCorpoUterino1 ? '' : 'text--disabled'
          }`"
        >
          <span>Corpo uterino:</span>
          <v-select
            :items="[
              'centralizado na pelve',
              'desviado para a direita',
              'desviado para a esquerda',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeCorpoUterino1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.corpoUterino1"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.corpoUterino1 == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeCorpoUterino1"
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.corpoUterino1Outros"
          ></v-textarea>

          <span>em</span>

          <v-select
            :items="['anteflexão', 'medioversão', 'retroversão', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeCorpoUterino1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.corpoUterino2"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.corpoUterino2 == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeCorpoUterino1"
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.corpoUterino2Outros"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeCorpoUterino1"
            @click="
              camposExibidos.exibeCorpoUterino1 = true;

              laudoSelecionado.laudoJson.corpoUterino1 = undefined;
              laudoSelecionado.laudoJson.corpoUterino1Outros = undefined;
              laudoSelecionado.laudoJson.corpoUterino2 = undefined;
              laudoSelecionado.laudoJson.corpoUterino2Outros = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeCorpoUterino1 = false;
              laudoSelecionado.laudoJson.corpoUterino1 = undefined;
              laudoSelecionado.laudoJson.corpoUterino1Outros = undefined;
              laudoSelecionado.laudoJson.corpoUterino2 = undefined;
              laudoSelecionado.laudoJson.corpoUterino2Outros = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeFormaColoUterino ? '' : 'text--disabled'
          }`"
        >
          <span>A forma é</span>
          <v-select
            :items="['típica', 'globosa', 'outro']"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeFormaColoUterino"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.formaColoUterino"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.formaColoUterino == 'outro'"
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeFormaColoUterino"
            filled
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.formaColoUterinoOutros"
          ></v-textarea>

          <span>e os contornos</span>

          <v-select
            :items="['regulares', 'irregulares', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeFormaColoUterino"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.contornoColoUterino"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.contornoColoUterino == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeFormaColoUterino"
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.contornoColoUterinoOutro"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeFormaColoUterino"
            @click="
              camposExibidos.exibeFormaColoUterino = true;
              laudoSelecionado.laudoJson.formaColoUterino = undefined;
              laudoSelecionado.laudoJson.formaColoUterinoOutros = undefined;
              laudoSelecionado.laudoJson.contornoColoUterino = undefined;
              laudoSelecionado.laudoJson.contornoColoUterinoOutro = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeFormaColoUterino = false;
              laudoSelecionado.laudoJson.formaColoUterino = undefined;
              laudoSelecionado.laudoJson.formaColoUterinoOutros = undefined;
              laudoSelecionado.laudoJson.contornoColoUterino = undefined;
              laudoSelecionado.laudoJson.contornoColoUterinoOutro = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span
            :class="`${
              camposExibidos.exibeParedeColoUterinoNew ? '' : 'text--disabled'
            }`"
            >As paredes são</span
          >
          <v-select
            :items="[
              'simétricas',
              'assimétricas com aumento da parede posterior',
              'assimétricas com aumento da parede anterior',
              'outro',
            ]"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeParedeColoUterinoNew"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.paredeColoUterino"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.paredeColoUterino == 'outro'"
            required
            validate-on-blur
            filled
            outlined
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeParedeColoUterinoNew"
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.paredeColoUterinoOutros"
          ></v-textarea>

          <span
            :class="`${
              camposExibidos.exibeParedeColoUterinoNew ? '' : 'text--disabled'
            }`"
            >e o miométrio apresenta zona juncional</span
          >

          <v-select
            :items="['regular', 'irregular', 'outro']"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeParedeColoUterinoNew"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.zonaJuncional"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.zonaJuncional == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeParedeColoUterinoNew"
            outlined
            no-resize
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.zonaJuncionalOutros"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeParedeColoUterinoNew"
            @click="
              camposExibidos.exibeParedeColoUterinoNew = true;
              laudoSelecionado.laudoJson.paredeColoUterino = undefined;
              laudoSelecionado.laudoJson.paredeColoUterinoOutros = undefined;
              laudoSelecionado.laudoJson.zonaJuncional = undefined;
              laudoSelecionado.laudoJson.zonaJuncionalOutros = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeParedeColoUterinoNew = false;
              laudoSelecionado.laudoJson.paredeColoUterino = undefined;
              laudoSelecionado.laudoJson.paredeColoUterinoOutros = undefined;
              laudoSelecionado.laudoJson.zonaJuncional = undefined;
              laudoSelecionado.laudoJson.zonaJuncionalOutros = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span
            :class="`${
              camposExibidos.exibeParedeColoUterino ? '' : 'text--disabled'
            }`"
            >,com textura miometrial
          </span>
          <v-select
            :items="['homogênea', 'difusamente heterogênea', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeParedeColoUterino"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.texturaMiometrial"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.texturaMiometrial == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeParedeColoUterino"
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.texturaMiometrialOutros"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeParedeColoUterino"
            @click="
              camposExibidos.exibeParedeColoUterino = true;
              laudoSelecionado.laudoJson.texturaMiometrial = undefined;
              laudoSelecionado.laudoJson.texturaMiometrialOutros = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeParedeColoUterino = false;
              laudoSelecionado.laudoJson.texturaMiometrial = undefined;
              laudoSelecionado.laudoJson.texturaMiometrialOutros = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Biometria uterina:</h4>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeBiometriaUterinaUtero1 ? '' : 'text--disabled'
          }`"
        >
          <span>Útero:</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeBiometriaUterinaUtero1"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.biometriaUterinaUtero1"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeBiometriaUterinaUtero1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.biometriaUterinaUtero2"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeBiometriaUterinaUtero1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.biometriaUterinaUtero3"
          >
          </v-text-field>
          <span>cm e volume de</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeBiometriaUterinaUtero1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.biometriaUterinaUtero4"
          >
          </v-text-field>
          <span>cm³.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeBiometriaUterinaUtero1"
            @click="
              camposExibidos.exibeBiometriaUterinaUtero1 = true;
              laudoSelecionado.laudoJson.biometriaUterinaUtero1 = undefined;
              laudoSelecionado.laudoJson.biometriaUterinaUtero2 = undefined;
              laudoSelecionado.laudoJson.biometriaUterinaUtero3 = undefined;
              laudoSelecionado.laudoJson.biometriaUterinaUtero4 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeBiometriaUterinaUtero1 = false;
              laudoSelecionado.laudoJson.biometriaUterinaUtero1 = undefined;
              laudoSelecionado.laudoJson.biometriaUterinaUtero2 = undefined;
              laudoSelecionado.laudoJson.biometriaUterinaUtero3 = undefined;
              laudoSelecionado.laudoJson.biometriaUterinaUtero4 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeFundoCm ? '' : 'text--disabled'
          }`"
        >
          <span>Fundo:</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeFundoCm"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.fundoCm"
          >
          </v-text-field>
          <span>cm.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeFundoCm"
            @click="
              camposExibidos.exibeFundoCm = true;
              laudoSelecionado.laudoJson.fundoCm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeFundoCm = false;
              laudoSelecionado.laudoJson.fundoCm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeCavidadeUterinaCm ? '' : 'text--disabled'
          }`"
        >
          <span>Cavidade uterina:</span>
          <v-text-field
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeCavidadeUterinaCm"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.cavidadeUterinaCm"
          >
          </v-text-field>
          <span>cm.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeCavidadeUterinaCm"
            @click="
              camposExibidos.exibeCavidadeUterinaCm = true;
              laudoSelecionado.laudoJson.cavidadeUterinaCm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeCavidadeUterinaCm = false;
              laudoSelecionado.laudoJson.cavidadeUterinaCm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeColoCm ? '' : 'text--disabled'
          }`"
        >
          <span>Colo:</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeColoCm"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloCm"
          >
          </v-text-field>
          <span>cm.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeColoCm"
            @click="
              camposExibidos.exibeColoCm = true;
              laudoSelecionado.laudoJson.coloCm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeColoCm = false;
              laudoSelecionado.laudoJson.coloCm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span
            :class="`${
              camposExibidos.exibeEcoEndometrial1 ? '' : 'text--disabled'
            }`"
            >O eco endometrial é</span
          >
          <v-select
            :items="['hipoecóico', 'hiperecóico', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeEcoEndometrial1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ecoEndometrial"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ecoEndometrial == 'outro'"
            required
            validate-on-blur
            :clearable="podeEditar"
            filled
            outlined
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeEcoEndometrial1"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ecoEndometrialOutros"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeEcoEndometrial1"
            @click="
              camposExibidos.exibeEcoEndometrial1 = true;
              laudoSelecionado.laudoJson.ecoEndometrial = undefined;
              laudoSelecionado.laudoJson.ecoEndometrialOutros = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeEcoEndometrial1 = false;
              laudoSelecionado.laudoJson.ecoEndometrial = undefined;
              laudoSelecionado.laudoJson.ecoEndometrialOutros = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span>,</span>

          <v-select
            :items="['uniforme', 'disforme', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeEcoEndometrial2"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ecoEndometrial2"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ecoEndometrial2 == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeEcoEndometrial2"
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ecoEndometrial2Outros2"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeEcoEndometrial2"
            @click="
              camposExibidos.exibeEcoEndometrial2 = true;
              laudoSelecionado.laudoJson.ecoEndometrial2 = undefined;
              laudoSelecionado.laudoJson.ecoEndometrial2Outros2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeEcoEndometrial2 = false;
              laudoSelecionado.laudoJson.ecoEndometrial2 = undefined;
              laudoSelecionado.laudoJson.ecoEndometrial2Outros2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span
            :class="`${
              camposExibidos.exibeEcoEndometrial ? '' : 'text--disabled'
            }`"
            >,com linha média</span
          >
          <v-select
            :items="['linear', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeEcoEndometrial"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.linhaMedia"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.linhaMedia == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeEcoEndometrial"
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.linhaMediaOutros"
          ></v-textarea>

          <span
            :class="`${
              camposExibidos.exibeEcoEndometrial ? '' : 'text--disabled'
            }`"
            >e junção endométrio-miométrio
          </span>
          <v-select
            :items="['regular', 'irregular']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeEcoEndometrial"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.contornoColoUterino2"
          >
          </v-select>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeEcoEndometrial"
            @click="
              camposExibidos.exibeEcoEndometrial = true;
              laudoSelecionado.laudoJson.linhaMedia = undefined;
              laudoSelecionado.laudoJson.linhaMediaOutros = undefined;
              laudoSelecionado.laudoJson.contornoColoUterino2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeEcoEndometrial = false;
              laudoSelecionado.laudoJson.linhaMedia = undefined;
              laudoSelecionado.laudoJson.linhaMediaOutros = undefined;
              laudoSelecionado.laudoJson.contornoColoUterino2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeEspessuraEndometrialMm ? '' : 'text--disabled'
          }`"
        >
          <span>Espessura endometrial de</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeEspessuraEndometrialMm"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.espessuraEndometrialMm"
          >
          </v-text-field>
          <span>mm.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeEspessuraEndometrialMm"
            @click="
              camposExibidos.exibeEspessuraEndometrialMm = true;
              laudoSelecionado.laudoJson.espessuraEndometrialMm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeEspessuraEndometrialMm = false;
              laudoSelecionado.laudoJson.espessuraEndometrialMm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeCavidadeUterina ? '' : 'text--disabled'
          }`"
        >
          <span>Cavidade uterina:</span>
          <v-select
            :items="[
              'ausência de conteúdo intracavitário',
              'líquido intracavitário em pequena quantidade',
              'líquido intracavitário em grande quantidade',
              'conteúdo heterogêneo intracavitário em pequena quantidade',
              'conteúdo heterogêneo intracavitário em grande quantidade',
              'imagem hiperecóica medindo',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeCavidadeUterina"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.cavidadeUterina"
          >
          </v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.cavidadeUterina ===
              'Imagem hiperecóica medindo'
            "
            required
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeCavidadeUterina"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoCabecaNadegas"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.cavidadeUterina ==
              'Imagem hiperecóica medindo'
            "
            >cm, apresentando pedículo vascular</span
          >

          <v-textarea
            v-if="laudoSelecionado.laudoJson.cavidadeUterina == 'outro'"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeCavidadeUterina"
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ecoEndometrial2Outros"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeCavidadeUterina"
            @click="
              camposExibidos.exibeCavidadeUterina = true;
              laudoSelecionado.laudoJson.cavidadeUterina = undefined;
              laudoSelecionado.laudoJson.comprimentoCabecaNadegas = undefined;
              laudoSelecionado.laudoJson.ecoEndometrial2Outros = undefined;
              laudoSelecionado.laudoJson.cavidadeUterina = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeCavidadeUterina = false;
              laudoSelecionado.laudoJson.cavidadeUterina = undefined;
              laudoSelecionado.laudoJson.comprimentoCabecaNadegas = undefined;
              laudoSelecionado.laudoJson.ecoEndometrial2Outros = undefined;
              laudoSelecionado.laudoJson.cavidadeUterina = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Ovários</h4>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span
            :class="`${
              camposExibidos.exibeOvarioDireito1new ? '' : 'text--disabled'
            }`"
            >Ovário direito:</span
          >
          <v-select
            :items="[
              'não visualizado',
              'localizado em região parauterina',
              'localizado em região retrouterina',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOvarioDireito1new"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioDireito1"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioDireito1 == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOvarioDireito1new"
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ovarioDireito1Outro"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeOvarioDireito1new"
            @click="
              camposExibidos.exibeOvarioDireito1new = true;
              laudoSelecionado.laudoJson.ovarioDireito1 = undefined;
              laudoSelecionado.laudoJson.ovarioDireito1Outro = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeOvarioDireito1new = false;
              laudoSelecionado.laudoJson.ovarioDireito1 = undefined;
              laudoSelecionado.laudoJson.ovarioDireito1Outro = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span>,</span>
          <v-select
            :items="['a forma é típica e os limites bem definidos', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOvarioDireito1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioDireito2Outro"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioDireito2Outro == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOvarioDireito1"
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ovarioDireito3Outro"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeOvarioDireito1"
            @click="
              camposExibidos.exibeOvarioDireito1 = true;
              laudoSelecionado.laudoJson.ovarioDireito2Outro = undefined;
              laudoSelecionado.laudoJson.ovarioDireito3Outro = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeOvarioDireito1 = false;
              laudoSelecionado.laudoJson.ovarioDireito2Outro = undefined;
              laudoSelecionado.laudoJson.ovarioDireito3Outro = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeImagemAnecoica ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'O parênquima exibe ecogenicidade habitual',
              'O parênquima  tem ecotextura difusamente heterogênea',
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo',
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo',
              'Apresenta imagem anecóica com debris, medindo',
              'Apresenta imagem anecóica multiseptada medindo',
              'Apresenta imagem heterogênea medindo',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemAnecoica"
          >
          </v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemAnecoicaCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
            >cm no seu maior diâmetro</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.finoSeptoCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
            >cm no seu maior diâmetro</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem anecóica com debris, medindo'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaDebrisCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem anecóica com debris, medindo'
            "
            >cm no seu maior diâmetro</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
            required
            v-mask="''"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-model="laudoSelecionado.laudoJson.anecoicaMultiseptadaCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
            >cm no seu maior diâmetro</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem heterogênea medindo'
            "
            required
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaMultiseptadaCm2"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem heterogênea medindo'
            "
            >x</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem heterogênea medindo'
            "
            required
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica ==
              'Apresenta imagem heterogênea medindo'
            "
            >cm</span
          >

          <v-textarea
            v-if="laudoSelecionado.laudoJson.imagemAnecoica == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica"
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaOutro"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeImagemAnecoica"
            @click="
              camposExibidos.exibeImagemAnecoica = true;
              laudoSelecionado.laudoJson.imagemAnecoica = undefined;
              laudoSelecionado.laudoJson.imagemAnecoicaCm = undefined;
              laudoSelecionado.laudoJson.finoSeptoCm = undefined;
              laudoSelecionado.laudoJson.anecoicaDebrisCm = undefined;
              laudoSelecionado.laudoJson.anecoicaMultiseptadaCm = undefined;
              laudoSelecionado.laudoJson.anecoicaMultiseptadaCm2 = undefined;
              laudoSelecionado.laudoJson.imagemHeterogeneaCm = undefined;
              laudoSelecionado.laudoJson.imagemHeterogeneaOutro = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeImagemAnecoica = false;
              laudoSelecionado.laudoJson.imagemAnecoica = undefined;
              laudoSelecionado.laudoJson.imagemAnecoica = undefined;
              laudoSelecionado.laudoJson.imagemAnecoicaCm = undefined;
              laudoSelecionado.laudoJson.finoSeptoCm = undefined;
              laudoSelecionado.laudoJson.anecoicaDebrisCm = undefined;
              laudoSelecionado.laudoJson.anecoicaMultiseptadaCm = undefined;
              laudoSelecionado.laudoJson.anecoicaMultiseptadaCm2 = undefined;
              laudoSelecionado.laudoJson.imagemHeterogeneaCm = undefined;
              laudoSelecionado.laudoJson.imagemHeterogeneaOutro = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeMedidasOvarioDireito1 ? '' : 'text--disabled'
          }`"
        >
          <span>Medidas do ovário direito:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMedidasOvarioDireito1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito1"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMedidasOvarioDireito1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito2"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMedidasOvarioDireito1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito3"
          >
          </v-text-field>
          <span>cm e volume de </span>

          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMedidasOvarioDireito1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.volumeOvarioDireito"
          >
          </v-text-field>
          <span>cm³.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeMedidasOvarioDireito1"
            @click="
              camposExibidos.exibeMedidasOvarioDireito1 = true;
              laudoSelecionado.laudoJson.medidasOvarioDireito1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito3 = undefined;
              laudoSelecionado.laudoJson.volumeOvarioDireito = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeMedidasOvarioDireito1 = false;
              laudoSelecionado.laudoJson.medidasOvarioDireito1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito3 = undefined;
              laudoSelecionado.laudoJson.volumeOvarioDireito = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span
            :class="`${
              camposExibidos.exibeOvarioEsquerdoNew ? '' : 'text--disabled'
            }`"
            >Ovário esquerdo:</span
          >
          <v-select
            :items="[
              'não visualizado',
              'localizado em região parauterina',
              'localizado em região retrouterina',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOvarioEsquerdoNew"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdo"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioEsquerdo == 'outro'"
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOvarioEsquerdoNew"
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdoOutros"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeOvarioEsquerdoNew"
            @click="
              camposExibidos.exibeOvarioEsquerdoNew = true;
              laudoSelecionado.laudoJson.ovarioEsquerdo = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdoOutros = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeOvarioEsquerdoNew = false;
              laudoSelecionado.laudoJson.ovarioEsquerdo = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdoOutros = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span>,</span>
          <v-select
            :items="['a forma é típica e os limites bem definidos', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOvarioEsquerdo"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdo2"
          >
          </v-select>
          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioEsquerdo2 == 'outro'"
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeOvarioEsquerdo"
            filled
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdoOutros2"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeOvarioEsquerdo"
            @click="
              camposExibidos.exibeOvarioEsquerdo = true;
              laudoSelecionado.laudoJson.ovarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdoOutros2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeOvarioEsquerdo = false;
              laudoSelecionado.laudoJson.ovarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdoOutros2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeImagemAnecoica2 ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'O parênquima exibe ecogenicidade habitual',
              'O parênquima  tem ecotextura difusamente heterogênea',
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo',
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo',
              'Apresenta imagem anecóica com debris, medindo',
              'Apresenta imagem anecóica multiseptada medindo',
              'Apresenta imagem heterogênea medindo',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica2"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemAnecoica2"
          >
          </v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica2"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemAnecoicaCm2"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
            >cm no seu maior diâmetro</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica2"
            v-mask="''"
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-model="laudoSelecionado.laudoJson.finoSeptoCm2"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
            >cm no seu maior diâmetro</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem anecóica com debris, medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica2"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaDebrisCm2"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem anecóica com debris, medindo'
            "
            >cm no seu maior diâmetro</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica2"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaMultiseptadaCm21"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
            >cm no seu maior diâmetro</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem heterogênea medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica2"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaMultiseptadaCm22"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem heterogênea medindo'
            "
            >x</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem heterogênea medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica2"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaCm2"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica2 ==
              'Apresenta imagem heterogênea medindo'
            "
            >cm</span
          >

          <v-textarea
            v-if="laudoSelecionado.laudoJson.imagemAnecoica2 == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeImagemAnecoica2"
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaOutro2"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeImagemAnecoica2"
            @click="
              camposExibidos.exibeImagemAnecoica2 = true;
              laudoSelecionado.laudoJson.imagemAnecoica2 = undefined;
              laudoSelecionado.laudoJson.imagemAnecoica2 = undefined;
              laudoSelecionado.laudoJson.imagemAnecoicaCm2 = undefined;
              laudoSelecionado.laudoJson.finoSeptoCm2 = undefined;
              laudoSelecionado.laudoJson.anecoicaDebrisCm2 = undefined;
              laudoSelecionado.laudoJson.anecoicaMultiseptadaCm21 = undefined;
              laudoSelecionado.laudoJson.anecoicaMultiseptadaCm22 = undefined;
              laudoSelecionado.laudoJson.imagemHeterogeneaCm2 = undefined;
              laudoSelecionado.laudoJson.imagemHeterogeneaOutro2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeImagemAnecoica2 = false;
              laudoSelecionado.laudoJson.imagemAnecoica2 = undefined;
              laudoSelecionado.laudoJson.imagemAnecoica2 = undefined;
              laudoSelecionado.laudoJson.imagemAnecoicaCm2 = undefined;
              laudoSelecionado.laudoJson.finoSeptoCm2 = undefined;
              laudoSelecionado.laudoJson.anecoicaDebrisCm2 = undefined;
              laudoSelecionado.laudoJson.anecoicaMultiseptadaCm21 = undefined;
              laudoSelecionado.laudoJson.anecoicaMultiseptadaCm22 = undefined;
              laudoSelecionado.laudoJson.imagemHeterogeneaCm2 = undefined;
              laudoSelecionado.laudoJson.imagemHeterogeneaOutro2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeMedidasOvarioEsquerdo1 ? '' : 'text--disabled'
          }`"
        >
          <span>Medidas do ovário esquerdo:</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMedidasOvarioEsquerdo1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo1"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            required
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMedidasOvarioEsquerdo1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo2"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            required
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMedidasOvarioEsquerdo1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo3"
          >
          </v-text-field>
          <span>cm e volume de </span>

          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMedidasOvarioEsquerdo1"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.volumeOvarioEsquerdo"
          >
          </v-text-field>
          <span>cm³.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeMedidasOvarioEsquerdo1"
            @click="
              camposExibidos.exibeMedidasOvarioEsquerdo1 = true;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo3 = undefined;
              laudoSelecionado.laudoJson.volumeOvarioEsquerdo = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeMedidasOvarioEsquerdo1 = false;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo3 = undefined;
              laudoSelecionado.laudoJson.volumeOvarioEsquerdo = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeSinaisEndometriose ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Não há sinais de endometriose profunda em septo retovaginal',
              'Não há sinais de endometriose profunda em fórnice vaginal',
              'Não há sinais de endometriose profunda em região retrocervical',
              'Não há sinais de endometriose profunda em região paracervical',
              'Não há sinais de endometriose profunda em recesso vésico uterino e intestinal',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeSinaisEndometriose"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.sinaisEndometriose"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.sinaisEndometriose == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeSinaisEndometriose"
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.sinaisEndometrioseOutro"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeSinaisEndometriose"
            @click="
              camposExibidos.exibeSinaisEndometriose = true;
              laudoSelecionado.laudoJson.sinaisEndometriose = undefined;
              laudoSelecionado.laudoJson.sinaisEndometrioseOutro = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeSinaisEndometriose = false;
              laudoSelecionado.laudoJson.sinaisEndometriose = undefined;
              laudoSelecionado.laudoJson.sinaisEndometrioseOutro = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeSinaisEndometriose2 ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Não há sinais de endometriose profunda no ceco',
              'Apêndice caracterizado e de aspecto ecográfico normal',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeSinaisEndometriose2"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.sinaisEndometriose2"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.sinaisEndometriose2 == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeSinaisEndometriose2"
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.sinaisEndometrioseOutro2"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeSinaisEndometriose2"
            @click="
              camposExibidos.exibeSinaisEndometriose2 = true;
              laudoSelecionado.laudoJson.sinaisEndometriose2 = undefined;
              laudoSelecionado.laudoJson.sinaisEndometrioseOutro2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeSinaisEndometriose2 = false;
              laudoSelecionado.laudoJson.sinaisEndometriose2 = undefined;
              laudoSelecionado.laudoJson.sinaisEndometrioseOutro2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeRinsAspecto ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Rins de aspecto ecográfico normal e sem sinais de hidronefrose',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeRinsAspecto"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.RinsAspecto"
          >
          </v-select>
          <v-textarea
            v-if="laudoSelecionado.laudoJson.RinsAspecto == 'outro'"
            required
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeRinsAspecto"
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.RinsAspectoOutro"
          ></v-textarea>
          <span>.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeRinsAspecto"
            @click="
              camposExibidos.exibeRinsAspecto = true;
              laudoSelecionado.laudoJson.RinsAspecto = undefined;
              laudoSelecionado.laudoJson.RinsAspectoOutro = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeRinsAspecto = false;
              laudoSelecionado.laudoJson.RinsAspecto = undefined;
              laudoSelecionado.laudoJson.RinsAspectoOutro = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Conclusão</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeConclusao1 ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Exame dentro dos limites da normalidade ',
              '------------------------------------------',
            ]"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeConclusao1"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.conclusao1"
          >
          </v-select>
          <span>.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeConclusao1"
            @click="
              camposExibidos.exibeConclusao1 = true;
              laudoSelecionado.laudoJson.conclusao1 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeConclusao1 = false;
              laudoSelecionado.laudoJson.conclusao1 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeConclusao2 ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Não há sinais de endometriose profunda',
              '------------------------------------------',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeConclusao2"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.conclusao2"
          >
          </v-select>
          <span>.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeConclusao2"
            @click="
              camposExibidos.exibeConclusao2 = true;
              laudoSelecionado.laudoJson.conclusao2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeConclusao2 = false;
              laudoSelecionado.laudoJson.conclusao2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea
            required
            validate-on-blur
            filled
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionais"
          ></v-textarea>
        </v-col>
      </template>

      <v-col cols="12" class="d-flex justify-start align-center pt-6">
        <v-btn
          v-if="podeEditar"
          color="primary"
          :loading="carregamentoDoLaudo"
          @click="
            () => {
              laudoId ? editarLaudo() : salvarLaudo();
            }
          "
        >
          salvar laudo
        </v-btn>

        <v-btn
          color="primary"
          v-if="podeImprimir && !podeEditar"
          @click="imprimirLaudo"
          class="ml-5"
          :loading="loadingImprimir"
        >
          Imprimir
        </v-btn>

        <v-btn
          color="primary"
          v-if="!podeEditar"
          class="ml-5"
          @click="podeEditar = true"
        >
          Editar
        </v-btn>

        <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
          Cancelar
        </v-btn>

        <v-btn
          color="primary"
          v-if="podePersonalizar"
          @click="adcionarConteudoEditorPersonalizado"
          class="ml-5"
        >
          {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
        </v-btn>
      </v-col>
    </v-row>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    VueEditor,
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      quantidadeFolhasEditorPersonalizado: 0,
      carregamentoDoLaudo: false,
      loadingImprimir: false,
      laudoId: null,
      podeEditar: true,
      voltando: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      camposExibidos: {
        exDataDUM: true,
        exDiaCiclo: true,
        exUteroPos: true,
        exUretra: true,
        exBexigaCheia: true,
        exVagina: true,
        exColoUterino: true,
        exCorpoUterino: true,
        exFormCont: true,
        exParede: true,
        exBiome: true,
        exFundoCm: true,
        exCav: true,
        exColoCM: true,
        exEcoEndo: true,
        exEspEndo: true,
        exCavUter: true,
        exOvaDir: true,
        exImgAnenoica: true,
        exMedOvarioDir: true,
        exOvarioEsq: true,
        exOvaEsqImg: true,
        exOvaEsqMed: true,
        exSinaisEndome: true,
        exSinaisEndome2: true,
        exRinsAsp: true,
        exibeVagina1new: true,

        exibeDataDum: true,
        exibeDiaCicloMenstrual: true,
        exibeUteroPosicao: true,
        exibeUretra: true,
        exibeBexigaCheia1: true,
        exibeVagina1: true,
        exibeColoUterino1: true,
        exibeColoUterino1new: true,
        exibeCorpoUterino1: true,
        exibeFormaColoUterino: true,
        exibeParedeColoUterino: true,
        exibeParedeColoUterinoNew: true,
        exibeEcoEndometrial1: true,
        exibeEcoEndometrial2: true,
        exibeBiometriaUterinaUtero1: true,
        exibeFundoCm: true,
        exibeCavidadeUterinaCm: true,
        exibeColoCm: true,
        exibeEcoEndometrial: true,
        exibeEspessuraEndometrialMm: true,
        exibeCavidadeUterina: true,
        exibeOvarioDireito1: true,
        exibeOvarioDireito1new: true,
        exibeOvarioEsquerdoNew: true,
        exibeImagemAnecoica: true,
        exibeMedidasOvarioDireito1: true,
        exibeOvarioEsquerdo: true,
        exibeImagemAnecoica2: true,
        exibeMedidasOvarioEsquerdo1: true,
        exibeSinaisEndometriose: true,
        exibeSinaisEndometriose2: true,
        exibeRinsAspecto: true,
        exibeConclusao1: true,
        exibeConclusao2: true,
      },

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,

      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 2,5 cm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    podeImprimir() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    temIdDoLaudo() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    removeFolha(value) {
      let indice = value - 1;

      if (this.laudoSelecionado.laudoJson.folhas.length != 1) {
        this.conteudoEditorPersonalizado.splice(indice, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.$forceUpdate();
    },
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudoSelecionado.laudoJson.folhas) {
        this.conteudoEditorPersonalizado =
          this.laudoSelecionado.laudoJson.folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }

      let todos = [];

      this.conteudoEditorPersonalizado = [];

      if (
        this.camposExibidos.exibeDataDum &&
        this.laudoSelecionado.laudoJson.dataDum
      ) {
        todos.push(`DUM: ${this.laudoSelecionado.laudoJson.dataDum}.\n`);
      }
      if (
        this.camposExibidos.exibeDiaCicloMenstrual &&
        this.laudoSelecionado.laudoJson.diaCicloMenstrual
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.diaCicloMenstrual}º dia do ciclo menstrual.\n`
        );
      }
      if (
        this.camposExibidos.exibeUteroPosicao &&
        this.laudoSelecionado.laudoJson.uteroPosicao
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.uteroPosicao} uso de contraceptivos hormonais.\n`
        );
      }

      if (
        this.camposExibidos.exibeUretra &&
        this.laudoSelecionado.laudoJson.uretra
      ) {
        todos.push(
          `Urétra: ${
            this.laudoSelecionado.laudoJson.uretra !== 'outro'
              ? this.laudoSelecionado.laudoJson.uretra
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.uretra === 'outro' &&
          this.laudoSelecionado.laudoJson.uretraOutros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.uretraOutros}`);
        }
        if (this.laudoSelecionado.laudoJson.uretra2) {
          todos.push(
            ` ${
              this.laudoSelecionado.laudoJson.uretra2 !== 'outro'
                ? this.laudoSelecionado.laudoJson.uretra2
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.uretra2 === 'outro' &&
          this.laudoSelecionado.laudoJson.uretra2Outros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.uretra2Outros}`);
        }
        todos.push('.\n');
      }

      if (
        this.camposExibidos.exibeBexigaCheia1 &&
        this.laudoSelecionado.laudoJson.bexigaCheia1
      ) {
        todos.push(
          `Bexiga: cheia, ${
            this.laudoSelecionado.laudoJson.bexigaCheia1 !== 'outro'
              ? this.laudoSelecionado.laudoJson.bexigaCheia1
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.bexigaCheia1 === 'outro' &&
          this.laudoSelecionado.laudoJson.bexigaCheia1Outros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.bexigaCheia1Outros}`);
        }
        if (this.laudoSelecionado.laudoJson.bexigaCheia2) {
          todos.push(
            ` e ${
              this.laudoSelecionado.laudoJson.bexigaCheia2 !== 'outro'
                ? this.laudoSelecionado.laudoJson.bexigaCheia2
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.bexigaCheia2 === 'outro' &&
          this.laudoSelecionado.laudoJson.bexigaCheia2Outros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.bexigaCheia2Outros}`);
        }
        if (this.laudoSelecionado.laudoJson.bexigaCheia3) {
          todos.push(
            ` com conteúdo ${
              this.laudoSelecionado.laudoJson.bexigaCheia3 !== 'outro'
                ? this.laudoSelecionado.laudoJson.bexigaCheia3
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.bexigaCheia3 === 'outro' &&
          this.laudoSelecionado.laudoJson.bexigaChei32Outros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.bexigaChei32Outros}`);
        }
        todos.push('.\n');
      }

      if (
        this.camposExibidos.exibeVagina1new &&
        this.laudoSelecionado.laudoJson.vagina1
      ) {
        todos.push(
          `Vagina: ${
            this.laudoSelecionado.laudoJson.vagina1 !== 'outro'
              ? this.laudoSelecionado.laudoJson.vagina1
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.vagina1 === 'outro' &&
          this.laudoSelecionado.laudoJson.vagina1Outros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.vagina1Outros}`);
        }
        if (this.laudoSelecionado.laudoJson.vagina2) {
          todos.push(
            ` ,${
              this.laudoSelecionado.laudoJson.vagina2 !== 'outro'
                ? this.laudoSelecionado.laudoJson.vagina2
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.vagina2 === 'outro' &&
          this.laudoSelecionado.laudoJson.vagina2Outros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.vagina2Outros}`);
        }
        todos.push('.\n');
      }

      if (
        this.camposExibidos.exibeColoUterino1new &&
        this.laudoSelecionado.laudoJson.coloUterino1
      ) {
        todos.push(
          `Colo uterino: ${
            this.laudoSelecionado.laudoJson.coloUterino1 !== 'outro'
              ? this.laudoSelecionado.laudoJson.coloUterino1
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.coloUterino1 === 'outro' &&
          this.laudoSelecionado.laudoJson.coloUterino1Outros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.coloUterino1Outros}`);
        }
        if (this.laudoSelecionado.laudoJson.coloUterino2) {
          todos.push(
            ` ,${
              this.laudoSelecionado.laudoJson.coloUterino2 !== 'outro'
                ? this.laudoSelecionado.laudoJson.coloUterino2
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.coloUterino2 === 'outro' &&
          this.laudoSelecionado.laudoJson.coloUterino2Outros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.coloUterino2Outros}`);
        }
        if (this.laudoSelecionado.laudoJson.canalEndocervical) {
          todos.push(
            ` e canal endocervical ${
              this.laudoSelecionado.laudoJson.canalEndocervical !== 'outro'
                ? this.laudoSelecionado.laudoJson.canalEndocervical
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.canalEndocervical === 'outro' &&
          this.laudoSelecionado.laudoJson.canalEndocervicalOutros
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.canalEndocervicalOutros}`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.canalEndocervical ===
            'apresentando imagem hiperecóica com pedículo vascular medindo' &&
          this.laudoSelecionado.laudoJson.canalEndocervicalMedindo
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.canalEndocervicalMedindo} cm.`
          );
        }
        todos.push('.\n');
      }

      if (
        this.camposExibidos.exibeCorpoUterino1 &&
        this.laudoSelecionado.laudoJson.corpoUterino1
      ) {
        todos.push(
          `Corpo uterino: ${
            this.laudoSelecionado.laudoJson.corpoUterino1 !== 'outro'
              ? this.laudoSelecionado.laudoJson.corpoUterino1
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.corpoUterino1 === 'outro' &&
          this.laudoSelecionado.laudoJson.corpoUterino1Outros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.corpoUterino1Outros}`);
        }
        if (this.laudoSelecionado.laudoJson.corpoUterino2) {
          todos.push(
            ` em ${
              this.laudoSelecionado.laudoJson.corpoUterino2 !== 'outro'
                ? this.laudoSelecionado.laudoJson.corpoUterino2
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.corpoUterino2 === 'outro' &&
          this.laudoSelecionado.laudoJson.corpoUterino2Outros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.corpoUterino2Outros}`);
        }
        todos.push('.\n');
      }

      if (
        this.camposExibidos.exibeFormaColoUterino &&
        this.laudoSelecionado.laudoJson.formaColoUterino
      ) {
        todos.push(
          `A forma é ${
            this.laudoSelecionado.laudoJson.formaColoUterino !== 'outro'
              ? this.laudoSelecionado.laudoJson.formaColoUterino
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.formaColoUterino === 'outro' &&
          this.laudoSelecionado.laudoJson.formaColoUterinoOutros
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.formaColoUterinoOutros}`
          );
        }
        if (this.laudoSelecionado.laudoJson.contornoColoUterino) {
          todos.push(
            ` e os contornos ${
              this.laudoSelecionado.laudoJson.contornoColoUterino !== 'outro'
                ? this.laudoSelecionado.laudoJson.contornoColoUterino
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.contornoColoUterino === 'outro' &&
          this.laudoSelecionado.laudoJson.contornoColoUterinoOutro
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.contornoColoUterinoOutro}`
          );
        }
        todos.push('.\n');
      }

      if (
        this.camposExibidos.exibeParedeColoUterinoNew &&
        this.laudoSelecionado.laudoJson.paredeColoUterino
      ) {
        todos.push(
          `As paredes são ${
            this.laudoSelecionado.laudoJson.paredeColoUterino !== 'outro'
              ? this.laudoSelecionado.laudoJson.paredeColoUterino
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.paredeColoUterino === 'outro' &&
          this.laudoSelecionado.laudoJson.paredeColoUterinoOutros
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.paredeColoUterinoOutros}`
          );
        }
        if (this.laudoSelecionado.laudoJson.zonaJuncional) {
          todos.push(
            ` e o miométrio apresenta zona juncional ${
              this.laudoSelecionado.laudoJson.zonaJuncional !== 'outro'
                ? this.laudoSelecionado.laudoJson.zonaJuncional
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.zonaJuncional === 'outro' &&
          this.laudoSelecionado.laudoJson.zonaJuncionalOutros
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.zonaJuncionalOutros}`);
        }
        if (this.laudoSelecionado.laudoJson.texturaMiometrial) {
          todos.push(
            ` ,com textura miometrial ${
              this.laudoSelecionado.laudoJson.texturaMiometrial !== 'outro'
                ? this.laudoSelecionado.laudoJson.texturaMiometrial
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.texturaMiometrial === 'outro' &&
          this.laudoSelecionado.laudoJson.texturaMiometrialOutros
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.texturaMiometrialOutros}`
          );
        }
        todos.push('.\n');
      }

      todos.push('\n');

      if (this.camposExibidos.exibeBiometriaUterinaUtero1) {
        todos.push(`\n<h4>Biometria uterina</h4>`);
      }

      todos.push('\n');

      if (
        this.camposExibidos.exibeBiometriaUterinaUtero1 &&
        this.laudoSelecionado.laudoJson.biometriaUterinaUtero1
      ) {
        todos.push(
          `Útero: ${this.laudoSelecionado.laudoJson.biometriaUterinaUtero1}${
            this.laudoSelecionado.laudoJson.biometriaUterinaUtero2
              ? ' x ' + this.laudoSelecionado.laudoJson.biometriaUterinaUtero2
              : ''
          }${
            this.laudoSelecionado.laudoJson.biometriaUterinaUtero3
              ? ' x ' + this.laudoSelecionado.laudoJson.biometriaUterinaUtero3
              : ''
          } cm ${
            this.laudoSelecionado.laudoJson.biometriaUterinaUtero4
              ? ' e volume de ' +
                this.laudoSelecionado.laudoJson.biometriaUterinaUtero4 +
                ' cm³'
              : ''
          }.\n`
        );
      }

      if (
        this.camposExibidos.exibeFundoCm &&
        this.laudoSelecionado.laudoJson.fundoCm
      ) {
        todos.push(`Fundo: ${this.laudoSelecionado.laudoJson.fundoCm} cm.\n`);
      }

      if (
        this.camposExibidos.exibeCavidadeUterinaCm &&
        this.laudoSelecionado.laudoJson.cavidadeUterinaCm
      ) {
        todos.push(
          `Cavidade uterina: ${this.laudoSelecionado.laudoJson.cavidadeUterinaCm} cm.\n`
        );
      }

      if (
        this.camposExibidos.exibeColoCm &&
        this.laudoSelecionado.laudoJson.coloCm
      ) {
        todos.push(`Colo: ${this.laudoSelecionado.laudoJson.coloCm} cm.\n`);
      }

      if (
        this.camposExibidos.exibeEcoEndometrial1 &&
        this.laudoSelecionado.laudoJson.ecoEndometrial
      ) {
        todos.push(
          `O eco endometrial é ${
            this.laudoSelecionado.laudoJson.ecoEndometrial !== 'outro'
              ? this.laudoSelecionado.laudoJson.ecoEndometrial
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.ecoEndometrial === 'outro' &&
          this.laudoSelecionado.laudoJson.ecoEndometrialOutros
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.ecoEndometrialOutros}`
          );
        }
        if (this.laudoSelecionado.laudoJson.ecoEndometrial2) {
          todos.push(
            `, ${
              this.laudoSelecionado.laudoJson.ecoEndometrial2 !== 'outro'
                ? this.laudoSelecionado.laudoJson.ecoEndometrial2
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.ecoEndometrial2 === 'outro' &&
          this.laudoSelecionado.laudoJson.ecoEndometrial2Outros2
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.ecoEndometrial2Outros2}`
          );
        }
        if (this.laudoSelecionado.laudoJson.linhaMedia) {
          todos.push(
            ` ,com linha média ${
              this.laudoSelecionado.laudoJson.linhaMedia !== 'outro'
                ? this.laudoSelecionado.laudoJson.linhaMedia
                : ''
            }${
              this.laudoSelecionado.laudoJson.contornoColoUterino2
                ? ' e junção endométrio-miométrio' +
                  this.laudoSelecionado.laudoJson.contornoColoUterino2
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.linhaMedia === 'outro' &&
          this.laudoSelecionado.laudoJson.linhaMediaOutros
        ) {
          todos.push(`${this.laudoSelecionado.laudoJson.linhaMediaOutros}`);
        }

        todos.push('.\n');
      }

      if (
        this.camposExibidos.exibeEspessuraEndometrialMm &&
        this.laudoSelecionado.laudoJson.espessuraEndometrialMm
      ) {
        todos.push(
          `Espessura endometrial de ${this.laudoSelecionado.laudoJson.espessuraEndometrialMm} mm.\n`
        );
      }

      if (
        this.camposExibidos.exibeCavidadeUterina &&
        this.laudoSelecionado.laudoJson.cavidadeUterina
      ) {
        todos.push(
          `Cavidade uterina: ${
            this.laudoSelecionado.laudoJson.cavidadeUterina !== 'outro'
              ? this.laudoSelecionado.laudoJson.cavidadeUterina
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.cavidadeUterina ===
            'Imagem hiperecóica medindo' &&
          this.laudoSelecionado.laudoJson.comprimentoCabecaNadegas
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.comprimentoCabecaNadegas}`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.cavidadeUterina === 'outro' &&
          this.laudoSelecionado.laudoJson.ecoEndometrial2Outros
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.ecoEndometrial2Outros}`
          );
        }
        todos.push('.\n');
      }

      todos.push('\n');

      todos.push(`\n<h4>Ovários</h4>`);

      todos.push('\n');

      if (
        this.camposExibidos.exibeOvarioDireito1new &&
        this.laudoSelecionado.laudoJson.ovarioDireito1
      ) {
        todos.push(
          `Ovário direito: ${
            this.laudoSelecionado.laudoJson.ovarioDireito1 !== 'outro'
              ? this.laudoSelecionado.laudoJson.ovarioDireito1
              : ''
          }, `
        );
        if (
          this.laudoSelecionado.laudoJson.ovarioDireito1 === 'outro' &&
          this.laudoSelecionado.laudoJson.ovarioDireito1Outro
        ) {
          todos.push(`${this.laudoSelecionado.laudoJson.ovarioDireito1Outro} `);
        }
        if (this.laudoSelecionado.laudoJson.ovarioDireito2Outro) {
          todos.push(`${this.laudoSelecionado.laudoJson.ovarioDireito2Outro} `);
        }
        if (
          this.laudoSelecionado.laudoJson.ovarioDireito2Outro === 'outro' &&
          this.laudoSelecionado.laudoJson.ovarioDireito3Outro
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.ovarioDireito3Outro}`);
        }
        todos.push('.\n');
      }

      if (
        this.camposExibidos.exibeImagemAnecoica &&
        this.laudoSelecionado.laudoJson.imagemAnecoica
      ) {
        todos.push(
          `${
            this.laudoSelecionado.laudoJson.imagemAnecoica !== 'outro'
              ? this.laudoSelecionado.laudoJson.imagemAnecoica
              : ''
          }\n`
        );
        if (
          this.laudoSelecionado.laudoJson.imagemAnecoica ===
            'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo' &&
          this.laudoSelecionado.laudoJson.imagemAnecoicaCm
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.imagemAnecoicaCm} cm no seu maior diâmetro.\n`
          );
        }
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica ===
          'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo' &&
        this.laudoSelecionado.laudoJson.finoSeptoCm
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.finoSeptoCm} cm no seu maior diâmetro.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica ===
          'Apresenta imagem anecóica com debris, medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaDebrisCm
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaDebrisCm} cm no seu maior diâmetro.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica ===
          'Apresenta imagem anecóica multiseptada medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm} cm no seu maior diâmetro.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica ===
          'Apresenta imagem heterogênea medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm2
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm2} x ${this.laudoSelecionado.laudoJson.imagemHeterogeneaCm} cm.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica === 'outro' &&
        this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro}.\n`
        );
      }

      if (
        this.camposExibidos.exibeMedidasOvarioDireito1 &&
        this.laudoSelecionado.laudoJson.medidasOvarioDireito1
      ) {
        todos.push(
          `Medidas do ovário direito: ${this.laudoSelecionado.laudoJson.medidasOvarioDireito1}`
        );

        if (this.laudoSelecionado.laudoJson.medidasOvarioDireito2) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioDireito2}`
          );
        }
        if (this.laudoSelecionado.laudoJson.medidasOvarioDireito3) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioDireito3} cm e volume de`
          );
        }
        if (this.laudoSelecionado.laudoJson.volumeOvarioDireito) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.volumeOvarioDireito} cm³`
          );
        }
        todos.push('.\n');
      }

      if (
        this.camposExibidos.exibeOvarioEsquerdoNew &&
        this.laudoSelecionado.laudoJson.ovarioEsquerdo
      ) {
        todos.push(
          `Ovário esquerdo: ${
            this.laudoSelecionado.laudoJson.ovarioEsquerdo !== 'outro'
              ? this.laudoSelecionado.laudoJson.ovarioEsquerdo
              : ''
          }, `
        );
        if (
          this.laudoSelecionado.laudoJson.ovarioEsquerdo === 'outro' &&
          this.laudoSelecionado.laudoJson.ovarioEsquerdoOutros
        ) {
          todos.push(
            `${this.laudoSelecionado.laudoJson.ovarioEsquerdoOutros} `
          );
        }
        if (this.laudoSelecionado.laudoJson.ovarioEsquerdo2) {
          todos.push(
            `${
              this.laudoSelecionado.laudoJson.ovarioEsquerdo2 !== 'outro'
                ? this.laudoSelecionado.laudoJson.ovarioEsquerdo2
                : ''
            }`
          );
        }
        if (
          this.laudoSelecionado.laudoJson.ovarioEsquerdo2 === 'outro' &&
          this.laudoSelecionado.laudoJson.ovarioEsquerdoOutros2
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.ovarioEsquerdoOutros2}`
          );
        }
        todos.push('\n');
      }

      if (
        this.camposExibidos.exibeImagemAnecoica2 &&
        this.laudoSelecionado.laudoJson.imagemAnecoica2
      ) {
        todos.push(
          `${
            this.laudoSelecionado.laudoJson.imagemAnecoica2 !== 'outro'
              ? this.laudoSelecionado.laudoJson.imagemAnecoica2
              : ''
          }.\n`
        );
        if (
          this.laudoSelecionado.laudoJson.imagemAnecoica2 ===
            'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo' &&
          this.laudoSelecionado.laudoJson.imagemAnecoicaCm2
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.imagemAnecoicaCm2} cm no seu maior diâmetro\n`
          );
        }
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica2 ===
          'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo' &&
        this.laudoSelecionado.laudoJson.finoSeptoCm2
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.finoSeptoCm2} cm no seu maior diâmetro\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica2 ===
          'Apresenta imagem anecóica com debris, medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaDebrisCm2
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaDebrisCm2} cm no seu maior diâmetro\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica2 ===
          'Apresenta imagem anecóica multiseptada medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm21
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm21} cm no seu maior diâmetro\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica2 ===
          'Apresenta imagem heterogênea medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm22
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm22} x ${this.laudoSelecionado.laudoJson.imagemHeterogeneaCm2} cm\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica2 === 'outro' &&
        this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro2
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro2}\n`
        );
        todos.push('.\n');
      }

      if (
        this.camposExibidos.exibeMedidasOvarioEsquerdo1 &&
        this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo1
      ) {
        todos.push(
          `Medidas do ovário esquerdo: ${this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo1}`
        );

        if (this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo2) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo2}`
          );
        }
        if (this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo3) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo3} cm e volume de ${this.laudoSelecionado.laudoJson.volumeOvarioEsquerdo} cm³`
          );
        }
        todos.push('.\n');
      }

      if (
        this.camposExibidos.exibeSinaisEndometriose &&
        this.laudoSelecionado.laudoJson.sinaisEndometriose
      ) {
        todos.push(
          `${
            this.laudoSelecionado.laudoJson.sinaisEndometriose !== 'outro'
              ? this.laudoSelecionado.laudoJson.sinaisEndometriose
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.sinaisEndometriose === 'outro' &&
          this.laudoSelecionado.laudoJson.sinaisEndometrioseOutro
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.sinaisEndometrioseOutro}`
          );
        }
        todos.push('\n');
      }

      if (
        this.camposExibidos.exibeSinaisEndometriose2 &&
        this.laudoSelecionado.laudoJson.sinaisEndometriose2
      ) {
        todos.push(
          `${
            this.laudoSelecionado.laudoJson.sinaisEndometriose2 !== 'outro'
              ? this.laudoSelecionado.laudoJson.sinaisEndometriose2
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.sinaisEndometriose2 === 'outro' &&
          this.laudoSelecionado.laudoJson.sinaisEndometrioseOutro2
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.sinaisEndometrioseOutro2}`
          );
        }
        todos.push('\n');
      }
      if (
        this.camposExibidos.exibeRinsAspecto &&
        this.laudoSelecionado.laudoJson.RinsAspecto
      ) {
        todos.push(
          `${
            this.laudoSelecionado.laudoJson.RinsAspecto !== 'outro'
              ? this.laudoSelecionado.laudoJson.RinsAspecto
              : ''
          }`
        );
        if (
          this.laudoSelecionado.laudoJson.RinsAspecto === 'outro' &&
          this.laudoSelecionado.laudoJson.RinsAspectoOutro
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.RinsAspectoOutro}`);
        }
        todos.push('.\n');
      }

      todos.push('\n');

      todos.push(`\n<h4>Conclusão</h4>`);

      todos.push('\n');
      if (
        this.camposExibidos.exibeConclusao1 &&
        this.laudoSelecionado.laudoJson.conclusao1
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.conclusao1}\n`);
      }
      if (
        this.camposExibidos.exibeConclusao2 &&
        this.laudoSelecionado.laudoJson.conclusao2
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.conclusao2}\n`);
      }
      if (this.laudoSelecionado.laudoJson.comentariosAdcionais) {
        todos.push(`${this.laudoSelecionado.laudoJson.comentariosAdcionais}\n`);
      }

      while (todos.length > 0) {
        const teste = todos.splice(0, 65);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },

    forceReRender() {
      this.componentKey++;
    },

    ...mapGetters('Offline', ['requisicao', 'dados']),

    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          this.carregamentoDoLaudo = true;

          const exameId = this.$route.query.exame;

          this.laudoSelecionado.examePorConsultaId = exameId;
          this.consultaSelecionada.status = 'LAUDADO';

          if (navigator.onLine) {
            const { data } = await this.postLaudo();
            this.laudoId = data;
            await this.alterarStatus();

            if (!Number.isInteger(data)) throw new Error(data);

            this.getLaudoByExamePorConsultaId(exameId);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
              requisicaoSalvar: this.postLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'POST_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', {
              laudo: this.laudoSelecionado,
              consulta: this.consultaSelecionada,
            });
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        this.carregamentoDoLaudo = false;
        return;
      } finally {
        this.carregamentoDoLaudo = false;
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            await this.patchLaudo(this.laudoSelecionado);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
            // this.podeImprimir = false;
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    juntaPersonalizadoAoLaudo() {
      this.laudoSelecionado.laudoJson.folhas = this.conteudoEditorPersonalizado;
      this.laudoSelecionado.laudoJson.titulo =
        'ULTRASSONOGRAFIA PÉLVICA TRANSVAGINAL COM DOPPLER PARA PESQUISA DE ENDOMETRIOSE PROFUNDA';
    },
    desativaLoading() {
      this.loadingImprimir = false;
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      this.loadingImprimir = true;
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.pelvicatransvaginaldopplerpreparointestinal',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },
    formataLaudo() {
      if (this.exibeObs) {
        this.laudoSelecionado.laudoJson.exibeObs = false;
      } else {
        this.laudoSelecionado.laudoJson.exibeObs = true;
      }
    },

    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },
  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;

    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      //   this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
