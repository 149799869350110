<template lang="">
  <div>
    <LaudoPelvicaTransabdominal></LaudoPelvicaTransabdominal>
  </div>
</template>
<script>
import LaudoPelvicaTransabdominal from "../components/laudos/formularios/exames/pelvicaTransabdominal/LaudoPelvicaTransabdominal.vue"
export default {
  components: {
    LaudoPelvicaTransabdominal,
  },
};
</script>
<style lang=""></style>
