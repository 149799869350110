var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":_vm.cols,"md":_vm.md}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('FormConsulta',{attrs:{"exibirConsulta":this.exibirConsulta,"exibirBotao":this.exibirBotao,"exibirMenu":this.exibirMenu},on:{"emitterexbbotao":function (novoValor5) { return (_vm.exibirBotao = novoValor5); },"emitterexbcons":function (novoValor4) { return (_vm.exibirConsulta = novoValor4); },"emitterexmen":function (novoValor3) { return (_vm.exibirMenu = novoValor3); }}})],1),_c('v-container',{staticClass:"d-flex justify-center",staticStyle:{"max-width":"95%"},attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12","md":"5","sm":"12","xs":"12"}},[(_vm.exibirBotaoPagamento)?_c('v-row',{attrs:{"text-align":"center"}},[_c('v-col',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"pa-md-7 grey darken-1",attrs:{"block":"","router":"","to":''},on:{"click":_vm.encaminharParaPagamento}},[_c('h2',{staticClass:"grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"},[_vm._v(" EFETUAR PAGAMENTO ")])])],1)],1):_vm._e()],1)],1)],1)],1),(_vm.exibirMenu)?_c('v-col',{staticClass:"mt-8",attrs:{"cols":"12","md":"1","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"pa-md-7 pa-2 grey darken-1",attrs:{"block":""},on:{"click":_vm.editarConsulta}},[_c('h2',{staticClass:"grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"},[_vm._v(" EDITAR ")])])],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.consultaSelecionada.status == 'Laudado' ||
          _vm.consultaSelecionada.status == 'LAUDADO' ||
          _vm.consultaSelecionada.status == 'Atendido' ||
          _vm.consultaSelecionada.status == 'ATENDIDO'
        ),expression:"\n          consultaSelecionada.status == 'Laudado' ||\n          consultaSelecionada.status == 'LAUDADO' ||\n          consultaSelecionada.status == 'Atendido' ||\n          consultaSelecionada.status == 'ATENDIDO'\n        "}]},[_c('v-btn',{staticClass:"pa-md-7 pa-2 grey darken-1",attrs:{"block":""}},[_c('h2',{staticClass:"grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"},[_vm._v(" HISTÓRICO CONSULTAS ")])])],1),_c('v-col',[_c('v-btn',{staticClass:"pa-md-7 pa-2 grey darken-1",attrs:{"block":""}},[_c('h2',{staticClass:"grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"},[_vm._v(" WHATSAPP ")])])],1),_c('v-col',[_c('v-btn',{staticClass:"pa-md-7 pa-2 grey darken-1",attrs:{"block":""},on:{"click":_vm.email}},[_c('h2',{staticClass:"grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"},[_vm._v(" ENVIAR EMAIL ")])])],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.consultaSelecionada.status == 'Laudado' ||
          _vm.consultaSelecionada.status == 'LAUDADO' ||
          _vm.consultaSelecionada.status == 'Atendido' ||
          _vm.consultaSelecionada.status == 'ATENDIDO'
        ),expression:"\n          consultaSelecionada.status == 'Laudado' ||\n          consultaSelecionada.status == 'LAUDADO' ||\n          consultaSelecionada.status == 'Atendido' ||\n          consultaSelecionada.status == 'ATENDIDO'\n        "}]},[_c('v-btn',{staticClass:"pa-md-7 pa-2 grey darken-1",attrs:{"block":""},on:{"click":_vm.getRecibo}},[_c('h2',{staticClass:"grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"},[_vm._v(" RECIBO ")])])],1),_c('v-col',[_c('v-btn',{staticClass:"pa-md-7 pa-2 grey darken-1",attrs:{"block":""},on:{"click":_vm.getDeclaracao}},[_c('h2',{staticClass:"grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"},[_vm._v(" DECLARAÇÃO ")])])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }