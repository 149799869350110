<template lang="">
    <div>
      <v-container fluid style="max-width: 100%">

     
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card class="pa-3  text-center grey lighten-4" height="auto">
          <v-card-text class="d-flex justify-space-around pa-0 ma-0 flex-wrap">
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <div class="d-flex pa-2">
                  <div class="circulo grey lighten-2">
                    <h1 class="text-center grey--text">{{metricas.totalDeAtendimento}}</h1>
                  </div>
                  <div class="d-flex align-center text-left ml-5">
                    <h3 class="grey--text">TOTAL DE </br>ATENDIMENTOS</h3>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" md="12">
                <div class="d-flex pa-2">
                  <div class="circulo grey lighten-2">
                    <h1 class="text-center grey--text">{{metricas.atendimentosRelizados}}</h1>
                  </div>
                  <div class="d-flex align-center text-left ml-5">
                    <h3 class="grey--text">ATENDIMENTOS </br> REALIZADOS</h3>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" md="12">
                <div class="d-flex pa-2">
                  <div class="circulo grey lighten-2">
                    <h1 class="text-center grey--text">{{metricas.atendimentosEspera}}</h1>
                  </div>
                  <div class="d-flex align-center text-left ml-5">
                    <h3 class="grey--text">ATENDIMENTOS </br> EM ESPERA</h3>
                  </div>
                </div>
              </v-col>
            </v-row>            

            <v-row no-gutters>
              <v-col cols="12" md="12">
                <div class="text-center align-center pa-2">
                  <h2 class="text-center grey--text text--darken-1 pb-6">STATUS DE ESPERA</h2>
                  <v-progress-linear
                    value="100"
                    :color="tempoEsperaMediaCor"
                    height="25"
                    rounded
                    position="fixed"
                    
                  >            
                    <template v-slot:default="{ tempoEsperaMedia }">
                      <strong class="grey--text text--lighten-4">{{ metricas.tempoMedio }}</strong>
                    </template>
                  </v-progress-linear>           
                </div>
              </v-col>
            </v-row>           
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
     </v-container>
    </div>
</template>
<script>
import MetricasMixin from '../../mixins/MetricasMixin'
export default {

  mixins: [MetricasMixin],

    data() {
        return {
            tempoEsperaMedia: "",
            tempoEsperaMediaCor: "",
            tempoDeEsperaTexto: "",
        }
    },

    methods: {
        corTempoEsperaMedia() {
      if(this.tempoEsperaMedia <= 30) {
        this.tempoEsperaMediaCor = "green darken-1"
        this.tempoDeEsperaTexto = this.getTempoMedio().then((resp) => {
      });
      } else if (this.tempoEsperaMedia > 30 && this.tempoEsperaMedia <= 60) {
        this.tempoEsperaMediaCor = "orange darken-1"
         this.tempoDeEsperaTexto = "30 min - 1 hr"
      } else {
        this.tempoEsperaMediaCor = "red darken-1"
         this.tempoDeEsperaTexto = "1 hr - 1 hr 30 min"
      }
    },




    // função em construção 
    // teste(){

    // let tempoMedio = this.getTempoMedio(),
    // this.tempoEsperaMedia = TempoMedio

    //},
    
    
    },

    mounted() {
      let date = new Date();
      let dia = date.getDate();
      let mes = date.getMonth();
      let ano = date.getFullYear();
      if (dia < 10) {
        dia = `0${dia}`;
      }
      if(mes < 10) {
        mes = `0${mes+1}`;
      }
      const data = `${dia}/${mes}/${ano}`;
      this.corTempoEsperaMedia();
      this.getMetricas(data).then((resp) => {
      });
      
      

    },
    
}
</script>
<style lang="css">

.tamanho {
  width: 1000px
}
.circulo {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  /* transform: translateY(-50%); */
}
.circulo > h1 {
  transform: translateY(125%);
}
.tamanho {
  width: 10em;
  justify-content: center;
}
    
</style>