<template lang="">
  <div>
    <v-container fluid style="max-width: 95%">
      <v-card class="pa-5 ma-5" elevation="3">
        <v-form 
        ref="formularioPaciente"
        v-model="formValidado"
        :key="componentKey">
          
          <h2 class="pb-8">INFORMAÇÕES GERAIS</h2>

          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="nameField"
                v-model="pacienteById.nome"
                label="Nome completo"
                filled
                outlined
                :readonly="exibePaciente"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pb-2">
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="dataDeNascimentoField"
                v-model="pacienteById.dataNascimento"
                label="Data de nascimento"
                required
                filled
                outlined
                v-mask="'##/##/####'"
                :readonly="exibePaciente"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="py-0">
              <v-select
                class="maiuscula"
                ref="generoField"
                v-model="pacienteById.genero"
                :items="generos"
                label="Gênero"
                required
                filled
                outlined
                :readonly="exibePaciente"
              ></v-select>
            </v-col>

            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                ref="cpfField"
                v-mask="'###.###.###-##'"
                v-model="pacienteById.cpf"
                label="CPF"
                required
                filled
                outlined
                :readonly="exibePaciente"
                validate-on-blur
                :rules="[value => !!value || 'Campo obrigatório']"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                ref="cpfField"
                v-model="pacienteById.rg"
                label="RG"
                required
                filled
                outlined
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="my-0">
            <v-col cols="12" md="12" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="enderecoField"
                v-model="pacienteById.endereco"
                label="Endereço"
                required
                filled
                outlined
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="my-0">
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="enderecoField"
                v-model="pacienteById.numero"
                label="Número"
                required
                filled
                outlined
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="bairroField"
                v-model="pacienteById.bairro"
                label="Bairro"
                required
                filled
                outlined
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                ref="cepField"
                v-model="pacienteById.cep"
                label="CEP"
                required
                filled
                outlined
                v-on:blur="getAdress"
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="my-0">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="cidadeField"
                v-model="pacienteById.cidade"
                label="Cidade"
                required
                filled
                outlined
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="estadoField"
                v-model="pacienteById.estado"
                :items="estados"
                label="Estado"
                placeholder="Selecione..."
                required
                filled
                outlined
                :readonly="exibePaciente"
                validate-on-blur
              >
              </v-text-field>
            </v-col>
          </v-row>

          <h2 class="pb-8">CONTATO</h2>

          <v-row>
            <v-col cols="12" md="5" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="telefonePrincipalField"
                v-model="pacienteById.telefonePrincipal"
                label="Telefone 1 (Whatsapp)"
                required
                filled
                outlined
                v-mask="'(##)#####-####'"
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="7" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="emailField"
                v-model="pacienteById.email"
                label="Email"
                required
                filled
                outlined
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="5" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="telefoneSecudarioField"
                v-model="pacienteById.telefoneSecundario"
                label="Telefone 2"
                v-mask="'(##)#####-####'"
                required
                filled
                outlined
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="7" class="py-0">
              <v-text-field
                class="maiuscula"
                ref="redeSocialField"
                v-model="pacienteById.redeSocial"
                label="Redes sociais"
                required
                filled
                outlined
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>

          <h2 class="pb-8">OBSERVAÇÕES</h2>

          <v-col cols="12" md="12" class="pa-0">
            <v-textarea
              class="maiuscula"
              ref="observacoesField"
              v-model="pacienteById.observacao"
              background-color="grey lighten-3"
              color="cyan"
              label="Observações"
              no-resize
              outlined
              :readonly="exibePaciente"
              validate-on-blur
            ></v-textarea>
          </v-col>

          <v-row>
            <v-col cols="12" md="12" class="d-flex justify-end mb-4">
              <v-btn depressed plain small @click="handleInfoMedicas">
                <v-icon v-show="exibirInfoMedicas == false" class="px-2"
                  >mdi-plus-thick</v-icon
                >
                <v-icon v-show="exibirInfoMedicas == true" class="px-2"
                  >mdi-minus-thick</v-icon
                >
                <span>Exibir informações médicas</span>
              </v-btn>
            </v-col>
          </v-row>

          <h2
            class="pb-8"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            INFORMAÇÕES MÉDICAS
          </h2>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <template class="d-flex justify-space-between">
              <v-col cols="12" md="4" class="py-0">
                <h4 class="grey--text text--darken-3 pb-1">Idade</h4>
                <v-text-field
                  ref="idadeField"
                  required
                  filled
                  outlined
                  :value="idadePaciente()"
                  :readonly="exibePaciente"
                  validate-on-blur
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
                class="py-0"
                v-if="
                  exibirInfoMedicas == true && pacienteById.historico != null
                "
              >
                <h4 class="grey--text text--darken-3 pb-1">
                  Data de Nascimento
                </h4>
                <v-text-field
                  v-model="pacienteById.dataNascimento"
                  filled
                  outlined
                  required
                  v-mask="'##/##/####'"
                  readonly
                  validate-on-blur
                  :readonly="exibePaciente"
                  validate-on-blur
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
                class="py-0"
                v-if="
                  exibirInfoMedicas == true && pacienteById.historico != null
                "
              >
                <h4 class="grey--text text--darken-3 pb-1">Raça</h4>
                <v-select
                  :items="racas"
                  required
                  outlined
                  filled
                  v-model="pacienteById.historico.raca"
                  :readonly="exibePaciente"
                  validate-on-blur
                ></v-select>
              </v-col>
            </template>
          </v-row>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="8" class="d-flex align-center pa-0 flex-wrap">
              <div class="d-flex justify-space-between">
                <!-- <h4 class="mx-3">Gestação Espontânea:</h4> -->
              </div>
              <div class="d-flex justify-start mx-3">
                <!-- <v-checkbox
                  class="mx-4"
                  label="Sim"
                  value="true"
                  v-model="pacienteById.historico.gestacaoespotania"
                  color="green"
                  :readonly="exibePaciente"
                  validate-on-blur
                ></v-checkbox> -->
                <v-select
                  :items="escolhaSimNao"
                  v-model="pacienteById.historico.gestacaoespotania"
                  label="Gestação Espontânea"
                  required
                  :readonly="exibePaciente"
                  validate-on-blur
                ></v-select>
              </div>
            </v-col>
          </v-row>

          <!-- <v-row v-if="exibirInfoMedicas == true && pacienteById.historico != null">
            <v-col cols="12" md="8" class="d-flex align-center pa-0 flex-wrap">
              <v-textarea
                class="mx-3"
                v-if="pacienteById.historico.gestacaoespotania"
                color="cyan"
                v-model="pacienteById.historico.observacao"
                label="Observações"
                rows="1"
                no-resize
                outlined
                background-color="grey lighten-3"
                :readonly="exibePaciente"
                validate-on-blur
              ></v-textarea>
            </v-col>
          </v-row> -->

          <h3
            class="pb-2 pt-4"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            História Obstétrica
          </h3>
          <v-row
            class="pa-0 ma-0 aling-center"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-row>
                <v-col
                  class="d-flex flex-sm-wrap flex-md-nowrap flex-lg-nowrap pa-0 align-center"
                  cols="12"
                  md="2"
                >
                  <v-checkbox
                    class="mr-3"
                    label="Aborto"
                    v-model="pacienteById.historico.aborto"
                    :readonly="exibePaciente"
                    validate-on-blur
                    @change="forceReRender"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="pa-0 ma-0">
                <v-col cols="12" md="2" class="py-0">
                  <div
                    class="d-flex align-center pa-0"
                    v-if="pacienteById.historico.aborto"
                  >
                    <!-- <h4 class="pa-0 ma-0">Quantidade:</h4> -->
                    <v-text-field
                      class="pa-0"
                      type="number"
                      label="Quantidade"
                      v-model.number="pacienteById.historico.abortoQtd"
                      :readonly="exibePaciente"
                      validate-on-blur
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            class="pa-0 ma-0 aling-center"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-row>
                <v-col
                  class="d-flex flex-sm-wrap flex-md-nowrap flex-lg-nowrap pa-0 align-center"
                  cols="12"
                  md="3"
                >
                  <v-checkbox
                    label="Óbitos Fetais"
                    v-model="pacienteById.historico.obitosFetais"
                    :readonly="exibePaciente"
                    validate-on-blur
                    @change="forceReRender"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col cols="12" md="2" class="py-0">
                  <div
                    class="d-flex align-center pa-0"
                    v-if="pacienteById.historico.obitosFetais"
                  >
                    <v-text-field
                      class="pa-0"
                      type="number"
                      label="Quantidade"
                      v-model.number="pacienteById.historico.obitosFetaisQtd"
                      :readonly="exibePaciente"
                      validate-on-blur
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            class="pa-0 ma-0 aling-center"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-row>
                <v-col
                  class="d-flex flex-sm-wrap flex-md-nowrap flex-lg-nowrap pa-0 align-center"
                  cols="12"
                  md="3"
                >
                  <v-checkbox
                    label="Macrossômicos"
                    v-model="pacienteById.historico.macrossomicos"
                    :readonly="exibePaciente"
                    validate-on-blur
                    @change="forceReRender"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col class="py-0" cols="12" md="2">
                  <div
                    class="d-flex align-center pa-0"
                    v-if="pacienteById.historico.macrossomicos"
                  >
                    <!-- <h4 class="pa-0 ma-0">Quantidade:</h4> -->
                    <v-text-field
                      class="pt-2"
                      type="number"
                      label="Quantidade"
                      v-model.number="pacienteById.historico.qtdMacrossomicos"
                      :readonly="exibePaciente"
                      validate-on-blur
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            class="pa-0 ma-0 aling-center"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-row>
                <v-col
                  class="d-flex flex-sm-wrap flex-md-nowrap flex-lg-nowrap pa-0 align-center"
                  cols="12"
                  md="3"
                >
                  <v-checkbox
                    label="Prematuridade"
                    v-model="pacienteById.historico.prematuridade"
                    :readonly="exibePaciente"
                    validate-on-blur
                    @change="forceReRender"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col class="py-0" cols="12" md="2">
                  <div
                    class="d-flex align-center pa-0"
                    v-if="pacienteById.historico.prematuridade"
                  >
                    <!-- <h4 class="pa-0 ma-0">Quantidade:</h4> -->
                    <v-text-field
                      class="pt-2"
                      type="number"
                      label="Quantidade"
                      v-model.number="pacienteById.historico.qtdPrematuridade"
                      :readonly="exibePaciente"
                      validate-on-blur
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            class="pa-0 ma-0 aling-center"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-row>
                <v-col
                  class="d-flex flex-sm-wrap flex-md-nowrap flex-lg-nowrap pa-0 align-center"
                  cols="12"
                  md="5"
                >
                  <v-checkbox
                    label="Perda com menos de 24 semanas"
                    v-model="pacienteById.historico.perda24Menos"
                    :readonly="exibePaciente"
                    validate-on-blur
                    @change="forceReRender"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="pa-0 ma-0">
                <v-col cols="12" md="2" class="pt-2">
                  <div
                    class="d-flex align-center py-0"
                    v-if="pacienteById.historico.perda24Menos"
                  >
                    <v-text-field
                      class="pt-2"
                      type="number"
                      label="Quantidade"
                      v-model.number="pacienteById.historico.perda24Menostd"
                      :readonly="exibePaciente"
                      validate-on-blur
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            class="pa-0 ma-0"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12" class="d-flex align-center pa-0">
              <v-container fluid style="max-width: 170px" class="pa-0 ma-0">
                <h4 class="">Número de Gestações:</h4>
              </v-container>
            </v-col>
            <v-row>
              <v-col cols="12" md="2" class="py-1">
                <v-text-field
                  class="pa-0"
                  type="number"
                  v-model.number="pacienteById.historico.numeroGestacao"
                  :readonly="exibePaciente"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-row>

          <v-row
            class="pa-0 ma-0"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12" class="d-flex align-center pa-0">
              <v-container fluid style="max-width: 170px" class="pa-0 ma-0">
                <h4 class="">Intervalo Gestacional:</h4>
              </v-container>
            </v-col>
            <v-row>
              <v-col cols="12" md="2" class="py-1">
                <v-text-field
                  class="pa-0"
                  type="number"
                  v-model.number="
                    pacienteById.historico.intevalorInterGestacional
                  "
                  :readonly="exibePaciente"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-row>

          <v-row
            class="pa-0 ma-0"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12" class="d-flex align-center pa-0">
              <v-container class="pa-0 ma-0">
                <h4 class="">Idade Gestacional do último parto ou aborto:</h4>
              </v-container>
            </v-col>
            <v-row>
              <v-col cols="12" md="2" class="py-1">
                <v-text-field
                  class="pa-0"
                  type="number"
                  v-model.number="pacienteById.historico.idadeUltimoGestacional"
                  :readonly="exibePaciente"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-row>

          <v-row
            class="pa-0 ma-0"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12" class="d-flex align-center pa-0">
              <div class="pa-0 ma-0">
                <h4 class="">Data do último parto ou aborto:</h4>
              </div>
            </v-col>
            <v-row>
              <v-col cols="12" md="2" class="py-1">
                <v-text-field
                  class="pa-0"
                  type="text"
                  v-mask="'##/##/####'"
                  v-model="pacienteById.historico.dataUltimoPartoAborto"
                  :readonly="exibePaciente"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="6" md="6">
                  <v-row
                    class="pa-0 ma-0"
                    v-if="
                      exibirInfoMedicas == true &&
                      pacienteById.historico != null
                    "
                  >
                    <v-col cols="6" md="6" class="d-flex align-center pa-0">
                      <div class="pa-0 ma-0">
                        <h4 class="">Bebê de maior peso:</h4>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    class="py-0 my-0"
                    v-if="
                      exibirInfoMedicas == true &&
                      pacienteById.historico != null
                    "
                  >
                    <v-col cols="12" md="4" class="py-0">
                      <v-text-field
                        class="pa-0"
                        type="number"
                        v-model.number="pacienteById.historico.pesoMaiorBebe"
                        :readonly="exibePaciente"
                        validate-on-blur
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" md="6">
                  <v-row
                    class="pa-0 ma-0"
                    v-if="
                      exibirInfoMedicas == true &&
                      pacienteById.historico != null
                    "
                  >
                    <v-col cols="6" md="6" class="d-flex align-center pa-0">
                      <div class="pa-0 ma-0">
                        <h4 class="">IG que nasceu:</h4>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    class="py-0 my-0"
                    v-if="
                      exibirInfoMedicas == true &&
                      pacienteById.historico != null
                    "
                  >
                    <v-col cols="12" md="4" class="py-0">
                      <v-text-field
                        class="pa-0"
                        type="number"
                        v-model.number="pacienteById.historico.igMaiorBebe"
                        :readonly="exibePaciente"
                        validate-on-blur
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="6" md="6">
                  <v-row
                    class="pa-0 ma-0"
                    v-if="
                      exibirInfoMedicas == true &&
                      pacienteById.historico != null
                    "
                  >
                    <v-col cols="6" md="6" class="d-flex align-center pa-0">
                      <div class="pa-0 ma-0">
                        <h4 class="">Bebê de menor peso:</h4>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    class="py-0 my-0"
                    v-if="
                      exibirInfoMedicas == true &&
                      pacienteById.historico != null
                    "
                  >
                    <v-col cols="12" md="4" class="py-0">
                      <v-text-field
                        class="pa-0"
                        type="number"
                        v-model.number="pacienteById.historico.pesoMenorBebe"
                        :readonly="exibePaciente"
                        validate-on-blur
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" md="6">
                  <v-row
                    class="pa-0 ma-0"
                    v-if="
                      exibirInfoMedicas == true &&
                      pacienteById.historico != null
                    "
                  >
                    <v-col cols="6" md="6" class="d-flex align-center pa-0">
                      <div class="pa-0 ma-0">
                        <h4 class="">IG que nasceu:</h4>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    class="py-0 my-0"
                    v-if="
                      exibirInfoMedicas == true &&
                      pacienteById.historico != null
                    "
                  >
                    <v-col cols="12" md="4" class="py-0">
                      <v-text-field
                        class="pa-0"
                        type="number"
                        v-model.number="pacienteById.historico.igMenorBebe"
                        :readonly="exibePaciente"
                        validate-on-blur
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <h3
            class="pb-8 pt-4"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            Antecedentes pessoais
          </h3>
          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="2" class="py-0">
              <v-text-field
                ref="idadeField"
                v-model.number="pacienteById.historico.peso"
                label="Peso"
                required
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="2" class="py-0">
              <v-text-field
                v-model.number="pacienteById.historico.altura"
                label="Altura"
                required
                :readonly="exibePaciente"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="4" class="py-0">
              <v-select
                v-model="pacienteById.historico.diabetes"
                :items="escolhaSimNao"
                item-text="text"
                item-value="value"
                label="Diabetes"
                required
                :readonly="exibePaciente"
                validate-on-blur
              ></v-select>
            </v-col>
          </v-row>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="4" class="py-0">
              <v-select
                :items="escolhaSimNao"
                v-model="pacienteById.historico.hipertensaoCronica"
                label="Hipertensão crônica"
                required
                :readonly="exibePaciente"
                validate-on-blur
              ></v-select>
            </v-col>
          </v-row>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="4" class="py-0">
              <v-select
                :items="escolhaSimNao"
                v-model="pacienteById.historico.lopus"
                label="Lúpus"
                required
                :readonly="exibePaciente"
                validate-on-blur
              ></v-select>
            </v-col>
          </v-row>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="4" class="py-0">
              <v-select
                :items="escolhaSimNao"
                v-model="pacienteById.historico.sAntitosfolipidi"
                label="Síndrome antifosfolipe"
                required
                :readonly="exibePaciente"
                validate-on-blur
              ></v-select>
            </v-col>
          </v-row>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <v-select
                    :items="escolhaSimNao"
                    v-model="pacienteById.historico.trombofilias"
                    label="Trombofilias"
                    required
                    :readonly="exibePaciente"
                    validate-on-blur
                    @change="forceReRender"
                  ></v-select>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    label="Qual?"
                    v-if="pacienteById.historico.trombofilias"
                    v-model="pacienteById.historico.trombofiliasTipo"
                    :readonly="exibePaciente"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <v-select
                    :items="escolhaSimNao"
                    v-model="pacienteById.historico.drenal"
                    label="Doença renal"
                    required
                    :readonly="exibePaciente"
                    validate-on-blur
                    @change="forceReRender"
                  ></v-select>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    label="Qual?"
                    v-if="pacienteById.historico.drenal"
                    v-model="pacienteById.historico.drenalTipo"
                    :readonly="exibePaciente"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <v-select
                    :items="escolhaSimNao"
                    v-model="pacienteById.historico.dematologica"
                    label="Doença hematológica"
                    required
                    :readonly="exibePaciente"
                    validate-on-blur
                    @change="forceReRender"
                  ></v-select>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    label="Qual?"
                    v-if="pacienteById.historico.dematologica"
                    v-model="pacienteById.historico.dHematologicaTipo"
                    :readonly="exibePaciente"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="4" class="py-0">
              <div class="d-flex align-center">
                <v-text-field
                  label="Outros"
                  v-model="pacienteById.historico.outros"
                  :readonly="exibePaciente"
                  validate-on-blur
                ></v-text-field>
              </div>
            </v-col>
          </v-row>

          <h3
            class="pb-8 pt-8"
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            Antecedentes familiares
          </h3>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <v-select
                    :items="escolhaSimNao"
                    v-model="pacienteById.historico.malformacao"
                    label="Doença hematológica"
                    required
                    :readonly="exibePaciente"
                    validate-on-blur
                    @change="forceReRender"
                  ></v-select>
                </v-col>
                <v-col cols="4" md="4">
                  <v-text-field
                    label="Qual?"
                    v-if="pacienteById.historico.malformacao"
                    v-model="pacienteById.historico.malFormacaoTipo"
                    :readonly="exibePaciente"
                    validate-on-blur
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            v-if="exibirInfoMedicas == true && pacienteById.historico != null"
          >
            <v-col cols="12" md="4" class="py-0 d-flex">
              <v-select
                :items="escolhaSimNao"
                v-model="pacienteById.historico.mptpreEclampsia"
                label="Mãe da paciente teve Pre-eclâmpsia"
                required
                :readonly="exibePaciente"
                validate-on-blur
              ></v-select>
            </v-col>
          </v-row>
        </v-form>

        <v-row class="d-flex justify-start pt-6 pb-4" v-if="exibirBotao">
          <v-col cols="12" md="9">
            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="">
                <v-btn
                  color="primary"
                  :loading="loading"
                  v-bind="attrs"
                  v-on="on"
                  @click="salvarPaciente"
                  route
                  :to="''"
                  >Salvar</v-btn
                >
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-card-text>
                    <div class="text-h5 pa-8 text-center">{{ mensagem }}</div>
                  </v-card-text>
                  <v-card-actions class="justify-center pb-4">
                    <v-btn
                      text
                      depressed
                      class="grey lighten-2 grey--text texte-darken-6"
                      @click="dialog.value = false"
                      >Ok</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PacienteMixin from '../../../mixins/PacienteMixin';
export default {
  mixins: [PacienteMixin],
  props: ['exibePaciente', 'exibirBotao', 'exibirMenu'],
  emits: ['emitterexbpac', 'emitterexbbot', 'emitterexmen'],
  data() {
    return {
      componentKey: 0,
      loading: false,
      exibirInfoMedicas: false,
      enabled: false,
      formValidado: true,
      rules: {
        required: value => !!value || 'Obrigatório.',
      },
      mensagem: 'Paciente não foi salvo verifique os dados',

      // pacientes: {},
      paciente: {},
      criaPaciente: true,
      salvoOuEditado: '',
      generos: ['FEMININO', 'MASCULINO'],

      racas: ['Branco', 'Amarelo', 'Pardo', 'Preto'],
      escolhaSimNao: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],
      // rules IMP
      requiredRules(property) {
        return !!property || 'Este campo é obrigatório.';
      },
      // cpfRules: (v) => v.length == 14 || "São necessários 11 caracteres",
      // cepRules: (v) => v.length == 8 || "São necessários 8",
    };
  },
  computed: {
    computedExibirPaciente: {
      get() {
        return this.exibirPaciente;
      },
      set(val) {
        this.$emit('emitterexbpac', val);
      },
    },
    computedExibirBotao: {
      get() {
        return this.exibirBotao;
      },
      set(val) {
        this.$emit('emitterexbbot', val);
      },
    },
    computedExibirMenu: {
      get() {
        return this.exibirMenu;
      },
      set(val) {
        this.$emit('emitterexmen', val);
      },
    },
  },

  methods: {
    // saber se exibe as informações médicas do paciente ou não
    handleInfoMedicas() {
      this.exibirInfoMedicas = !this.exibirInfoMedicas;
      if (this.exibirInfoMedicas && this.pacienteById.historico == null) {
        this.pacienteById.historico = {};
      }
    },

    // calcular a idade do paciente
    idadePaciente() {
      if (this.pacienteById.dataNascimento != undefined) {
        let idade = this.pacienteById.dataNascimento.split('/');

        return this.calculaIdade(idade[2], idade[1], idade[0]);
      }
    },

    calculaIdade(ano_aniversario, mes_aniversario, dia_aniversario) {
      const hoje = new Date();
      const nascimento = new Date(
        ano_aniversario,
        mes_aniversario,
        dia_aniversario
      );

      var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
      if (
        new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
        new Date(
          hoje.getFullYear(),
          nascimento.getMonth(),
          nascimento.getDate()
        )
      )
        diferencaAnos--;
      return diferencaAnos;
    },

    currentRouteName() {
      if (this.$route.name === 'ScreenPaciente') {
        this.criaPaciente = false;
      } else {
        this.criaPaciente = true;
      }
    },

    getPacienteId() {
      const id = this.$route.query.id;
      this.getPacienteById(id);
    },

    // verificar se o cep eh válido
    cepValido(cep) {
      return (this.cepEhValido = cep.length == 8);
    },

    forceReRender() {
      this.componentKey++;
    },

    // preencher o endereço do paciente
    fillAdress(endereco) {
      this.pacienteById.endereco = endereco.logradouro;
      this.pacienteById.cidade = endereco.localidade;
      this.pacienteById.estado = endereco.uf;
      this.pacienteById.bairro = endereco.bairro;
    },

    // buscar o endereço do paciente
    async getAdress() {
      const cep = this.pacienteById.cep;
      const url = `https://servidor-cliente-nodejs.herokuapp.com/http://viacep.com.br/ws/${cep}/json/`;
      const dados = await fetch(url);
      const endereco = await dados.json();
      if (this.cepValido(cep)) {
        if (Object.prototype.hasOwnProperty.call(endereco, 'erro')) {
          this.erro = true;
          this.mensagem = 'O CEP informado não existe.';
        } else {
          this.fillAdress(endereco);
          this.forceReRender();
        }
      } else {
        this.mensagem = 'O CEP informado não é válido.';
      }
    },

    // salvar o cadastro de um novo paciente e salvar a edição de um cadastro de um paciente já cadastrado
    async salvarPaciente() {
      if(!this.formValidado )
      this.loading = true;
      await this.$refs.formularioPaciente.validate()
      

      if (this.criaPaciente) {
        this.salvoOuEditado = 'salvo';

        const hora = this.$route.query.hora;
        const data = this.$route.query.data;
        const medico = this.$route.query.medico;

        if (navigator.onLine) {
          this.loading = true;
          await this.postPaciente(this.pacienteById).then(resp => {
            
            
            if (hora != undefined) {
              
              setTimeout(() =>
                this.$router.push(
                  `/agendamento/?hora=${hora}&medico=${medico}&data=${data}&paciente=${resp.data.id}`,
                  2000
                )
              );
            } else {
              setTimeout(() => this.$router.go(`Pacientes`), 2000);
              
              setTimeout(alert('paciente ' + this.salvoOuEditado), 3000);
            }
          }).catch(e => {
            this.loading = false;
            const erro = e.response.data.mensagem
            alert(`Erro ao salvar paciente.\nMensagem: ${erro} `)
            return
            
          });
          this.loading = false;
        } else {
          this.$store.commit('offline/SET_REQUISICAO', {
            requisicao: this.postPacienteOffline.bind({}),
            headers: { ...this.headers },
            urlBase: `${this.urlBase}`,
            codigo: 'POST_PACIENTE',
          });
          this.$store.commit('offline/SET_DADOS', this.pacienteById);
        }
      } else {
        const patchBody = this.formatPacientePatch(this.pacienteById);
        this.salvoOuEditado = 'editado';
        if (navigator.onLine) {
          await this.patchPaciente(patchBody);
          this.loading = false;
        } else {
          this.$store.commit('offline/SET_REQUISICAO', {
            requisicao: this.patchPacienteOffline.bind({}),
            headers: { ...this.headers },
            urlBase: `${this.urlBase}`,
            codigo: 'PATCH_PACIENTE',
          });
          this.$store.commit('offline/SET_DADOS', {
            paciente: this.pacienteById,
            patchBody: patchBody,
          });
        }
      }

      
      this.computedExibirMenu = true;
      this.computedExibirBotao = false;
      this.computedExibirPaciente = true;

      this.loading = false;
    },
  },

  mounted() {
    this.currentRouteName();
    if (this.criaPaciente == false) {
      this.getPacienteId();
    }
  },

  beforeDestroy() {
    this.resetaPacienteSelecionado();
  },
};
</script>
<style lang="css"></style>
