<template>
  <v-container>
    <v-card class="pa-5 ma-5" elevation="3">
      <v-form
        ref="formularioDeConsulta"
        @submit.prevent="salvarConsulta"
        v-model="formValidado"
      >
        <h2 class="pb-8">INFORMAÇÕES GERAIS</h2>

        <v-row>
          <v-col cols="12" md="12" sm="12" class="py-0">
            <v-text-field
              ref="nameField"
              label="Nome completo"
              class="maiuscula"
              
              required
              filled
              outlined
              validate-on-blur
              readonly
              v-model="consultaSelecionada.paciente.nome"
            />
          </v-col>
        </v-row>

        <v-row class="pb-2">
          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              ref="dataDeNascimentoField"
              
              label="Data de nascimento"
              class="maiuscula"
              v-mask="'##/##/####'"
              required
              filled
              outlined
              validate-on-blur
              
              readonly
              v-model="consultaSelecionada.paciente.dataNascimento"
            />
          </v-col>

          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              class="maiuscula"
              ref="generoField"
              
              label="Gênero"
              required
              filled
              outlined
              validate-on-blur
              :clearable="false"
              readonly
              v-model="consultaSelecionada.paciente.genero"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              
              class="maiuscula"
              v-mask="'###.###.###-##'"
              label="CPF"
              required
              filled
              outlined
              validate-on-blur
              :clearable="false"
              readonly
              v-model="consultaSelecionada.paciente.cpf"
            ></v-text-field>
          </v-col>
        </v-row>

        <h2 class="pb-8">CONTATO</h2>

        <v-row>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              
              class="maiuscula"
              ref="telefonePrincipalField"
              label="Telefone 1 (Whatsapp)"
              required
              filled
              outlined
              v-mask="'(##)#####-####'"
              validate-on-blur
              :clearable="false"
              readonly
              v-model="consultaSelecionada.paciente.telefonePrincipal"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="8" class="py-0">
            <v-text-field
              
              class="maiuscula"
              type="email"
              label="Email"
              required
              filled
              outlined
              validate-on-blur
              :clearable="false"
              readonly
              v-model="consultaSelecionada.paciente.email"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" class="py-0">
            <v-text-field
              
              class="maiuscula"
              ref="telefoneSecudarioField"
              label="Telefone 2"
              filled
              outlined
              v-mask="'(##)#####-####'"
              validate-on-blur
              :clearable="false"
              readonly
              v-model="consultaSelecionada.paciente.telefoneSecundario"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="8" class="py-0">
            <v-text-field
              
              class="maiuscula"
              ref="redeSocialField"
              label="Redes sociais"
              filled
              outlined
              validate-on-blur
              :clearable="false"
              readonly
              v-model="consultaSelecionada.paciente.redeSocial"
            ></v-text-field>
          </v-col>
        </v-row>

        <h2 class="pb-10 pt-4">AGENDAMENTO</h2>

        <v-row>
          <v-col cols="12" md="3">
            <!-- <v-select
              :disabled="exibirConsulta"
              label="Médico Solicitante"
              class="maiuscula"
              required
              filled
              outlined
              :clearable="false"
              validate-on-blur
              :items="nomesMedicosSolicitantes"
              :readonly="exibirConsulta"
              :rules="[value => !!value || 'Campo obrigatório']"
              @change="handleMedicoSolicitanteChange"
              v-model="consultaSelecionada.medicoSolicitante.nome"
            /> -->
            
            
            <VAutocomplete
            :disabled="exibirConsulta"
              label="Médico Solicitante"
              class="maiuscula"
              filled
              outlined
              :clearable="false"
              
              :value="value"
              :items="nomesMedSolicitantes"
              :rules="[value => !!value || 'Campo obrigatório']"
              @change="handleMedicoSolicitanteChange"
              v-model="consultaSelecionada.medicoSolicitante.nome"
            />
            <v-btn @click="modalMed.active = true">CRIAR MÉDICO SOLICITANTE</v-btn>
          </v-col>

          <v-col cols="12" md="3">
            <v-select
              :disabled="exibirConsulta"
              class="maiuscula"
              label="Médico Executante"
              required
              validate-on-blur
              filled
              outlined
              :clearable="false"
              :rules="[value => !!value || 'Campo obrigatório']"
              :items="nomesMedicosExecutantes"
              :readonly="exibirConsulta"
              @change="handleMedicoExecutanteChange"
              v-model="consultaSelecionada.medicoExecutante.nome"
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              :disabled="exibirConsulta"
              class="maiuscula"
              label="Data"
              v-mask="'##/##/####'"
              required
              validate-on-blur
              filled
              outlined
              :clearable="false"
              :readonly="exibirConsulta"
              :rules="[value => !!value || 'Campo obrigatório']"
              v-model="consultaSelecionada.data"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              class="maiuscula"
              label="Horário"
              required
              validate-on-blur
              filled
              outlined
              :clearable="false"
              :rules="[value => !!value || 'Campo obrigatório']"
              :readonly="exibirConsulta"
              :disabled="exibirConsulta"
              v-model="consultaSelecionada.hora"
            />
          </v-col>

          <!-- <v-col cols="12" md="4">
            <v-select
              class="maiuscula"
              label="Convênio"
              validate-on-blur
              :disabled="exibirConsulta"
              filled
              outlined
              :clearable="false"
              required
              v-model="consultaSelecionada.convenio"
              :items="nomesConvenios"
              @change="handleConvenioChange"
              :rules="[value => !!value || 'Campo obrigatório']"
            />
          </v-col> -->

          <v-col cols="12" md="4">
            <v-text-field
              :disabled="exibirConsulta"
              class="maiuscula"
              label="Solicitação de Desconto"
              type="number"
             
              validate-on-blur
              filled
              outlined
              :clearable="false"
              :readonly="exibirConsulta"
              v-model="consultaSelecionada.desconto"
              @change="formatarDesconto"
            />
            
          </v-col>

          

          <v-col cols="12" md="4">
            <v-select
              :disabled="exibirConsulta"
              label="Pagamento"
              class="maiuscula"
              :clearable="false"
              validate-on-blur
              filled
              outlined
              required
              :readonly="exibirConsulta"
              :items="this.formasPagamento"
              v-model="consultaSelecionada.formaDePagamento"
              :rules="[value => !!value || 'Campo obrigatório']"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox
              v-model="consultaSelecionada.gemelar"
              :disabled="exibirConsulta"
              class="d-flex justify-content-start align-items-center mt-3"
              label="Gemelar."
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <!-- <v-col
            v-if="consultaSelecionada.formaDePagamento === 'CARTAO'"
            cols="12"
            md="6"
          >
            <v-text-field
              :disabled="exibirConsulta"
              label="Nome no CARTAO"
              class="maiuscula"
              :clearable="false"
              validate-on-blur
              filled
              outlined
              required
              :readonly="exibirConsulta"
              v-model="consultaSelecionada.nomeNoCARTAO"
              :rules="[value => !!value || 'Campo obrigatório']"
            />
          </v-col> -->

          <v-col
            v-if="consultaSelecionada.formaDePagamento === 'CARTAO'"
            cols="12"
            md="2"
          >
            <v-select
              :disabled="exibirConsulta"
              label="Número de Parcelas"
              class="maiuscula"
              :clearable="false"
              validate-on-blur
              filled
              outlined
              required
              :readonly="exibirConsulta"
              :items="this.quantidadeParcelas"
              v-model="consultaSelecionada.quantidadeDeParcelas"
              :rules="[value => !!value || 'Campo obrigatório']"
            />
          </v-col>

          <v-col 
          v-if="consultaSelecionada.formaDePagamento === 'CARTAO'"
          cols="12" md="4">
            <v-text-field
              :disabled="exibirConsulta"
              class="maiuscula"
              label="Nome no cartão"
              
              validate-on-blur
              filled
              outlined
              :clearable="false"
              :readonly="exibirConsulta"
              v-model="consultaSelecionada.nomeNocartao"
             
            />
          </v-col>
          <v-col
            v-if="consultaSelecionada.formaDePagamento === 'CARTAO'"
            cols="12"
            md="2"
          >
            <v-select
              :disabled="exibirConsulta"
              label="Bandeira do cartão"
              class="maiuscula"
              :clearable="false"
              validate-on-blur
              filled
              outlined
              required
              :readonly="exibirConsulta"
              :items="['Mastercard','Visa', 'Elo', 'American Express', 'Hipercard', 'Alelo']"
              v-model="consultaSelecionada.bandeira"
              :rules="[value => !!value || 'Campo obrigatório']"
            />
          </v-col>

          <!-- <v-col
            v-if="consultaSelecionada.formaDePagamento === 'CARTAO'"
            cols="12"
            md="2"
          >
            <v-select
              :disabled="exibirConsulta"
              label="Bandeira"
              class="maiuscula"
              :clearable="false"
              validate-on-blur
              filled
              outlined
              required
              :readonly="exibirConsulta"
              :items="this.selecaoBandeira"
              v-model="consultaSelecionada.bandeira"
              :rules="[value => !!value || 'Campo obrigatório']"
            />
          </v-col> -->
        </v-row>
        <h2 class="pb-8 pt-8">PROCEDIMENTOS</h2>
       
       
        <div>
          <v-row
            v-for="(procedimento, indexProc) of procedimentos"
            :key="indexProc"
          >
         
            <v-col cols="12">
              <h3>PROCEDIMENTO {{ indexProc + 1 }}</h3>
            </v-col>
            
              <v-col class="ml-2" cols="12" md="4">
              <v-select
                class="maiuscula ml-2"
                label="Convênio"
                validate-on-blur
                :disabled="exibirConsulta"
                filled
                outlined
                :clearable="false"
                required
                v-model="procedimentos[indexProc].convenio.nome"
                :items="nomesConvenios"
                @change="handleConvenioChangeProc(procedimentos[indexProc].convenio.nome, indexProc)"
                :rules="[value => !!value || 'Campo obrigatório']"
              />
            </v-col>

            <v-col cols="12">
              <v-row
                class="mt-1 ml-2"
                v-for="(teste, indexExame) in procedimentos[indexProc].proc
                  .exames"
                :key="indexExame"
              >
                <v-col cols="10">
                 
                  <VAutocomplete
                    class="maiuscula"
                    label="Exame"
                    required
                    :clearable="false"
                    validate-on-blur
                    filled
                    :disabled="
                      !!procedimentos[indexProc].proc.exames[indexExame]
                        .valorExameConvenio.exame.nome
                    "
                    outlined
                    :readonly="exibirConsulta"
                    :items="nomesExames"
                    @change="
                      handleExameChangeProc(
                        procedimentos[indexProc].proc.exames[indexExame]
                          .valorExameConvenio.exame.nome,
                        indexProc,
                        indexExame
                      )
                    "
                    v-model="
                      procedimentos[indexProc].proc.exames[indexExame]
                        .valorExameConvenio.exame.nome
                    "
                    :rules="[value => !!value || 'Campo obrigatório']"
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    plain
                    small
                    v-if="exibirBotao"
                    @click="removeExameProc(teste, indexProc, indexExame)"
                    class="pl-0 mt-3"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="ml-2" cols="12">
              <v-btn
                plain
                small
                v-if="exibirBotao"
                @click="adicionaExameProc(indexProc)"
                class="pl-0"
              >
                <v-icon>mdi-plus-thick</v-icon>
                <span>Adicionar exame</span>
              </v-btn>
            </v-col>
            <v-col class="ml-2" cols="12">
              <v-btn
                small
                v-if="exibirBotao"
                @click="calculaValorProcedimento(indexProc)"
                class="pl-2"
              >
                <span>Calcular valor do Procedimento</span>
              </v-btn>
              <span class="ml-4" v-if="exibirBotao"
                >Valor: {{ procedimentos[indexProc].valor }}</span
              >
            </v-col>
          </v-row>
        </div>
        <br />
        <v-btn
          plain
          small
          v-if="exibirBotao"
          @click="adicionaProcedimento"
          class="pl-0"
        >
          <v-icon>mdi-plus-thick</v-icon>
          <span>Adicionar Procedimento</span>
        </v-btn>
        <template v-if="alertDesconto">
            <br>
        <v-alert
          type="info"
          title="Alerta do desconto"

          
        >O valor de desconto não pode ser maior que o valor total da consulta</v-alert>
      </template>
      <template v-if="temConsultaSemana && criaConsulta">
            <br>
        <v-alert
          type="info"
          title="Alerta da consulta"

          
        >A paciente já tem  consulta marcada nessa semana, {{ consultasSemana.length > 1 ? ' nos dias : ' : ' no dia: '}} {{ consultasSemana.join(", ") }}</v-alert>
      </template>
      <template v-if="alertValExame">
            <br>
        <v-alert
          type="info"
          title="Alerta procedimento não cadastrado"

          
        >A consulta possui procedimentos não cadastrados, cadastre o procedimento e o valor
        <v-btn  @click="abreModalCadastrar"  color="primary" class=" ml-5"
            >Clique aqui cadastrar</v-btn
          >
        </v-alert>
      </template>
      <template v-if="alertDesconto || alertValExame">
            <br>
        <v-alert
          type="error"
          title="Alerta do desconto"

          
        >A consulta não foi salva, siga as instruções acima e tente salvar novamente</v-alert>
      </template>
      

        <!-- <h2 class="pb-8 pt-8">EXAMES</h2>


        <v-row v-for="(exames, index) of consultaSelecionada.exames" :key="index">
          <v-col cols="12" md="4">
            <v-select :disabled="!!exames.nome" class="maiuscula" label="Exame" required :clearable="false"
              validate-on-blur filled outlined :readonly="exibirConsulta" :items="!exames.nome
                ? nomesExames.filter(
                  nomeExame =>
                    !examesjahSelecionado.includes(nomeExame) &&
                    nomeExame !== consultaSelecionada.exames[index].nome
                )
                : nomesExames.filter(
                  nomeExame =>
                    !examesjahSelecionado.includes(nomeExame) ||
                    nomeExame === consultaSelecionada.exames[index].nome
                )
                " @change="handleExameChange(exames.nome, index)" :rules="[value => !!value || 'Campo obrigatório']"
              v-model="exames.nome" />
          </v-col>

          <v-col v-if="exames.valorExameConvenio" cols="12" md="4">
            <v-text-field class="maiuscula" label="Valor (R$)" :disabled="true" required validate-on-blur
              :clearable="false" filled outlined :rules="[value => !!value || 'Campo obrigatório']"
              v-model="exames.valorExameConvenio.valor" />
          </v-col>
          <v-col v-if="exames.valor" cols="12" md="4">
            <v-text-field v-if="exames.valor" class="maiuscula" label="Valor (R$)" :disabled="true" required
              validate-on-blur :clearable="false" filled outlined :rules="[value => !!value || 'Campo obrigatório']"
              v-model="exames.valor" />
          </v-col>
          <v-col>
            <v-btn plain small v-if="exibirBotao" @click="removeExame(exames, index)" class="pl-0">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row> -->

        <div class="d-flex flex-column align-start">
          <!-- <v-btn
            plain
            small
            v-if="exibirBotao"
            @click="adicionaClique"
            class="pl-0"
          >
            <v-icon>mdi-plus-thick</v-icon>
            <span>Adicionar Exame</span>
          </v-btn> -->
         

          <v-btn type="submit" v-if="exibirBotao" :loading="carregandoExames"  color="primary" class="mt-5"
            >Salvar</v-btn
          >
          
          
          <v-col >
            <v-btn v-if="!!consultaSelecionada.id" block dense @click="abreModalEncaminhar">ENCAMINHAR PARA O FINANCEIRO</v-btn>
          </v-col>
        </div>
      </v-form>
    </v-card>
    <component
      :is="modalMed.modalComponent"
      v-bind="{ show: modalMed.active }"
      @close="closeModalMed"
    />
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
    <component
        :is="modalEnca.modalComponent"
        v-bind="{ show: modalEnca.active }"
        @close="closeModalEnca"
      />
  </v-container>
  
</template>

<script>
import axios from 'axios';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import ConsultasMixin from '../../../mixins/ConsultasMixin';
import MedicoMixin from '../../../mixins/MedicoMixin';
import PacienteMixin from '../../../mixins/PacienteMixin';
import moment from 'moment';
import ModalCadastrarProc from '@components/consultas/modalCadastrarProcedimento.vue'
import SubModalOcorrenciaVue from '@components/financeiro/SubModalOcorrencia.vue'
import modalCriarMedicoSolicitanteVue from '../modalCriarMedicoSolicitante.vue';

export default {
  mixins: [ConsultasMixin, MedicoMixin, PacienteMixin],
  props: ['exibirConsulta', 'exibirBotao', 'exibirMenu'],
  emits: ['emitterexbcons', 'emitterexbbotao', 'emitterexmen2'],
  data: () => ({
    exibeAgendamento: true,
    modal: {
        active: false,
        modalComponent: ModalCadastrarProc,
      },
      modalMed: {
        active: false,
        modalComponent: modalCriarMedicoSolicitanteVue,
      },
      carregandoExames: false,
    criaConsulta: true,
    temConsultaSemana: false,
    consultasSemana: [],
    salvoOuEditado: '',
    editandoExames: false,
    examesParaAdicionar: [],
    examesParaDeletar: [],
    examesjahSelecionado: [],
    tamanhoOriginal: 0,
    adicionou: false,
    mudou: false,

    modalEnca: {
      active: false,
      modalComponent: SubModalOcorrenciaVue,
    },


    procedimentos: [],
    examesProc: [],
    totalValor: 1,
    alertDesconto: false,
    alertValExame: false,

    exame: '',
    qntExamesOriginal: 0,
    formValidado: true,
    loadingSalvar: false,
  }),
  computed: {
    ...mapGetters([
      'medicosSolicitante',

    ]),
    computedExibirConsulta1: {
      get() {
        return this.exibirConsulta;
      },
      set(val) {
        this.$emit('emitterexbcons', val);
      },
    },
    computedExibirBotao1: {
      get() {
        return this.exibirBotao;
      },
      set(val) {
        this.$emit('emitterexbbotao', val);
      },
    },
    computedExibirMenu1: {
      get() {
        return this.exibirMenu;
      },
      set(val) {
        this.$emit('emitterexmen', val);
      },
    },
    nomesMedSolicitantes() {
      
      return this.$store.getters['medicosSolicitantes'] || [];},
    selectItems() {
      // filtra a lista de itens para remover os itens já selecionados

      return this.nomesExames.filter(
        nomeExame => !this.examesjahSelecionado.includes(nomeExame)
      );
    },
  },

  methods: {
    currentRouteName() {
      if (this.$route.query.id == undefined) {
        this.criaConsulta = true;
        this.resetaConsultaSelecionada();
      } else {
        this.criaConsulta = false;
      }
    },
    mostraProc() {},
    abreModal() {
      this.modal.active = true;
      
    
    },
    closeModal() {
      this.modal.active = false;
      
     
      
    },
    abreModalEncaminhar() {
      this.modalEnca.active = true;
      
    
    },
    closeModalEnca() {
      this.modalEnca.active = false;
      
     
      
    },
    closeModalMed() {
      this.modalMed.active = false;
      
     this.$forceUpdate
      
    },
   async removeExameProc(exame, indexProc, indexExame) {
      this.mudou = true;
      this.adicionou = false;
      this.mudou = true;
      let nomeantigo = exame.valorExameConvenio.exame.nome;
      let idParaDeletar = exame.id;
      var exameIdParaRemover = exame.id;
     
      let teste
      try{
        
        if(!this.criaConsulta){
         teste = await this.laudoExists(exame.id)
         

        if(teste){
        alert("O EXAME NÃO PODE SER REMOVIDO \n POIS JÁ POSSUI UM LAUDO ASSOCIADO.")
        return
        }
      }

      }catch{

      }finally{
        if(teste){return}
        if (this.criaConsulta) {
        this.procedimentos[indexProc].proc.exames = this.procedimentos[
          indexProc
        ].proc.exames.filter(
          exame => exame.valorExameConvenio.exame.nome !== nomeantigo
        );
      } else {
        if (!idParaDeletar) {
          this.examesParaAdicionar = this.examesParaAdicionar.filter(
            exame => exame.exameId !== exameIdParaRemover
          );
        } else {
          this.examesParaDeletar.push(exame.id);
        }

        this.procedimentos[indexProc].proc.exames = this.procedimentos[
          indexProc
        ].proc.exames.filter(
          exame => exame.valorExameConvenio.exame.nome !== nomeantigo
        );
      }
      this.examesjahSelecionado = this.examesjahSelecionado.filter(
        exame => exame !== nomeantigo
      );


      }
      
      
      

     
      this.$forceUpdate();
    },
    adicionaExameProc(index) {
      this.procedimentos[index].proc.exames.push({
        id: '',
        nome: '',
        valorExameConvenio: { exame: { nome: '' } },
      });

      this.$forceUpdate();
    },
    formatarDesconto() {
      // Formatar o valor para ter sempre duas casas decimais
       // Formatar o valor para ter sempre duas casas decimais
       let valorFormatado = parseFloat(this.consultaSelecionada.desconto.replace(/[^\d.]/g, '')).toFixed(2);

// Atualizar o modelo
this.consultaSelecionada.desconto = valorFormatado;

// Emitir um evento para forçar a atualização do modelo pela máscara
Vue.nextTick(() => {
  this.$forceUpdate();
});
    },
    async handleExameChangeProc(exameNome, procedimento, exame) {
      if (exameNome == '') return;
      this.carregandoExames =  true


      this.mudou = true;
      await this.getExames();
      const e = this.listaExames.find(e => e.nome === exameNome);
      let body = {};
      this.examesjahSelecionado.push(exameNome);
      this.procedimentos[procedimento].proc.exames[exame].id = e.id;
      //console.log(procedimento)

      if (this.convenioSelecionado.id != undefined) {
        const resp = await this.getValorConsulta({
          convenio: this.convenioSelecionado.id,
          exame: e.id,
        }).then((response)=>{
          this.carregandoExames =  false
          body = {
          consultaId: '',
          exameId: response.data.exameId,
          valorExameConvenioId: response.data.id,
          procedimento: procedimento,
        };
        this.procedimentos[procedimento].proc.exames[
          exame
        ].valorExameConvenioId = response.data.id;
        if (!this.criaConsulta) {
        body.consultaId = this.consultaSelecionada.id;
        this.examesParaAdicionar.push(body);
      }
        
        })

        body = {
          consultaId: '',
          exameId: resp.data.exameId,
          valorExameConvenioId: resp.data.id,
          procedimento: procedimento,
        };
        this.procedimentos[procedimento].proc.exames[
          exame
        ].valorExameConvenioId = resp.data.id;
        if (!this.criaConsulta) {
        body.consultaId = this.consultaSelecionada.id;
        this.examesParaAdicionar.push(body);
      }
      }else{
        this.carregandoExames =  false
      }
      if (!this.criaConsulta) {
        body.consultaId = this.consultaSelecionada.id;
        this.examesParaAdicionar.push(body);
      }
      

      this.$forceUpdate();
    },
    abreModalCadastrar(){
      
      this.abreModal()
      
    },
    async handleExameChange(exameNome, index) {
      if (exameNome == '') return; // verificacao
      this.mudou = true;

      this.editandoExames = true;
      var idDelete2 = this.consultaSelecionada.exames[index].id;
      let nomeantigo = this.consultaSelecionada.exames[index].nome;
      let antigoExameId = this.consultaSelecionada.exames[index].exameId;

      if (this.consultaSelecionada.exames[index].exameId) {
        antigoExameId = this.consultaSelecionada.exames[index].exameId;
      }

      if (this.adicionou) {
        nomeantigo =
          this.consultaSelecionada.exames[index].valorExameConvenio.nome;
      } else {
        this.examesjahSelecionado = this.examesjahSelecionado.filter(
          exame => exame !== nomeantigo
        );

        // let valorExameConvenio = {
        //       exame: {
        //         nome: '',
        //       },
        //       convenio: {
        //         nome: '',
        //       },
        //     };
        // this.consultaSelecionada.exames.splice(index,0, valorExameConvenio);
      }

      const exame = this.consultaSelecionada.exames.filter(
        exame => exame.nome == exameNome
      )[0];
      await this.getExames();
      const e = this.listaExames.find(e => e.nome === exameNome);

      if (exame.valorExameConvenio) {
        exame.valorExameConvenio.exame = e;
      }

      exame.id = e.id;
      exame.exameId = e.id;

      if (this.convenioSelecionado.id != undefined) {
        const resp = await this.getValorConsulta({
          convenio: this.convenioSelecionado.id,
          exame: exame.id,
        });
        exame.valorExameConvenioId = resp.data.id;
        exame.valorExameConvenio = resp.data;
      }
      if (this.adicionou) {
        var bodyPost = {
          consultaId: this.consultaSelecionada.id,
          exameId: exame.exameId,
          valorExameConvenioId: exame.valorExameConvenioId,
          procedimento: index,
        };
        this.examesjahSelecionado.push(exameNome);
        this.examesParaAdicionar.push(bodyPost);
      } else {
        if (!!idDelete2) {
          this.examesParaDeletar.push(idDelete2);
          this.consultaSelecionada.exames[index].valor = undefined;
        } else {
          this.examesjahSelecionado = this.examesjahSelecionado.filter(
            exame => exame !== nomeantigo
          );

          var bodyPost = {
            consultaId: this.consultaSelecionada.id,
            exameId: exame.exameId,
            valorExameConvenioId: exame.valorExameConvenioId,
            procedimento: index,
          };

          this.examesParaAdicionar.push(bodyPost);

          this.examesParaAdicionar = this.examesParaAdicionar.filter(
            exame => exame.exameId !== antigoExameId
          );
        }

        if (!this.examesjahSelecionado.includes(nomeantigo)) {
          this.examesjahSelecionado.push(nomeantigo);
        }
      }

      this.adicionou = false;
      this.$forceUpdate();
    },
    async calculaValorProcedimento(index) {
      let listaExames = this.procedimentos[index].proc.exames;
      

      let valExame = await this.getValorProcedimento({
        convenioId: this.procedimentos[index].convenio.id,
        exames: listaExames,
      });
      let valor = valExame == "O procedimento não está cadastrado no sistema, não foi possivel calcular" ? 0: valExame
      this.totalValor += valor
      this.procedimentos[index].valor = valExame;
      this.procedimentos[index].procedimento = index;
      this.$forceUpdate();
    },

    adicionaClique() {
      this.consultaSelecionada.exames.push({
        valorExameConvenio: {
          exame: {
            nome: '',
          },
          convenio: {
            nome: '',
          },
        },
      });
      this.adicionou = true;
    },
    async removeExame(item, index) {
      this.adicionou = false;
      this.mudou = true;
      let nomeDoExameParaTirar = item.nome;

      var idDelete = item.idExamePorConsulta;

      if (!this.criaConsulta) {
        if (!!idDelete) {
          this.examesParaDeletar.push(idDelete);
          this.examesjahSelecionado = this.examesjahSelecionado.filter(
            exame => exame !== nomeDoExameParaTirar
          );
        } else {
          var exameIdParaRemover =
            this.consultaSelecionada.exames[index].valorExameConvenio.exame.id;
          let exameParaTirarDoSelect =
            this.consultaSelecionada.exames[index].valorExameConvenio.exame.id;

          this.examesParaAdicionar = this.examesParaAdicionar.filter(
            exame => exame.exameId !== exameIdParaRemover
          );
          this.examesjahSelecionado = this.examesjahSelecionado.filter(
            exame => exame !== exameParaTirarDoSelect
          );
          //tirar da lista de adicionar
        }
      }
      this.consultaSelecionada.exames.splice(
        this.consultaSelecionada.exames.indexOf(item),
        1
      );

      this.$forceUpdate();
    },
    async getConsultaId() {
      const id = this.$route.query.id;
      await this.getConsultaById(id).then(
        () => (this.qntExamesOriginal = this.consultaSelecionada.exames.length)
      );
    },
    getInfoRoute() {
      const dates = this.$route.query.data.split('-');
      let dia = dates[2];
      let mes = dates[1];
      let ano = dates[0];
      if (dia < 10) {
        dia = `0${dia}`;
      }
      if (mes < 10) {
        mes = `0${mes + 1}`;
      }
      const data = `${dia}/${mes}/${ano}`;
      const hora = this.$route.query.hora;
      const medico = this.$route.query.medico;
      const paciente = this.$route.query.paciente;
      this.consultaSelecionada.pacienteId = parseInt(paciente);

      this.consultaSelecionada.hora = hora;
      this.getMedicoById(medico).then(() => {
        this.setMedicoExecutanteConsulta(this.medicoSelecionado);
        this.$forceUpdate();
      });
      this.getPacienteById(paciente).then(() => {
        this.consultaSelecionada.paciente = this.pacienteById;
      });
      this.consultaSelecionada.medicoExecutanteId = parseInt(medico);
      this.consultaSelecionada.data = data;
    },
    handleConvenioChange(convenioNome) {
      this.setaConvenioSelecionado(convenioNome).then(() => {
        this.consultaSelecionada.exames.map(e => {
          e.valorExameConvenio.convenio = this.convenioSelecionado;
          // this.handleExameChange(e.valorExameConvenio.exame.nome);
        });
      });
    },
    handleConvenioChangeProc(convenioNome, indexProc){
      
    
      
      this.setaConvenioSelecionado(convenioNome).then(() => {
        // this.consultaSelecionada.exames.map(e => {
        //   e.valorExameConvenio.convenio = this.convenioSelecionado;
        //   // this.handleExameChange(e.valorExameConvenio.exame.nome);
        // });
        
        
        this.procedimentos[indexProc].convenio = this.convenioSelecionado;
        
      });
      
      
      
      
    },

    handleMedicoExecutanteChange(medicoNome) {
      this.getMedicoExecutanteByNome(medicoNome).then(medico => {
        this.consultaSelecionada.medicoExecutante = medico;
        this.consultaSelecionada.medicoExecutanteId = medico.id;
      });
    },
    handleMedicoSolicitanteChange(medicoNome) {
      this.getMedicoSolicitanteByNome(medicoNome).then(medico => {
        this.consultaSelecionada.medicoSolicitante = medico;
        this.consultaSelecionada.medicoSolicitanteId = medico.id;
      });
    },
    logicaFor(){
      let semCadastro;
      this.procedimentos.forEach(proc => {
        
        semCadastro = proc.valor == 'O procedimento não está cadastrado no sistema, não foi possivel calcular'
      });
      
      this.alertValExame = semCadastro
      // if(this.alertValExame){
      //   this.abreModal()
      // }
    },
    async salvarConsulta() {
      await this.$refs.formularioDeConsulta.validate();

    
      if(this.consultaSelecionada.formaDePagamento !== 'CARTAO'){
        this.consultaSelecionada.quantidadeDeParcelas = 0
      }
      
      
      

      if (!this.formValidado || this.loadingSalvar) return;
      

      if (this.criaConsulta) {
       
        if(!!this.consultaSelecionada.desconto){
        if(this.consultaSelecionada.desconto > this.totalValor){
          this.alertDesconto = true
          this.loadingSalvar = false;
      this.exibirBotao = true;
      this.computedExibirBotao1 = true;
          return
        }else{
          this.alertDesconto = false
        }
      }
      for(let i in this.procedimentos){
        await this.calculaValorProcedimento(i)
       
        
      }
      
        let semCadastro;
        this.procedimentos.forEach(proc => {
          
          semCadastro = proc.valor == 'O procedimento não está cadastrado no sistema, não foi possivel calcular'
        });
        
        this.alertValExame = semCadastro
        if(this.alertValExame){
          this.alertDesconto = false
          this.loadingSalvar = false;
          this.exibirBotao = true;
          this.computedExibirBotao1 = true;
          return
        }
        this.loadingSalvar = true;
      this.exibirBotao = false;
      this.computedExibirBotao1 = false;
        delete this.consultaSelecionada.paciente;
        delete this.consultaSelecionada.medicoExecutante;
        delete this.consultaSelecionada.medicoSolicitante;
        delete this.consultaSelecionada.valorExameConvenio;
        //this.consultaSelecionada.gemelar = '';
        this.procedimentos.map(item => {
          let procedimento = item.procedimento;
          let lista = item.proc.exames.map(item => {
            const body = {
              consultaId: 'ok',
              exameId: item.id,
              procedimento: procedimento,
              valorExameConvenioId: item.valorExameConvenioId,
            };
            this.examesProc.push(body);
            return body;
          });
          return lista;
        });

        if (navigator.onLine) {
          this.consultaSelecionada.data = moment(
            this.consultaSelecionada.data,
            'DD/MM/YYYY'
          ).format('YYYY/MM/DD');
          const resp = await this.postConsulta(
            this.consultaSelecionada,
            this.examesProc
          );

          this.salvoOuEditado = 'salvo';

          alert('A CONSULTA FOI SALVA');

          // aqui vai ser o código de abrir o modal

          setTimeout(() => this.$router.push('/recepcao'), 2000);
        } else {
          this.$store.commit('offline/SET_REQUISICAO', {
            requisicao: this.postConsultaOffline.bind({}),
            headers: { ...this.headers },
            urlBase: `${this.urlBase}`,
            codigo: 'POST_CONSULTA',
          });
          this.$store.commit('offline/SET_DADOS', this.consultaSelecionada);
        }
      } else {
        delete this.consultaSelecionada.paciente;
        delete this.consultaSelecionada.medicoExecutante;
        delete this.consultaSelecionada.medicoSolicitante;
        delete this.consultaSelecionada.pagamentoId;

        this.salvoOuEditado = 'editado';
        //this.putConsulta(putBody);
        if (navigator.onLine) {
          this.consultaSelecionada.data = moment(
            this.consultaSelecionada.data,
            'DD/MM/YYYY'
          ).format('YYYY/MM/DD');

          if (this.qntExamesOriginal < this.consultaSelecionada.exames.length) {
            for (
              let i = this.qntExamesOriginal;
              i < this.consultaSelecionada.exames.length;
              i++
            ) {
              const exame = this.consultaSelecionada.exames[i];
              exame.consultaId = this.consultaSelecionada.id;

              delete exame.valorExameConvenio;

              // await axios.post(
              //   `${this.urlBase}ExamePorConsulta`,
              //   exame,
              //   this.headers
              // );
            }
          }
          if (this.mudou) {
            try {
              for (var exame in this.examesParaAdicionar) {
                await this.postExamePorConsulta(
                  this.examesParaAdicionar[exame]
                ).then((resp)=>{
               
                  this.examesParaAdicionar.filter(exame2 => exame2.exameId == this.examesParaAdicionar[exame].exameId)
                  
                  this.examesParaAdicionar.splice(exame,1)
                 
                
                }).catch((eer)=>{
                  alert("erro ao editar consulta, tente novamente")
                  


                  return

                });
              }
              for (var idExame in this.examesParaDeletar) {
                await this.deleteExamePorConsulta(
                  this.examesParaDeletar[idExame]
                ).then((resp)=>{
                  
                  this.examesParaDeletar.splice(idExame,1)
                  

                }).catch((eer)=>{
                  alert("erro ao editar consulta, tente novamente")
                  return

                });
              }
            } catch (e) {
              alert('Erro ao atualizar consulta');
            } finally {
            }
          }
          let consulta = {...this.consultaSelecionada}
          delete consulta.pagamento;
          const patchBody = this.formatConsultaPatch(consulta);
          this.patchConsulta(patchBody);

          await this.atualizaValorPagamento(this.consultaSelecionada.id);
          this.$forceUpdate();
          alert('Consulta Editada');
          this.$forceUpdate();
        } else {
          this.$store.commit('offline/SET_REQUISICAO', {
            requisicao: this.patchConsultaOffline.bind({}),
            headers: { ...this.headers },
            urlBase: `${this.urlBase}`,
            codigo: 'PATCH_CONSULTA',
          });
          this.$store.commit('offline/SET_DADOS', this.consultaSelecionada);
        }
      }

      this.loadingSalvar = false;
      this.computedExibirMenu1 = true;
      this.computedExibirConsulta1 = true;
      this.computedExibirBotao1 = false;
      
    },
    adicionaProcedimento() {
      this.procedimentos.push({
        proc: {
          exames: [
            {
              nome: '',
              id: '',
              valorExameConvenioId: '',
              valorExameConvenio: { exame: { nome: '' } },
            },
          ],
        },
        convenio: {
          nome: this.convenioSelecionado.nome,
          id: this.convenioSelecionado.id,
        },
        
        
        valor: '',
        procedimento: 0,
      });

      this.$forceUpdate();
    },
    mesmaSemana(data1, data2) {
    // Verifica se as duas datas estão na mesma semana
    return moment(data1).isSame(data2, 'week');
},
  },
 
  async mounted() {
    this.currentRouteName();
    if (this.criaConsulta == false) {
      

      this.getConsultaId().then(()=>{if (
        this.consultaSelecionada.gemelar == 'true') {
        this.consultaSelecionada.gemelar = true;
      } else {
        this.consultaSelecionada.gemelar = false;
      }
      this.consultaSelecionada.formaDePagamento =
        this.consultaSelecionada.pagamento.formaDePagamento;
      this.consultaSelecionada.desconto =
        this.consultaSelecionada.pagamento.desconto;
        


      });
      
      

      let examesDaConsulta = {};

      for (let exame of this.consultaSelecionada.exames) {
      
        
        if (this.procedimentos[exame.procedimento] == undefined) {
          exame;
          this.procedimentos[exame.procedimento] = {
            proc: {
              exames: [exame],
            },
            valor: '',
            procedimento: exame.procedimento,
            
            convenio: exame.valorExameConvenio.convenio
          };
        } else {
          this.procedimentos[exame.procedimento].proc.exames.push(exame);
          
        }
      }
      this.procedimentos = this.procedimentos.filter((proc)=> proc != undefined )
      
      
      //console.log(this.procedimentos)
    } else {
      this.procedimentos.push({
        proc: {
          exames: [
            {
              nome: '',
              id: '',
              valorExameConvenioId: '',
              valorExameConvenio: { exame: { nome: '' } },
            },
          ],
          
        },
        
        convenio:{nome:'', id: ''},
        valor: '',
        procedimento: 0,
      });
      
      this.consultaSelecionada.gemelar = false;
      this.getInfoRoute();
    }
    //this.consultaSelecionada.gemelar = false;

    this.getNomesConvenios();
    this.getNomesExames();
    this.getMedicos().then(() => {
      this.getNomesMedicosExecutanteSolicitanteNew(this);
    });
    if (this.criaConsulta == false) {
      this.consultaSelecionada.formaDePagamento =
        this.consultaSelecionada.pagamento.formaDePagamento;
      this.consultaSelecionada.desconto =
        this.consultaSelecionada.pagamento.desconto;
      this.consultaSelecionada.quantidadeDeParcelas =
        this.quantidadeParcelas[
          this.consultaSelecionada.pagamento.parcelamento - 1
        ];

      // this.consultaSelecionada.data = moment(this.consultaSelecionada.data,'YYYY-MM-DD' ).format('DD-MM-YYYY')
      // console.log(this.consultaSelecionada.data)
      this.consultaSelecionada.convenio =
        this.consultaSelecionada.exames[0].valorExameConvenio.convenio.nome;
      this.setaConvenioSelecionado(
        this.consultaSelecionada.exames[0].valorExameConvenio.convenio.nome
      ).then(() => {});

      for (let index in this.consultaSelecionada.exames) {
        this.examesjahSelecionado.push(
          this.consultaSelecionada.exames[index].valorExameConvenio.exame.nome
        );
        this.examesProc.push(this.consultaSelecionada.exames[index]);
      } 
    }

    this.tamanhoOriginal = this.consultaSelecionada.exames.length;
    try {
        let diaAtual = moment();
        diaAtual.format('YYYY-MM-DD')
        const consultaInfo = await this.getConsultaByPacienteId(this.consultaSelecionada.pacienteId);
        
        consultaInfo.forEach(objeto => {
          if (this.mesmaSemana(diaAtual, objeto.data)) {
             
              this.temConsultaSemana = true;
              this.consultasSemana.push(moment(objeto.data, 'YYYY-MM-DD').format('DD-MM-YYYY'))
          } else {
             
          }
});
      } catch (error) {
        console.error('Erro ao consultar consultas:', error);
      } finally {
        this.loadingProc = false;
      }
  },
  beforeDestroy() {
    this.resetaConsultaSelecionada();
    this.resetaMedicoSelecionado();
  },
};
</script>
