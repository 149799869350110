<template lang="">
  <div>
    <LaudoObstetricaPerfilCervical></LaudoObstetricaPerfilCervical>
  </div>
</template>
<script>
import LaudoObstetricaPerfilCervical from '../components/laudos/formularios/exames/obstetricaComPerfilCervical/LaudoObstetricaPerfilCervical.vue';
export default {
  components: {
    LaudoObstetricaPerfilCervical,
  },
};
</script>
<style lang=""></style>
