<template lang="">
  <div>
    <v-container fluid style="max-width:95%">
      <v-row class="d-flex justify-center pa-0">
        <v-col cols="12" md="12" sm="12" class="pa-0">
          <v-select
            class="pa-0 ma-0"
            v-on:blur=""
            router
            :to="''"
            item-value="first"
            filled
            outlined
            label="Exame"
            @click.stop
            clearable
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ConsultasMixin from "../../mixins/ConsultasMixin";
export default {
  mixins: [ConsultasMixin],

  data() {
    return {
      exames: [],
      // this.consultaSelecionada.valorExameConvenio.exame.nome
      // exame: "",
    };
  },

  created() {
    this.getNomesExames();
  },

  beforeMount() {
    return this.getConsultaById(this.$route.query.id).then(() => {
      this.exames.push(this.consultaSelecionada.valorExameConvenio.exame.nome);
    });
  },

  mounted() {
  },

  beforeDestroy() {
    this.resetaConsultaSelecionada();
  },
};
</script>
<style lang="css"></style>
