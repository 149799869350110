import axios from "axios";
import { mapState, mapActions, mapGetters } from "vuex";
import { EMBRION_API_URL } from "../data/enviroment";

export default {
  data() {
    return {
      urlBase: EMBRION_API_URL,
      nomesMedicosExecutantes: [],
      nomesMedicosSolicitantes: [],
    };
  },
  computed: {
    ...mapState({
      medicos: "medicosState",
      medicoSelecionado: "medicoSelecionadoState",
    }),
    ...mapGetters(["listaMedicos", "medicoSelecionado", "headers"]),
    ...mapGetters('Auth', ['headers']),
  },
  methods: {
    ...mapActions({
      getMedicos: "getMedicos",
      getMedicoById: "getMedicoById",
      resetaMedicoSelecionado: "resetaMedicoSelecionado",
      setMedicoSelecionado: "setMedicoSelecionado",
    }),

    async getNomesMedicos() {
      await this.getMedicos();
      return this.listaMedicos.map((medico) => medico.nome);
    },

    async getMedicoExecutanteByNome(nome) {
      await this.getMedicos();
      for (let medico of this.listaMedicos) {
        if (medico.acesso == "Executante") {
          if (medico.nome == nome) {
            return medico;
          }
        }
      }
    },

    async getMedicoSolicitanteByNome(nome) {
      await this.getMedicos();
      for (let medico of this.listaMedicos) {
        if (medico.tipoMedico == "Solicitante") {
          if (medico.nome == nome) {
            return medico;
          }
        }
      }
    },

    async getNomesMedicosExecutanteSolicitante() {
      await this.getMedicos();

      for (let med of this.listaMedicos) {
        if (med.tipoMedico === "Executante") {
          this.nomesMedicosExecutantes.push(med.nome);
        } else {
          this.nomesMedicosSolicitantes.push(med.nome);
        }
      }
      
    },
    async getNomesMedicosExecutanteSolicitanteNew(context) {
      await this.getMedicos();

      for (let med of this.listaMedicos) {
        if (med.tipoMedico === "Executante") {
          this.nomesMedicosExecutantes.push(med.nome);
        } else {
          this.nomesMedicosSolicitantes.push(med.nome);
        }
      }
      
    },

    getMedicoByNome(nome) {
      let medicos = this.listaMedicos.filter((medico) => medico.nome === nome);
      return medicos.length > 0 ? medicos[0] : null;
    },
  },
};
