<template lang="">
  <div>
    <LaudoMorfologica></LaudoMorfologica>
  </div>
</template>
<script>
import LaudoMorfologica from "../components/laudos/formularios/exames/morfologica/LaudoMorfologica.vue";
export default {
  components: {
    LaudoMorfologica,
  },
};
</script>
<style lang=""></style>
