<template>
  <v-container>
    <v-card class="dark-1">
      <br />

      <v-row>
        <v-col class="ml-5 white--text" cols="4" md="2">
          <v-text-field
            label="Data"
            type="date"
            v-model="dataInicial"
          ></v-text-field>
          <v-checkbox
            class="mt-n6 white--text text--darken-3"
            label="Data atual"
            @change="selectCurrentDate('start')"
          >
          </v-checkbox>
        </v-col>

        <v-col class="ml-5 white--text" cols="4" md="2">
          <v-text-field
            label="Até:"
            type="date"
            v-model="dataFinal"
          ></v-text-field>
          <v-checkbox
            class="mt-n6 white--text text--darken-3"
            label="Data atual"
            @change="selectCurrentDate('end')"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="2" md="2">
          <v-select
            class="maiuscula white--tex"
            background-color="#FFFFFF"
            label="Convênio"
            filled
            @change="handleConvenioChange"
            outlined
            :clearable="false"
            required
            v-model="convenio"
            :items="[...this.nomesConvenios, 'TODOS']"
          />
        </v-col>
        <v-col cols="2" md="2">
          <v-select
            class="maiuscula white--tex"
            background-color="#FFFFFF"
            label="Médico"
            filled
            @change="handleMedicoChange"
            outlined
            :clearable="false"
            required
            v-model="medico"
            :items="[...this.nomesMedicosExecutantes, 'TODOS']"
          />
        </v-col>
        <v-col cols="2" md="2">
          <v-select
            label="Pagamento"
            background-color="#FFFFFF"
            class="maiuscula"
            :clearable="false"
            filled
            outlined
            required
            :items="[...this.formasPagamento, 'TODAS']"
            v-model="modelBody.formaDePagamento"
          />
        </v-col>
        <v-col cols="4" md="">
          <v-btn @click="buscaConsulta"
            ><v-icon large dense> mdi-magnify </v-icon></v-btn
          >
        </v-col>
      </v-row>

      <!-- <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busque por médico, exame, paciente, convênio ou valor"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title> -->

      <v-col>
        <v-data-table
          :headers="headers1"
          :items="consultasPagamentos"
          :items-per-page="-1"
          :search="search"
          class="elevation-1 maiuscula"
          no-data-text="Nenhum relátorio"
          loading-text="Buscando relátorios..."
          :loading="loadingRel"
          @click:row="handleClick"
        >
          <template v-slot:item.exames="{ item }">
            <div
              v-for="(exame, index) in item.exames"
              
              :key="index"
            >
            
            <span v-for=" (exame2, index2) in exame.exames" :key="index2">
             
              <span v-if="exame.exames.length == 1">
                {{ exame2 }}
               
              </span>
              <span v-else>
                <abbr
                :title="`${exame2}`">
                {{ getSigla(exame2) }} </abbr>
                
                <span v-if="exame.exames.length != index2 + 1">/</span>
              </span>

            </span>
           
          </div>
          </template>
          <template v-slot:item.valor="{ item }">
            <span
              :class="`${
                item.consulta.pagamento.formaDePagamento == 'CARTAO'
                  ? 'text--disabled'
                  : ''
              }`"
              >{{ item.valor}}
            </span>
          </template>
        </v-data-table>
        <br /><br />
        <v-row>
          <v-spacer></v-spacer>
          &nbsp;&nbsp;&nbsp;
          <strong style="margin-right: 10px"> Valor Total: </strong>
          <strong> R$ {{ formatarValor(valorTotal) }}</strong
          >&nbsp;&nbsp;&nbsp;
          <br />
        </v-row>
      </v-col>
    </v-card>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import ConsultasMixin from '@mixins/ConsultasMixin';
import PagamentosMixin from '@mixins/PagamentosMixin';
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import MedicoMixin from '@/mixins/MedicoMixin';
import VisualizarPagamentoModal from '../pagamento/modals/VisualizarPagamentoModal.vue';
export default {
  mixins: [ConsultasMixin, PagamentosMixin],
  data() {
    return {
      dataInicial: '',
      dataFinal: '',
      dataAtual: false,
      convenio: '',
      modal: {
        active: false,
        modalComponent: VisualizarPagamentoModal,
      },
      nomesConvenios: [],
      medico: '',
      selectAllConvenios: false,
      consultasComPagamentos: [],
      modelBody: {
        convenioId: '',
        medicoId: '',
        formaDePagamento: '',
      },
      consultasTotal: [],
      loadingRel: false,
      search: '',
      loading: true,
      headers1: [
        {
          text: 'Data',
          align: 'start',
          sortable: false,
          value: 'data',
        },
        { text: 'Nome', value: 'consulta.paciente.nome' },
        { text: 'Exames', value: 'exames' },
        // { text: 'Status', value: 'consulta.pagamento.statusPagamento' },
        // { text: 'Exame', value: 'exames' },
        { text: 'Pagamento', value: 'consulta.pagamento.formaDePagamento' },
        // { text: 'Convênio', value: 'convenio' },
        { text: 'Médico', value: 'consulta.medicoExecutante.nome' },
        { text: 'Valor', value: 'valor' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'consultaPagamentos',
      'consultasPagamentos',
      'valorTotalDia',
    ]),
    ...mapGetters('Auth', ['usuarioLogado']),
    valorTotal() {
      return this.$store.getters['Financeiro/valorTotalProd'] || null;
    },
    consultaPagamentos() {
      return this.$store.getters['Financeiro/consultaPagamentos'] || null;
    },
    consultasPagamentos() {
      return this.$store.getters['Financeiro/pagamentosProducao'] || [];
    },
  },
  created() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.dataInicial = `${year}-${month}-${day}`;
    this.dataFinal = `${year}-${month}-${day}`;
  },
  mounted() {
    const agora = moment();
    this.getNomesConvenios();
    this.getMedicos().then(() => {
      this.getNomesMedicosExecutanteSolicitante();
    });
    this.convenio = 'PARTICULAR';
    this.medico = this.medicoSelecionado.nome;
    this.setaConvenioSelecionado(this.convenio).then(() => {});

    this.getProducao(
      this,
      agora.format('DD/MM/YYYY'),
      this.medicoSelecionado.id,
      this.convenioSelecionado.id
    );
  },
  methods: {
    formatarValor(valor) {
      // Arredonda para 2 casas decimais e formata com vírgula para milhares
      return Number(valor)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    buscaConsulta() {
      const medicoId = this.medico == 'TODOS' ? '' : this.medicoSelecionado.id;
      const convenioId =
        this.convenio == 'TODOS' ? '' : this.convenioSelecionado.id;
      const formaPagamento =
        this.modelBody.formaDePagamento == 'TODAS'
          ? ''
          : this.modelBody.formaDePagamento;
      const dataInicial = this.dataInicial;
      const dataFinal = this.dataFinal;
      this.loadingRel = true;
      this.getProducao(
        this,
        dataInicial,
        dataFinal,
        medicoId,
        convenioId,
        formaPagamento
      ).then(() => {
        this.loadingRel = false;
      });
    },
    selectCurrentDate(field) {
      if (field === 'start') {
        this.dataInicial = this.getCurrentDate();
      } else if (field === 'end') {
        this.dataFinal = this.getCurrentDate();
      }
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    async handleClick(event) {
      
      this.setContaSelecionada(this, event);
      await this.getConsultaById(event.consulta.id);
     
      this.modal.active = true;
    },
    closeModal() {
     
     
       this.modal.active = false;
   },
    handleConvenioChange(convenioNome) {
      this.setaConvenioSelecionado(convenioNome).then(() => {});
    },
    async handleMedicoChange(nome) {
      this.loadingDialog = true;

      if (!nome) {
        this.resetaMedicoSelecionado();
        await this.getConsultaByData(this.dataSelecionada).then(() => {
          this.loadingDialog = false;
        });

        return;
      }
      let medico = await this.getMedicoByNome(nome);
      this.setMedicoSelecionado(medico);
    },

    getSigla(value) {
      if (value === 'TRANSLUCÊNCIA NUCAL') {
        return 'TN';
      }
      if (value === 'DOPPLER') {
        return 'DP';
      }
      if (value === 'PERFIL CERVICAL') {
        return 'PC';
      }
      if (value === 'COLO UTERINO') {
        return 'CUT';
      }
      if (value === 'AVALIAÇÃO MORFOLÓGICA DO PRIMEIRO TRIMESTRE') {
        return 'M1T';
      }

      return value;
    },

    async setaConsultasComPagamentos() {
      this.buscaConsultas('');
    },
    async buscaConsultas(value) {},
  },
};
</script>
