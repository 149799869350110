<template lang="">
  <div>
    <LaudoViloCorial></LaudoViloCorial>
  </div>
</template>
<script>
import LaudoViloCorial from '../components/laudos/formularios/exames/viloCorial/LaudoViloCorial.vue';
export default {
  components: {
    LaudoViloCorial,
  },
};
</script>
<style lang=""></style>
