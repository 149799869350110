<template>
  <v-container fluid>
    <FormInfoLaudo />

    <v-row justify="center">
      <v-col cols="12" class="pb-0">
        <v-select
          class="pa-0 ma-0"
          label="Exame Base"
          :items="examesDaConsulta"
          @click.stop
          @change="handleExameChange"
          chips
          multiple
          deletable-chips
          filled
          outlined
          clearable
        ></v-select>
      </v-col>

      <v-col cols="12" class="pt-0" align="end">
        <v-btn @click="fecharLaudos" class="mr-5" :disabled="estaNaTelaLaudo">
          <v-icon>mdi-close</v-icon>
          Fechar Laudo
        </v-btn>

        <v-btn @click="preencherLaudo" :disabled="examesVariacoes.length === 0">
          PREENCHER LAUDO
        </v-btn>
      </v-col>
    </v-row>
    <router-view />
  </v-container>
</template>

<script>
import laudos from '@/router/laudos';

import LaudosMixin from '@mixins/LaudosMixin';
import ConsultasMixin from '@mixins/ConsultasMixin';
import FormInfoLaudo from '../laudos/formularios/FormInfoLaudo.vue';
import barraDeBuscaExames from '../laudos/barraDeBuscaExames.vue';

export default {
  components: { FormInfoLaudo, barraDeBuscaExames },
  mixins: [LaudosMixin, ConsultasMixin],
  data: () => ({ examesVariacoes: [] }),
  computed: {
    laudosDisponiveis() {
      return laudos.map(({ name }) => {
        // 'laudo.nome do laudo' - split ['laudo', 'nome do laudo']
        const rotaSplitada = String(name).split('.');
        return rotaSplitada[1];
      });
    },
    estaNaTelaLaudo() {
      return this.$route.name === 'laudo';
    },
  },
  methods: {
    handleExameChange(examesBase) {
      this.examesVariacoes = examesBase;
    },
    handleChangeVariacoes(exames) {
      this.examesVariacoes = exames;
    },
    preencherLaudo() {
      const indexTN = this.examesVariacoes.findIndex(
        exame => exame === 'translucência nucal' || exame === 'mama'
      );

      const consulta = this.$route.query.consulta;
      const variacoes = [...this.examesVariacoes]; // todos os selecionados

      if (
        variacoes.includes('morfologica') &&
        variacoes.includes('perfil cervical') &&
        variacoes.includes('doppler') &&
        variacoes.length == 3
      ) {
        const exame = this.getExameByNome('MORFOLOGICA');

        this.$router.push({
          name: `laudo.${'morfologica com cervical e doppler'}`,
          query: { consulta, exame: exame.id },
        });

        return;
      }
      if (
        variacoes.includes('obstetrica') &&
        variacoes.includes('ecocardiograma') &&
        variacoes.includes('doppler') &&
        variacoes.includes('perfil cervical') &&
        variacoes.length == 4
      ) {
        const exame = this.getExameByNome('OBSTETRICA');

        this.$router.push({
          name: `laudo.${'obstetrica com doppler e ecocardiodoppler'}`,
          query: { consulta, exame: exame.id },
        });

        return;
      }
      if (
        variacoes.includes('obstetrica') &&
        variacoes.includes('doppler') &&
        variacoes.includes('ecocardiograma') &&
        variacoes.length == 3
      ) {
        const exame = this.getExameByNome('OBSTETRICA');

        this.$router.push({
          name: `laudo.${'obstetrica com doppler e ecocardiodoppler'}`,
          query: { consulta, exame: exame.id },
        });

        return;
      }
      if (
        (variacoes[0] === 'morfologica' &&
          variacoes.includes('perfil cervical')) ||
        (variacoes[0] === 'perfil cervical' &&
          variacoes.includes('morfologica'))
      ) {
        const exame = this.getExameByNome('MORFOLOGICA');

        this.$router.push({
          name: `laudo.${'morfologica com cervical'}`,
          query: { consulta, exame: exame.id },
        });

        return;
      }
      if (
        variacoes.includes('perfil cervical') &&
        variacoes.includes('doppler') &&
        variacoes.length == 2
      ) {
        const exame = this.getExameByNome('PERFIL CERVICAL');

        this.$router.push({
          name: `laudo.${'perfil cervical com doppler'}`,
          query: { consulta, exame: exame.id },
        });

        return;
      }
      if (
        variacoes.includes('doppler') &&
        variacoes.includes('morfologica') &&
        variacoes.length == 2
      ) {
        const exame = this.getExameByNome('MORFOLOGICA');

        this.$router.push({
          name: `laudo.${'morfologica com doppler'}`,
          query: { consulta, exame: exame.id },
        });

        return;
      }

      if (variacoes[0] === 'axila' && !variacoes.includes('mama')) {
        alert('Variação não suportada');

        return;
      }

      if (
        (variacoes[0] == 'axila' && variacoes[1] == 'mama') ||
        (variacoes[1] == 'axila' && variacoes[0] == 'mama')
      ) {
        const exame = this.getExameByNome('AXILA');
        this.$router.push({
          name: `laudo.${'axila'}`,
          query: { consulta, exame: exame.id },
        });
        return;
      }

      if (
        variacoes.includes('doppler') &&
        variacoes.includes('obstetrica') &&
        variacoes.length == 2
      ) {
        const exame = this.getExameByNome('OBSTETRICA');
        this.$router.push({
          name: `laudo.${'obstetricaDoppler'}`,
          query: { consulta, exame: exame.id },
        });
        return;
      }
      if (
        variacoes.includes('doppler') &&
        variacoes.includes('transvaginal') &&
        variacoes.length == 2
      ) {
        const exame = this.getExameByNome('TRANSVAGINAL');
        this.$router.push({
          name: `laudo.${'transvaginal com doppler'}`,
          query: { consulta, exame: exame.id },
        });
        return;
      }

      if (
        variacoes.includes('perfil cervical') &&
        variacoes.includes('obstetrica') &&
        variacoes.length == 2
      ) {
        const exame = this.getExameByNome('OBSTETRICA');

        this.$router.push({
          name: `laudo.${'obstetricaPerfilCervical'}`,
          query: { consulta, exame: exame.id },
        });
        return;
      }

      if (
        variacoes.includes('perfil cervical') &&
        variacoes.includes('doppler') &&
        variacoes.includes('obstetrica') &&
        variacoes.length == 3
      ) {
        const exame = this.getExameByNome('OBSTETRICA');

        this.$router.push({
          name: `laudo.${'obstetricaDopplerPerfilCervical'}`,
          query: { consulta, exame: exame.id },
        });
        return;
      }

      // #region Tranlucencia Nucal
      if (indexTN !== -1) {
        const nomeTN = variacoes.splice(indexTN, 1); // remove elementos baseado no index + quantidade
        const exameTN = this.getExameByNome(String(nomeTN).toUpperCase());

        var referencia = [
          'doppler',
          'avaliação morfológica do primeiro trimestre',
          'perfil cervical',
        ];

        var valorInexistente = false;

        for (var i = 0; i < variacoes.length; i++) {
          if (referencia.indexOf(variacoes[i]) === -1) {
            valorInexistente = true;
            break;
          }
        }

        if (nomeTN != 'mama' && !valorInexistente) {
          this.$router.push({
            name: 'laudo.translucência nucal',
            query: {
              variacoes,
              consulta,
              exame: exameTN.id,
            },
          });
          return;
        }
        if (nomeTN[0] === 'mama') {
          this.$router.push({
            name: `laudo.mama`,
            query: { consulta, exame: exameTN.id },
          });
          return;
        }
      }

      // #endregion

      // this.laudosDisponiveis aquivo de laudos(rotas)
      const verificaSeOExameEstaImplementado = this.laudosDisponiveis.includes(
        variacoes[0]
      );

      if (variacoes.length > 1 || !verificaSeOExameEstaImplementado) {
        alert('Variação não suportada');

        return;
      }

      let nomeExame = variacoes[0] == '4d' ? '4d' : (variacoes[0] == '3d' ? '3d' : variacoes[0].toUpperCase() )
      const exame = this.getExameByNome(nomeExame);
      this.$router.push({
        name: `laudo.${variacoes[0]}`,
        query: { consulta, exame: exame.id },
      });
    },
    fecharLaudos() {
      this.examesVariacoes = [];
      const { consulta } = this.$route.query;
      this.$router.push({ name: 'laudo', query: { consulta } });
      this.consultaSelecionada.liberada = undefined;
    },
  },
  mounted() {
    this.getExamesPorConsultaSelecionada(this.consultaSelecionada.id);
  },
};
</script>
