P
<template lang="">
  <div>
    <v-container fluid style="max-width: 95%" class="px-0">
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <controleConsultas></controleConsultas>
        </v-col>
      </v-row>
      <v-row dense block style="width: 100%">
        <v-col cols="12" md="20" sm="12" class="px-0">
          <v-row>
            <v-col cols="12" md="9" class="px-6 pt-2">
              <v-card class="grey lighten-4 text-right">
                <v-spacer></v-spacer>
                <v-card-text class="pt-5 pb-5">
                  <h1 class="text-center mt-2">
                    <v-row>
                      <v-spacer></v-spacer> <v-spacer></v-spacer>Agenda Diária
                      <v-spacer></v-spacer>
                      <v-btn class="mt-n2" @click="getAgendamentos()">
                        <v-icon> mdi-arrow-collapse-down</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-row>
                  </h1>
                </v-card-text>
              </v-card>

              <ListaConsultas
                v-if="
                  (!eMedico && nivelPermissao !== 4) ||
                  (eMedico && nivelPermissao == 1)
                "
              />
              <ListaConsultasMedicos v-if="nivelPermissao == 2" />
              <ListaConsultasMedicos v-if="nivelPermissao == 5" />
              <ListaDeConsultasDigitador v-if="nivelPermissao === 4" />
            </v-col>

            <v-col cols="6" md="3">
              <BotaoChamarPaciente v-if="nivelPermissao === 1" />
              <Calendario style="width: 280px" />
              <template v-if="nivelPermissao === 2 || nivelPermissao === 5">
                <v-btn
                  v-if="dataSelecionadaBloqueada"
                  color="primary"
                  class="mt-0 pa-3 ml-4"
                  @click="abreModal()"
                  >Habilitar agendamento</v-btn
                >
                <v-btn
                  v-else
                  color="primary"
                  class="mt-0 pa-3 ml-4"
                  @click="abreModal()"
                  >Desabilitar agendamento</v-btn
                >
              </template>
            </v-col>
          </v-row>
          <component
            :is="modal.modalComponent"
            v-bind="{ show: modal.active }"
            @close="closeModal"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import controleConsultas from '../homeRecepcao/controleConsultas.vue';
import BotaoChamarPaciente from '../homeRecepcao/botaoChamarPaciente.vue';
import Calendario from '../../components/Calendario.vue';
import modalDesabilitarAgendamento from './modalDesabilitarAgendamento.vue';
import ListaConsultas from '../consultas/ListaDeConsulta.vue';
import ListaConsultasMedicos from '../consultas/ListaDeConsultasMedico.vue';
import ListaDeConsultasDigitador from '../consultas/listaDeConsultasDigitador.vue';
import MetricasMixin from '@mixins/MetricasMixin';
import ConsultasMixin from '@mixins/ConsultasMixin';
import LoginMixin from '@mixins/LoginMixin';
import { mapGetters } from 'vuex';

export default {
  mixins: [MetricasMixin, ConsultasMixin, LoginMixin],
  components: {
    controleConsultas,
    BotaoChamarPaciente,
    Calendario,
    modalDesabilitarAgendamento,
    ListaConsultas,
    ListaConsultasMedicos,
    ListaDeConsultasDigitador,
  },
  data() {
    return {
      modal: {
        active: false,
        modalComponent: modalDesabilitarAgendamento,
      },
    };
  },
  computed: {
    ...mapGetters('Auth', ['eMedico', 'nivelPermissao']),
    ...mapGetters('Calendario', ['dataSelecionadaBloqueada']),

    dataSelecionadaBloqueada() {
      return this.$store.getters['Calendario/dataSelecionadaBloqueada'];
    },
  },
  methods: {
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },
  },
};
</script>
