<template lang="">
  <div>
    <LaudoObstetrica></LaudoObstetrica>
  </div>
</template>
<script>
import LaudoObstetrica from "../components/laudos/formularios/exames/obstetrica/LaudoObstetrica.vue"
export default {
  components: {
    LaudoObstetrica,
  },
};
</script>
<style lang=""></style>
