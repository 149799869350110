<template lang="">
  <div>
    <LaudoMorfologica1Trimestre></LaudoMorfologica1Trimestre>
  </div>
</template>
<script>
import LaudoMorfologica1Trimestre from "../components/laudos/formularios/exames/morfologica1Trimestre/LaudoMorfológica1Trimestre.vue";
export default {
  components: {
    LaudoMorfologica1Trimestre,
  },
};
</script>
<style lang=""></style>
