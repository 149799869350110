<template>

  <v-dialog v-model="show" transition="dialog-top-transition" width="80%">

    <v-card class="px-4 py-4">
      <span class="d-flex justify-end">
                <v-icon class="pa-3" @click="close">
                  mdi-close-circle-outline
                </v-icon>
              </span>
      <h1 class="text-center">Encaminhar para financeiro</h1>
      <br>



            <v-textarea
                width="100%"
                required
                validate-on-blur
                filled
                outlined
                no-resize
                label="Ocorrência"
                v-model="consultaSelecionada.ocorrencia" >
            </v-textarea>
            <v-row>
            <v-spacer></v-spacer>
            <v-btn  @click="close(),submitOcorrencia()">Enviar Ocorrência</v-btn>
            <v-spacer></v-spacer>
            
        </v-row>
        <br>
    </v-card>
  </v-dialog>

</template>

<script>
import axios from 'axios';
import ConsultasMixin from '@/mixins/ConsultasMixin';
import moment from 'moment';
export default {
  mixins: [
    ConsultasMixin,
    ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({

  }),

  methods: {
    close() {
      this.$emit('close');

    },
    async submitOcorrencia(){
      let dados = {
        data :  moment(this.consultaSelecionada.data,'DD/MM/YYYY').format("YYYY/MM/DD"),
        observacao: this.consultaSelecionada.ocorrencia,
        consultaId : this.consultaSelecionada.id,
        visualizado: false


      }
      await axios.post(`${this.urlBase}Ocorrencia`, dados, this.headers).then(()=>{
        this.consultaSelecionada.ocorrencia = ''

      });

    },


  },
};
</script>

<style>

</style>
