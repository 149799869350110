import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import { EMBRION_API_URL } from '../data/enviroment';

export default {
  data() {
    return {
      urlBase: EMBRION_API_URL,
    };
  },
  computed: {
    ...mapState({
      dataSelecionada: 'dataSelecionadaState',
    }),
    ...mapGetters(['dataSelecionada', 'datasConsultas', 'headers']),
    ...mapGetters('Auth', ['headers']),
  },
  methods: {
    ...mapActions({
      selecionaData: 'selecionaData',
      setDatasConsultas: 'setDatasConsultas',
    }),
  
    getNumeroConsultasMes(mes, ano, medicoId) {
      
      return axios.get(
        `${this.urlBase}Consulta/QuantidadeConsultasPorDia?ano=${ano}&mes=${mes}&medicoId=${medicoId}`,
        { headers: this.headers }
      );
    },
    async getDataComConsultas(context, dia, mes, ano, medicoId){
      const resp = axios.get(
        `${this.urlBase}Consulta/QuantidadeConsultasPorDia?ano=${ano}&mes=${mes}&medicoId=${medicoId}`,
        { headers: this.headers }
      ).then((resp)=>{
        const datas = resp.data;
        let arrayEvents = Object.keys(datas).map(data => {
          const n = datas[data];
          if (n > 0) {
            let [dia, mes, ano] = data.split('/');
            
            return `${ano}-${mes}-${dia}`;
          }
        });
        
        context.$store.commit('Calendario/SET_DATA_COM_CONSULTA', arrayEvents)
       
      });
    }
  },
  actions: {
    
  },
};
