<template lang="">
  <div>
    <Login></Login>
  </div>
</template>
<script>
import Login from "../components/Login.vue";
export default {
  components: { Login },
};
</script>
<style lang=""></style>
