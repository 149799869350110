<template>
  <v-container fluid>
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">ECOCARDIOGRAMA</h2>
    </v-card>
    <v-alert
      v-if="!!laudoSelecionado.laudoJson.folhas"
      outlined
      type="warning"
      border="left"
      text=""
    >
      Esse <strong>laudo</strong> teve a impressão
      <strong>personalizada.</strong>
      <v-btn text @click="adcionarConteudoEditorPersonalizado"
        >Clique aqui para visualizar</v-btn
      >
    </v-alert>

    <v-row class="pt-5" no-gutters>
      <template v-if="clicouNoPersonalizado">
        <v-col
          cols="12"
          class="form-group"
          v-for="(index, folha) in quantidadeFolhasEditorPersonalizado"
          :key="index"
        >
          <h2>Folha {{ folha + 1 }}</h2>
          <v-btn plain small @click="removeFolha(index)" class="pl-0">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <vue-editor v-model="conteudoEditorPersonalizado[folha]" />
        </v-col>

        <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
      </template>
      <template v-else>
        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeIdadeGestacional ? '' : 'text--disabled'
          }`"
        >
          <span>Idade gestacional:</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.idadeGestacional"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeIdadeGestacional"
          >
          </v-text-field>
          <span>semanas.</span>
          <v-select
            :items="['Compatível', 'Incompatível']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeIdadeGestacional"
            clearable
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.compativelIncompativel"
          >
          </v-select>
          <span>.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeIdadeGestacional"
            @click="
              camposExibidos.exibeIdadeGestacional = true;
              laudoSelecionado.laudoJson.idadeGestacional = undefined;
            "
          >
            <v-icon>mdi-eye-off</v-icon></v-btn
          >

          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeIdadeGestacional = false;
              laudoSelecionado.laudoJson.idadeGestacional = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeIndicacao ? '' : 'text--disabled'
          }`"
        >
          <span>Indicação:</span>
          <v-select
            :items="[
              'rotina',
              'malformação em exame anterior',
              'TN alterada? Ducto venoso alterado',
              '__________________________________',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeIndicacao"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.indicacao"
          >
          </v-select>
          <span>.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeIndicacao"
            @click="
              camposExibidos.exibeIndicacao = true;
              laudoSelecionado.laudoJson.indicacao = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeIndicacao = false;
              laudoSelecionado.laudoJson.indicacao = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeSitus ? '' : 'text--disabled'
          }`"
        >
          <span>Situs:</span>
          <v-select
            :items="[
              'solitus (normal)',
              'inverus',
              'ambiuguous(isomerismo direito)',
              'ambiuguous (isomerismo esquerdo)',
            ]"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeSitus"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.situs"
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.situs2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeSitus"
          >
          </v-text-field>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeSitus"
            @click="
              camposExibidos.exibeSitus = true;
              laudoSelecionado.laudoJson.situs = undefined;
              laudoSelecionado.laudoJson.situs2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeSitus = false;
              laudoSelecionado.laudoJson.situs = undefined;
              laudoSelecionado.laudoJson.situs2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibePosicaoCardiaca ? '' : 'text--disabled'
          }`"
        >
          <span>Posição cardíaca:</span>
          <v-select
            :items="['levocardia', 'dextrocardia', 'mesocardia']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibePosicaoCardiaca"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.posicaoCardiaca"
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.posicaoCardiaca2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibePosicaoCardiaca"
          >
          </v-text-field>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibePosicaoCardiaca"
            @click="
              camposExibidos.exibePosicaoCardiaca = true;
              laudoSelecionado.laudoJson.posicaoCardiaca = undefined;
              laudoSelecionado.laudoJson.posicaoCardiaca2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibePosicaoCardiaca = false;
              laudoSelecionado.laudoJson.posicaoCardiaca = undefined;
              laudoSelecionado.laudoJson.posicaoCardiaca2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeEixoCardiaco ? '' : 'text--disabled'
          }`"
        >
          <span>Eixo cardíaco:</span>
          <v-select
            :items="[
              'ápice para o lado esquerdo (cerca de 45º - normal)',
              'ápice para a direita',
              'ápice mesoposicionada',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeEixoCardiaco"
            v-model="laudoSelecionado.laudoJson.eixoCardiaco"
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.eixoCardiaco2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeEixoCardiaco"
          >
          </v-text-field>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeEixoCardiaco"
            @click="
              camposExibidos.exibeEixoCardiaco = true;
              laudoSelecionado.laudoJson.eixoCardiaco = undefined;
              laudoSelecionado.laudoJson.eixoCardiaco2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeEixoCardiaco = false;
              laudoSelecionado.laudoJson.eixoCardiaco = undefined;
              laudoSelecionado.laudoJson.eixoCardiaco2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeAreaCardiaca ? '' : 'text--disabled'
          }`"
        >
          <span>Área cardíaca:</span>
          <v-select
            :items="[
              '1/3 da área do torax (normal)',
              'aumentado com domínio de câmaras direitas',
              'aumentado com domínio de câmaras esquerdas',
              'Índice cardiotoráxico',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeAreaCardiaca"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.areaCardiaca"
          >
          </v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.areaCardiaca == 'Índice cardiotoráxico'
            "
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeAreaCardiaca"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.areaCardiacaCm"
          ></v-text-field>
          <span>.</span>

          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.areaCardiaca2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeAreaCardiaca"
          >
          </v-text-field>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeAreaCardiaca"
            @click="
              camposExibidos.exibeAreaCardiaca = true;
              laudoSelecionado.laudoJson.areaCardiaca = undefined;
              laudoSelecionado.laudoJson.areaCardiaca2 = undefined;
              laudoSelecionado.laudoJson.areaCardiacaCm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeAreaCardiaca = false;
              laudoSelecionado.laudoJson.areaCardiaca = undefined;
              laudoSelecionado.laudoJson.areaCardiaca2 = undefined;
              laudoSelecionado.laudoJson.areaCardiacaCm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeJuncaoAtrioventricular ? '' : 'text--disabled'
          }`"
        >
          <span>Junção atrioventricular:</span>
          <v-select
            :items="['concordante', 'discordante']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeJuncaoAtrioventricular"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.juncaoAtrioventricular"
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.juncaoAtrioventricular2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeJuncaoAtrioventricular"
          >
          </v-text-field>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeJuncaoAtrioventricular"
            @click="
              camposExibidos.exibeJuncaoAtrioventricular = true;
              laudoSelecionado.laudoJson.juncaoAtrioventricular = undefined;
              laudoSelecionado.laudoJson.juncaoAtrioventricular2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeJuncaoAtrioventricular = false;
              laudoSelecionado.laudoJson.juncaoAtrioventricular = undefined;
              laudoSelecionado.laudoJson.juncaoAtrioventricular2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeVeiasPulmonares ? '' : 'text--disabled'
          }`"
        >
          <span>Veias pulmonares:</span>
          <v-select
            :items="[
              'drenando no atrio esquerdo',
              'drenagem anômola',
              '__________________',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeVeiasPulmonares"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.veiasPulmonares"
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.veiasPulmonares2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeVeiasPulmonares"
          >
          </v-text-field>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeVeiasPulmonares"
            @click="
              camposExibidos.exibeVeiasPulmonares = true;
              laudoSelecionado.laudoJson.veiasPulmonares = undefined;
              laudoSelecionado.laudoJson.veiasPulmonares2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeVeiasPulmonares = false;
              laudoSelecionado.laudoJson.veiasPulmonares = undefined;
              laudoSelecionado.laudoJson.veiasPulmonares2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeVeiasSistemicas ? '' : 'text--disabled'
          }`"
        >
          <span>Veias sistêmicas:</span>
          <v-select
            :items="['drenando no átrio direito', '_________________________']"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeVeiasSistemicas"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.veiasSistemicas"
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.veiasSistemicas2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeVeiasSistemicas"
          >
          </v-text-field>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeVeiasSistemicas"
            @click="
              camposExibidos.exibeVeiasSistemicas = true;
              laudoSelecionado.laudoJson.veiasSistemicas = undefined;
              laudoSelecionado.laudoJson.veiasSistemicas2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeVeiasSistemicas = false;
              laudoSelecionado.laudoJson.veiasSistemicas = undefined;
              laudoSelecionado.laudoJson.veiasSistemicas2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeForameOval ? '' : 'text--disabled'
          }`"
        >
          <span>Forame oval:</span>
          <v-select
            :items="[
              'patente com passagem de fluxo do átrio direito para o esquerdo',
              'válvula abualada (aneurismática)',
              'válvula retificada',
              'fluxo invertido (esquerda/direita)',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeForameOval"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.forameOval"
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.forameOval2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeForameOval"
          >
          </v-text-field>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeForameOval"
            @click="
              camposExibidos.exibeForameOval = true;
              laudoSelecionado.laudoJson.forameOval = undefined;
              laudoSelecionado.laudoJson.forameOval2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeForameOval = false;
              laudoSelecionado.laudoJson.forameOval = undefined;
              laudoSelecionado.laudoJson.forameOval2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeSeptoInterventricular ? '' : 'text--disabled'
          }`"
        >
          <span>Septo interventricular:</span>
          <v-select
            :items="[
              'aparentemente integro',
              'comunicação interventricular medindo',
              'tipo muscular trabecular',
              'tipo muscular apical',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeSeptoInterventricular"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.septoInterventricular"
          >
          </v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.septoInterventricular ==
              'comunicação interventricular medindo'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            :disabled="!camposExibidos.exibeSeptoInterventricular"
            v-model="laudoSelecionado.laudoJson.septoInterventricularCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.septoInterventricular ==
              'comunicação interventricular medindo'
            "
            cols="12"
            md="1"
            sm="12"
            >mm, tipo via de entrada
          </span>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.septoInterventricular2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeSeptoInterventricular"
          >
          </v-text-field>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeSeptoInterventricular"
            @click="
              camposExibidos.exibeSeptoInterventricular = true;
              laudoSelecionado.laudoJson.septoInterventricular = undefined;
              laudoSelecionado.laudoJson.septoInterventricular2 = undefined;
              laudoSelecionado.laudoJson.septoInterventricularCm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeSeptoInterventricular = false;
              laudoSelecionado.laudoJson.septoInterventricular = undefined;
              laudoSelecionado.laudoJson.septoInterventricular2 = undefined;
              laudoSelecionado.laudoJson.septoInterventricularCm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeValvasAtrioventricularesAorticaPulmonar
              ? ''
              : 'text--disabled'
          }`"
        >
          <span>Valvas atrioventriculares, aórtica e pulmonar:</span>
          <v-select
            :items="[
              'a análise do fluxo por elas ao mapeamento de fluxo em cores é  normal, sem evidências de regurgitaçãoe e estenose',
              'não foram observadas alteraçõe anatômicas e funcionais evidentes das valvas',
              '_____________________________________________',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="
              !camposExibidos.exibeValvasAtrioventricularesAorticaPulmonar
            "
            validate-on-blur
            v-model="
              laudoSelecionado.laudoJson.valvasAtrioventricularesAorticaPulmonar
            "
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="
              laudoSelecionado.laudoJson
                .valvasAtrioventricularesAorticaPulmonar2
            "
            class="mx-2 flex-grow-0"
            dense
            :disabled="
              !camposExibidos.exibeValvasAtrioventricularesAorticaPulmonar
            "
          >
          </v-text-field>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeValvasAtrioventricularesAorticaPulmonar"
            @click="
              camposExibidos.exibeValvasAtrioventricularesAorticaPulmonar = true;
              laudoSelecionado.laudoJson.valvasAtrioventricularesAorticaPulmonar =
                undefined;
              laudoSelecionado.laudoJson.valvasAtrioventricularesAorticaPulmonar2 =
                undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeValvasAtrioventricularesAorticaPulmonar = false;
              laudoSelecionado.laudoJson.valvasAtrioventricularesAorticaPulmonar =
                undefined;
              laudoSelecionado.laudoJson.valvasAtrioventricularesAorticaPulmonar2 =
                undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeTroncoPulmonar ? '' : 'text--disabled'
          }`"
        >
          <span>Tronco pulmonar:</span>
          <v-select
            :items="[
              'originando-se do ventrículo direito, com as artérias pulmonares direita e esquerda de dimensões normais',
              'originando-se do ventrículo esquerdo',
              'com dimensões reduzidas',
              'com dimensões aumentadas após a valva pulmonar',
              '_____________________________________________',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeTroncoPulmonar"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.troncoPulmonar"
          >
          </v-select>

          <span
            v-if="
              laudoSelecionado.laudoJson.troncoPulmonar ==
              'com dimensões reduzidas'
            "
            cols="12"
            md="1"
            sm="12"
            >( Z-score=</span
          >
          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.troncoPulmonar ==
              'com dimensões reduzidas'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeTroncoPulmonar"
            v-model="laudoSelecionado.laudoJson.troncoPulmonarCm"
          ></v-text-field>
          <span
            v-if="
              laudoSelecionado.laudoJson.troncoPulmonar ==
              'com dimensões reduzidas'
            "
            cols="12"
            md="1"
            sm="12"
          >
            )</span
          >
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.troncoPulmonar2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeTroncoPulmonar"
          >
          </v-text-field>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeTroncoPulmonar"
            @click="
              camposExibidos.exibeTroncoPulmonar = true;
              laudoSelecionado.laudoJson.troncoPulmonarCm = undefined;
              laudoSelecionado.laudoJson.troncoPulmonar = undefined;
              laudoSelecionado.laudoJson.troncoPulmonar2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeTroncoPulmonar = false;
              laudoSelecionado.laudoJson.troncoPulmonarCm = undefined;
              laudoSelecionado.laudoJson.troncoPulmonar = undefined;
              laudoSelecionado.laudoJson.troncoPulmonar2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeAorta ? '' : 'text--disabled'
          }`"
        >
          <span>Aorta:</span>
          <v-select
            :items="[
              'originando-se do ventrículo esquerdo, de dimensões normais',
              'originando-se do ventrículo direito',
              'com dimensões reduzidas',
              'com dimensões aumentadas após a valva aórtica',
              '---------------------------------------------',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeAorta"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.aorta"
          >
          </v-select>

          <span
            v-if="laudoSelecionado.laudoJson.aorta == 'com dimensões reduzidas'"
            cols="12"
            md="1"
            sm="12"
            >( Z-score=</span
          >
          <v-text-field
            v-if="laudoSelecionado.laudoJson.aorta == 'com dimensões reduzidas'"
            required
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeAorta"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.aortaCm"
          ></v-text-field>
          <span
            v-if="laudoSelecionado.laudoJson.aorta == 'com dimensões reduzidas'"
            cols="12"
            md="1"
            sm="12"
          >
            )</span
          >
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.aorta2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeAorta"
          >
          </v-text-field>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeAorta"
            @click="
              camposExibidos.exibeAorta = true;
              laudoSelecionado.laudoJson.aorta = undefined;
              laudoSelecionado.laudoJson.aorta2 = undefined;
              laudoSelecionado.laudoJson.aortaCm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeAorta = false;
              laudoSelecionado.laudoJson.aorta = undefined;
              laudoSelecionado.laudoJson.aorta2 = undefined;
              laudoSelecionado.laudoJson.aortaCm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeArcoAortico ? '' : 'text--disabled'
          }`"
        >
          <span>Arco aórtico</span>
          <v-select
            :items="['voltado para a esquerda', 'voltado para a direita ']"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeArcoAortico"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.arcoAortico"
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.arcoAortico2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeArcoAortico"
          >
          </v-text-field>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeArcoAortico"
            @click="
              camposExibidos.exibeArcoAortico = true;
              laudoSelecionado.laudoJson.arcoAortico = undefined;
              laudoSelecionado.laudoJson.arcoAortico2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeArcoAortico = false;
              laudoSelecionado.laudoJson.arcoAortico = undefined;
              laudoSelecionado.laudoJson.arcoAortico2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibePatente ? '' : 'text--disabled'
          }`"
        >
          <span
            >Canal arterial: patente e apresenta fluxo de características
            normais direcionando do tronco pulmonar para a aorta descendente </span
          ><span>(IP</span>

          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibePatente"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.patente"
          >
          </v-text-field>
          <span>).</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.patente2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibePatente"
          >
          </v-text-field>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibePatente"
            @click="
              camposExibidos.exibePatente = true;
              laudoSelecionado.laudoJson.patente = undefined;
              laudoSelecionado.laudoJson.patente2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibePatente = false;
              laudoSelecionado.laudoJson.patente = undefined;
              laudoSelecionado.laudoJson.patente2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeMiocardio ? '' : 'text--disabled'
          }`"
        >
          <span>Miocárdio:</span>
          <v-select
            :items="[
              'de ambos os ventrículos tem espessura e contratilidade normais, evidenciado boa função sistólica',
              '_____________________________________________',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMiocardio"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.miocardio"
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.miocardio2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeMiocardio"
          >
          </v-text-field>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeMiocardio"
            @click="
              camposExibidos.exibeMiocardio = true;
              laudoSelecionado.laudoJson.miocardio = undefined;
              laudoSelecionado.laudoJson.miocardio2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeMiocardio = false;
              laudoSelecionado.laudoJson.miocardio = undefined;
              laudoSelecionado.laudoJson.miocardio2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Conclusão</h3>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeConclusao ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Ecocardiograma fetal dentro dos limites da normalidade para a idade gestacional',
              '_____________________________________________',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeConclusao"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.conclusao"
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.conclusao2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeConclusao"
          >
          </v-text-field>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeConclusao"
            @click="
              camposExibidos.exibeConclusao = true;
              laudoSelecionado.laudoJson.conclusao = undefined;
              laudoSelecionado.laudoJson.conclusao2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeConclusao = false;
              laudoSelecionado.laudoJson.conclusao = undefined;
              laudoSelecionado.laudoJson.conclusao2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span>Observação:</span>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeObservacao ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'É importante ressaltar que após o nascimento o coração sofre modificações com fechamento do forame oval e do canal arterial podendo apararecer alterações não observadas na vida fetal',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeObservacao"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.observacao"
          >
          </v-select>
          <span>.</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.observacao2"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exibeObservacao"
          >
          </v-text-field>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeObservacao"
            @click="
              camposExibidos.exibeObservacao = true;
              laudoSelecionado.laudoJson.observacao = undefined;
              laudoSelecionado.laudoJson.observacao2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeObservacao = false;
              laudoSelecionado.laudoJson.observacao = undefined;
              laudoSelecionado.laudoJson.observacao2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-textarea
          v-model="laudoSelecionado.laudoJson.comentariosAdcionaisFinal2"
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          filled
          outlined
          no-resize
          label="Comentários adicionais:"
        >
        </v-textarea>
      </template>

      <v-col cols="12" class="d-flex justify-start align-center pt-6">
        <v-btn
          v-if="podeEditar"
          color="primary"
          :loading="carregamentoDoLaudo"
          @click="
            () => {
              laudoId ? editarLaudo() : salvarLaudo();
            }
          "
        >
          salvar laudo
        </v-btn>

        <v-btn
          color="primary"
          v-if="podeImprimir && !podeEditar"
          @click="imprimirLaudo"
          :loading="loadingImprimir"
          class="ml-5"
        >
          Imprimir
        </v-btn>

        <v-btn
          color="primary"
          v-if="!podeEditar"
          class="ml-5"
          @click="podeEditar = true"
        >
          Editar
        </v-btn>

        <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
          Cancelar
        </v-btn>

        <v-btn
          color="primary"
          v-if="podePersonalizar"
          @click="adcionarConteudoEditorPersonalizado"
          class="ml-5"
        >
          {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
        </v-btn>
      </v-col>
    </v-row>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    VueEditor,
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      quantidadeFolhasEditorPersonalizado: 0,
      carregamentoDoLaudo: false,
      laudoId: null,
      podeEditar: true,
      voltando: false,
      loadingImprimir: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,
      exibeVelocidadePicoSistolico: true,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      camposExibidos: {
        exibePatente: true,
        exibeVeiasSistemicas: true,
        exibeForameOval: true,
        exibeObservacao: true,
        exibeSeptoInterventricular: true,
        checkAxilaDireita: false,
        exibeAreaCardiaca: true,
        exibeArcoAortico: true,
        checkAxilaEsquerda: false,
        exibeConclusao: true,
        exibeTroncoPulmonar: true,
        exibeAorta: true,
        exibeMiocardio: true,
        exibeValvasAtrioventricularesAorticaPulmonar: true,
        exIdadeGest: true,
        exIndicacao: true,
        exSitus: true,
        exPosCardc: true,
        exEixoCardc: true,
        exAreaCardc: true,
        exJuncaoAtrioVentri: true,
        exVeiasPulm: true,
        exVeiasSistemicas: true,
        exForameOval: true,
        exSepto: true,
        exValvas: true,
        exTronco: true,
        exAorta: true,
        exArcoAort: true,
        exPatente: true,
        exMiocardio: true,
        exibeIndicacao: true,
        exibeVeiasPulmonares: true,
        exibePosicaoCardiaca: true,
        exibeEixoCardiaco: true,
        exibeJuncaoAtrioventricular: true,
        exibeSitus: true,
        exConclusao: true,
        exibeIdadeGestacional: true,
      },

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,

      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 2,5 cm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    podeImprimir() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    temIdDoLaudo() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    removeFolha(value) {
      let indice = value - 1;

      if (this.laudoSelecionado.laudoJson.folhas.length != 1) {
        this.conteudoEditorPersonalizado.splice(indice, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.$forceUpdate();
    },
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudoSelecionado.laudoJson.folhas) {
        this.conteudoEditorPersonalizado =
          this.laudoSelecionado.laudoJson.folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }

      let todos = [];

      this.conteudoEditorPersonalizado = [];

      if (this.laudoSelecionado.laudoJson.idadeGestacional) {
        todos.push(
          `Idade gestacional: ${
            this.laudoSelecionado.laudoJson.idadeGestacional
          } semanas. ${
            this.laudoSelecionado.laudoJson.compativelIncompativel
              ? this.laudoSelecionado.laudoJson.compativelIncompativel
              : ''
          }.\n`
        );
      }
      if (
        this.camposExibidos.exibeIndicacao &&
        this.laudoSelecionado.laudoJson.indicacao
      ) {
        todos.push(
          `Indicação: ${this.laudoSelecionado.laudoJson.indicacao}.\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.situs) {
        todos.push(
          `Situs: ${this.laudoSelecionado.laudoJson.situs}. ${
            this.laudoSelecionado.laudoJson.situs2
              ? this.laudoSelecionado.laudoJson.situs2
              : ''
          }.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.posicaoCardiaca) {
        todos.push(
          `Posição cardíaca: ${
            this.laudoSelecionado.laudoJson.posicaoCardiaca
          }. ${
            this.laudoSelecionado.laudoJson.posicaoCardiaca2
              ? this.laudoSelecionado.laudoJson.posicaoCardiaca2
              : ''
          }.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.eixoCardiaco) {
        todos.push(
          `Eixo cardíaco: ${this.laudoSelecionado.laudoJson.eixoCardiaco}. ${
            this.laudoSelecionado.laudoJson.eixoCardiaco2
              ? this.laudoSelecionado.laudoJson.eixoCardiaco2
              : ''
          }.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.areaCardiaca) {
        let resultadoAreaCardiaca = `Área cardíaca: ${this.laudoSelecionado.laudoJson.areaCardiaca}`;

        if (
          this.laudoSelecionado.laudoJson.areaCardiaca ===
          'Índice cardiotoráxico'
        ) {
          if (this.laudoSelecionado.laudoJson.areaCardiacaCm) {
            resultadoAreaCardiaca += ` ${this.laudoSelecionado.laudoJson.areaCardiacaCm}`;
          }
        }

        if (this.laudoSelecionado.laudoJson.areaCardiaca2) {
          resultadoAreaCardiaca += `. ${this.laudoSelecionado.laudoJson.areaCardiaca2}`;
        }

        resultadoAreaCardiaca += '.\n';
        todos.push(resultadoAreaCardiaca);
      }

      if (this.laudoSelecionado.laudoJson.juncaoAtrioventricular) {
        todos.push(
          `Junção atrioventricular: ${
            this.laudoSelecionado.laudoJson.juncaoAtrioventricular
          }. ${
            this.laudoSelecionado.laudoJson.juncaoAtrioventricular2
              ? this.laudoSelecionado.laudoJson.juncaoAtrioventricular2
              : ''
          }.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.veiasPulmonares) {
        todos.push(
          `Veias pulmonares: ${
            this.laudoSelecionado.laudoJson.veiasPulmonares
          }. ${
            this.laudoSelecionado.laudoJson.veiasPulmonares2
              ? this.laudoSelecionado.laudoJson.veiasPulmonares2
              : ''
          }.\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.veiasSistemicas) {
        todos.push(
          `Veias sistêmicas: ${
            this.laudoSelecionado.laudoJson.veiasSistemicas
          }. ${
            this.laudoSelecionado.laudoJson.veiasSistemicas2
              ? this.laudoSelecionado.laudoJson.veiasSistemicas2
              : ''
          }.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.forameOval) {
        todos.push(
          `Forame oval: ${this.laudoSelecionado.laudoJson.forameOval}. ${
            this.laudoSelecionado.laudoJson.forameOval2
              ? this.laudoSelecionado.laudoJson.forameOval2
              : ''
          }.\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.septoInterventricular) {
        let resultadoBatimentosCardiacos = `Septo interventricular: ${this.laudoSelecionado.laudoJson.septoInterventricular}`;

        if (
          this.laudoSelecionado.laudoJson.septoInterventricular ===
          'comunicação interventricular medindo'
        ) {
          if (this.laudoSelecionado.laudoJson.septoInterventricularCm) {
            resultadoBatimentosCardiacos += `, ${this.laudoSelecionado.laudoJson.septoInterventricularCm} mm, tipo via de entrada.`;
          }
        }

        if (this.laudoSelecionado.laudoJson.septoInterventricular2) {
          resultadoBatimentosCardiacos += ` ${this.laudoSelecionado.laudoJson.septoInterventricular2}`;
        }

        resultadoBatimentosCardiacos += '.\n';
        todos.push(resultadoBatimentosCardiacos);
      }

      if (
        this.laudoSelecionado.laudoJson.valvasAtrioventricularesAorticaPulmonar
      ) {
        todos.push(
          `Valvas atrioventriculares, aórtica e pulmonar: ${
            this.laudoSelecionado.laudoJson
              .valvasAtrioventricularesAorticaPulmonar
          }. ${
            this.laudoSelecionado.laudoJson
              .valvasAtrioventricularesAorticaPulmonar2
              ? this.laudoSelecionado.laudoJson
                  .valvasAtrioventricularesAorticaPulmonar2
              : ''
          }.\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.troncoPulmonar) {
        let resultadoTroncoPulmonar = `Tronco pulmonar: ${this.laudoSelecionado.laudoJson.troncoPulmonar}`;

        if (
          this.laudoSelecionado.laudoJson.troncoPulmonar ===
          'com dimensões reduzidas'
        ) {
          if (this.laudoSelecionado.laudoJson.troncoPulmonarCm) {
            resultadoTroncoPulmonar += ` ( Z-score= ${this.laudoSelecionado.laudoJson.troncoPulmonarCm} ).`;
          }
        }

        if (this.laudoSelecionado.laudoJson.troncoPulmonar2) {
          resultadoTroncoPulmonar += ` ${this.laudoSelecionado.laudoJson.troncoPulmonar2}`;
        }

        resultadoTroncoPulmonar += '.\n';
        todos.push(resultadoTroncoPulmonar);
      }

      if (this.laudoSelecionado.laudoJson.aorta) {
        let resultadoAorta = `Aorta: ${this.laudoSelecionado.laudoJson.aorta}`;

        if (
          this.laudoSelecionado.laudoJson.aorta === 'com dimensões reduzidas'
        ) {
          if (this.laudoSelecionado.laudoJson.aortaCm) {
            resultadoAorta += ` ( Z-score= ${this.laudoSelecionado.laudoJson.aortaCm} ).`;
          }
        }

        if (this.laudoSelecionado.laudoJson.aorta2) {
          resultadoAorta += ` ${this.laudoSelecionado.laudoJson.aorta2}`;
        }

        resultadoAorta += '.\n';
        todos.push(resultadoAorta);
      }

      if (this.laudoSelecionado.laudoJson.arcoAortico) {
        todos.push(
          `Arco aórtico: ${this.laudoSelecionado.laudoJson.arcoAortico}. ${
            this.laudoSelecionado.laudoJson.arcoAortico2
              ? this.laudoSelecionado.laudoJson.arcoAortico2
              : ''
          }\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.patente) {
        todos.push(
          `Canal arterial: Patente e apresenta fluxo de características normais direcionando do tronco pulmonar para a aorta descendente (IP ${
            this.laudoSelecionado.laudoJson.patente
          }). ${
            this.laudoSelecionado.laudoJson.patente2
              ? this.laudoSelecionado.laudoJson.patente2
              : ''
          }.\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.miocardio) {
        todos.push(
          `Miocárdio: ${this.laudoSelecionado.laudoJson.miocardio}. ${
            this.laudoSelecionado.laudoJson.miocardio2
              ? this.laudoSelecionado.laudoJson.miocardio2
              : ''
          }.\n`
        );
      }

      todos.push(`\n<h4>Conclusão</h4>\n`);

      if (this.laudoSelecionado.laudoJson.conclusao) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.conclusao}. ${
            this.laudoSelecionado.laudoJson.conclusao2
              ? this.laudoSelecionado.laudoJson.conclusao2
              : ''
          }.\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.observacao) {
        todos.push(`\n<h4>Observação </h4>`);
      }
      if (this.laudoSelecionado.laudoJson.observacao) {
        todos.push(`${this.laudoSelecionado.laudoJson.observacao}.\n`);
      }
      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisFinal2) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisFinal2}.\n`
        );
      }

      this.clicouNoPersonalizado = true;

      while (todos.length > 0) {
        const teste = todos.splice(0, 22);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },

    forceReRender() {
      this.componentKey++;
    },

    ...mapGetters('Offline', ['requisicao', 'dados']),

    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          this.carregamentoDoLaudo = true;

          const exameId = this.$route.query.exame;

          this.laudoSelecionado.examePorConsultaId = exameId;
          this.consultaSelecionada.status = 'LAUDADO';

          if (navigator.onLine) {
            const { data } = await this.postLaudo();
            this.laudoId = data;
            await this.alterarStatus();

            if (!Number.isInteger(data)) throw new Error(data);

            this.getLaudoByExamePorConsultaId(exameId);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
              requisicaoSalvar: this.postLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'POST_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', {
              laudo: this.laudoSelecionado,
              consulta: this.consultaSelecionada,
            });
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.carregamentoDoLaudo = false;
        this.abreModal();
        return;
      } finally {
        this.carregamentoDoLaudo = false;
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            await this.patchLaudo(this.laudoSelecionado);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
            // this.podeImprimir = false;
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    juntaPersonalizadoAoLaudo() {
      this.laudoSelecionado.laudoJson.folhas = this.conteudoEditorPersonalizado;
      this.laudoSelecionado.laudoJson.titulo = 'ECOCARDIOGRAMA';
    },
    desativaLoading() {
      this.loadingImprimir = false;
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },

    async imprimirLaudo() {
      this.loadingImprimir = true;
      this.laudoPrint = 'impresso';
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.ecocardiodopplerfetal',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },
    formataLaudo() {
      if (this.exibeObs) {
        this.laudoSelecionado.laudoJson.exibeObs = false;
      } else {
        this.laudoSelecionado.laudoJson.exibeObs = true;
      }
    },
    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },
  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;

    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      // this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
