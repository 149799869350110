<template lang="">
  <div>
    <LaudoEcocardiodopplerFetal></LaudoEcocardiodopplerFetal>
  </div>
</template>
<script>
import LaudoEcocardiodopplerFetal from "../components/laudos/formularios/exames/ecocardiodopplerFetal/LaudoEcocardiodopplerFetal";
export default {
  components: {
    LaudoEcocardiodopplerFetal,
  },
};
</script>
<style lang=""></style>
