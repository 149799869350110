<template >
 
   
    <v-container class="d-flex align-center justify-center login-wrapper" fluid>
    <v-card  class="cyan darken-2 px-5 ">
      <v-card-title class="d-flex justify-center ">
        <!-- <h3 class="white--text text--darken-3">CLÍNICA EMBRION</h3> -->
        <v-container>
          <v-row justify="center">
          <v-col cols="5">
            <v-img
          :src="imagemLogin"
        ></v-img>
          </v-col>
        </v-row>
        </v-container>

       
        
      </v-card-title>
      
      <form @submit.prevent="handleLogin">
        <h4 class="mt-4 mb-4">Usuário: {{ nomeDefinirSenha
   }}</h4>
   <v-text-field
          :type="show ? 'text' : 'password'"
          class="teste"
          label="Digite a nova senha"
          solo
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show = !show"
          @change="limpaTela"
          filled
          
          clearable
          v-model="senha"
        />
        <v-text-field
          :type="show2 ? 'text' : 'password'"
          class="teste"
          label="Confirme a nova senha"
          solo
          @change="limpaTela"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show2 = !show2"
          filled
          
          clearable
          v-model="senhaConfirm"
        />
        <v-btn class="white mb-4 mt-n4" :loading="loadingSub" @click="submitSenha" block large>
            Salvar senha
          </v-btn>
        <v-alert type="success" v-model="sucesso">
            Nova senha cadastrada com sucesso
            </v-alert>

            <v-alert type="error" v-model="erro">
              {{ msgErro }}
            
         </v-alert>

        
      
      </form>
      
    </v-card>
    
    

  </v-container>
   
</template>

<script>
import { mapGetters } from 'vuex';
import LoginMixin from '@/mixins/LoginMixin';
import jwt_decode from 'jwt-decode';
import imagemLogin from '@assets/embrionLogin.png'
export default {
    mixins: [LoginMixin ],
    data() {
    return {
        nome: '',
        token: '',
        senha: '',
        senhaConfirm: '',
        show2: false,
        loadingSub: false,
        show: false,
        erro: false,
        msgErro: '',
      sucesso: false,
      imagemLogin: imagemLogin
      
    };
  },
  async mounted(){
    this.senha = ''
    this.senhaConfirm = ''
    
    if(!!this.$route.query.token){
      this.decoded(this.$route.query.token)
    
    await this.setTokenDefinirSenha(this,this.$route.query.token, this.nome )
    this.$route.query.token = ''
    this.$router.push('/definir-senha')
    }else{
      this.decoded(this.tokenDefinirSenha)
    }

  },
  computed: {
    ...mapGetters('Auth', ['tokenDefinirSenha', 'nivelPermissao', 'nomeDefinirSenha']),
  },
  methods: {
    decoded(token){
       let decoded = jwt_decode(token)
       this.nome = decoded.unique_name
       this.token = token
       
       

    },
    limpaTela(){
      this.erro = false;
      this.sucesso = false;
      this.msgErro = "";

    },
    async submitSenha(){
      if(this.senha !== this.senhaConfirm)
      {
        this.erro = true
        this.msgErro = "As senhas devem ser iguais"
        return
      }

        this.loadingSub= true
        this.patchSenha(this.tokenDefinirSenha, this.senha)
        .then(()=>{
            this.senha = ''
            this.senhaConfirm = ''
            this.sucesso = true;
            this.loadingSub= false;
            this.resetTokenDefinirSenha(this)

        }).catch(()=>{
            this.erro = true
            this.msgErro = "Erro ao cadastrar uma nova senha, tente novamente"

            this.loadingSub= false
        })

    }

  }

}
</script>

<style>

</style>