<template lang="">
  <div>
    <LaudoObstetrica1Trimestre></LaudoObstetrica1Trimestre>
  </div>
</template>
<script>
import LaudoObstetrica1Trimestre from "../components/laudos/formularios/exames/obstetrica1Trimestre/LaudoObstetrica1Trimestre.vue"
export default {
  components: {
    LaudoObstetrica1Trimestre,
  },
};
</script>
<style lang=""></style>
