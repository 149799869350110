import Http from '@services/Http';
import { EMBRION_API_URL } from '@/data/enviroment';

export default {
  async getLaudoByExamePorConsulta({ token, exameId }) {
    return Http(EMBRION_API_URL, token).get(
      `Laudos/ByExamePorConsultaId?examePorConsultaId=${exameId}`
    );
  },
  async postLaudo({ token, data }) {
    return Http(EMBRION_API_URL, token).post('/Laudos', data);
  },
};
