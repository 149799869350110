import Http from '@services/Http';
import { EMBRION_API_URL } from '@/data/enviroment';

export default {
  async getPacientes({ token }) {
    return Http(EMBRION_API_URL, token).get('/Paciente');
  },
  async postProximoPaciente({ token, data }) {
    return Http(EMBRION_API_URL, token).post('/NextPaciente', data);
  },
};
