<template>
  <v-container class="ma-2 ml-2 text-center" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="ma-5 ">
          <v-data-table
              :headers="headDash"
              :items="items"
              hide-default-footer

              
              :items-per-page="-1"
              class="elevation-1 maiuscula ml-2 mr-2"
             
              no-data-text="Nenhum dado "
              :loading="loading"
              loading-text="Buscando dados..."
            >
              <template v-slot:item.avista="{ item }">
                ENTRADA &nbsp;&nbsp;R$ {{ item.avista.toFixed(2) }}<br>
                Saída &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;R${{ item.saidaVista.toFixed(2) }}<br>
                total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;R$ {{ item.totalVista.toFixed(2) }}
              </template>
              <template v-slot:item.pix="{ item }">
                ENTRADA &nbsp;&nbsp;R$ {{ item.pix.toFixed(2) }}<br>
                Saída &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;R${{ item.saidaPix.toFixed(2)}}<br>
                total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;R$ {{ item.totalPix.toFixed(2) }}
              </template>
              <template v-slot:item.aporte="{ item }">
                ENTRADA &nbsp;&nbsp;R$ {{ item.aporte.toFixed(2) }}<br>
                Saída &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;R${{ item.saidaAporte.toFixed(2)}}<br>
                total &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;R$ {{ item.totalAporte.toFixed(2) }}
              </template>
            </v-data-table>
          <v-row>
         
        </v-row>
         
          <br />
          <v-divider></v-divider>
          <br />
          <v-row>
            <v-col class="ml-5 white--text" cols="3" md="2">
              <v-select
                class="maiuscula"
                label="Emissão"
                validate-on-blur
                filled
                outlined
                required
                :items="['Vencimento', 'Pagamento']"
                v-model="tipoPesquisa"
              />
            </v-col>
            <v-col class="ml-5 white--text" cols="3" md="2">
              <v-text-field
                label="De:"
                type="date"
                v-model="modelBody.dataInicial"
              ></v-text-field>
              <!-- <v-checkbox
                class="mt-n6 white--text text--darken-3"
                label="Data atual"
                v-model="model.checkDataInicial"
                @change="selectCurrentDate('start')"
              >
              </v-checkbox> --></v-col
            ><v-col class="ml-5 white--text" cols="3" md="2">
              <v-text-field
                label="Até:"
                type="date"
                v-model="modelBody.dataFinal"
              ></v-text-field>
              <!-- <v-checkbox
                class="mt-n6 white--text text--darken-3"
                label="Data atual"
                v-model="model.checkDataFinal"
                @change="selectCurrentDate('end')"
              >
              </v-checkbox> -->
            </v-col>
            <v-col cols="2" md="2">
          <!-- <v-select
            class="maiuscula white--tex"
            background-color="#FFFFFF"
            label="Médico"
            filled
            @change="handleMedicoChange"
            outlined
            :clearable="false"
            required
            v-model="modelBody.medico"
            :items="this.nivelPermissao==2 ? this.nomesMedicos :[...this.nomesMedicosExecutantes, 'TODOS', ...this.nomesMedicos]"
          /> -->
        </v-col>

            <v-col cols="2" md="2" class="d-flex">
              <v-btn @click="consultarContasAPagar" class="mr-2">
                <v-icon large dense>mdi-magnify</v-icon>
              </v-btn>
              <v-btn class="mr-16">
                <v-icon large dense @click="abreModal">mdi-sticker-plus</v-icon>
              </v-btn>
              <v-btn class="ml-16">
                <v-icon large dense @click="abreModalDois"
                  >mdi-cash-multiple</v-icon
                >
              </v-btn>
              <!-- <v-btn class="ml-16">
                <v-icon large dense @click="abrirDashboard"
                  >mdi-monitor-dashboard</v-icon
                >
              </v-btn> -->
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col class="ml-5 white--text" cols="4" md="2">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Título"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row> -->

          <v-col>
            <v-data-table
              :headers="headers1"
              :items="consultasDividas"
              sort-by="data"
              :items-per-page="-1"
              class="elevation-1 maiuscula"
              @click:row="handleClick"
              no-data-text="Nenhuma conta "
              :loading="loading"
              loading-text="Buscando contas..."
            >
              <template v-slot:item.pago="{ item }">
                <span>{{ item.pago ? 'SIM' : 'NÃO' }} </span>
              </template>
            </v-data-table>
          </v-col>
          
        </v-card>
      </v-col>
    </v-row>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
    <component
      :is="modalDois.modalComponent"
      v-bind="{ show: modalDois.active }"
      @close="closeModalDois"
    />
  </v-container>
</template>
<script>
import ModalInserirAporte from '@components/financeiro/ModalInserirAporte.vue';
import ModalNovoContas from '@components/financeiro/ModalNovoContasAPagar.vue';
import ConsultasMixin from '@mixins/ConsultasMixin';
import PagamentosMixin from '@mixins/PagamentosMixin';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { EMBRION_API_URL } from '@/data/enviroment';

export default {
  mixins: [ConsultasMixin, PagamentosMixin],
  data() {
    return {
      urlBase: EMBRION_API_URL,
      loading: false,
      tipoPesquisa: 'Vencimento',
      consultasTotal: [],
      consulta: [],
      nomesMedicos: [],
      modelDashboard: {
        aporte: 0,
        avista: 0,
        pix: 0,
        saidaAporte: 0,
        saidaPix: 0,
        saidaVista: 0,
        totalAporte: 0,
        totalPix: 0,
        totalVista: 0,
      },
      modelBody: {
        dataFinal: '',
        dataInicial: '',
        fornecedores: '',
        medico: 'TODOS'
      },
      dashBoard: false,
      modal: {
        active: false,
        modalComponent: ModalNovoContas,
      },
      modalDois: {
        active: false,
        modalComponent: ModalInserirAporte,
      },
      items: [],
      search: '',
      loading: false,
      headDash: [
     
      {
          text: 'À VISTA',
          align: 'left',
          value: 'avista',
        },
        {
          text: 'PIX',
          align: 'left',
          value: 'pix',
        },
        {
          text: 'APORTE',
          align: 'left',
          value: 'aporte',
        },


      ],
      
      headers1: [
        { text: 'Título', value: 'titulo' },
        { text: 'Valor', value: 'valor' },
        {
          text: 'Tipo de Pagamento',
          align: 'start',
          value: 'tipoPagamento',
        },
        {
          text: 'Vencimento',
          align: 'start',
          value: 'dataVencimento',
        },
        {
          text: 'Pagamento',
          align: 'start',
          value: 'dataPagamento',
        },
        {
          text: 'Pago',
          align: 'start',
          value: 'pago',
        },
      ],
    };
    
  },
  computed: {
    ...mapGetters('Auth', ['headers', 'nivelPermissao']),
    ...mapGetters('Auth', ['usuarioLogado']),
    ...mapGetters(['valoresAPagar', 'valoresAPagar']),
    consultasDividas() {
      return this.$store.getters['Financeiro/consultasDividas'] || [];
    },
  },
  mounted() {
    this.loading = true;
    this.selectCurrentDate('start');
    this.selectCurrentDate('end');
    if (this.usuarioLogado.nivelPermissao === 5) {
    // Se for um superusuário, obtenha a lista de médicos
    this.getMedicos().then(() => {
      this.getNomesMedicosExecutanteSolicitante();
    });
    this.medico = this.medicoSelecionado;
  }
  if (this.usuarioLogado.nivelPermissao === 2) {
    this.medico = this.usuarioLogado.medico.nome
    this.nomesMedicos.push(this.usuarioLogado.medico.nome)
  }
    this.getContasAPagarPorVencimento(
      this,
      this.modelBody.dataInicial,
      this.modelBody.dataFinal,
      this.tipoPesquisa
    ).then(() => {
      this.loading = false;
    });
    this.getRelatorioDashboard(this.modelBody.dataInicial, this.modelBody.dataFinal).then((resp)=> {
      this.modelDashboard = resp.data;
      this.items = [{
        aporte: this.modelDashboard.aporte,
        avista: this.modelDashboard.avista,
        pix: this.modelDashboard.pix,
        saidaAporte: this.modelDashboard.saidaAporte,
        saidaPix: this.modelDashboard.saidaPix,
        saidaVista: this.modelDashboard.saidaVista,
        totalAporte: this.modelDashboard.totalAporte,
        totalPix: this.modelDashboard.totalPix,
        totalVista: this.modelDashboard.totalVista,

      }]
    
    })
    // this.getContasAPagarPorVencimento(
    //   this,
    //   this.modelBody.dataInicial,
    //   this.modelBody.dataFinal
    // );
  },
  methods: {
    abrirDashboard(){
      this.dashBoard = true
      this.getRelatorioDashboard(this.modelBody.dataInicial, this.modelBody.dataFinal).then((resp)=> {;
      this.modelDashboard = resp.data})
    },
    async handleMedicoChange(nome) {
      this.loadingDialog = true;

      if (!nome) {
        this.resetaMedicoSelecionado();
        await this.getConsultaByData(this.dataSelecionada).then(() => {
          this.loadingDialog = false;
        });

        return;
      }
      let medico = await this.getMedicoByNome(nome);
      this.setMedicoSelecionado(medico);
    },
    handleClick(conta) {
      this.resetDividaSelecionada(this, null);

      this.setDividaSelecionada(this, conta);
      this.modal.active = true;
    },
    consultarContasAPagar() {
      
      let medicoId;
      if(this.medicoSelecionado){
        medicoId = this.medico == 'TODOS' ? '' : this.medicoSelecionado.id;
      }
       
      this.loading = true;
      this.getContasAPagarPorVencimento(
        this,
        this.modelBody.dataInicial,
        this.modelBody.dataFinal,
        this.tipoPesquisa,
        medicoId
      ).then(() => {
        this.loading = false;
      });
      this.getRelatorioDashboard(this.modelBody.dataInicial, this.modelBody.dataFinal).then((resp)=> {
      this.modelDashboard = resp.data;
      this.items = [{
        aporte: this.modelDashboard.aporte,
        avista: this.modelDashboard.avista,
        pix: this.modelDashboard.pix,
        saidaAporte: this.modelDashboard.saidaAporte,
        saidaPix: this.modelDashboard.saidaPix,
        saidaVista: this.modelDashboard.saidaVista,
        totalAporte: this.modelDashboard.totalAporte,
        totalPix: this.modelDashboard.totalPix,
        totalVista: this.modelDashboard.totalVista,

      }]
    })
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
      this.resetDividaSelecionada(this, null);
      this.getContasAPagarPorVencimento(
        this,
        this.modelBody.dataInicial,
        this.modelBody.dataFinal,
        this.tipoPesquisa
      ).then(() => {
        this.loading = false;
      });
    },
    abreModalDois() {
      this.modalDois.active = true;
    },
    closeModalDois() {
      this.modalDois.active = false;
      this.resetDividaSelecionada(this, null);
    },

    selectCurrentDate(field) {
      if (field === 'start') {
        this.modelBody.dataInicial = this.getCurrentDate();
      } else if (field === 'end') {
        this.modelBody.dataFinal = moment().endOf('month')
        this.modelBody.dataFinal = this.modelBody.dataFinal.format('YYYY-MM-DD')
      }
      this.$forceUpdate;
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.v-input--checkbox .v-label {
  font-size: 15px;
}
.v-input_slot {
  font-size: 15px;
}
.tamanho{
  width: 120px;
  height: 120px;
}
</style>
