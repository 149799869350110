<template>
  <v-container fluid>
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">
        ULTRASSONOGRAFIA PÉLVICA TRANSABDOMINAL
      </h2>
    </v-card>
    <v-alert
      v-if="!!laudoSelecionado.laudoJson.folhas"
      outlined
      type="warning"
      border="left"
      text=""
    >
      Esse <strong>laudo</strong> teve a impressão
      <strong>personalizada.</strong>
      <v-btn text @click="adcionarConteudoEditorPersonalizado"
        >Clique aqui para visualizar</v-btn
      >
    </v-alert>

    <v-row class="pt-5" no-gutters>
      <template v-if="clicouNoPersonalizado">
        <v-col
          cols="12"
          class="form-group"
          v-for="(index, folha) in quantidadeFolhasEditorPersonalizado"
          :key="index"
        >
          <h2>Folha {{ folha + 1 }}</h2>
          <v-btn plain small @click="removeFolha(index)" class="pl-0">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <vue-editor v-model="conteudoEditorPersonalizado[folha]" />
        </v-col>

        <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
      </template>
      <template v-else>
        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <span
            :class="`${camposExibidos.exEstadoUterPos ? '' : 'text--disabled'}`"
          >
            O útero está
          </span>
          <v-select
            :items="['antevertido', 'retrovertido']"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            :disabled="!camposExibidos.exEstadoUterPos"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-model="laudoSelecionado.laudoJson.uteroPosicao"
          ></v-select>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exEstadoUterPos"
            @click="
              camposExibidos.exEstadoUterPos = true;
              laudoSelecionado.laudoJson.uteroPosicao = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exEstadoUterPos"
            @click="
              camposExibidos.exEstadoUterPos = false;
              laudoSelecionado.laudoJson.uteroPosicao = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
          <span>,</span>

          <v-select
            :items="[
              'em situação mediana',
              'desviado para a direita',
              'desviado para a esquerda',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.uteroSituacao"
            :disabled="!camposExibidos.exEstadoUterSit"
          >
          </v-select>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exEstadoUterSit"
            @click="
              camposExibidos.exEstadoUterSit = true;
              laudoSelecionado.laudoJson.uteroSituacao = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exEstadoUterSit"
            @click="
              camposExibidos.exEstadoUterSit = false;
              laudoSelecionado.laudoJson.uteroSituacao = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
          <span :class="!camposExibidos.exEstadoUter ? 'text--disabled' : ''"
            >, tem os contornos</span
          >
          <v-select
            :items="['regulares.', 'irregulares.']"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.uteroContornos"
            :disabled="!camposExibidos.exEstadoUter"
          >
          </v-select>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exEstadoUter"
            @click="
              camposExibidos.exEstadoUter = true;

              laudoSelecionado.laudoJson.uteroContornos = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exEstadoUter"
            @click="
              camposExibidos.exEstadoUter = false;
              laudoSelecionado.laudoJson.uteroContornos = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exMioEco ? '' : 'text--disabled'
          }`"
        >
          <span>O miométrio tem ecotextura</span>
          <v-select
            :items="[
              'homogênea.',
              'difusamente heterogênea.',
              'heterogênea focal.',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exMioEco"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.miometrioEcotextura"
          >
          </v-select>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exMioEco"
            @click="
              camposExibidos.exMioEco = true;
              laudoSelecionado.laudoJson.miometrioEcotextura = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exMioEco"
            @click="
              camposExibidos.exMioEco = false;
              laudoSelecionado.laudoJson.miometrioEcotextura = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <span
            :class="`${camposExibidos.exPresencaDeImg ? '' : 'text--disabled'}`"
            >Presença de</span
          >
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.presenca"
            :disabled="!camposExibidos.exPresencaDeImg"
          >
          </v-text-field>
          <span
            :class="`${camposExibidos.exPresencaDeImg ? '' : 'text--disabled'}`"
            >imagens hipoecóicas no miométrio, assim distribuídas:</span
          >

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exPresencaDeImg"
            @click="
              camposExibidos.exPresencaDeImg = true;
              laudoSelecionado.laudoJson.presenca = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exPresencaDeImg = false;
              laudoSelecionado.laudoJson.presenca = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <v-select
            :items="['Intramural', 'Subseroso', 'Submucoso']"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exDistriImg2"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.distribuicaoImagens"
          >
          </v-select>

          <span :class="!camposExibidos.exDistriImg2 ? 'text--disabled' : ''"
            >medindo</span
          >
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exDistriImg2"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medindo"
          >
          </v-text-field>

          <span>cm</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exDistriImg2"
            @click="
              camposExibidos.exDistriImg2 = true;
              laudoSelecionado.laudoJson.medindo = undefined;
              laudoSelecionado.laudoJson.distribuicaoImagens = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exDistriImg2"
            @click="
              camposExibidos.exDistriImg2 = false;
              laudoSelecionado.laudoJson.medindo = undefined;
              laudoSelecionado.laudoJson.distribuicaoImagens = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span :class="!camposExibidos.exDistriImg ? 'text--disabled' : ''">
            , localizado na</span
          >

          <v-select
            :items="[
              'parede anterior.',
              'parede posterior.',
              'região fúndica.',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exDistriImg"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.localizacao"
          >
          </v-select>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exDistriImg"
            @click="
              camposExibidos.exDistriImg = true;

              laudoSelecionado.laudoJson.localizacao = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exDistriImg"
            @click="
              camposExibidos.exDistriImg = false;

              laudoSelecionado.laudoJson.localizacao = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exPresencaDiv ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Presença de divisão do eco endometrial em sua porção superior, associado a fundo uterino regular. Exame complementado com avaliação tridimensional.',
              'Presença de divisão do eco endometrial em sua porção superior, associado divisão do fundo uterino. Exame complementado com avaliação tridimensional.',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exPresencaDiv"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.presencaDivisao"
          >
          </v-select>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exPresencaDiv"
            @click="
              camposExibidos.exPresencaDiv = true;
              laudoSelecionado.laudoJson.presencaDivisao = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exPresencaDiv = false;
              laudoSelecionado.laudoJson.presencaDivisao = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Diâmetros uterinos:</h4>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exLong ? '' : 'text--disabled'
          }`"
        >
          <span>Longitudinal:</span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exLong"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.longitudinal"
          >
          </v-text-field>
          <span>cm.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exLong"
            @click="
              camposExibidos.exLong = true;
              laudoSelecionado.laudoJson.longitudinal = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exLong = false;
              laudoSelecionado.laudoJson.longitudinal = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exAnteroPosterior ? '' : 'text--disabled'
          }`"
        >
          <span>Ântero-posterior:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exAnteroPosterior"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anteroPosterior"
          >
          </v-text-field>
          <span>cm.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exAnteroPosterior"
            @click="
              camposExibidos.exAnteroPosterior = true;
              laudoSelecionado.laudoJson.anteroPosterior = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exAnteroPosterior = false;
              laudoSelecionado.laudoJson.anteroPosterior = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exTransverso ? '' : 'text--disabled'
          }`"
        >
          <span>Transverso:</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exTransverso"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.transverso"
          >
          </v-text-field>
          <span>cm.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exTransverso"
            @click="
              camposExibidos.exTransverso = true;
              laudoSelecionado.laudoJson.transverso = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exTransverso = false;
              laudoSelecionado.laudoJson.transverso = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exVolume ? '' : 'text--disabled'
          }`"
        >
          <span>Volume:</span>
          <v-text-field
            v-mask="''"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exVolume"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.volume"
          >
          </v-text-field>
          <span>cm.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exVolume"
            @click="
              camposExibidos.exVolume = true;
              laudoSelecionado.laudoJson.volume = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exVolume = false;
              laudoSelecionado.laudoJson.volume = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exIntracavitario ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Ausência de conteúdo intracavitário.',
              'Liquido intracavitário em pequena quantidade.',
              'Liquido intracavitário em grande quantidade.',
              'Conteúdo heterogêneo intracavitário em pequena quantidade.',
              'Conteúdo heterogêneo intracavitário em grande quantidade.',
              'Imagem hiperecóica medindo',
              'Outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exIntracavitario"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.intracavitario"
          >
          </v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.intracavitario ==
              'Imagem hiperecóica medindo'
            "
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.intracavitarioCm"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.intracavitario ==
              'Imagem hiperecóica medidindo'
            "
            >cm, apresentando pedículo vascular ao Doppler</span
          >
          <v-textarea
            v-if="laudoSelecionado.laudoJson.intracavitario == 'Outro'"
            required
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            outlined
            dense
            class="mx-2 flex-grow-0"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionais8"
          ></v-textarea>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exIntracavitario"
            @click="
              camposExibidos.exIntracavitario = true;
              laudoSelecionado.laudoJson.intracavitario = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exIntracavitario"
            @click="
              camposExibidos.exIntracavitario = false;
              laudoSelecionado.laudoJson.intracavitario = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exEndometrioMede ? '' : 'text--disabled'
          }`"
        >
          <span>O endométrio mede</span>
          <v-text-field
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exEndometrioMede"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.endometrioMede"
          >
          </v-text-field>
          <span>mm de espessura.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exEndometrioMede"
            @click="
              camposExibidos.exEndometrioMede = true;
              laudoSelecionado.laudoJson.endometrioMede = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exEndometrioMede = false;
              laudoSelecionado.laudoJson.endometrioMede = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Ovários:</h4>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exOvaDir2 ? '' : 'text--disabled'
          }`"
        >
          <span>Ovário direito</span>
          <v-select
            :items="[
              'não visualizado',
              'localizado em região parauterina',
              'localizado em região retrouterina',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exOvaDir2"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioDireito"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioDireito == 'outro'"
            required
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            dense
            class="mx-2 flex-grow-0"
            outlined
            :disabled="!camposExibidos.exOvaDir2"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionais9"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exOvaDir2"
            @click="camposExibidos.exOvaDir2 = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exOvaDir2 = false;
              laudoSelecionado.laudoJson.ovarioDireito = undefined;
              laudoSelecionado.laudoJson.comentariosAdcionais9 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span>,</span>

          <v-select
            :items="['a forma é típica e os limites bem definidos', 'outro']"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exOvarioDireito"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioDireito2"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioDireito2 == 'outro'"
            required
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            outlined
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exOvarioDireito"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionais"
          ></v-textarea>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exOvarioDireito"
            @click="camposExibidos.exOvarioDireito = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exOvarioDireito = false;
              laudoSelecionado.laudoJson.ovarioDireito2 = undefined;
              laudoSelecionado.laudoJson.comentariosAdcionais = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exImagemAnecoica4 ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'O parênquima exibe ecogenicidade habitual.',
              'O parênquima  tem ecotextura difusamente heterogênea.',
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/ Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo',
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo',
              'Apresenta imagem anecóica com debris, medindo',
              'Apresenta imagem anecóica multiseptada medindo',
              'Apresenta imagem heterogênea medindo',
              'outro.',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exImagemAnecoica4"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemAnecoica4"
          >
          </v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/ Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-model="laudoSelecionado.laudoJson.imagemAnecoica4Cm"
            :disabled="!camposExibidos.exImagemAnecoica4"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/ Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
            >cm no seu maior diâmetro.</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.finoSeptoCm4"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
            >cm no seu maior diâmetro.</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'Apresenta imagem anecóica com debris, medindo'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaDebrisCm4"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'Apresenta imagem anecóica com debris, medindo'
            "
            >cm no seu maior diâmetro.</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-model="laudoSelecionado.laudoJson.anecoicaMultiseptadaCm4"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
            >cm no seu maior diâmetro.</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'Apresenta imagem heterogênea medindo'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-model="laudoSelecionado.laudoJson.anecoicaHeterogeneaCm45"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'Apresenta imagem heterogênea medindo'
            "
            >x</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'Apresenta imagem heterogênea medindo'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaCm4"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica4 ==
              'Apresenta imagem heterogênea medindo'
            "
            >cm</span
          >

          <v-textarea
            v-if="laudoSelecionado.laudoJson.imagemAnecoica4 == 'outro.'"
            required
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            outlined
            class="mx-2 flex-grow-0"
            dense
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaOutro4"
          ></v-textarea>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exImagemAnecoica4"
            @click="
              camposExibidos.exImagemAnecoica4 = true;
              laudoSelecionado.laudoJson.imagemAnecoica4 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exImagemAnecoica4"
            @click="
              camposExibidos.exImagemAnecoica4 = false;
              laudoSelecionado.laudoJson.imagemAnecoica4 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exMedidasOvDir ? '' : 'text--disabled'
          }`"
        >
          <span>Medidas do ovário direito:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exMedidasOvDir"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito1"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exMedidasOvDir"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito2"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exMedidasOvDir"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito4"
          >
          </v-text-field>
          <span> e volume de</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exMedidasOvDir"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito3"
          >
          </v-text-field>
          <span> cm³.</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exMedidasOvDir"
            @click="
              camposExibidos.exMedidasOvDir = true;
              laudoSelecionado.laudoJson.medidasOvarioDireito1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito3 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito4 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exMedidasOvDir"
            @click="
              camposExibidos.exMedidasOvDir = false;
              laudoSelecionado.laudoJson.medidasOvarioDireito1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito3 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito4 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exOvrEsq2 ? '' : 'text--disabled'
          }`"
        >
          <span>Ovário esquerdo</span>
          <v-select
            :items="[
              'não visualizado',
              'localizado em região parauterina',
              'localizado em região retrouterina',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exOvrEsq2"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdo"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioEsquerdo == 'outro'"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            validate-on-blur
            filled
            class="mx-2 flex-grow-0"
            dense
            outlined
            :disabled="!camposExibidos.exOvrEsq2"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdoOutro"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exOvrEsq2"
            @click="
              camposExibidos.exOvrEsq2 = true;
              laudoSelecionado.laudoJson.ovarioEsquerdo = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdoOutro = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exOvrEsq2 = false;
              laudoSelecionado.laudoJson.ovarioEsquerdo = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdoOutro = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span>,</span>

          <v-select
            :items="['a forma é típica e os limites bem definidos', 'outro']"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exOvrEsq"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdo2"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.ovarioEsquerdo2 == 'outro'"
            required
            validate-on-blur
            :disabled="!camposExibidos.exOvrEsq"
            filled
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.ovarioEsquerdo2Outro"
          ></v-textarea>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exOvrEsq"
            @click="
              camposExibidos.exOvrEsq = true;

              laudoSelecionado.laudoJson.ovarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdo2Outro = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exOvrEsq = false;

              laudoSelecionado.laudoJson.ovarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.ovarioEsquerdo2Outro = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exImagemAnecoica5 ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'O parênquima exibe ecogenicidade habitual.',
              'O parênquima  tem ecotextura difusamente heterogênea.',
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo',
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo',
              'Apresenta imagem anecóica com debris, medindo',
              'Apresenta imagem anecóica multiseptada medindo',
              'Apresenta imagem heterogênea medindo',
              'outro.',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exImagemAnecoica5"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.imagemAnecoica5"
          >
          </v-select>

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-model="laudoSelecionado.laudoJson.imagemAnecoica5Cm"
            :disabled="!camposExibidos.exImagemAnecoica5"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo'
            "
            >cm no seu maior diâmetro.</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
            required
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-model="laudoSelecionado.laudoJson.finoSeptoCm5"
            :disabled="!camposExibidos.exImagemAnecoica5"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo'
            "
            >cm no seu maior diâmetro.</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'Apresenta imagem anecóica com debris, medindo'
            "
            required
            :disabled="!camposExibidos.exImagemAnecoica5"
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaDebrisCm5"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'Apresenta imagem anecóica com debris, medindo'
            "
            >cm no seu maior diâmetro.</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
            required
            :disabled="!camposExibidos.exImagemAnecoica5"
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaMultiseptadaCm5"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'Apresenta imagem anecóica multiseptada medindo'
            "
            >cm no seu maior diâmetro.</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'Apresenta imagem heterogênea medindo'
            "
            required
            :disabled="!camposExibidos.exImagemAnecoica5"
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.anecoicaHeterogeneaCm5"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'Apresenta imagem heterogênea medindo'
            "
            >x</span
          >

          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'Apresenta imagem heterogênea medindo'
            "
            :disabled="!camposExibidos.exImagemAnecoica5"
            required
            class="mx-2 flex-grow-0"
            dense
            v-mask="''"
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaCm5"
          ></v-text-field>

          <span
            v-if="
              laudoSelecionado.laudoJson.imagemAnecoica5 ==
              'Apresenta imagem heterogênea medindo'
            "
            >cm</span
          >

          <v-textarea
            v-if="laudoSelecionado.laudoJson.imagemAnecoica5 == 'outro.'"
            required
            :disabled="!camposExibidos.exImagemAnecoica5"
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            class="mx-2 flex-grow-0"
            dense
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.imagemHeterogeneaOutro5"
          ></v-textarea>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exImagemAnecoica5"
            @click="camposExibidos.exImagemAnecoica5 = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exImagemAnecoica5 = false;
              laudoSelecionado.laudoJson.imagemAnecoica5Cm = undefined;
              laudoSelecionado.laudoJson.imagemAnecoica5 = undefined;
              laudoSelecionado.laudoJson.finoSeptoCm5 = undefined;
              laudoSelecionado.laudoJson.anecoicaDebrisCm5 = undefined;
              laudoSelecionado.laudoJson.anecoicaMultiseptadaCm5 = undefined;
              laudoSelecionado.laudoJson.anecoicaHeterogeneaCm5 = undefined;
              laudoSelecionado.laudoJson.imagemHeterogeneaCm5 = undefined;
              laudoSelecionado.laudoJson.imagemHeterogeneaOutro5 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exMedOvaEsq ? '' : 'text--disabled'
          }`"
        >
          <span>Medidas do ovário esquerdo:</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exMedOvaEsq"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo1"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exMedOvaEsq"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo2"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exMedOvaEsq"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo4"
          >
          </v-text-field>
          <span> e volume de </span>
          <v-text-field
            v-mask="''"
            :disabled="!camposExibidos.exMedOvaEsq"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo3"
          >
          </v-text-field>
          <span> cm³.</span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exMedOvaEsq"
            @click="camposExibidos.exMedOvaEsq = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exMedOvaEsq = false;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo3 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo4 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            !camposExibidos.exVolBexiga ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Bexiga de forma, volume e contornos normais, sem falha de enchimento ou imagem de cálculo no seu interior',
              'outro',
            ]"
            required
            :disabled="!camposExibidos.exVolBexiga"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-model="laudoSelecionado.laudoJson.volumeBexiga"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.volumeBexiga == 'outro'"
            required
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exVolBexiga"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.volumeBexigaOutro"
          ></v-textarea>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exVolBexiga"
            @click="camposExibidos.exVolBexiga = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exVolBexiga = false;
              laudoSelecionado.laudoJson.volumeBexiga = undefined;
              laudoSelecionado.laudoJson.volumeBexigaOutro = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Impressão:</h4>
        </v-col>
        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exSacoDouglas ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="['Presença', 'Ausência']"
            required
            :disabled="!camposExibidos.exSacoDouglas"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            dense
            class="mx-2 flex-grow-0"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.liquidoDouglas1"
          >
          </v-select>
          <span>de líquido livre em fundo de saco de Douglas.</span>
          <v-text-field
            v-if="laudoSelecionado.laudoJson.liquidoDouglas1 === 'Presença'"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exSacoDouglas"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.presencaLiqDou"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exSacoDouglas"
            @click="
              camposExibidos.exSacoDouglas = true;
              laudoSelecionado.laudoJson.liquidoDouglas1 = undefined;
              laudoSelecionado.laudoJson.presencaLiqDou = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="camposExibidos.exSacoDouglas"
            @click="
              camposExibidos.exLiquidoDouglas = false;
              laudoSelecionado.laudoJson.liquidoDouglas1 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <!-- <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exSacoDouglas ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Presença de líquido livre em fundo de saco de Douglas',
              'Ausência de líquido livre em fundo de saco de Douglas',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exSacoDouglas"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.sacoDouglas"
          >
          </v-select>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exSacoDouglas"
            @click="camposExibidos.exSacoDouglas = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exSacoDouglas"
            @click="
              camposExibidos.exSacoDouglas = false;
              laudoSelecionado.laudoJson.sacoDouglas = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col> -->

        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.impressao1"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Pelve sem alterações ecográficas."
            validate-on-blur
          ></v-checkbox>
          <!-- <v-select
            :items="[
              'Pelve sem alterações ecográficas',
              '---------------------------------------------------------',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.impressao1"
          >
          </v-select> -->
        </v-col>
        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <h4>Útero</h4>
        </v-col>
        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <v-select
            :items="[
              'Miomatose uterina',
              'Miomas uterinos',
              'Achados sugestivos de adenomiose',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.impressao2"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.impressao2 == 'outro'"
            required
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.impressao2Outro"
          ></v-textarea>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <v-select
            :items="[
              'Achados sugestivos de útero bicorno',
              'Achados sugestivos de útero septado',
              'Achados sugestivos de útero didelfo',
              'Achados sugestivos de útero unicorno',
              'outro',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.impressao3"
          >
          </v-select>

          <v-textarea
            v-if="laudoSelecionado.laudoJson.impressao3 == 'outro'"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            filled
            outlined
            :readonly="carregamentoDoLaudo || !podeEditar"
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.impressao3Outro"
          ></v-textarea>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <v-select
            :items="[
              'Aumento volumétrico do útero',
              'Útero com volume reduzido',
              'outro',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-model="laudoSelecionado.laudoJson.impressao4"
          >
          </v-select>
          <v-textarea
            v-if="laudoSelecionado.laudoJson.impressao4 == 'outro'"
            required
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            filled
            outlined
            class="mx-2 flex-grow-0"
            dense
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.impressao4Outro"
          ></v-textarea>
        </v-col>
        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <h4>Líquido intracavitário</h4>
        </v-col>
        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <v-select
            :items="[
              'Líquido intracavitário em pequena quantidade (muco?)',
              'Líquido intracavitário em grande quantidade (muco?)',
              'Líquido intracavitário em pequena quantidade (sangue?)',
              'Líquido intracavitário em grande quantidade (sangue?)',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.impressao5"
          >
          </v-select>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <v-select
            :items="[
              'Conteúdo heterogêneo intracavitário em pequena quantidade',
              'Conteúdo heterogêneo intracavitário em grande quantidade',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.impressao6"
          >
          </v-select>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.impressao7"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Imagem hiperecóica intracavitária (pólipo? Mioma submuccoso?)."
            validate-on-blur
          ></v-checkbox>
          <!-- <v-select
            :items="[
              'Imagem hiperecóica intracavitária (pólipo? Mioma submuccoso?)',
              '-------------------------------------------------------------------------',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-model="laudoSelecionado.laudoJson.impressao7"
          >
          </v-select> -->
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.impressao8"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Endométrio espessado e heterogêneo."
            validate-on-blur
          ></v-checkbox>
          <!-- <v-select
            :items="[
              'Endométrio espessado e heterogêneo',
              '--------------------------------------------------------',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.impressao8"
          >
          </v-select> -->
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <h4>Cistos</h4>
        </v-col>
        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <v-select
            :items="[
              'Cisto simples',
              'Cistro com debris (cisto hemorrágico)',
              'Cisto complexo',
              'Achados sugestivos de endometrioma',
              'Tumor sólido',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarios1"
          >
          </v-select>
          <v-select
            :items="['em ovário direito', 'em ovário esquerdo', 'bilateral']"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarios2"
          >
          </v-select>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center`"> </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.ovarios3"
            class="d-flex justify-content-start align-items-center mt-n1"
            label="Ovários aumentados de volume com textura polimicrocística."
            validate-on-blur
          ></v-checkbox>
          <!-- <v-select
            :items="[
              'Ovários aumentados de volume com textura polimicrocística',
              '-----------------------------------------',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            v-model="laudoSelecionado.laudoJson.ovarios3"
          >
          </v-select> -->
        </v-col>
      </template>

      <v-col cols="12" class="d-flex justify-start align-center pt-6">
        <v-btn
          v-if="podeEditar"
          color="primary"
          :loading="carregamentoDoLaudo"
          @click="
            () => {
              laudoId ? editarLaudo() : salvarLaudo();
            }
          "
        >
          salvar laudo
        </v-btn>

        <v-btn
          color="primary"
          v-if="podeImprimir && !podeEditar"
          @click="imprimirLaudo"
          class="ml-5"
          :loading="loadingImprimir"
        >
          Imprimir
        </v-btn>

        <v-btn
          color="primary"
          v-if="!podeEditar"
          class="ml-5"
          @click="podeEditar = true"
        >
          Editar
        </v-btn>

        <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
          Cancelar
        </v-btn>

        <v-btn
          color="primary"
          v-if="podePersonalizar"
          @click="adcionarConteudoEditorPersonalizado"
          class="ml-5"
        >
          {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
        </v-btn>
      </v-col>
    </v-row>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    VueEditor,
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      quantidadeFolhasEditorPersonalizado: 0,
      carregamentoDoLaudo: false,
      podeEditar: true,
      loadingImprimir: false,
      laudoId: null,
      voltando: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,

      camposExibidos: {
        exEstadoUterSit: true,
        exEstadoUterPos: true,
        exEstadoUter: true,
        exMioEco: true,
        exPresencaDeImg: true,
        exDistriImg: true,
        exDistriImg2: true,
        exPresencaDiv: true,
        exLong: true,
        exAnteroPosterior: true,
        exTransverso: true,
        exVolume: true,
        exIntracavitario: true,
        exEndometrioMede: true,
        exOvarioDireito: true,
        exOvaDir2: true,
        exImagemAnecoica4: true,
        exMedidasOvDir: true,
        exOvrEsq: true,
        exOvrEsq2: true,
        exImagemAnecoica5: true,
        exMedOvaEsq: true,
        exVolBexiga: true,
        exSacoDouglas: true,
      },

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,

      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 2,5 cm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    podeImprimir() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    temIdDoLaudo() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    removeFolha(value) {
      let indice = value - 1;

      if (this.laudoSelecionado.laudoJson.folhas.length != 1) {
        this.conteudoEditorPersonalizado.splice(indice, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.$forceUpdate();
    },
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudoSelecionado.laudoJson.folhas) {
        this.conteudoEditorPersonalizado =
          this.laudoSelecionado.laudoJson.folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }

      let todos = [];

      this.conteudoEditorPersonalizado = [];

      if (this.laudoSelecionado.laudoJson.uteroPosicao) {
        let resultadoPosicao = `O útero está ${this.laudoSelecionado.laudoJson.uteroPosicao}`;
        if (this.laudoSelecionado.laudoJson.uteroSituacao)
          resultadoPosicao += `, ${this.laudoSelecionado.laudoJson.uteroSituacao}`;
        if (this.laudoSelecionado.laudoJson.uteroContornos)
          resultadoPosicao += `, tem contornos ${this.laudoSelecionado.laudoJson.uteroContornos}`;
        resultadoPosicao += '\n';
        todos.push(resultadoPosicao);
      }

      if (
        this.camposExibidos.exMioEco &&
        this.laudoSelecionado.laudoJson.miometrioEcotextura
      ) {
        todos.push(
          `O miométrio tem ecotextura ${this.laudoSelecionado.laudoJson.miometrioEcotextura}\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.presenca) {
        let resultadoPresenca = `Presença de ${this.laudoSelecionado.laudoJson.presenca}`;
        if (this.laudoSelecionado.laudoJson.distribuicaoImagens)
          resultadoPresenca += ` imagens hipoecóicas no miométrio, assim distribuídas: ${this.laudoSelecionado.laudoJson.distribuicaoImagens}`;
        if (this.laudoSelecionado.laudoJson.medindo)
          resultadoPresenca += ` medindo ${this.laudoSelecionado.laudoJson.medindo} cm`;
        if (this.laudoSelecionado.laudoJson.localizacao)
          resultadoPresenca += `, localizado na ${this.laudoSelecionado.laudoJson.localizacao}`;
        resultadoPresenca += '\n';
        todos.push(resultadoPresenca);
      }
      // if (
      //   this.camposExibidos.exPresencaDeImg &&
      //   this.laudoSelecionado.laudoJson.presenca
      // ) {
      //   todos.push(
      //     `Presença de ${this.laudoSelecionado.laudoJson.presenca} imagens hipoecóicas no miométrio, assim distribuídas: ${this.laudoSelecionado.laudoJson.distribuicaoImagens} medindo ${this.laudoSelecionado.laudoJson.medindo}cm, localizado na ${this.laudoSelecionado.laudoJson.localizacao}\n`
      //   );
      // }
      if (
        this.camposExibidos.exPresencaDiv &&
        this.laudoSelecionado.laudoJson.presencaDivisao
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.presencaDivisao}\n`);
      }
      todos.push(`\n<h4>Diâmetros uterinos:</h4>`);

      todos.push(`\n`);
      if (
        this.camposExibidos.exLong &&
        this.laudoSelecionado.laudoJson.longitudinal
      ) {
        todos.push(
          `Longitudinal: ${this.laudoSelecionado.laudoJson.longitudinal} cm.\n`
        );
      }
      if (
        this.camposExibidos.exAnteroPosterior &&
        this.laudoSelecionado.laudoJson.anteroPosterior
      ) {
        todos.push(
          `Ântero-posterior: ${this.laudoSelecionado.laudoJson.anteroPosterior} cm.\n`
        );
      }
      if (
        this.camposExibidos.exTransverso &&
        this.laudoSelecionado.laudoJson.transverso
      ) {
        todos.push(
          `Transverso: ${this.laudoSelecionado.laudoJson.transverso} cm.\n`
        );
      }
      if (
        this.camposExibidos.exVolume &&
        this.laudoSelecionado.laudoJson.volume
      ) {
        todos.push(`Volume: ${this.laudoSelecionado.laudoJson.volume} cm.\n`);
      }
      if (
        this.camposExibidos.exIntracavitario &&
        this.laudoSelecionado.laudoJson.intracavitario
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.intracavitario}`);
        if (
          this.laudoSelecionado.laudoJson.intracavitario ===
            'Imagem hiperecóica medindo' &&
          this.laudoSelecionado.laudoJson.intracavitarioCm
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.intracavitarioCm}`);
        }
        if (
          this.laudoSelecionado.laudoJson.intracavitario === 'Outro' &&
          this.laudoSelecionado.laudoJson.comentariosAdcionais8
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.comentariosAdcionais8}.`
          );
        }
        todos.push('\n');
      }

      if (
        this.camposExibidos.exEndometrioMede &&
        this.laudoSelecionado.laudoJson.endometrioMede
      ) {
        todos.push(
          `O endométrio mede ${this.laudoSelecionado.laudoJson.endometrioMede}mm de espessura.\n`
        );
      }

      todos.push(`\n<h4>Ovários:</h4>`);

      todos.push(`\n`);

      if (
        this.camposExibidos.exOvaDir2 &&
        this.laudoSelecionado.laudoJson.ovarioDireito
      ) {
        todos.push(
          `Ovário direito: ${this.laudoSelecionado.laudoJson.ovarioDireito}, `
        );
        if (
          this.laudoSelecionado.laudoJson.ovarioDireito === 'outro' &&
          this.laudoSelecionado.laudoJson.comentariosAdcionais9
        ) {
          todos.push(
            `${this.laudoSelecionado.laudoJson.comentariosAdcionais9} `
          );
        }
        if (this.laudoSelecionado.laudoJson.ovarioDireito2) {
          todos.push(`${this.laudoSelecionado.laudoJson.ovarioDireito2} `);
        }
        if (
          this.laudoSelecionado.laudoJson.ovarioDireito2 === 'outro' &&
          this.laudoSelecionado.laudoJson.comentariosAdcionais
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.comentariosAdcionais}\n`
          );
        }
      }

      if (
        this.camposExibidos.exImagemAnecoica4 &&
        this.laudoSelecionado.laudoJson.imagemAnecoica4
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.imagemAnecoica4}\n`);
        if (
          this.laudoSelecionado.laudoJson.imagemAnecoica4 ===
            'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo' &&
          this.laudoSelecionado.laudoJson.imagemAnecoica4Cm
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.imagemAnecoica4Cm} cm no seu maior diâmetro.\n`
          );
        }
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica4 ===
          'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo' &&
        this.laudoSelecionado.laudoJson.finoSeptoCm4
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.finoSeptoCm4} cm no seu maior diâmetro.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica4 ===
          'Apresenta imagem anecóica com debris, medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaDebrisCm4
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaDebrisCm4} cm no seu maior diâmetro.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica4 ===
          'Apresenta imagem anecóica multiseptada medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm4
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm4} cm no seu maior diâmetro.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica4 ===
          'Apresenta imagem heterogênea medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaHeterogeneaCm45
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaHeterogeneaCm45} x ${this.laudoSelecionado.laudoJson.imagemHeterogeneaCm4} cm.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica4 === 'outro' &&
        this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro4
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro4}.\n`
        );
      }

      if (
        this.camposExibidos.exMedidasOvDir &&
        this.laudoSelecionado.laudoJson.medidasOvarioDireito1
      ) {
        todos.push(
          `Medidas do ovário direito: ${this.laudoSelecionado.laudoJson.medidasOvarioDireito1}`
        );

        if (this.laudoSelecionado.laudoJson.medidasOvarioDireito2) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioDireito2}`
          );
        }
        if (this.laudoSelecionado.laudoJson.medidasOvarioDireito4) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioDireito4}`
          );
        }
        if (this.laudoSelecionado.laudoJson.medidasOvarioDireito3) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioDireito3} cm e volume de cm³.\n`
          );
        }
      }

      if (
        this.camposExibidos.exOvrEsq2 &&
        this.laudoSelecionado.laudoJson.ovarioEsquerdo
      ) {
        todos.push(
          `Ovário esquerdo: ${this.laudoSelecionado.laudoJson.ovarioEsquerdo}, `
        );
        if (
          this.laudoSelecionado.laudoJson.ovarioEsquerdo === 'outro' &&
          this.laudoSelecionado.laudoJson.ovarioEsquerdoOutro
        ) {
          todos.push(
            `${this.laudoSelecionado.laudoJson.ovarioEsquerdoOutro} \n`
          );
        }
        if (this.laudoSelecionado.laudoJson.ovarioEsquerdo2) {
          todos.push(`${this.laudoSelecionado.laudoJson.ovarioEsquerdo2} \n`);
        }
        if (
          this.laudoSelecionado.laudoJson.ovarioEsquerdo2 === 'outro' &&
          this.laudoSelecionado.laudoJson.ovarioEsquerdo2Outro
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.ovarioEsquerdo2Outro}\n`
          );
        }
      }

      if (
        this.camposExibidos.exImagemAnecoica5 &&
        this.laudoSelecionado.laudoJson.imagemAnecoica5
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.imagemAnecoica5}\n`);
        if (
          this.laudoSelecionado.laudoJson.imagemAnecoica5 ===
            'O parênquima apresenta mais de 20 imagens anecóicas com menos de 1,0 cm/Aumentado à custa de imagem anecóica de contornos regulares e paredes finas medindo' &&
          this.laudoSelecionado.laudoJson.imagemAnecoica5Cm
        ) {
          todos.push(
            ` ${this.laudoSelecionado.laudoJson.imagemAnecoica5Cm} cm no seu maior diâmetro.\n`
          );
        }
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica5 ===
          'Aumentado à custa de imagem anecóica de contornos regulares e paredes finas, apresentando fino septo, medindo' &&
        this.laudoSelecionado.laudoJson.finoSeptoCm5
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.finoSeptoCm5} cm no seu maior diâmetro.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica5 ===
          'Apresenta imagem anecóica com debris, medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaDebrisCm5
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaDebrisCm5} cm no seu maior diâmetro.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica5 ===
          'Apresenta imagem anecóica multiseptada medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm5
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaMultiseptadaCm5} cm no seu maior diâmetro.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica5 ===
          'Apresenta imagem heterogênea medindo' &&
        this.laudoSelecionado.laudoJson.anecoicaHeterogeneaCm5
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.anecoicaHeterogeneaCm5} x ${this.laudoSelecionado.laudoJson.imagemHeterogeneaCm5} cm.\n`
        );
      }
      if (
        this.laudoSelecionado.laudoJson.imagemAnecoica5 === 'outro' &&
        this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro5
      ) {
        todos.push(
          ` ${this.laudoSelecionado.laudoJson.imagemHeterogeneaOutro5}.\n`
        );
      }

      if (
        this.camposExibidos.exMedOvaEsq &&
        this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo1
      ) {
        todos.push(
          `Medidas do ovário esquerdo: ${this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo1}`
        );

        if (this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo2) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo2}`
          );
        }
        if (this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo4) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo4}`
          );
        }
        if (this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo3) {
          todos.push(
            ` x ${this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo3} cm e volume de cm³.\n`
          );
        }
      }

      if (
        this.camposExibidos.exVolBexiga &&
        this.laudoSelecionado.laudoJson.volumeBexiga
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.volumeBexiga}`);
        if (
          this.laudoSelecionado.laudoJson.volumeBexiga === 'outro' &&
          this.laudoSelecionado.laudoJson.volumeBexigaOutro
        ) {
          todos.push(` ${this.laudoSelecionado.laudoJson.volumeBexigaOutro}`);
        }
        todos.push('\n');
      }

      todos.push(`\n<h4>Impressão</h4>\n`);

      if (this.laudoSelecionado.laudoJson.liquidoDouglas1) {
        todos.push(
          `${
            this.laudoSelecionado.laudoJson.liquidoDouglas1
          } de líquido livre em fundo de saco de Douglas. ${
            this.laudoSelecionado.laudoJson.liquidoDouglas1 === 'Presença'
              ? this.laudoSelecionado.laudoJson.presencaLiqDou ?? ''
              : ''
          }`
        );
      }

      if (
        this.camposExibidos.exSacoDouglas &&
        this.laudoSelecionado.laudoJson.sacoDouglas
      ) {
        todos.push(`${this.laudoSelecionado.laudoJson.sacoDouglas}\n`);
      }

      todos.push(`\n`);

      if (this.laudoSelecionado.laudoJson.impressao1) {
        todos.push(`Pelve sem alterações ecográficas.\n`);
      }

      todos.push(`\n<h4>Útero</h4>\n`);

      if (this.laudoSelecionado.laudoJson.impressao2) {
        if (
          this.laudoSelecionado.laudoJson.impressao2 === 'outro' &&
          this.laudoSelecionado.laudoJson.impressao2Outro
        ) {
          todos.push(`${this.laudoSelecionado.laudoJson.impressao2Outro}`);
        } else {
          todos.push(`${this.laudoSelecionado.laudoJson.impressao2}`);
        }
        todos.push('\n');
      }

      if (this.laudoSelecionado.laudoJson.impressao3) {
        if (
          this.laudoSelecionado.laudoJson.impressao3 === 'outro' &&
          this.laudoSelecionado.laudoJson.impressao3Outro
        ) {
          todos.push(`${this.laudoSelecionado.laudoJson.impressao3Outro}`);
        } else {
          todos.push(`${this.laudoSelecionado.laudoJson.impressao3}`);
        }
        todos.push('\n');
      }
      if (this.laudoSelecionado.laudoJson.impressao4) {
        if (
          this.laudoSelecionado.laudoJson.impressao4 === 'outro' &&
          this.laudoSelecionado.laudoJson.impressao4Outro
        ) {
          todos.push(`${this.laudoSelecionado.laudoJson.impressao4Outro}`);
        } else {
          todos.push(`${this.laudoSelecionado.laudoJson.impressao4}`);
        }
        todos.push('\n');
      }
      todos.push(`\n<h4>Líquido intracavitário</h4>\n`);
      if (this.laudoSelecionado.laudoJson.impressao5) {
        todos.push(`${this.laudoSelecionado.laudoJson.impressao5}\n`);
      }
      if (this.laudoSelecionado.laudoJson.impressao6) {
        todos.push(`${this.laudoSelecionado.laudoJson.impressao6}\n`);
      }
      if (this.laudoSelecionado.laudoJson.impressao7) {
        todos.push(
          `Imagem hiperecóica intracavitária (pólipo? Mioma submuccoso?).\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.impressao8) {
        todos.push(`Endométrio espessado e heterogêneo.\n`);
      }

      if (
        this.laudoSelecionado.laudoJson.ovarios1 ||
        this.laudoSelecionado.laudoJson.ovarios3
      ) {
        todos.push(`\n<h4>Cistos</h4>`);
        todos.push(`\n`);
      }
      if (this.laudoSelecionado.laudoJson.ovarios1) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.ovarios1} ${this.laudoSelecionado.laudoJson.ovarios2}\n`
        );
      }
      // if (this.laudoSelecionado.laudoJson.ovarios2) {
      //   todos.push(`${this.laudoSelecionado.laudoJson.ovarios2}\n`);
      // }
      if (this.laudoSelecionado.laudoJson.ovarios3) {
        todos.push(
          `Ovários aumentados de volume com textura polimicrocística.\n`
        );
      }

      while (todos.length > 0) {
        const teste = todos.splice(0, 44);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },

    forceReRender() {
      this.componentKey++;
    },

    ...mapGetters('Offline', ['requisicao', 'dados']),

    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          this.carregamentoDoLaudo = true;

          const exameId = this.$route.query.exame;

          this.laudoSelecionado.examePorConsultaId = exameId;
          this.consultaSelecionada.status = 'LAUDADO';

          if (navigator.onLine) {
            const { data } = await this.postLaudo();
            this.laudoId = data;
            await this.alterarStatus();

            if (!Number.isInteger(data)) throw new Error(data);

            this.getLaudoByExamePorConsultaId(exameId);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
              requisicaoSalvar: this.postLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'POST_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', {
              laudo: this.laudoSelecionado,
              consulta: this.consultaSelecionada,
            });
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        this.carregamentoDoLaudo = false;
        return;
      } finally {
        try {
          this.carregamentoDoLaudo = false;
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            await this.patchLaudo(this.laudoSelecionado);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
            // this.podeImprimir = false;
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    juntaPersonalizadoAoLaudo() {
      this.laudoSelecionado.laudoJson.folhas = this.conteudoEditorPersonalizado;
      this.laudoSelecionado.laudoJson.titulo =
        'ULTRASSONOGRAFIA PÉLVICA TRANSABDOMINAL';
    },
    desativaLoading() {
      this.loadingImprimir = false;
    },
    async imprimirLaudo() {
      this.loadingImprimir = true;
      this.laudoPrint = 'impresso';
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.pelvicatransabdominal',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },
    formataLaudo() {
      if (this.exibeObs) {
        this.laudoSelecionado.laudoJson.exibeObs = false;
      } else {
        this.laudoSelecionado.laudoJson.exibeObs = true;
      }
    },

    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },
  },
  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;

    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      //   this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
