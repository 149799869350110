<template>

    <v-dialog v-model="show" transition="dialog-top-transition" width="700px">

      <v-card class="px-4 py-4">
        <span class="d-flex justify-end">
                  <v-icon class="pa-3" @click="close">
                    mdi-close-circle-outline
                  </v-icon>
                </span>
                <h2 class="pb-8 text-center">CRIAR MÉDICO SOLICITANTE</h2>
          <v-row>
            <v-col cols="12" md="3">
                <v-text-field
                  class="maiuscula"
                  label="Nome"
                  validate-on-blur
                  filled
                  outlined
                  required
                  v-model="model.nome"
                  @change="limpaTela"
                  :rules="[value => !!value || 'Campo obrigatório']"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  class="maiuscula"
                  label="Especialização"
                  required
                  validate-on-blur
                  filled
                  outlined
                  v-model="model.especializacao"
                  @change="limpaTela"
                  :rules="[value => !!value || 'Campo obrigatório']"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  class="maiuscula"
                  label="CRM"
                  required
                  validate-on-blur
                  filled
                  outlined
                  v-model="model.crm"
                  @change="limpaTela"
                  :rules="[value => !!value || 'Campo obrigatório']"
                />
              </v-col>
            
        </v-row>
        <v-row>
          <v-btn class="ml-4 mt-n6" color="primary" @click="salvarMedico"
              >SALVAR MÉDICO</v-btn
            >
        
        </v-row>
        <v-alert
        v-if="sucesso"
      color="success"
      class="mt-8"
      icon="$success"
      title="Salvo"
      
    >Médico Solicitante salvo com sucesso</v-alert>
    <v-alert
    v-if="erro"
      color="error"
      class="mt-8"
      icon="$warning"
      title="Salvo"
      type="warning"
      
    >Não foi possivel salvar o médico solicitante, preencha todos os campos e tente novamente</v-alert>

        
          
      </v-card>
    </v-dialog>

  </template>

  <script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import axios from 'axios';
  import ConsultasMixin from '@/mixins/ConsultasMixin';
  import MedicoMixin from '@/mixins/MedicoMixin';
  import LoginMixin from '@/mixins/LoginMixin';
  export default {
    mixins: [
      ConsultasMixin,
      LoginMixin,
      MedicoMixin,
      ],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
        model: {
      nome: null,
      crm: null,
      especializacao:'',
      tipoMedico: 'Solicitante',
      loginId: 18
      

    },
    erro: false,
      sucesso: false,
   

    }),

    methods: {
        ...mapActions('Auth', ['login']),
      close() {
        this.$emit('close');

      },
      
    async salvarMedico() {
      if(!this.model.nome){
        this.erro = true
        return
      }
      this.postMedico(this.model).then(()=>{
                this.sucesso = true;

                this.model = {
      nome: null,
      crm: null,
      especializacao:'',
      tipoMedico: 'Solicitante',
      loginId: 18
      

    }
    this.getMedicos().then(() => {
      this.getNomesMedicosExecutanteSolicitanteNew(this);
    });
    // this.getMedicos().then(() => {
    //   this.getNomesMedicosExecutanteSolicitante();
    // });
              }).catch(()=>{
                this.erro = true
              })
      
    },
    handleConvenioChange(convenioNome) {
      
    },
    formatarValor(){
      let valorFormatado = parseFloat(this.model.valor.replace(/[^\d.]/g, '')).toFixed(2);
      this.model.valor= valorFormatado;
    },
    limpaTela(){
        this.erro = false
        this.sucesso =false
        this.$forceUpdate;
      }



    },
    mounted() {
    
  },
  };
  </script>

  <style>

  </style>
