<template>
    <div>
      <v-container fluid style="max-width: 95%">
        <v-card class="pa-5 ma-5" elevation="3">
          <v-form
            ref="formularioDeProcedimento"
            @submit.prevent="salvarProcedimento"
            v-model="formValidado"
          >
            <h2 class="pb-8 text-center">NOVO USUÁRIO</h2>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  class="maiuscula"
                  label="Nome"
                  validate-on-blur
                  filled
                  outlined
                  required
                  v-model="model.usuario"
                  @change="limpaTela"
                  :rules="[value => !!value || 'Campo obrigatório']"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  class="maiuscula"
                  label="Tipo do perfil"
                  required
                  
                  validate-on-blur
                  filled
                  outlined
                  v-model="model.nvlPermissao"
                  @change="limpaTela"
                  :items="permissao"
                  :rules="[value => !!value || 'Campo obrigatório']"
                />
              </v-col>
              
              <v-col cols="12" md="3">
                <v-text-field
                  class="maiuscula"
                  label="email"
                  required
                  validate-on-blur
                  filled
                  outlined
                  v-model="model.email"
                  @change="limpaTela"
                  :rules="[value => !!value || 'Campo obrigatório']"
                />
              </v-col>
            </v-row>
            <v-row v-if="model.nvlPermissao == 2">
             
              <v-col cols="12" md="3">
                <v-text-field
                  class="maiuscula"
                  label="Especialização"
                  required
                  validate-on-blur
                  filled
                  outlined
                  v-model="med.especializacao"
                  @change="limpaTela"
                  :rules="[value => !!value || 'Campo obrigatório']"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  class="maiuscula"
                  label="Tipo do médico"
                  required
                  
                  validate-on-blur
                  filled
                  outlined
                  v-model="med.tipoMedico"
                  @change="limpaTela"
                  :items =tiposMed 
                  :rules="[value => !!value || 'Campo obrigatório']"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  class="maiuscula"
                  label="CRM"
                  required
                  validate-on-blur
                  filled
                  outlined
                  v-model="med.crm"
                  @change="limpaTela"
                  :rules="[value => !!value || 'Campo obrigatório']"
                />
              </v-col>

            </v-row>
            <v-col cols="12" class="d-flex justify-start align-center pt-6">
              <v-btn color="primary" @click="cadastrar"
                >CADASTRAR USUÁRIO</v-btn
              >
              <!-- <v-btn type="submit" color="primary" class="ml-5">Salvar</v-btn> -->
            </v-col>
           
          </v-form>
          <v-alert type="success" v-model="sucesso">
            Usuário cadastrado com sucesso
            </v-alert>

            <v-alert type="error" v-model="erro">
            Erro ao cadastrar usuário, tente novamente
         </v-alert>
        </v-card>
        
      </v-container>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  
import LoginMixin from '@/mixins/LoginMixin';
  export default {
    mixins: [LoginMixin ],
    props: ['exibirConsulta', 'exibirBotao', 'exibirMenu'],
    data: () => ({
      formValidado: true,
      loadingSalvar: false,
      loadingProc: false,
      exibirProcedimentos: false,
      model: { nvlPermissao: '', email: '', usuario: '',  },
      erro: false,
      sucesso: false,
      med: {
        especializacao: '',
        crm: '',
        nome:'',
        tipoMedico: '',
        loginId : '',

      },
      tiposMed: ['Executante', 'Solicitante' ],
      
      permissao: [{
          text: 'Financeiro',
          value: 3,
        },{
          text: 'Recepção',
          value: 1,
        },{
          text: 'Médico',
          value: 2,
        },{
          text: 'Digitador',
          value: 4,
        }],
      search: '',
      mostrarCampoPesquisa: false,
      
    }),
    computed: {
      
    },
  
    methods: {
      async cadastrar(){
        
        this.postUsuario(this.model)
        .then((resp)=>{
           
         
            this.med.loginId = resp.data.id
            
            if(this.model.nvlPermissao == 2){
              this.med.nome = this.model.usuario
              this.postMedico(this.med).then(()=>{
                this.sucesso = true;

                this.model = { nvlPermissao: '', email: '', usuario: '',  }
              })

            }else{
              this.sucesso = true;
              this.model = { nvlPermissao: '', email: '', usuario: '',  }
            }

            

        }).catch(()=>{
            this.erro = true
        })
      },
      limpaTela(){
        this.erro = false
        this.sucesso =false
        this.$forceUpdate;
      }
      
      
    },
    mounted() {
     
    },
  };
  </script>
  
  <style></style>
  