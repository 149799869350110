<template lang="">
  <div>
    <LaudoAmniocentese></LaudoAmniocentese>
  </div>
</template>
<script>
import LaudoAmniocentese from '../components/laudos/formularios/exames/amniocentes/LaudoAmniocentese.vue';
export default {
  components: {
    LaudoAmniocentese,
  },
};
</script>
<style lang=""></style>
