<template lang="">
  <div>
    <template>
      <v-container fluid style="max-width: 100%">
        <v-row justify="space-between">
          <v-col cols="12" md="12" sm="12">
            <div>
              <v-date-picker
                v-model="date1"
                :events="arrayEvents"
                :event-color="date => getCores(date)"
                color="grey darken-1"
                full-width
                locale="pt-br"
                class="date-picker"
                elevation="2"
                @change="handleData"
              >
               
            </v-date-picker>
            <span style="color: green" >&diams;</span> Menos de 10
               <span style="color: yellow">&diams;</span> Entre 11 e 20<br>
               <span style="color: red">&diams;</span> Mais de 20
              
              
            </div>
          </v-col>
        </v-row>
        <LoadingDialog :loading="loadingDialog"/>
        <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
      </v-container>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ConsultasMixin from '../mixins/ConsultasMixin';
import moment from 'moment';
import LoginMixin from '../mixins/LoginMixin';
import CalendarioMixin from '../mixins/CalendarioMixin';
import LoadingDialog from '@components/LoadingDialog.vue';
import axios from 'axios';
import { EMBRION_API_URL } from '@/data/enviroment';
import ModalLogin from '@/components/homeRecepcao/modalLoginAgenda.vue';
export default {
  components: { LoadingDialog },
  mixins: [ConsultasMixin, LoginMixin, CalendarioMixin],
  data: () => ({
    arrayEvents: [],
    loadingDialog: false,
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    daysOfWeek: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
    urlBase: EMBRION_API_URL,
    datas: {},
    coresDatas: {},
    modal: {
        active: false,
        modalComponent: ModalLogin,
      },
  }),
  watch: {
    '$store.state.medicos.medicoSelecionado.nome': function () {
      
      const dates = this.date1.split('-');
    let dia = dates[2];
    let mes = dates[1];
    let ano = dates[0];
    
      this.addEvents(dia, mes, ano, this.medicoSelecionado.id);
      this.$forceUpdate();
    }
  },

  mounted() {
   
    const dates = this.date1.split('-');
    let dia = dates[2];
    let mes = dates[1];
    let ano = dates[0];
    const data = `${dia}/${mes}/${ano}`;
    this.selecionaData(data);
   
    this.addEvents(dia, mes, ano, this.medicoSelecionado.id);
    this.$forceUpdate();
  },
  
  methods: {
    ...mapActions(['selecionaData']),
    getCores(data){
      
      const dataFormatada = moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');

      
      if(this.coresDatas[dataFormatada] < 10){
        return "green"

      }else if( 10 <= this.coresDatas[dataFormatada] &&  this.coresDatas[dataFormatada] <=  20){
        return "yellow"

      }else if( 20 < this.coresDatas[dataFormatada]){
        return "red"
      }

    },
    handleData(event) {
      this.loadingDialog = true;
     

      const dates = event.split('-');
      let dia = dates[2];
      let mes = dates[1];
      let ano = dates[0];
      const data = `${dia}/${mes}/${ano}`;
      this.selecionaData(data);
      this.getConsultaByDataMedico({
        medicoId: this.medicoSelecionado.id,
        data,
      }).then(() => {
        this.adicionaEspacos();
        this.loadingDialog = false;
        this.addEvents(dia, mes, ano, this.medicoSelecionado.id);
        this.$forceUpdate();
      }).catch(()=>{
        this.addEvents(dia, mes, ano, this.medicoSelecionado.id);
        
        this.$forceUpdate();
       
        
      });

      
    },
    abreModal() {
      this.modal.active = true;
    
    },
    closeModal() {
      this.modal.active = false;
      
      this.handleData(this.date1)
      
    },

    async addEvents(dia, mes, ano, medicoId) {
      await this.getDataComConsultas(this,dia,mes,ano, medicoId)
      
      await this.getNumeroConsultasMes(mes,ano, medicoId).then(resp => {
        const datas = resp.data;
        
        this.coresDatas = resp.data
        this.arrayEvents = [];
        let arrayEvents = Object.keys(datas).map(data => {
          const n = datas[data];
          if (n > 0) {
            let [dia, mes, ano] = data.split('/');
            
            return `${ano}-${mes}-${dia}`;
          }
        })
        
        this.arrayEvents = [...this.arrayEvents, ...arrayEvents.filter(d => d)];
      
        this.$forceUpdate();
    
      }).catch((e)=>{
          
           if(e.response.status == 401) {
           this.abreModal();

           }      
          
        });;
      
      
    },
  },
};
</script>
<style lang="css"></style>
