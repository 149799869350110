var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","xs":"4"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-md-7 pa-2 grey darken-1",attrs:{"block":""}},'v-btn',attrs,false),on),[_c('h2',{staticClass:"grey--text text--lighten-4 text-center text-button text-md-h5 font-weight-bold"},[_vm._v(" PRÓXIMO PACIENTE ")])])]}},{key:"default",fn:function(dialog){return [_c('v-card',{staticStyle:{"overflow":"hidden"}},[_c('span',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"pa-3",on:{"click":function($event){dialog.value = false}}},[_vm._v(" mdi-close-circle-outline ")])],1),_c('v-card-text',[_c('div',{staticClass:"text-h5 pa-8 text-center font-weight-bold"},[_vm._v(" Próximo paciente: ")]),_c('v-select',{attrs:{"label":"Selecione uma Sala","items":_vm.salas,"filled":"","outlined":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',{attrs:{"id":attrs['aria-labelledby']},domProps:{"textContent":_vm._s(item)}})],1)]}}],null,true),model:{value:(_vm.model.salaNumber),callback:function ($$v) {_vm.$set(_vm.model, "salaNumber", $$v)},expression:"model.salaNumber"}}),_c('v-select',{attrs:{"label":"Selecione um paciente","filled":"","outlined":"","items":_vm.pacientesDoDia,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',{attrs:{"id":attrs['aria-labelledby']},domProps:{"textContent":_vm._s(item)}})],1)]}}],null,true),model:{value:(_vm.model.pacienteName),callback:function ($$v) {_vm.$set(_vm.model, "pacienteName", $$v)},expression:"model.pacienteName"}})],1),_c('v-card-actions',{staticClass:"justify-center pb-8"},[_c('v-btn',{staticClass:"grey darken-1 grey--text text--lighten-4 text-center mx-2 pa-3",attrs:{"text":"","depressed":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.chamaPaciente}},[_vm._v(" Confirmar ")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }