<template lang="">
  <div>
    <LaudoPelvicaTransvaginalDopplerPreparoIntestinal></LaudoPelvicaTransvaginalDopplerPreparoIntestinal>
  </div>
</template>
<script>
import LaudoPelvicaTransvaginalDopplerPreparoIntestinal from "../components/laudos/formularios/exames/pelvicaTransvaginalDopplerPreparoIntestinal/LaudoPelvicaTransvaginalDopplerPreparoIntestinal.vue";
export default {
  components: {
    LaudoPelvicaTransvaginalDopplerPreparoIntestinal,
  },
};
</script>
<style lang=""></style>
