<template>
  <v-container class="ma-2 ml-2 text-center" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="ma-5">
          <br />
          <v-row>
            <v-col class="ml-5 white--text" cols="4" md="2">
              <v-text-field
                label="De:"
                type="date"
                v-model="modelBody.dataInicial"
              ></v-text-field>
              <v-checkbox
                class="mt-n6 white--text text--darken-3"
                label="Data atual"
                v-model="model.checkDataInicial"
                @change="selectCurrentDate('start')"
              >
              </v-checkbox> </v-col
            ><v-col class="ml-5 white--text" cols="4" md="2">
              <v-text-field
                label="Até:"
                type="date"
                v-model="modelBody.dataFinal"
              ></v-text-field>
              <v-checkbox
                class="mt-n6 white--text text--darken-3"
                label="Data atual"
                v-model="model.checkDataFinal"
                @change="selectCurrentDate('end')"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="2" md="2">
              <v-select
              class="maiuscula white--tex"
              background-color="#FFFFFF"
              label="TIPO"
              filled
              outlined
              :clearable="true"
              required
              v-model="model.modo"
              :items="['cartão', 'convênio']"
             
            />
            </v-col>
            <v-col cols="2" md="2" v-if="model.modo == 'convênio'">
            <v-select
              class="maiuscula white--tex"
              background-color="#FFFFFF"
              label="Convênio"
              filled
              outlined
              :clearable="true"
              required
              v-model="model.convenio"
              :items="[...nomesConvenios, 'TODOS']"
              @change="handleConvenioChange"
            />
            <!-- <v-checkbox class="mt-n8" label="Todos os convênio">
            </v-checkbox> -->
          </v-col>
          <v-col cols="2" md="2">
          <v-select
            class="maiuscula white--tex"
            background-color="#FFFFFF"
            label="Médico"
            filled
            @change="handleMedicoChange"
            outlined
            :clearable="false"
            required
            v-model="medico"
            :items="this.nivelPermissao==2 || this.nivelPermissao==5? (model.modo == 'cartão'? [...this.nomesMedicosExecutantes, ]:[...this.nomesMedicosExecutantes, 'TODOS' ])  : (model.modo == 'cartão'? [...this.nomesMedicosExecutantes, ...this.nomesMedicos]:[...this.nomesMedicosExecutantes, 'TODOS',...this.nomesMedicos ])"
          />
        </v-col>
          <!-- <v-col cols="2" md="2" v-if="model.convenio">
            tem convenio
          </v-col> -->
            <!-- <v-col cols="2" md="2">
            <v-select
              label="Pagamento"
              background-color="#FFFFFF"
              class="maiuscula"
              :clearable="false"
              filled
              outlined
              required
              :items="this.formasPagamento"
              v-model="modelBody.formaDePagamento"
            />
            <v-checkbox class="mt-n8 font-size-8" label="Todas as formas de pagamentos">
            </v-checkbox>
          </v-col> -->
            <v-col cols="4" md="2">
              <v-btn @click="getContasAReceber"
                ><v-icon large dense> mdi-magnify </v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-col >
            <v-col v-if="model.modo == 'cartão'" cols="12" class="text-right"
            >Valor Total: {{ totalValoresAReceber }}</v-col
          >
            <v-data-table
              :headers="model.modo == 'cartão'? headers1 : headers2"
              :items="valoresAReceber"
              sort-by="data"
              :items-per-page="-1"
              @click:row="handleClick"
              class="elevation-1 maiuscula"
              no-data-text="Nenhuma conta "
              :loading="loading"
              loading-text="Buscando contas..."
            >
              <template v-slot:item.situacao="{ item }">
                <v-chip
                  class="text-center"
                  :color="item.situacao == 'recebido' ? 'green' : 'red'"
                  style="font-size: 12px"
                  @click="btnRecebido(item)"
                >
                  {{ item.situacao }}
                </v-chip>
              </template>
              <template v-slot:item.parcela="{ item }">
                <span
                  >{{ item.parcela  }} de {{ item.consulta.pagamento.parcelamento }}
                  </span
                >
                
              </template>
              <template v-if="model.modo == 'cartão'" v-slot:item.exames="{ item }">
                <span  v-for="exame, index in item.listaExames" :key="index"
                  >{{exame}} <br>
                  
                  </span
                >
              </template>
              <template v-else v-slot:item.exames="{ item }">
                {{ item.exames }}
              </template>
              <!-- <template v-slot:item.valorEsperado="{ item }">
                {{ item }}
              </template> -->
            </v-data-table>
          </v-col>
          <!-- <v-col v-else>
            convenio
          </v-col> -->

          
          
        </v-card>
      </v-col>
    </v-row>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>
<script>
import ConsultasMixin from '@mixins/ConsultasMixin';
import PagamentosMixin from '@mixins/PagamentosMixin';
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import { EMBRION_API_URL } from '@/data/enviroment';
import modalFinalizaContaVue from './modalFinalizaConta.vue';
import VisualizarPagamentoModal from '../pagamento/modals/VisualizarPagamentoModal.vue';

export default {
  mixins: [ConsultasMixin, PagamentosMixin],
  data() {
    return {
      urlBase: EMBRION_API_URL,
      modal: {
        active: false,
        modalComponent: VisualizarPagamentoModal,
      },
      

      consultasComPagamentos: [],
      consultasTotal: [],
      consulta: [],
      medico: 'TODOS',
      modelBody: {
        dataFinal: '',
        dataInicial: '',
        convenioId: '',
        medicoId: '',
        formaDePagamento: '',
        
      },
      nomesMedicos: [],
      model: {
        modo: 'cartão',
        convenio: false,
        checkDataFinal: false,
        checkDataInicial: false,
      },
      items: [],
      search: '',
      loading: false,
      headers1: [
        {
          text: 'Data',
          align: 'start',
          value: 'data',
        },
        {
          text: 'Nome do cartão',
          value: 'nomeCartao',
        },
        { text: 'Exames', value: 'exames' },

        
        { text: 'Valor recebido', value: 'valorRecebido' },
        { text: 'Valor', value: 'valor' },
        { text: 'Parcela', value: 'parcela' },
        { text: 'Situação', value: 'situacao' },
        
        // { text: '', value: 'actions' },
      ],
      headers2: [
        {
          text: 'Data',
          align: 'start',
          value: 'data',
        },
       
        { text: 'Exames', value: 'exames' },

        
        { text: 'Valor recebido', value: 'valorRecebido' },
        { text: 'Valor esperado', value: 'valorEsperado' },
        
        // { text: 'Situação', value: 'consulta.' },
        
        // { text: '', value: 'actions' },
      ],
      valorTotal: 0,
    };
  },
  computed: {
    ...mapGetters('Auth', ['headers', 'nivelPermissao']),
    ...mapGetters(['valoresReceber', 'totalValoresReceber']),
    valoresAReceber() {
      return this.$store.getters['Financeiro/valoresReceber'] || [];
    },
    totalValoresAReceber() {
      return this.$store.getters['Financeiro/totalValoresReceber'] || 0;
    },
    
  },
  mounted() {
    this.model.modo = 'cartão'
    this.selectCurrentDate('start');
    this.selectCurrentDate('end');
    this.getContasAReceber();
    this.getNomesConvenios();

    this.getMedicos().then(() => {
      this.getNomesMedicosExecutanteSolicitante();
    });
  },
  methods: {
    btnRecebido(conta) {
      if (conta.situacao !== 'recebido') {
        this.setContaSelecionada(this, conta);
        this.modal.active = true;
      }
    },
    async handleClick(conta) {
      this.setContaSelecionada(this, conta);
      await this.getConsultaById(conta.consultaId);
     
      this.modal.active = true;
      
      // if (conta.situacao !== 'recebido') {
      //   this.setContaSelecionada(this, conta);
        
        

      
      // }
    },
    closeModal() {
      // this.getContasAReceber()
      //   .then(() => {
      //     this.modal.active = false;
      //   })
      //   .catch(() => {
         
      //   });
      this.getContasAReceber()
        this.modal.active = false;
    },
    async handleMedicoChange(nome) {
      this.loadingDialog = true;

      if (!nome) {
        this.resetaMedicoSelecionado();
        await this.getConsultaByData(this.dataSelecionada).then(() => {
          this.loadingDialog = false;
        });

        return;
      }
      let medico = await this.getMedicoByNome(nome);
      this.setMedicoSelecionado(medico);
    },
    handleConvenioChange(convenioNome) {
      this.setaConvenioSelecionado(convenioNome).then(() => {
        this.consultaSelecionada.exames.map(e => {
          e.valorExameConvenio.convenio = this.convenioSelecionado;
          // this.handleExameChange(e.valorExameConvenio.exame.nome);
        });
        this.modelBody.convenioId = this.convenioSelecionado.id;
      });
      if(convenioNome == 'TODOS') this.modelBody.convenioId = ''
    },
    async getContasAReceber() {
      this.modelBody.medicoId = this.medico == 'TODOS'?'':  this.medicoSelecionado.id;
      this.modelBody.convenioId = this.model.convenio == 'TODOS'? '':  this.modelBody.convenioId
      this.loading = true;
      this.model
      await this.getValoresReceber(this, this.modelBody, this.model.modo).then(
        () => (this.loading = false)
      ).catch(()=>{
        this.loading = false
      });
    },
    selectCurrentDate(field) {
      if (field === 'start') {
        this.modelBody.dataInicial = this.getCurrentDate();
      } else if (field === 'end') {
        this.modelBody.dataFinal = this.getCurrentDate();
      }
      this.$forceUpdate;
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.v-input--checkbox .v-label {
  font-size: 15px;
}
.v-input_slot {
  font-size: 15px;
}
</style>
