<template>
  <v-dialog v-model="show" transition="dialog-top-transition" width="800px">
    <v-card class="px-6 py-6 d-flex flex-column align-center">
      <v-icon class="pa-3 align-self-start ml-auto" @click="close">
        mdi-close-circle-outline
      </v-icon>
      <h2 class="pb-8 text-center">LANÇAR APORTE</h2>

      <v-row class="flex-grow-1 justify-center">
        <v-col cols="6" md="3">
          <v-text-field
            class="maiuscula"
            label="Valor (R$)"
            required
            validate-on-blur
            filled
            outlined
            @change="formatarValor"
            v-model="modal.valor"
            :rules="[value => !!value || 'Campo obrigatório']"
          />
        </v-col>
        <v-col cols="6" md="5">
          <v-text-field
            class="maiuscula"
            label="Título"
            validate-on-blur
            filled
            outlined
            required
            v-model="modal.nome"
            :rules="[value => !!value || 'Campo obrigatório']"
          />
        </v-col>
        <v-col cols="2" md="4">
          <v-text-field
            class="maiuscula"
            label="Data"
            readonly
            type="date"
            validate-on-blur
            filled
            v-model="modal.data"
          />
        </v-col>
      </v-row>

      <br />
      <v-row class="mt-auto">
        <v-btn class="ml-auto" color="primary" @click="lancarAporte"
          >LANÇAR APORTE</v-btn
        >
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import axios from 'axios';
import ConsultasMixin from '@/mixins/ConsultasMixin';
import PagamentosMixin from '@/mixins/PagamentosMixin';
export default {
  mixins: [ConsultasMixin, PagamentosMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modelo: {
      usuario: null,
      senha: null,
    },

    modal: {
      valor: '',
      nome: '',
      data: '',
    },
  }),

  methods: {
    ...mapActions('Auth', ['login']),
    close() {
      this.$emit('close');
    },
    async fazLogin() {
      await this.login(this.modelo);

      this.mostraAlerta = false;
      this.close();
    },
    async lancarAporte() {
      await this.postEnviaAporte(this.modal)
        .then(resp => {
          if (resp.status === 200) {
            alert('APORTE REALIZADO COM SUCESSO');
          }
          this.modal.valor = '';
          this.modal.nome = '';
          this.close();
        })
        .catch(() => {
          alert('APORTE NÃO SALVO, POR FAVOR VERIFICAR');
        });
    },

    formatarValor() {
      let valorFormatado = parseFloat(
        this.modal.valor.replace(/[^\d.]/g, '')
      ).toFixed(2);
      this.modal.valor = valorFormatado;
    },
    // setTodayDate() {
    //   const today = new Date();
    //   const day = String(today.getDate()).padStart(2, '0');
    //   const month = String(today.getMonth() + 1).padStart(2, '0'); // Mês é base 0
    //   const year = today.getFullYear();
    // },
  },
  mounted() {
    // this.setTodayDate();
    this.modal.data = moment().format('YYYY-MM-DD');
  },
};
</script>

<style></style>
