<template>
  <div>
    <v-row>
      <v-col>
        <div class="d-flex ml-12 mt-8">
          <v-btn @click="voltar">
            <v-icon>mdi-arrow-left</v-icon>
            voltar
          </v-btn>
        </div>
        <router-view></router-view>
      </v-col>
    </v-row>
    <consulta></consulta>
  </div>
</template>

<script>
import Consulta from '../components/consultas/Consulta.vue';
import index from '../store/index.js'

export default {
  components: {
    Consulta, 
  },
  data() {
    return {
      // medicoSelecionadoId: null,
    };
  },
  methods: {
    // voltar() {
    //   this.$router.push({
    //     path: '/recepcao',
    //     query: { medicoId: this.medicoSelecionadoId },
    //   });
    // },

    voltar() {
    this.$router.push({
      path: '/recepcao',
      params: { medicoId: this.medicoSelecionadoId },
    });
    // // Salvar medicoSelecionadoId no localStorage
    // localStorage.setItem('medicoSelecionado.id', this.medicoSelecionado.id);
  },
  },
  created() {
    // this.medicoSelecionadoId = this.$route.query.medicoId;

    // Defina o medicoSelecionadoId com o valor do Vuex
    this.$store.commit('setMedicoSelecionadoId', this.$route.query.medicoId);
  },
};
</script>

<style lang=""></style>
