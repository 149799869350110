import axios from 'axios';
import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import { EMBRION_API_URL } from '../data/enviroment';

export default {
  data() {
    return {
      urlBase: EMBRION_API_URL,
      nomesPacientes: [],
    };
  },
  computed: {
    ...mapState({
      pacientes: 'pacientes',
      pacienteSelecionado: 'pacienteSelecionado',
    }),
    ...mapGetters(['listaPacientes', 'pacienteById', 'consultaSelecionada']),
    ...mapGetters('Auth', ['headers']),
  },
  methods: {
    ...mapActions({
      getPacientes: 'getPacientes',
      getPacienteById: 'getPacienteById',
      resetaPacienteSelecionado: 'resetaPacienteSelecionado',
      setPacienteSelecionado: 'setPacienteSelecionado',
    }),
    async postPaciente(paciente) {
      return axios
        .post(`${this.urlBase}Paciente`, paciente, {
          headers: this.headers,
        })
        // .then(response => {
        //   if (response.status === 401) {
        //     this.$router.push('/');
        //     alert('Sua sessão expirou, faça o login novamente!');
        //     return;
        //   }
        //   if (response.status != 200) {
        //     alert('Erro: ', response.status);
        //     return;
        //   }
        // });
    },
    async postPacienteOffline(paciente, urlBase, headers) {
      return axios
        .post(`${urlBase}Paciente`, paciente, {
          headers: headers,
        })
        .then(response => {
          if (response.status != 200) {
            alert('Erro: ', response.status);
            return;
          }
        });
    },
    enviaEmailPaciente(data) {
      axios.post(`${this.urlBase}Email/Send`, data, { headers: this.headers });
    },
    getNomesPacientes() {
      axios
        .get(`${this.urlBase}Paciente`, { headers: this.headers })
        .then(resp => {
          let pacientes = resp.data;
          this.nomesPacientes = pacientes.map(paciente => paciente.nome);
        });
    },
    async getPacienteByNome(nome) {
      await this.getPacientes();
      for (let paciente of this.listaPacientes) {
        if (paciente.nome == nome) {
          return paciente;
        }
      }
    },
    setPacienteByNome(nome) {
      return this.getPacienteByNome(nome).then(paciente => {
        this.setPacienteSelecionado(paciente);
      });
    },
    formatPacientePatch(paciente) {
      let patchBody = Object.keys(paciente).map(key => {
        return {
          path: key,
          op: 'replace',
          value: paciente[key],
        };
      });

      return patchBody;
    },
    patchPaciente(paciente) {
      axios.patch(
        `${this.urlBase}Paciente?id=${this.pacienteById.id}`,
        paciente,
        { headers: this.headers }
      );
    },
    patchPacienteOffline(paciente, pacienteById, urlBase, headers) {
      axios.patch(`${urlBase}Paciente?id=${pacienteById.id}`, paciente, {
        headers: headers,
      });
    },
    async getDeclaracao() {
      // Pegar apenas os números
      const cpf = String(this.pacienteById.cpf).replace(/[^\d]+/g, '');

      try {
        const { data } = await axios.get(
          `${this.urlBase}Pdfcreate/PdfDeclaracao`,
          {
            responseType: 'blob',
            params: {
              nome: this.pacienteById.nome,
              cpf: cpf,
              rg: this.pacienteById.rg,
              consultaId: this.consultaSelecionada.id,
            },
          }
        );

        const temp = URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = temp;

        const agora = moment().format('DD-MM-YYYY_HH-mm');
        link.setAttribute('download', `Declaracao-${agora}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error(JSON.stringify(error, undefined, 2));
      }
    },
  },
};
