<template lang="">
  <div>
    <LaudoTransvaginalUteroComDoppler></LaudoTransvaginalUteroComDoppler>
  </div>
</template>
<script>
import LaudoTransvaginalUteroComDoppler from '../components/laudos/formularios/exames/transvaginalUteroComDoppler/LaudoTransvaginalUteroComDoppler.vue';
export default {
  components: {
    LaudoTransvaginalUteroComDoppler,
  },
};
</script>
<style lang=""></style>
