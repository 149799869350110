<template lang="">
  <div>
    <LaudoObstetricaDopplerPerfilCervical></LaudoObstetricaDopplerPerfilCervical>
  </div>
</template>
<script>
import LaudoObstetricaDopplerPerfilCervical from '../components/laudos/formularios/exames/obstetricaDopplerPerfilCervical/LaudoObstetricaDopplerPerfilCervical.vue';
export default {
  components: {
    LaudoObstetricaDopplerPerfilCervical,
  },
};
</script>
<style lang=""></style>
