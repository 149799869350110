import ConsultaEmbrion from '@/services/ConsultaEmbrion';

const initialState = () => ({});

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  mutations: {},
  actions: {
    async patchConsulta(
      { commit, rootState },
      { consultaID, path, value, op = 'replace' }
    ) {
      const { token } = rootState.Auth.usuarioLogado;

      const { data } = await ConsultaEmbrion.patchConsulta({
        token,
        consultaID,
        data: [
          {
            path,
            op,
            value,
          },
        ],
      });
    },
  },
};
