<template>
  <div>
    <v-card class="my-2 py-4 text-center grey darken-1">
      <h2 class="grey--text text--lighten-4">{{ title }}</h2>
    </v-card>
    <v-card>
      <div class="pt-5">
        <v-row>
          <v-col cols="12" md="12" sm="12" class="">
            <v-row class="align-center px-10">
              <h4>Exame realizado com transdutor</h4>
              <v-col cols="12" md="3" class="py-0">
                <v-select
                  :items="[
                    'convexo',
                    'endocavitário',
                    'convexo e endocavitário',
                  ]"
                  required
                  clearable
                  validate-on-blur
                  v-model="laudoSelecionado.laudoJson.transdutor"
                ></v-select>
              </v-col>
              <h4>de alta resolução</h4>
            </v-row>
          </v-col>

          <v-col cols="12" md="12" sm="12">
            <v-row class="align-center px-10 mt-n8">
              <h4>Data da última menstruação</h4>
              <v-col cols="12" md="2" class="py-0">
                <v-text-field
                  v-model="laudoSelecionado.laudoJson.dataUltimaMestrucao"
                  @change="adicionaOpcoesImpressao"
                  required
                  validate-on-blur
                  v-mask="'##/##/####'"
                />
              </v-col>

              <v-checkbox
                v-model="laudoSelecionado.laudoJson.incerta"
                class="mx-4"
                label="Incerta"
                validate-on-blur
              />
            </v-row>
          </v-col>

          <v-col cols="12" md="12" sm="12">
            <v-row
              class="align-center px-10 mt-n8"
              v-if="!laudoSelecionado.laudoJson.incerta"
            >
              <span>Idade gestacional (DUM)</span>
              <v-col cols="12" md="1" class="py-0">
                <v-text-field
                  :value="calculaIdadeGestacionalMenstrualSemanas()"
                  validate-on-blur
                  required
                  readonly
                />
              </v-col>
              <span>semanas e</span>

              <v-col cols="12" md="1" sm="12" class="py-0">
                <v-text-field
                  :value="calculaIdadeGestacionalMenstrualDias()"
                  validate-on-blur
                  required
                  readonly
                />
              </v-col>
              <span>dias</span>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-row class="align-center px-10 mt-n5">
            <h4>Gestação</h4>
            <v-col cols="12" md="3" sm="12" class="py-0">
              <v-select
                required
                validate-on-blur
                :items="['única', 'gemelar', 'trigemelar', 'quadrigemelar']"
                v-model.number="laudoSelecionado.laudoJson.gestacao"
                clearable
                @change="handleEmbrioes"
              ></v-select>
            </v-col>

            <h4>, {{ feto }} situação variável ao exame.</h4>
          </v-row>

          <v-row class="align-center px-10 mt-n5">
            <h4>Placenta</h4>
            <v-col cols="12" md="3" sm="12" class="py-0">
              <v-select
                :items="['próxima ao colo uterino', 'distante do colo uterino']"
                required
                clearable
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.placenta"
              ></v-select>
            </v-col>

            <h4>, grau 0.</h4>
          </v-row>

          <v-row class="align-center px-10 mt-n5 pb-5">
            <h4>Comprimento cabeça-nádegas:</h4>
            <v-col cols="12" md="1" sm="12" class="py-0">
              <v-text-field
                required
                v-mask="'##'"
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.comprimentoCabecaNadegas"
              ></v-text-field>
            </v-col>
            <h4>mm.</h4>
          </v-row>

          <v-row
            class="align-center px-10 mt-n10"
            v-if="qntdEmbrioes >= 1 || qntdEmbrioes == 0"
          >
            <h4>Frequencia Cardíaca fetal:</h4>
            <v-col cols="12" md="1" sm="12" class="py-0">
              <v-text-field
                required
                v-mask="'###'"
                validate-on-blur
                v-model.number="
                  laudoSelecionado.laudoJson.frequenciaCardiacaFetal
                "
              ></v-text-field>
            </v-col>
            <h4>bpm.</h4>
          </v-row>

          <template v-if="morfologicaPrimeiroTrimestre">
            <MorfologicaPrimeiroTrimestre />
            <v-divider />
          </template>

          <v-divider class="py-3" />
          <v-row class="align-center px-7">
            <v-col cols="12" md="12" sm="12">
              <h3>Marcadores Para Cromossomopatias:</h3>
            </v-col>
          </v-row>

          <v-row class="align-center px-10 mt-n5">
            <h4>Translucência nucal:</h4>
            <v-col cols="12" md="1" sm="12" class="py-0">
              <v-text-field
                required
                v-mask="'##,#'"
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.transulucenciaNucal"
              ></v-text-field>
            </v-col>
            <h4>mm.</h4>
          </v-row>

          <v-row class="align-center px-10 mt-n6 mb-3">
            <h4>Osso nasal:</h4>
            <v-col cols="12" md="3" sm="12" class="py-0">
              <v-select
                required
                clearable
                validate-on-blur
                :items="['presente', 'ausente', 'de difícil avaliação']"
                v-model="laudoSelecionado.laudoJson.ossoNasal"
              ></v-select>
            </v-col>
          </v-row>

          <v-row
            class="align-center px-10 mt-n9 mb-3"
            v-if="morfologicaPrimeiroTrimestre"
          >
            <h4>Fluxo Tricúspide:</h4>
            <v-col cols="12" md="3" sm="12" class="py-0">
              <v-select
                required
                clearable
                validate-on-blur
                :items="[
                  'normal',
                  'presença de regurgitação',
                  'de difícil avaliação',
                ]"
                v-model="laudoSelecionado.laudoJson.fluxoTriscupide"
              ></v-select>
            </v-col>
          </v-row>

          <v-row
            class="align-center px-10 mt-n9 mb-3"
            v-if="morfologicaPrimeiroTrimestre"
          >
            <h4>Ducto venoso:</h4>
            <v-col cols="12" md="3" sm="12" class="py-0">
              <v-select
                required
                clearable
                validate-on-blur
                :items="['onda A normal', 'onda A reversa']"
                v-model="laudoSelecionado.laudoJson.ductoVenoso"
              ></v-select>
            </v-col>
          </v-row>

          <v-divider class="pa-5"></v-divider>

          <v-row class="align-center px-10">
            <h3>Risco Para Trissomias:</h3>
          </v-row>

          <v-row class="align-center px-10 pt-3">
            <h4>Risco basal (baseado na idade materna):</h4>
          </v-row>

          <v-row class="align-center px-10">
            <h4>Trissomia do 21:</h4>
            <v-col cols="12" md="1" sm="12" class="py-0">
              <v-text-field
                v-mask="'#####'"
                required
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.trissomia21Materna"
              ></v-text-field>
            </v-col>
            <h3>mm</h3>
          </v-row>

          <v-row class="align-center px-10">
            <h4>Risco corrigido (baseado na idade materna e TN):</h4>
          </v-row>

          <v-row class="align-center px-10 mb-3">
            <h4>Trissomia do 21:</h4>
            <v-col cols="12" md="1" sm="12" class="py-0">
              <v-text-field
                required
                v-mask="'#####'"
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.trissomia21MaternaTN"
              ></v-text-field>
            </v-col>
            <h4>mm</h4>
          </v-row>

          <template v-if="preEclampsia">
            <PreEclampsia />
            <v-divider />
          </template>

          <template v-if="perfilCervical">
            <v-divider class="pa-3" />
            <PerfilCervical />
            <v-divider class="pa-3" />
          </template>

          <v-row class="align-center px-10 mt-5">
            <h3>Conclusão</h3>
          </v-row>

          <v-row class="align-center px-10 mb-0 py-0">
            <h4>Gestação</h4>
            <v-col cols="12" md="3" sm="12" class="py-0">
              <v-select
                :items="[
                  'única',
                  'gemelar',
                  'trigemelar',
                  'quadrigemelar',
                  'quíntupla',
                ]"
                required
                clearable
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.gestacao2"
              >
              </v-select>
            </v-col>

            <v-col
              cols="12"
              md="2"
              sm="12"
              class="py-0"
              v-if="laudoSelecionado.laudoJson.gestacao2 == 'gemelar'"
            >
              <v-select
                :items="['dicoriônica', 'monocoriônica']"
                required
                clearable
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.gestacao3"
              >
              </v-select>
            </v-col>

            <v-col
              cols="12"
              md="2"
              sm="12"
              class="py-0"
              v-if="laudoSelecionado.laudoJson.gestacao2 == 'gemelar'"
            >
              <v-select
                :items="['diamniótica', 'monoamniótica']"
                required
                clearable
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.gestacao4"
              >
              </v-select>
            </v-col>

            <v-col
              cols="12"
              md="2"
              sm="12"
              class="py-0"
              v-if="laudoSelecionado.laudoJson.gestacao2 == 'trigemelar'"
            >
              <v-textarea
                required
                validate-on-blur
                filled
                outlined
                no-resize
                label="Comentários adicionais"
                v-model="
                  laudoSelecionado.laudoJson.comentariosAdcionaisTrigemelar
                "
              ></v-textarea>
            </v-col>

            <v-col
              cols="12"
              md="2"
              sm="12"
              class="py-0"
              v-if="laudoSelecionado.laudoJson.gestacao2 == 'quadrigemelar'"
            >
              <v-textarea
                required
                validate-on-blur
                filled
                outlined
                no-resize
                label="Comentários adicionais"
                v-model="
                  laudoSelecionado.laudoJson.comentariosAdcionaisQuadrigemelar
                "
              ></v-textarea>
            </v-col>

            <v-col
              cols="12"
              md="2"
              sm="12"
              class="py-0"
              v-if="laudoSelecionado.laudoJson.gestacao2 == 'quíntupla'"
            >
              <v-textarea
                required
                validate-on-blur
                filled
                outlined
                no-resize
                label="Comentários adicionais"
                v-model="
                  laudoSelecionado.laudoJson.comentariosAdcionaisQuintupla
                "
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row class="align-center px-10 mt-n8">
            <h4>Idade gestacional baseada na biometria fetal atual de</h4>
            <v-col cols="12" md="1" sm="12">
              <v-text-field
                required
                v-mask="'##'"
                validate-on-blur
                v-model="
                  laudoSelecionado.laudoJson
                    .idadeGestacionalBiometriaFetalSemanas
                "
              >
              </v-text-field>
            </v-col>
            <h4>semanas e</h4>
            <v-col cols="12" md="1" sm="12">
              <v-text-field
                required
                v-mask="'#'"
                validate-on-blur
                v-model="
                  laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalDias
                "
              >
              </v-text-field>
            </v-col>
            <h4>dias,</h4>
            <v-col cols="12" md="3" sm="12">
              <v-select
                :items="['compatível', 'incompatível']"
                required
                clearable
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.compativelIncompativel"
              >
              </v-select>
            </v-col>
            <h4 class="pb-8 mt-n6">com a idade gestacional baseada na DUM.</h4>
          </v-row>

          <v-row class="align-center px-10 mt-n10">
            <h4>Data provável do parto:</h4>
            <v-col cols="12" md="2" sm="12">
              <v-text-field
                required
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.dataProvavelParto"
                v-mask="'##/##/####'"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="align-center px-10 mt-n10">
            <h4>O risco de trissomia do 13, 18 e 21</h4>
            <v-col cols="12" md="2" sm="12">
              <v-select
                :items="['aumentou', 'diminuiu']"
                clearable
                v-model="laudoSelecionado.laudoJson.riscoTrissomia"
              ></v-select>
            </v-col>
            <h4>após a medida da TN no feto avaliado.</h4>
          </v-row>

          <v-row class="align-center px-10 mt-n10 pb-4">
            <h4>Sendo considerado de</h4>
            <v-col cols="12" md="3" sm="12">
              <v-select
                :items="['baixo risco', 'risco intermediário', 'alto risco']"
                required
                clearable
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.taxaRisco"
              ></v-select>
            </v-col>
            <h4>para cromossomopatias.</h4>
          </v-row>

          <v-row
            class="align-center px-8 mt-n10 pb-4"
            v-if="morfologicaPrimeiroTrimestre"
          >
            <v-col cols="12" md="5" sm="12">
              <v-select
                :items="[
                  'Morfologia fetal dentro do esperado para a idade gestacional',
                  'Alterações precoces da mofologia fetal, caracterizada por',
                ]"
                clearable
                required
                validate-on-blur
                v-model="
                  laudoSelecionado.laudoJson.morfologiafetalIdadeGestacional
                "
              ></v-select>
            </v-col>

            <v-col
              v-if="
                laudoSelecionado.laudoJson.morfologiafetalIdadeGestacional ==
                'Alterações precoces da mofologia fetal, caracterizada por'
              "
              cols="12"
              md="3"
              sm="12"
            >
              <v-textarea
                required
                validate-on-blur
                filled
                outlined
                no-resize
                label="Comentários adicionais"
                v-model="
                  laudoSelecionado.laudoJson
                    .morfologiafetalIdadeGestacionalOutros
                "
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row class="align-center px-8 mt-n10 pb-6">
            <v-col cols="12" md="9" sm="12">
              <v-select
                :items="[
                  'A critério médico, sugerimos pesquisa do cariótipo fetal, ultrassonografia morfológica entre 20 e 24 semanas e ecocardiograma fetal.',
                  '--------------------------------------',
                ]"
                clearable
                v-model="laudoSelecionado.laudoJson.conclusao3"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="align-center px-10 mt-n10 pb-3" v-if="perfilCervical">
            <h4>Colo uterino</h4>
            <v-col cols="12" md="9" sm="12">
              <v-select
                :items="coloUterinoConclusao"
                clearable
                required
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.coloUterinoConclusao"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="align-center px-10 mt-n10" v-if="preEclampsia">
            <h4>Risco:</h4>
            <v-col cols="12" md="11" sm="12">
              <v-select
                :items="classificacaoRisco"
                clearable
                required
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.coloUterinoConclusao2"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="align-center px-10 mt- pb-9" v-if="preEclampsia">
            <h4>
              De acordo com o Estudo ASPRE, mulheres identificadas pela triagem
              do primeiro trimestre como de alto risco para pré-eclâmpsia, devem
              utilizar aspirina 150mg à noite entre 11 - 14 e 36 semanas de
              gestação.
            </h4>
          </v-row>

          <v-row class="align-center px-7 mt-n2 mb-3">
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-textarea
                required
                validate-on-blur
                filled
                outlined
                no-resize
                label="Comentários adicionais"
                v-model="laudoSelecionado.laudoJson.comentariosAdcionaisFinal1"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row class="align-center px-10 mt-1">
            <h3>NOTAS:</h3>
          </v-row>

          <v-row class="align-center px-10 mt-5">
            <h5>
              A Fetal Medicine Foundation calcula o risco de trissomia de acordo
              com o risco corrigido pela TN em:
            </h5>
          </v-row>

          <v-row class="align-center px-10 mt-5">
            <h5>■ Baixo o risco, o risco corrigido < 1:1000</h5>
          </v-row>

          <v-row class="align-center px-10 mt-5">
            <h5>
              ■ Risco intermediário, o risco corrigido entre ≤ 1:51 - 1:1000
            </h5>
          </v-row>

          <v-row class="align-center px-10 mt-5">
            <h5>■ Risco alto, o risco corrigido entre ≤ 1:50</h5>
          </v-row>

          <v-row class="align-center px-10 mt-5">
            <h5>
              *Risco corrigido baixo - avaliação morfológica entre 20 e 24
              semanas.
            </h5>
          </v-row>

          <v-row class="align-center px-10 mt-5">
            <h5>
              *Risco corrigido intermediário - Incluir a avaliação do osso
              nasal, do ducto venoso, da valva tricúspide e dos marcadores
              bioquímicos.
            </h5>
          </v-row>

          <v-row class="align-center px-10 mt-5">
            <h5>
              *Risco corrigido alto - indicação de investigação adicional
              (biópsia de vilo corial ou amniocentese).
            </h5>
          </v-row>

          <v-row class="align-center px-7 mb-3 mt-10">
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-textarea
                required
                validate-on-blur
                filled
                outlined
                no-resize
                label="Acrescentar notas"
                v-model="laudoSelecionado.laudoJson.notasFinal1"
              ></v-textarea>
            </v-col>
          </v-row>

          <div class="d-flex justify-content-end px-5">
            <v-btn
              v-if="!temIdDoLaudo"
              color="primary"
              :loading="carregamentoDoLaudo"
              @click="salvarLaudo"
            >
              salvar laudo
            </v-btn>

            <v-btn
              color="primary"
              v-if="temIdDoLaudo"
              @click="imprimirLaudo"
              class="ml-5"
            >
              Imprimir
            </v-btn>
          </div>

          <v-dialog transition="dialog-bottom-transition" max-width="600">
            <template #default="dialog">
              <v-card>
                <v-card-text>
                  <div class="text-h5 pa-8 text-center">
                    Laudo
                    {{ salvoOuEditado }}
                    com sucesso!
                  </div>
                </v-card-text>
                <v-card-actions class="justify-center pb-4">
                  <v-btn
                    text
                    depressed
                    class="grey lighten-2 grey--text texte-darken-6"
                    @click="dialog.value = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>

          <v-dialog transition="dialog-bottom-transition" max-width="600">
            <template #default="dialog">
              <v-card>
                <v-card-text>
                  <div class="text-h5 pa-8 text-center">
                    Laudo
                    {{ laudoPrint }}
                    com sucesso!
                  </div>
                </v-card-text>

                <v-card-actions class="justify-center pb-4">
                  <v-btn
                    text
                    depressed
                    class="grey lighten-2 grey--text texte-darken-6"
                    @click="dialog.value = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      carregamentoDoLaudo: false,
      voltando: false,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,

      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 2,5 cm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    title() {
      let titulos = ['TRANSLUCÊNCIA NUCAL'];

      if (typeof this.$route.query.variacoes === 'object') {
        titulos = [...titulos, ...this.$route.query.variacoes];
      } else if (!!this.$route.query.variacoes) {
        titulos.push(this.$route.query.variacoes);
      }

      titulos = titulos.map(titulo => titulo.toUpperCase());

      // ['TN', 'VAR1', 'VAR2'].join(' - ') -> TN - VAR1 - VAR2
      return titulos.join(' - ');
    },
    morfologicaPrimeiroTrimestre() {
      return this.$route.query.variacoes.includes(
        'avaliação morfológica do primeiro trimestre'
      );
    },
    perfilCervical() {
      return this.$route.query.variacoes.includes('perfil cervical');
    },
    preEclampsia() {
      return this.$route.query.variacoes.includes('doppler');
    },
    temIdDoLaudo() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    calculaSacoGestacional() {
      let semanas = this.laudoSelecionado.laudoJson.dataGestacionalSemanas;
      let diametro = this.calculoDiametroSacoGestacional();
      if ((diametro >= 2) & (diametro <= 8)) {
        if (semanas == 4) {
          this.corSaco = 'green';
        } else {
          this.corSaco = 'red';
        }
      } else if ((diametro >= 6) & (diametro <= 16)) {
        if (semanas == 5) {
          this.corSaco = 'green';
        } else {
          this.corSaco = 'red';
        }
      } else if ((diametro >= 9) & (diametro <= 23)) {
        if (semanas == 6) {
          this.corSaco = 'green';
        } else {
          this.corSaco = 'red';
        }
      } else if ((diametro >= 15) & (diametro <= 31)) {
        if (semanas == 7) {
          this.corSaco = 'green';
        } else {
          this.corSaco = 'red';
        }
      } else if ((diametro >= 22) & (diametro <= 38)) {
        if (semanas == 8) {
          this.corSaco = 'green';
        } else {
          this.corSaco = 'red';
        }
      } else if ((diametro >= 28) & (diametro <= 37)) {
        if (semanas == 9) {
          this.corSaco = 'green';
        } else {
          this.corSaco = 'red';
        }
      } else if ((diametro >= 35) & (diametro <= 51)) {
        if (semanas == 10) {
          this.corSaco = 'green';
        } else {
          this.corSaco = 'red';
        }
      }
    },
    async salvarLaudo() {
      try {
        this.carregamentoDoLaudo = true;

        const exameId = this.$route.query.exame;

        this.laudoSelecionado.examePorConsultaId = exameId;
        this.salvoOuEditado = 'salvo';
        this.consultaSelecionada.status = 'LAUDADO';

        await this.alterarStatus();
        const { data } = await this.postLaudo();

        if (!Number.isInteger(data)) throw new Error(data);

        this.getLaudoByExamePorConsultaId(exameId);
      } catch (error) {
        alert('Error ao salvar o laudo');
      } finally {
        this.carregamentoDoLaudo = false;
      }
    },
    imprimirLaudo() {
      this.laudoPrint = 'impresso';

      this.$router.push({
        name: 'imprecoes.translucencianucal',
        query: { exame: this.$route.query.exame },
      });
    },
    adicionaOpcoesImpressao() {
      let semanas = this.calculaIdadeGestacionalMenstrualSemanas();
      let dias = this.calculaIdadeGestacionalMenstrualDias();

      if (this.impressaoOpcoes.length <= 4) {
        this.impressaoOpcoes.pop();
      }

      this.impressaoOpcoes[0] = `Gestação única de ${semanas} semanas e ${dias} dias baseada na biometria atual.`;
      this.impressaoOpcoes.push(
        'Gestação incipiente. Sugerimos nova avaliação em uma semana.'
      );
      this.impressaoOpcoes.push(
        'Gestação incipiente. Sugerimos nova avaliação em duas semanas.'
      );
    },
    handleEmbrioes(event) {
      this.qntdEmbrioes = event;
      if (this.qntdEmbrioes == 0) {
        this.vesiculasV = 'Vesícula vitelínica';
        this.laudoSelecionado.laudoJson.presencaDe = '';
      } else if (this.qntdEmbrioes == 1) {
        this.vesiculasV = 'Vesícula vitelínica';

        this.laudoSelecionado.laudoJson.presencaDe = this.embrioesPresenca[0];
      } else if (this.qntdEmbrioes == 2) {
        this.laudoSelecionado.laudoJson.presencaDe = this.embrioesPresenca[1];
        this.vesiculasV = 'Vesículas vitelínicas';
        this.embriaoBpm = '(embrião 1)';
      } else if (this.qntdEmbrioes == 3) {
        this.laudoSelecionado.laudoJson.presencaDe = this.embrioesPresenca[2];
        this.vesiculasV = 'Vesículas vitelínicas';
        this.embriaoBpm = '(embrião 1)';
      } else if (this.qntdEmbrioes == 4) {
        this.laudoSelecionado.laudoJson.presencaDe = this.embrioesPresenca[3];
        this.vesiculasV = 'Vesículas vitelínicas';
        this.embriaoBpm = '(embrião 1)';
      } else if (this.qntdEmbrioes == 5) {
        this.laudoSelecionado.laudoJson.presencaDe = this.embrioesPresenca[4];
        this.embriaoBpm = '(embrião 1)';
        this.vesiculasV = 'Vesículas vitelínicas';
      }
    },
    calculoDiametroSacoGestacional() {
      let m1 = this.laudoSelecionado.laudoJson.saco1m1;
      let m2 = this.laudoSelecionado.laudoJson.saco1m2;
      let m3 = this.laudoSelecionado.laudoJson.saco1m3;
      if (m1 == undefined || m2 == undefined || m3 == undefined) {
        return;
      }
      return ((m1 + m2 + m3) / 3).toFixed(2);
    },
    calculaIdadeGestacionalMenstrualSemanas() {
      if (
        this.laudoSelecionado.laudoJson.dataUltimaMestrucao == undefined ||
        this.laudoSelecionado.laudoJson.dataUltimaMestrucao.length < 10
      )
        return 0;
      const idade =
        this.laudoSelecionado.laudoJson.dataUltimaMestrucao.split('/');
      const hoje = new Date();
      const ultimaMenstruacao = new Date(idade[2], idade[1] - 1, idade[0]);
      const oneDay = 24 * 60 * 60 * 1000;
      this.laudoSelecionado.laudoJson.dataGestacionalSemanas = Math.floor(
        Math.floor((hoje - ultimaMenstruacao) / oneDay) / 7
      );
      this.semanas = this.laudoSelecionado.laudoJson.dataGestacionalSemanas;
      this.calculaSacoGestacional();

      return this.laudoSelecionado.laudoJson.dataGestacionalSemanas;
    },
    calculaIdadeGestacionalMenstrualDias() {
      if (
        this.laudoSelecionado.laudoJson.dataUltimaMestrucao == undefined ||
        this.laudoSelecionado.laudoJson.dataUltimaMestrucao.length < 10
      )
        return 0;
      const idade =
        this.laudoSelecionado.laudoJson.dataUltimaMestrucao.split('/');
      const hoje = new Date();
      const ultimaMenstruacao = new Date(idade[2], idade[1] - 1, idade[0]);
      const oneDay = 24 * 60 * 60 * 1000;
      this.laudoSelecionado.laudoJson.dataGestacionalDias = Math.floor(
        Math.floor((hoje - ultimaMenstruacao) / oneDay) % 7
      );
      return this.laudoSelecionado.laudoJson.dataGestacionalDias;
    },
    idadeGestacional() {
      if (this.laudoSelecionado.laudoJson.dataUltimaMestrucao != undefined) {
        let dataMenstruacao =
          this.laudoSelecionado.laudoJson.dataUltimaMestrucao.split('/');
        return calculoIdadeGestacional(
          dataMenstruacao[0],
          dataMenstruacao[1],
          dataMenstruacao[2]
        );
      }
    },
    calculoIdadeGestacional(data1, data2, data3) {
      let hoje = new Date();
    },
  },
  mounted() {
    this.getLaudoByExamePorConsultaId(this.$route.query.exame);
    this.adicionaOpcoesImpressao();
    this.impressaoOpcoes = [];
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      this.resetaConsultaSelecionada();
    }
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
