<template>
    <v-container class="d-flex align-center justify-center login-wrapper" fluid>
      <v-card width="350" class="cyan darken-2 px-5 ">
        <v-card-title class="d-flex justify-center ">
          <h3 class="white--text text--darken-3 maiuscula">Redefinir senha</h3>
          <v-container>
            <v-row justify="center">
            <v-col cols="5">
              
            </v-col>
          </v-row>
          </v-container>
  
         
          
        </v-card-title>
        
        <form >
          <v-text-field
            label="Digite seu email de recuperação"
            filled
            solo
            clearable
            v-model="nomeUsuario"
            @change="limpaTela"
          />
          <div class="d-flex justify-center mb-6 mt-4">
            <v-btn class="white" :loading="isLoading" @click="enviarEmail" block large>
              Enviar email
            </v-btn>
          </div>
          <v-alert type="success" v-model="sucesso">
            Email de recuperação enviado com sucesso
          </v-alert>
          <v-alert type="error" v-model="erro">
              {{ msgErro }} 
          </v-alert>
          
        </form>
        
      </v-card>
    </v-container>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import LoginMixin from '@mixins/LoginMixin';
  
  
  export default {
    mixins: [LoginMixin],
    data: () => ({
      show: false,
      password: 'password',
      nomeUsuario: '',
      isLoading: false,
      erro: false,
        msgErro: '',
      sucesso: false,
      
    }),
    watch: {
      
    },
    computed: {
      
    },
    methods: {
     async enviarEmail(){
      this.erro = false;
      this.sucesso = false
        if(!this.nomeUsuario){
          this.erro = true
          this.msgErro = "Digite o nome de usuário";
          return
        }else{
          await this.redefinirSenha(this.nomeUsuario).then(
            ()=>{
              this.sucesso = true
            }
            ).catch((e)=>{
              
              this.erro = true
          this.msgErro = "Não foi possivel encontrar o usuário, tente novamente com um email válido.";

            })
        }
      },
      
      limpaTela(){
      this.erro = false;
      this.sucesso = false
     
      this.msgErro = "";

    },
      
    },
    mounted() {
     
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .login-wrapper {
    height: 100vh;
    width: 100%;
  }
  .teste ::v-deep -webkit-autofill {
      background-color: red !important;
      -webkit-box-shadow: 0 0 0 50px white inset;
  }
  </style>
  