<template lang="">
  <div>
    <LaudoPersonalizado></LaudoPersonalizado>
  </div>
</template>
<script>
import LaudoPersonalizado from '../components/laudos/formularios/exames/personalizado/LaudoPersonalizado.vue';
export default {
  components: {
    LaudoPersonalizado,
  },
};
</script>
<style lang=""></style>
