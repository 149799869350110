<template>
  <v-container fluid>
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">
        ULTRASSONOGRAFIA PÉLVICA TRANSVAGINAL
      </h2>
    </v-card>
    <v-alert
      v-if="!!laudoSelecionado.laudoJson.folhas"
      outlined
      type="warning"
      border="left"
      text=""
    >
      Esse <strong>laudo</strong> teve a impressão
      <strong>personalizada.</strong>
      <v-btn text @click="adcionarConteudoEditorPersonalizado"
        >Clique aqui para visualizar</v-btn
      >
    </v-alert>
    <v-row class="pt-5" no-gutters>
      <template v-if="clicouNoPersonalizado">
        <v-col
          cols="12"
          class="form-group"
          v-for="(index, folha) in quantidadeFolhasEditorPersonalizado"
          :key="index"
        >
          <h2>Folha {{ folha + 1 }}</h2>
          <v-btn plain small @click="removeFolha(index)" class="pl-0">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <vue-editor v-model="conteudoEditorPersonalizado[folha]" />
        </v-col>

        <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
      </template>
      <template v-else>
        <v-col
          cols="12"
          class="d-flex justify-start align-center"
          :class="!camposExibidos.exOpcoesUtero ? 'text--disabled' : ''"
        >
          <v-select
            :clearable="podeEditar"
            required
            :items="[
              'Paciente no menacme',
              'Paciente em uso de contraceptivo hormonal',
              'Paciente menopausada sem terapia hormonal',
              'Paciente menopausada em terapia hormonal',
            ]"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.opcoesUtero"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exOpcoesUtero"
            dense
            class="mx-2 flex-grow-0"
          >
          </v-select>

          <span
            v-if="
              laudoSelecionado.laudoJson.opcoesUtero === 'Paciente no menacme'
            "
            >Data da última menstruação</span
          >
          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.opcoesUtero === 'Paciente no menacme'
            "
            type="date"
            class="mx-2 flex-grow-0"
            validate-on-blur
            :disabled="!camposExibidos.exOpcoesUtero"
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            dense
            v-model="laudoSelecionado.laudoJson.dataUltimaMestrucao"
          />
          <span>{{
            laudoSelecionado.laudoJson.dataUltimaMestrucao
              ? diaCicloMenstrual(
                  laudoSelecionado.laudoJson.dataUltimaMestrucao
                ) + 'º dia do ciclo menstrual'
              : ''
          }}</span>
          <span>.</span>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exPosUtero ? '' : 'text--disabled'
          }`"
        >
          <span>O útero está</span>
          <v-select
            :clearable="podeEditar"
            required
            :items="['antevertido', 'retrovertido', 'mediovertido']"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.uteroPosicao"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exPosUtero"
            dense
            class="mx-2 flex-grow-0"
          >
          </v-select>
          <span>,</span>
          <v-select
            :clearable="podeEditar"
            required
            :items="[
              'em situação mediana',
              'desviado para a direita',
              'desviado para a esquerda',
            ]"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.uteroSituacao"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exPosUtero"
            dense
            class="mx-2 flex-grow-0"
          >
          </v-select>
          <span>, tem os contornos</span>
          <v-select
            :items="['regulares', 'irregulares']"
            :disabled="!camposExibidos.exPosUtero"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.uteroContornos"
            dense
            class="mx-2 flex-grow-0"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exPosUtero"
            @click="
              camposExibidos.exPosUtero = true;
              laudoSelecionado.laudoJson.uteroSituacao = undefined;
              laudoSelecionado.laudoJson.uteroPosicao = undefined;
              laudoSelecionado.laudoJson.uteroContornos = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exPosUtero = false;
              laudoSelecionado.laudoJson.uteroSituacao = undefined;
              laudoSelecionado.laudoJson.uteroPosicao = undefined;
              laudoSelecionado.laudoJson.uteroContornos = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exMiomeEcoText ? '' : 'text--disabled'
          }`"
        >
          <span>O miométrio tem ecotextura</span>
          <v-select
            :items="[
              'homogênea.',
              'difusamente heterogênea.',
              'heterogênea focal',
            ]"
            required
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exMiomeEcoText"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.miometrioEcotextura"
          >
          </v-select>
          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.miometrioEcotextura ===
                'heterogênea focal' ||
              laudoSelecionado.laudoJson.miometrioEcotextura ===
                'heterogênea focal.'
            "
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exMiomeEcoText"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.miometrioEcotextura2"
          />
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exMiomeEcoText"
            @click="
              camposExibidos.exMiomeEcoText = true;
              laudoSelecionado.laudoJson.miometrioEcotextura = undefined;
              laudoSelecionado.laudoJson.miometrioEcotextura2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exMiomeEcoText = false;
              laudoSelecionado.laudoJson.miometrioEcotextura = undefined;
              laudoSelecionado.laudoJson.miometrioEcotextura2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisUter01"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais:"
          >
          </v-textarea>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exPresenca ? '' : 'text--disabled'
          }`"
        >
          <span>Presença de</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exPresenca"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            @change="criaListaFor(laudoSelecionado.laudoJson.presenca)"
            v-model="laudoSelecionado.laudoJson.presenca"
          >
          </v-text-field>
          <!-- <v-select
          :items="['miometrial', 'miometriais']"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          class="mx-2 flex-grow-0"
          dense
          :disabled="!camposExibidos.exDistImg"
          v-model="laudoSelecionado.laudoJson.presencaOpc"
        >
        </v-select> -->

          <span
            >{{
              laudoSelecionado.laudoJson.presenca > 1
                ? 'nódulos miometriais '
                : 'nódulo miometrial '
            }}
            com as seguintes características:</span
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exPresenca"
            @click="
              camposExibidos.exPresenca = true;
              laudoSelecionado.laudoJson.presenca = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exPresenca = false;
              laudoSelecionado.laudoJson.presenca = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center `"
          :key="index"
          v-for="(n, index) in laudoSelecionado.laudoJson.listaPresencas"
        >
          <span
            :class="`${
              camposExibidos.exDistImg[index] ? '' : 'text--disabled'
            }`"
            >&#149; Nódulo {{ index + 1 }}:</span
          >

          <v-select
            :items="['subseroso, ', 'submucoso, ', 'intramural, ']"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            class="mx-2 flex-grow-0"
            dense
            :disabled="!camposExibidos.exDistImg[index]"
            v-model="n.presenca.distri"
          >
          </v-select>

          <v-select
            :items="[
              'localizado em parede anterior',
              'localizado em parede posterior',
              'localizado em região fúndica',
              'localizado em parede lateral direita',
              'localizado em parede lateral esquerda',
              'localizado no colo uterino',
            ]"
            :disabled="!camposExibidos.exDistImg[index]"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="n.presenca.loc"
          >
          </v-select>
          <span
            :class="`${
              camposExibidos.exDistImg[index] ? '' : 'text--disabled'
            }`"
            >medindo</span
          >
          <v-text-field
            :disabled="!camposExibidos.exDistImg[index]"
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="n.presenca.medida"
          >
          </v-text-field>
          <span
            :class="` ${
              camposExibidos.exDistImg[index] ? '' : 'text--disabled'
            }`"
            >cm.</span
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exDistImg[index]"
            @click="
              camposExibidos.exDistImg[index] = true;
              n.presenca.medida = undefined;
              n.presenca.distri = undefined;
              n.presenca.loc = undefined;

              $forceUpdate();
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exDistImg[index] = false;
              n.presenca.medida = undefined;
              n.presenca.distri = undefined;
              n.presenca.loc = undefined;
              $forceUpdate();
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span
            :class="` ${
              camposExibidos.exDistImgFigo[index] ? '' : 'text--disabled'
            }`"
            >FIGO
          </span>
          <v-text-field
            :disabled="!camposExibidos.exDistImgFigo[index]"
            v-mask="''"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="n.presenca.figo"
          />
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exDistImgFigo[index]"
            @click="
              camposExibidos.exDistImgFigo[index] = true;
              $forceUpdate();
              n.presenca.figo = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exDistImgFigo[index] = false;
              $forceUpdate();
              n.presenca.figo = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exDistImg[index] ? '' : 'text--disabled'
          }`"
        >
        </v-col>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.malFormacaoUte"
          cols="12"
          :class="`d-flex justify-start align-center`"
          label="Malformações uterinas"
          validate-on-blur
        ></v-checkbox>

        <v-col
          v-if="laudoSelecionado.laudoJson.malFormacaoUte"
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exInfoUt1 ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Divisão do eco endometrial na sua porção superior',
              'Divisão do eco endometrial na sua totalidade',
            ]"
            :disabled="!camposExibidos.exInfoUt1"
            required
            class="mx-2 flex-grow-0"
            dense
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.informacaoUtero1"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exInfoUt1"
            @click="camposExibidos.exInfoUt1 = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="camposExibidos.exInfoUt1 = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col
          v-if="laudoSelecionado.laudoJson.malFormacaoUte"
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exInfoUtFund ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Fundo uterino regular',
              'Fundo uterino irregular',
              'Presença de dois úteros divididos em sua totalidade',
              'Presença de corno único',
              'Útero em forma de T',
            ]"
            required
            :disabled="!camposExibidos.exInfoUtFund"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.informacaoUteroFund"
          >
          </v-select
          >.
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exInfoUtFund"
            @click="
              camposExibidos.exInfoUtFund = true;
              laudoSelecionado.laudoJson.informacaoUteroFund = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exInfoUtFund = false;
              laudoSelecionado.laudoJson.informacaoUteroFund = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          v-if="laudoSelecionado.laudoJson.malFormacaoUte"
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exInfoUt2 ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Associado a fundo uterino regular (exame complementado com ultrassonografia tridimensional)',
              'Presença de divisão do eco endometrial em sua porção superior',
            ]"
            required
            :disabled="!camposExibidos.exInfoUt2"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.informacaoUtero2"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exInfoUt2"
            @click="camposExibidos.exInfoUt2 = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="camposExibidos.exInfoUt2 = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          v-if="laudoSelecionado.laudoJson.malFormacaoUte"
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exInfoUt3 ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Associado divisão do fundo uterino (exame complementado com ultrassonografia tridimensional)',
              'Presença de um único corno uterino',
            ]"
            required
            :disabled="!camposExibidos.exInfoUt3"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.informacaoUtero3"
          >
          </v-select>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exInfoUt3"
            @click="camposExibidos.exInfoUt3 = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="camposExibidos.exInfoUt3 = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          v-if="laudoSelecionado.laudoJson.malFormacaoUte"
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exInfoUt4 ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Presença de divisão do eco endometrial em sua totalidade',
              '-----------------------------------',
            ]"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            required
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exInfoUt4"
            v-model="laudoSelecionado.laudoJson.informacaoUtero4"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exInfoUt4"
            @click="camposExibidos.exInfoUt4 = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="camposExibidos.exInfoUt4 = false"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          class="d-flex justify-start align-center"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exlong ? '' : 'text--disabled'
          }`"
        >
          <span>Os diâmetros uterinos são:</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exlong"
            @click="
              camposExibidos.exlong = true;
              laudoSelecionado.laudoJson.longitudinal = undefined;
              laudoSelecionado.laudoJson.transversal = undefined;
              laudoSelecionado.laudoJson.anteroPosterior = undefined;
              laudoSelecionado.laudoJson.volumeUterino = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exlong = false;
              laudoSelecionado.laudoJson.longitudinal = undefined;
              laudoSelecionado.laudoJson.transversal = undefined;
              laudoSelecionado.laudoJson.anteroPosterior = undefined;
              laudoSelecionado.laudoJson.volumeUterino = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exlong ? '' : 'text--disabled'
          }`"
        >
          <span>Longitudinal:</span>
          <v-text-field
            v-mask="''"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exlong"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.longitudinal"
          >
          </v-text-field>
          <span>cm.</span>
          <!-- <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exlong"
          @click="camposExibidos.exlong = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exlong = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        > -->
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exlong ? '' : 'text--disabled'
          }`"
        >
          <span>Antero-posterior:</span>
          <v-text-field
            v-mask="''"
            required
            :disabled="!camposExibidos.exlong"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.anteroPosterior"
          >
          </v-text-field>
          <span>cm.</span>
          <!-- <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exAntPost"
          @click="camposExibidos.exAntPost = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exAntPost = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        > -->
        </v-col>
        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exlong ? '' : 'text--disabled'
          }`"
        >
          <span>Transverso:</span>
          <v-text-field
            v-mask="''"
            required
            :disabled="!camposExibidos.exlong"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.transversal"
          >
          </v-text-field>
          <span>cm.</span>
          <!-- <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.extrans"
          @click="camposExibidos.extrans = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.extrans = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        > -->
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exlong ? '' : 'text--disabled'
          }`"
        >
          <span>Volume uterino:</span>
          <v-text-field
            v-mask="''"
            required
            :disabled="!camposExibidos.exlong"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.volumeUterino"
          >
          </v-text-field>
          <span>cm³.</span>
          <!-- <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exVol"
          @click="camposExibidos.exVol = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exVol = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        > -->
        </v-col>
        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exMedEndoUm ? '' : 'text--disabled'
          }`"
        >
          <span>O endométrio mede</span>
          <v-text-field
            v-mask="''"
            required
            :disabled="!camposExibidos.exMedEndoUm"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.endometrioMede"
          >
          </v-text-field>
          <span>mm de espessura</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exMedEndoUm"
            @click="
              camposExibidos.exMedEndoUm = true;
              laudoSelecionado.laudoJson.endometrioMede = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exMedEndoUm = false;
              laudoSelecionado.laudoJson.endometrioMede = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span>,</span>
          <v-select
            :items="['compatível', 'incompatível']"
            :disabled="!camposExibidos.exMedEndoUm2"
            dense
            class="mx-2 flex-grow-0"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.uteroSituacao2"
          >
          </v-select>
          <v-select
            :items="[
              'com a fase do ciclo menstrual.',
              'com o uso de contraceptivo hormonal.',
              'com a idade da paciente.',
            ]"
            :disabled="!camposExibidos.exMedEndoUm2"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.uteroContornos2"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exMedEndoUm2"
            @click="
              camposExibidos.exMedEndoUm2 = true;
              laudoSelecionado.laudoJson.uteroContornos2 = undefined;
              laudoSelecionado.laudoJson.uteroSituacao2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exMedEndoUm2 = false;
              laudoSelecionado.laudoJson.uteroContornos2 = undefined;
              laudoSelecionado.laudoJson.uteroSituacao2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12" :class="`d-flex justify-start align-center`">
          <span>Ecotextura:</span>
          <v-select
            :items="['homogênea', 'heterogênea']"
            :disabled="!camposExibidos.exEco"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.ecotextura"
          >
          </v-select>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exEco"
            @click="
              camposExibidos.exEco = true;
              laudoSelecionado.laudoJson.ecotextura = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exEco = false;
              laudoSelecionado.laudoJson.ecotextura = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >

          <span v-if="laudoSelecionado.laudoJson.ecotextura === 'heterogênea'"
            >, apresentando imagem hiperecóica medindo
          </span>
          <!-- <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exEco"
          @click="camposExibidos.exEco = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exEco = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        > -->
          <v-text-field
            v-if="laudoSelecionado.laudoJson.ecotextura === 'heterogênea'"
            v-mask="''"
            required
            :disabled="!camposExibidos.exEco01"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.imagemHiperecoicaMedindo"
          >
          </v-text-field>
          <span v-if="laudoSelecionado.laudoJson.ecotextura === 'heterogênea'"
            >mm,
          </span>
          <!-- <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exImgHipereMede"
          @click="camposExibidos.exImgHipereMede = true;
          laudoSelecionado.laudoJson.imagemHiperecoicaMedindo = undefined"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exImgHipereMede = false;
          laudoSelecionado.laudoJson.imagemHiperecoicaMedindo = undefined"
          ><v-icon>mdi-eye</v-icon></v-btn
        > -->
          <v-select
            v-if="laudoSelecionado.laudoJson.ecotextura === 'heterogênea'"
            :items="[
              'com pedículo vascular',
              'sem detecção de pedículo vascular',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exEco01"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.pediculoVascular"
          >
          </v-select>
          .
          <div v-if="laudoSelecionado.laudoJson.ecotextura === 'heterogênea'">
            <v-btn
              dense
              depressed
              flat
              icon
              v-if="!camposExibidos.exEco01"
              @click="
                camposExibidos.exEco01 = true;
                laudoSelecionado.laudoJson.imagemHiperecoicaMedindo = undefined;
                laudoSelecionado.laudoJson.pediculoVascular = undefined;
              "
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              flat
              icon
              v-else
              @click="
                camposExibidos.exEco01 = false;
                laudoSelecionado.laudoJson.imagemHiperecoicaMedindo = undefined;
                laudoSelecionado.laudoJson.pediculoVascular = undefined;
              "
              ><v-icon>mdi-eye</v-icon></v-btn
            >
          </div>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <span>Cavidade uterina:</span>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exCavUt ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'ausência de conteúdo intracavitário.',
              'liquido intracavitário em pequena quantidade.',
              'liquido intracavitário em grande quantidade.',
              'conteúdo heterogêneo intracavitário em pequena quantidade.',
              'conteúdo heterogêneo intracavitário em grande quantidade.',
            ]"
            :disabled="!camposExibidos.exCavUt"
            required
            dense
            class="mx-2 flex-grow-0"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.cavidadeUterina"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exCavUt"
            @click="
              camposExibidos.exCavUt = true;
              laudoSelecionado.laudoJson.cavidadeUterina = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exCavUt = false;
              laudoSelecionado.laudoJson.cavidadeUterina = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <!-- <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exImgHipereMede ? '' : 'text--disabled'
        }`"
      >
        <span>Imagem hiperecóica medindo</span>
        <v-text-field
          v-mask="''"
          required
          :disabled="!camposExibidos.exImgHipereMede"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          dense
          class="mx-2 flex-grow-0"
          v-model="laudoSelecionado.laudoJson.imagemHiperecoicaMedindo"
        >
        </v-text-field>
        <span>mm. </span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exImgHipereMede"
          @click="camposExibidos.exImgHipereMede = true;
          laudoSelecionado.laudoJson.imagemHiperecoicaMedindo = undefined"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exImgHipereMede = false;
          laudoSelecionado.laudoJson.imagemHiperecoicaMedindo = undefined"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-select
          :items="[
            'com pedículo vascular',
            'sem detecção de pedículo vascular',
          ]"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          dense
          class="mx-2 flex-grow-0"
          :disabled="!camposExibidos.exImgHipereMede2"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.pediculoVascular"
        >
        </v-select>
        .
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exImgHipereMede2"
          @click="camposExibidos.exImgHipereMede2 = true;
          laudoSelecionado.laudoJson.pediculoVascular = undefined"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exImgHipereMede2 = false;
          laudoSelecionado.laudoJson.pediculoVascular = undefined"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exPresencaDIUUm ? '' : 'text--disabled'
          }`"
        >
          <span> DIU</span>
          <v-text-field
            v-mask="''"
            required
            :disabled="!camposExibidos.exPresencaDIUUm"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.presencaDiu"
          >
          </v-text-field>
          <v-select
            :items="[
              'localizado acima do orifício cervical interno',
              'ultrapassando o orifício cervical interno',
              'localizado no colo uterino',
            ]"
            :disabled="!camposExibidos.exPresencaDIUUm"
            required
            dense
            class="mx-2 flex-grow-0"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.pediculoVascular2"
          >
          </v-select
          >.
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exPresencaDIUUm"
            @click="
              camposExibidos.exPresencaDIUUm = true;
              laudoSelecionado.laudoJson.presencaDiu = undefined;
              laudoSelecionado.laudoJson.pediculoVascular2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exPresencaDIUUm = false;
              laudoSelecionado.laudoJson.presencaDiu = undefined;
              laudoSelecionado.laudoJson.pediculoVascular2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisUter02"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais:"
          >
          </v-textarea>
        </v-col>

        <!-- <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exSacoGestacional ? '' : 'text--disabled'
        }`"
      >
        <span>Saco gestacional intracavitário de contornos:</span>
        <v-select
          :items="['regulares', 'irregulares', '____________']"
          :disabled="!camposExibidos.exSacoGestacional"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          required
          dense
          class="mx-2 flex-grow-0"
          v-model="laudoSelecionado.laudoJson.sacoGestacional"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exSacoGestacional"
          @click="camposExibidos.exSacoGestacional = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exSacoGestacional = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exVesiculaVitelinica ? '' : 'text--disabled'
        }`"
      >
        <span>Vesícula vitelínica</span>
        <v-select
          :items="[
            'ausente',
            'presente de aspecto habitual',
            'presente hidrópica',
            'presente, com dimensões reduzidas',
            '____________________',
          ]"
          :disabled="!camposExibidos.exVesiculaVitelinica"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          required
          dense
          class="mx-2 flex-grow-0"
          v-model="laudoSelecionado.laudoJson.vesiculasVitelinica"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exVesiculaVitelinica"
          @click="camposExibidos.exVesiculaVitelinica = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exVesiculaVitelinica = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exEmbrion ? '' : 'text--disabled'
        }`"
        ><span>Embrião</span>
        <v-select
          :items="[
            'ausente',
            'presente sem batimentos cardíacos',
            'presente com batimentos cardíacos',
          ]"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exEmbrion"
          validate-on-blur
          required
          dense
          class="mx-2 flex-grow-0"
          v-model="laudoSelecionado.laudoJson.sacoGestacional2"
        >
        </v-select>
        <span>medindo</span>
        <v-text-field
          v-mask="''"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          dense
          class="mx-2 flex-grow-0"
          :disabled="!camposExibidos.exEmbrion"
          v-model="laudoSelecionado.laudoJson.medindo2"
        >
        </v-text-field>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exEmbrion"
          @click="camposExibidos.exEmbrion = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exEmbrion = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->
        <v-col
          v-if="
            laudoSelecionado.laudoJson.informacaoUtero5 != ' não visualizado'
          "
          cols="12"
          class="d-flex justify-start align-center"
        >
          <h4>Ovários</h4>
        </v-col>

        <v-col
          v-if="
            laudoSelecionado.laudoJson.informacaoUtero5 != ' não visualizado'
          "
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exOvaDir ? '' : 'text--disabled'
          }`"
        >
          <span>Ovário direito mede</span>
          <v-text-field
            v-mask="''"
            required
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exOvaDir"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito1"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            :disabled="!camposExibidos.exOvaDir"
            required
            dense
            class="mx-2 flex-grow-0"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito2"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            :disabled="!camposExibidos.exOvaDir"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito3"
          >
          </v-text-field>
          <span>cm de diâmetros, com volume de</span>
          <v-text-field
            v-mask="''"
            :disabled="!camposExibidos.exOvaDir"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.medidasOvarioDireito4"
          >
          </v-text-field>
          <span>cm³</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exOvaDir"
            @click="
              camposExibidos.exOvaDir = true;
              laudoSelecionado.laudoJson.medidasOvarioDireito1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito3 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito4 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exOvaDir = false;
              laudoSelecionado.laudoJson.medidasOvarioDireito1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito3 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioDireito4 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exInfoUt5 ? '' : 'text--disabled'
          }`"
          ><span
            v-if="
              laudoSelecionado.laudoJson.informacaoUtero5 == ' não visualizado'
            "
            >Ovário direito
          </span>
          <v-select
            :items="[
              ' não visualizado',
              ' e tem ecotextura habitual',
              ', apresentando corpo lúteo',
              ', apresentando folículo dominante',
              ', apresentando mais de 20 folículos antrais',
              ', apresentando imagem em vidro fosco, medindo',
              ', apresentando cisto com debris medindo',
              ', apresentando cisto com contornos regulares e paredes finas, medindo',
              ', apresentando cisto com fino septo, medindo',
              ', apresentando cisto com septos, perfazendo menos de 10 loculações, medindo',
              ', apresentando cisto com septos, perfazendo mais de 10 loculações, medindo',
              ', difusamente heterogêneo',
              ', apresentando imagem heterogênea medindo',

              'Outros',
            ]"
            required
            dense
            class="mx-2 flex-grow-0"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exInfoUt5"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.informacaoUtero5"
          >
          </v-select>

          <v-text-field
            v-if="
              variaveisDoOvario.includes(
                laudoSelecionado.laudoJson.informacaoUtero5
              )
            "
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exInfoUt5"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.informacaoUtero5Cm"
          ></v-text-field>

          <span
            v-if="
              variaveisDoOvario.includes(
                laudoSelecionado.laudoJson.informacaoUtero5
              )
            "
            >cm.</span
          >

          <v-textarea
            v-if="laudoSelecionado.laudoJson.informacaoUtero5 === 'Outros'"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exInfoUt5"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.informacaoUteroOutros"
          ></v-textarea>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exInfoUt5"
            @click="
              camposExibidos.exInfoUt5 = true;
              laudoSelecionado.laudoJson.informacaoUtero5 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm2 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm3 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm4 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm5 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm6 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="camposExibidos.exInfoUt5"
            @click="
              camposExibidos.exInfoUt5 = false;
              laudoSelecionado.laudoJson.informacaoUtero5 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm2 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm3 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm4 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm5 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero5Cm6 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          v-if="
            laudoSelecionado.laudoJson.informacaoUtero6 != ' não visualizado'
          "
          :class="`d-flex justify-start align-center ${
            camposExibidos.exMedOvEsq ? '' : 'text--disabled'
          }`"
        >
          <span>Ovário esquerdo mede</span>
          <v-text-field
            v-mask="''"
            :disabled="!camposExibidos.exMedOvEsq"
            dense
            class="mx-2 flex-grow-0"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo1"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            required
            :disabled="!camposExibidos.exMedOvEsq"
            dense
            class="mx-2 flex-grow-0"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo2"
          >
          </v-text-field>
          <span>x</span>
          <v-text-field
            v-mask="''"
            :disabled="!camposExibidos.exMedOvEsq"
            dense
            class="mx-2 flex-grow-0"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo3"
          >
          </v-text-field>
          <span>cm de diâmetros, com volume de</span>
          <v-text-field
            v-mask="''"
            :disabled="!camposExibidos.exMedOvEsq"
            dense
            class="mx-2 flex-grow-0"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.medidasOvarioEsquerdo4"
          >
          </v-text-field>
          <span>cm³</span>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exMedOvEsq"
            @click="
              camposExibidos.exMedOvEsq = true;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo3 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo4 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="camposExibidos.exMedOvEsq"
            @click="
              camposExibidos.exMedOvEsq = false;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo1 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo2 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo3 = undefined;
              laudoSelecionado.laudoJson.medidasOvarioEsquerdo4 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exInfUt6 ? '' : 'text--disabled'
          }`"
        >
          <span
            v-if="
              laudoSelecionado.laudoJson.informacaoUtero6 == ' não visualizado'
            "
            >Ovário esquerdo
          </span>
          <v-select
            :items="[
              ' não visualizado',
              ' e tem ecotextura habitual',
              ', apresentando corpo lúteo',
              ', apresentando folículo dominante',
              ', apresentando folículo pré-ovulatório',
              ', apresentando mais de 20 folículos antrais',
              ', apresentando imagem em vidro fosco, medindo',
              ', apresentando cisto com debris medindo',
              ', apresentando cisto com contornos regulares e paredes finas, medindo',
              ', apresentando cisto com fino septo, medindo',
              ', apresentando cisto com septos, perfazendo menos de 10 loculações, medindo',
              ', apresentando cisto com septos, perfazendo mais de 10 loculações, medindo',
              ', difusamente heterogêneo',
              ', apresentando imagem heterogênea medindo',
              'Outros',
            ]"
            required
            :disabled="!camposExibidos.exInfUt6"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.informacaoUtero6"
          >
          </v-select>

          <v-text-field
            v-if="
              variaveisDoOvario.includes(
                laudoSelecionado.laudoJson.informacaoUtero6
              )
            "
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exInfoUt5"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.informacaoUtero6Cm"
          ></v-text-field>

          <span
            v-if="
              variaveisDoOvario.includes(
                laudoSelecionado.laudoJson.informacaoUtero6
              )
            "
            >cm.</span
          >

          <v-textarea
            v-if="laudoSelecionado.laudoJson.informacaoUtero6 === 'Outros'"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exInfoUt5"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.informacaoUteroOutros6"
          ></v-textarea>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exInfUt6"
            @click="
              camposExibidos.exInfUt6 = true;

              laudoSelecionado.laudoJson.informacaoUtero6 = undefined;
              laudoSelecionado.laudoJson.informacaoUteroOutros6 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="camposExibidos.exInfUt6"
            @click="
              camposExibidos.exInfUt6 = false;
              laudoSelecionado.laudoJson.informacaoUtero6 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero6Cm = undefined;
              laudoSelecionado.laudoJson.informacaoUtero6Cm2 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero6Cm3 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero6Cm4 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero6Cm5 = undefined;
              laudoSelecionado.laudoJson.informacaoUtero6Cm6 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisOvarios"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais:"
          >
          </v-textarea>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exLiquidoDouglas ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="['Presença', 'Ausência']"
            required
            :disabled="!camposExibidos.exLiquidoDouglas"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            dense
            class="mx-2 flex-grow-0"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.liquidoDouglas1"
          >
          </v-select>
          <span>de líquido livre em fundo de saco de Douglas.</span>
          <v-text-field
            v-if="laudoSelecionado.laudoJson.liquidoDouglas1 === 'Presença'"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exLiquidoDouglas"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.presencaLiqDou"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exLiquidoDouglas"
            @click="
              camposExibidos.exLiquidoDouglas = true;
              laudoSelecionado.laudoJson.liquidoDouglas1 = undefined;
              laudoSelecionado.laudoJson.presencaLiqDou = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="camposExibidos.exLiquidoDouglas"
            @click="
              camposExibidos.exLiquidoDouglas = false;
              laudoSelecionado.laudoJson.liquidoDouglas1 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <!-- <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exLiquidoDouglas2 ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :items="['Presença', 'Ausência']"
          required
          :disabled="!camposExibidos.exLiquidoDouglas2"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          dense
          class="mx-2 flex-grow-0"
          v-model="laudoSelecionado.laudoJson.liquidoDouglas2"
        >
        </v-select>
        <span>de líquido livre em região pélvica.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exLiquidoDouglas2"
          @click="
            camposExibidos.exLiquidoDouglas2 = true;
            laudoSelecionado.laudoJson.liquidoDouglas2 = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exLiquidoDouglas2"
          @click="
            camposExibidos.exLiquidoDouglas2 = false;
            laudoSelecionado.laudoJson.liquidoDouglas2 = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exPresencaImg ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :items="[
            'Presença de imagem heterogênea em topografia anexial',
            '________________________________________',
          ]"
          required
          :disabled="!camposExibidos.exPresencaImg"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          dense
          class="mx-2 flex-grow-0"
          v-model="laudoSelecionado.laudoJson.presencaImagem"
        >
        </v-select>

        <v-select
          :items="['direita', 'esquerda']"
          :disabled="!camposExibidos.exPresencaImg"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          dense
          class="mx-2 flex-grow-0"
          v-model="laudoSelecionado.laudoJson.liquidoDouglas"
        >
        </v-select>
        <span>,medindo</span>

        <v-text-field
          v-mask="''"
          required
          dense
          class="mx-2 flex-grow-0"
          :disabled="!camposExibidos.exPresencaImg"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.imagemHeterogeneaCm"
        >
        </v-text-field>
        <span>x</span>
        <v-text-field
          v-mask="''"
          required
          dense
          class="mx-2 flex-grow-0"
          :disabled="!camposExibidos.exPresencaImg"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.imagemHeterogeneaCm2"
        >
        </v-text-field>
        <span>x</span>
        <v-text-field
          v-mask="''"
          dense
          class="mx-2 flex-grow-0"
          :disabled="!camposExibidos.exPresencaImg"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.imagemHeterogeneaCm3"
        >
        </v-text-field>
        <span>cm de diâmetros, com volume de</span>
        <v-text-field
          v-mask="''"
          dense
          class="mx-2 flex-grow-0"
          :disabled="!camposExibidos.exPresencaImg"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.imagemHeterogeneaCm4"
        >
        </v-text-field>
        <span>cm³.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exPresencaImg"
          @click="
            camposExibidos.exPresencaImg = true;
            laudoSelecionado.laudoJson.presencaImagem = undefined;
            laudoSelecionado.laudoJson.liquidoDouglas = undefined;
            laudoSelecionado.laudoJson.imagemHeterogeneaCm = undefined;
            laudoSelecionado.laudoJson.imagemHeterogeneaCm2 = undefined;
            laudoSelecionado.laudoJson.imagemHeterogeneaCm3 = undefined;
            laudoSelecionado.laudoJson.imagemHeterogeneaCm4 = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exPresencaImg"
          @click="
            camposExibidos.exPresencaImg = false;
            laudoSelecionado.laudoJson.presencaImagem = undefined;
            laudoSelecionado.laudoJson.liquidoDouglas = undefined;
            laudoSelecionado.laudoJson.imagemHeterogeneaCm = undefined;
            laudoSelecionado.laudoJson.imagemHeterogeneaCm2 = undefined;
            laudoSelecionado.laudoJson.imagemHeterogeneaCm3 = undefined;
            laudoSelecionado.laudoJson.imagemHeterogeneaCm4 = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Conclusão</h3>
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Útero</h4>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exPelveSemAlteracao ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Pelve de aspecto ecográfico normal',
              'Miomas uterinos',
              'Mioma uterino',
              'Miomatose uterina',
              'Adenomiose difusa',
              'Adenomiose focal',
              'Aumento volumétrico do útero ',
              'Histerectomia total',
              'Histerectomia parcial',
              'Útero com dimensões reduzidas',
              'Malformação uterina',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            :disabled="!camposExibidos.exPelveSemAlteracao"
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.concUtero"
          >
          </v-select>
          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.concUtero === 'Malformação uterina'
            "
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exInfoUt5"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.concUteroMalForm"
          ></v-text-field>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exPelveSemAlteracao"
            @click="
              camposExibidos.exPelveSemAlteracao = true;
              laudoSelecionado.laudoJson.concUtero = undefined;
              laudoSelecionado.laudoJson.concUteroMalForm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exPelveSemAlteracao = false;
              laudoSelecionado.laudoJson.concUtero = undefined;
              laudoSelecionado.laudoJson.concUteroMalForm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisUter"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais:"
          >
          </v-textarea>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Endométrio</h4>
        </v-col>
        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exCondicaoUterina ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Endométrio espessado e heterogêneo',
              'Pólipo endometrial',
              'Líquido intracavitário em pequena quantidade',
              'Líquido intracavitário em moderada quantidade',
              'Conteúdo heterogêneo intracavitário em pequena quantidade',
              'Conteúdo heterogêneo intracavitário em moderada quantidade',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exCondicaoUterina"
            dense
            class="mx-2 flex-grow-0"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.condicaoUterina"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exCondicaoUterina"
            @click="
              camposExibidos.exCondicaoUterina = true;
              laudoSelecionado.laudoJson.condicaoUterina = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exCondicaoUterina = false;
              laudoSelecionado.laudoJson.condicaoUterina = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisCondcUter"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais:"
          >
          </v-textarea>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exAchadosSugestivos ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Achados sugestivos de útero bicorno',
              'Achados sugestivos de útero septado',
              'Achados sugestivos de útero didelfo',
              'Achados sugestivos de útero unicorno',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            :disabled="!camposExibidos.exAchadosSugestivos"
            dense
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.achadosSugestivos"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exAchadosSugestivos"
            @click="
              camposExibidos.exAchadosSugestivos = true;
              laudoSelecionado.laudoJson.achadosSugestivos = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exAchadosSugestivos = false;
              laudoSelecionado.laudoJson.achadosSugestivos = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>DIU</h4>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exAumentoVolumetrico ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'DIU bem localizado',
              'DIU deslocado inferiormente',
              'DIU na endocérvice',
              'Penetração do DIU no miométrio sem ultrapassar a serosa',
              'Penetração do DIU no miométrio, ultrapassando a seros',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exAumentoVolumetrico"
            dense
            class="mx-2 flex-grow-0"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.diu"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exAumentoVolumetrico"
            @click="
              camposExibidos.exAumentoVolumetrico = true;
              laudoSelecionado.laudoJson.diu = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exAumentoVolumetrico = false;
              laudoSelecionado.laudoJson.diu = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisDIU"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais:"
          >
          </v-textarea>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Cavidade</h4>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exCavidade ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Líquido intracavitário em pequena quantidade (muco?)',
              'Líquido intracavitário em grande quantidade (muco?)',
              'Líquido intracavitário em pequena quantidade (sangue?)',
              'Líquido intracavitário em grande quantidade (sangue?)',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exCavidade"
            dense
            class="mx-2 flex-grow-0"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.cavidadeUm"
          >
          </v-select>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exCavidade"
            @click="
              camposExibidos.exCavidade = true;
              laudoSelecionado.laudoJson.cavidadeUm = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exCavidade = false;
              laudoSelecionado.laudoJson.cavidadeUm = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exCavidadeDois ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Conteúdo heterogêneo intracavitário em pequena quantidade',
              'Conteúdo heterogêneo intracavitário em grande quantidade',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exCavidadeDois"
            dense
            class="mx-2 flex-grow-0"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.cavidadeDois"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exCavidadeDois"
            @click="
              camposExibidos.exCavidadeDois = true;
              laudoSelecionado.laudoJson.cavidadeDois = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exCavidadeDois = false;
              laudoSelecionado.laudoJson.cavidadeDois = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisCavidade"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais:"
          >
          </v-textarea>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h4>Ovários</h4>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exOvarioCOnc ? '' : 'text--disabled'
          }`"
        >
          <v-select
            :items="[
              'Não visualizados',
              'Ovário direito não visualizado',
              'Ovário esquerdo não visualizado',
              'Cisto simples em ovário direito',
              'Cisto simples em ovário esquerdo',
              'Cisto simples bilateral',
              'Cisto hemorrágico em ovário direito',
              'Cisto hemorrágico em ovário esquerdo',
              'Cisto hemorrágico bilateral',
              'Ovários aumentados de volume com textura polimicrocística',
              'Endometrioma em ovário direito',
              'Endometrioma em ovário esquerdo',
              'Endometrioma bilateral',
            ]"
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exOvarioCOnc"
            dense
            class="mx-2 flex-grow-0"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ovarioConc"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exOvarioCOnc"
            @click="
              camposExibidos.exOvarioCOnc = true;
              laudoSelecionado.laudoJson.ovarioConc = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exOvarioCOnc = false;
              laudoSelecionado.laudoJson.ovarioConc = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisOvarioConc"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais:"
          >
          </v-textarea>
        </v-col>

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exIOTA ? '' : 'text--disabled'
          }`"
        >
          <span> IOTA – chance de benignidade basal </span>
          <v-text-field
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exIOTA"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.chanceIOTA"
          ></v-text-field>
          <span> %. Chance de benignidade ajustado </span>
          <v-text-field
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exIOTA"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.chanceIOTABenin"
          ></v-text-field>
          <span> %. </span>

          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exIOTA"
            @click="
              camposExibidos.exIOTA = true;
              laudoSelecionado.laudoJson.chanceIOTA = undefined;
              laudoSelecionado.laudoJson.chanceIOTABenin = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exIOTA = false;
              laudoSelecionado.laudoJson.chanceIOTA = undefined;
              laudoSelecionado.laudoJson.chanceIOTABenin = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <!-- <span>
          %.
        O-RADS
      </span>
      <v-text-field


          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          dense
          class="mx-2 flex-grow-0"
          :disabled="!camposExibidos.exIOTA"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.orads"
        ></v-text-field> -->
        <!-- <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exIOTA"
          @click="camposExibidos.exIOTA = true;
          laudoSelecionado.laudoJson.chanceIOTA = undefined;
          laudoSelecionado.laudoJson.orads = undefined;
          laudoSelecionado.laudoJson.chanceIOTABenin = undefined;

          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exIOTA = false;
          laudoSelecionado.laudoJson.chanceIOTA = undefined;
          laudoSelecionado.laudoJson.orads = undefined;
          laudoSelecionado.laudoJson.chanceIOTABenin = undefined;"
          ><v-icon>mdi-eye</v-icon></v-btn
        > -->

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exIOTA2 ? '' : 'text--disabled'
          }`"
        >
          <span> O-RADS </span>
          <v-text-field
            required
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            dense
            class="mx-2 flex-grow-0"
            :disabled="!camposExibidos.exIOTA2"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.orads"
          ></v-text-field>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exIOTA2"
            @click="
              camposExibidos.exIOTA2 = true;
              laudoSelecionado.laudoJson.orads = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-else
            @click="
              camposExibidos.exIOTA2 = false;
              laudoSelecionado.laudoJson.orads = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-col cols="12">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisIOTA"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais:"
          >
          </v-textarea>
        </v-col>

        <!-- <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exLiquidoIntracavitario ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :items="[
            'Líquido intracavitário em pequena quantidade (muco?)',
            'Líquido intracavitário em grande quantidade (muco?)',
            'Líquido intracavitário em pequena quantidade (sangue?)',
            'Líquido intracavitário em grande quantidade (sangue?)',
          ]"
          required
          :disabled="!camposExibidos.exLiquidoIntracavitario"
          dense
          class="mx-2 flex-grow-0"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.liquidoIntracavitario"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exLiquidoIntracavitario"
          @click="camposExibidos.exLiquidoIntracavitario = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exLiquidoIntracavitario = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exConteudoHeterogeneo ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :items="[
            'Conteúdo heterogêneo intracavitário em pequena quantidade',
            'Conteúdo heterogêneo intracavitário em grande quantidade',
          ]"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exConteudoHeterogeneo"
          dense
          class="mx-2 flex-grow-0"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.conteudoHeterogeneo"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exConteudoHeterogeneo"
          @click="camposExibidos.exConteudoHeterogeneo = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exConteudoHeterogeneo = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->

        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exImagemHiperecóica ? '' : 'text--disabled'
          }`"
        >
          <span
            >Imagem hiperecóica intracavitária com pedículo vascular, sugestiva
            de
          </span>

          <v-select
            :items="['pólipo.', 'Mioma submucoso.']"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exImagemHiperecóica"
            validate-on-blur
            dense
            required
            class="mx-2 flex-grow-0"
            v-model="laudoSelecionado.laudoJson.imagemHiperecóica"
          >
          </v-select>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exImagemHiperecóica"
            @click="
              camposExibidos.exImagemHiperecóica = true;
              laudoSelecionado.laudoJson.imagemHiperecóica = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="camposExibidos.exImagemHiperecóica"
            @click="
              camposExibidos.exImagemHiperecóica = false;
              laudoSelecionado.laudoJson.imagemHiperecóica = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <!-- <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exEndometrioEspessado ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :items="['Endométrio espessado e heterogêneo', '_______________']"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :disabled="!camposExibidos.exEndometrioEspessado"
          dense
          class="mx-2 flex-grow-0"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.endometrioEspessado"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exEndometrioEspessado"
          @click="camposExibidos.exEndometrioEspessado = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exEndometrioEspessado = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->

        <!-- <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exGestacao ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :items="[
            'Gestação incipiente?',
            'Vesícula vitelínica hidrópica',
            'Vesícula vitelínica com dimensões reduzidas',
            'Gestação anembrionada',
            'Óbito embrionário',
          ]"
          required
          :disabled="!camposExibidos.exGestacao"
          dense
          class="mx-2 flex-grow-0"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.gestacao"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exGestacao"
          @click="camposExibidos.exGestacao = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exGestacao = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->

        <!-- <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exSugestaoAvaliacao ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :items="[
            'Sugerimos nova avaliação em uma semana semanas.',
            'Sugerimos nova avaliação em duas semana semanas.',
          ]"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :disabled="!camposExibidos.exSugestaoAvaliacao"
          dense
          class="mx-2 flex-grow-0"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.sugestaoAvaliacao"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exSugestaoAvaliacao"
          @click="camposExibidos.exSugestaoAvaliacao = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exSugestaoAvaliacao = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->

        <!-- <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exSugestaoDiu ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :items="[
            'DIU bem posicionado.',
            'DIU deslocado.',
            'Expulsão do DIU.',
            'Penetração do DIU no miométrio',
            'DIU ultrapassando a serosa.',
          ]"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          :disabled="!camposExibidos.exSugestaoDiu"
          dense
          class="mx-2 flex-grow-0"
          v-model="laudoSelecionado.laudoJson.sugestaoDiu"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exSugestaoDiu"
          @click="camposExibidos.exSugestaoDiu = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exSugestaoDiu = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->

        <!-- <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exGravidezEctópica ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :items="[
            'Achados sugestivos de gravidez ectópica integra',
            'Achados sugestivos de gravidez ectópica rota.',
          ]"
          required
          :disabled="!camposExibidos.exGravidezEctópica"
          dense
          class="mx-2 flex-grow-0"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.gravidezEctópica"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exGravidezEctópica"
          @click="camposExibidos.exGravidezEctópica = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exGravidezEctópica = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->

        <!-- <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exCistoSimples ? '' : 'text--disabled'
        }`"
      >
        <span>Cisto simples</span>
        <v-select
          :items="[
            'em ovário direito.',
            'em ovário esquerdo.',
            'em ovário bilateral.',
          ]"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          dense
          class="mx-2 flex-grow-0"
          validate-on-blur
          :disabled="!camposExibidos.exCistoSimples"
          v-model="laudoSelecionado.laudoJson.cistoSimples"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCistoSimples"
          @click="
            camposExibidos.exCistoSimples = true;
            laudoSelecionado.laudoJson.cistoSimples = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCistoSimples"
          @click="
            camposExibidos.exCistoSimples = false;
            laudoSelecionado.laudoJson.cistoSimples = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exCistoDebris ? '' : 'text--disabled'
        }`"
      >
        <span>Cisto com debris (cisto hemorrágico) </span>
        <v-select
          :items="[
            'em ovário direito.',
            'em ovário esquerdo.',
            'em ovário bilateral.',
          ]"
          :disabled="!camposExibidos.exCistoDebris"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          dense
          class="mx-2 flex-grow-0"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.cistoDebris"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCistoDebris"
          @click="
            camposExibidos.exCistoDebris = true;
            laudoSelecionado.laudoJson.cistoDebris = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCistoDebris"
          @click="
            camposExibidos.exCistoDebris = false;
            laudoSelecionado.laudoJson.cistoDebris = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exCistoComplexo ? '' : 'text--disabled'
        }`"
      >
        <span>Cisto complexo</span>
        <v-select
          :items="[
            'em ovário direito.',
            'em ovário esquerdo.',
            'em ovário bilateral.',
          ]"
          :disabled="!camposExibidos.exCistoComplexo"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.cistoComplexo"
        >
        </v-select>

        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCistoComplexo"
          @click="
            camposExibidos.exCistoComplexo = true;
            laudoSelecionado.laudoJson.cistoComplexo = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCistoComplexo"
          @click="
            camposExibidos.exCistoComplexo = false;
            laudoSelecionado.laudoJson.cistoComplexo = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exAchadoEndometrioma ? '' : 'text--disabled'
        }`"
      >
        <span>Achados sugestivos de endometrioma</span>
        <v-select
          :items="[
            'em ovário direito.',
            'em ovário esquerdo.',
            'em ovário bilateral.',
          ]"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
          :disabled="!camposExibidos.exAchadoEndometrioma"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.achadoEndometrioma"
        >
        </v-select>

        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exAchadoEndometrioma"
          @click="
            camposExibidos.exAchadoEndometrioma = true;
            laudoSelecionado.laudoJson.achadoEndometrioma = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exAchadoEndometrioma"
          @click="
            camposExibidos.exAchadoEndometrioma = false;
            laudoSelecionado.laudoJson.achadoEndometrioma = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exTumorSolido ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :items="['Tumor sólido', '_____________']"
          required
          class="mx-2 flex-grow-0"
          dense
          :disabled="!camposExibidos.exTumorSolido"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.tumorSolido"
        >
        </v-select>
        <v-select
          :items="[
            'em ovário direito.',
            'em ovário esquerdo.',
            'em ovário bilateral.',
          ]"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
          :disabled="!camposExibidos.exTumorSolido"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.tumorSolido2"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exTumorSolido"
          @click="camposExibidos.exTumorSolido = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exTumorSolido = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exOvariosAumentados ? '' : 'text--disabled'
        }`"
      >
        <v-select
          :items="[
            'Ovários aumentados de volume com textura polimicrocística.',
            '___________________',
          ]"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
          :disabled="!camposExibidos.exOvariosAumentados"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.ovariosAumentados"
        >
        </v-select>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exOvariosAumentados"
          @click="camposExibidos.exOvariosAumentados = true"
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-else
          @click="camposExibidos.exOvariosAumentados = false"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->
      </template>

      <v-col cols="12" class="d-flex justify-start align-center pt-6">
        <v-btn
          v-if="podeEditar"
          color="primary"
          :loading="carregamentoDoLaudo"
          @click="
            () => {
              laudoId ? editarLaudo() : salvarLaudo();
            }
          "
        >
          salvar laudo
        </v-btn>

        <v-btn
          color="primary"
          v-if="podeImprimir && !podeEditar"
          @click="imprimirLaudo"
          class="ml-5"
          :loading="loadingImprimir"
        >
          Imprimir
        </v-btn>

        <v-btn
          color="primary"
          v-if="!podeEditar"
          class="ml-5"
          @click="podeEditar = true"
        >
          Editar
        </v-btn>

        <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
          Cancelar
        </v-btn>

        <v-btn
          color="primary"
          v-if="podePersonalizar"
          @click="adcionarConteudoEditorPersonalizado"
          class="ml-5"
        >
          {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
        </v-btn>
      </v-col>
    </v-row>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import moment from 'moment';
import { VueEditor } from 'vue2-editor';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    barraDeBuscaExames,
    VueEditor,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      quantidadeFolhasEditorPersonalizado: 0,
      carregamentoDoLaudo: false,
      loadingImprimir: false,
      laudoId: null,
      podeEditar: true,
      voltando: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      variaveisDoOvario: [
        ', apresentando imagem em vidro fosco, medindo',
        ', apresentando cisto com debris medindo',
        ', apresentando cisto com contornos regulares e paredes finas, medindo',
        ', apresentando cisto com fino septo, medindo',
        ', apresentando cisto com septos, perfazendo menos de 10 loculações, medindo',
        ', apresentando cisto com septos, perfazendo mais de 10 loculações, medindo',
        ', apresentando imagem heterogênea medindo',
      ],
      feto: '',
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,

      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      camposExibidos: {
        exSitUtero: true,
        exPosUtero: true,
        exEstdUtero: true,
        exMiomeEcoText: true,
        exPresenca: true,
        exDistImg: [],
        exDistImgFigo: [],
        exInfoUt1: true,
        exInfoUt2: true,
        exInfoUt3: true,
        exInfoUt4: true,
        exlong: true,
        extrans: true,
        exAntPost: true,
        exVol: true,
        exCavUt: true,
        exMedEndo: true,
        exMedEndoUm: true,
        exMedEndoUm2: true,
        exImgHipereMede2: true,
        exEco: true,
        exEco01: true,
        exImgHipereMede: true,
        exIOTA: true,
        exIOTA2: true,
        exImgHipereMedeUm: true,
        exPresencaDIU: true,
        exPresencaDIUUm: true,
        exSacoGestacional: true,
        exVesiculaVitelinica: true,
        exOvarioCOnc: true,
        exEmbrion: true,
        exOvaDir: true,
        exInfoUt5: true,
        exMedOvEsq: true,
        exInfUt6: true,
        exInfoUtFund: true,
        exLiquidoDouglas2: true,
        exLiquidoDouglas: true,
        exPresencaImg: true,
        exImagemHiperecóica: true,
        exCistoDebris: true,
        exCistoSimples: true,
        exCistoComplexo: true,
        exPelveSemAlteracao: true,
        exAchadoEndometrioma: true,
        exAchadosSugestivos: true,
        exCondicaoUterina: true,
        exLiquidoIntracavitario: true,
        exConteudoHeterogeneo: true,
        exTumorSolido: true,
        exEndometrioEspessado: true,
        exGestacao: true,
        exOpcoesUtero: true,
        exSugestaoAvaliacao: true,
        exSugestaoDiu: true,
        exOvariosAumentados: true,
        exAumentoVolumetrico: true,
        exCavidade: true,
        exCavidadeDois: true,
        exGravidezEctópica: true,
      },

      listaPresencas: [],
      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],
      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,
      colodeutero: false,
      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 2,5 cm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    podeImprimir() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    temIdDoLaudo() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    removeFolha(value) {
      let indice = value - 1;

      if (this.laudoSelecionado.laudoJson.folhas.length != 1) {
        this.conteudoEditorPersonalizado.splice(indice, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.$forceUpdate();
    },
    forceReRender() {
      this.componentKey++;
    },
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudoSelecionado.laudoJson.folhas) {
        this.conteudoEditorPersonalizado =
          this.laudoSelecionado.laudoJson.folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }

      let todos = [];

      this.conteudoEditorPersonalizado = [];
      if (this.laudoSelecionado.laudoJson.opcoesUtero) {
        todos.push(
          `${
            this.laudoSelecionado.laudoJson.opcoesUtero ===
              'Paciente menacme' ||
            this.laudoSelecionado.laudoJson.opcoesUtero ===
              'Paciente no menacme'
              ? (this.laudoSelecionado.laudoJson.dataUltimaMestrucao
                  ? 'Data da última menstruação ' +
                    this.dataFormatada(
                      this.laudoSelecionado.laudoJson.dataUltimaMestrucao
                    )
                  : '') +
                (this.laudoSelecionado.laudoJson.dataUltimaMestrucao
                  ? ', ' +
                    this.diaCicloMenstrual(
                      this.laudoSelecionado.laudoJson.dataUltimaMestrucao
                    ) +
                    'º dia do ciclo menstrual'
                  : '')
              : this.laudoSelecionado.laudoJson.opcoesUtero
          }.`
        );
      }
      todos.push('\n');
      // if (this.laudoSelecionado.laudoJson.uteroPosicao) {
      //   todos.push(
      //     `O útero está ${this.laudoSelecionado.laudoJson.uteroPosicao}, ${this.laudoSelecionado.laudoJson.uteroSituacao}, tem contornos ${this.laudoSelecionado.laudoJson.uteroContornos}`
      //   );
      //   todos.push('\n');
      // }

      if (this.laudoSelecionado.laudoJson.uteroPosicao) {
        let resultadoPosicao = `O útero está ${this.laudoSelecionado.laudoJson.uteroPosicao}`;
        if (this.laudoSelecionado.laudoJson.uteroSituacao)
          resultadoPosicao += `, ${this.laudoSelecionado.laudoJson.uteroSituacao}`;
        if (this.laudoSelecionado.laudoJson.uteroContornos)
          resultadoPosicao += `, tem contornos ${this.laudoSelecionado.laudoJson.uteroContornos}`;
        resultadoPosicao += '.\n';
        todos.push(resultadoPosicao);
      }
      if (this.laudoSelecionado.laudoJson.miometrioEcotextura) {
        todos.push(
          `O miométrio tem ecotextura ${
            this.laudoSelecionado.laudoJson.miometrioEcotextura
          } ${
            this.laudoSelecionado.laudoJson.miometrioEcotextura ===
            'heterogênea focal'
              ? this.laudoSelecionado.laudoJson.miometrioEcotextura2
              : ''
          }`
        );
        todos.push('\n');
      }
      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisUter01) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisUter01}`
        );
        todos.push('\n');
      }
      if (this.laudoSelecionado.laudoJson.presenca) {
        todos.push(
          `Presença de ${this.laudoSelecionado.laudoJson.presenca} ${
            this.laudoSelecionado.laudoJson.presenca > 1
              ? 'nódulos miometriais '
              : 'nódulo miometriail'
          } com as seguintes características: \n`
        );
      }
      if (!!this.laudoSelecionado.laudoJson.presenca) {
        if (this.laudoSelecionado.laudoJson.listaPresencas.length > 0)
          for (
            var i = 0;
            i < this.laudoSelecionado.laudoJson.listaPresencas.length;
            i++
          ) {
            todos.push(`Nódulo ${
              i + 1 === 1
                ? 'um'
                : i + 1 === 2
                ? 'dois'
                : i + 1 === 3
                ? 'três'
                : i + 1 === 4
                ? 'quatro'
                : i + 1 === 5
                ? 'cinco'
                : ''
            } - ${
              this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.distri
                ? this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                    .distri
                : ''
            }${
              this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.loc
                ? ' '
                : ''
            }${
              this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.loc
                ? this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.loc
                : '.'
            }${
              this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.distri
                ? ' medindo '
                : ''
            }${
              this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.medida
                ? this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                    .medida
                : ''
            }${
              this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.medida
                ? ' cm.'
                : ''
            }${
              (this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                .medida ||
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                  .distri) &&
              this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.loc
                ? ''
                : ''
            }${
              this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.medida
                ? ' FIGO '
                : ''
            }${
              (this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                .medida ||
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                  .distri) &&
              this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.figo
                ? this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                    .figo + '.'
                : ''
            }
`);
          }
      }
      if (this.laudoSelecionado.laudoJson.malFormacaoUte) {
        todos.push(`\n<h4>Malformações uterinas</h4>`);
      }
      if (this.laudoSelecionado.laudoJson.informacaoUtero1) {
        todos.push(`${this.laudoSelecionado.laudoJson.informacaoUtero1}\n`);
      }
      if (this.laudoSelecionado.laudoJson.informacaoUteroFund) {
        todos.push(`${this.laudoSelecionado.laudoJson.informacaoUteroFund}\n`);
      }
      if (this.laudoSelecionado.laudoJson.informacaoUtero2) {
        todos.push(`${this.laudoSelecionado.laudoJson.informacaoUtero2}\n`);
      }
      if (this.laudoSelecionado.laudoJson.informacaoUtero3) {
        todos.push(`${this.laudoSelecionado.laudoJson.informacaoUtero3}\n`);
      }
      if (this.laudoSelecionado.laudoJson.informacaoUtero4) {
        todos.push(`${this.laudoSelecionado.laudoJson.informacaoUtero4}\n`);
      }

      todos.push(`\n<h4>Diâmetros uterinos</h4>`);
      todos.push('');
      if (this.laudoSelecionado.laudoJson.longitudinal) {
        todos.push(
          `Longitudinal: ${this.laudoSelecionado.laudoJson.longitudinal} cm.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.anteroPosterior) {
        todos.push(
          `Antero-posterior: ${this.laudoSelecionado.laudoJson.anteroPosterior} cm.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.transversal) {
        todos.push(
          `Transverso: ${this.laudoSelecionado.laudoJson.transversal} cm.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.volumeUterino) {
        todos.push(
          `Volume uterino: ${this.laudoSelecionado.laudoJson.volumeUterino} cm³.\n`
        );
      }
      todos.push('\n');

      if (this.laudoSelecionado.laudoJson.endometrioMede) {
        todos.push(
          `<span><strong>Endométrio</strong> mede ${
            this.laudoSelecionado.laudoJson.endometrioMede
          } mm de espessura ${
            this.laudoSelecionado.laudoJson.uteroSituacao2
              ? ', ' +
                this.laudoSelecionado.laudoJson.uteroSituacao2 +
                ' ' +
                (this.laudoSelecionado.laudoJson.uteroContornos2
                  ? this.laudoSelecionado.laudoJson.uteroContornos2
                  : '')
              : '.'
          }</span>`
        );
      }
      if (this.laudoSelecionado.laudoJson.ecotextura) {
        todos.push(
          `<span><strong>Ecotextura</strong> ${
            this.laudoSelecionado.laudoJson.ecotextura == 'heterogênea'
              ? this.laudoSelecionado.laudoJson.ecotextura +
                ', apresentando imagem hiperecóica medindo ' +
                this.laudoSelecionado.laudoJson.imagemHiperecoicaMedindo +
                'mm'
              : this.laudoSelecionado.laudoJson.ecotextura
          }.</span>`
        );
      }
      if (this.laudoSelecionado.laudoJson.cavidadeUterina) {
        todos.push(
          `<span><strong>Cavidade uterina:</strong> ${this.laudoSelecionado.laudoJson.cavidadeUterina}</span>`
        );
      }

      if (this.laudoSelecionado.laudoJson.presencaDiu) {
        todos.push(
          `DIU ${this.laudoSelecionado.laudoJson.presencaDiu} ${
            this.laudoSelecionado.laudoJson.pediculoVascular2
              ? this.laudoSelecionado.laudoJson.pediculoVascular2 + ''
              : ''
          }`
        );
        todos.push('.');
        todos.push('\n');
      }

      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisUter02) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisUter02}\n`
        );
      }
      todos.push('\n');

      todos.push(`<h4>Ovários</h4>`);

      if (
        this.laudoSelecionado.laudoJson.medidasOvarioDireito1 &&
        this.laudoSelecionado.laudoJson.informacaoUtero5 != ' não visualizado'
      ) {
        todos.push(
          `Ovário direito mede ${
            this.laudoSelecionado.laudoJson.medidasOvarioDireito1 ?? ''
          } x ${
            this.laudoSelecionado.laudoJson.medidasOvarioDireito2 ?? ''
          } x ${
            this.laudoSelecionado.laudoJson.medidasOvarioDireito3 ?? ''
          } cm de diâmetros, com volume de ${
            this.laudoSelecionado.laudoJson.medidasOvarioDireito4 ?? ''
          } cm³ ${
            this.laudoSelecionado.laudoJson.informacaoUtero5
              ? this.laudoSelecionado.laudoJson.informacaoUtero5
              : ''
          }. \n`
        );
      }

      if (
        this.laudoSelecionado.laudoJson.medidasOvarioDireito1 &&
        this.laudoSelecionado.laudoJson.informacaoUtero5 == ' não visualizado'
      ) {
        todos.push(
          `Ovário direito ${this.laudoSelecionado.laudoJson.informacaoUtero5}\n`
        );
      }

      if (
        this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo1 &&
        this.laudoSelecionado.laudoJson.informacaoUtero6 != ' não visualizado'
      ) {
        todos.push(
          `Ovário esquerdo mede ${
            this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo1 ?? ''
          } x ${
            this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo2 ?? ''
          } x ${
            this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo3 ?? ''
          } cm de diâmetros, com volume de ${
            this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo4 ?? ''
          } cm³ ${
            this.laudoSelecionado.laudoJson.informacaoUtero6
              ? this.laudoSelecionado.laudoJson.informacaoUtero6
              : ''
          }. \n`
        );
      }
      // if (
      //   this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo1 &&
      //   this.laudoSelecionado.laudoJson.informacaoUtero6 != ' não visualizados'
      // ) {
      //   todos.push(
      //     `Ovario esquerdo mede ${
      //       this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo1
      //     }  x ${this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo2}  x ${
      //       this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo3
      //     }  cm de diâmetros, com volume de ${
      //       this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo4
      //     } cm³${
      //       this.laudoSelecionado.laudoJson.informacaoUtero6
      //         ? this.laudoSelecionado.laudoJson.informacaoUtero6
      //         : ''
      //     }. \n`
      //   );
      // }
      if (
        this.laudoSelecionado.laudoJson.medidasOvarioEsquerdo1 &&
        this.laudoSelecionado.laudoJson.informacaoUtero6 == ' não visualizado'
      ) {
        todos.push(
          `Ovário esquerdo ${this.laudoSelecionado.laudoJson.informacaoUteroOutros6}\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisOvarios) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisOvarios}\n`
        );
      }
      todos.push('\n');
      if (this.laudoSelecionado.laudoJson.liquidoDouglas1) {
        todos.push(
          `${
            this.laudoSelecionado.laudoJson.liquidoDouglas1
          } de líquido livre em fundo de saco de Douglas. ${
            this.laudoSelecionado.laudoJson.liquidoDouglas1 === 'Presença'
              ? this.laudoSelecionado.laudoJson.presencaLiqDou ?? ''
              : ''
          }`
        );
      }
      todos.push('.');
      todos.push('\n');

      todos.push(`\n<h4>Conclusão</h4>`);
      if (this.laudoSelecionado.laudoJson.concUtero) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.concUtero}. ${
            this.laudoSelecionado.laudoJson.concUtero === 'Malformação uterina'
              ? this.laudoSelecionado.laudoJson.concUteroMalForm ?? ''
              : ''
          }</span>`
        );
      }
      todos.push('\n');

      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisUter) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisUter}\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.condicaoUterina) {
        todos.push(`${this.laudoSelecionado.laudoJson.condicaoUterina}.`);
      }
      todos.push('\n');
      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisCondcUter) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisCondcUter}\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.achadosSugestivos) {
        todos.push(
          `<span>${this.laudoSelecionado.laudoJson.achadosSugestivos}.</span>`
        );
      }
      if (this.laudoSelecionado.laudoJson.diu) {
        todos.push(`${this.laudoSelecionado.laudoJson.diu}.`);
      }
      todos.push('\n');
      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisDIU) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisDIU}`
        );
      }
      todos.push('\n');
      if (this.laudoSelecionado.laudoJson.cavidadeUm) {
        todos.push(`${this.laudoSelecionado.laudoJson.cavidadeUm}.\n`);
      }
      if (this.laudoSelecionado.laudoJson.cavidadeDois) {
        todos.push(`${this.laudoSelecionado.laudoJson.cavidadeDois}.\n`);
      }
      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisCavidade) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisCavidade}\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.ovarioConc) {
        todos.push(
          `<span>${this.laudoSelecionado.laudoJson.ovarioConc}.</span>`
        );
      }
      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisOvarioConc) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisOvarioConc}\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.chanceIOTA) {
        todos.push(
          `IOTA – chance de benignidade basal ${
            this.laudoSelecionado.laudoJson.chanceIOTA
          } %. Chance de benignidade ajustado ${
            this.laudoSelecionado.laudoJson.chanceIOTABenin ?? ''
          }%.\n`
        );
      }

      if (this.laudoSelecionado.laudoJson.orads) {
        todos.push(`O-RADS  ${this.laudoSelecionado.laudoJson.orads}.\n`);
      }
      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisIOTA) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisIOTA}\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.achadosSugestivos) {
        todos.push(`${this.laudoSelecionado.laudoJson.achadosSugestivos}.\n`);
      }
      if (this.laudoSelecionado.laudoJson.imagemHiperecóica) {
        todos.push(
          `Imagem hiperecóica intracavitária com pedículo vascular, sugestiva de ${this.laudoSelecionado.laudoJson.imagemHiperecóica}\n`
        );
      }

      while (todos.length > 0) {
        const teste = todos.splice(0, 30);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },
    criaListaFor(value) {
      this.laudoSelecionado.laudoJson.listaPresencas = [];
      let limite = parseFloat(value);

      for (let i = 0; i < limite; i++) {
        this.laudoSelecionado.laudoJson.listaPresencas.push({
          presenca: {
            distri: '',
            medida: '',
            loc: '',
            figo: '',
          },
        });
        this.camposExibidos.exDistImg.push(true);
        this.camposExibidos.exDistImgFigo.push(true);
      }
      this.$forceUpdate();
    },
    forceReRender() {
      this.componentKey++;
    },

    ...mapGetters('Offline', ['requisicao', 'dados']),

    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          this.carregamentoDoLaudo = true;

          const exameId = this.$route.query.exame;

          this.laudoSelecionado.examePorConsultaId = exameId;
          this.consultaSelecionada.status = 'LAUDADO';

          if (navigator.onLine) {
            const { data } = await this.postLaudo();
            this.laudoId = data;
            await this.alterarStatus();

            if (!Number.isInteger(data)) throw new Error(data);

            this.getLaudoByExamePorConsultaId(exameId);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
              requisicaoSalvar: this.postLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'POST_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', {
              laudo: this.laudoSelecionado,
              consulta: this.consultaSelecionada,
            });
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    juntaPersonalizadoAoLaudo() {
      this.laudoSelecionado.laudoJson.folhas = this.conteudoEditorPersonalizado;
      this.laudoSelecionado.laudoJson.titulo =
        'ULTRASSONOGRAFIA PÉLVICA TRANSVAGINAL';
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        this.carregamentoDoLaudo = false;
        return;
      } finally {
        try {
          this.carregamentoDoLaudo = false;
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            await this.patchLaudo(this.laudoSelecionado);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
            // this.podeImprimir = false;
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    desativaLoading() {
      this.loadingImprimir = false;
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      this.loadingImprimir = true;
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.pelvicaTransvaginalUtero',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },

    diaCicloMenstrual(ultimaMenstruacao) {
      const hoje = moment();
      const inicioCiclo = moment(ultimaMenstruacao);
      const duracaoCiclo = 28; // duração do ciclo menstrual em dias
      const diasDesdeInicioCiclo = hoje.diff(inicioCiclo, 'days');
      const diaCiclo = (diasDesdeInicioCiclo % duracaoCiclo) + 1;
      return diasDesdeInicioCiclo.toFixed(0);
    },
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
    formataLaudo() {
      if (this.exibeObs) {
        this.laudoSelecionado.laudoJson.exibeObs = false;
      } else {
        this.laudoSelecionado.laudoJson.exibeObs = true;
      }
    },

    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },
  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;

    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      //   this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
