<template lang="">
  <div>
    <LaudoMama></LaudoMama>
  </div>
</template>
<script>
import LaudoMama from "../components/laudos/formularios/exames/mama/LaudoMama.vue";
export default {
  components: {
    LaudoMama,
  },
};
</script>
<style lang=""></style>
