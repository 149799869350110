import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import { format } from 'date-fns';
import { EMBRION_API_URL } from '../data/enviroment';

export default {
  data() {
    return {
      urlBase: EMBRION_API_URL,
    };
  },
  computed: {
    ...mapGetters('Auth', ['headers', 'nivelPermissao']),
  },
  methods: {
    async setContaSelecionada(context, conta) {
      context.$store.commit('Financeiro/SET_CONTA_SELECIONADA', conta);
    },
    async getValoresReceber(context, body,modoUrl) {
      let modo = modoUrl == "cartão"? 'Cartao': (modoUrl == 'convênio'? 'Convenio': modoUrl)
      let query = `${
        body.dataInicial ? 'dataInicial=' + body.dataInicial + '&' : ''
      }${body.dataFinal ? 'dataFinal=' + body.dataFinal + '&' : ''}${
        body.convenioId ? 'convenioId=' + body.convenioId + '&' : ''
      }${body.medicoId ? 'medicoId=' + body.medicoId : ''}`;

      const resp = axios
        .get(`${this.urlBase}ContasReceber/${modo}?${query}`, { headers: this.headers })
        .then(response => {
          
          let lista
          let total = parseFloat(response.data.valorTotal);
          total = total.toFixed(2);
          
          if(modo == 'Cartao'){
            
            lista = response.data.contas.map(item => {
            
              return {
                ...item,
                data: moment(item.data, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              };
            });
            
          }else{
            
            lista = response.data.map(item => {
              return {
                ...item,
                data: moment(item.data, 'YYYY-MM-DD').format('DD/MM/YYYY'),
              };
            });
            
          }
          

          context.$store.commit('Financeiro/SET_TOTAL_VALORES_RECEBER', total);

          context.$store.commit('Financeiro/SET_VALORES_RECEBER', lista);
        });
      return resp;
    },

    async getContasAPagarPorVencimento(context, dataInicial, dataFinal, tipo, medicoId) {
      const dataInicialFormatada = moment(dataInicial).format('YYYY-MM-DD');
      const dataFinalFormatada = moment(dataFinal).format('YYYY-MM-DD');
      const resp = await axios

        .get(`${this.urlBase}LivroCaixa/contasPor${tipo}`, {
          params: {
            dataInicial: dataInicialFormatada,
            dataFinal: dataFinalFormatada,
            medicoId: medicoId,
          },
          headers: this.headers,
        })
        .then(response => {
          let consultas = response.data.map(item => {
            return {
              ...item,
              dataVencimento: moment(item.dataVencimento, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
              ),
              dataPagamento: moment(item.dataPagamento, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
              ),
            };
          });
          context.$store.commit('Financeiro/SET_CONSULTAS_DIVIDAS', consultas);
        })
        .catch(() => {
          context.$store.commit('Financeiro/SET_CONSULTAS_DIVIDAS', []);
        });
    },

    async setDividaSelecionada(context, conta) {
      let model = { ...conta };

      model.dataVencimento = moment(model.dataVencimento, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      );
      model.dataPagamento = moment(model.dataPagamento, 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      );
      model.pago = model.pago == true? 'SIM':'NÃO';
      context.$store.commit('Financeiro/SET_DIVIDA_SELECIONADA', model);
    },
    async resetDividaSelecionada(context, conta) {
      context.$store.commit('Financeiro/RESET_DIVIDA_SELECIONADA', conta);
    },

    async postContasAPagar(model) {
      model.pago = model.pago === 'SIM' ? true : false;
      const response = await axios.post(`${this.urlBase}LivroCaixa`, model, {
        headers: this.headers,
      });
      return response;
    },

    async postEnviaAporte(model) {
      // model.data = moment(model.data, 'DD-MM-YYYY').format('YYYY-MM-DD');
      let body = model;
      // body.data = moment(model.data, 'DD-MM-YYYY').format('YYYY-MM-DD');
      const response = await axios.post(
        `${this.urlBase}Pagamento/EnviaAporte`,
        model,
        {
          headers: this.headers,
        }
      );
      return response;
    },
    async postEnviaContaFixa(model) {
      const response = await axios.post(
        `${this.urlBase}LivroCaixa/EnviaContaFixa`,
        model,
        {
          headers: this.headers,
        }
      );
      return response;
    },

    async getProcedimentoPorConvenio(convenioId) {
      const resp = await axios.get(
        `${this.urlBase}MapaAssociacaoExames/ListaPorConvenio?convenioId=${convenioId}`,
        { headers: this.headers }
      );

      return resp;
    },
    async patchAtualizarConta(conta, IdConta) {
      let model = {...conta}
      model.pago = model.pago == 'SIM'? true: false
      const resp = await axios.patch(`${this.urlBase}LivroCaixa`, model, {
        headers: this.headers,
      });
      return resp;
    },
    async confirmarRecebimentoCartao(conta) {
      let bodyConta = {
        id: conta.id,
        data: moment(conta.data, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        consultaId: conta.consultaId,
        situacao: 'recebido',
        valor: conta.valor,
        parcela: conta.parcela,
        valorRecebido: conta.valorRecebido,
        convenioId: conta.convenioId,
      };
      const resp = await axios.patch(
        `${this.urlBase}ContasReceber/Cartao`,
        bodyConta,
        {
          headers: this.headers,
        }
      );
      return resp;
    },
    async confirmarRecebimentoConvenio(conta) {
      let bodyConta = {
        id: conta.id,
        data: moment(conta.data, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        consultaId: conta.consultaId,
        situacao: 'recebido',
        convenio: conta.convenio,
        exames: conta.exames,
        valorRecebido: conta.valorRecebido,
        valorEsperado: conta.valorEsperado,
        convenioId: conta.convenioId,
      };
      const resp = await axios.patch(
        `${this.urlBase}ContasReceber/Cartao`,
        bodyConta,
        {
          headers: this.headers,
        }
      );
      return resp;
    },    async getConsultaPagamentos(
      context,
      dataInicial,
      dataFinal,
      medicoId,
      convenioId,
      formaPagamento
    ) {
      const dataInicialFormatada = moment(dataInicial).format('YYYY-MM-DD');
      const dataFinalFormatada = moment(dataFinal).format('YYYY-MM-DD');
      context.$store.commit(
        'Financeiro/SET_CONSULTAS_PAGAMENTOS',
        []
      );
      context.$store.commit(
        'Financeiro/SET_VALOR_TOTAL',
        0
      );
      const resp = await axios
        .get(`${this.urlBase}Pagamento/consultasPorPeriodo`, {
          params: {
            dataInicial: dataInicialFormatada,
            dataFinal: dataFinalFormatada,
            medicoId,
            convenioId,
            formaPagamento,
          },
          headers: this.headers,
        })
        .then(response => {
          let consultas = response.data.consultas.map(item => {
            return {
              ...item,
              data: moment(item.data, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            };
          });
          context.$store.commit(
            'Financeiro/SET_CONSULTAS_PAGAMENTOS',
            consultas
          );
          context.$store.commit(
            'Financeiro/SET_VALOR_TOTAL',
            response.data.valorTotal
          );
        })
        .catch(() => {
          context.$store.commit('Financeiro/SET_CONSULTAS_PAGAMENTOS', []);
          context.$store.commit('Financeiro/SET_VALOR_TOTAL', 0);
        });
    },
    async getProducao(
      context,
      dataInicial,
      dataFinal,
      medicoId,
      convenioId,
      formaPagamento
    ) {
      const dataInicialFormatada = moment(dataInicial).format('YYYY-MM-DD');
      const dataFinalFormatada = moment(dataFinal).format('YYYY-MM-DD');
      context.$store.commit(
        'Financeiro/SET_CONSULTAS_PAGAMENTOS',
        []
      );
      context.$store.commit(
        'Financeiro/SET_VALOR_TOTAL',
        0
      );
      const resp = await axios
        .get(`${this.urlBase}Pagamento/producao`, {
          params: {
            dataInicial: dataInicialFormatada,
            dataFinal: dataFinalFormatada,
            medicoId,
            convenioId,
            formaPagamento,
          },
          headers: this.headers,
        })
        .then(response => {
          let consultas = response.data.consultas.map(item => {
            return {
              ...item,
              data: moment(item.consulta.data, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            };
          });
          context.$store.commit(
            'Financeiro/SET_PAGAMENTOS_PRODUCAO',
            consultas
          );
          context.$store.commit(
            'Financeiro/SET_VALOR_TOTAL_PROD',
            response.data.valorTotal
          );
        })
        .catch(() => {
          context.$store.commit('Financeiro/SET_PAGAMENTOS_PRODUCAO', []);
          context.$store.commit('Financeiro/SET_VALOR_TOTAL_PROD', 0);
        });
    },

    async getRelatorioDashboard(dataInicial, dataFinal) {
      const resp = await axios.get(
        `${this.urlBase}LivroCaixa/relatorioPorData?dataInicial=${dataInicial}&dataFinal=${dataFinal}`,
        { headers: this.headers }
      );

      return resp;
    },
  },
};
