<template>
    <v-dialog v-model="loading" persistent width="30%">
        <!-- <template #activator="{props}">


        </template> -->
        <v-card>
            <v-card-text>
        <v-row
        justify="center">
        <v-col>
            <h2 class="text-center pa-5 maiuscula">Carregando</h2>
            <v-progress-linear indeterminate></v-progress-linear>
        </v-col>
            
        </v-row>
    </v-card-text>
    </v-card>
    </v-dialog>
  
</template>

<script>
export default {
    props:{
        loading: {type:Boolean, default: false}
    },
    

}
</script>

<style>

</style>