<template lang="">
  <div>
    <LaudoPerfilBiofisicoFetal></LaudoPerfilBiofisicoFetal>
  </div>
</template>
<script>
import LaudoPerfilBiofisicoFetal from '../components/laudos/formularios/exames/perfilBiofisicoFetal/LaudoPerfilBiofisicoFetal.vue';
export default {
  components: {
    LaudoPerfilBiofisicoFetal,
  },
};
</script>
<style lang=""></style>
