import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { EMBRION_API_URL } from "../data/enviroment";

export default {
  data() {
    return {
      urlBase: EMBRION_API_URL,
      appBarLogin: false,
      drawerLogin: false,
    };
  },
  computed: {
    ...mapGetters(["logado", "nivel", "token", "headers", "usuarioLogado"]),
    ...mapGetters('Auth', ['headers']),
  },
  methods: {
    ...mapActions({ setToken: "setToken", setLogado: "setLogado", setNivel: "setNivel", setUsuarioLogado: "setUsuarioLogado" }),

    postLogin(usuario, senha) {
      return axios.post(`${this.urlBase}Login`, { usuario: usuario, senha: senha }).then((resp) => {
        let usuarioLogou = true;
        this.setToken(resp.data.token);
        this.setLogado(usuarioLogou);
        this.setNivel(resp.data.nivelPermissao);
        this.setUsuarioLogado(resp.data.usuario ? resp.data.usuario : resp.data.medico);
      });
    },
    setTokenDefinirSenha(context, token, nome){
      context.$store.commit('Auth/SET_TOKEN_DEFINIR_SENHA', token);
      context.$store.commit('Auth/SET_NOME_DEFINIR_SENHA', nome);
    },
    resetTokenDefinirSenha(context){
      context.$store.commit('Auth/RESET_TOKEN_DEFINIR_SENHA', null);
    },
    async redefinirSenha(email){
      return await axios.post(`${this.urlBase}Login/redifinirSenha?email=${email}`)
    },

    async postUsuario(modelBody){
      
      return await axios.post(`${this.urlBase}Login/novoLogin`, modelBody, {headers: this.headers})
    },
    async postMedico(modelBody){
      
      return await axios.post(`${this.urlBase}Medico`, modelBody, {headers: this.headers})
    },
    async patchSenha(token, senha)
    {
      return await axios.patch(`${this.urlBase}Login/definirSenha?token=${token}&novaSenha=${senha}`, null)

    }
  },
};
