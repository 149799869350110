import AuthEmbrion from '@/services/AuthEmbrion';

const initialState = () => ({
  loading: false,
  mensagem: null,
  modelLogin: {
    usuario: null,
    senha: null,
  },
  usuarioLogado: {
    loginId: null,
    usuario: null,
    medico: null,
    nivelPermissao: null,
    token: null,
    admin: false,
    tokenDefinirSenha: null,
    nomeDefinirSenha: null,
  },
  tokenDefinirSenha: null,
  nomeDefinirSenha: null,
  headers: { Authorization: null },
});

const RESET_ALL = 'RESET_ALL';
const SET_TOKEN = 'SET_TOKEN';
const SET_LOADING = 'SET_LOADING';
const SET_MENSAGEM = 'SET_MENSAGEM';
const SET_LOGIN_MODEL = 'SET_LOGIN_MODEL';
const SET_USUARIO_LOGADO_MODEL = 'SET_USUARIO_LOGADO_MODEL';
const SET_NIVEL_PERMISSAO = 'SET_NIVEL_PERMISSAO';
const SET_TOKEN_DEFINIR_SENHA = 'SET_TOKEN_DEFINIR_SENHA';
const SET_NOME_DEFINIR_SENHA = 'SET_NOME_DEFINIR_SENHA';
const RESET_TOKEN_DEFINIR_SENHA = 'RESET_TOKEN_DEFINIR_SENHA';

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    isLoading: state => state.loading,
    mensagem: state => state.mensagem,
    usuarioLogado: state => state.usuarioLogado,
    eMedico: state => !!state.usuarioLogado.medico, // É um médico?
    estaAutenticado: state => !!state.usuarioLogado.token,
    nivelPermissao: state => state.usuarioLogado.nivelPermissao,
    token: state => state.usuarioLogado.token,
    headers: state => state.headers,
    tokenDefinirSenha: state => state.tokenDefinirSenha,
    nomeDefinirSenha: state => state.nomeDefinirSenha,
    isAdmin: state => state.usuarioLogado.admin,
    tokenDefinirSenha: state => state.tokenDefinirSenha,
    nomeDefinirSenha: state => state.nomeDefinirSenha,
  },
  mutations: {
    [RESET_ALL](state) {
      Object.assign(state, initialState());
    },
    [SET_TOKEN](state, payload) {
      state.headers.Authorization = `Bearer ${payload}`;
    },
    [SET_LOADING](state, payload) {
      state.loading = payload;
    },
    [SET_MENSAGEM](state, payload) {
      state.mensagem = payload;
    },
    [SET_LOGIN_MODEL](state, payload) {
      state.modelLogin = { ...state.modelLogin, ...payload };
    },
    [RESET_TOKEN_DEFINIR_SENHA](state, payload) {
      state.tokenDefinirSenha = null;
      state.nomeDefinirSenha = null;
    },
    [SET_NOME_DEFINIR_SENHA](state, payload) {
      state.nomeDefinirSenha = payload;
    },
    [SET_TOKEN_DEFINIR_SENHA](state, payload) {
      state.tokenDefinirSenha = payload;
    },
    [SET_USUARIO_LOGADO_MODEL](state, payload) {
      state.usuarioLogado = { ...state.usuarioLogado, ...payload };
    },
    [SET_NIVEL_PERMISSAO](state, payload) {
      state.usuarioLogado.nivelPermissao = payload;
      state.usuarioLogado.admin = state.usuarioLogado.admin
        ? true
        : payload == 5
        ? true
        : false;
    },
  },
  actions: {
    async login({ commit, state }) {
      commit(SET_LOADING, true); // Inicial loading
      commit(SET_MENSAGEM, null); // Reset mensagem

      const { data } = await AuthEmbrion.login(state.modelLogin);

      if (data.mensagem) {
        commit(SET_MENSAGEM, data.mensagem);
        commit(SET_LOADING, false);
        return;
      }

      data.admin = data.nivelPermissao == 5;
      // Salvar dados do usuário logado
      commit(SET_USUARIO_LOGADO_MODEL, data);
      commit(SET_TOKEN, data.token);

      // Reset valor do form de login (Segurança)
      commit(SET_LOGIN_MODEL, { ...initialState.modelLogin });

      commit(SET_LOADING, false);
    },
  },
};
