<template lang="">
  <div>
    <v-container fluid style="max-width: 95%" class="px-0">
      <v-card class="borda ma-5" elevation="3">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Busque por paciente ou cpf"
            single-line
            hide-details
          ></v-text-field>
          <v-btn class="mx-2" text plain large router :to="'cadastrar-paciente'">
            <v-icon>mdi-account-plus-outline </v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          class="clique maiuscula cursor"
          :headers="headers"
          :loading='loading'
          loading-text="Carregando lista de pacientes"
          :items="listaPacientes"
          :search="search"
          no-data-text="Nenhum paciente cadastrado"
          @click:row="handleClick"
        >
          <router-view></router-view>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import PacienteMixin from "../../mixins/PacienteMixin";
export default {
  name: "ListaDePacientes",
  mixins: [PacienteMixin],
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        {
          text: "Nome",
          align: "start",
          filterable: true,
          value: "nome",
        },
        { text: "CPF", value: "cpf" },
      ],
    };
  },

  methods: {
    // redireciona o paciente selecionado para a pagina do paciente
    handleClick(event) {
      this.$router.push(`/paciente/?id=${event.id}`);
    },
  },
  async created() {
  
    try{
      this.loading= true
     
      await this.getPacientes()

    }finally{
    
    this.loading= false;
    
    }

  },
};
</script>
<style lang=""></style>
