<template>

    <v-dialog v-model="show" transition="dialog-top-transition" width="40%">
  
      <v-card class="px-4 py-4">
        <span class="d-flex justify-end">
                  <v-icon class="pa-3" @click="close">
                    mdi-close-circle-outline
                  </v-icon>
                </span>
        <h1 class="text-center">Confirmação do recebimento</h1>
        <br>
        <v-col cols="4" md="8" class="ml-16">
                <span><strong>Data: </strong>{{ valoresAReceber.data }}</span><br>
                <span><strong>Valor: </strong>{{ valoresAReceber.valor }}</span>
                <v-text-field
             
              class="maiuscula mt-4"
              label="Valor recebido"
              type="number"
             
              validate-on-blur
              filled
              outlined
              :clearable="false"
             
              v-model="valoresAReceber.valorRecebido"
              
            />
            </v-col>
            {{ !!valoresAReceber.bandeira }} bandeira
  
            
              <v-row>
              <v-spacer></v-spacer>
              <v-btn  @click="confirmado(valoresAReceber)">Confirmar recebimento</v-btn>
              <v-spacer></v-spacer>
          </v-row>
          <br>
      </v-card>
    </v-dialog>
  
  </template>
  
  <script>
  import axios from 'axios';
  import PagamentosMixin from '@mixins/PagamentosMixin';
  import { mapGetters } from 'vuex';
  export default {
    mixins: [
    PagamentosMixin
      ],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
  
    }),
    computed:{
        ...mapGetters([
      'contaSelecionada',
      

    ]),
    valoresAReceber() {
      return this.$store.getters['Financeiro/contaSelecionada'] || {};
    },

    },
  
    methods: {
        async confirmado(conta){
          if(!!this.valoresAReceber.bandeira){
            await this.confirmarRecebimentoCartao(conta).then(()=>{
          this.close()

        }).catch(()=>{
            alert("Erro ao confirmar recebimento")
            this.close()
        })
          }else{
            await this.confirmarRecebimentoConvenio(conta).then(()=>{
          this.close()

        }).catch(()=>{
            alert("Erro ao confirmar recebimento")
            this.close()
        })

          }
        

        },
      close() {
        this.$emit('close');
  
      },
      
  
  
    },
  };
  </script>
  
  <style>
  
  </style>
  