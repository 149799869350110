<template lang="">
  <div>
    <LaudoConsultaGinecologica></LaudoConsultaGinecologica>
  </div>
</template>
<script>
import LaudoConsultaGinecologica from '../components/laudos/formularios/exames/consultaGinecologica/LaudoConsultaGinecologica.vue';
export default {
  components: {
    LaudoConsultaGinecologica,
  },
};
</script>
<style lang=""></style>
