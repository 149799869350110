<template>
  <section class="mt-2">
    <h3>Perfil Cervical:</h3>

    <v-row class="pt-2" no-gutters>
      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Colo uterino</span>
        <v-select
          :items="['fechado', 'entreaberto']"
          class="mx-2 flex-grow-0"
          required
          dense
          validate-on-blur
          v-model="laudo.coloUterino"
        />
        <span>, medindo</span>
        <v-text-field
          class="mx-2 flex-grow-0"
          v-mask="'#,#'"
          required
          dense
          validate-on-blur
          v-model="laudo.coloMedidaEmRepouco"
        >
        </v-text-field>
        <span>cm em repouso.</span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Após três minutos de avaliação, o colo</span>
        <v-select
          class="mx-2 flex-grow-0"
          :items="['passou a medir', 'continuou a medir']"
          dense
          required
          clearable
          validate-on-blur
          v-model="laudo.coloPassouAMedir"
        ></v-select>
        <v-text-field
          class="mx-2 flex-grow-0"
          required
          dense
          validate-on-blur
          v-mask="'#,#'"
          v-model="laudo.cm"
        ></v-text-field>
        <span>cm.</span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Orifício cervical interno</span>
        <v-select
          class="mx-2 flex-grow-0"
          :items="['fechado', 'aberto para']"
          required
          dense
          validate-on-blur
          v-model="laudo.orificioCervicalInterno"
        ></v-select>

        <v-text-field
          class="mx-2 flex-grow-0"
          v-if="laudo.orificioCervicalInterno == 'aberto para'"
          required
          dense
          validate-on-blur
          v-mask="'#,#'"
          v-model="laudo.orificioCervicalInternoDois"
        ></v-text-field>
        <span v-if="laudo.orificioCervicalInterno == 'aberto para'"> cm. </span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <v-select
          class="mx-2 flex-grow-0"
          :items="['Ausência', 'Presença']"
          required
          dense
          validate-on-blur
          v-model="laudo.protusaoBolsaAmniotica"
        ></v-select>
        <span>de protrusão da bolsa amniótica no momento do exame.</span>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <span>Sinal de sludge</span>
        <v-select
          class="mx-2 flex-grow-0"
          :items="['presente', 'ausente']"
          required
          dense
          validate-on-blur
          v-model="laudo.sinalDeSludge"
        ></v-select>
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center">
        <v-select
          class="mx-2 flex-grow-0"
          :items="[
            'Observamos pólipo endocervical medindo',
            '-------------------------------------',
          ]"
          required
          dense
          validate-on-blur
          v-model="laudo.polipoEndocervicalMedindo"
        ></v-select>

        <v-text-field
          class="mx-2 flex-grow-0"
          v-if="
            laudo.polipoEndocervicalMedindo ==
            'Observamos pólipo endocervical medindo'
          "
          required
          dense
          validate-on-blur
          v-mask="'#,#'"
          v-model="laudo.polipoEndocervicalMedindoCm"
        ></v-text-field>

        <span
          v-if="
            laudo.polipoEndocervicalMedindo ==
            'Observamos pólipo endocervical medindo'
          "
        >
          cm
        </span>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import LaudosMixin from '@mixins/LaudosMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';

// props, computed, watch
export default {
  mixins: [LaudosMixin],
  props: {
    laudoIndex: {
      type: Number,
      required: true,
    },
  },
  components: { FormInfoLaudo },
  computed: {
    laudo() {
      return this.$store.state.Laudo.laudos[this.laudoIndex];
    },
  },
  watch: {
    laudo: {
      handler(value) {
        this.$store.commit('Laudo/SET_LAUDO_BY_INDEX', {
          index: this.laudoIndex,
          model: this.laudo,
        });
      },
      deep: true,
    },
  },
};
</script>
