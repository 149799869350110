<template>
  <v-container fluid>
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">PERFIL CERVICAL</h2>
    </v-card>
    <v-alert v-if="!!laudoSelecionado.laudoJson.folhas" outlined type="warning" border="left" text="">
      Esse <strong>laudo</strong> teve a impressão
      <strong>personalizada.</strong>
      <v-btn text @click="adcionarConteudoEditorPersonalizado">Clique aqui para visualizar</v-btn>
    </v-alert>

    <v-row class="pt-5" no-gutters>
      <template v-if="clicouNoPersonalizado">
        <v-col cols="12" class="form-group" v-for="(index, folha) in quantidadeFolhasEditorPersonalizado" :key="index">
          <h2>Folha {{ folha + 1 }}</h2>
          <vue-editor v-model="conteudoEditorPersonalizado[folha]" />
        </v-col>

        <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
      </template>

      <template v-else>
        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeColoUterino ? '' : 'text--disabled'
          }`">
          <span>Colo uterino</span>
          <v-select :clearable="podeEditar" required :items="['fechado', 'entreaberto', 'aberto']" validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloUterino" :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exibeColoUterino" dense class="mx-2 flex-grow-0"></v-select>
          <span>, com menor comprimento de  </span>
          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar" required class="mx-2 flex-grow-0" dense
            :disabled="!camposExibidos.exibeColoUterino" v-mask="''" validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloMedidaEmRepouco">
          </v-text-field>
          <span>mm (colo avaliado por três minutos).</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeColoUterino" @click="
            camposExibidos.exibeColoUterino = true;
          laudoSelecionado.laudoJson.coloUterino = undefined;
          laudoSelecionado.laudoJson.coloMedidaEmRepouco = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeColoUterino = false;
          laudoSelecionado.laudoJson.coloUterino = undefined;
          laudoSelecionado.laudoJson.coloMedidaEmRepouco = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <!-- <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeColoPassouAMedir ? '' : 'text--disabled'
          }`">
          <span>Após três minutos de avaliação, o colo</span>
          <v-select :readonly="carregamentoDoLaudo || !podeEditar" required :clearable="podeEditar"
            :items="['passou a medir', 'continuou a medir']" validate-on-blur
            v-model="laudoSelecionado.laudoJson.coloPassouAMedir" class="mx-2 flex-grow-0" dense
            :disabled="!camposExibidos.exibeColoPassouAMedir"></v-select>

          <v-text-field :readonly="carregamentoDoLaudo || !podeEditar" required validate-on-blur v-mask="''"
            v-model="laudoSelecionado.laudoJson.cmdois" class="mx-2 flex-grow-0" dense
            :disabled="!camposExibidos.exibeColoPassouAMedir"></v-text-field>
          <span>cm.</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeColoPassouAMedir" @click="
            camposExibidos.exibeColoPassouAMedir = true;
          laudoSelecionado.laudoJson.coloPassouAMedir = undefined;
          laudoSelecionado.laudoJson.cmdois = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeColoPassouAMedir = false;
          laudoSelecionado.laudoJson.coloPassouAMedir = undefined;
          laudoSelecionado.laudoJson.cmdois = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col> -->

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeOrificioCervicalInterno ? '' : 'text--disabled'
          }`">
          <span>Orifício cervical interno</span>
          <v-select :readonly="carregamentoDoLaudo || !podeEditar" :items="['fechado', 'aberto']" required
            :clearable="podeEditar" validate-on-blur v-model="laudoSelecionado.laudoJson.orificioCervicalInterno"
            class="mx-2 flex-grow-0" dense :disabled="!camposExibidos.exibeOrificioCervicalInterno"></v-select>

          <span v-if="laudoSelecionado.laudoJson.orificioCervicalInterno == 'aberto'
            ">
            com
          </span>

          <v-text-field v-if="laudoSelecionado.laudoJson.orificioCervicalInterno == 'aberto'
            " :readonly="carregamentoDoLaudo || !podeEditar" required validate-on-blur v-mask="''"
            v-model="laudoSelecionado.laudoJson.orificioCervicalInterno2" class="mx-2 flex-grow-0" dense
            :disabled="!camposExibidos.exibeOrificioCervicalInterno"></v-text-field>
          <span v-if="laudoSelecionado.laudoJson.orificioCervicalInterno == 'aberto'
            ">
            mm
          </span>
          <span>.</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeOrificioCervicalInterno" @click="
            camposExibidos.exibeOrificioCervicalInterno = true;
          laudoSelecionado.laudoJson.orificioCervicalInterno = undefined;
          laudoSelecionado.laudoJson.orificioCervicalInterno2 = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeOrificioCervicalInterno = false;
          laudoSelecionado.laudoJson.orificioCervicalInterno = undefined;
          laudoSelecionado.laudoJson.orificioCervicalInterno2 = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeProtusaoBolsaAmniotica ? '' : 'text--disabled'
          }`">
          <v-select :readonly="carregamentoDoLaudo || !podeEditar" :items="['Ausência', 'Presença']"
            :clearable="podeEditar" required validate-on-blur v-model="laudoSelecionado.laudoJson.protusaoBolsaAmniotica"
            class="mx-2 flex-grow-0" dense :disabled="!camposExibidos.exibeProtusaoBolsaAmniotica"></v-select>
          <span>de protrusão da bolsa amniótica no momento do exame.</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeProtusaoBolsaAmniotica" @click="
            camposExibidos.exibeProtusaoBolsaAmniotica = true;
          laudoSelecionado.laudoJson.protusaoBolsaAmniotica = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeProtusaoBolsaAmniotica = false;
          laudoSelecionado.laudoJson.protusaoBolsaAmniotica = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeSinalDeSludge ? '' : 'text--disabled'
          }`">
          <span>Sinal de sludge</span>
          <v-select :readonly="carregamentoDoLaudo || !podeEditar" :items="['presente', 'ausente']" required
            :clearable="podeEditar" validate-on-blur v-model="laudoSelecionado.laudoJson.sinalDeSludge"
            class="mx-2 flex-grow-0" dense :disabled="!camposExibidos.exibeSinalDeSludge"></v-select>
          <span>.</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeSinalDeSludge" @click="
            camposExibidos.exibeSinalDeSludge = true;
          laudoSelecionado.laudoJson.sinalDeSludge = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeSinalDeSludge = false;
          laudoSelecionado.laudoJson.sinalDeSludge = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibePolipoEndocervicalMedindo
          ? ''
          : 'text--disabled'
          }`">
          <v-select :readonly="carregamentoDoLaudo || !podeEditar" :items="['Observamos pólipo endocervical medindo']"
            required :clearable="podeEditar" validate-on-blur
            v-model="laudoSelecionado.laudoJson.polipoEndocervicalMedindo" class="mx-2 flex-grow-0" dense
            :disabled="!camposExibidos.exibePolipoEndocervicalMedindo"></v-select>

          <v-text-field v-if="laudoSelecionado.laudoJson.polipoEndocervicalMedindo ==
            'Observamos pólipo endocervical medindo'
            " :readonly="carregamentoDoLaudo || !podeEditar" required validate-on-blur v-mask="''"
            v-model="laudoSelecionado.laudoJson.polipoEndocervicalMedindoCm" class="mx-2 flex-grow-0" dense
            :disabled="!camposExibidos.exibePolipoEndocervicalMedindo"></v-text-field>
          <span v-if="laudoSelecionado.laudoJson.polipoEndocervicalMedindo ==
            'Observamos pólipo endocervical medindo'
            ">
            mm
          </span>
          <span>.</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibePolipoEndocervicalMedindo" @click="
            camposExibidos.exibePolipoEndocervicalMedindo = true;
          laudoSelecionado.laudoJson.polipoEndocervicalMedindoCm =
            undefined;
          laudoSelecionado.laudoJson.polipoEndocervicalMedindo = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibePolipoEndocervicalMedindo = false;
          laudoSelecionado.laudoJson.polipoEndocervicalMedindoCm =
            undefined;
          laudoSelecionado.laudoJson.polipoEndocervicalMedindo = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <h3>Conclusão</h3>
        </v-col>

        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos.exibeColoUterino2 ? '' : 'text--disabled'
          }`">
          <span>Colo uterino</span>
          <v-select :readonly="carregamentoDoLaudo || !podeEditar" required :clearable="podeEditar" :items="[
            'de aspecto ecográfico habitual para a idade gestacional',
            'entreaberto, porém, com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
            'entreaberto, com menos de 25 mm de comprimento',
            'fechado, com menos de 25 mm de comprimento',
          ]" validate-on-blur v-model="laudoSelecionado.laudoJson.coloUterino2" class="mx-2 flex-grow-0" dense
            :disabled="!camposExibidos.exibeColoUterino2"></v-select>
          <span>.</span>
          <v-btn dense depressed text icon v-if="!camposExibidos.exibeColoUterino2" @click="
            camposExibidos.exibeColoUterino2 = true;
          laudoSelecionado.laudoJson.coloUterino2 = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos.exibeColoUterino2 = false;
          laudoSelecionado.laudoJson.coloUterino2 = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>

        <v-col cols="12" class="d-flex justify-start align-center">
          <v-textarea :readonly="carregamentoDoLaudo || !podeEditar" required validate-on-blur filled outlined no-resize
            label="Comentários adicionais" v-model="laudoSelecionado.laudoJson.comentarioAdcionalPerfilCervical
              ">
          </v-textarea>
        </v-col>
      </template>

      <v-col cols="12" class="d-flex justify-start align-center pt-6">
        <v-btn v-if="podeEditar" color="primary" :loading="carregamentoDoLaudo" @click="() => {
          laudoId ? editarLaudo() : salvarLaudo();
        }
          ">
          salvar laudo
        </v-btn>

        <v-btn color="primary" v-if="podeImprimir && !podeEditar" @click="imprimirLaudo" :loading="loadingImprimir"
          class="ml-5">
          Imprimir
        </v-btn>

        <v-btn color="primary" v-if="!podeEditar" class="ml-5" @click="podeEditar = true">
          Editar
        </v-btn>

        <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
          Cancelar
        </v-btn>
        <v-btn color="primary" v-if="podePersonalizar" @click="adcionarConteudoEditorPersonalizado" class="ml-5">
          {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
        </v-btn>
      </v-col>
    </v-row>
    <component :is="modal.modalComponent" v-bind="{ show: modal.active }" @close="closeModal" />
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    VueEditor,
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      loadingImprimir: false,
      quantidadeFolhasEditorPersonalizado: 0,
      carregamentoDoLaudo: false,
      podeEditar: true,
      laudoId: null,
      voltando: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      camposExibidos: {
        exibeColoUterino: true,
        exibeColoPassouAMedir: true,
        exibeOrificioCervicalInterno: true,
        exibePolipoEndocervicalMedindo: true,
        exibeColoUterino2: true,
        exibeProtusaoBolsaAmniotica: true,
        exibeSinalDeSludge: true,
      },
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 25 mm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    podeImprimir() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudoSelecionado.laudoJson.folhas) {
        this.conteudoEditorPersonalizado =
          this.laudoSelecionado.laudoJson.folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }

      let todos = [];

      this.conteudoEditorPersonalizado = [];
      if (
        this.camposExibidos.exibeColoUterino &&
        this.laudoSelecionado.laudoJson.coloUterino
      ) {
        todos.push(
          `Colo uterino ${this.laudoSelecionado.laudoJson.coloUterino}${this.laudoSelecionado.laudoJson.coloMedidaEmRepouco
            ? ', com menor comprimento de ' +
            this.laudoSelecionado.laudoJson.coloMedidaEmRepouco +
            ' mm (colo avaliado por três minutos).'
            : ''
          }.\n`
        );
      }
      // if (
      //   this.camposExibidos.exibeColoPassouAMedir &&
      //   this.laudoSelecionado.laudoJson.coloPassouAMedir
      // ) {
      //   todos.push(
      //     `Após três minutos de avaliação, o colo ${this.laudoSelecionado.laudoJson.coloPassouAMedir
      //     }${this.laudoSelecionado.laudoJson.cmdois
      //       ? ' ' + this.laudoSelecionado.laudoJson.cmdois
      //       : ''
      //     } cm.\n`
      //   );
      // }

      if (
        this.camposExibidos.exibeOrificioCervicalInterno &&
        this.laudoSelecionado.laudoJson.orificioCervicalInterno
      ) {
        todos.push(
          `Orifício cervical interno ${this.laudoSelecionado.laudoJson.orificioCervicalInterno
          }${this.laudoSelecionado.laudoJson.orificioCervicalInterno === 'aberto'
            ? ` com ${this.laudoSelecionado.laudoJson.orificioCervicalInterno2} mm`
            : ''
          }.\n`
        );
      }
      if (
        this.camposExibidos.exibeProtusaoBolsaAmniotica &&
        this.laudoSelecionado.laudoJson.protusaoBolsaAmniotica
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.protusaoBolsaAmniotica} de protrusão da bolsa amniótica no momento do exame.\n`
        );
      }
      if (
        this.camposExibidos.exibeSinalDeSludge &&
        this.laudoSelecionado.laudoJson.sinalDeSludge
      ) {
        todos.push(
          `Sinal de sludge ${this.laudoSelecionado.laudoJson.sinalDeSludge}.\n`
        );
      }

      if (
        this.camposExibidos.exibePolipoEndocervicalMedindo &&
        this.laudoSelecionado.laudoJson.polipoEndocervicalMedindo
      ) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.polipoEndocervicalMedindo}${this.laudoSelecionado.laudoJson.polipoEndocervicalMedindo ===
            'Observamos pólipo endocervical medindo'
            ? ` ${this.laudoSelecionado.laudoJson.polipoEndocervicalMedindoCm} mm`
            : ''
          }.\n`
        );
      }

      todos.push(`\n<h4>Conclusão</h4>\n`);
      if (
        this.camposExibidos.exibeColoUterino2 &&
        this.laudoSelecionado.laudoJson.coloUterino2
      ) {
        todos.push(
          `Colo uterino ${this.laudoSelecionado.laudoJson.coloUterino2}.\n`
        );
      }
      if (this.laudoSelecionado.laudoJson.comentarioAdcionalPerfilCervical) {
        todos.push(
          `${this.laudoSelecionado.laudoJson.comentarioAdcionalPerfilCervical}\n`
        );
      }

      while (todos.length > 0) {
        const teste = todos.splice(0, 22);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },
    forceReRender() {
      this.componentKey++;
    },
    ...mapGetters('Offline', ['requisicao', 'dados']),

    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        return;
      } finally {
        try {
          this.carregamentoDoLaudo = true;

          const exameId = this.$route.query.exame;

          this.laudoSelecionado.examePorConsultaId = exameId;
          this.consultaSelecionada.status = 'LAUDADO';

          if (navigator.onLine) {
            const { data } = await this.postLaudo();
            this.laudoId = data;

            await this.alterarStatus();

            if (!Number.isInteger(data)) throw new Error(data);

            this.getLaudoByExamePorConsultaId(exameId);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
              requisicaoSalvar: this.postLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'POST_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', {
              laudo: this.laudoSelecionado,
              consulta: this.consultaSelecionada,
            });
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        this.carregamentoDoLaudo = false;
        return;
      } finally {
        this.carregamentoDoLaudo = false;
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            await this.patchLaudo(this.laudoSelecionado);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
            // this.podeImprimir = false;
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    juntaPersonalizadoAoLaudo() {
      this.laudoSelecionado.laudoJson.folhas = this.conteudoEditorPersonalizado;
      this.laudoSelecionado.laudoJson.titulo = 'PERFIL CERVICAL';
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      this.loadingImprimir = true;
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.perfilcervical',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },
    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },
  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;
    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      // this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
