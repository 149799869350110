<template>
  <v-row class="pa-0 ma-0">
    <v-col :cols="cols" :md="md">
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <FormConsulta
            :exibirConsulta="this.exibirConsulta"
            :exibirBotao="this.exibirBotao"
            :exibirMenu="this.exibirMenu"
            @emitterexbbotao="novoValor5 => (exibirBotao = novoValor5)"
            @emitterexbcons="novoValor4 => (exibirConsulta = novoValor4)"
            v-on:emitterexmen="novoValor3 => (exibirMenu = novoValor3)"
          ></FormConsulta>
        </v-col>
        <v-container fluid style="max-width: 95%" class="d-flex justify-center">
          <v-col cols="12" md="5" sm="12" xs="12">
            <v-row v-if="exibirBotaoPagamento" text-align="center">
              <v-col class="pa-0">
                <v-btn
                  block
                  class="pa-md-7 grey darken-1"
                  @click="encaminharParaPagamento"
                  router
                  :to="''"
                >
                  <h2
                    class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
                  >
                    EFETUAR PAGAMENTO
                  </h2>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-container>
      </v-row>
    </v-col>

    <v-col cols="12" md="1" sm="12" class="mt-8" v-if="exibirMenu">
      <v-row>
        <v-col cols="12">
          <v-btn
            class="pa-md-7 pa-2 grey darken-1"
            @click="editarConsulta"
            block
          >
            <h2
              class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
            >
              EDITAR
            </h2>
          </v-btn>
        </v-col>

        <v-col
          v-show="
            consultaSelecionada.status == 'Laudado' ||
            consultaSelecionada.status == 'LAUDADO' ||
            consultaSelecionada.status == 'Atendido' ||
            consultaSelecionada.status == 'ATENDIDO'
          "
        >
          <v-btn block class="pa-md-7 pa-2 grey darken-1">
            <h2
              class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
            >
              HISTÓRICO CONSULTAS
            </h2>
          </v-btn>
        </v-col>

        <v-col>
          <v-btn block class="pa-md-7 pa-2 grey darken-1">
            <h2
              class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
            >
              WHATSAPP
            </h2>
          </v-btn>
        </v-col>

        <v-col>
          <v-btn block class="pa-md-7 pa-2 grey darken-1" @click="email">
            <h2
              class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
            >
              ENVIAR EMAIL
            </h2>
          </v-btn>
        </v-col>

        <v-col
          v-show="
            consultaSelecionada.status == 'Laudado' ||
            consultaSelecionada.status == 'LAUDADO' ||
            consultaSelecionada.status == 'Atendido' ||
            consultaSelecionada.status == 'ATENDIDO'
          "
        >
          <v-btn block class="pa-md-7 pa-2 grey darken-1" @click="getRecibo">
            <h2
              class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
            >
              RECIBO
            </h2>
          </v-btn>
        </v-col>

        <v-col>
          <v-btn
            block
            class="pa-md-7 pa-2 grey darken-1"
            @click="getDeclaracao"
          >
            <h2
              class="grey--text text--lighten-4 text-center text-button text-md-h6 font-weight-bold"
            >
              DECLARAÇÃO
            </h2>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FormConsulta from '../consultas/formularios/FormConsulta.vue';
import ConsultasMixin from '@mixins/ConsultasMixin';
import PacienteMixin from '@mixins/PacienteMixin';

export default {
  mixins: [ConsultasMixin, PacienteMixin],
  components: { FormConsulta },

  data() {
    return {
      exibirMenu: true,
      exibirBotaoPagamento: false,
      exibirBotao: this.$route.query.id == undefined,
      exibirConsulta: true,
      cols: '9',
      md: '9',
      formasPagamento: [],
    };
  },
  watch: {
    exibirMenu(val) {
      if (this.exibirMenu) {
        this.cols = 9;
        this.md = 9;
      }
    },
  },

  methods: {
    editarConsulta() {
      this.exibirMenu = false;
      this.exibirConsulta = false;
      this.exibirBotao = true;

      //(this.cols = '12'), (this.md = '12');
    },

    // mudar o tamanho das colunas de acordo com a rota
    currentRouteName() {
      if (this.$route.query.id == undefined) {
        this.exibirMenu = false;
        this.exibirConsulta = false;
        (this.cols = '12'), (this.md = '12');
      }
      if (this.$route.query.pagamento != undefined) {
        this.exibirBotaoPagamento = true;
        this.exibirMenu = false;
        this.cols = '12';
        this.md = '12';
      }
    },
    encaminharParaPagamento() {
      const id = this.$route.query.id;
      this.$router.push(`/efetuar-pagamento?id=${id}`);
    },
    colunas() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          this.cols = 12;
        case 'sm':
          this.cols = 12;
      }
    },
    email() {
      this.$router.push(
        `/email?email=${this.consultaSelecionada.paciente.email}`
      );
    },
    async getConsultaId() {
      const id = this.consultaSelecionada.id;
      return await this.getConsultaById(id).then(
        () => (this.qntExamesOriginal = this.consultaSelecionada.exames.length)
      );
    },
  },
  async mounted() {
    this.currentRouteName();
    this.colunas();

    // this.consultaSelecionada.formaDePagamento =
    //   this.consultaSelecionada.exames[0].consulta.pagamento.formaDePagamento;
    // this.consultaSelecionada.convenio =
    //   this.consultaSelecionada.exames[0].valorExameConvenio.convenio.nome;
  },
  beforeDestroy() {
    this.resetaConsultaSelecionada();
    this.resetaPacienteSelecionado();
  },
};
</script>
