import PagamentoEmbrion from '@/services/PagamentoEmbrion';
import ExameEmbrion from '@/services/ExameEmbrion';

const initialState = () => ({
  pagamentoSelecionado: {},
  pagamentos: [],
});

const SET_PAGAMENTOS = 'SET_PAGAMENTOS';

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    pagamentos: state => state.pagamentos,
  },
  mutations: {
    [SET_PAGAMENTOS](state, payload) {
      state.pagamentos = [...payload];
    },
  },
  actions: {
    async getPagamentosPorData({ commit, rootState }, dataPagamento) {
      const { token } = rootState.Auth.usuarioLogado;

      const { data } = await PagamentoEmbrion.getPagamentosPorData({
        token,
        data: dataPagamento,
      });

      // FIXME: mock
      const pagamentosComExames = [...data];
      // const pagamentosComExames = [];

      // for (const pagamento of data) {
      //   const response = await ExameEmbrion.getExamePorConsulta({
      //     token,
      //     consultaID: pagamento.consulta.id,
      //   });

      //   pagamentosComExames.push({ ...pagamento, exames: response.data });
      // }

      commit(SET_PAGAMENTOS, pagamentosComExames);
    },
  },
};
