import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';
import moment from 'moment';
import { EMBRION_API_URL } from '../data/enviroment';

import modules from './modules';

Vue.use(Vuex);

const urlBase = EMBRION_API_URL;

// módulo Pacientes
const pacientes = {
  state: {
    pacientes: [] /* todos os pacientes cadastrados */,
    pacienteSelecionado: {},
    medicoSelecionadoId: null,
  },
  mutations: {
    setMedicoSelecionadoId(state, id) {
      state.medicoSelecionadoId = id;
    },
    getPacientesMutation(state, payload) {
      state.pacientes = payload;
    },
    pacienteSelecionadoMutation(state, payload) {
      state.pacienteSelecionado = payload;
    },
    resetaPacienteSelecionadoMutation(state) {
      state.pacienteSelecionado = {};
    },
    setPacienteByNomeMutation(state, payload) {
      state.pacienteSelecionado = payload;
    },
  },
  actions: {
    // get de todos os pacientes
    getPacientes({ commit, rootState }) {
      return axios
        .get(`${urlBase}Paciente`, { headers: rootState.Auth.headers })
        .then(resp => {
          let lista = resp.data.map(p => ({
            ...p,
            nome: p.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            nomeAntes: p.nome,
          }));

          commit('getPacientesMutation', lista);
        });
    },
    // busca o paciente pelo id
    getPacienteById({ commit, rootState }, id) {
      return axios
        .get(`${urlBase}Paciente/byId?Id=${id}`, {
          headers: rootState.Auth.headers,
        })
        .then(resp => {
          commit('pacienteSelecionadoMutation', resp.data);
        });
    },

    setPacienteSelecionado({ commit }, paciente) {
      commit('setPacienteByNomeMutation', paciente);
    },

    resetaPacienteSelecionado({ commit }) {
      commit('resetaPacienteSelecionadoMutation');
    },
  },
  getters: {
    listaPacientes: state => state.pacientes, // lista de todos os pacientes cadastrados
    pacienteById: state => state.pacienteSelecionado, // paciente selecionado
  },
};

// módulo de consultas
const consultas = {
  state: {
    consultas: [], // todas as consultas cadastradas
    convenios: [] /* todos os convenios */,
    exames: [] /* todos os exames */,
    convenioSelecionado: {} /* convenio selecionado */,
    exameSelecionado: {} /* exame selecionado */,
    nomesPacientesPorDia: [],
    consultaSelecionada: {
      /* consulta selecionada */ status: '',
      paciente: {},
      medicoExecutante: {},
      medicoSolicitante: {},
      exames: [
        {
          valorExameConvenio: {
            exame: {
              nome: '',
            },
            convenio: {
              nome: '',
            },
          },
        },
      ],
    },
    consultasSelecionadas:
      [] /* consultas do dia selecionadas pela data no calendário */,
  },
  mutations: {
    getConsultasMutation(state, payload) {
      state.consultas = payload;
    },
    consultaSelecionadaMutation(state, payload) {
      state.consultaSelecionada = payload;
    },
    consultasSelecionadasMutation(state, payload) {
      state.consultasSelecionadas = payload;
    },
    nomesPacientesPorDiaMutation(state, payload){
      state.nomesPacientesPorDia = payload
    },

    setMedicoExecutanteConsultaMutation(state, payload) {
      state.consultaSelecionada.medicoExecutante = payload;
    },

    getValorConsultaMutation(state, payload) {
      state.consultaSelecionada.valorExameConvenio = payload;
      state.consultaSelecionada.valorExameConvenioId = payload.id;
    },

    getConveniosMutation(state, payload) {
      state.convenios = payload;
    },

    getExamesMutation(state, payload) {
      state.exames = payload;
    },

    convenioSelecionadoMutation(state, payload) {
      state.convenioSelecionado = payload;
    },

    exameSelecionadoMutation(state, payload) {
      state.exameSelecionado = payload;
    },

    resetaConsultaSelecionadaMutation(state) {
      state.consultaSelecionada = {
        status: 'AUSENTE',
        paciente: {},
        medicoExecutante: {},
        medicoSolicitante: {},
        exames: [
          {
            valorExameConvenio: {
              exame: {
                nome: '',
              },
              convenio: {
                nome: '',
              },
            },
          },
        ],
      };
    },

    resetaConsultasSelecionadasMutation(state) {
      state.consultasSelecionadas = [];
    },
  },
  actions: {
    // get de todas as consultas
    async getConsultas({ commit, rootState }) {
      await axios
        .get(`${EMBRION_API_URL}Consulta`, { headers: rootState.Auth.headers })
        .then(resp => {
          commit('getConsultasMutation', resp.data);

          this.consultas = resp.data;
        });
      return this.consultas;
    },

    // busca consulta pelo ID
    async getConsultaById({ commit, rootState }, id) {
      
      try {
        let resp = await axios.get(`${EMBRION_API_URL}Consulta/byId?Id=${id}`, {
          headers: rootState.Auth.headers,
        });
        let dataFomatada = moment(resp.data.data,'YYYY/MM/DD' ).format("DD/MM/YYYY")
        const consulta = resp.data;
        consulta.data = dataFomatada
        
        
        
        commit('consultaSelecionadaMutation', consulta);
        return resp
        

      } catch {
        commit('consultaSelecionadaMutation', []);
      }
    },

    // busca consulta pelo id do médico
    getConsultaByMedicoId({ commit, rootState }, id) {
      axios
        .get(`${urlBase}Consulta/byMedicoId?medicoId=${id}`, {
          headers: rootState.Auth.headers,
        })
        .then(resp => {
          commit('consultasSelecionadasMutation', resp.data);
        });
    },

    // busca consulta pela data

    async getConsultaByData({ commit, rootState }, data) {
      let resp;
      try {
        resp = await axios.get(`${urlBase}Consulta/byData?data=${data}`, {
          headers: rootState.Auth.headers,
        });
        const consultas = resp.data;
        // await Promise.all(
        //   consultas.map(async consulta => {
        //     resp = await axios.get(
        //       `${urlBase}ExamePorConsulta?consultaId=${consulta.id}`,
        //       { headers: rootState.Auth.headers }
        //     );
        //     consulta.exames = resp.data;

        //   })
        // );
        commit('consultasSelecionadasMutation', []);
        commit('consultasSelecionadasMutation', consultas);
      } catch {
        commit('consultasSelecionadasMutation', []);
      } finally {
        return resp;
      }
    },

    // busca consulta por data e médico
    async getConsultaByDataMedico({ commit, rootState }, { medicoId, data }) {
      let dataFomatada = moment(data, "DD/MM/YYYY").format('YYYY/MM/DD')
      try {
        let resp = await axios.get(
          `${urlBase}Consulta/byDataMedicoId?Data=${dataFomatada}&medicoId=${medicoId}`,
          { headers: rootState.Auth.headers }
        );
        const consultas = resp.data;
        let nomesPacientes = [
          ...new Set(resp.data.map(p => p.paciente.nome)),
        ];
        commit('nomesPacientesPorDiaMutation', []);
        commit('nomesPacientesPorDiaMutation', nomesPacientes);

        commit('consultasSelecionadasMutation', []);
        commit('consultasSelecionadasMutation', consultas);
      } catch (e) {
        commit('nomesPacientesPorDiaMutation', []);
        commit('consultasSelecionadasMutation', []);
      }
    },

    // busca consulta pelo id do paciente
    getConsultaByPacienteId({ commit, rootState }, id) {
      axios
        .get(`${urlBase}Consulta/byPaciente?pacienteId=${id}`, {
          headers: rootState.Auth.headers,
        })
        .then(resp => {
          commit('consultasSelecionadasMutation', resp.data);
        });
    },

    // busca valorExameConvenio pelo conveio e pelo exame
    getValorConsulta({ commit, rootState }, { convenio, exame }) {
      return axios.get(
        `${urlBase}ValorExameConvenio/ByExameAndConvenio?exameid=${exame}&convenioId=${convenio}`,
        { headers: rootState.Auth.headers }
      );
    },

    // get de todos os convenios
    getConvenios({ commit, rootState }) {
      return axios
        .get(`${urlBase}Convenio`, { headers: rootState.Auth.headers })
        .then(resp => {
          commit('getConveniosMutation', resp.data);
        });
    },

    // get de todos os exames
    getExames({ commit, rootState }) {
      return axios
        .get(`${urlBase}Exame`, { headers: rootState.Auth.headers })
        .then(resp => {
          commit('getExamesMutation', resp.data);
        });
    },

    // seta o médico executante da consulta
    setMedicoExecutanteConsulta({ commit }, medico) {
      commit('setMedicoExecutanteConsultaMutation', medico);
    },

    setConsultasSelecionadas({ commit }, consultas) {
      commit('consultasSelecionadasMutation', consultas);
    },

    setConsultaSelecionada({ commit }, consulta) {
      commit('consultaSelecionadaMutation', consulta);
    },

    setConvenioSelecionado({ commit }, consulta) {
      commit('convenioSelecionadoMutation', consulta);
    },

    setExameSelecionado({ commit }, exame) {
      commit('exameSelecionadoMutation', exame);
    },

    resetaConsultaSelecionada({ commit }) {
      commit('resetaConsultaSelecionadaMutation');
    },

    resetaConsultasSelecionadas({ commit }) {
      commit('resetaConsultasSelecionadasMutation');
    },
  },
  getters: {
    listaConsultas: state =>
      state.consultas /* lista de todos as consultas cadastrados  */,
    consultaSelecionada: state =>
      state.consultaSelecionada /* consulta selecionada  */,
    consultasSelecionadas: state =>
      state.consultasSelecionadas /*  consultas selecionadas por dia no calendário */,
    listaConvenios: state =>
      state.convenios /* lista de convenios disponiveis */,
    listaExames: state =>
      state.exames /* lista de todos os exames disponiveis */,
    convenioSelecionado: state =>
      state.convenioSelecionado /* convenio selecionado */,
    exameSelecionado: state => state.exameSelecionado /* exame selecionado  */,
    medicosSolicitante: state => state.medicosSolicitante,
    
    nomesPacientesPorDia: state => state.nomesPacientesPorDia,
  },
};

// módulo de médicos
const medicos = {
  state: {
    medicos: [], // lista de todos os médicos cadastrados
    medicoSelecionado: {}, // médico selecionado
    medicosSolicitante: []
  },
  mutations: {
    getMedicosMutation(state, payload) {
      state.medicos = payload;
    },
    medicoSelecionadoMutation(state, payload) {
      state.medicoSelecionado = payload;
    },
    resetaMedicoSelecionadoMutation(state) {
      state.medicoSelecionado = {};
    },
    setNomesMedicosSolicitantes(state, payload){
      state.medicosSolicitante = payload
    }
    
  },
  actions: {
    // get de todos os médicos
    getMedicos({ commit, rootState }) {
      let nomesMedicosExecutantes = []
      let nomesMedicosSolicitantes = []
      return axios
        .get(`${urlBase}Medico`, { headers: rootState.Auth.headers })
        .then(resp => {
          commit('getMedicosMutation', resp.data);
          for (let med of resp.data) {
            if (med.tipoMedico === "Executante") {
              nomesMedicosExecutantes.push(med.nome);
            } else {
              nomesMedicosSolicitantes.push(med.nome);
            }
          }
          commit('setNomesMedicosSolicitantes', nomesMedicosSolicitantes);
        });
    },

    // busca médico pelo id
    getMedicoById({ commit, rootState }, id) {
      return axios
        .get(`${urlBase}Medico/byId?Id=${id}`, {
          headers: rootState.Auth.headers,
        })
        .then(resp => {
          commit('medicoSelecionadoMutation', resp.data);
        });
    },

    // seta o médico selecionado
    setMedicoSelecionado({ commit }, medico) {
      commit('medicoSelecionadoMutation', medico);
    },

    resetaMedicoSelecionado({ commit }) {
      commit('resetaMedicoSelecionadoMutation');
    },
  },
  getters: {
    listaMedicos: state => state.medicos /* lista de todos os médicos */,
    medicoSelecionado: state =>
      state.medicoSelecionado /* médico selecionado */,
      medicosSolicitantes: state => state.medicosSolicitante
  },
 
};

// módulo de laudos
const laudos = {
  state: {
    // laudo selecionado no momento
    laudoSelecionado: {
      laudoJson: {},
    },
    laudos: [] /* lista de todos os laudos */,
    laudosPorPaciente: [], // lista de laudos por paciente
  },
  mutations: {
    laudoSelecionadoMutation(state, payload) {
      state.laudoSelecionado = payload;
    },

    laudosMutation(state, payload) {
      state.laudos = payload;
    },

    laudosPorPacientes(state, payload) {
      state.laudosPorPaciente = payload;
    },
    laudosPacientesMutation(state, payload) {
      state.laudosPorPaciente = payload;
    },
  },
  actions: {
    // get de todos os laudos
    getLaudos({ commit, rootState }) {
      return axios
        .get(`${urlBase}Laudos`, { headers: rootState.Auth.headers })
        .then(resp => {
          commit('laudosMutation', resp.data);
        });
    },

    // busca o laudo de um exame pelo id do exame por consulta
    async getLaudoByExamePorConsultaId({ commit, rootState }, exameId) {
      return await axios
        .get(
          `${urlBase}Laudos/ByExamePorConsultaId?examePorConsultaId=${exameId}`,
          { headers: rootState.Auth.headers }
        )
        .then(resp => {
          const data = resp.data === '' ? {} : resp.data;
          data.laudoJson = JSON.parse(data.laudo);
          let data2= moment(data.examePorConsulta.consulta.data).format('DD/MM/YYYY')
          data.examePorConsulta.consulta.data = data2
          
          commit('laudoSelecionadoMutation', data);
        })
        .catch(() => {
          commit('laudoSelecionadoMutation', {
            laudoJson: {},
          });
        });
    },

    // busca o laudo pelo id do paciente
    getLaudoPorPaciente({ commit, rootState }, pacienteId) {
      return axios
        .get(`${urlBase}Laudos/ByPacienteId?pacienteId=${pacienteId}`, {
          headers: rootState.Auth.headers,
        })
        .then(resp => {
          // const data = resp.data === "" ? {} : resp.data;
          // data.laudoJson = JSON.parse(data.laudo);
          commit('laudosPacientesMutation', resp.data);
          return resp;
        });
      // .catch(() => {
      //   commit("laudoSelecionadoMutation", {
      //     laudoJson: {}
      //   });
      // });
    },

    setLaudoSelecionada({ commit }, laudo) {
      commit('laudoSelecionadoMutation', laudo);
    },

    resetaLaudoSelecionado({ commit }) {
      commit('laudoSelecionadoMutation', {
        laudoJson: {},
      });
    },
  },
  getters: {
    laudoSelecionado: state => state.laudoSelecionado /* laudo selecionado */,
    laudosPaciente: state => state.laudosPorPaciente /* laudos do paciente */,
  },
};

// módulo calendario
const calendario = {
  state: {
    dataSelecionada: '', // data selecionada no calendário
    datasConsultas: {}, // todas as consultas da data selecionada
  },
  mutations: {
    dataSelecionadaM(state, payload) {
      state.dataSelecionada = payload;
    },
    setDatasConsultasM(state, payload) {
      state.datasConsultas = payload;
    },
  },
  actions: {
    selecionaData({ commit }, payload) {
      commit('dataSelecionadaM', payload);
    },
    setDatasConsultas({ commit, rootState }, mesAno) {
      return axios
        .get(`${urlBase}Consulta/QuantidadeConsultasPorDia?mesAno=${mesAno}`, {
          headers: rootState.Auth.headers,
        })
        .then(resp => {
          commit('setDatasConsultasM', resp.data);
        });
    },
  },
  getters: {
    dataSelecionada: state => state.dataSelecionada,
    datasConsultas: state => state.datasConsultas,
  },
};

// módulo metricas
const metricas = {
  state: {
    metricas: {}, // métricas
    tempoMedio: {}, // tempo médio de espera
  },
  mutations: {
    setMetricasMutation(state, payload) {
      state.metricas = payload;
    },
    setTempoMedioMutation(state, payload) {
      state.tempoMedio = payload;
    },
  },
  actions: {
    // get de todas as métricas
    getMetricas({ commit, rootState }, { data, medicoId }) {
      if (!data) return;
      const dataFomatada = moment(data,'DD/MM/YYYY' ).format('YYYY-MM-DD');
      return axios
        .get(
          `${EMBRION_API_URL}Metricas/NumeroAtendimento?data=${dataFomatada}&medicoExecutanteId=${medicoId}`,
          {
            headers: rootState.Auth.headers,
          }
        )
        .then(resp => {
          commit('setMetricasMutation', resp.data);
        });
    },

    // get tempo médio de espera
    getTempoMedio({ commit, rootState }) {
      // return axios
      //   .get(`${urlBase}Metricas/TempoMedio`, rootState.login.headers)
      //   .then(resp => {
      //     commit('setTempoMedioMutation', resp.data);
      //   });
      return null;
    },
  },
  getters: {
    metricas: state => state.metricas, // metricas
    tempoMedio: state => state.getTempoMedio, // tempo médio de espera
  },
};

// módulo login
const login = {
  state: {
    usuarioLogado: {}, // usuário que está logado
    logado: false,
    nivelPermissao: 1, // nível de permissão para saber qual tela exibir
    token: '', // token para autenticação, esse token é passado em todas as requisições ao backend
    headers: null, // headers para as requisições autenticadas
  },
  mutations: {
    setTokenMutation(state, payload) {
      state.token = payload;
      state.headers = { headers: { Authorization: `Bearer ${payload}` } };
    },

    setLogadoMutation(state, payload) {
      state.logado = payload;
    },

    setNivelMutation(state, payload) {
      state.nivelPermissao = payload;
    },

    setUsuarioLogadoMutation(state, payload) {
      state.usuarioLogado = payload;
    },
  },
  actions: {
    getUsuarioLogado() {},

    setToken({ commit }, token) {
      commit('setTokenMutation', token);
    },

    setLogado({ commit }, logado) {
      commit('setLogadoMutation', logado);
    },

    setNivel({ commit }, nivel) {
      commit('setNivelMutation', nivel);
    },

    setUsuarioLogado({ commit }, usuario) {
      commit('setUsuarioLogadoMutation', usuario);
    },
  },
  getters: {
    logado: state => state.logado,
    nivel: state => state.nivelPermissao,
    token: state => state.token,
    headers: state => state.headers,
    usuarioLogado: state => state.usuarioLogado,
  },
};

const vuexLocal = new VuexPersistence({
  key: 'Embrion',
  storage: window.localStorage,
});

/* todos os módulos são registrados aqui */
export default new Vuex.Store({
  state: {
    medicoSelecionadoId: null,
  },
  modules: {
    ...modules,
    pacientes,
    consultas,
    medicos,
    calendario,
    laudos,
    metricas,
    login,
  },
  plugins: [vuexLocal.plugin],
});
