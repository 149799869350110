<template lang="">
  <div>
    <HomeLaudos></HomeLaudos>
  </div>
</template>
<script>
import HomeLaudos from "../components/laudos/HomeLaudos.vue";
export default {
  components: { HomeLaudos },
};
</script>
<style lang=""></style>
