export default [
  {
    path: 'perfil-cervical',
    name: 'imprecoes.perfilcervical',
    component: () => import('@views/ScreenPerfilCervical.vue'),
  },

  {
    path: 'morfologica-1Trimestre',
    name: 'imprecoes.avaliacaomorfologicadoprimeirotrimestre',
    component: () => import('@/views/ScreenMorfologica1Trimestre.vue'),
  },

  {
    path: 'doppler',
    name: 'imprecoes.doppler',
    component: () => import('@/views/ScreenDoppler.vue'),
  },

  {
    path: 'axila',
    name: 'imprecoes.axila',
    component: () => import('@views/ScreenAxila.vue'),
  },

  {
    path: 'ecocardiodoppler-Fetal',
    name: 'imprecoes.ecocardiodopplerfetal',
    component: () => import('@/views/ScreenEcocardiodopplerFetal.vue'),
  },

  {
    path: 'morfologica',
    name: 'imprecoes.morfologica',
    component: () => import('@/views/ScreenMorfologica.vue'),
  },
  {
    path: 'mama',
    name: 'imprecoes.mama',
    component: () => import('@/views/ScreenMama.vue'),
  },

  {
    path: 'obstetrica1Trimestre',
    name: 'imprecoes.obstetrica1trimestre',
    component: () => import('@/views/ScreenObstetrica1Trimestre.vue'),
  },
  {
    path: 'obstetrica',
    name: 'imprecoes.obstetrica',
    component: () => import('@/views/ScreenObstetrica.vue'),
  },
  {
    path: 'obstetricaDoppler',
    name: 'imprecoes.obstetricaDoppler',
    component: () => import('@/views/ScreenObstetricaDoppler.vue'),
  },
  {
    path: 'obstetricaPerfilCervical',
    name: 'imprecoes.obstetricaPerfilCervical',
    component: () => import('@/views/ScreenObstetricaPerfilCervical.vue'),
  },
  {
    path: 'obstetricaDopplerPerfilCervical',
    name: 'imprecoes.obstetricaDopplerPerfilCervical',
    component: () =>
      import('@/views/ScreenObstetricaDopplerPerfilCervical.vue'),
  },

  {
    path: 'pelvica-transabdominal',
    name: 'imprecoes.pelvicatransabdominal',
    component: () => import('@/views/ScreenPelvicaTransabdominal.vue'),
  },
  {
    path: 'pelvicaTransvaginalDopplerPreparoIntestinal',
    name: 'imprecoes.pelvicatransvaginaldopplerpreparointestinal',
    component: () =>
      import('@/views/ScreenPelvicaTransvaginalDopplerPreparoIntestinal'),
  },

  {
    path: 'translucenciaNucal',
    name: 'imprecoes.translucencianucal',
    component: () => import('@/views/ScreenTranslucenciaNucal.vue'),
  },

  {
    path: 'translucenciaNucalDoppler',
    name: 'imprecoes.translucencianucaldoppler',
    component: () => import('@/views/ScreenTranslucenciaNucalDoppler.vue'),
  },

  {
    path: 'translucenciaNucalPerfilCervical',
    name: 'imprecoes.translucencianucalperfilcervical',
    component: () =>
      import('@/views/ScreenTranslucenciaNucalPerfilCervical.vue'),
  },

  {
    path: 'translucenciaNucalMorfologica1Trimestre',
    name: 'imprecoes.translucencianucalmorfologica1trimestre',
    component: () =>
      import('@/views/ScreenTranslucenciaNucalMorfologica1Trimestre.vue'),
  },

  {
    path: 'translucenciaNucalDopplerPerfilCervical',
    name: 'imprecoes.translucencianucaldopplerperfilcervical',
    component: () =>
      import('@/views/ScreenTranslucenciaNucalDopplerPerfilCervical.vue'),
  },

  {
    path: 'translucenciaNucalDopplerMorfologica1Trimestre',
    name: 'imprecoes.translucencianucaldopplermorfologica1trimestre',
    component: () =>
      import(
        '@/views/ScreenTranslucenciaNucalDopplerMorfologica1Trimestre.vue'
      ),
  },

  {
    path: 'translucenciaNucalPerfilCervicalMorfologica1Trimestre',
    name: 'imprecoes.translucencianucalperfilcervicalmorfologica1trimestre',
    component: () =>
      import(
        '@/views/ScreenTranslucenciaNucalPerfilCervicalMorfologica1Trimestre.vue'
      ),
  },

  {
    path: 'translucenciaNucalPerfilCervicalDopplerMorfologica1Trimestre',
    name: 'imprecoes.translucencianucalperfilcervicaldopplermorfologica1trimestre',
    component: () =>
      import(
        '@/views/ScreenTranslucenciaNucalPerfilCervicalDopplerMorfologica1Trimestre.vue'
      ),
  },

  {
    path: 'transvaginalHisterectomia',
    name: 'imprecoes.transvaginalhisterectomia',
    component: () => import('@/views/ScreenTransvaginalHisterectomia.vue'),
  },
  {
    path: 'pelvicaTransvaginalUtero',
    name: 'imprecoes.pelvicaTransvaginalUtero',
    component: () => import('@/views/ScreenPelvicaTransvaginalUtero.vue'),
  },
  {
    path: 'transvaginalUteroComDoppler',
    name: 'imprecoes.transvaginalUteroComDoppler',
    component: () => import('@/views/ScreenTransvaginalUteroComDoppler.vue'),
  },
  {
    path: 'transvaginalUteroComTridimensional',
    name: 'imprecoes.transvaginalUteroComTridimensional',
    component: () =>
      import('@/views/ScreenTransvaginalUteroComTridimensional.vue'),
  },
  {
    path: 'transvaginalUteroParaAvaliacaoDeReservaOvariana',
    name: 'imprecoes.transvaginalUteroParaAvaliacaoDeReservaOvariana',
    component: () =>
      import(
        '@/views/ScreenTransvaginalUteroParaAvaliacaoDeReservaOvariana.vue'
      ),
  },
  {
    path: 'transvaginalUteroParaPesquisaDeEndometriose',
    name: 'imprecoes.transvaginalUteroParaPesquisaDeEndometriose',
    component: () =>
      import('@/views/ScreenTransvaginalUteroParaPesquisaDeEndometriose.vue'),
  },
  {
    path: 'viloCorial',
    name: 'imprecoes.viloCorial',
    component: () => import('@/views/ScreenViloCorial.vue'),
  },
  {
    path: 'retorno',
    name: 'imprecoes.retorno',
    component: () => import('@/views/ScreenRetorno.vue'),
  },
  {
    path: '3d',
    name: 'imprecoes.3d',
    component: () => import('@/views/Screen3d.vue'),
  },
  {
    path: '4d',
    name: 'imprecoes.4d',
    component: () => import('@/views/Screen4d.vue'),
  },
  {
    path: 'insercaoDeDiuGuiadoPorTransvaginal',
    name: 'imprecoes.insercaodediuguiadoportransvaginal',
    component: () => import('@/views/ScreenInsercaoDeDiuGuiadoPorTransvaginal.vue'),
  },
  {
    path: 'pelvica',
    name: 'imprecoes.pelvica',
    component: () => import('@/views/ScreenPelvica.vue'),
  },
  {
    path: 'neurosonografia',
    name: 'imprecoes.neurosonografia',
    component: () => import('@/views/ScreenNeurosonografia.vue'),
  },
  {
    path: 'amniocentes',
    name: 'imprecoes.amniocentese',
    component: () => import('@/views/ScreenAmniocentese.vue'),
  },
  {
    path: 'perfilBiofisicoFetal',
    name: 'imprecoes.perfilBiofisicoFetal',
    component: () => import('@/views/ScreenPerfilBiofisicoFetal.vue'),
  },
  {
    path: 'colocacaoDoPessario',
    name: 'imprecoes.colocacaoDoPessario',
    component: () => import('@/views/ScreenPessario.vue'),
  },
  {
    path: 'seriadaAvaliacaoOvulacao',
    name: 'imprecoes.seriada',
    component: () => import('@/views/ScreenSeriada.vue'),
  },
  {
    path: 'perfilBiofisicoFetal',
    name: 'imprecoes.perfilBiofisicoFetal',
    component: () => import('@/views/ScreenPerfilBiofisicoFetal.vue'),
  },
  {
    path: 'LaudoMorfologicaComDoppler',
    name: 'imprecoes.morfologicaComDoppler',
    component: () => import('@/views/ScreenMorfologicaComDoppler.vue'),
  },

  {
    path: 'LaudoMorfologicaComCervical',
    name: 'imprecoes.morfologicaComCervical',
    component: () => import('@/views/ScreenMorfologicaComCervical.vue'),
  },
  {
    path: 'LaudoMorfologicaComDopplerCervical',
    name: 'imprecoes.morfologicaComDopplerCervical',
    component: () => import('@/views/ScreenMorfologicaDopplerComCervical.vue'),
  },
  {
    path: 'LaudoPerfilCervicalComDoppler',
    name: 'imprecoes.perfilCervicalComDoppler',
    component: () => import('@/views/ScreenPerfilCervicalComDoppler.vue'),
  },
  {
    path: 'LaudoObstetricaDopplerEcocardiodoppler',
    name: 'imprecoes.obstetricaComDopplerEEcocardiodoppler',
    component: () =>
      import('@/views/ScreenObstetricaComDopplerEEcocardiodoppler.vue'),
  },
  {
    path: 'LaudoPersonalizado',
    name: 'imprecoes.personalizado',
    component: () => import('@/views/ScreenPersonalizado.vue'),
  },
  {
    path: 'LaudoConsultaGinecologica',
    name: 'imprecoes.consultaGinecologica',
    component: () => import('@/views/ScreenConsultaGinecologica.vue'),
  },
];
