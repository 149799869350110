<template>
  <v-dialog v-model="show" transition="dialog-top-transition">

    <v-card class="px-4 py-4">
      <span class="d-flex justify-end">
                <v-icon class="pa-3" @click="close">
                  mdi-close-circle-outline
                </v-icon>
              </span>
      <h1 class="text-center">Pagamento</h1>



      <div class="d-flex flex-column">
        <h3 class="mt-4 ml-n2">PACIENTE:</h3>
        <v-row dense class="mt-3">
          <v-col cols="12">

            <v-text-field label="NOME COMPLETO" class="maiuscula" readonly  dense v-model="consultaSelecionada.paciente.nome"/>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="DATA DE NASCIMENTO"
              readonly
              
              dense
              v-model="consultaSelecionada.paciente.dataNascimento"
            />

          </v-col>
          <v-col cols="4">
            <v-text-field type="tel" label="CPF" readonly  dense v-model="consultaSelecionada.paciente.cpf"/>
          </v-col>
          <v-col>
            <v-text-field type="tel" label="TELEFONE" readonly  dense v-model="consultaSelecionada.paciente.telefonePrincipal" />
          </v-col>
          <v-col cols="8">
            <v-text-field type="email" label="EMAIL" readonly  dense v-model="consultaSelecionada.paciente.email"/>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex flex-column">

        <h3 class="mt-4 ml-n2">CONSULTA:</h3>
        
        <v-row dense class="mt-3">
          <v-col cols="6">
            <v-text-field label="MÉDICO" class="maiuscula" readonly  dense v-model="consultaSelecionada.medicoExecutante.nome" />
          </v-col>
          <v-col cols="6">
            <!-- <v-text-field label="EXAME" readonly  dense v-model="consultaSelecionada.valorExameConvenio.exame.nome"/> -->
          </v-col>
          <v-col cols="6">
            <v-text-field label="DATA" readonly  dense v-model="consultaSelecionada.data"/>
          </v-col>
          <v-col cols="6">
            <v-text-field label="HORÁRIO" type="time" readonly  dense v-model="consultaSelecionada.hora"/>
          </v-col>
          <v-col cols="6" v-if="consultaSelecionada.medicoSolicitante!= null && consultaSelecionada.medicoSolicitante != 'null'">
            <v-text-field label="MÉDICO SOLICITANTE" readonly  dense v-model="consultaSelecionada.medicoSolicitante.nome"/>
          </v-col>
          
          <v-col cols="6">
            <v-text-field label="CONVÊNIO" readonly  dense v-model="consultaSelecionada.exames[0].valorExameConvenio.convenio.nome"/>
          </v-col>
        </v-row>
        
        <h2 class="pb-8 pt-8">PROCEDIMENTOS</h2>
        
        <div>
        <v-row  v-for="(procedimento, indexProc) of procedimentosComp"
          :key="indexProc">
          <v-col cols="12">
            <h3>PROCEDIMENTO {{ indexProc + 1 }}</h3>
          </v-col>
          
       
          
          <v-col cols="12">
        
          <v-row class="mt-1 ml-2" v-for="(teste, indexExame) in procedimentos[indexProc].proc.exames"
          :key="indexExame">
          <v-col cols="10">
          
            
            <v-text-field
                  class="text-center"
                  
                  label="EXAME"
                
                  readonly
                  dense
                  v-model=" teste.valorExameConvenio.exame.nome"
                />
            
          </v-col>
          
          
          </v-row>
        </v-col>
        
        
        

        </v-row>
      </div>
      </div>
      

      <!-- <div class="d-flex justify-end">
        <v-btn @click="openAll">Abrir Todos</v-btn>
        <v-btn @click="closeAll" class="ml-4">Fechar Todos</v-btn>
      </div>

      <v-expansion-panels v-model="panelsOpen" multiple class="mt-4">
        <v-expansion-panel v-for="(exame, index) of exames" :key="index">
          <v-expansion-panel-header>
            EXAME {{ index + 1 }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-column">
              <v-row dense class="mt-3">
                <v-col cols="6">
                  <v-text-field
                    label="MEDICO EXECUTANTE"
                    solo
                    dense
                    v-model="exame.medicoSolicitante"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="CONVÊNIO"
                    solo
                    dense
                    v-model="exame.conveino"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="EXAME"
                    solo
                    dense
                    v-model="exame.exame"
                  />
                </v-col>
              </v-row>

              <div class="d-flex flex-column align-center">
                <span class="text-center">VALOR</span>
                <v-text-field
                  class="text-center"
                  label="Total"
                  type="tel"
                  prefix="R$"
                  solo
                  dense
                  v-model="exame.valor"
                />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->
      <h2 class="pb-8 pt-8">Pagamento</h2>


        <v-row  class="mt-3">
          <v-col cols="12">
          <v-text-field v-if="consultaSelecionada.pagamento.nomeCartao" label="Nome no cartão" readonly     v-model="consultaSelecionada.pagamento.nomeCartao"/>
        </v-col>
          <v-col cols="3">
            <v-text-field label="Total" readonly type="tel" prefix="R$"   v-model="consultaSelecionada.pagamento.valorFinal"/>
            
           
          
          </v-col>
          <v-col cols="3">
            <v-text-field label="Bandeira" readonly type="tel"    v-model="consultaSelecionada.pagamento.bandeira"/>
          
             </v-col>
             <v-col cols="3">
           
            <v-text-field label="Desconto" readonly  v-model="consultaSelecionada.pagamento.desconto"/>
             </v-col>
             <v-col cols="3">
           
           <v-text-field label="Parcelamento" readonly  v-model="consultaSelecionada.pagamento.parcelamento"/>
            </v-col>
          

        </v-row>


        <!-- <div style="width:100%" class="d-flex flex-column align-itens-center mt-3">
          <h3 style="width:100%"  class="text-left">Forma de Pagamento</h3>
          <v-select
            multiple
            dense
            readonly
            solo
            label="Forma de pagamento"
            class="text-center maiuscula"
            :items="[
              consultaSelecionada.pagamento.formaDePagamento
            ]"
            v-model="consultaSelecionada.pagamento.formaDePagamento"
          />
        </div> -->

        <div style="width:100%" class="d-flex ">
        <v-row dense  class="mt-3">
          <!-- <v-col >
            <v-btn block dense @click="abreModal">ENCAMINHAR PARA O FINANCEIRO</v-btn>

          </v-col> -->
          <v-col >
            <v-btn block dense @click="close" >Voltar</v-btn>
          </v-col>
          <v-col >
            <v-btn block dense @click="abreModal" >EFETUAR PAGAMENTO</v-btn>
          </v-col>

        </v-row>
          </div>

      
      <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
    </v-card>
  </v-dialog>
</template>

<script>
import ConsultasMixin from '@/mixins/ConsultasMixin';
import modalFinalizaConta from '@/components/financeiro/modalFinalizaConta.vue';
import moment from 'moment';
export default {
  mixins: [
    ConsultasMixin,
    ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mostraOcorrencia: false,
    exames: [
      {
        medicoSolicitante: '',
        convenio: '',
        exame: '',
        valor: '',
      },
    ],
    procedimentos:[],
    modal: {
      active: false,
      modalComponent: modalFinalizaConta,
    },
    panelsOpen: [],
  }),
  computed:{
    procedimentosComp(){
      this.procedimentos = []
      for (let exame of this.consultaSelecionada.exames){
        if(this.procedimentos[exame.procedimento] == undefined){
          this.procedimentos[exame.procedimento] = {
            proc: {
              exames: [exame]
            },
            valor: "",
            procedimento: exame.procedimento 
          }

        }else{
          this.procedimentos[exame.procedimento].proc.exames.push(exame)
        }
       
      }
      return this.procedimentos;
    }
  },
  mounted() {
    
    for (let exame of this.consultaSelecionada.exames){
        if(this.procedimentos[exame.procedimento] == undefined){
          this.procedimentos[exame.procedimento] = {
            proc: {
              exames: [exame]
            },
            valor: "",
            procedimento: exame.procedimento 
          }

        }else{
          this.procedimentos[exame.procedimento].proc.exames.push(exame)
        }
       
      }
      this.$forceUpdate
     
     

  },
  methods: {
    close() {
      this.$emit('close');
     
    },
    openAll() {
      this.panelsOpen = [...Array(this.exames).keys()].map((_, index) => index);
    },
    closeAll() {
      this.panelsOpen = [];
    },
    abreModal(){
      //this.mostraOcorrencia = true;

      this.modal.active = true;
    },
    async closeModal() {
      this.modal.active = false;
      this.procedimentos = []
    },
    submitOcorrencia(){
    },

  },
};
</script>



      <!-- <v-button @click="close"> Voltar </v-button>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script> -->
