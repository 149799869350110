var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",staticStyle:{"max-width":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex mt-2 justify-space-between",attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',{attrs:{"id":attrs['aria-labelledby']},domProps:{"textContent":_vm._s(item)}})],1)]}},{key:"item.calories",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item)+" ")])]}}])})],1),_c('v-card',{staticClass:"grey lighten-4 pa-0 ma-0"},[_c('v-container',{staticClass:"fluid grey lighten-4 pa-0 ma-0"},[(_vm.dataSelecionadaBloqueada)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" Data bloqueada, por favor desbloqueie antes para marcar uma consulta ")])])],1):_c('v-data-table',_vm._g(_vm._b({staticClass:"grey lighten-4 pt-2 px-0 clique maiuscula",staticStyle:{"font-size":"10px !important"},attrs:{"height":"450px","hide-default-footer":"","headers":_vm.headers1,"sort-by":"hora","items":_vm.consultasSelecionadas,"no-data-text":"Nenhuma consulta neste dia ","items-per-page":-1},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.hora",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hora)+" "),(item.gemelar === 'true')?_c('v-chip',{staticClass:"ma-2",staticStyle:{"font-size":"10px","width":"70px","height":"25px"},attrs:{"color":"green"}},[_vm._v(" Gemelar ")]):_vm._e()]}},{key:"item.convenio",fn:function(ref){
var item = ref.item;
return [(item.pagamento.formaDePagamento == 'Cortesia')?_c('span',[_vm._v(" cortesia ")]):_c('span',[_vm._v(" "+_vm._s(_vm.extrairNomesConvenio(item.exames).join(', '))+" ")])]}},{key:"item.exames",fn:function(ref){
var item = ref.item;
return _vm._l((item.exames),function(exame,index){return _c('span',{key:index,attrs:{"title":("" + exame)}},[_vm._v(" "+_vm._s(exame.valorExameConvenio.exame.nome)+" "),_c('br')])})}},(_vm.valorStatus === 'status')?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"exibir":true,"transition":"dialog-bottom-transition","max-width":"600","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","depressed":"","plain":"","router":"","to":''},on:{"click":function($event){return _vm.setaConsultaSelecionada(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal-circle-outline")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',{staticClass:"py-4 px-2"},[_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"text-end pl-0 ml-n1",attrs:{"depressed":"","plain":"","small":"","route":"","to":''},on:{"click":_vm.redirecionaNovoLaudo}},[_c('v-icon',[_vm._v("mdi-plus-thick")]),_vm._v(" novo laudo ")],1),_c('v-btn',{staticClass:"text-end",attrs:{"depressed":"","plain":"","small":""}},[_c('v-icon',{on:{"click":function($event){dialog.value = false}}},[_vm._v("mdi-close-circle-outline")])],1)],1),[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("EXAME")]),_c('th',{staticClass:"text-left"},[_vm._v("MÉDICO")]),_c('th',{staticClass:"text-left"},[_vm._v("DATA")]),_c('th',{staticClass:"text-left"},[_vm._v("AÇÕES")])])]),_c('tbody',_vm._l((_vm.historicoFinalizado),function(item,indexHistorico){return _c('tr',{key:item.name,staticClass:"text-left pointer"},[_c('td',{staticClass:"ml-4 "},_vm._l((item.exames),function(exame,index){return _c('v-checkbox',{key:index,staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"hide-details":"","label":exame.nome,"validate-on-blur":""},model:{value:(
                                  _vm.laudoCheckbox[indexHistorico][exame.nome]
                                ),callback:function ($$v) {_vm.$set(_vm.laudoCheckbox[indexHistorico], exame.nome, $$v)},expression:"\n                                  laudoCheckbox[indexHistorico][exame.nome]\n                                "}})}),1),_c('td',[_vm._v(_vm._s(item.nomeMedico))]),_c('td',[_vm._v(_vm._s(item.data))]),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function () { return _vm.imprimirLaudo(item, indexHistorico); }}},[_c('v-icon',[_vm._v("mdi-printer")])],1),( _vm.usuarioLogado.medico.nome == item.nomeMedico)?_c('v-btn',{attrs:{"icon":""},on:{"click":function () { return _vm.irParaOsLaudos(item, indexHistorico); }}},[_c('v-icon',[_vm._v("mdi-text-box-edit")])],1):_vm._e()],1)])}),0)]},proxy:true}],null,true)}),(
                        _vm.historicoFinalizado.length === 0 && _vm.loading === false
                      )?_c('p',{staticClass:"text--disabled text-center maiuscula"},[_vm._v(" "+_vm._s(_vm.consultaSelecionada.paciente.nome)+" ainda não possui laudos. ")]):_vm._e(),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":""}})]],2)]}}],null,true)})]}}:null,(_vm.valorStatus === 'status')?{key:"item.status",fn:function(ref){
                      var item = ref.item;
return [(item.status || item.statusPagamento)?_c('v-chip',{staticClass:"tamanho",staticStyle:{"width":"75px"},attrs:{"color":_vm.getColor(item.status, item.statusPagamento),"small":"","width":"30px"}},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(item.status))])]):_vm._e()]}}:null,(_vm.valorStatus === 'statusPagamento')?{key:"item.statusPagamento",fn:function(ref){
                      var item = ref.item;
return [(item.status || item.statusPagamento)?_c('v-chip',{staticClass:"tamanho",attrs:{"color":_vm.getColor(item.status, item.statusPagamento),"small":"","width":"30px"}},[_c('span',{staticStyle:{"font-size":"8px"}},[_vm._v(_vm._s(item.statusPagamento))])]):_vm._e()]}}:null],null,true)},'v-data-table',_vm.attrs,false),_vm.on))],1)],1),_c('LoadingDialog',{attrs:{"loading":_vm.loadingDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }