<template>

    <v-dialog v-model="show" transition="dialog-top-transition" width="350px">

      <v-card class="px-4 py-4">
        <span class="d-flex justify-end">
                  <v-icon class="pa-3" @click="close">
                    mdi-close-circle-outline
                  </v-icon>
                </span>
        <h3 class="text-center maiuscula">Seu login expirou, valide seu login novamente!AGENDAAA</h3>
        <br>



              <v-btn   outlined @click="fazLogin()" block large>
                Clique aqui para validar o login
              </v-btn>

              <v-spacer></v-spacer>

          <br>
          <v-alert type="error">Tente fazer a operação novamente!</v-alert>
      </v-card>
    </v-dialog>

  </template>

  <script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import axios from 'axios';
  import ConsultasMixin from '@/mixins/ConsultasMixin';
  export default {
    mixins: [
      ConsultasMixin,
      ],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
        modelo: {
      usuario: null,
      senha: null

    },

    }),

    methods: {
        ...mapActions('Auth', ['login']),
      close() {
        this.$emit('close');

      },
      async fazLogin(){
      await this.login(this.modelo);

      this.mostraAlerta = false;
      //ok
      this.close();

    },



    },
  };
  </script>

  <style>

  </style>
