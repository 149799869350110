<template lang="">
  <div>
    <LaudoPerfilCervical></LaudoPerfilCervical>
  </div>
</template>
<script>
import LaudoPerfilCervical from "../components/laudos/formularios/exames/perfilCervical/LaudoPerfilCervical.vue";
export default {
  components: {
    LaudoPerfilCervical,
  },
};
</script>
<style lang=""></style>