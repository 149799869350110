<template lang="">
  <div>
    <LaudoColocacaoDoPessario></LaudoColocacaoDoPessario>
  </div>
</template>
<script>
import LaudoColocacaoDoPessario from '../components/laudos/formularios/exames/colocacaoDoPessario/LaudoColocacaoDoPessario.vue';
export default {
  components: {
    LaudoColocacaoDoPessario,
  },
};
</script>
<style lang=""></style>
