<template lang="">
  <div>
    <LaudoSeriadaAvaliacaoOvulacao></LaudoSeriadaAvaliacaoOvulacao>
  </div>
</template>
<script>
import LaudoSeriadaAvaliacaoOvulacao from '../components/laudos/formularios/exames/seriadaAvaliacaoOvulacao/LaudoSeriadaAvaliacaoOvulacao.vue';
export default {
  components: {
    LaudoSeriadaAvaliacaoOvulacao,
  },
};
</script>
<style lang=""></style>
