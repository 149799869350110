<template>
  <v-app class="notranslate" translate="no">
    <view-sidebar v-if="estaAutenticado"></view-sidebar>
    <v-main class="white notranslate"  translate="no">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import ViewSidebar from '@components/templates/Sidebar.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ViewSidebar },
  computed: {
    ...mapGetters('Auth', ['estaAutenticado']),
  },
};
</script>
