<template lang="">
    <div>
      <Laudo3d></Laudo3d>
    </div>
  </template>
  <script>
  import Laudo3d from '../components/laudos/formularios/exames/3d/Laudo3d.vue';
  export default {
    components: {
        Laudo3d,
    },
  };
  </script>
  <style lang=""></style>
  