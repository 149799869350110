<template>

    <v-dialog v-model="show" transition="dialog-top-transition" width="700px">

      <v-card class="px-4 py-4">
        <span class="d-flex justify-end">
                  <v-icon class="pa-3" @click="close">
                    mdi-close-circle-outline
                  </v-icon>
                </span>
                <h2 class="pb-8 text-center">PROCEDIMENTOS</h2>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                class="maiuscula"
                label="Cod° do procedimento"
                validate-on-blur
                filled
                outlined
                required
                v-model="model.procedimento"
                :rules="[value => !!value || 'Campo obrigatório']"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                class="maiuscula"
                label="Exame"
                required
                multiple
                validate-on-blur
                filled
                outlined
                v-model="model.exames"
                :items="nomesExames"
                :rules="[value => !!value || 'Campo obrigatório']"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                class="maiuscula"
                label="Convênio"
                validate-on-blur
                filled
                outlined
                required
                v-model="model.convenio"
                @change="handleConvenioChange"
                :items="nomesConvenios"
                :rules="[value => !!value || 'Campo obrigatório']"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                class="maiuscula"
                label="Valor (R$)"
                required
                validate-on-blur
                filled
                @change="formatarValor"
                outlined
                v-model="model.valor"
                :rules="[value => !!value || 'Campo obrigatório']"
              />
            </v-col>
            
        </v-row>
        <v-row>
          <v-btn class="ml-4 mt-n6" color="primary" @click="salvarProcedimento"
              >SALVAR PROCEDIMENTO</v-btn
            >
        
        </v-row>

        <br>
          <v-alert type="info">Tente calcular o valor após cadastrar o procedimento!</v-alert>
      </v-card>
    </v-dialog>

  </template>

  <script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import axios from 'axios';
  import ConsultasMixin from '@/mixins/ConsultasMixin';
  export default {
    mixins: [
      ConsultasMixin,
      ],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
        modelo: {
      usuario: null,
      senha: null,
     
      formValidado: true,

    },
    model: { procedimento: '', convenio: '', exames: [], valor: '' },

    }),

    methods: {
        ...mapActions('Auth', ['login']),
      close() {
        this.$emit('close');

      },
      async fazLogin(){
      await this.login(this.modelo);

      this.mostraAlerta = false;
      this.close();

    },
    async salvarProcedimento() {
      await this.criarMapaAssociacaoExames(this.model).then(resp => {
        if (resp.status === 200) {
          alert('Procedimento cadastrado');
          this.model.procedimento = '';
          this.model.convenio = '';
          this.model.exames = [];
          this.model.valor = '';
          this.close();

          this.formValidado = true;
          if (this.model.convenio) {
            this.consultarProcedimento();
          }
        }
      }).catch(()=>{
        alert('Procedimento já existe');
      });
    },
    handleConvenioChange(convenioNome) {
      this.setaConvenioSelecionado(convenioNome).then(() => {});
    },
    formatarValor(){
      let valorFormatado = parseFloat(this.model.valor.replace(/[^\d.]/g, '')).toFixed(2);
      this.model.valor= valorFormatado;
    }
    



    },
    mounted() {
    this.getNomesConvenios();
    this.getNomesExames();
  },
  };
  </script>

  <style>

  </style>
