<template>
  <v-container>
    <v-card class="grey darken-1">
      <br />

      <v-row>
        <v-col <v-col cols="1" md="1">
          <v-icon large right dense> mdi-tune </v-icon>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field label="De:" type="date"></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-text-field label="Até:" type="date"></v-text-field>
        </v-col>
        <!-- <v-col cols="12"
        md="2">
        <v-text-field label="Médico"></v-text-field>




      </v-col>
      <v-col cols="12"
        md="2">
      <v-text-field label="Convênio"></v-text-field>
      </v-col> -->
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-col cols="4" md="">
          <v-btn><v-icon large dense> mdi-download </v-icon></v-btn>
        </v-col>
      </v-row>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-col>
        <v-data-table
          :headers="headers1"
          :items="consultasTotal"
          :items-per-page="8"
          :search="search"
          class="elevation-1 maiuscula"
          no-data-text="Nenhuma ocorrência "
          :loading="loading"
          loading-text="Buscando relátorios..."
          @click:row="handleClick"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon v-if="!item.visualizado">mdi-alert-circle</v-icon>

            <v-icon v-if="item.visualizado">mdi-check-all</v-icon>
          </template>
          <template v-slot:item.valorExameConvenio.exame.nome="{ item }">
            <abbr
              v-for="(exame, index) in item.exames"
              :title="`${exame.exame}`"
              :key="index"
            >
              <span v-if="item.exames.length == 1">
                {{ exame.exame }}
              </span>
              <span v-else>
                {{ getSigla(exame.exame) }}
                <span v-if="item.exames.length != index + 1">/</span>
              </span>
            </abbr>
          </template>
        </v-data-table>
      </v-col>
    </v-card>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import ConsultasMixin from '@mixins/ConsultasMixin';
import { mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import ModalOcorrenciaVue from './ModalOcorrencia.vue';
export default {
  mixins: [ConsultasMixin],
  data() {
    return {
      modal: {
        active: false,
        modalComponent: ModalOcorrenciaVue,
      },
      consultasComPagamentos: [],
      consultasTotal: [],
      consulta: [],
      search: '',
      loading: true,
      headers1: [
        {
          text: 'Data',
          align: 'start',
          sortable: false,
          value: 'consulta.data',
        },
        { text: 'Nome', value: 'consulta.paciente.nome' },
        { text: 'Exame', value: 'valorExameConvenio.exame.nome' },
        { text: 'Convênio', value: 'exames[0].convenio' },
        { text: 'Médico', value: 'consulta.medicoExecutante.nome' },
        { text: 'Valor', value: 'pagamento.valorFinal' },
        { text: '', value: 'actions' },
      ],
      valorTotal: 0,
    };
  },
  computed: {
    ...mapGetters('Auth', ['headers', 'nivelPermissao']),
  },
  mounted() {
    let agora = moment();
    agora = agora.format('DD/MM/YYYY');
    //this.buscaConsultas(agora);
    this.closeModal();
    //this.getConsultaByData(agora)
    this.getOcorrencia(agora);
  },
  methods: {
    async handleClick(event, row) {
      this.consulta = event;
      this.setConsultaSelecionada(event);

      let indice = row.index;
      this.consultasTotal[indice].visualizado = true;
      this.setaVisualizado(event.id);
      this.modal.active = true;
      return;
    },
    getSigla(value) {
      if (value === 'TRANSLUCÊNCIA NUCAL') {
        return 'TN';
      }
      if (value === 'DOPPLER') {
        return 'DP';
      }
      if (value === 'PERFIL CERVICAL') {
        return 'PC';
      }
      if (value === 'COLO UTERINO') {
        return 'CUT';
      }
      if (value === 'AVALIAÇÃO MORFOLÓGICA DO PRIMEIRO TRIMESTRE') {
        return 'M1T';
      }

      return value;
    },
    closeModal() {
      this.modal.active = false;
    },
    async setaVisualizado(id) {
      await axios.patch(
        `${this.urlBase}Ocorrencia?id=${id}`,
        [
          {
            path: 'visualizado',
            op: 'replace',
            value: true,
          },
        ],
        { headers: this.headers }
      );
    },

    async setaConsultasComPagamentos() {
      //const consultas = await this.getTodasConsultas();
      //this.consultasTotal = consultas;
      this.buscaConsultas('18/10/2022');
    },
    async getOcorrencia(data) {
      const resp = await axios.get(
        `${this.urlBase}Ocorrencia?data=${data}`,
        null
      );

      this.consultasTotal = resp.data;

      for (var k = 0; k < this.consultasTotal.length; k++) {
        const resp2 = await axios.get(
          `${this.urlBase}Pagamento/?pagamentoId=${this.consultasTotal[k].consulta.pagamentoId}`,
          { headers: this.headers }
        );
        this.consultasTotal[k].pagamento = resp2.data;
        //this.valorTotal = this.valorTotal + this.consultasTotal[k].consulta.pagamento.valorFinal

        const resp = await axios.get(
          `${this.urlBase}ExamePorConsulta/?consultaId=${this.consultasTotal[k].consultaId}`,
          null
        );
        const examesPorConsulta = [];
        let teste = resp.data;

        for (
          var i = 0;
          i < Object.keys(Object.assign({}, resp.data)).length;
          i++
        ) {
          let exame = {
            exame: teste[i].valorExameConvenio.exame.nome,
            valor: teste[i].valorExameConvenio.valor,
            convenio: teste[i].valorExameConvenio.convenio.nome,
          };

          examesPorConsulta.push(exame);
          this.consultasTotal[k].exames = examesPorConsulta;
        }
      }

      this.loading = false;
    },
    async buscaConsultas(value) {
      if (!value) return;

      const resp = await axios.get(
        `${this.urlBase}ExamePorConsulta/ByData?data=${value}`,
        null
      );
      const consultasFiltradas = [];
      const encontrou = [];
      for (var i = 0; i < resp.data.length; i++) {
        for (var j = 0; j < resp.data.length; j++) {
          if (
            resp.data[i].consultaId == resp.data[j].consultaId &&
            !encontrou.includes(resp.data[i].consultaId)
          ) {
            encontrou.push(resp.data[i].consultaId);
            consultasFiltradas.push(resp.data[i]);
          }
        }
      }
      for (var k = 0; k < consultasFiltradas.length; k++) {
        this.valorTotal =
          this.valorTotal + consultasFiltradas[k].consulta.pagamento.valorFinal;

        const resp = await axios.get(
          `${this.urlBase}ExamePorConsulta/?consultaId=${consultasFiltradas[k].consultaId}`,
          null
        );
        const examesPorConsulta = [];
        let teste = resp.data;

        for (
          var i = 0;
          i < Object.keys(Object.assign({}, resp.data)).length;
          i++
        ) {
          let exame = {
            exame: teste[i].valorExameConvenio.exame.nome,
            valor: teste[i].valorExameConvenio.valor,
          };

          examesPorConsulta.push(exame);
        }

        consultasFiltradas[k].exames = examesPorConsulta;
        consultasFiltradas[k].visualizado = false;
      }

      this.consultasTotal = consultasFiltradas;

      this.loading = false;
    },
  },
};
</script>
