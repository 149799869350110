<template>
  <section class="mt-6">
    <template v-if="clicouNoPersonalizado">
      <v-card class="my-2 py-4 text-center teal darken-4">
        <h2 class="grey--text text--lighten-4">
          ULTRASSONOGRAFIA OBSTÉTRICA DE PRIMEIRO TRIMESTRE
        </h2>
      </v-card>
      <v-col
        cols="12"
        class="form-group"
        v-for="index in quantidadeFolhasEditorPersonalizado"
        :key="index"
      >
        <h2>Folha {{ index }}</h2>
        <vue-editor v-model="conteudoEditorPersonalizado[index - 1]" />
      </v-col>
    </template>
    <template v-else>
      <v-card>
        <v-card class="my-2 py-4 text-center teal darken-4">
          <h2 class="grey--text text--lighten-4">
            ULTRASSONOGRAFIA OBSTÉTRICA DE PRIMEIRO TRIMESTRE
          </h2>
        </v-card>
        <v-alert
          v-if="!!laudos[0].folhas"
          outlined
          type="warning"
          border="left"
          text=""
        >
          Esse <strong>laudo</strong> teve a impressão
          <strong>personalizada.</strong>
          <v-btn text @click="adcionarConteudoEditorPersonalizado"
            >Clique aqui para visualizar</v-btn
          >
        </v-alert>
        

        <v-tabs class="pa-4">
          <v-tab v-for="(laudo, index) in laudos" :key="index">
            Laudo {{ index + 1 }}
          </v-tab>

          <v-tab-item v-for="(laudo, index) in laudos" :key="index">
            <v-row class="pt-5" no-gutters>
              <v-col cols="12" class="d-flex justify-start align-center">
                <h4>Indicação:</h4>
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
                <span
                  :class="
                    !camposExibidos[index].exibeIndicacaoExame
                      ? 'text--disabled'
                      : ''
                  "
                  >Indicação do exame: </span
                >
                
                <v-select
                  :items="indicacao"
                  validate-on-blur
                  required
                  :disabled="!camposExibidos[index].exibeUteroGestUn"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :disable="podeEditar"
                  dense
                  v-model="laudo.indicacaoExame"
                  class="flex-grow-0 mx-2"
                  
                />
                <v-text-field
                v-if="laudo.indicacaoExame == 'Outros'"
                  class="mx-2 flex-grow-0"
                 
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeIndicacaoExame"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.indicacaoExameOutros"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeIndicacaoExame
                      ? 'text--disabled'
                      : ''
                  "
                  >.</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeIndicacaoExame"
                  @click="camposExibidos[index].exibeIndicacaoExame = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeIndicacaoExame"
                  @click="
                    camposExibidos[index].exibeIndicacaoExame = false;
                    laudo.indicacaoExame = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exDatMens ? 'text--disabled' : ''
                "
              >
                <span>Data da última menstruação</span>
                <v-text-field
                  type="date"
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exDatMens"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  dense
                  v-model="infosCompartilhadas.dataUltimaMestrucao"
                />
                <span>.</span>
                <v-checkbox
                  label="incerta"
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exDatMens"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  dense
                  v-model="laudo.incerta"
                />
              </v-col>

              <v-col
                v-if="!laudo.incerta"
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exDatMens ? 'text--disabled' : ''
                "
              >
                <span>Idade gestacional (DUM)</span>
                <v-text-field
                  class="mx-2 flex-grow-0"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  dense
                  :disabled="!camposExibidos[index].exDatMens"
                  :value="infosCompartilhadas.semanasDUM"
                />
                <span>semanas e</span>
                <v-text-field
                  class="mx-2 flex-grow-0"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  dense
                  :disabled="!camposExibidos[index].exDatMens"
                  :value="infosCompartilhadas.diasDUM"
                />
                <span>dias.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exDatMens"
                  @click="
                    camposExibidos[index].exDatMens = true;
                    laudo.incerta = undefined;
                    infosCompartilhadas.semanasDUM = undefined;
                    infosCompartilhadas.diasDUM = undefined;

                    infosCompartilhadas.dataUltimaMestrucao = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exDatMens"
                  @click="
                    camposExibidos[index].exDatMens = false;
                    laudo.incerta = undefined;
                    infosCompartilhadas.semanasDUM = undefined;
                    infosCompartilhadas.diasDUM = undefined;
                    infosCompartilhadas.dataUltimaMestrucao = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeUteroGestUn
                    ? 'text--disabled'
                    : ''
                "
              >
                <span>Gestação</span>
                <v-select
                  :items="tipoGemeo"
                  validate-on-blur
                  required
                  :disabled="!camposExibidos[index].exibeUteroGestUn"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :disable="podeEditar"
                  dense
                  class="flex-grow-0 mx-2"
                  v-model="infosCompartilhadas.gestacao"
                />
                <span>.</span>
                <v-select
                    v-if="infosCompartilhadas.gestacao === 2"
                    class="mx-2 flex-grow-0"
                    :items="['dicoriônica', 'monocoriônica']"
                    dense
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    validate-on-blur
                    v-model="laudo.gestacaoTres"
                  />

                  <v-select
                    v-if="infosCompartilhadas.gestacao === 2"
                    class="mx-2 flex-grow-0"
                    :items="['diamniótica ', 'monoamniótica']"
                    dense
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    validate-on-blur
                    v-model="laudo.gestacaoQuatro"
                  />

                  <v-textarea
                    v-if="[3, 4, 5].includes(infosCompartilhadas.gestacao)"
                    class="mx-2 flex-grow-0"
                    dense
                    rows="3"
                    no-resize
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    validate-on-blur
                    v-model="laudo.gestacaoCinco"
                  />
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeUteroGestUn"
                  @click="camposExibidos[index].exibeUteroGestUn = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeUteroGestUn"
                  @click="
                    camposExibidos[index].exibeUteroGestUn = false;
                    laudo.uteroSituado = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              ><h4>História materna</h4>
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >Data de nascimento 
              <v-text-field
                  type="date"
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  dense
                  @change="calcularIdadeEMeses"
                  v-model="laudo.dataNascimento"
                  
                />
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0 && laudo.dataNascimento"
              >
            <span>
              {{ laudo.anos }} {{ laudo.anos>1 ? ' anos ': 'ano'}} e {{ laudo.meses }} {{ laudo.meses>1?' meses ':'mês' }} 
            </span>
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >Peso
              <v-text-field
                  
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  dense
                  @change="calculaImc"
                 
                  v-model="laudo.peso"
                  
                />
                <span>kg. Altura</span>
                <v-text-field
                  
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  dense
                  @change="calculaImc"
                 
                  v-model="laudo.altura"
                  
                />
                <span>cm. &nbsp; </span>
                <span>IMC: {{ laudo.imc }}</span>
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Perda gestacional:  </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Não',
                    'perda com menos de 11 semanas',
                    'perda entre 11 e 15 semanas',
                    'perda entre 16 e 20 semanas',
                    'perda entre 21 e 28 semanas',
                    'perda entre 29 e 34 semanas',
                    'perda acima de 34 semanas'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.perdaGestacional"
                />
                <span v-if="laudo.perdaGestacional != 'Não' && !!laudo.perdaGestacional">. Quantas: </span>
                <v-text-field
                v-if="[
                    'perda com menos de 11 semanas',
                    'perda entre 11 e 15 semanas',
                    'perda entre 16 e 20 semanas',
                    'perda entre 21 e 28 semanas',
                    'perda entre 29 e 34 semanas',
                  ].includes(laudo.perdaGestacional)"
                class="mx-2 flex-grow-0"
                :readonly="carregamentoDoLaudo || !podeEditar"
                v-model="laudo.quantasPercas"
                ></v-text-field>
                
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Doenças pré-existente: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.doencaPreExistente"
                />
                <v-text-field
                v-if="laudo.doencaPreExistente == 'Sim'"
                class="mx-4 flex-grow-4"
                :readonly="carregamentoDoLaudo || !podeEditar"
                v-model="laudo.tipoDoencaPreExistente"
                ></v-text-field>
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Tabagismo durante a gravidez: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.fuma"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Método de contracepção: </span>
              
                <v-select
                :items="[
                  'Espontânea',
                  'indução da ovulação',
                  'FIV'
                ]"
                class="mx-2 flex-grow-0"
                :readonly="carregamentoDoLaudo || !podeEditar"
                v-model="laudo.metodoContracepcao"
                ></v-select>.
            
            </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exibeUteroSituado
                      ? 'text--disabled'
                      : ''
                  "
                  ><b>Útero</b> em</span
                >
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeUteroSituado"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="[
                    'anteversão',
                    'anteversoflexão',
                    'retroversão',
                    'retroversoflexão',
                  ]"
                  v-model="laudo.uteroSituado"
                ></v-select>
                <span>.</span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeUteroSituado"
                  @click="camposExibidos[index].exibeUteroSituado = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeUteroSituado"
                  @click="
                    camposExibidos[index].exibeUteroSituado = false;
                    laudo.uteroSituado = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <span
                  :class="
                    !camposExibidos[index].exFraseSinais ? 'text--disabled' : ''
                  "
                  ><b>Colo uterino e canal endocervical</b>
                </span>
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exFraseSinais"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="[
                    'sem anormalidades detectáveis',
                    'apresentando pólipo endocervical',
                  ]"
                  v-model="laudo.uteroAnormal"
                ></v-select>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exFraseSinais"
                  @click="
                    camposExibidos[index].exFraseSinais = true;
                    laudo.uteroAnormal = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exFraseSinais"
                  @click="
                    camposExibidos[index].exFraseSinais = false;
                    laudo.uteroAnormal = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  v-if="
                    laudo.uteroAnormal === 'apresentando pólipo endocervical'
                  "
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exibePolipo ? 'text--disabled' : ''
                  "
                >
                  medindo
                </span>
                <v-text-field
                  v-if="
                    laudo.uteroAnormal === 'apresentando pólipo endocervical'
                  "
                  class="mx-2 flex-grow-0"
                 
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibePolipo"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.polipoEndocervical"
                />
                <span
                  v-if="
                    laudo.uteroAnormal === 'apresentando pólipo endocervical'
                  "
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exibePolipo ? 'text--disabled' : ''
                  "
                  >mm.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    !camposExibidos[index].exibePolipo &&
                    laudo.uteroAnormal === 'apresentando pólipo endocervical'
                  "
                  @click="
                    camposExibidos[index].exibePolipo = true;
                    laudo.polipoEndocervical = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    camposExibidos[index].exibePolipo &&
                    laudo.uteroAnormal === 'apresentando pólipo endocervical'
                  "
                  @click="
                    camposExibidos[index].exibePolipo = false;
                    laudo.polipoEndocervical = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h4>Cavidade uterina:</h4>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeAreaHematoma
                    ? 'text--disabled'
                    : ''
                "
              >
                <span
                  :class="
                    !camposExibidos[index].exibeAreaHematoma
                      ? 'text--disabled'
                      : ''
                  "
                  >Hematoma subcoriônico: </span
                >
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeAreaHematoma"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="['Sim', 'Não']"
                  v-model="laudo.temHematoma"
                ></v-select>
              
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="laudo.temHematoma == 'Sim'"
                :class="
                  !camposExibidos[index].exibeAreaHematoma
                    ? 'text--disabled'
                    : ''
                "
              >
                <span
                  :class="
                    !camposExibidos[index].exibeAreaHematoma
                      ? 'text--disabled'
                      : ''
                  "
                  >na porção</span
                >
                <!-- <v-text-field
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeAreaHematoma"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                 
                  v-model="laudo.areaHematoma"
                ></v-text-field> -->
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeAreaHematoma"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="['caudal', 'cranial']"
                  v-model="laudo.areaHematoma"
                ></v-select>
                <span
                  :class="
                    !camposExibidos[index].exibeAreaHematoma
                      ? 'text--disabled'
                      : ''
                  "
                  >do saco gestacional, acometendo o córion
                </span>
                <!-- <v-text-field
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeAreaHematoma"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                 
                  v-model="laudo.corion"
                ></v-text-field> -->
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeAreaHematoma"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="['frondoso', 'liso']"
                  v-model="laudo.corion"
                ></v-select>
                <span
                  :class="
                    !camposExibidos[index].exibeAreaHematoma
                      ? 'text--disabled'
                      : ''
                  "
                  >,</span
                >
              </v-col>
              <v-col cols="12" class="d-flex justify-start align-center" v-if="laudo.temHematoma == 'Sim'">
                <span
                  :class="
                    !camposExibidos[index].exibeAreaHematoma
                      ? 'text--disabled'
                      : ''
                  "
                  >medindo</span
                >
                <v-text-field
                  class="mx-2 flex-grow-0"
                 
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeAreaHematoma"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.corion1"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeAreaHematoma
                      ? 'text--disabled'
                      : ''
                  "
                  >X</span
                >
                <v-text-field
                  class="mx-2 flex-grow-0"
                 
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeAreaHematoma"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.corion2"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeAreaHematoma
                      ? 'text--disabled'
                      : ''
                  "
                  >mm, acometendo </span
                >
                <!-- <v-text-field
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeAreaHematoma"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                 
                  v-model="laudo.acometSacGest"
                ></v-text-field> -->
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeAreaHematoma"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="['menos de 25%', 'entre 25 e 50%', 'entre 50 e 75%', 'acima de 75%']"
                  v-model="laudo.acometSacGest"
                ></v-select>
                <span
                  :class="
                    !camposExibidos[index].exibeAreaHematoma
                      ? 'text--disabled'
                      : ''
                  "
                  > do saco gestacional.  </span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeAreaHematoma"
                  @click="camposExibidos[index].exibeAreaHematoma = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeAreaHematoma"
                  @click="
                    camposExibidos[index].exibeAreaHematoma = false;
                    laudo.areaHematoma = undefined;
                    laudo.corion = undefined;
                    laudo.corion1 = undefined;
                    laudo.corion2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span><b>Saco gestacional</b></span>
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="[
                    'tópico',
                    'em região ístmica',
                    'em colo uterino',
                    'em cicatriz de cesárea',
                    'em região cornual',
                    'em região intersticial da trompa',
                    'em região ampular da trompa',
                    'em cavidade abdominal',
                    'em região angular',
                    'em região abdominal'
                  ]"
                  v-model="laudo.sacoGestacional"
                ></v-select>
                <!-- <div>
                <span
                :class="
                  !camposExibidos[index].exUteroAumentado2
                    ? 'text--disabled'
                    : ''
                "
              >
                , único, </span
              >
                 <span
                  :class="
                    !camposExibidos[index].exUteroAumentado2
                      ? 'text--disabled'
                      : ''
                  "
                >
                  inserido na lâmina endometrial</span
                >
              </div> -->

                <!-- <v-select
                required
                dense
                class="flex-grow-0 mx-2"
                validate-on-blur
                :disabled="!camposExibidos[index].exUteroAumentado2"
                :readonly="carregamentoDoLaudo || !podeEditar"
                :clearable="podeEditar"
                :items="['anterior', 'posterior', 'fúndica']"
                v-model="laudo.sacoGestacional2"
              ></v-select> -->
                <!-- <span
                :class="
                  !camposExibidos[index].exUteroAumentado2
                    ? 'text--disabled'
                    : ''
                "
              >
                da cavidade uterina,</span
              > -->
                <!-- <v-btn
                dense
                depressed
                text
                icon
                v-if="!camposExibidos[index].exUteroAumentado2"
                @click="
                  camposExibidos[index].exUteroAumentado2 = true;

                  laudo.sacoGestacional2 = undefined;
                "
                ><v-icon>mdi-eye-off</v-icon></v-btn
              >
              <v-btn
                dense
                depressed
                text
                icon
                v-if="camposExibidos[index].exUteroAumentado2"
                @click="
                  camposExibidos[index].exUteroAumentado2 = false;

                  laudo.sacoGestacional2 = undefined;
                "
                ><v-icon>mdi-eye</v-icon></v-btn
              > -->
                <span
                  :class="
                    !camposExibidos[index].exUteroAumentado3
                      ? 'text--disabled'
                      : ''
                  "
                >
                  com paredes</span
                >
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exUteroAumentado3"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="['regulares', 'irregulares']"
                  v-model="laudo.sacoGestacional3"
                ></v-select>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exUteroAumentado3"
                  @click="
                    camposExibidos[index].exUteroAumentado3 = true;

                    laudo.sacoGestacional3 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exUteroAumentado3"
                  @click="
                    camposExibidos[index].exUteroAumentado3 = false;

                    laudo.sacoGestacional3 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <!-- <span
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeReacao ? 'text--disabled' : ''
                "
                >e reação decidual</span
              >
              <v-select
                required
                dense
                class="flex-grow-0 mx-2"
                validate-on-blur
                :disabled="!camposExibidos[index].exibeReacao"
                :readonly="carregamentoDoLaudo || !podeEditar"
                :clearable="podeEditar"
                :items="['homogênea', 'heterogênea']"
                v-model="laudo.sacoGestacional4"
              ></v-select>
              <v-btn
                dense
                depressed
                text
                icon
                v-if="!camposExibidos[index].exibeReacao"
                @click="
                  camposExibidos[index].exibeReacao = true;
                  laudo.sacoGestacional4 = undefined;
                "
                ><v-icon>mdi-eye-off</v-icon></v-btn
              >
              <v-btn
                dense
                depressed
                text
                icon
                v-if="camposExibidos[index].exibeReacao"
                @click="
                  camposExibidos[index].exibeReacao = false;
                  laudo.sacoGestacional4 = undefined;
                "
                ><v-icon>mdi-eye</v-icon></v-btn
              >
              <span>.</span> -->
                <span
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exibeDiametro ? 'text--disabled' : ''
                  "
                  >Diâmetro médio de</span
                >
                <v-text-field
                  class="mx-2 flex-grow-0"
                 
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeDiametro"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.diametroMedio"
                />
                <span
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exibeDiametro ? 'text--disabled' : ''
                  "
                  >mm.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDiametro"
                  @click="camposExibidos[index].exibeDiametro = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeDiametro"
                  @click="
                    camposExibidos[index].exibeDiametro = false;
                    laudo.diametroMedio = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              <!-- <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exFraseSinais2 ? 'text--disabled' : ''
                "
              >
                <span><b>Saco amniótico</b> filiforme junto ao embrião.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exFraseSinais2"
                  @click="camposExibidos[index].exFraseSinais2 = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exFraseSinais2"
                  @click="camposExibidos[index].exFraseSinais2 = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col> -->
              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exFraseSinais23 ? 'text--disabled' : ''
                "
              >
                <span><b>Saco amniótico</b> filiforme junto ao </span><v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="['embrião', 'feto']"
                  v-model="laudo.fraseSinaisFetEmb"
                ></v-select>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exFraseSinais23"
                  @click="camposExibidos[index].exFraseSinais23 = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exFraseSinais23"
                  @click="camposExibidos[index].exFraseSinais23 = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeEmbriao ? 'text--disabled' : ''
                "
              >
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeEmbriao"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="['Embrião', 'Feto']"
                  v-model="laudo.embriaoEFeto"
                ></v-select>
                <span
                  >identificado no interior do saco amniótico com CCN medindo
                </span>

                <v-text-field
                  class="mx-2 flex-grow-0"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeEmbriao"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.embriaoBpm"
                />.

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeEmbriao"
                  @click="
                    camposExibidos[index].exibeEmbriao = true;
                    laudo.embriao = undefined;
                    laudo.embriaoBpm = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeEmbriao"
                  @click="
                    camposExibidos[index].exibeEmbriao = false;
                    laudo.embriao = undefined;
                    laudo.embriaoBpm = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeEmbriao ? 'text--disabled' : ''
                "
              >
                <span> Atividade cardíaca</span>
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeEmbriao"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="[ 'presente', 'ausente', 'ainda não identificada']"
                  v-model="laudo.embriao"
                ></v-select>
                <span
                  v-if="laudo.embriao === 'presente'"
                  :class="
                    !camposExibidos[index].exibeEmbriao ? 'text--disabled' : ''
                  "
                  >, com frequência de </span
                >
                <v-text-field
                  v-if="laudo.embriao === 'presente'"
                  class="mx-2 flex-grow-0"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeEmbriao"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.embriaoBpm2"
                />
                <span
                  v-if="laudo.embriao === 'presente'"
                  :class="
                    !camposExibidos[index].exibeEmbriao ? 'text--disabled' : ''
                  "
                  >bpm. &nbsp;</span
                >
                <!-- <span v-if="laudo.embriao === 'presente,'">
                com comprimento cabeça nádegas (CCN) médio
              </span>

              <v-text-field
                v-if="laudo.embriao === 'presente,'"
                class="mx-2 flex-grow-0"
                :readonly="carregamentoDoLaudo || !podeEditar"
                :clearable="podeEditar"
                :disabled="!camposExibidos[index].exibeEmbriao"
                validate-on-blur
                required
                dense
                v-model="laudo.embriaoBpm"
              />
              <span
                v-if="laudo.embriao === 'presente,'"
                :class="
                  !camposExibidos[index].exibeEmbriao ? 'text--disabled' : ''
                "
                >mm</span
              > -->
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exibevesiculaVitelinica
                      ? 'text--disabled'
                      : ''
                  "
                  ><b>Vesícula vitelínica</b></span
                >
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibevesiculaVitelinica"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="['única', 'não identificada']"
                  v-model="laudo.vesiculaVitelinica"
                ></v-select>
                <span
                  v-if="laudo.vesiculaVitelinica != 'não identificada'"
                  :class="
                    !camposExibidos[index].exibevesiculaVitelinica
                      ? 'text--disabled'
                      : ''
                  "
                  >,</span
                >
                <v-select
                  v-if="laudo.vesiculaVitelinica != 'não identificada'"
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibevesiculaVitelinica"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="['de superfície e contornos regulares', 'dismórfica', 'hidrópica']"
                  v-model="laudo.vesiculaContornos"
                ></v-select>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibevesiculaVitelinica"
                  @click="camposExibidos[index].exibevesiculaVitelinica = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibevesiculaVitelinica"
                  @click="
                    camposExibidos[index].exibevesiculaVitelinica = false;
                    laudo.vesiculaContornos = undefined;
                    laudo.vesiculaVitelinica = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  v-if="laudo.vesiculaVitelinica != 'não identificada'"
                  :class="
                    !camposExibidos[index].exibeConteudo ? 'text--disabled' : ''
                  "
                  >, conteúdo</span
                >
                <v-select
                  v-if="laudo.vesiculaVitelinica != 'não identificada'"
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeConteudo"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="['anecogênico', 'ecogênico']"
                  v-model="laudo.vesiculaConteudo"
                ></v-select>
                <span> , </span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeConteudo"
                  @click="
                    camposExibidos[index].exibeConteudo = true;
                    laudo.vesiculaConteudo = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    camposExibidos[index].exibeConteudo &&
                    laudo.vesiculaVitelinica != 'não identificada'
                  "
                  @click="
                    camposExibidos[index].exibeConteudo = false;
                    laudo.vesiculaConteudo = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  v-if="laudo.vesiculaVitelinica != 'não identificada'"
                  :class="
                    !camposExibidos[index].exibeVesiculaDiametro
                      ? 'text--disabled'
                      : ''
                  "
                >
                  medindo</span
                >
                <v-text-field
                  v-if="laudo.vesiculaVitelinica != 'não identificada'"
                  class="mx-2 flex-grow-0"
                 
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeVesiculaDiametro"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.vesiDiametro"
                />
                <span
                  v-if="laudo.vesiculaVitelinica != 'não identificada'"
                  :class="
                    !camposExibidos[index].exibeVesiculaDiametro
                      ? 'text--disabled'
                      : ''
                  "
                  >mm de diâmetro</span
                >
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeVesiculaDiametro"
                  @click="
                    camposExibidos[index].exibeVesiculaDiametro = true;
                    laudo.vesiDiametro = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    camposExibidos[index].exibeVesiculaDiametro &&
                    laudo.vesiculaVitelinica != 'não identificada'
                  "
                  @click="
                    camposExibidos[index].exibeVesiculaDiametro = false;
                    laudo.vesiDiametro = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h4>Ovários:</h4>
              </v-col>

              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeOvarDir ? 'text--disabled' : ''
                "
              >
                <span><b>Direito: </b></span>
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeOvarDir"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="[
                    'ecograficamente normal',
                    'corpo lúteo sólido',
                    'corpo lúteo hemorrágico medindo',
                    'não identificado',
                    'Outro'
                  ]"
                  v-model="laudo.ovaDir"
                ></v-select>
                <v-text-field
                  v-if="laudo.ovaDir === 'Outro'"
                  required
                  :disabled="!camposExibidos[index].exibeOvarDir2"
                 
                  class="flex-grow-0 mx-2"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  v-model="laudo.ovaDirOutro"
                ></v-text-field>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeOvarDir"
                  @click="
                    camposExibidos[index].exibeOvarDir = true;
                    laudo.ovaDir = undefined;
                    laudo.ovaDir = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeOvarDir"
                  @click="
                    camposExibidos[index].exibeOvarDir = false;
                    laudo.ovaDir = undefined;
                    laudo.ovaDir = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <v-text-field
                  v-if="laudo.ovaDir === 'corpo lúteo hemorrágico medindo'"
                  required
                  :disabled="!camposExibidos[index].exibeOvarDir2"
                 
                  class="flex-grow-0 mx-2"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  v-model="laudo.ovarioDireitoUm"
                ></v-text-field>
                <span
                  v-if="laudo.ovaDir === 'corpo lúteo hemorrágico medindo'"
                  :class="
                    !camposExibidos[index].exibeOvarDir2 ? 'text--disabled' : ''
                  "
                  >x
                </span>
                <v-text-field
                  v-if="laudo.ovaDir === 'corpo lúteo hemorrágico medindo'"
                  required
                  :disabled="!camposExibidos[index].exibeOvarDir2"
                 
                  class="flex-grow-0 mx-2"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  v-model="laudo.ovarioDireitoDois"
                ></v-text-field>
                <span
                  v-if="laudo.ovaDir === 'corpo lúteo hemorrágico medindo'"
                  :class="
                    !camposExibidos[index].exibeOvarDir2 ? 'text--disabled' : ''
                  "
                  >x</span
                >
                <v-text-field
                  v-if="laudo.ovaDir === 'corpo lúteo hemorrágico medindo'"
                  required
                  :disabled="!camposExibidos[index].exibeOvarDir2"
                 
                  class="flex-grow-0 mx-2"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  v-model="laudo.ovarioDireitoTres"
                ></v-text-field>
                <span
                  v-if="laudo.ovaDir === 'corpo lúteo hemorrágico medindo'"
                  :class="
                    !camposExibidos[index].exibeOvarDir2 ? 'text--disabled' : ''
                  "
                  >cm de diâmetro, com volume de</span
                >
                <v-text-field
                  v-if="laudo.ovaDir === 'corpo lúteo hemorrágico medindo'"
                  required
                  :disabled="!camposExibidos[index].exibeOvarDir2"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                 
                  class="flex-grow-0 mx-2"
                  dense
                  v-model="laudo.ovarioDireitoQuatro"
                ></v-text-field>
                <!-- :value="calculoVolumeDireito()" -->
                <span
                  v-if="laudo.ovaDir === 'corpo lúteo hemorrágico medindo'"
                  :class="
                    !camposExibidos[index].exibeOvarDir2 ? 'text--disabled' : ''
                  "
                  >cm³</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    !camposExibidos[index].exibeOvarDir2 &&
                    laudo.ovaDir === 'corpo lúteo hemorrágico medindo'
                  "
                  @click="
                    camposExibidos[index].exibeOvarDir2 = true;
                    laudo.ovarioDireitoUm = undefined;
                    laudo.ovarioDireitoDois = undefined;
                    laudo.ovarioDireitoTres = undefined;
                    laudo.ovarioDireitoQuatro = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    camposExibidos[index].exibeOvarDir2 &&
                    laudo.ovaDir === 'corpo lúteo hemorrágico medindo'
                  "
                  @click="
                    camposExibidos[index].exibeOvarDir2 = false;
                    laudo.ovarioDireitoUm = undefined;
                    laudo.ovarioDireitoDois = undefined;
                    laudo.ovarioDireitoTres = undefined;
                    laudo.ovarioDireitoQuatro = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeOvarEsquerdo
                    ? 'text--disabled'
                    : ''
                "
              >
                <span><b>Esquerdo: </b></span>
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeOvarEsquerdo"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="[
                    'ecograficamente normal',
                    'corpo lúteo sólido',
                    'corpo lúteo hemorrágico medindo',
                    'não identificado',
                    'Outro'
                  ]"
                  v-model="laudo.ovaEsq"
                ></v-select>
                <v-text-field
                  v-if="laudo.ovaEsq === 'Outro'"
                  required
                  :disabled="!camposExibidos[index].exibeOvarDir2"
                 
                  class="flex-grow-0 mx-2"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  v-model="laudo.ovaEsqOutro"
                ></v-text-field>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeOvarEsquerdo"
                  @click="
                    camposExibidos[index].exibeOvarEsquerdo = true;
                    laudo.ovaEsq = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeOvarEsquerdo"
                  @click="
                    camposExibidos[index].exibeOvarEsquerdo = false;
                    laudo.ovaEsq = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <v-text-field
                  v-if="laudo.ovaEsq === 'corpo lúteo hemorrágico medindo'"
                  required
                  :disabled="!camposExibidos[index].exibeOvarEsq2"
                 
                  class="flex-grow-0 mx-2"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  v-model="laudo.ovarioEsqUm"
                ></v-text-field>
                <span
                  v-if="laudo.ovaEsq === 'corpo lúteo hemorrágico medindo'"
                  :class="
                    !camposExibidos[index].exibeOvarEsq2 ? 'text--disabled' : ''
                  "
                  >x
                </span>
                <v-text-field
                  v-if="laudo.ovaEsq === 'corpo lúteo hemorrágico medindo'"
                  required
                  :disabled="!camposExibidos[index].exibeOvarEsq2"
                 
                  class="flex-grow-0 mx-2"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  v-model="laudo.ovarioEsqDois"
                ></v-text-field>
                <span
                  v-if="laudo.ovaEsq === 'corpo lúteo hemorrágico medindo'"
                  :class="
                    !camposExibidos[index].exibeOvarEsq2 ? 'text--disabled' : ''
                  "
                  >x</span
                >
                <v-text-field
                  v-if="laudo.ovaEsq === 'corpo lúteo hemorrágico medindo'"
                  required
                  :disabled="!camposExibidos[index].exibeOvarEsq2"
                 
                  class="flex-grow-0 mx-2"
                  dense
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  v-model="laudo.ovarioEsqTres"
                ></v-text-field>
                <span
                  v-if="laudo.ovaEsq === 'corpo lúteo hemorrágico medindo'"
                  :class="
                    !camposExibidos[index].exibeOvarEsq2 ? 'text--disabled' : ''
                  "
                  >cm de diâmetro, com volume de</span
                >
                <v-text-field
                  v-if="laudo.ovaEsq === 'corpo lúteo hemorrágico medindo'"
                  required
                  :disabled="!camposExibidos[index].exibeOvarEsq2"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                 
                  class="flex-grow-0 mx-2"
                  dense
                  v-model="laudo.ovarioEsqQuatro"
                ></v-text-field>
                <!-- :value="calculoVolumeDireito()" -->
                <span
                  v-if="laudo.ovaEsq === 'corpo lúteo hemorrágico medindo'"
                  :class="
                    !camposExibidos[index].exibeOvarEsq2 ? 'text--disabled' : ''
                  "
                  >cm³</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    !camposExibidos[index].exibeOvarEsq2 &&
                    laudo.ovaEsq === 'corpo lúteo hemorrágico medindo'
                  "
                  @click="
                    camposExibidos[index].exibeOvarEsq2 = true;
                    laudo.ovarioEsqUm = undefined;
                    laudo.ovarioEsqDois = undefined;
                    laudo.ovarioEsqTres = undefined;
                    laudo.ovarioEsqQuatro = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="
                    camposExibidos[index].exibeOvarEsq2 &&
                    laudo.ovaEsq === 'corpo lúteo hemorrágico medindo'
                  "
                  @click="
                    camposExibidos[index].exibeOvarEsq2 = false;
                    laudo.ovarioEsqUm = undefined;
                    laudo.ovarioEsqDois = undefined;
                    laudo.ovarioEsqTres = undefined;
                    laudo.ovarioEsqQuatro = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeDopCol ? 'text--disabled' : ''
                "
              >
                <span><b>Doppler colorido: </b></span>
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeDopCol"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="[
                    'corpo lúteo bem vascularizado',
                    'corpo lúteo pouco vascularizado',
                    'vascularização do corpo lúteo não detectada',
                  ]"
                  v-model="laudo.dopplerCol"
                ></v-select>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDopCol"
                  @click="
                    camposExibidos[index].exibeDopCol = true;
                    laudo.dopplerCol = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeDopCol"
                  @click="
                    camposExibidos[index].exibeDopCol = false;
                    laudo.dopplerCol = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center pt-0">
                <v-textarea
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  filled
                  outlined
                  no-resize
                  dense
                  label="Comentários adicionais:"
                  v-model="laudo.comentariosAdcionais"
                >
                </v-textarea>
              </v-col>

              <!-- <v-col
              cols="12"
              dense
              class="d-flex justify-start align-center"
              :class="
                !camposExibidos[index].exibeRegiaoAnexiais
                  ? 'text--disabled'
                  : ''
              "
            >
              <span
                >Ovários identificados de aspecto morfológico dentro da
                normalidade, destacando-se a presença do corpo lúteo no
                parênquima ovariano direito esquerdo.</span
              >
              <v-btn
                dense
                depressed
                text
                icon
                v-if="!camposExibidos[index].exibeRegiaoAnexiais"
                @click="camposExibidos[index].exibeRegiaoAnexiais = true"
                ><v-icon>mdi-eye-off</v-icon></v-btn
              >
              <v-btn
                dense
                depressed
                text
                icon
                v-if="camposExibidos[index].exibeRegiaoAnexiais"
                @click="camposExibidos[index].exibeRegiaoAnexiais = false"
                ><v-icon>mdi-eye</v-icon></v-btn
              >
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-start align-center"
              :class="
                !camposExibidos[index].exibeCorpoLuteo ? 'text--disabled' : ''
              "
            >
              <span>Corpo lúteo localizado no parênquima ovariano</span>
              <v-select
                required
                dense
                class="flex-grow-0 mx-2"
                validate-on-blur
                :disabled="!camposExibidos[index].exibeCorpoLuteo"
                :readonly="carregamentoDoLaudo || !podeEditar"
                :clearable="podeEditar"
                :items="['direito', 'esquerdo']"
                v-model="laudo.corpoLuteo"
              ></v-select>
              <span>.</span>
              <v-btn
                dense
                depressed
                text
                icon
                v-if="!camposExibidos[index].exibeCorpoLuteo"
                @click="camposExibidos[index].exibeCorpoLuteo = true"
                ><v-icon>mdi-eye-off</v-icon></v-btn
              >
              <v-btn
                dense
                depressed
                text
                icon
                v-if="camposExibidos[index].exibeCorpoLuteo"
                @click="
                  camposExibidos[index].exibeCorpoLuteo = false;
                  laudo.corpoLuteo = undefined;
                "
                ><v-icon>mdi-eye</v-icon></v-btn
              >
            </v-col> -->

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeLiquidoLivre
                    ? 'text--disabled'
                    : ''
                "
              >
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeLiquidoLivre"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="['Ausência', 'Presença']"
                  v-model="laudo.liquidoLivre"
                ></v-select>
                <span
                  >de líquido livre em escavação retrouterina e ou
                  parauterinas.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeLiquidoLivre"
                  @click="camposExibidos[index].exibeLiquidoLivre = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeLiquidoLivre"
                  @click="
                    camposExibidos[index].exibeLiquidoLivre = false;
                    laudo.liquidoLivre = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h4>Informações Adicionais:</h4>
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeGestacaoTopicaUnica
                    ? 'text--disabled'
                    : ''
                "
              >
                <span>Gestação </span>
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeGestacaoTopicaUnica"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="[
                    'tópica',
                    'outros'
                  ]"
                  v-model="laudo.gestacaoTopicaUnica"
                ></v-select>
                <v-text-field
                  v-if="laudo.gestacaoTopicaUnica === 'outros'"
                  required
                  :disabled="!camposExibidos[index].exibeOvarEsq2"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                 
                  class="flex-grow-0 mx-2"
                  dense
                  v-model="laudo.gestacaoTopicaUnicaOutros"
                ></v-text-field>
                <v-select
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exibeGestacaoTopicaUnica"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :items="[
                  
                    'evolutiva',
                    'de evolução incerta',
                    'não evolutiva',
                    'incipiente',
                  ]"
                  v-model="laudo.gestacaoTopicaUnica2"
                ></v-select>
                
                <!-- <span v-if="laudo.gestacaoTopicaUnica === 'evolutiva'">
                baseada na biometria embrionária (CCN)</span
              > -->
                <!-- <span>, evolutiva.</span> -->
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeGestacaoTopicaUnica"
                  @click="camposExibidos[index].exibeGestacaoTopicaUnica = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeGestacaoTopicaUnica"
                  @click="
                    camposExibidos[index].exibeGestacaoTopicaUnica = false;
                    laudo.gestacaoTopicaUnica = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exibebiometriaEmbrionariaSemanas
                      ? 'text--disabled'
                      : ''
                  "
                  >Idade gestacional pelo CCN de</span
                >
                
                <v-text-field
                  class="mx-2 flex-grow-0"
                  
                  
                  :clearable="podeEditar"
                  :disabled="
                    !camposExibidos[index].exibebiometriaEmbrionariaSemanas
                  "
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.biometriaEmbrionariaSemanas"
                />
                
                <span
                  :class="
                    !camposExibidos[index].exibebiometriaEmbrionariaSemanas
                      ? 'text--disabled'
                      : ''
                  "
                  >semanas e
                </span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibebiometriaEmbrionariaSemanas"
                  @click="
                    camposExibidos[
                      index
                    ].exibebiometriaEmbrionariaSemanas = true
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibebiometriaEmbrionariaSemanas"
                  @click="
                    camposExibidos[
                      index
                    ].exibebiometriaEmbrionariaSemanas = false;
                    laudo.biometriaEmbrionariaSemanas = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
               
                <v-text-field
                  class="mx-2 flex-grow-0"
                 
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="
                    !camposExibidos[index].exibebiometriaEmbrionariaDias
                  "
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.biometriaEmbrionariaDias"
                />

                <span
                  :class="
                    !camposExibidos[index].exibebiometriaEmbrionariaDias
                      ? 'text--disabled'
                      : ''
                  "
                  >dias,</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibebiometriaEmbrionariaDias"
                  @click="
                    camposExibidos[index].exibebiometriaEmbrionariaDias = true
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibebiometriaEmbrionariaDias"
                  @click="
                    camposExibidos[index].exibebiometriaEmbrionariaDias = false;
                    laudo.biometriaEmbrionariaDias = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <v-col cols="12" md="3" sm="12">
                  <v-select
                    :items="['compatível', 'incompatível']"
                    :disabled="
                      !camposExibidos[index].exibebiometriaEmbrionariaComp
                    "
                    required
                    clearable
                    validate-on-blur
                    v-model="laudo.compativelIncompativel"
                  >
                  </v-select>
                </v-col>
                <span
                  :class="
                    !camposExibidos[index].exibebiometriaEmbrionariaComp
                      ? 'text--disabled'
                      : ''
                  "
                >
                  com o datismo menstrual. {{ laudo.compativelIncompativel == 'incompatível'? '(Erro de data, devendo ser considerada a data provável do parto pela biometria atual)' : '' }}</span
                >
                
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibebiometriaEmbrionariaComp"
                  @click="
                    camposExibidos[index].exibebiometriaEmbrionariaComp = true
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibebiometriaEmbrionariaComp"
                  @click="
                    camposExibidos[index].exibebiometriaEmbrionariaComp = false;
                    laudo.compativelIncompativel = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exDataProvavelParto
                    ? 'text--disabled'
                    : ''
                "
              >
                <span
                  :class="
                    !camposExibidos[index].exDataProvavelParto
                      ? 'text--disabled'
                      : ''
                  "
                  >Data provável do parto pela biometria atual:</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  :disabled="!camposExibidos[index].exDataProvavelParto"
                  type="date"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  
                  max="2050/01/01"
                  :rules="[validateDate]"
                  
                  v-model="laudo.dataProvavelParto"
                ></v-text-field>
                
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exDataProvavelParto"
                  @click="camposExibidos[index].exDataProvavelParto = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exDataProvavelParto"
                  @click="
                    camposExibidos[index].exDataProvavelParto = false;
                    laudo.dataProvavelParto = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeEmbriaoeVesicula
                    ? 'text--disabled'
                    : ''
                "
              >
                <span
                  >Embrião e vesícula vitelínica ainda não identificados.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeEmbriaoeVesicula"
                  @click="camposExibidos[index].exibeEmbriaoeVesicula = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeEmbriaoeVesicula"
                  @click="camposExibidos[index].exibeEmbriaoeVesicula = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeEmbriaoSo ? 'text--disabled' : ''
                "
              >
                <span>Embrião ainda não identificado.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeEmbriaoSo"
                  @click="camposExibidos[index].exibeEmbriaoSo = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeEmbriaoSo"
                  @click="camposExibidos[index].exibeEmbriaoSo = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeObitoEmbrionario
                    ? 'text--disabled'
                    : ''
                "
              >
                <span>Óbito embrionário.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeObitoEmbrionario"
                  @click="camposExibidos[index].exibeObitoEmbrionario = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeObitoEmbrionario"
                  @click="camposExibidos[index].exibeObitoEmbrionario = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeGestacaoAnembrionada
                    ? 'text--disabled'
                    : ''
                "
              >
                <span>Gestação anembrionada.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeGestacaoAnembrionada"
                  @click="
                    camposExibidos[index].exibeGestacaoAnembrionada = true
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeGestacaoAnembrionada"
                  @click="
                    camposExibidos[index].exibeGestacaoAnembrionada = false
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              

             

              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeAvaliacao7Dias
                    ? 'text--disabled'
                    : ''
                "
              >
                <span
                  >Sugiro nova avaliação em 7 dias para adequada caracterização
                  da vitalidade embrionária.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeAvaliacao7Dias"
                  @click="camposExibidos[index].exibeAvaliacao7Dias = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeAvaliacao7Dias"
                  @click="camposExibidos[index].exibeAvaliacao7Dias = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeAvaliacao14Dias
                    ? 'text--disabled'
                    : ''
                "
              >
                <span
                  >Sugiro nova avaliação em 14 dias para adequada caracterização
                  da vitalidade embrionária.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeAvaliacao14Dias"
                  @click="camposExibidos[index].exibeAvaliacao14Dias = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeAvaliacao14Dias"
                  @click="camposExibidos[index].exibeAvaliacao14Dias = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeDesenvolvimentoAdequado
                    ? 'text--disabled'
                    : ''
                "
              >
                <span>Desenvolvimento adequado para a idade gestacional.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeDesenvolvimentoAdequado"
                  @click="
                    camposExibidos[index].exibeDesenvolvimentoAdequado = true
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeDesenvolvimentoAdequado"
                  @click="
                    camposExibidos[index].exibeDesenvolvimentoAdequado = false
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                dense
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exibeconclusaoFinal
                    ? 'text--disabled'
                    : ''
                "
              >
                <h4>
                  A ultrassonografia obstétrica em gestação inicial não permite
                  o rastreamento ou diagnóstico de malformações fetais ou
                  doenças cromossômicas/genéticas.
                </h4>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeconclusaoFinal"
                  @click="camposExibidos[index].exibeconclusaoFinal = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeconclusaoFinal"
                  @click="camposExibidos[index].exibeconclusaoFinal = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center pt-0">
                <v-textarea
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  filled
                  outlined
                  no-resize
                  dense
                  label="Comentários adicionais:"
                  v-model="laudo.comentariosAdcionaisDois"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </template>
    <v-alert
         v-if="erroData"
          outlined
          type="warning"
          border="left"
          text=""
        >
        Verifique as datas deste laudo, algumas podem estar incorretas.
          
        
        </v-alert>
    <v-col cols="12" class="d-flex justify-start align-center pt-6">
      <v-btn
        v-if="podeEditar"
        color="primary"
        :loading="carregamentoDoLaudo"
        @click="
          () => {
            temLaudo ? editarLaudo() : salvarLaudo();
          }
        "
      >
        salvar laudo
      </v-btn>

      <v-btn
        color="primary"
        v-if="temLaudo && !podeEditar"
        @click="imprimirLaudo"
        :loading="loadingImprimir"
        class="ml-5"
      >
        Imprimir
      </v-btn>

      <v-btn
        color="primary"
        v-if="!podeEditar"
        class="ml-5"
        @click="podeEditar = true"
      >
        Editar
      </v-btn>

      <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        v-if="temLaudo"
        @click="adcionarConteudoEditorPersonalizado"
        class="ml-5"
      >
        {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
      </v-btn>
    </v-col>

    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import moment, { max } from 'moment';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import TNCalcs from '@/helpers/calculosTn';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';
import { VueEditor } from 'vue2-editor';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    barraDeBuscaExames,
    VueEditor,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      carregamentoDoLaudo: false,
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      quantidadeFolhasEditorPersonalizado: 0,
      laudoId: null,
      podeEditar: true,
      voltando: false,
      loadingImprimir: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      maxYear: 2024,
      dataErrorMessages: [],

      laudoPrint: '',
      corBarra: '',
      feto: '',
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,
      backGroundSaco: '',

      erroData: false,

      maxYear: 2024,
      minDate: new Date('2023-01-01'),
      maxDate: new Date('2000-12-31'),

      modal: {
        active: false,
        modalComponent: ModalLogin,
      },
      gestacaoText: [
        '',
        'única',
        'gemelar',
        'trigemelar',
        'quadrigemelar',
        'quíntuplo',
      ],

      camposExibidos: [
        {
          exibeReacao: true,
          exibevesiculaVitelinica: true,
          exibeLiquidoLivre: true,
          exibeOvarioEsq: true,
          exibeBiometriaEmbrionaria: true,
          exibebiometriaEmbrionariaSemanas: true,
          exDataProvavelParto: true,
          exibeAvaliacao7Dias: false,
          exibeUteroSituado: true,
          exibeconclusaoFinal: false,
          exibeAvaliacao14Dias: false,
          exibeDesenvolvimentoAdequado: false,
          exibeAreaHematoma: true,
          exibeGestacaoTopicaUnica: true,
          exibeVesiculaDiametro: true,
          exibeCorpoLuteo: true,
          exibeRegiaoAnexiais: true,
          exibeGestacaoAnembrionada: false,
          exibeConteudo: true,
          exibeEmbriaoeVesicula: false,
          exibeObitoEmbrionario: false,
          exibebiometriaEmbrionariaComp: true,
          exFraseSinais2: true,
          exibeDiametro: true,
          exibeOvarEsquerdo: true,
          exibeEmbriao: true,
          exFraseSinais: true,
          exibeTransdutor: true,
          exDatMens: true,
          exUteroAumentado: true,
          exUteroAumentado2: true,
          exUteroAumentado3: true,
          exPresenca: true,
          exFraseSinais23: true,
          exVesiculaVit: true,
          exibeUteroGestUn: true,
          exSacoGest: true,
          exBatCard: true,
          exTrofo: true,
          exAusenciaHigro: true,
          exColoUter: true,
          exOvaDir: true,
          exOvaEsq: true,
          exCorpLuteo: true,
          exGestavaoInci: true,
          exObservamos: true,
          exHematoma: true,
          exDataParto: true,
          exAnteriorPost: true,
          exibeIndicacaoExame: true,
          exibePolipo: true,
          exibebiometriaEmbrionariaDias: true,
          exibeOvarioDir: true,
          exibeOvarDir: true,
          exibeOvarDir2: true,
          exibeOvarEsq2: true,
          exibeDopCol: true,
          exibeEmbriaoSo: false,
        },
      ],

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],

      indicacao: ["Sangramento vaginal",'Cólica','Controle hematoma', 'Avaliação viabilidade','Rotina','Outros'],

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,
      tipoGemeo: [
        {
          text: 'única',
          value: 1,
        },
        {
          text: 'gemelar',
          value: 2,
        },
        {
          text: 'trigemelar',
          value: 3,
        },
        {
          text: 'quadrigemelar',
          value: 4,
        },
        {
          text: 'quíntuplo',
          value: 5,
        },
      ],
    };
  },

  watch: {
    'infosCompartilhadas.gestacao'(value) {
      const quantidadeDeLaudos = this.laudos.length;
      if (value === quantidadeDeLaudos) return;

      const diff = value - quantidadeDeLaudos;

      const novaQuantidade = [...Array(diff > 0 ? diff : diff * -1).keys()];

      if (diff > 0) novaQuantidade.forEach(() => this.laudos.push({}));
      else novaQuantidade.forEach(() => this.laudos.pop());
    },
    'infosCompartilhadas.dataUltimaMestrucao'(dataUltimaMestrucao) {
      this.$store.commit('Laudo/SET_INFO_COMPARTILHADAS', {
        semanasDUM:
          TNCalcs.idadeGestacionalMenstrualSemanas(dataUltimaMestrucao),
        diasDUM: TNCalcs.idadeGestacionalMenstrualDias(dataUltimaMestrucao),
      });
    },
  },

  computed: {
    temLaudo() {
      this.laudoId = this.$store.getters['Laudo/laudoId'];
      return !!this.$store.getters['Laudo/laudoId'];
    },

    infosCompartilhadas() {
      return this.$store.state.Laudo.infosCompartilhadas;
    },
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    laudos() {
      return this.$store.state.Laudo.laudos;
    },
    loading() {
      return this.$store.getters['Laudo/loading'];
    },
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    forceReRender() {
      this.componentKey++;
    },

    // calculoVolumeDireito() {
    //   let medida1 = parseFloat(ovarioDireitoUm);
    //   let medida2 = parseFloat(this.ovarioDireitoDois);
    //   let medida3 = parseFloat(this.ovarioDireitoTres);

    //   if (
    //     medida1 == undefined ||
    //     medida2 == undefined ||
    //     medida3 == undefined
    //   ) {
    //     return;
    //   }
    //   return medida1 * medida2 * medida3 * 0.45;
    // },

    mudaCorProgresso() {
      if (this.vesiculaVUm === null || this.vesiculaVUm === undefined) {
        this.corBarra = '';
        this.forceReRender();
      }
      if ((this.vesiculaVUm >= 3) & (this.vesiculaVUm <= 6)) {
        this.corBarra = 'green';
      } else {
        this.corBarra = 'red';
      }
    },
    // calculaSacoGestacional() {
    //   let semanas = this.laudo.dataGestacionalSemanas;
    //   let diametro = this.calculoDiametroSacoGestacional();
    //   if ((diametro >= 2) & (diametro <= 8)) {
    //     if (semanas == 4) {
    //       this.corSaco = 'green';
    //     } else {
    //       this.corSaco = 'red';
    //     }
    //   } else if ((diametro >= 6) & (diametro <= 16)) {
    //     if (semanas == 5) {
    //       this.corSaco = 'green';
    //     } else {
    //       this.corSaco = 'red';
    //     }
    //   } else if ((diametro >= 9) & (diametro <= 23)) {
    //     if (semanas == 6) {
    //       this.corSaco = 'green';
    //     } else {
    //       this.corSaco = 'red';
    //     }
    //   } else if ((diametro >= 15) & (diametro <= 31)) {
    //     if (semanas == 7) {
    //       this.corSaco = 'green';
    //     } else {
    //       this.corSaco = 'red';
    //     }
    //   } else if ((diametro >= 22) & (diametro <= 38)) {
    //     if (semanas == 8) {
    //       this.corSaco = 'green';
    //     } else {
    //       this.corSaco = 'red';
    //     }
    //   } else if ((diametro >= 28) & (diametro <= 37)) {
    //     if (semanas == 9) {
    //       this.corSaco = 'green';
    //     } else {
    //       this.corSaco = 'red';
    //     }
    //   } else if ((diametro >= 35) & (diametro <= 51)) {
    //     if (semanas == 10) {
    //       this.corSaco = 'green';
    //     } else {
    //       this.corSaco = 'red';
    //     }
    //   }
    // },
    // mudaCorSaco() {
    //   if (this.laudo.sacomm == null || this.laudo.sacomm == undefined) {
    //     this.corSaco = '';
    //     this.backGroundSaco = '';
    //   }
    //   if (this.laudo.sacomm == 7) {
    //     this.corSaco = 'red';
    //   }
    // },

    calculoDiametroSacoGestacional() {
      let m1 = this.saco1m1;
      let m2 = this.saco1m2;
      let m3 = this.saco1m3;
      if (m1 == undefined || m2 == undefined || m3 == undefined) {
        return;
      }
      return ((m1 + m2 + m3) / 3).toFixed(2);
    },
    
    calculaImc(){
      
      
      if(!this.laudos[0].altura || !this.laudos[0].peso) {
        return}
      let altura = this.laudos[0].altura
      let peso = this.laudos[0].peso
      altura = altura / 100; // converter para metros

    // Calculando o IMC
      const imc = peso / (altura * altura);
      this.laudos[0].imc = imc.toFixed(2)
      
      this.$forceUpdate()

    },
    calcularIdadeEMeses() {
    const dataNascimento = moment(this.laudos[0].dataNascimento);
    const hoje = moment();
    

    const anos = hoje.diff(dataNascimento, 'years');
    dataNascimento.add(anos, 'years');
    const meses = hoje.diff(dataNascimento, 'months');
    
      this.laudos[0].anos = anos
      this.laudos[0].meses = meses
      
      this.$forceUpdate()
    },
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudos[0].folhas) {
        this.conteudoEditorPersonalizado = this.laudos[0].folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }
      let folha = [];
      for (let i = 0; i < this.laudos.length; i++) {

        if(this.laudos.length > 1){
          folha.push(
            `<h4>Feto  ${i + 1}</h4>\n`
          );
        }

        if (this.laudos[i].indicacaoExame) {
          folha.push(
            `<span><strong>Indicação do exame:</strong> ${this.laudos[i].indicacaoExame == 'Outros'? this.laudos[i].indicacaoExameOutros:this.laudos[i].indicacaoExame }.</span>`
          );
        }
        if (this.infosCompartilhadas.dataUltimaMestrucao)
          folha.push(
            `Data da última menstruação: ${this.dataFormatada(
              this.infosCompartilhadas.dataUltimaMestrucao
            )}.\n`
          );

        if (
          this.infosCompartilhadas.semanasDUM ||
          this.infosCompartilhadas.diasDUM
        )
          folha.push(
            `Idade gestacional (DUM) ${
              this.infosCompartilhadas.semanasDUM
                ? this.infosCompartilhadas.semanasDUM
                : ''
            }${
              this.infosCompartilhadas.semanasDUM
                ? this.infosCompartilhadas.semanasDUM > 1
                  ? ' semanas'
                  : ' semana'
                : ''
            }${
              this.infosCompartilhadas.semanasDUM &&
              this.infosCompartilhadas.diasDUM
                ? ' e '
                : ''
            }${
              this.infosCompartilhadas.diasDUM
                ? this.infosCompartilhadas.diasDUM
                : ''
            }${
              this.infosCompartilhadas.diasDUM
                ? this.infosCompartilhadas.diasDUM > 1
                  ? ' dias'
                  : ' dia'
                : ''
            }.\n`
          );

         folha.push(`Gestação ${
              this.gestacaoText[this.laudos.length] 
            }${
              this.laudos.length == 2
                ?" " + this.laudos[i].gestacaoTres +
                  ' ' +
                  this.laudos[i].gestacaoQuatro
                : ''
            }${
              this.laudos.length > 2
                ? this.laudos[i].gestacaoCinco
                : ''
            }.\n`);
        
        folha.push('\n');

        folha.push(`<span><strong>História materna</strong></span>`);
      if (this.laudos[i].dataNascimento)
        folha.push(`Data de nascimento : ${this.dataFormatada(this.laudos[i].dataNascimento)}.\n`);
        if (this.laudos[i].anos || this.laudos[i].meses)
        folha.push(`${this.laudos[i].anos?this.laudos[i].anos + (this.laudos[i].anos > 1 ? ' anos':' ano'):''}${this.laudos[i].anos && this.laudos[i].meses? ' e ':''}${this.laudos[i].meses?this.laudos[i].meses + (this.laudos[i].meses > 1 ? ' meses':' mês'):''}.\n`);
        if (this.laudos[i].peso)
        folha.push(`${this.laudos[i].peso?"Peso: " + this.laudos[i].peso + ' kg.':""}${
      this.laudos[i].altura?" Altura: " + this.laudos[i].altura + ' cm.':""}${
      this.laudos[i].imc?" IMC: " + this.laudos[i].imc :""}.\n`);
      if (this.laudos[i].perdaGestacional)
      folha.push(`Perda gestacional: ${this.laudos[i].perdaGestacional}${
        [
                    'perda com menos de 11 semanas ',
                    'perda entre 11 e 15 semanas ',
                    'perda entre 16 e 20 semanas ',
                    'perda entre 21 e 28 semanas ',
                    'perda entre 29 e 34 semanas ',
                  ].includes(this.laudos[i].perdaGestacional)? '. Quantas: ' + 
                  this.laudos[i].quantasPercas + '' : ''
    }.\n`)
    if (this.laudos[i].doencaPreExistente)
      folha.push(`Doenças pré-existente: ${this.laudos[i].doencaPreExistente}${
    this.laudos[i].tipoDoencaPreExistente? ', '+  this.laudos[i].tipoDoencaPreExistente : ''}.\n`)
    if (this.laudos[i].fuma)
      folha.push(`Tabagismo durante a gravidez: ${this.laudos[i].fuma}.\n`)
      if (this.laudos[i].metodoContracepcao)
      folha.push(`Método de contracepção: ${this.laudos[i].metodoContracepcao}.\n`)
      folha.push(`\n`)
        if (this.laudos[i].uteroSituado)
          folha.push(
            `<span><strong>Útero:</strong>${
              this.laudos[i].uteroSituado
                ? ` em ${this.laudos[i].uteroSituado}.`
                : ''
            }${
              this.laudos[i].uteroAnormal
                ? ` Colo uterino e canal endocervical ${
                    this.laudos[i].uteroAnormal
                      ? this.laudos[i].uteroAnormal
                      : ''
                  }${
                    this.laudos[i].polipoEndocervical
                      ? ' medindo ' + this.laudos[i].polipoEndocervical + ' mm'
                      : ''
                  }.`
                : ''
            }</span>\n`
          );
          folha.push(`<h4>Cavidade uterina</h4>`);
          if (this.laudos[0].temHematoma)
        folha.push(`Hematoma subcoriônico: ${this.laudos[0].temHematoma}.`);
    
      

      if (this.laudos[0].temHematoma == 'Sim')
        folha.push(
          ` Localizado na porção ${
            this.laudos[0].areaHematoma
          } do saco gestacional, ${
            this.laudos[0].corion ? 'acometendo o córion' : ''
          } ${this.laudos[0].corion}, medindo ${this.laudos[0].corion1} x ${
            this.laudos[0].corion2
          } mm${this.laudos[0].acometSacGest? (', acometendo ' + this.laudos[0].acometSacGest + ' do saco gestacional' ):''}.\n`
        );

        if (this.laudos[i].sacoGestacional)
          folha.push(
            `<span><strong>Saco gestacional:</strong>${
              this.laudos[i].sacoGestacional
                ? ` ${this.laudos[i].sacoGestacional}`
                : ''
            }${
              this.laudos[i].sacoGestacional3
                ? ` com paredes ${
                    this.laudos[i].sacoGestacional3
                      ? this.laudos[i].sacoGestacional3
                      : ''
                  }${
                    this.laudos[i].diametroMedio
                      ? '. Diâmetro médio de ' +
                        this.laudos[i].diametroMedio +
                        ' mm'
                      : ''
                  }.`
                : ''
            }</span>`
          );

        // if (this.camposExibidos[i].exFraseSinais2 === true)
        //   folha.push(
        //     `<span><strong>Saco amniótico</strong> filiforme junto ao embrião.</span>`
        //   );
          if (this.camposExibidos[i].exFraseSinais23 === true && this.laudos[i].fraseSinaisFetEmb)
          folha.push(
            `<span><strong>Saco amniótico</strong> filiforme junto ao ${this.laudos[i].fraseSinaisFetEmb}.</span>`
          );

        if (this.laudos[i].embriaoEFeto === 'Embrião')-
          folha.push(
            `${
              this.laudos[i].embriaoBpm
                ? `<span><strong>Embrião</strong> identificado no interior do saco amniótico, com CCN medindo ${
                    this.laudos[i].embriaoBpm ? this.laudos[i].embriaoBpm + ' mm' : ''
                  }${
                    this.laudos[i].embriao
                      ? '. Atividade cardíaca ' + this.laudos[i].embriao 
                      : ''
                  }${this.laudos[i].embriaoBpm2? ', com frequência de ' +
                      this.laudos[i].embriaoBpm2 + ' bpm' :''

                  }.`
                : ''
            }</span>`
          );
        if (this.laudos[i].embriaoEFeto === 'Feto')
          folha.push(
            `${
              this.laudos[i].embriaoBpm
                ? `<span><strong>Feto</strong> identificado no interior do saco amniótico, com CCN medindo ${
                    this.laudos[i].embriaoBpm ? this.laudos[i].embriaoBpm + ' mm': ''
                  }${
                    this.laudos[i].embriao
                      ? '. Atividade cardíaca ' + this.laudos[i].embriao  
                      : ''
                  }${this.laudos[i].embriaoBpm2? ', com frequência de ' +
                      this.laudos[i].embriaoBpm2 + ' bpm' :''

                  }.`
                : ''
            }</span>`
          );

        // if (this.laudos[i].embriaoEFeto)
        //   folha.push(
        //     `<span><strong>${
        //       this.laudos[i].embriaoEFeto
        //     }</strong> identificado no interior do saco amniótico, com comprimento cabeça nádegas (CCN) médio de ${
        //       this.laudos[i].embriaoBpm
        //     } mm. Atividade cardíaca ${
        //       this.laudos[i].embriao == 'presente'
        //         ? this.laudos[i].embriao +
        //           ' ' +
        //           this.laudos[i].embriaoBpm2 +
        //           ' bpm'
        //         : this.laudos[i].embriao
        //     }.</span>`
        //   );

        if (
          this.laudos[i].vesiculaVitelinica &&
          this.laudos[i].vesiculaContornos
        )
          folha.push(`<span><strong>Vesícula vitelínica</strong>${
            this.camposExibidos[i].exibevesiculaVitelinica &&
            this.laudos[i].vesiculaVitelinica &&
            this.laudos[i].vesiculaContornos
              ? ` ${this.laudos[i].vesiculaVitelinica}, ${this.laudos[i].vesiculaContornos}`
              : ''
          }${
            this.camposExibidos[i].exibeConteudo &&
            this.laudos[i].vesiculaConteudo
              ? `, conteúdo ${this.laudos[i].vesiculaConteudo}`
              : ''
          }${
            this.camposExibidos[i].exibeVesiculaDiametro &&
            this.laudos[i].vesiDiametro
              ? `, medindo ${this.laudos[i].vesiDiametro} mm de diâmetro.`
              : '.'
          }
</span>`);
        // if (this.laudos[i].vesiculaVitelinica === 'não identificada')
        //   folha.push(`VesiVite${i}\n`);

        //       if (this.camposExibidos[i].exibeRegiaoAnexiais === true)
        //         folha.push(`Ovários identificados de aspecto morfológico dentro da normalidade, destacando-se a
        // presença do corpo lúteo no parênquima ovariano direito esquerdo.`);

        if (this.laudos[i].corpoLuteo)
          folha.push(
            `Corpo lúteo localizado no parênquima ovariano ${this.laudos[i].corpoLuteo}.\n`
          );

        if (this.laudos[i].ovaDir || this.laudos[i].ovaEsq)
          folha.push(`\n<h4>Ovários</h4>`);
        if (this.laudos[i].ovaDir)
          folha.push(
            `<span><strong>Direito: </strong>${
              this.laudos[i].ovaDir === 'corpo lúteo hemorrágico medindo'
                ? this.laudos[i].ovaDir +
                  ' ' +
                  this.laudos[i].ovarioDireitoUm +
                  ' x ' +
                  this.laudos[i].ovarioDireitoDois +
                  ' x ' +
                  this.laudos[i].ovarioDireitoTres +
                  ' cm de diâmetro' +
                  (this.laudos[i].ovarioDireitoQuatro
                    ? ', com volume de ' +
                      this.laudos[i].ovarioDireitoQuatro +
                      ' cm³'
                    : '')
                : (this.laudos[i].ovaDir == 'Outro'? this.laudos[i].ovaDirOutro : this.laudos[i].ovaDir)
            }.</span>`
          );
        if (this.laudos[i].ovaEsq)
          folha.push(
            `<span><strong>Esquerdo: </strong>${
              this.laudos[i].ovaEsq === 'corpo lúteo hemorrágico medindo'
                ? this.laudos[i].ovaEsq +
                  ' ' +
                  this.laudos[i].ovarioEsqUm +
                  ' x ' +
                  this.laudos[i].ovarioEsqDois +
                  ' x ' +
                  this.laudos[i].ovarioEsqTres +
                  ' cm de diâmetro' +
                  (this.laudos[i].ovarioEsqQuatro
                    ? ', com volume de ' +
                      this.laudos[i].ovarioEsqQuatro +
                      ' cm³'
                    : '')
                : (this.laudos[i].ovaEsq == 'Outro'? this.laudos[i].ovaEsqOutro:this.laudos[i].ovaEsq)
            }.</span>`
          );
        if (this.laudos[i].dopplerCol)
          folha.push(
            `<span><strong>Doppler colorido: </strong>${this.laudos[i].dopplerCol}.</span>`
          );
        folha.push('\n');

        if (this.laudos[i].comentariosAdcionais)
          folha.push(`${this.laudos[i].comentariosAdcionais}\n`);

        if (this.laudos[i].liquidoLivre)
          folha.push(
            `${this.laudos[i].liquidoLivre} de líquido livre em escavação retrouterina e ou parauterinas.\n`
          );
        folha.push('\n');

        if (this.laudos[i].gestacaoTopicaUnica)
          folha.push(`<span><strong>Informações adicionais </strong></span>`);

        if (
          this.laudos[i].gestacaoTopicaUnica
        )
          folha.push(
            `Gestação ${this.laudos[i].gestacaoTopicaUnica === 'outros'?
        this.laudos[i].gestacaoTopicaUnicaOutros : this.laudos[i].gestacaoTopicaUnica
      }${ this.laudos[i].gestacaoTopicaUnica2? ', ' + this.laudos[i].gestacaoTopicaUnica2: ''}.\n`
          );

        

        if (this.laudos[i].biometriaEmbrionariaSemanas)
          folha.push(
            `Idade gestacional pelo CCN de ${
              this.laudos[i].biometriaEmbrionariaSemanas
                ? this.laudos[i].biometriaEmbrionariaSemanas +
                  (this.laudos[i].biometriaEmbrionariaSemanas > 1
                    ? ' semanas'
                    : ' semana')
                : ''
            }${
              this.laudos[i].biometriaEmbrionariaSemanas &&
              this.laudos[i].biometriaEmbrionariaDias
                ? ' e '
                : ''
            }${
              this.laudos[i].biometriaEmbrionariaDias
                ? this.laudos[i].biometriaEmbrionariaDias +
                  (this.laudos[i].biometriaEmbrionariaDias > 1
                    ? ' dias'
                    : ' dia')
                : ''
            }${
              this.laudos[i].compativelIncompativel
                ? ', ' +
                  this.laudos[i].compativelIncompativel +
                  ' com o datismo menstrual.'
                : ''
            }${
              this.laudos[i].compativelIncompativel== 'incompatível'? ' (Erro de data, devendo ser considerada a data provável do parto pela biometria atual).' : ''
            }\n`
          );
        if (this.laudos[i].dataProvavelParto)
          folha.push(
            `Data provável do parto pela biometria atual: ${this.dataFormatada(
              this.laudos[i].dataProvavelParto
            )}.\n`
          );
        if (this.camposExibidos[i].exibeEmbriaoeVesicula === true)
          folha.push(
            `Embrião e vesícula vitelínica ainda não identificados.\n`
          );
        if (this.camposExibidos[i].exibeEmbriaoSo === true)
          folha.push(`Embrião ainda não identificado.\n`);

        if (this.camposExibidos[i].exibeObitoEmbrionario === true)
          folha.push(`Óbito embrionário.\n`);

        if (this.camposExibidos[i].exibeGestacaoAnembrionada === true)
          folha.push(`Gestação anembrionada.\n`);
          

        // if (this.laudos[i].areaHematoma) {
        //   folha.push(
        //     `Presença de hematoma subcoriônico na porção ${
        //       this.laudos[i].areaHematoma
        //     } do saco gestacional, ${
        //       this.laudos[i].corion ? 'acometendo o córion' : ''
        //     } ${
        //       this.laudos[i].corion ? `${this.laudos[i].corion},` : ''
        //     } medindo ${this.laudos[i].corion1 ?? ''} x ${
        //       this.laudos[i].corion2 ?? ''
        //     } mm${this.laudos[i].acometSacGest? (', acometendo ' + this.laudos[i].acometSacGest + ' do saco gestacional' ):''}.\n`
        //   );
        // }

        // if (this.laudos[i].areaHematoma)
        //   folha.push(
        //     `Presença de hematoma subcoriônico na porção ${
        //       this.laudos[i].areaHematoma
        //     } do saco gestacional, ${
        //       this.laudos[i].corion ? 'acometendo o córion' : ''
        //     } ${this.laudos[i].corion}, medindo ${this.laudos[i].corion1} x ${
        //       this.laudos[i].corion2
        //     } mm.\n`
        //   );

        if (this.camposExibidos[i].exibeAvaliacao7Dias === true)
          folha.push(
            `Sugiro nova avaliação em 7 dias para adequada caracterização da vitalidade embrionária.\n`
          );
        if (this.camposExibidos[i].exibeAvaliacao14Dias === true)
          folha.push(
            `Sugiro nova avaliação em 14 dias para adequada caracterização da vitalidade embrionária.\n`
          );
        if (this.camposExibidos[i].exibeDesenvolvimentoAdequado === true)
          folha.push(`Desenvolvimento adequado para a idade gestacional.\n`);
        if (this.laudos[i].comentariosAdcionaisDois)
          folha.push(`${this.laudos[i].comentariosAdcionaisDois}`);

        
        if (this.camposExibidos[i].exibeconclusaoFinal === true)
          folha.push(
            `\n<strong>A ultrassonografia obstétrica em gestação inicial não permite o rastreamento ou diagnóstico de malformações fetais ou doenças cromossômicas/genéticas.</strong>\n`
          );
      }

      this.conteudoEditorPersonalizado = [];

      while (folha.length > 0) {
        const teste = folha.splice(0, 50);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },
    juntaPersonalizadoAoLaudo() {
      this.laudos[0].folhas = this.conteudoEditorPersonalizado;
      this.laudos[0].titulo =
        'ULTRASSONOGRAFIA OBSTÉTRICA DE PRIMEIRO TRIMESTRE';
    },
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
    validateDate(value) {
      let dataMoment = moment(value)
      
      
      if (!value) {
      
      
        return 'Data inválida';
      }
     
      const [year,  month, day,] = value.split('-');

      if (year.length !== 4) {
        
        this.erroData = true
        return 'O ano deve ter 4 dígitos';
      }
      if (dataMoment.year() > 2100) {
        this.erroData = true
        return 'O ano não pode ser maior que 2100.';
      }
      if (dataMoment.year() < 1900) {
        this.erroData = true
        return 'O ano não pode ser menor que 1900.';
      }


      // const date = new Date(`${year}-${month}-${day}`);
      // if (isNaN(date.getTime())) {
      //   console.log("3")
      //   return 'Data inválida';
      // }
      this.erroData = false
     
      return true;
    },
    formatDate(date) {
      
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },

    forceReRender() {
      this.componentKey++;
    },
    ...mapActions('Laudo', ['getLaudos']),
    async salvarLaudo() {
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        this.carregamentoDoLaudo = false;

        return;
      } finally {
        this.carregamentoDoLaudo = false;
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            this.$store.commit('Laudo/SET_LOADING', true);
            if (!this.temLaudo) {
              this.$store.commit(
                'Laudo/SET_CAMPOS_EXIBIDOS',
                this.camposExibidos
              );
              await this.$store.dispatch(
                'Laudo/postLaudos',
                this.$route.query.exame
              );
            }
            await this.$store.dispatch('Consulta/patchConsulta', {
              consultaID: this.$route.query.consulta,
              path: 'status',
              value: 'LAUDADO',
            });
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: async (store, exame, consulta) => {
                await store.dispatch('Laudo/postLaudos', exame);
                await store.dispatch('Consulta/patchConsulta', {
                  consultaID: consulta,
                  path: 'status',
                  value: 'LAUDADO',
                });
              },
              exame: this.$route.query.exame,
              consulta: this.$route.query.consulta,
              codigo: 'POST_LAUDO_TN',
            });
            this.$store.commit('offline/SET_DADOS', this.laudos);
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.$store.commit('Laudo/SET_LOADING', false);
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        this.carregamentoDoLaudo = false;
        return;
      } finally {
        this.carregamentoDoLaudo = false;
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            this.$store.commit(
              'Laudo/SET_CAMPOS_EXIBIDOS',
              this.camposExibidos
            );
            await this.$store.dispatch('Laudo/pathLaudos');
            this.patchLaudoTn(this.laudoId, this.laudos);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoTnOffline.bind({}),
              laudoId: this.laudoId,
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO_TN',
            });
            this.$store.commit('offline/SET_DADOS', this.laudos);
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },
    formataLaudos() {
      if (this.exFraseSinais == true) {
        this.laudos[0].exFraseSinais = true;
      } else {
        this.laudos[0].exFraseSinais = false;
      }
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      this.loadingImprimir = true;
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.obstetrica1trimestre',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },

    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },


  
  async mounted() {
    this.$store.commit('Laudo/RESET_LAUDOS');

    if (!!this.$route.query?.exame) {
      this.$store.dispatch('Laudo/getLaudos', this.$route.query.exame);
    }
    this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }

    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.length > 0
    ) {
      this.camposExibidos = [];
      for (
        let i = 0;
        i < this.$store.getters['laudoSelecionado'].laudoJson.length;
        i++
      ) {
        this.camposExibidos.push(
          this.$store.getters['laudoSelecionado'].laudoJson[i].camposExibidos
        );
      }
    }

    for (let i = this.camposExibidos.length; i < 5; i++) {
      this.camposExibidos.push({
        exibeReacao: true,
        exibeOvarEsquerdo: true,
        exibeOvarEsquerdo: true,
        exibevesiculaVitelinica: true,
        exibeVesiculaDiametro: true,
        exibeGestacaoTopicaUnica: true,
        exibeDiametro: true,
        exibeAreaHematoma: true,
        exibeLiquidoLivre: true,
        exibebiometriaEmbrionariaSemanas: true,
        exDataProvavelParto: true,
        exibeBiometriaEmbrionaria: true,
        exibeAvaliacao14Dias: false,
        exibeDesenvolvimentoAdequado: false,
        exibeGestacaoAnembrionada: false,
        exibeAvaliacao7Dias: false,
        exibeOvarioEsq: true,
        exibeOvarDir: true,
        exibeOvarEsq: true,
        exibeUteroGestUn: true,
        exibeObitoEmbrionario: true,
        exibeEmbriaoeVesicula: true,
        exibebiometriaEmbrionariaComp: true,
        exibeUteroSituado: true,
        exibeCorpoLuteo: true,
        exibeDopCol: true,
        exibeRegiaoAnexiais: true,
        exFraseSinais2: true,
        exibeConteudo: true,
        exibeTransdutor: true,
        exibeEmbriao: true,
        exibeEmbriaoSo: false,
        exDatMens: true,
        exibebiometriaEmbrionariaDias: true,
        exUteroAumentado2: true,
        exUteroAumentado3: true,
        exUteroAumentado: true,
        exibeOvarEsq2: true,
        exFraseSinais: true,
        exPresenca: true,
        exVesiculaVit: true,
        exSacoGest: true,
        exBatCard: true,
        exTrofo: true,
        exAusenciaHigro: true,
        exColoUter: true,
        exOvaDir: true,
        exibeconclusaoFinal: false,
        exOvaEsq: true,
        exCorpLuteo: true,
        exibeOvarioDir: true,
        exGestavaoInci: true,
        exObservamos: true,
        exHematoma: true,
        exDataParto: true,
        exAnteriorPost: true,
        exibeIndicacaoExame: true,
        exibeOvarDir2: true,
        exibeOvarEsq: true,
        exibeOvarEsquerdo: true,
        exibePolipo: true,
        exFraseSinais23: true,
      });
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      //   this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
