<template>
  <v-container fluid>
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">
        ULTRASSONOGRAFIA MORFOLÓGICA 1° TRIMESTRE
      </h2>
    </v-card>

    <div class="pt-5">
      <v-row>
        <v-row class="align-center px-10 mt-2">
          <v-col cols="12" md="12" sm="12">
            <h4>Estática:</h4>
          </v-col>
        </v-row>
        <v-col
          cols="12"
          md="12"
          sm="12"
          class=""
          :class="!camposExibidos.exSituacao ? 'text--disabled' : ''"
        >
          <v-row class="align-center px-10">
            <span>Situação</span>
            <v-col cols="12" md="2" class="py-0">
              <v-select
                :items="['longitudinal.', 'transversa.']"
                required
                :disabled="!camposExibidos.exSituacao"
                :readonly="carregamentoDoLaudo || !podeEditar"
                :clearable="podeEditar"
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.situacao"
              ></v-select>
            </v-col>

            <v-btn
              dense
              depressed
              flat
              icon
              v-if="!camposExibidos.exSituacao"
              @click="
                camposExibidos.exSituacao = true;
                laudoSelecionado.laudoJson.situacao = undefined;
              "
              ><v-icon>mdi-eye</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              flat
              icon
              v-if="camposExibidos.exSituacao"
              @click="
                camposExibidos.exSituacao = false;
                laudoSelecionado.laudoJson.situacao = undefined;
              "
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
          </v-row>
        </v-col>

        <v-col
          cols="12"
          md="12"
          sm="12"
          :class="!camposExibidos.exApresentacao ? 'text--disabled' : ''"
        >
          <v-row class="align-center px-10 mt-n8">
            <span>Apresentação</span>
            <v-col cols="12" md="2" class="py-0">
              <v-select
                :items="['cefálica.', 'pélvica.', 'córmica.']"
                required
                :disabled="!camposExibidos.exApresentacao"
                :readonly="carregamentoDoLaudo || !podeEditar"
                :clearable="podeEditar"
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.apresentacao"
              ></v-select>
            </v-col>
            <v-btn
              dense
              depressed
              flat
              icon
              v-if="!camposExibidos.exApresentacao"
              @click="
                camposExibidos.exApresentacao = true;
                laudoSelecionado.laudoJson.apresentacao = undefined;
              "
              ><v-icon>mdi-eye</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              flat
              icon
              v-if="camposExibidos.exApresentacao"
              @click="
                camposExibidos.exApresentacao = false;
                laudoSelecionado.laudoJson.apresentacao = undefined;
              "
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
          </v-row>
        </v-col>

        <v-col
          cols="12"
          md="12"
          sm="12"
          :class="!camposExibidos.exDorsoFetal ? 'text--disabled' : ''"
        >
          <v-row class="align-center px-10 mt-n8">
            <span>Dorso fetal</span>
            <v-col cols="12" md="4" class="py-0">
              <v-select
                :items="[
                  'anterior à direita.',
                  'anterior à esquerda.',
                  'posterior à direita.',
                  'posterior   à esquerda.',
                  'posterior na linha mediana.',
                  'anterior na linha mediana.',
                ]"
                :disabled="!camposExibidos.exDorsoFetal"
                required
                :readonly="carregamentoDoLaudo || !podeEditar"
                :clearable="podeEditar"
                validate-on-blur
                v-model="laudoSelecionado.laudoJson.dorsoFetal"
              ></v-select>
            </v-col>
            <v-btn
              dense
              depressed
              flat
              icon
              v-if="!camposExibidos.exDorsoFetal"
              @click="
                camposExibidos.exDorsoFetal = true;
                laudoSelecionado.laudoJson.dorsoFetal = undefined;
              "
              ><v-icon>mdi-eye</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              flat
              icon
              v-if="camposExibidos.exDorsoFetal"
              @click="
                camposExibidos.exDorsoFetal = false;
                laudoSelecionado.laudoJson.dorsoFetal = undefined;
              "
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-row class="align-center px-7">
      <v-col cols="12" md="12" sm="12">
        <h4>Vitalidade:</h4>
      </v-col>
    </v-row>
    <v-row
      class="align-center px-10 mt-n4"
      :class="!camposExibidos.exMovFetaisPresentes ? 'text--disabled' : ''"
    >
      <span>Movimentos fetais presentes</span>
      <v-col cols="12" md="2" class="py-0">
        <v-select
          :items="['Sim.', 'Não.']"
          required
          :disabled="!camposExibidos.exMovFetaisPresentes"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.movimentosFetaisPresentes"
        ></v-select>
      </v-col>
      <v-btn
        dense
        depressed
        flat
        icon
        v-if="!camposExibidos.exMovFetaisPresentes"
        @click="
          camposExibidos.exMovFetaisPresentes = true;
          laudoSelecionado.laudoJson.movimentosFetaisPresentes = undefined;
        "
        ><v-icon>mdi-eye</v-icon></v-btn
      >
      <v-btn
        dense
        depressed
        flat
        icon
        v-if="camposExibidos.exMovFetaisPresentes"
        @click="
          camposExibidos.exMovFetaisPresentes = false;
          laudoSelecionado.laudoJson.movimentosFetaisPresentes = undefined;
        "
        ><v-icon>mdi-eye-off</v-icon></v-btn
      >
    </v-row>

    <v-col cols="12" md="12" sm="12">
      <v-row
        class="align-center px-7 mt-n6"
        :class="!camposExibidos.exBatimentoCardFetais ? 'text--disabled' : ''"
      >
        <span>Batimentos cardiacos fetais</span>
        <v-col cols="12" md="3" sm="12" class="py-0">
          <v-select
            required
            validate-on-blur
            :items="['regulares.', 'com ritmo cardíaco irregular']"
            v-model.number="
              laudoSelecionado.laudoJson.batimentosCardiacosFetais
            "
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exBatimentoCardFetais"
            :clearable="podeEditar"
          ></v-select>
        </v-col>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exBatimentoCardFetais"
          @click="
            camposExibidos.exBatimentoCardFetais = true;
            laudoSelecionado.laudoJson.batimentosCardiacosFetais = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exBatimentoCardFetais"
          @click="
            camposExibidos.exBatimentoCardFetais = false;
            laudoSelecionado.laudoJson.batimentosCardiacosFetais = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-7 mt-n6 pb-6"
        :class="!camposExibidos.exFreqBpm ? 'text--disabled' : ''"
      >
        <span>Frequência de</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exFreqBpm"
            validate-on-blur
            v-mask="''"
            v-model="laudoSelecionado.laudoJson.frequenciaBpm"
          ></v-text-field>
        </v-col>
        <span>bpm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exFreqBpm"
          @click="
            camposExibidos.exFreqBpm = true;
            laudoSelecionado.laudoJson.frequenciaBpm = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exFreqBpm"
          @click="
            camposExibidos.exFreqBpm = false;
            laudoSelecionado.laudoJson.frequenciaBpm = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row class="align-center px-4 mt-n4 mb-3">
        <v-col cols="12" md="12" sm="12" class="py-0">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.comentariosAdicionais1"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-divider class="py-3"></v-divider>
      <v-row class="align-center px-7">
        <v-col cols="12" md="12" sm="12">
          <h4>Biometria fetal:</h4>
        </v-col>
      </v-row>

      <v-row
        class="align-center px-10 mt-n5"
        :class="!camposExibidos.exDiametroBiparietal ? 'text--disabled' : ''"
      >
        <span>Diâmetro biparietal</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            v-mask="''"
            :disabled="!camposExibidos.exDiametroBiparietal"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.diametroBiparietal"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exDiametroBiparietal"
          @click="
            camposExibidos.exDiametroBiparietal = true;
            laudoSelecionado.laudoJson.diametroBiparietal = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exDiametroBiparietal"
          @click="
            camposExibidos.exDiametroBiparietal = false;
            laudoSelecionado.laudoJson.diametroBiparietal = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exCircCefalica ? 'text--disabled' : ''"
      >
        <span>Circunferência cefálica</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exCircCefalica"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.circunferenciaCefalica"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCircCefalica"
          @click="
            camposExibidos.exCircCefalica = true;
            laudoSelecionado.laudoJson.circunferenciaCefalica = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCircCefalica"
          @click="
            camposExibidos.exCircCefalica = false;
            laudoSelecionado.laudoJson.circunferenciaCefalica = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exCircAbdom ? 'text--disabled' : ''"
      >
        <span>Circunferência abdominal</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exCircAbdom"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.circunferenciaAbdominal"
          ></v-text-field>
        </v-col>
        <span>mm. </span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCircAbdom"
          @click="
            camposExibidos.exCircAbdom = true;
            laudoSelecionado.laudoJson.circunferenciaAbdominal = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCircAbdom"
          @click="
            camposExibidos.exCircAbdom = false;
            laudoSelecionado.laudoJson.circunferenciaAbdominal = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exCompUtero ? 'text--disabled' : ''"
      >
        <span>Comprimento do úmero</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exCompUtero"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoUmero"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCompUtero"
          @click="
            camposExibidos.exCompUtero = true;
            laudoSelecionado.laudoJson.comprimentoUmero = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCompUtero"
          @click="
            camposExibidos.exCompUtero = false;
            laudoSelecionado.laudoJson.comprimentoUmero = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exCompUlna ? 'text--disabled' : ''"
      >
        <span>Comprimento da ulna</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exCompUlna"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoUlna"
          ></v-text-field>
        </v-col>
        <span>mm.</span>

        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCompUlna"
          @click="
            camposExibidos.exCompUlna = true;
            laudoSelecionado.laudoJson.comprimentoUlna = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCompUlna"
          @click="
            camposExibidos.exCompUlna = false;
            laudoSelecionado.laudoJson.comprimentoUlna = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exCompRadio ? 'text--disabled' : ''"
      >
        <span>Comprimento do rádio</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exCompRadio"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoRadio"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCompRadio"
          @click="
            camposExibidos.exCompRadio = true;
            laudoSelecionado.laudoJson.comprimentoRadio = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCompRadio"
          @click="
            camposExibidos.exCompRadio = false;
            laudoSelecionado.laudoJson.comprimentoRadio = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exCompFemur ? 'text--disabled' : ''"
      >
        <span>Comprimento do femur</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exCompFemur"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoFemur"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCompFemur"
          @click="
            camposExibidos.exCompFemur = true;
            laudoSelecionado.laudoJson.comprimentoFemur = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCompFemur"
          @click="
            camposExibidos.exCompFemur = false;
            laudoSelecionado.laudoJson.comprimentoFemur = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exCompTibia ? 'text--disabled' : ''"
      >
        <span>Comprimento da tíbia</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exCompTibia"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoTibia"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCompTibia"
          @click="
            camposExibidos.exCompTibia = true;
            laudoSelecionado.laudoJson.comprimentoTibia = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCompTibia"
          @click="
            camposExibidos.exCompTibia = false;
            laudoSelecionado.laudoJson.comprimentoTibia = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exCompFibula ? 'text--disabled' : ''"
      >
        <span>Comprimento da fíbula</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exCompFibula"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoFibula"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCompFibula"
          @click="
            camposExibidos.exCompFibula = true;
            laudoSelecionado.laudoJson.comprimentoFibula = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCompFibula"
          @click="
            camposExibidos.exCompFibula = false;
            laudoSelecionado.laudoJson.comprimentoFibula = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exCompPe ? 'text--disabled' : ''"
      >
        <span>Comprimento do pé</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exCompPe"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.comprimentoPe"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCompPe"
          @click="
            camposExibidos.exCompPe = true;
            laudoSelecionado.laudoJson.comprimentoPe = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCompPe"
          @click="
            camposExibidos.exCompPe = false;
            laudoSelecionado.laudoJson.comprimentoPe = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exDiametroCerebelo ? 'text--disabled' : ''"
      >
        <span>Diâmetro do cerebelo</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exDiametroCerebelo"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.diametroCerebelo"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exDiametroCerebelo"
          @click="
            camposExibidos.exDiametroCerebelo = true;
            laudoSelecionado.laudoJson.diametroCerebelo = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exDiametroCerebelo"
          @click="
            camposExibidos.exDiametroCerebelo = false;
            laudoSelecionado.laudoJson.diametroCerebelo = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exCisternaMagna ? 'text--disabled' : ''"
      >
        <span>Cisterna magna</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exCisternaMagna"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.cisternaMagna"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCisternaMagna"
          @click="
            camposExibidos.exCisternaMagna = true;
            laudoSelecionado.laudoJson.cisternaMagna = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCisternaMagna"
          @click="
            camposExibidos.exCisternaMagna = false;
            laudoSelecionado.laudoJson.cisternaMagna = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exPregaCutaneaOccipital ? 'text--disabled' : ''"
      >
        <span>Prega cutânea occipital</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exPregaCutaneaOccipital"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.pregaCutaneaOccipital"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exPregaCutaneaOccipital"
          @click="
            camposExibidos.exPregaCutaneaOccipital = true;
            laudoSelecionado.laudoJson.pregaCutaneaOccipital = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exPregaCutaneaOccipital"
          @click="
            camposExibidos.exPregaCutaneaOccipital = false;
            laudoSelecionado.laudoJson.pregaCutaneaOccipital = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="
          !camposExibidos.exAtrioVentriculoLateral ? 'text--disabled' : ''
        "
      >
        <span>Átrio do ventrículo lateral</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exAtrioVentriculoLateral"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.atrioVentriculoLateral"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exAtrioVentriculoLateral"
          @click="
            camposExibidos.exAtrioVentriculoLateral = true;
            laudoSelecionado.laudoJson.atrioVentriculoLateral = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exAtrioVentriculoLateral"
          @click="
            camposExibidos.exAtrioVentriculoLateral = false;
            laudoSelecionado.laudoJson.atrioVentriculoLateral = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="!camposExibidos.exOssoNasal ? 'text--disabled' : ''"
      >
        <span>Osso Nasal</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exOssoNasal"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.ossoNasal"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exOssoNasal"
          @click="
            camposExibidos.exOssoNasal = true;
            laudoSelecionado.laudoJson.ossoNasal = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exOssoNasal"
          @click="
            camposExibidos.exOssoNasal = false;
            laudoSelecionado.laudoJson.ossoNasal = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="
          !camposExibidos.exDistanciaInterobitariaInterna
            ? 'text--disabled'
            : ''
        "
      >
        <span>Distância interorbitária interna</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exDistanciaInterobitariaInterna"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.distanciaInterobitariaInterna"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exDistanciaInterobitariaInterna"
          @click="
            camposExibidos.exDistanciaInterobitariaInterna = true;
            laudoSelecionado.laudoJson.distanciaInterobitariaInterna =
              undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exDistanciaInterobitariaInterna"
          @click="
            camposExibidos.exDistanciaInterobitariaInterna = false;
            laudoSelecionado.laudoJson.distanciaInterobitariaInterna =
              undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7"
        :class="
          !camposExibidos.exDistanciaInterobitariaExterna
            ? 'text--disabled'
            : ''
        "
      >
        <span>Distância interorbitária externa</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exDistanciaInterobitariaExterna"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.distanciaInterobitariaExterna"
          ></v-text-field>
        </v-col>
        <span>mm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exDistanciaInterobitariaExterna"
          @click="
            camposExibidos.exDistanciaInterobitariaExterna = true;
            laudoSelecionado.laudoJson.distanciaInterobitariaExterna =
              undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exDistanciaInterobitariaExterna"
          @click="
            camposExibidos.exDistanciaInterobitariaExterna = false;
            laudoSelecionado.laudoJson.distanciaInterobitariaExterna =
              undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-row
        class="align-center px-10 mt-n7 pb-3"
        :class="!camposExibidos.exPesofetal ? 'text--disabled' : ''"
      >
        <span>Peso fetal</span>
        <v-col cols="12" md="1" sm="12" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            required
            :disabled="!camposExibidos.exPesofetal"
            v-mask="''"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.pesoFetal"
          ></v-text-field>
        </v-col>
        <span>g (+ 10%)</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exPesofetal"
          @click="
            camposExibidos.exPesofetal = true;
            laudoSelecionado.laudoJson.pesoFetal = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exPesofetal"
          @click="
            camposExibidos.exPesofetal = false;
            laudoSelecionado.laudoJson.pesoFetal = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>

      <v-divider class="pa-5"></v-divider>

      <v-row class="align-center px-9">
        <h4>Morfologia Fetal:</h4>
      </v-row>

      <v-col
        cols="12"
        md="12"
        sm="12"
        class=""
        :class="!camposExibidos.exCranio ? 'text--disabled' : ''"
      >
        <v-row class="align-center px-7 mt-1">
          <span>Crânio</span>
          <v-col cols="12" md="3" class="py-0">
            <v-select
              :items="[
                'não examinado.',
                'aparentemente normal.',
                'acrania.',
                'sinal do limão.',
                'encefalocele.',
                'outro',
              ]"
              required
              :disabled="!camposExibidos.exCranio"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="laudoSelecionado.laudoJson.cranio"
            ></v-select>
          </v-col>

          <v-col
            v-if="laudoSelecionado.laudoJson.cranio == 'outro'"
            cols="12"
            md="3"
            sm="12"
          >
            <v-textarea
              :readonly="carregamentoDoLaudo || !podeEditar"
              required
              validate-on-blur
              filled
              :disabled="!camposExibidos.exCranio"
              outlined
              no-resize
              label="Comentários adicionais"
              v-model="laudoSelecionado.laudoJson.cranioTexto"
            ></v-textarea>
          </v-col>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exCranio"
            @click="
              camposExibidos.exCranio = true;
              laudoSelecionado.laudoJson.cranio = undefined;
              laudoSelecionado.laudoJson.cranioTexto = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="camposExibidos.exCranio"
            @click="
              camposExibidos.exCranio = false;
              laudoSelecionado.laudoJson.cranio = undefined;
              laudoSelecionado.laudoJson.cranioTexto = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="12"
        sm="12"
        class=""
        :class="!camposExibidos.exEncefalo ? 'text--disabled' : ''"
      >
        <v-row class="align-center px-7 mt-n10">
          <span>Encéfalo</span>
          <v-col cols="12" md="5" class="py-0">
            <v-select
              :items="[
                'não examinado.',
                'aparentemente normal.',
                'ventriculomegalia.',
                'cistos de plexo coroide.',
                'cavum do septo pelucido não visualizado.',
                'tálamos fundidos.',
                'cisterna magna aumentada.',
                'corpo caloso não visualizado.',
                'cerebelo hipoplástico.',
                'vermis cerebelar não visualizado.',
                'vermis cerebelar hipoplástico.',
                'outro',
              ]"
              :disabled="!camposExibidos.exEncefalo"
              required
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="laudoSelecionado.laudoJson.encefalo"
            ></v-select>
          </v-col>

          <v-col
            v-if="laudoSelecionado.laudoJson.encefalo == 'outro'"
            cols="12"
            md="3"
            sm="12"
          >
            <v-textarea
              :readonly="carregamentoDoLaudo || !podeEditar"
              required
              validate-on-blur
              filled
              :disabled="!camposExibidos.exEncefalo"
              outlined
              no-resize
              label="Comentários adicionais"
              v-model="laudoSelecionado.laudoJson.encefaloTexto"
            ></v-textarea>
          </v-col>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exEncefalo"
            @click="
              camposExibidos.exEncefalo = true;
              laudoSelecionado.laudoJson.encefalo = undefined;
              laudoSelecionado.laudoJson.encefaloTexto = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="camposExibidos.exEncefalo"
            @click="
              camposExibidos.exEncefalo = false;
              laudoSelecionado.laudoJson.encefalo = undefined;
              laudoSelecionado.laudoJson.encefaloTexto = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="12"
        sm="12"
        class=""
        :class="!camposExibidos.exFace ? 'text--disabled' : ''"
      >
        <v-row class="align-center px-7 mt-n10">
          <span>Face</span>
          <v-col cols="12" md="9" class="py-0">
            <v-select
              :items="[
                'não examinado',
                'aparentemente normal com distância interorbitária normal, lábios e nariz visibilizados..',
                'perfil de aspecto normal, com adequado alinhamento fronto-mandibular.',
                'Anoftalmia.',
                'Hipotelorismo.',
                'Fenda labial.',
                'Micrognatia.',
                'Hipoplasia nasal.',
                'Probóscide.',
                'outro',
              ]"
              required
              :disabled="!camposExibidos.exFace"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="laudoSelecionado.laudoJson.face"
            ></v-select>
          </v-col>

          <v-col
            v-if="laudoSelecionado.laudoJson.face == 'outro'"
            cols="12"
            md="3"
            sm="12"
          >
            <v-textarea
              :readonly="carregamentoDoLaudo || !podeEditar"
              required
              validate-on-blur
              filled
              :disabled="!camposExibidos.exFace"
              outlined
              no-resize
              label="Comentários adicionais"
              v-model="laudoSelecionado.laudoJson.faceTexto"
            ></v-textarea>
          </v-col>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exFace"
            @click="
              camposExibidos.exFace = true;
              laudoSelecionado.laudoJson.face = undefined;
              laudoSelecionado.laudoJson.faceTexto = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="camposExibidos.exFace"
            @click="
              camposExibidos.exFace = false;
              laudoSelecionado.laudoJson.face = undefined;
              laudoSelecionado.laudoJson.faceTexto = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="12"
        sm="12"
        class=""
        :class="!camposExibidos.exColuna ? 'text--disabled' : ''"
      >
        <v-row class="align-center px-7 mt-n10">
          <span>Coluna</span>
          <v-col cols="12" md="9" class="py-0">
            <v-select
              :items="[
                'não examinado.',
                'aparentemente normal sendo observadas em cortes sagital,coronal e transversal as regiões cervical, toráxica, lombar e sacra, todas de aspecto normais.',
                'Espinha bífida.',
                'Cifoescoliose.',
                'outro',
              ]"
              required
              :disabled="!camposExibidos.exColuna"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="laudoSelecionado.laudoJson.coluna"
            ></v-select>
          </v-col>

          <v-col
            v-if="laudoSelecionado.laudoJson.coluna == 'outro'"
            cols="12"
            md="3"
            sm="12"
          >
            <v-textarea
              required
              :readonly="carregamentoDoLaudo || !podeEditar"
              validate-on-blur
              :disabled="!camposExibidos.exColuna"
              filled
              outlined
              no-resize
              label="Comentários adicionais"
              v-model="laudoSelecionado.laudoJson.colunaTexto"
            ></v-textarea>
          </v-col>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exColuna"
            @click="
              camposExibidos.exColuna = true;
              laudoSelecionado.laudoJson.coluna = undefined;
              laudoSelecionado.laudoJson.colunaTexto = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="camposExibidos.exColuna"
            @click="
              camposExibidos.exColuna = false;
              laudoSelecionado.laudoJson.coluna = undefined;
              laudoSelecionado.laudoJson.colunaTexto = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="12"
        sm="12"
        class=""
        :class="!camposExibidos.exTorax ? 'text--disabled' : ''"
      >
        <v-row class="align-center px-7 mt-n10 pb-6">
          <span>Tórax</span>
          <v-col cols="12" md="8" class="py-0">
            <v-select
              :items="[
                'não examinado.',
                'aparentemente normal com pulmão de dimensões normais e ecogenicidade preservada.',
                'diafragma integro e ausencia de derrames.',
                'derrame pleural discreto.',
                'derrame pleural importante.',
                'imagem ecogenica intralobar.',
                'imagem ecogenica extralobar.',
                'imagem ecogenica com cistos maiores que dois centimetros.',
                'imagem ecogenica com microcistos.',
                'bolha gástrica intratoracica.',
                'fígado em região intratorácica.',
                'outro',
              ]"
              :disabled="!camposExibidos.exTorax"
              required
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="laudoSelecionado.laudoJson.torax"
            ></v-select>
          </v-col>

          <v-col
            v-if="laudoSelecionado.laudoJson.torax == 'outro'"
            cols="12"
            md="3"
            sm="12"
          >
            <v-textarea
              :readonly="carregamentoDoLaudo || !podeEditar"
              required
              :disabled="!camposExibidos.exTorax"
              validate-on-blur
              filled
              outlined
              no-resize
              label="Comentários adicionais"
              v-model="laudoSelecionado.laudoJson.toraxTexto"
            ></v-textarea>
          </v-col>
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="!camposExibidos.exTorax"
            @click="
              camposExibidos.exTorax = true;
              laudoSelecionado.laudoJson.torax = undefined;
              laudoSelecionado.laudoJson.toraxTexto = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            flat
            icon
            v-if="camposExibidos.exTorax"
            @click="
              camposExibidos.exTorax = false;
              laudoSelecionado.laudoJson.torax = undefined;
              laudoSelecionado.laudoJson.toraxTexto = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
        </v-row>
      </v-col>

      <v-row v-row class="align-center px-7 mt-n6 pb-4 mb-5">
        <h4>Coração</h4>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            :items="['Não examinado.']"
            label="Não examinado"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.naoExaminado"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            label="Aparentemente normal com aorta abdominal à esquerda da coluna e presença de veia cava inferior situada à direita."
            validate-on-blur
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.aparentementeNormal"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            label="Corte de quatro câmaras de aspecto ecográfico normal para a idade gestacional."
            valida
            :readonly="carregamentoDoLaudo || !podeEditar"
            te-on-blur
            v-model="laudoSelecionado.laudoJson.corteQuatro"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Corte dos três vasos e traqueia visibilizado e de aspecto normal. Timo visivel."
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.corteTres"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Quatro câmaras assimetricas com dominância esquerda."
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.quatroCamarasEsquerda"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Quatro câmaras assimétrica com dominância direita."
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.quatroCamarasDireita"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Presença de dois vasos no corte 3VT."
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.presencaDois"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Presença de quatro vasos no corte 3VT."
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.presencaQuatro"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Traqueia localizada entre a aorta e pulmonar."
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.traqueiaLoc"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Pulmonar menor que a aorta."
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.pulmonar"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Dimensão da aorta similar a VCS."
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.dimensaoAorta"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Aorta originando-se do ventrículo direito."
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.aortaOriginando"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Pulmonar originando-se do ventrículo esquerdo."
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.pulmonarOriginando"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="align-center px-7 mt-n8">
        <v-col ols="12" md="12" sm="12" class="mt-n6">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            label="Veia ázigos dilatada."
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.veia"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="align-center px-1 mt-n1 ml-n1">
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          class="align-center px-7 mt-n5"
          label="Outro."
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.outroTexto"
        ></v-checkbox>
        <v-col cols="12" md="3" sm="12" class="mt-n5">
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-if="laudoSelecionado.laudoJson.outroTexto"
            required
            alidate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.outroTexto2"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="12" sm="12" class="">
      <v-row
        class="align-center px-7 mt-n10 pb-2"
        :class="!camposExibidos.exAbdomen ? 'text--disabled' : ''"
      >
        <span>Abdômen</span>
        <v-col cols="12" md="7" class="py-0">
          <v-select
            :items="[
              'não examinado',
              'aparentemente normal com parede abdominal anterior íntegra',
              'estômago visível em sua localização habitual. Vesícula biliar tópica',
              'Inserção normal do cordão umbilical ',
              'estômago aumentado de volume',
              'sinal da dupla bolha presente',
              'dilatação de alças intestinais',
              'exteriorização de alças intestinais à direita da inserção do cordão umbilical',
              'hernia de vísceras intestinais recoberta por membrana, compatível com onfalocele',
              'outro',
            ]"
            required
            :disabled="!camposExibidos.exAbdomen"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.abdome"
          ></v-select>
        </v-col>

        <v-col
          v-if="laudoSelecionado.laudoJson.abdome == 'outro'"
          cols="12"
          md="3"
          sm="12"
        >
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            :disabled="!camposExibidos.exAbdomen"
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.abdomeOutro"
          ></v-textarea>
        </v-col>

        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exAbdomen"
          @click="
            camposExibidos.exAbdomen = true;
            laudoSelecionado.laudoJson.abdome = undefined;
            laudoSelecionado.laudoJson.abdomeOutro = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exAbdomen"
          @click="
            camposExibidos.exAbdomen = false;
            laudoSelecionado.laudoJson.abdome = undefined;
            laudoSelecionado.laudoJson.abdomeOutro = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <v-col cols="12" md="12" sm="12" class="">
      <v-row
        class="align-center px-7 mt-n10 pb-2"
        :class="!camposExibidos.exSisUrinario ? 'text--disabled' : ''"
      >
        <span>Sistema urinário</span>
        <v-col cols="12" md="7" class="py-0">
          <v-select
            :items="[
              'não examinado',
              'aparentemente normal com os rins tópicos de estrutura normal, sem dilatação pielocaliciais e bexiga bem visualizada. Ureteres não visualizados (normal)',
              'rins não visualizados em sua topografia habitual',
              'rim direito não visualizado em sua topografia habitual',
              'rim esquerdo não visualizado em sua topografia habitual',
              'rim esquerdo localizado em topografia mais baixa que o habitual',
              'rim direito localizado em topografia mais baixa que o habitual',
              'presença de múltiplos cistos no rim direito',
              'presença de múltiplos cistos no rim esquerda',
              'presença de múltiplos cistos nos rins',
              'rins hiperecogênicos',
              'bexiga aumentada de volume com uretra dilatada',
              'outro',
            ]"
            :disabled="!camposExibidos.exSisUrinario"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.sistemaUrinario"
          ></v-select>
        </v-col>

        <v-col
          v-if="laudoSelecionado.laudoJson.sistemaUrinario == 'outro'"
          cols="12"
          md="3"
          sm="12"
        >
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exSisUrinario"
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.sistemaUrinarioOutro"
          ></v-textarea>
        </v-col>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exSisUrinario"
          @click="
            camposExibidos.exSisUrinario = true;
            laudoSelecionado.laudoJson.sistemaUrinario = undefined;
            laudoSelecionado.laudoJson.sistemaUrinarioOutro = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exSisUrinario"
          @click="
            camposExibidos.exSisUrinario = false;
            laudoSelecionado.laudoJson.sistemaUrinario = undefined;
            laudoSelecionado.laudoJson.sistemaUrinarioOutro = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <v-col cols="12" md="12" sm="12" class="">
      <v-row
        class="align-center px-7 mt-n10"
        :class="!camposExibidos.exDiametroLongRimDir ? 'text--disabled' : ''"
      >
        <span>Diâmetro longitudinal do rim direito:</span>
        <v-col cols="12" md="1" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exDiametroLongRimDir"
            v-mask="''"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.diametroLongitudinalRimDireito"
          ></v-text-field>
        </v-col>
        <span>cm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exDiametroLongRimDir"
          @click="
            camposExibidos.exDiametroLongRimDir = true;
            laudoSelecionado.laudoJson.diametroLongitudinalRimDireito =
              undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exDiametroLongRimDir"
          @click="
            camposExibidos.exDiametroLongRimDir = false;
            laudoSelecionado.laudoJson.diametroLongitudinalRimDireito =
              undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>
    <v-col cols="12" md="12" sm="12" class="">
      <v-row
        class="align-center px-7 mt-n10"
        :class="!camposExibidos.exDiametroLongRimEsq ? 'text--disabled' : ''"
      >
        <span>Diâmetro longitudinal do rim esquerdo:</span>
        <v-col cols="12" md="1" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            :disabled="!camposExibidos.exDiametroLongRimEsq"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.diametroLongitudinalRimEsquerdo"
          ></v-text-field>
        </v-col>
        <span>cm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exDiametroLongRimEsq"
          @click="
            camposExibidos.exDiametroLongRimEsq = true;
            laudoSelecionado.laudoJson.diametroLongitudinalRimEsquerdo =
              undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exDiametroLongRimEsq"
          @click="
            camposExibidos.exDiametroLongRimEsq = false;
            laudoSelecionado.laudoJson.diametroLongitudinalRimEsquerdo =
              undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="12"
      sm="12"
      class=""
      :class="!camposExibidos.exPelveRenalDireita ? 'text--disabled' : ''"
    >
      <v-row class="align-center px-7 mt-n10">
        <span>Pelve renal direita:</span>
        <v-col cols="12" md="1" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            :disabled="!camposExibidos.exPelveRenalDireita"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.pelveRenalDireita"
          ></v-text-field>
        </v-col>
        <span>cm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exPelveRenalDireita"
          @click="
            camposExibidos.exPelveRenalDireita = true;
            laudoSelecionado.laudoJson.pelveRenalDireita = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exPelveRenalDireita"
          @click="
            camposExibidos.exPelveRenalDireita = false;
            laudoSelecionado.laudoJson.pelveRenalDireita = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="12"
      sm="12"
      class=""
      :class="!camposExibidos.exPelveRenalEsquerda ? 'text--disabled' : ''"
    >
      <v-row class="align-center px-7 mt-n10">
        <span>Pelve renal esquerda:</span>
        <v-col cols="12" md="1" class="py-0">
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            v-mask="''"
            :disabled="!camposExibidos.exPelveRenalEsquerda"
            required
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.pelveRenalEsquerda"
          ></v-text-field>
        </v-col>
        <span>cm.</span>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exPelveRenalEsquerda"
          @click="
            camposExibidos.exPelveRenalEsquerda = true;
            laudoSelecionado.laudoJson.pelveRenalEsquerda = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exPelveRenalEsquerda"
          @click="
            camposExibidos.exPelveRenalEsquerda = false;
            laudoSelecionado.laudoJson.pelveRenalEsquerda = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <v-col cols="12" md="12" sm="12" class="">
      <v-row
        class="align-center px-7 mt-n10 pb-2"
        :class="
          !camposExibidos.exSexoGenitaliaExternaMorfologicamente
            ? 'text--disabled'
            : ''
        "
      >
        <span>Sexo - Genitália externa morfologicamente</span>
        <v-col cols="12" md="3" class="py-0">
          <v-select
            :items="['masculina', 'feminina', 'não identificada', 'ambígua']"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exSexoGenitaliaExternaMorfologicamente"
            validate-on-blur
            v-model="
              laudoSelecionado.laudoJson.sexoGenitaliaExternaMorfologicamente
            "
          ></v-select>
        </v-col>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exSexoGenitaliaExternaMorfologicamente"
          @click="
            camposExibidos.exSexoGenitaliaExternaMorfologicamente = true;
            laudoSelecionado.laudoJson.sexoGenitaliaExternaMorfologicamente =
              undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exSexoGenitaliaExternaMorfologicamente"
          @click="
            camposExibidos.exSexoGenitaliaExternaMorfologicamente = false;
            laudoSelecionado.laudoJson.sexoGenitaliaExternaMorfologicamente =
              undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <v-col
      cols="12"
      md="12"
      sm="12"
      class=""
      :class="!camposExibidos.exMembro ? 'text--disabled' : ''"
    >
      <v-row class="align-center px-7 mt-n10 pb-2">
        <span>Membros</span>
        <v-col cols="12" md="8" class="py-0">
          <v-select
            :items="[
              'não examinado',
              'aparentemente normais sendo identificados quatro membros simétricos, sem anormalidades em seus diferentes segmentos',
              'sem anormalidades em seus diferentes segmentos',
              'mão direita não visualizada',
              'mão esquerda não visualizada',
              'mãos não visualizada',
              'desvio do eixo dos pés',
              'desvio do eixo do pé esquerdo',
              'polidactilia em mão direita',
              'polidactilia em mão esquerda',
              'polidactilia em ambas as mãos',
              'outro',
            ]"
            :disabled="!camposExibidos.exMembro"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.membros"
          ></v-select>
        </v-col>

        <v-col
          v-if="laudoSelecionado.laudoJson.membros == 'outro'"
          cols="12"
          md="3"
          sm="12"
        >
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            :disabled="!camposExibidos.exMembro"
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.membrosOutro"
          ></v-textarea>
        </v-col>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exMembro"
          @click="
            camposExibidos.exMembro = true;
            laudoSelecionado.laudoJson.membros = undefined;
            laudoSelecionado.laudoJson.membrosOutro = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exMembro"
          @click="
            camposExibidos.exMembro = false;
            laudoSelecionado.laudoJson.membros = undefined;
            laudoSelecionado.laudoJson.membrosOutro = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <v-col
      cols="12"
      md="9"
      sm="12"
      class=""
      :class="!camposExibidos.exLiquidoAmniotico ? 'text--disabled' : ''"
    >
      <v-row class="align-center px-7 mt-n10 pb-8">
        <span>Líquido amniótico</span>
        <v-col cols="12" md="6" class="py-0">
          <v-select
            :items="[
              'normoidrâmnia para idade gestacional',
              'reduzido',
              'aumentado',
            ]"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :disabled="!camposExibidos.exLiquidoAmniotico"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.liquidoAmniotico"
          ></v-select>
        </v-col>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exLiquidoAmniotico"
          @click="
            camposExibidos.exLiquidoAmniotico = true;
            laudoSelecionado.laudoJson.liquidoAmniotico = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exLiquidoAmniotico"
          @click="
            camposExibidos.exLiquidoAmniotico = false;
            laudoSelecionado.laudoJson.liquidoAmniotico = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <!-- <v-divider class="py-3 pb-9"></v-divider> -->

    <v-col cols="12" md="12" sm="12" class="">
      <v-row class="align-center px-7 mt-n10">
        <h4>Placenta</h4>
      </v-row>
    </v-col>

    <v-col cols="12" md="12" sm="12" class="">
      <v-row
        class="align-center px-7 mt-n10 pb-1"
        :class="!camposExibidos.exLocalizacao ? 'text--disabled' : ''"
      >
        <span>Localização</span>
        <v-col cols="12" md="3" class="py-0">
          <v-select
            :items="[
              'anterior alta',
              'posterior alta',
              'anterior baixa',
              'posterior baixa',
              'fúndica',
              'outro',
            ]"
            :disabled="!camposExibidos.exLocalizacao"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.localizacao"
          ></v-select>
        </v-col>

        <v-col
          v-if="laudoSelecionado.laudoJson.localizacao == 'outro'"
          cols="12"
          md="4"
          sm="12"
        >
          <v-textarea
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            :disabled="!camposExibidos.exLocalizacao"
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais"
            v-model="laudoSelecionado.laudoJson.localizacaoOutro"
          ></v-textarea>
        </v-col>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exLocalizacao"
          @click="
            camposExibidos.exLocalizacao = true;
            laudoSelecionado.laudoJson.localizacao = undefined;
            laudoSelecionado.laudoJson.localizacaoOutro = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exLocalizacao"
          @click="
            camposExibidos.exLocalizacao = false;
            laudoSelecionado.laudoJson.localizacao = undefined;
            laudoSelecionado.laudoJson.localizacaoOutro = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <v-row
      class="align-center px-10 mt-n7 pb-3"
      :class="!camposExibidos.exEspessura ? 'text--disabled' : ''"
    >
      <span>Espessura</span>
      <v-col cols="12" md="1" sm="12" class="py-0">
        <v-text-field
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          v-mask="''"
          :disabled="!camposExibidos.exEspessura"
          required
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.espessura"
        ></v-text-field>
      </v-col>
      <span>cm.</span>
      <v-btn
        dense
        depressed
        flat
        icon
        v-if="!camposExibidos.exEspessura"
        @click="
          camposExibidos.exEspessura = true;
          laudoSelecionado.laudoJson.espessura = undefined;
        "
        ><v-icon>mdi-eye</v-icon></v-btn
      >
      <v-btn
        dense
        depressed
        flat
        icon
        v-if="camposExibidos.exEspessura"
        @click="
          camposExibidos.exEspessura = false;
          laudoSelecionado.laudoJson.espessura = undefined;
        "
        ><v-icon>mdi-eye-off</v-icon></v-btn
      >
    </v-row>

    <v-col cols="12" md="12" sm="12" class="">
      <v-row
        class="align-center px-7 mt-n10 pb-1"
        :class="!camposExibidos.exTextura ? 'text--disabled' : ''"
      >
        <span>Textura</span>
        <v-col cols="12" md="2" class="py-0">
          <v-select
            :items="['homogênea', 'heterogênea']"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exTextura"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.textura"
          ></v-select>
        </v-col>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exTextura"
          @click="
            camposExibidos.exTextura = true;
            laudoSelecionado.laudoJson.textura = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exTextura"
          @click="
            camposExibidos.exTextura = false;
            laudoSelecionado.laudoJson.textura = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <v-col cols="12" md="12" sm="12" class="">
      <v-row
        class="align-center px-7 mt-n10 pb-5"
        :class="!camposExibidos.exGrau ? 'text--disabled' : ''"
      >
        <span>Grau</span>
        <v-col cols="12" md="8" class="py-0">
          <v-select
            :items="['0', 'I', 'II (Grannum)']"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exGrau"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.grau"
          ></v-select>
        </v-col>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exGrau"
          @click="
            camposExibidos.exGrau = true;
            laudoSelecionado.laudoJson.textura = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exGrau"
          @click="
            camposExibidos.exGrau = false;
            laudoSelecionado.laudoJson.textura = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <v-row class="align-center px-7 mt-n4 mb-3">
      <v-col cols="12" md="12" sm="12" class="py-0">
        <v-textarea
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          filled
          outlined
          no-resize
          label="Comentários adicionais"
          v-model="laudoSelecionado.laudoJson.comentariosAdicionais2"
        ></v-textarea>
      </v-col>
    </v-row>

    <!-- <v-divider class="py-3 pb-9"></v-divider> -->

    <v-col cols="12" md="12" sm="12" class="">
      <v-row class="align-center px-7 mt-n10 pb-1">
        <h4>Cordão umbilical</h4>
        <v-col cols="12" md="11" class="py-0"> </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      md="12"
      sm="12"
      class=""
      :class="!camposExibidos.exInsercao ? 'text--disabled' : ''"
    >
      <v-row class="align-center px-7 mt-n10 pb-1">
        <span>Inserção</span>
        <v-col cols="12" md="8" class="py-0">
          <v-select
            :items="['normal', 'na periferia da placenta', 'velamentosa']"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exInsercao"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.insercao"
          ></v-select>
        </v-col>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exInsercao"
          @click="
            camposExibidos.exInsercao = true;
            laudoSelecionado.laudoJson.insercao = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exInsercao"
          @click="
            camposExibidos.exInsercao = false;
            laudoSelecionado.laudoJson.insercao = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <v-col
      cols="12"
      md="12"
      sm="12"
      class=""
      :class="!camposExibidos.exVasos ? 'text--disabled' : ''"
    >
      <v-row class="align-center px-7 mt-n10 pb-6">
        <span>Vasos</span>
        <v-col cols="12" md="9" class="py-0">
          <v-select
            :items="['3 (duas artérias e 1 veia)', '2 (uma artéria e 1 veia)']"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exVasos"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.vasos"
          ></v-select>
        </v-col>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exVasos"
          @click="
            camposExibidos.exVasos = true;
            laudoSelecionado.laudoJson.vasos = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exVasos"
          @click="
            camposExibidos.exVasos = false;
            laudoSelecionado.laudoJson.vasos = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <v-row class="align-center px-7 mt-n4 mb-3">
      <v-col cols="12" md="12" sm="12" class="py-0">
        <v-textarea
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          validate-on-blur
          filled
          outlined
          no-resize
          label="Comentários adicionais"
          v-model="laudoSelecionado.laudoJson.comentariosAdicionais3"
        ></v-textarea>
      </v-col>
    </v-row>

    <!-- <v-divider class="py-3 pb-9"></v-divider> -->

    <v-col cols="12" md="12" sm="12" class="">
      <v-row class="align-center px-7 mt-n10 pb-6">
        <h4>Conclusão</h4>
        <v-col cols="12" md="11" class="py-0"> </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="12" sm="12" class="">
      <v-row class="align-center px-7 mt-n5">
        <span>Gestação única com feto vivo.</span>
        <v-col cols="12" md="11" class="py-0"> </v-col>
      </v-row>
    </v-col>

    <v-row
      class="align-center px-10 mt-n8"
      :class="!camposExibidos.exIdade ? 'text--disabled' : ''"
    >
      <span>Idade gestacional baseado na biometria fetal atual de</span>
      <v-col cols="12" md="1" sm="12">
        <v-text-field
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          v-mask="''"
          :disabled="!camposExibidos.exIdade"
          required
          validate-on-blur
          v-model="
            laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas
          "
        >
        </v-text-field>
      </v-col>
      <span>semanas e</span>
      <v-col cols="12" md="1" sm="12">
        <v-text-field
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          v-mask="''"
          :disabled="!camposExibidos.exIdade"
          required
          validate-on-blur
          v-model="
            laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalDias
          "
        >
        </v-text-field>
      </v-col>
      <span>dias,</span>
      <v-col cols="12" md="4" sm="12">
        <v-select
          :items="[
            'compatível com a idade gestacional baseada na DUM.',
            'incompatível com a idade gestacional baseada na DUM.',
            'compatível com a idade gestacional corrigida pela ultrassonografia precoce',
          ]"
          required
          :disabled="!camposExibidos.exIdade"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.compativelIncompativel"
        >
        </v-select>
      </v-col>
      <span class="pb-9 mt-n5">com a idade gestacional baseada na DUM.</span>
      <v-btn
        dense
        depressed
        flat
        icon
        v-if="!camposExibidos.exIdade"
        @click="
          camposExibidos.exIdade = true;
          laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas =
            undefined;
          laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalDias =
            undefined;
          laudoSelecionado.laudoJson.compativelIncompativel = undefined;
        "
        ><v-icon>mdi-eye</v-icon></v-btn
      >
      <v-btn
        dense
        depressed
        flat
        icon
        v-if="camposExibidos.exIdade"
        @click="
          camposExibidos.exIdade = false;
          laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalSemanas =
            undefined;
          laudoSelecionado.laudoJson.idadeGestacionalBiometriaFetalDias =
            undefined;
          laudoSelecionado.laudoJson.compativelIncompativel = undefined;
        "
        ><v-icon>mdi-eye-off</v-icon></v-btn
      >
    </v-row>

    <v-row
      class="align-center px-10 mt-n10 pb-1"
      :class="!camposExibidos.exData ? 'text--disabled' : ''"
    >
      <span>Data provável do parto:</span>
      <v-col cols="12" md="2" sm="12">
        <v-text-field
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exData"
          required
          type="date"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.dataProvavelParto"
        ></v-text-field>
      </v-col>
      <v-btn
        dense
        depressed
        flat
        icon
        v-if="!camposExibidos.exData"
        @click="
          camposExibidos.exData = true;
          laudoSelecionado.laudoJson.dataProvavelParto = undefined;
        "
        ><v-icon>mdi-eye</v-icon></v-btn
      >
      <v-btn
        dense
        depressed
        flat
        icon
        v-if="camposExibidos.exData"
        @click="
          camposExibidos.exData = false;
          laudoSelecionado.laudoJson.dataProvavelParto = undefined;
        "
        ><v-icon>mdi-eye-off</v-icon></v-btn
      >
    </v-row>

    <v-col
      cols="12"
      md="12"
      sm="12"
      class=""
      :class="!camposExibidos.exCrescimento ? 'text--disabled' : ''"
    >
      <v-row class="align-center px-4 mt-n10 pb-6">
        <v-col cols="12" md="9" class="py-0">
          <v-select
            :items="[
              'Crescimento fetal adequado',
              'Crescimento fetal abaixo do esperado para idade gestacional.',
              'Crescimento fetal acima do esperado para idade gestacional.',
            ]"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exCrescimento"
            validate-on-blur
            v-model="laudoSelecionado.laudoJson.crescimentoFetal"
          ></v-select>
        </v-col>
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="!camposExibidos.exCrescimento"
          @click="
            camposExibidos.exCrescimento = true;
            laudoSelecionado.laudoJson.crescimentoFetal = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          flat
          icon
          v-if="camposExibidos.exCrescimento"
          @click="
            camposExibidos.exCrescimento = false;
            laudoSelecionado.laudoJson.crescimentoFetal = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
      </v-row>
    </v-col>

    <v-row class="align-center px-7 mt-n4 mb-3">
      <v-col cols="12" md="12" sm="12" class="py-0">
        <v-textarea
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          filled
          outlined
          no-resize
          label="Comentários adicionais"
          v-model="laudoSelecionado.laudoJson.comentariosAdicionais4"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-col cols="12" md="12" sm="12" class="">
      <v-row class="align-center px-7 mt-n10 pb-10">
        <span>
          OBS: No momento do exame não se observam anomalias morfológicas
          fetais, entretanto não podemos descartar malformações que não tem
          expressão ecográfica ou se apresentam de forma tardia (sensibilidade
          do exame 85%). A critério clínico, complementar avaliação cardiológica
          com especialista em ecocardiografia fetal.
        </span>
        <v-col cols="12" md="11" class="py-0"> </v-col>
      </v-row>
    </v-col>

    <!-- <template> </template> -->

    <div class="d-flex justify-content-end px-5">
      <v-btn
        v-if="podeEditar"
        color="primary"
        :loading="carregamentoDoLaudo"
        @click="
          () => {
            laudoId ? editarLaudo() : salvarLaudo();
          }
        "
      >
        salvar laudo
      </v-btn>

      <v-btn
        color="primary"
        v-if="temIdDoLaudo && !podeEditar"
        @click="imprimirLaudo"
        class="ml-5"
      >
        Imprimir
      </v-btn>

      <v-btn
        color="primary"
        v-if="!podeEditar"
        class="ml-5"
        @click="podeEditar = true"
      >
        Editar
      </v-btn>

      <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
        Cancelar
      </v-btn>
    </div>

    <v-dialog transition="dialog-bottom-transition" max-width="600">
      <template #default="dialog">
        <v-card>
          <v-card-text>
            <div class="text-span pa-8 text-center">
              Laudo
              {{ salvoOuEditado }}
              com sucesso!
            </div>
          </v-card-text>
          <v-card-actions class="justify-center pb-4">
            <v-btn
              text
              depressed
              class="grey lighten-2 grey--text texte-darken-6"
              @click="dialog.value = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" max-width="600">
      <template #default="dialog">
        <v-card>
          <v-card-text>
            <div class="text-span pa-8 text-center">
              Laudo
              {{ laudoPrint }}
              com sucesso!
            </div>
          </v-card-text>

          <v-card-actions class="justify-center pb-4">
            <v-btn
              text
              depressed
              class="grey lighten-2 grey--text texte-darken-6"
              @click="dialog.value = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </template></v-dialog
    >
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      carregamentoDoLaudo: false,
      laudoId: null,
      podeEditar: true,
      voltando: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      camposExibidos: {
        exSituacao: true,
        exApresentacao: true,
        exDorsoFetal: true,
        exMovFetaisPresentes: true,
        exBatimentoCardFetais: true,
        exFreqBpm: true,
        exDiametroBiparietal: true,
        exCircCefalica: true,
        exCircAbdom: true,
        exCompUtero: true,
        exCompUlna: true,
        exCompRadio: true,
        exCompFemur: true,
        exCompTibia: true,
        exCompFibula: true,
        exCompPe: true,
        exDiametroCerebelo: true,
        exCisternaMagna: true,
        exPregaCutaneaOccipital: true,
        exAtrioVentriculoLateral: true,
        exOssoNasal: true,
        exDistanciaInterobitariaInterna: true,
        exDistanciaInterobitariaExterna: true,
        exPesofetal: true,
        exCranio: true,
        exEncefalo: true,
        exFace: true,
        exColuna: true,
        exTorax: true,
        exAbdomen: true,
        exSisUrinario: true,
        exDiametroLongRimDir: true,
        exDiametroLongRimEsq: true,
        exPelveRenalDireita: true,
        exPelveRenalEsquerda: true,
        exSexoGenitaliaExternaMorfologicamente: true,
        exMembro: true,
        exLiquidoAmniotico: true,
        exLocalizacao: true,
        exEspessura: true,
        exTextura: true,
        exGrau: true,
        exInsercao: true,
        exVasos: true,
        exIdade: true,
        exData: true,
        exCrescimento: true,
      },

      colodeutero: false,

      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 2,5 cm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    temIdDoLaudo() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    forceReRender() {
      this.componentKey++;
    },

    ...mapGetters('Offline', ['requisicao', 'dados']),

    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          this.carregamentoDoLaudo = true;

          const exameId = this.$route.query.exame;

          this.laudoSelecionado.examePorConsultaId = exameId;
          this.consultaSelecionada.status = 'LAUDADO';

          if (navigator.onLine) {
            const { data } = await this.postLaudo();
            this.laudoId = data;

            await this.alterarStatus();

            if (!Number.isInteger(data)) throw new Error(data);

            this.getLaudoByExamePorConsultaId(exameId);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
              requisicaoSalvar: this.postLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'POST_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', {
              laudo: this.laudoSelecionado,
              consulta: this.consultaSelecionada,
            });
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },

    async editarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            await this.patchLaudo(this.laudoSelecionado);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
            // this.podeImprimir = false;
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);

      const novaAba = this.$router.resolve({
        name: 'imprecoes.avaliacaomorfologicadoprimeirotrimestre',
        query: { exame: this.$route.query.exame },
      });
      window.open(novaAba.href, '_blank');
    },
    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },
  async mounted() {
    this.getLaudoByExamePorConsultaId(this.$route.query.exame);
    this.forceReRender();

    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;
    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      // this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
