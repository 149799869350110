<template lang="">
  <div>
    <Home> </Home>
  </div>
</template>
<script>
import Home from "../components/homeRecepcao/home.vue";

export default {
  components: {
    Home,
  },
};
</script>
<style lang=""></style>
