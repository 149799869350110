import LaudoEmbrion from '@/services/LaudoEmbrion';

const initialState = () => ({
  loading: false,
  laudoId: null,
  infosCompartilhadas: {
    gestacao: 1,
    dataUltimaMestrucao: null,
    semanasDUM: null,
    diasDUM: null,
    transdutor: null,
  },
  laudos: [{}],
  camposExibidos: [{}],
});

const SET_LOADING = 'SET_LOADING';
const RESET_LAUDOS = 'RESET_LAUDOS';
const SET_LAUDO_ID = 'SET_LAUDO_ID';
const SET_LAUDO_BY_INDEX = 'SET_LAUDO_BY_INDEX';
const SET_LAUDOS = 'SET_LAUDOS';
const SET_CAMPOS_EXIBIDOS = 'SET_CAMPOS_EXIBIDOS';
const SET_INFO_COMPARTILHADAS = 'SET_INFO_COMPARTILHADAS';

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    infosCompartilhadas: state => state.infosCompartilhadas,
    laudos: state => state.laudos,
    laudoId: state => state.laudoId,
    camposExibidos: state => state.camposExibidos,
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload;
    },
    [SET_LAUDO_BY_INDEX](state, { index, model }) {
      state.laudos[index] = { ...state.laudos[index], ...model };
    },
    [SET_LAUDOS](state, payload) {
      state.laudos = payload;
    },
    [SET_CAMPOS_EXIBIDOS](state, payload) {
      state.camposExibidos = payload;
    },
    [SET_INFO_COMPARTILHADAS](state, payload) {
      state.infosCompartilhadas = { ...state.infosCompartilhadas, ...payload };
    },
    [SET_LAUDO_ID](state, payload) {
      state.laudoId = payload;
    },
    [RESET_LAUDOS](state) {
      state.laudoId = initialState().laudoId;
      state.infosCompartilhadas = initialState().infosCompartilhadas;
      state.laudos = initialState().laudos;
    },
  },
  actions: {
    async getLaudos({ commit, rootState }, examePorConsultaId) {
      const { token } = rootState.Auth.usuarioLogado;
      const { data } = await LaudoEmbrion.getLaudoByExamePorConsulta({
        token,
        exameId: examePorConsultaId,
      });

      const laudos = JSON.parse(data.laudo);
      const primeiroLaudo = laudos[0];

      commit(SET_LAUDO_ID, data.id);
      commit(SET_INFO_COMPARTILHADAS, {
        gestacao: primeiroLaudo.gestacao,
        semanasDUM: primeiroLaudo.semanasDUM,
        diasDUM: primeiroLaudo.diasDUM,
        transdutor: primeiroLaudo.transdutor,
        dataUltimaMestrucao: primeiroLaudo.dataUltimaMestrucao,
      });
      commit(SET_LAUDOS, laudos);
    },

    async postLaudos({ commit, getters, rootState }, examePorConsultaId) {
      const { token } = rootState.Auth.usuarioLogado;
      const { infosCompartilhadas, laudos, camposExibidos } = getters;

      for (const [index, laudo] of laudos.entries()) {
        laudo.gestacao = infosCompartilhadas.gestacao;
        laudo.semanasDUM = infosCompartilhadas.semanasDUM;
        laudo.diasDUM = infosCompartilhadas.diasDUM;
        laudo.transdutor = infosCompartilhadas.transdutor;
        laudo.dataUltimaMestrucao = infosCompartilhadas.dataUltimaMestrucao;
        laudo.camposExibidos = camposExibidos[index];
      }

      const { data } = await LaudoEmbrion.postLaudo({
        token,
        data: {
          examePorConsultaId,
          laudoJson: laudos,
        },
      });

      commit(SET_LAUDO_ID, data);
    },
    async pathLaudos({ commit, getters }) {
      const { infosCompartilhadas, laudos } = getters;

      // Percorrer todos os laudos para fazer o envio
      for (const laudo of laudos) {
        laudo.gestacao = infosCompartilhadas.gestacao;
        laudo.semanasDUM = infosCompartilhadas.semanasDUM;
        laudo.diasDUM = infosCompartilhadas.diasDUM;
        laudo.transdutor = infosCompartilhadas.transdutor;
        laudo.dataUltimaMestrucao = infosCompartilhadas.dataUltimaMestrucao;

        // Criar array de "paths" para atualizar do dados
        const laudoNormalizado = Object.keys(laudo).map(key => ({
          path: key,
          op: 'replace',
          value: laudo[key],
        }));

        // TODO: Enviar laudo para o servidor
      }
    },
  },
};
