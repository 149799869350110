import ScreenListaDePacientes from '../views/ScreenPacientesLista.vue';
import ScreenPaciente from '../views/ScreenPaciente.vue';
import ScreenEmailPaciente from '../views/ScreenEmailPaciente.vue';
import ScreenHomeRecepcao from '../views/ScreenHomeRecepcao.vue';
import ScreenHomeLaudos from '../views/ScreenHomeLaudos.vue';
import ScreenLaudo from '../views/ScreenLaudo.vue';
import ScreenConsulta from '../views/ScreenConsulta.vue';
import FormPagamento from '../components/pagamento/formularios/formPagamento.vue';
import ScreenLogin from '../views/ScreenLogin.vue';
import ScreenTranslucenciaNucal from '../views/ScreenLaudoTranslucenciaNucal.vue';
import ScreenPelvicaTransvaginalDopplerPreparoIntestinal from '../views/ScreenLaudoPelvicaTransvaginalDopplerPreparoIntestinal.vue';
import ScreenMorfologica from '../views/ScreenLaudoMorfologica.vue';
import ScreenPelvicaTransabdominal from '../views/ScreenLaudoPelvicaTransabdominal.vue';
import ScreenTransvaginalHisterectomia from '../views/ScreenLaudoTransvaginalHisterectomia.vue';
import ScreenEcocardiodopplerFetal from '../views/ScreenLaudoEcocardiodopplerFetal.vue';
import ScreenTransvaginalUtero from '../views/ScreenLaudoTransvaginalUtero.vue';
import ScreenTransvaginalUteroComDoppler from '../views/ScreenLaudoTransvaginalUteroComDoppler.vue';
import ScreenTransvaginalUteroComTridimensional from '../views/ScreenLaudoTransvaginalUteroComTridimensional.vue';
import ScreenTransvaginalUteroParaAvaliacaoDeReservaOvariana from '../views/ScreenLaudoTransvaginalUteroParaAvaliacaoDeReservaOvariana.vue';
import ScreenTransvaginalUteroParaPesquisaDeEndometriose from '../views/ScreenLaudoTransvaginalUteroParaPesquisaDeEndometriose.vue';
import ScreenViloCorial from '../views/ScreenLaudoViloCorial.vue';
import ScreenRetorno from '../views/ScreenLaudoRetorno.vue';
import Screen3d from '../views/ScreenLaudo3d.vue';
import Screen4d from '../views/ScreenLaudo4d.vue';
import ScreenInsercaoDeDiuGuiadoPorTransvaginal from '../views/ScreenLaudoInsercaoDeDiuGuiadoPorTransvaginal.vue';
import ScreenSeriada from '../views/ScreenLaudoSeriada.vue';
import ScreenPessario from '../views/ScreenLaudoPessario.vue';
import ScreenNeurosonografia from '../views/ScreenLaudoNeurosonografia.vue';
import ScreenPelvica from '../views/ScreenLaudoPelvica.vue';
import ScreenAmniocentese from '../views/ScreenLaudoAmniocentese.vue';
import ScreenPerfilBiofisicoFetal from '../views/ScreenLaudoPerfilBiofisicoFetal.vue';
import ScreenPerfilCervical from '../views/ScreenLaudoPerfilCervical.vue';
import ScreenPersonalizado from '../views/ScreenLaudoPersonalizado.vue';
import ScreenDoppler from '../views/ScreenLaudoDoppler.vue';
import ScreenMama from '../views/ScreenLaudoMama.vue';
import ScreenAxila from '../views/ScreenLaudoAxila';
import ScreenConsultaGinecologica from '../views/ScreenLaudoConsultaGinecologica';
import ScreenObstetrica from '../views/ScreenLaudoObstetrica';
import ScreenObstetricaDoppler from '../views/ScreenLaudoObstetricaDoppler';
import ScreenObstetricaPerfilCervical from '../views/ScreenLaudoObstetricaPerfilCervical';
import ScreenObstetricaDopplerPerfilCervical from '../views/ScreenLaudoObstetricaDopplerPerfilCervical';
import ScreenObstetrica1Trimestre from '../views/ScreenLaudoObstetrica1Trimestre';
import ScreenMorfologica1Trimestre from '../views/ScreenLaudoMorfologica1Trimestre.vue';
import ScreenValoresAPagar from '../components/financeiro/ScreenValoresAPagar';
import ScreenFinanceiro from '../components/financeiro/ScreenFinanceiro';
import ScreenOcorrencias from '../components/financeiro/ScreenOcorrencias';
import ScreenRelatorios from '../components/financeiro/ScreenRelatorios';
import ScreenProducao from '../components/financeiro/ScreenProducao';
import ScreenMorfologicaComDoppler from '../views/ScreenMorfologicaComDoppler';
import ScreenMorfologicaComCervical from '../views/ScreenMorfologicaComCervical';
import ScreenMorfologicaDopplerComCervical from '../views/ScreenMorfologicaDopplerComCervical';
import ScreenPerfilCervicalComDoppler from '../views/ScreenPerfilCervicalComDoppler';
import ScreenObstetricaDopplerEEcocardiodoppler from '../views/ScreenObstetricaComDopplerEEcocardiodoppler';
import ScreenProcedimentos from '../components/financeiro/ScreenProcedimentos.vue';
import ScreenCadastrarUsuario from '../components/perfil/formPerfil.vue';
import ScreenDefineSenha from '../components/perfil/definirSenha.vue';
import ScreenRedefinirSenha from '../components/perfil/redefinirSenha.vue';
import laudos from './laudos';

export default [
  {
    path: '',
    name: 'Login',
    component: ScreenLogin,
  },
  {
    path: '/recepcao',
    name: 'HomeRecepcao',
    component: ScreenHomeRecepcao,
    props: route => ({ medicoId: route.params.medicoId }),
  },
  {
    path: '/cadastrarUsuario',
    name: 'cadastrarUsuario',
    component: ScreenCadastrarUsuario,
  },
  {
    path: '/definir-senha',
    name: 'definir-senha',
    component: ScreenDefineSenha,
  },
  {
    path: '/redefinir-senha',
    name: 'redefinir-senha',
    component: ScreenRedefinirSenha,
  },
  {
    path: '/medico',
    name: 'HomeMedico',
    component: ScreenHomeRecepcao,
  },
  {
    path: '/financeiro',
    name: 'financeiro',
    component: ScreenFinanceiro,
  },
  {
    path: '/valoresAPagar',
    name: 'valoresAPagar',
    component: ScreenValoresAPagar,
  },
  {
    path: '/ocorrencias',
    name: 'ocorrencias',
    component: ScreenOcorrencias,
  },
  {
    path: '/relatorios',
    name: 'relatorios',
    component: ScreenRelatorios,
  },
  {
    path: '/producao',
    name: 'producao',
    component: ScreenProducao,
  },

  {
    path: '/pacientes',
    name: 'ScreenListaDePacientes',
    component: ScreenListaDePacientes,
  },
  {
    path: '/paciente',
    name: 'ScreenPaciente',
    component: ScreenPaciente,
  },
  {
    path: '/cadastrar-paciente',
    name: 'CadastrarPaciente',
    component: ScreenPaciente,
  },
  {
    path: '/email',
    name: 'EmailPaciente',
    component: ScreenEmailPaciente,
  },
  {
    path: '/agendamento',
    name: 'Agendamento',
    component: ScreenConsulta,
  },

  {
    path: '/pagamento',
    name: 'pagamento',
    component: () => import('@views/pages/HomePagamento.vue'),
  },

  {
    path: '/efetuar-pagamento',
    name: 'FormularioPagamento',
    component: FormPagamento,
  },

  {
    path: '/laudos',
    name: 'HomeLaudos',
    component: ScreenHomeLaudos,
  },

  {
    path: '/laudo',
    name: 'laudo',
    component: ScreenLaudo,
    children: laudos,
  },

  {
    path: '/translucenciaNucal',
    name: 'LaudoTranslucenciaNucal',
    component: ScreenTranslucenciaNucal,
  },

  {
    path: '/morfologica',
    name: 'LaudoMorfologica',
    component: ScreenMorfologica,
  },

  {
    path: '/pelvicaTransabdominal',
    name: 'LaudoPelvicaTransabdominal',
    component: ScreenPelvicaTransabdominal,
  },

  {
    path: '/transvaginalHisterectomia',
    name: 'LaudoTransvaginalHisterectomia',
    component: ScreenTransvaginalHisterectomia,
  },

  {
    path: '/pelvicaTransvaginalDopplerPreparoIntestinal',
    name: 'LaudoPelvicaTransvaginalDopplerPreparoIntestinal',
    component: ScreenPelvicaTransvaginalDopplerPreparoIntestinal,
  },

  {
    path: '/ecocardiodopplerFetal',
    name: 'LaudoEcocardiodopplerFetal',
    component: ScreenEcocardiodopplerFetal,
  },

  {
    path: '/transvaginalUtero',
    name: 'LaudoTransvaginalUtero',
    component: ScreenTransvaginalUtero,
  },
  {
    path: '/transvaginalUteroComDoppler',
    name: 'LaudoTransvaginalUteroComDoppler',
    component: ScreenTransvaginalUteroComDoppler,
  },
  {
    path: '/transvaginalUteroComTridimensional',
    name: 'LaudoTransvaginalUteroComTridimensional',
    component: ScreenTransvaginalUteroComTridimensional,
  },
  {
    path: '/transvaginalUteroParaAvaliacaoDeReservaOvariana',
    name: 'LaudoTransvaginalUteroParaAvaliacaoDeReservaOvariana',
    component: ScreenTransvaginalUteroParaAvaliacaoDeReservaOvariana,
  },
  {
    path: '/transvaginalUteroParaPesquisaDeEndometriose',
    name: 'LaudoTransvaginalUteroParaPesquisaDeEndometriose',
    component: ScreenTransvaginalUteroParaPesquisaDeEndometriose,
  },

  {
    path: '/vilocorial',
    name: 'LaudoViloCorial',
    component: ScreenViloCorial,
  },
  {
    path: '/retorno',
    name: 'LaudoRetorno',
    component: ScreenRetorno,
  },
  {
    path: '/3d',
    name: 'Laudo3d',
    component: Screen3d,
  },
  {
    path: '/4d',
    name: 'Laudo4d',
    component: Screen4d,
  },
  {
    path: '/insercaoDeDiuGuiadoPorTransvaginal',
    name: 'LaudoInsercaoDeDiuGuiadoPorTransvaginal',
    component: ScreenInsercaoDeDiuGuiadoPorTransvaginal,
  },
  {
    path: '/seriadaavaliacaoovulacao',
    name: 'LaudoSeriadaAvaliacaoOvulacao',
    component: ScreenSeriada,
  },
  {
    path: '/colocacaodopessario',
    name: 'LaudoColocacaoDoPessario',
    component: ScreenPessario,
  },
  {
    path: '/neurosonografia',
    name: 'LaudoNeurosonografia',
    component: ScreenNeurosonografia,
  },
  {
    path: '/pelvica',
    name: 'LaudoPelvica',
    component: ScreenPelvica,
  },
  {
    path: '/amniocentes',
    name: 'LaudoAmniocentese',
    component: ScreenAmniocentese,
  },
  {
    path: '/perfilBiofisicoFetal',
    name: 'LaudoPerfilBiofisicoFetal',
    component: ScreenPerfilBiofisicoFetal,
  },
  {
    path: '/perfilcervical',
    name: 'LaudoPerfilCervical',
    component: ScreenPerfilCervical,
  },

  {
    path: '/mama',
    name: 'LaudoMama',
    component: ScreenMama,
  },

  {
    path: '/axila',
    name: 'LaudoAxila',
    component: ScreenAxila,
  },

  {
    path: '/doppler',
    name: 'LaudoDoppler',
    component: ScreenDoppler,
  },

  {
    path: '/obstetrica',
    name: 'LaudoObstetrica',
    component: ScreenObstetrica,
  },

  {
    path: '/obstetricaDoppler',
    name: 'LaudoObstetricaDoppler',
    component: ScreenObstetricaDoppler,
  },
  {
    path: '/obstetricaComDopplerEEcocardiodoppler',
    name: 'LaudoObstetricaDopplerEcocardiodoppler',
    component: ScreenObstetricaDopplerEEcocardiodoppler,
  },
  {
    path: '/obstetricaPerfilCervical',
    name: 'LaudoObstetricaPerfilCervical',
    component: ScreenObstetricaPerfilCervical,
  },
  {
    path: '/obstetricaDopplerPerfilCervical',
    name: 'LaudoObstetricaDopplerPerfilCervical',
    component: ScreenObstetricaDopplerPerfilCervical,
  },

  {
    path: '/obstetrica1Trimestre',
    name: 'LaudoLaudoObstetrica1Trimestre',
    component: ScreenObstetrica1Trimestre,
  },

  {
    path: '/morfologica1Trimestre',
    name: 'LaudoMorfológica1Trimestre',
    component: ScreenMorfologica1Trimestre,
  },
  {
    path: '/morfologicaDoppler',
    name: 'LaudoMorfologicaComDoppler',
    component: ScreenMorfologicaComDoppler,
  },

  {
    path: '/morfologicaCervical',
    name: 'LaudoMorfologicaComCervical',
    component: ScreenMorfologicaComCervical,
  },
  {
    path: '/morfologicaDopplerCervical',
    name: 'LaudoMorfologicaComDopplerCervical',
    component: ScreenMorfologicaDopplerComCervical,
  },
  {
    path: '/perfilCervicalComDoppler',
    name: 'LaudoPerfilCervicalComDoppler',
    component: ScreenPerfilCervicalComDoppler,
  },
  {
    path: '/personalizado',
    name: 'LaudoPersonalizado',
    component: ScreenPersonalizado,
  },
  {
    path: '/consultaGinecologica',
    name: 'LaudoConsultaGinecologica',
    component: ScreenConsultaGinecologica,
  },
  {
    path: '/procedimentos',
    name: 'Procedimentos',
    component: ScreenProcedimentos,
  },
];
