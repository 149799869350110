<template>
  <v-container class="d-flex align-center justify-center login-wrapper" fluid>
    <v-card :width="width" class="cyan darken-2 px-5">
      <v-card-title class="d-flex justify-center">
        <!-- <h3 class="white--text text--darken-3">CLÍNICA EMBRION</h3> -->
        <v-container>
          <v-row justify="center">
            <v-col cols="5">
              <v-img src="../assets/embrionLogin.png"></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>

      <form @submit.prevent="handleLogin">
        <v-text-field
          label="Usuário"
          filled
          solo
          clearable
          v-model="model.usuario"
        />

        <v-text-field
          :type="show ? 'text' : 'password'"
          class="teste"
          label="Senha"
          solo
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show = !show"
          filled
          clearable
          v-model="model.senha"
        />

        <v-alert v-if="mensagem" type="error" dismissible>
          Usuário e/ou senha incorretos
        </v-alert>

        <div class="d-flex justify-center mb-6 mt-4">
          <v-btn class="white" :loading="isLoading" type="submit" block large>
            Entrar
          </v-btn>
        </div>
        <v-btn plain small @click="redefinicaoDenha" class="pl-0">
          <span>Esqueceu a senha?</span>
        </v-btn>
      </form>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import imagemLogin from '../assets/embrionLogin.png';

export default {
  data: () => ({
    show: false,
    password: 'password',
    model: {
      usuario: null,
      senha: null,
    },
    imagemLogin: imagemLogin,
  }),
  watch: {
    model: {
      handler(value) {
        this.$store.commit('Auth/SET_LOGIN_MODEL', value);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('Auth', ['isLoading', 'eMedico', 'mensagem']),
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 300;
        case 'sm':
          return 350;
        case 'md':
          return 450;
        case 'lg':
          return 500;
      }
    },
  },
  methods: {
    ...mapActions('Auth', ['login']),
    async handleLogin() {
      await this.login(this.model);

      // Se tem erro não faz nada
      if (this.mensagem) return;

      // Se não tem erro, redireciona para a página principal
      const nomeDaRota = this.eMedico ? 'HomeMedico' : this.getCargo();

      this.$router.push({ name: nomeDaRota });
    },
    redefinicaoDenha() {
      this.$router.push('/redefinir-senha');
    },
    getCargo() {
      const nomeRota = '';
      if (this.nivelPermissao === 1) {
        nomeRota = 'HomeRecepcao';
      }
      if (this.nivelPermissao === 3) {
        nomeRota = 'Financeiro';
      }
      if (this.nivelPermissao === 5) {
        nomeRota = 'Super';
      }
      return nomeRota;
    },
  },
  mounted() {
    
    this.$store.commit('Auth/RESET_ALL');

   
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  height: 100vh;
  width: 100%;
}
.teste ::v-deep -webkit-autofill {
  background-color: red !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}
</style>
