<template>
  <v-dialog v-model="show" transition="dialog-top-transition">
  
    <v-card class="px-4 py-4">
      <span class="d-flex justify-end">
                <v-icon class="pa-3" @click="close">
                  mdi-close-circle-outline
                </v-icon>
              </span>
      <h1 class="text-center">Ocorrência</h1>
      

      <div class="d-flex flex-column">
        <h3 class="mt-4">Informações gerais</h3>
        <v-row dense class="mt-3">
          <v-col cols="12">
            <v-text-field label="NOME COMPLETO" class="maiuscula " readonly maiuscula solo dense v-model="consultaSelecionada.consulta.paciente.nome"/>
          </v-col>
          <v-col cols="4">
            <v-text-field
              
              label="DATA DE NASCIMENTO"
              readonly
              
              solo
              dense
              v-model="consultaSelecionada.consulta.paciente.dataNascimento"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field type="tel" label="CPF" readonly solo dense v-model="consultaSelecionada.consulta.paciente.cpf"/>
          </v-col>
          <v-col cols="4">
            <v-text-field label="GÊNERO" readonly solo dense v-model="consultaSelecionada.consulta.paciente.genero"/>
          </v-col>
          <v-col>
            <v-text-field type="tel" label="TELEFONE" readonly solo dense v-model="consultaSelecionada.consulta.paciente.telefonePrincipal"/>
          </v-col>
          <v-col cols="8">
            <v-text-field type="email" label="EMAIL" readonly solo dense v-model="consultaSelecionada.consulta.paciente.email"/>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex flex-column">
        <h3 class="mt-4">Informações da consulta</h3>
        <v-row dense class="mt-3">
          <v-col cols="6" v-if="consultaSelecionada.consulta.medicoSolicitante!= null && consultaSelecionada.consulta.medicoSolicitante != 'null'">
            <v-text-field label="MÉDICO SOLICITANTE" class="maiuscula" readonly solo dense v-model="consultaSelecionada.consulta.medicoExecutante.nome"/>
          </v-col>
          <v-col cols="6">
            <v-text-field label="DATA" readonly solo dense v-model="consultaSelecionada.consulta.data"/>
          </v-col>
          <v-col cols="6">
            <v-text-field label="HORÁRIO" type="time" readonly solo dense v-model="consultaSelecionada.consulta.hora"/>
          </v-col>
          <!-- <v-col cols="6">
            <v-text-field label="CONVÊNIO" readonly solo dense v-model="consultaSelecionada.exames[0].convenio" />
          </v-col> -->
        </v-row>
      </div>

      <div class="d-flex justify-end">
        <v-btn @click="openAll">Abrir Todos</v-btn>
        <v-btn @click="closeAll" class="ml-4">Fechar Todos</v-btn>
      </div>

      <v-expansion-panels v-model="panelsOpen" multiple class="mt-4">
        <v-expansion-panel v-for="(exame, index) of consultaSelecionada.exames" :key="index">
          <v-expansion-panel-header>
            EXAME {{ index + 1 }} -
            {{consultaSelecionada.exames[index].exame}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-column">
              <v-row dense class="mt-3">
                <v-col cols="6">
                  <v-text-field
                    label="MEDICO EXECUTANTE"
                    class="maiuscula"
                    readonly
                    solo
                    dense
                    v-model="consultaSelecionada.consulta.medicoExecutante.nome"
                  />
                </v-col>
                  
                <v-col cols="6">
                  <v-text-field
                    label="CONVÊNIO"
                    solo
                    dense
                    readonly
                    v-model="exame.convenio"
                  />
                  
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="EXAME"
                    solo
                    readonly
                    dense
                    v-model="exame.exame"
                  />
                </v-col>
              </v-row>
              

              <div class="d-flex flex-column align-center">
                <span class="text-center">VALOR</span>
                <v-text-field
                  class="text-center"
                  label="Total"
                  type="tel"
                  prefix="R$"
                  solo
                  readonly
                  dense
                  v-model="exame.valor"
                />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      

        

      <div class="d-flex flex-column align-center mt-3">
        <br><br>
        <h3>Forma de Pagamento : {{consultaSelecionada.pagamento.formaDePagamento}} </h3>
        <v-row dense class="mt-3">
            
          
          <v-col cols="12">
            <h3 class="text-center">Valor Total</h3>
            <v-text-field label="TOTAL" readonly type="tel" prefix="R$" solo dense v-model="consultaSelecionada.pagamento.valorFinal
"/>
          </v-col>
          
        </v-row>
        
        
        
        
          
       
      </div>
      
      <v-textarea
                readonly
                width="100%"
                required
                validate-on-blur
                filled
                outlined
                no-resize
                v-model="consultaSelecionada.observacao"
                label="Ocorrência" >
            </v-textarea>
    </v-card>
  </v-dialog>
</template>

<script>
import ConsultasMixin from '@/mixins/ConsultasMixin';
export default {
  mixins: [
    ConsultasMixin,
    ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    exames: [
      {
        exame: '',
        valor: '',
      },
    ],
    panelsOpen: [],
    examesPorConsulta: [],
  }),
  
  methods: {
    close() {
      this.$emit('close');
    },
    openAll() {
      this.panelsOpen = [...Array(this.consultaSelecionada.exames).keys()].map((_, index) => index);
    },
    closeAll() {
      this.panelsOpen = [];
    },
    
  },
};
</script>