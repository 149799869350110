<template>
  <v-row>
    <v-col cols="12" class="d-flex ml-12 mt-8">
       <v-btn :to="{ name: 'HomeMedico' }">
        <v-icon>mdi-arrow-left</v-icon>
        voltar
      </v-btn>
    </v-col>
    <v-col cols="12">
      <Laudo /> 
    </v-col>
  </v-row>
</template>
<script>
import Laudo from '@/components/laudos/Laudo.vue';
import ConsultaMixin from '../mixins/ConsultasMixin';

export default {
  mixins: [ ConsultaMixin ],
  components: { Laudo },
};
</script>
<style lang="scss" scoped></style>
