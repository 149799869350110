<template lang="">
  <div>
    <LaudoTransvaginalUtero></LaudoTransvaginalUtero>
  </div>
</template>
<script>
import LaudoTransvaginalUtero from "../components/laudos/formularios/exames/transvaginalUtero/LaudoTransvaginalUtero.vue";
export default {
  components: {
    LaudoTransvaginalUtero,
  },
};
</script>
<style lang=""></style>