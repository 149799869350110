<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="12" sm="12" xs="4">
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="700"
          v-model="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              block
              class="pa-md-7 pa-2 grey darken-1"
              v-bind="attrs"
              v-on="on"
            >
              <h2
                class="grey--text text--lighten-4 text-center text-button text-md-h5 font-weight-bold"
              >
                PRÓXIMO PACIENTE
              </h2>
            </v-btn>
          </template>

          <template v-slot:default="dialog">
            <v-card style="overflow: hidden">
              <span class="d-flex justify-end">
                <v-icon class="pa-3" @click="dialog.value = false">
                  mdi-close-circle-outline
                </v-icon>
              </span>
              <v-card-text>
                <div class="text-h5 pa-8 text-center font-weight-bold">
                  Próximo paciente:
                </div>

                <v-select
                  label="Selecione uma Sala"
                  :items="salas"
                  filled
                  outlined
                  v-model="model.salaNumber"
                  :loading="loading"
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title
                        :id="attrs['aria-labelledby']"
                        v-text="item"
                      />
                    </v-list-item>
                  </template>
                </v-select>
             

                <v-select
                  label="Selecione um paciente"
                  filled
                  outlined
                  :items="pacientesDoDia"
                  v-model="model.pacienteName"
                  :loading="loading"
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title
                        :id="attrs['aria-labelledby']"
                        v-text="item"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </v-card-text>

              <v-card-actions class="justify-center pb-8">
                <v-btn
                  text
                  depressed
                  class="grey darken-1 grey--text text--lighten-4 text-center mx-2 pa-3"
                  :loading="loading"
                  :disabled="loading"
                  @click="chamaPaciente"
                >
                  Confirmar
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MedicoMixin from '@mixins/MedicoMixin';
import ConsultasMixin from '@mixins/ConsultasMixin';
import CalendarioMixin from '@mixins/CalendarioMixin';

export default {
  mixins: [MedicoMixin, ConsultasMixin, CalendarioMixin],
  data: () => ({
    model: {
      pacienteName: '',
      salaNumber: '',
    },
    loading: false,
    dialog: false,
    salas: ['1', '2', '3'],
  }),
  watch: {
    medicoSelecionado: {
      handler(medico) {
        if (!medico?.id) return;
        this.getListaNomesPacientes()
        // this.getConsultByMedicoEData(medico?.id, this.dataSelecionada);
      },
      deep: true,
    },
    dataSelecionada(data) {
      if (!data) return;
      this.getListaNomesPacientes()
      // this.getConsultaByDataMedico(this.medicoSelecionado.id, data);
    },
  },
  computed: {
    pacientesDoDia() {
      return this.$store.getters['nomesPacientesPorDia'];
    },
  },
  methods: {
    ...mapActions('Paciente', ['postProximoPaciente']),
    async chamaPaciente() {
      try {
        this.loading = true;

        await this.postProximoPaciente(this.model);

        // TODO: Exibir algo de sucesso

        // Fechar modal
        this.dialog = false;
        // Limpar os campos
        this.limparCampos();
      } catch (error) {
        // Exibir algo de erro
      } finally {
        this.loading = false;
      }
    },
    limparCampos() {
      // Resetar model com os valores iniciais
      this.model = this.$options.data().model;
    },
  },
  // Vue Hook
  // Quando montar (Carregar a tela)
  mounted() {
    if (!this.medicoSelecionado?.id) return;
    if (!this.dataSelecionada) return;
    
    this.getListaNomesPacientes()
    this.$forceUpdate()

    // this.getConsultByMedicoEData(
    //   this.medicoSelecionado.id,
    //   this.dataSelecionada
    // );
  },
  beforeMount(){
    this.getListaNomesPacientes()
  }
};
</script>
