<template lang="">
  <div>
    <v-container fluid style="max-width: 95%">
      <v-container>
        <v-card height="80vh">
          <v-card-title>Novo Email</v-card-title>
          <v-form class="pa-4">
            <v-row>
              <v-col cols="12" md="12" class="py-0">
                <v-text-field
                  class="code"
                  ref="emailField"
                  label="Email"
                  v-model="email"
                  required
                  filled
                  outlined
                  validate-on-blur
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" class="py-0">
                <v-text-field
                  class="code"
                  ref="emailField"
                  label="Assunto"
                  v-model="assunto"
                  required
                  filled
                  outlined
                  validate-on-blur
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" class="py-0">
                <v-textarea
                  class="code"
                  ref="emailField"
                  label="Conteúdo"
                  v-model="conteudo"
                  required
                  filled
                  outlined
                  validate-on-blur
                  height="350px"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start pt-6 pb-4">
              <v-col cols="12" md="9">
                <v-dialog transition="dialog-bottom-transition" max-width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" @click="sendEmail" router :to="''">Enviar</v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-card-text>
                        <div class="text-h5 pa-8 text-center">Email enviado com sucesso!</div>
                      </v-card-text>
                      <v-card-actions class="justify-center pb-4">
                        <v-btn
                          text
                          depressed
                          class="grey lighten-2 grey--text texte-darken-6"
                          @click="dialog.value = false"
                          >Ok</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import PacienteMixin from "../../../mixins/PacienteMixin";
import { EMBRION_API_URL } from "@/data/enviroment";
export default {
  mixins: [PacienteMixin],
  data() {
    return {
      email: this.$route.query.email,
      assunto: "",
      conteudo: "",
      urlBase: EMBRION_API_URL,
    };
  },
  methods: {
    sendEmail() {
      const data = new FormData();
      let Attachments = [];
      data.append("ToEmail", this.email);
      data.append("Subject", this.assunto);
      data.append("Body", this.conteudo);
      this.enviaEmailPaciente(data);
      setTimeout(() => this.$router.go(-1), 2500);
    },
  },
};
</script>
<style lang=""></style>
