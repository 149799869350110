const initialState = () => ({
  requisicao: {},
  dados: {},
});

const RESET_STORE = 'RESET_STORE';
const SET_REQUISICAO = 'SET_REQUISICAO';
const SET_DADOS = 'SET_DADOS';

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    requisicao: state => state.requisicao,
    dados: state => state.dados,
  },
  mutations: {
    [RESET_STORE](state, _) {
      state.requisicao = {};
      state.dados = {};
    },

    [SET_REQUISICAO](state, payload) {
      state.requisicao = { ...payload };
    },
    [SET_DADOS](state, payload) {
      state.dados = { ...payload };
    },
  },
  actions: {
    // async getRequisicao({ commit, rootState }) {
    //   const { token } = rootState.Auth.usuarioLogado;
    //   const { data } = await PacienteEmbrion.getRequisicao({ token });
    //   commit(SET_PACIENTES, data);
    // },
    // async getDados({ commit, rootState }) {
    //   const { token } = rootState.Auth.usuarioLogado;
    //   const { data } = await PacienteEmbrion.getDados({ token });
    //   commit(SET_PACIENTES, data);
    // },
  },
};
