import axios from 'axios';

// Retornar uma instância de axios
export default (baseURL, token = null) => {
  const instance = axios.create({ baseURL });

  if(token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return instance;
};
