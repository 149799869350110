const initialState = () => ({
  datasBloqueadas: [],
  dataSelecionadaBloqueada: false,
  dataComConsultas: [],
});

const RESET_STORE = 'RESET_STORE';
const SET_DATAS_BLOQUEADAS = 'SET_DATAS_BLOQUEADAS';
const SET_DATA_SELECIONADA_BLOQUEADA = 'SET_DATA_SELECIONADA_BLOQUEADA';
const SET_DATA_COM_CONSULTA  = 'SET_DATA_COM_CONSULTA';

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    datasBloqueadas: state => state.datasBloqueadas,
    dataSelecionadaBloqueada: state => state.dataSelecionadaBloqueada,
    dataComConsultas: state => state.dataComConsultas
  },
  mutations: {
    [RESET_STORE](state, _) {
      state.datasBloqueadas = {};
      state.dataSelecionadaBloqueada = {};
    },
    [SET_DATA_COM_CONSULTA] (state, payload){
      state.dataComConsultas = [...payload];
    },

    [SET_DATAS_BLOQUEADAS](state, payload) {
      state.datasBloquedas = [...payload];
    },
    [SET_DATA_SELECIONADA_BLOQUEADA](state, payload) {
      state.dataSelecionadaBloqueada = !!payload;
    },
  },
  actions: {},
};
