import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import { EMBRION_API_URL } from '../data/enviroment';

export default {
  data() {
    return {
      urlBase: EMBRION_API_URL,
    };
  },
  computed: {
    ...mapGetters(['laudoSelecionado', 'laudos', 'laudosPaciente']),
    ...mapGetters('Auth', ['headers']),
  },
  methods: {
    ...mapActions({
      getLaudoSelecionado: 'getLaudoSelecionado',
      getLaudoByExamePorConsultaId: 'getLaudoByExamePorConsultaId',
      setLaudoSelecionada: 'setLaudoSelecionada',
      getLaudos: 'getLaudos',
      resetaLaudoSelecionado: 'resetaLaudoSelecionado',
      getLaudoPorPaciente: 'getLaudoPorPaciente',
      patchLaudoTn: 'patchLaudoTn',
    }),
    formatLaudoPatch(laudo) {
      return Object.keys(laudo).map(key => ({
        path: key,
        op: 'replace',
        value: laudo[key],
      }));
    },
    patchLaudo(laudo) {
      // Verificar se o body está sendo enviado corretamente;
      return axios.patch(`${this.urlBase}Laudos?id=${laudo.id}`, laudo.laudoJson, {
        headers: this.headers,
      });
    },
    patchLaudoOffline(laudo, urlBase, headers) {
      axios.patch(`${urlBase}Laudos?id=${laudo.id}`, laudo.laudoJson, {
        headers: headers,
      });
    },
    async patchLaudoTn(laudoId, laudo) {
      for (let i = 0; i < laudo.length; i++) {
        laudo[i].camposExibidos = this.camposExibidos[i];
      }
      const dados = await axios.patch(`${this.urlBase}Laudos?id=${laudoId}`, laudo, {
        headers: this.headers,
      });
      return dados;
    },
    patchLaudoTnOffline(laudo, laudoId, urlBase, headers) {
      axios.patch(`${urlBase}Laudos?id=${laudoId}`, laudo, {
        headers: headers,
      });
    },
    postLaudo() {
      this.laudoSelecionado.laudoJson.camposExibidos = this.camposExibidos;
      return axios.post(`${this.urlBase}Laudos`, this.laudoSelecionado, {
        headers: this.headers,
      });
    },
    postLaudoOffline(laudo, urlBase, headers) {
      return axios.post(`${urlBase}Laudos`, laudo, {
        headers: headers,
      });
    },
  },
};
