import moment from 'moment';

export default {
  idadeGestacionalMenstrualSemanas(dataUltimaMestrucao) {
    if (!dataUltimaMestrucao) return 0;
    return moment().diff(dataUltimaMestrucao, 'weeks');
  },
  idadeGestacionalMenstrualDias(dataUltimaMestrucao) {
    if (!dataUltimaMestrucao) return 0;
    return Math.floor(moment().diff(dataUltimaMestrucao, 'days') % 7);
  },
  calculaSacoGestacional() {
    let semanas = this.laudoSelecionado.laudoJson.dataGestacionalSemanas;
    let diametro = this.calculoDiametroSacoGestacional();
    if ((diametro >= 2) & (diametro <= 8)) {
      if (semanas == 4) {
        this.corSaco = 'green';
      } else {
        this.corSaco = 'red';
      }
    } else if ((diametro >= 6) & (diametro <= 16)) {
      if (semanas == 5) {
        this.corSaco = 'green';
      } else {
        this.corSaco = 'red';
      }
    } else if ((diametro >= 9) & (diametro <= 23)) {
      if (semanas == 6) {
        this.corSaco = 'green';
      } else {
        this.corSaco = 'red';
      }
    } else if ((diametro >= 15) & (diametro <= 31)) {
      if (semanas == 7) {
        this.corSaco = 'green';
      } else {
        this.corSaco = 'red';
      }
    } else if ((diametro >= 22) & (diametro <= 38)) {
      if (semanas == 8) {
        this.corSaco = 'green';
      } else {
        this.corSaco = 'red';
      }
    } else if ((diametro >= 28) & (diametro <= 37)) {
      if (semanas == 9) {
        this.corSaco = 'green';
      } else {
        this.corSaco = 'red';
      }
    } else if ((diametro >= 35) & (diametro <= 51)) {
      if (semanas == 10) {
        this.corSaco = 'green';
      } else {
        this.corSaco = 'red';
      }
    }
  },
  calculoDiametroSacoGestacional() {
    let m1 = this.laudoSelecionado.laudoJson.saco1m1;
    let m2 = this.laudoSelecionado.laudoJson.saco1m2;
    let m3 = this.laudoSelecionado.laudoJson.saco1m3;
    if (m1 == undefined || m2 == undefined || m3 == undefined) {
      return;
    }
    return ((m1 + m2 + m3) / 3).toFixed(2);
  },
  idadeGestacional() {
    if (this.laudoSelecionado.laudoJson.dataUltimaMestrucao != undefined) {
      let dataMenstruacao =
        this.laudoSelecionado.laudoJson.dataUltimaMestrucao.split('/');
      return calculoIdadeGestacional(
        dataMenstruacao[0],
        dataMenstruacao[1],
        dataMenstruacao[2]
      );
    }
  },
};
