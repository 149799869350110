<template>
  <v-dialog v-model="show" transition="dialog-top-transition" width="350px">
    <v-card class="px-4 py-4">
      <span class="d-flex justify-end">
        <v-icon class="pa-3" @click="close"> mdi-close-circle-outline </v-icon>
      </span>
      <h3 class="text-center maiuscula">
        {{ dataSelecionadaBloqueada ? 'Habilitar' : 'Desabilitar' }} data
        selecionada?
      </h3>
      <br />

      <v-btn outlined @click="statusDoDia()" block large>
        Confirmar ação
      </v-btn>

      <v-spacer></v-spacer>

      <br />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
import ConsultasMixin from '@/mixins/ConsultasMixin';
export default {
  mixins: [ConsultasMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modelo: {
      usuario: null,
      senha: null,
    },
  }),

  methods: {
    ...mapActions('Auth', ['login']),
    close() {
      this.$emit('close');
    },
    async statusDoDia() {
      
      if (this.dataSelecionadaBloqueada) {
        this.desbloquearData(this.dataSelecionada, this.medicoSelecionado.id);
      } else {
        this.postdataBloqueada(this.dataSelecionada, this.medicoSelecionado.id);
      }

      this.mostraAlerta = false;
      this.close();
    },
  },
  computed: {
    ...mapGetters('Calendario', ['dataSelecionadaBloqueada']),
    ...mapGetters('medicoSelecionado'),

    dataSelecionadaBloqueada() {
      return this.$store.getters['Calendario/dataSelecionadaBloqueada'];
    },
  },
};
</script>

<style></style>
