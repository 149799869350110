<template lang="">
  <div>
    <Email></Email>
  </div>
</template>
<script>
import Email from "../components/paciente/formularios/Email.vue";
export default {
  components: {
    Email,
  },
};
</script>
<style lang=""></style>
