<template>
  <section class="mt-6">
    <template v-if="clicouNoPersonalizado">
      <v-card class="my-2 py-4 text-center teal darken-4">
        <h2 class="grey--text text--lighten-4">
          ULTRASSONOGRAFIA OBSTÉTRICA COM PERFIL CERVICAL
        </h2>
      </v-card>
      <v-col
        cols="12"
        class="form-group"
        v-for="index in quantidadeFolhasEditorPersonalizado"
        :key="index"
      >
        <h2>Folha {{ index }}</h2>
        <v-btn plain small @click="removeFolha(index)" class="pl-0">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <vue-editor v-model="conteudoEditorPersonalizado[index - 1]" />
      </v-col>

      <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
    </template>
    <template v-else>
      <v-card>
        <v-card class="my-2 py-4 text-center teal darken-4">
          <h2 class="grey--text text--lighten-4">
            ULTRASSONOGRAFIA OBSTÉTRICA COM PERFIL CERVICAL
          </h2>
        </v-card>

        <v-alert
          v-if="!!laudos[0].folhas"
          outlined
          type="warning"
          border="left"
          text=""
        >
          Esse <strong>laudo</strong> teve a impressão
          <strong>personalizada.</strong>
          <v-btn text @click="adcionarConteudoEditorPersonalizado"
            >Clique aqui para visualizar</v-btn
          >
        </v-alert>

        <v-tabs class="pa-4">
          <v-tab v-for="(laudo, index) in laudos" :key="index">
            Laudo {{ index + 1 }}
          </v-tab>

          <v-tab-item v-for="(laudo, index) in laudos" :key="index">
            <v-row class="pt-5" no-gutters>
              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              ><h4>História materna</h4>
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >Data de nascimento 
              <v-text-field
                  type="date"
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  dense
                  @change="calcularIdadeEMeses"
                  v-model="laudo.dataNascimento"
                
                />
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0 && laudo.dataNascimento"
              >
            <span>
              {{ laudo.anos }} {{ laudo.anos>1 ? ' anos ': 'ano'}} e {{ laudo.meses }} {{ laudo.meses>1?' meses ':'mês' }} 
            </span>
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >Peso
              <v-text-field
                  
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  dense
                  @change="calculaImc"
                 
                  v-model="laudo.peso"
                  
                />
                <span>kg. Altura</span>
                <v-text-field
                  
                  class="mx-2 flex-grow-0"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  dense
                  @change="calculaImc"
                 
                  v-model="laudo.altura"
                  
                />
                <span>cm. &nbsp; </span>
                <span>IMC: {{ laudo.imc }}</span>
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Método de concepção: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Espontâneo',
                    'Medicamento para ovulação',
                    'Fertilização in vitro'

                  ]"
                  validate-on-blur
                  :readonly="carregamentoDoLaudo || !podeEditar"
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.metodoConcepcao"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Tabagismo durante a gravidez: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.fuma"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Hipertensão : </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.hipertensao"
                />
            
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Diabetes : </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.diabetes"
                />
                <span v-if="laudo.diabetes == 'Sim'">. Tipo: </span>
              <v-select
              v-if="laudo.diabetes == 'Sim'"
                  class="mx-2 flex-grow-0"
                  :items="[
                    'gestacional',
                    'Tipo I',
                    'Tipo II'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.tipoDiabetes"
                />
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Outras intercorrências: </span>
              <v-text-field
                  class="mx-8 flex-grow-6"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.intecorrencia"
                />
            </v-col>
            <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
              <span>Uso de medicações: </span>
              <v-select
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Sim',
                    'Não'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                 
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.medicacao"
                />
                <span v-if="laudo.medicacao == 'Sim'">. Tipo</span>
              <v-select
              v-if="laudo.medicacao == 'Sim'"
                  class="mx-2 flex-grow-0"
                  :items="[
                    'Insulina',
                    'anti-hipertensivo',
                    'ácido fólico',
                    'vitaminas',
                    'Ferro injetável',
                    'Heparina',
                    'Progesterona',
                    'outros'

                  ]"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  validate-on-blur
                  :clearable="podeEditar"
                  required
                  dense
                  v-model="laudo.usoMedicacao"
                />
                <v-text-field
                v-if="laudo.usoMedicacao == 'outros'"
                  class="mx-8 flex-grow-6"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                 
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.outroUsoMedicacao"
                />
            </v-col>
            
              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                v-if="index == 0"
              >
                <span
                  :class="
                    !camposExibidos[index].exibeIndicacaoExame
                      ? 'text--disabled'
                      : ''
                  "
                  >Indicação do exame:</span
                >
                <v-select
                  :items="indicacao"
                  validate-on-blur
                  required
                  :disabled="!camposExibidos[index].exibeIndicacaoExame"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :disable="podeEditar"
                  dense
                  v-model="laudo.indicacaoExame"
                  class="flex-grow-0 mx-2"
                  
                />
                <v-text-field
                v-if="laudo.indicacaoExame == 'Outros'"
                  class="mx-2 flex-grow-0"
                  v-mask="''"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exibeIndicacaoExame"
                  validate-on-blur
                  required
                  dense
                  v-model="laudo.indicacaoExameOutros"
                />
                <span
                  :class="
                    !camposExibidos[index].exibeIndicacaoExame
                      ? 'text--disabled'
                      : ''
                  "
                  >.</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeIndicacaoExame"
                  @click="camposExibidos[index].exibeIndicacaoExame = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exibeIndicacaoExame"
                  @click="
                    camposExibidos[index].exibeIndicacaoExame = false;
                    laudo.indicacaoExame = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeGestacao ? '' : 'text--disabled'
                }`"
              >
                <span>Gestação</span>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :items="tipoGemeo"
                  :disabled="!camposExibidos[index].exibeGestacao"
                  required
                  validate-on-blur
                  dense
                  class="flex-grow-0 mx-2"
                  v-model="infosCompartilhadas.gestacao"
                />
                <span>.</span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeGestacao"
                  @click="camposExibidos[index].exibeGestacao = true"
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="camposExibidos[index].exibeGestacao = false"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h4>Posição fetal</h4>
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="` ${
                    camposExibidos[index].exibeSituacao2 ? '' : 'text--disabled'
                  }`"
                  >Situação</span
                >
                <v-select
                  :items="['longitudinal', 'transversa']"
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.situacao"
                  :disabled="!camposExibidos[index].exibeSituacao2"
                />
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeSituacao2"
                  @click="
                    camposExibidos[index].exibeSituacao2 = true;
                    laudo.situacao = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeSituacao2 = false;
                    laudo.situacao = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="` ${
                    camposExibidos[index].exibeApresentacao
                      ? ''
                      : 'text--disabled'
                  }`"
                  >, apresentação</span
                >

                <v-select
                  :items="['cefálica', 'pélvica', 'córmica']"
                  required
                  :disabled="!camposExibidos[index].exibeApresentacao"
                  dense
                  class="flex-grow-0 mx-2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.apresentacao"
                >
                </v-select>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeApresentacao"
                  @click="
                    camposExibidos[index].exibeApresentacao = true;

                    laudo.apresentacao = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeApresentacao = false;

                    laudo.apresentacao = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="` ${
                    camposExibidos[index].exibeSituacao ? '' : 'text--disabled'
                  }`"
                  >com dorso</span
                >

                <v-select
                  :items="['anterior', 'posterior']"
                  :disabled="!camposExibidos[index].exibeSituacao"
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.dorso"
                >
                </v-select>

                <span
                  v-if="laudo.dorso2 != ' na linha mediana'"
                  :class="` ${
                    camposExibidos[index].exibeSituacao ? '' : 'text--disabled'
                  }`"
                ></span>

                <v-select
                  :items="['à direita', 'à esquerda', 'na linha mediana']"
                  required
                  :disabled="!camposExibidos[index].exibeSituacao"
                  dense
                  class="flex-grow-0 mx-2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.dorso2"
                >
                </v-select>

                <span
                  :class="` ${
                    camposExibidos[index].exibeSituacao ? '' : 'text--disabled'
                  }`"
                  >.</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeSituacao"
                  @click="
                    camposExibidos[index].exibeSituacao = true;
                    laudo.dorso = undefined;
                    laudo.dorso2 = undefined;
                    laudo.apresentacao = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeSituacao = false;
                    laudo.dorso = undefined;
                    laudo.dorso2 = undefined;
                    laudo.apresentacao = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h4>Biometria fetal</h4>
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exDiamBiparietal
                      ? 'text--disabled'
                      : ''
                  "
                  >DBP :</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  :disabled="!camposExibidos[index].exDiamBiparietal"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-mask="''"
                  v-model="laudo.diametroBiparietal"
                ></v-text-field>
                <span
                  class="mr-2"
                  :class="
                    !camposExibidos[index].exDiamBiparietal
                      ? 'text--disabled'
                      : ''
                  "
                  >mm</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exDiamBiparietal"
                  @click="
                    camposExibidos[index].exDiamBiparietal = true;
                    laudo.diametroBiparietal = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exDiamBiparietal"
                  @click="
                    camposExibidos[index].exDiamBiparietal = false;
                    laudo.diametroBiparietal = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <span
                  :class="
                    !camposExibidos[index].exPercentil1 ? 'text--disabled' : ''
                  "
                  >(percentil</span
                >

                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  :disabled="!camposExibidos[index].exPercentil1"
                  dense
                  class="flex-grow-0 mx-2"
                  v-mask="''"
                  validate-on-blur
                  v-model="laudo.percentil1"
                ></v-text-field>

                <span
                  :class="
                    !camposExibidos[index].exPercentil1 ? 'text--disabled' : ''
                  "
                  >).</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPercentil1"
                  @click="
                    camposExibidos[index].exPercentil1 = true;
                    laudo.percentil1 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPercentil1"
                  @click="
                    camposExibidos[index].exPercentil1 = false;
                    laudo.percentil1 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exDOF
                      ? 'text--disabled'
                      : ''
                  "
                  >DOF :</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  :disabled="!camposExibidos[index].exDOF"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-mask="''"
                  v-model="laudo.dof"
                ></v-text-field>
                <span
                  class="mr-2"
                  :class="
                    !camposExibidos[index].exDOF
                      ? 'text--disabled'
                      : ''
                  "
                  >mm</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exDOF"
                  @click="
                    camposExibidos[index].exDOF = true;
                    laudo.dof = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exDOF"
                  @click="
                    camposExibidos[index].exDOF = false;
                    laudo.dof = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exCircCranio ? 'text--disabled' : ''
                  "
                  >CC :</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exCircCranio"
                  dense
                  class="flex-grow-0 mx-2"
                  required
                  validate-on-blur
                  @change="getPercentilCC(index)"
                  v-mask="''"
                  v-model="laudo.circunferenciaCraniana"
                ></v-text-field>
                <span
                  class="mr-2"
                  :class="
                    !camposExibidos[index].exCircCranio ? 'text--disabled' : ''
                  "
                  >mm</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exCircCranio"
                  @click="
                    camposExibidos[index].exCircCranio = true;
                    laudo.circunferenciaCraniana = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exCircCranio"
                  @click="
                    camposExibidos[index].exCircCranio = false;
                    laudo.circunferenciaCraniana = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <span
                  :class="
                    !camposExibidos[index].exPercentil2 ? 'text--disabled' : ''
                  "
                  >(percentil</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  :disabled="!camposExibidos[index].exPercentil2"
                  dense
                  class="flex-grow-0 mx-2"
                  v-mask="''"
                  validate-on-blur
                  v-model="laudo.percentil2"
                ></v-text-field>
                <span
                  :class="
                    !camposExibidos[index].exPercentil2 ? 'text--disabled' : ''
                  "
                  >).</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPercentil2"
                  @click="
                    camposExibidos[index].exPercentil2 = true;
                    laudo.percentil2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPercentil2"
                  @click="
                    camposExibidos[index].exPercentil2 = false;
                    laudo.percentil2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exCircAbdomen ? 'text--disabled' : ''
                  "
                  >CA :</span
                >
                <v-text-field
                  dense
                  :disabled="!camposExibidos[index].exCircAbdomen"
                  class="flex-grow-0 mx-2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-mask="''"
                  v-model="laudo.circunferenciaAbdominal"
                ></v-text-field>
                <span
                  class="mr-2"
                  :class="
                    !camposExibidos[index].exCircAbdomen ? 'text--disabled' : ''
                  "
                  >mm</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exCircAbdomen"
                  @click="
                    camposExibidos[index].exCircAbdomen = true;
                    laudo.circunferenciaAbdominal = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exCircAbdomen"
                  @click="
                    camposExibidos[index].exCircAbdomen = false;
                    laudo.circunferenciaAbdominal = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >

                <span
                  :class="
                    !camposExibidos[index].exPercentil3 ? 'text--disabled' : ''
                  "
                  >(percentil</span
                >

                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exPercentil3"
                  dense
                  class="flex-grow-0 mx-2"
                  required
                  v-mask="''"
                  validate-on-blur
                  v-model="laudo.percentil3"
                ></v-text-field>

                <span
                  :class="
                    !camposExibidos[index].exPercentil3 ? 'text--disabled' : ''
                  "
                  >).</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPercentil3"
                  @click="
                    camposExibidos[index].exPercentil3 = true;
                    laudo.percentil3 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPercentil3"
                  @click="
                    camposExibidos[index].exPercentil3 = false;
                    laudo.percentil3 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exComprimentoFemoral
                      ? 'text--disabled'
                      : ''
                  "
                  >CF :</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  @change="getPercentilFemur(index)"
                  :disabled="!camposExibidos[index].exComprimentoFemoral"
                  required
                  validate-on-blur
                  dense
                  class="flex-grow-0 mx-2"
                  v-mask="''"
                  v-model="laudo.comprimentoFemoral"
                ></v-text-field>
                <span
                  :class="
                    !camposExibidos[index].exComprimentoFemoral
                      ? 'text--disabled'
                      : ''
                  "
                  >mm
                </span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exComprimentoFemoral"
                  @click="
                    camposExibidos[index].exComprimentoFemoral = true;
                    laudo.comprimentoFemoral = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exComprimentoFemoral"
                  @click="
                    camposExibidos[index].exComprimentoFemoral = false;
                    laudo.comprimentoFemoral = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="
                    !camposExibidos[index].exPercentil4 ? 'text--disabled' : ''
                  "
                  >(percentil</span
                >

                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exPercentil4"
                  required
                  v-mask="''"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-model="laudo.percentil4"
                ></v-text-field>
                <span
                  :class="
                    !camposExibidos[index].exPercentil4 ? 'text--disabled' : ''
                  "
                  >).</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPercentil4"
                  @click="
                    camposExibidos[index].exPercentil4 = true;
                    laudo.percentil4 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPercentil4"
                  @click="
                    camposExibidos[index].exPercentil4 = false;
                    laudo.percentil4 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exPesoFetal ? 'text--disabled' : ''
                  "
                  >Peso fetal estimado de</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  :disabled="!camposExibidos[index].exPesoFetal"
                  validate-on-blur
                  dense
                  class="flex-grow-0 mx-2"
                  v-mask="''"
                  v-model="laudo.pesoFetal"
                ></v-text-field>

                <span
                  :class="
                    !camposExibidos[index].exPesoFetal ? 'text--disabled' : ''
                  "
                  >gramas</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPesoFetal"
                  @click="
                    camposExibidos[index].exPesoFetal = true;
                    laudo.pesoFetal = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPesoFetal"
                  @click="
                    camposExibidos[index].exPesoFetal = false;
                    laudo.pesoFetal = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="
                    !camposExibidos[index].exPercentil5 ? 'text--disabled' : ''
                  "
                  >, correspondendo ao percentil</span
                >

                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  required
                  :disabled="!camposExibidos[index].exPercentil5"
                  v-mask="''"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-model="laudo.percentil5"
                ></v-text-field>
                <span
                  :class="
                    !camposExibidos[index].exPercentil5 ? 'text--disabled' : ''
                  "
                  >.</span
                >

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPercentil5"
                  @click="
                    camposExibidos[index].exPercentil5 = true;

                    laudo.percentil5 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPercentil5"
                  @click="
                    camposExibidos[index].exPercentil5 = false;

                    laudo.percentil5 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>
              <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos[index].exibeccca ? '' : 'text--disabled'
          }`">
               <span>CC/CA:</span>
          <v-text-field v-mask="''" required :disabled="!camposExibidos[index].exibeccca" validate-on-blur
            class="mx-2 flex-grow-0" dense :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudo.ccca"></v-text-field>
          <span>%</span>
          <v-btn dense depressed text icon v-if="!camposExibidos[index].exibeccca" @click="
            camposExibidos[index].exibeccca = true;
          laudo.ccca = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos[index].exibeccca = false;
          laudo.ccca = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>
        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos[index].exibecfcc ? '' : 'text--disabled'
          }`">
          <span>CF/CC:</span>
          <v-text-field v-mask="''" required :disabled="!camposExibidos[index].exibecfcc" validate-on-blur
            class="mx-2 flex-grow-0" dense :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudo.cfcc"></v-text-field>
          <span>%</span>
          <v-btn dense depressed text icon v-if="!camposExibidos[index].exibecfcc" @click="
            camposExibidos[index].exibecfcc = true;
          laudo.cfcc = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos[index].exibecfcc = false;
          laudo.cfcc = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>
        <v-col cols="12" :class="`d-flex justify-start align-center ${camposExibidos[index].exibecfca ? '' : 'text--disabled'
          }`">
          <span>CF/CA:</span>
          <v-text-field v-mask="''" required :disabled="!camposExibidos[index].exibecfca" validate-on-blur
            class="mx-2 flex-grow-0" dense :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudo.cfca"></v-text-field>
          <span>%</span>
          <v-btn dense depressed text icon v-if="!camposExibidos[index].exibecfca" @click="
            camposExibidos[index].exibecfca = true;
          laudo.cfca = undefined;
          "><v-icon>mdi-eye-off</v-icon></v-btn>
          <v-btn dense depressed text icon v-else @click="
            camposExibidos[index].exibecfca = false;
          laudo.cfca = undefined;
          "><v-icon>mdi-eye</v-icon></v-btn>
        </v-col>
              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exIndCef ? 'text--disabled' : ''
                  "
                  >Índice cefálico (DBP/DOF)</span
                >
                <v-select
                  :items="['normal','braquicefalia', 'dolicocefalia']"
                  validate-on-blur
                  required
                  :disabled="!camposExibidos[index].exIndCef"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :disable="podeEditar"
                  dense
                  v-model="laudo.indiceCefalico"
                  class="flex-grow-0 mx-2"
                  
                />
                
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exIndCef"
                  @click="
                    camposExibidos[index].exIndCef = true;

                    laudo.indiceCefalico = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exIndCef"
                  @click="
                    camposExibidos[index].exIndCef = false;

                    laudo.indiceCefalico = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                </v-col>


              <v-col cols="12" class="d-flex justify-start align-center">
                <h4>Vitalidade fetal</h4>
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exBatimentoFeto ? 'text--disabled' : ''
                "
              >
                <span>Batimentos cardíacos fetais</span>
                <v-select
                  :items="['presentes', 'ausentes']"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exBatimentoFeto"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-model="laudo.batimentosCardiacosFetais"
                >
                </v-select>

                <span v-if="laudo.batimentosCardiacosFetais == 'presentes'"
                  >,</span
                >
                <span v-if="laudo.batimentosCardiacosFetais == 'presentes'">
                  <v-select
                    :items="['rítmicos', 'com ritmo cardíaco irregular']"
                    :disabled="!camposExibidos[index].exBatimentoFeto"
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    dense
                    class="flex-grow-0 mx-2"
                    validate-on-blur
                    v-model="laudo.batimentosCardiacosFetais2"
                  >
                  </v-select>
                </span>
                <v-span
                  v-if="
                    laudo.batimentosCardiacosFetais2 ==
                    'com ritmo cardíaco irregular'
                  "
                >
                  <v-textarea
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :disabled="!camposExibidos[index].exBatimentoFeto"
                    required
                    validate-on-blur
                    filled
                    color="#074535"
                    outlined
                    no-resize
                    dense
                    class="flex-grow-0 mx-2"
                    label="Caracterize a arritimia se necessário:"
                    v-model="laudo.caracterizacaoArritimia"
                  >
                  </v-textarea>
                </v-span>

                <span v-if="laudo.batimentosCardiacosFetais == 'presentes'"
                  >, com frequência cardíaca de</span
                >

                <v-span v-if="laudo.batimentosCardiacosFetais == 'presentes'">
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exBatimentoFeto"
                    v-mask="''"
                    dense
                    class="flex-grow-0 mx-2"
                    validate-on-blur
                    v-model="laudo.frequenciabpm"
                  ></v-text-field>
                </v-span>

                <span v-if="laudo.batimentosCardiacosFetais == 'presentes'"
                  >bpm</span
                >
                <span>.</span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exBatimentoFeto"
                  @click="
                    camposExibidos[index].exBatimentoFeto = true;
                    laudo.batimentosCardiacosFetais = undefined;
                    laudo.batimentosCardiacosFetais2 = undefined;
                    laudo.caracterizacaoArritimia = undefined;
                    laudo.frequenciabpm = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exBatimentoFeto"
                  @click="
                    camposExibidos[index].exBatimentoFeto = false;
                    laudo.batimentosCardiacosFetais = undefined;
                    laudo.batimentosCardiacosFetais2 = undefined;
                    laudo.caracterizacaoArritimia = undefined;
                    laudo.frequenciabpm = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="
                  !camposExibidos[index].exMovCorporais ? 'text--disabled' : ''
                "
              >
                <span>Movimentos corporais</span>
                <v-select
                  :items="['presentes', 'ausentes']"
                  required
                  :disabled="!camposExibidos[index].exMovCorporais"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-model="laudo.movimentosCorporais"
                >
                </v-select>
                <span>.</span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exMovCorporais"
                  @click="
                    camposExibidos[index].exMovCorporais = true;
                    laudo.movimentosCorporais = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exMovCorporais"
                  @click="
                    camposExibidos[index].exMovCorporais = false;
                    laudo.movimentosCorporais = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <!-- <v-col
                cols="12"
                class="d-flex justify-start align-center"
                :class="!camposExibidos[index].exTonus ? 'text--disabled' : ''"
              >
                <span>Tônus</span>
                <v-select
                  :items="['normal', 'abolido']"
                  dense
                  :disabled="!camposExibidos[index].exTonus"
                  class="flex-grow-0 mx-2"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.tonus"
                >
                </v-select>
                <span>.</span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exTonus"
                  @click="
                    camposExibidos[index].exTonus = true;
                    laudo.tonus = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exTonus"
                  @click="
                    camposExibidos[index].exTonus = false;
                    laudo.tonus = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col> -->

              <v-col cols="12" class="d-flex justify-start align-center">
                <span>Volume de líquido amniótico</span>
                <v-select
                  :items="['normal', 'aumentado', 'reduzido', 'ausente']"
                  required
                  dense
                  class="flex-grow-0 mx-2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.volumeLíquidoAmniotico"
                >
                </v-select>
                <span
                  :class="
                    !camposExibidos[index].exVolLiquidoAmni
                      ? 'text--disabled'
                      : ''
                  "
                  >com maior bolsão medindo</span
                >
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  v-mask="''"
                  :disabled="!camposExibidos[index].exVolLiquidoAmni"
                  validate-on-blur
                  dense
                  class="flex-grow-0 mx-2"
                  v-model="laudo.percentil6"
                ></v-text-field>
                <span>mm.</span>

                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exVolLiquidoAmni"
                  @click="
                    camposExibidos[index].exVolLiquidoAmni = true;
                    laudo.volumeLíquidoAmniotico = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exVolLiquidoAmni"
                  @click="
                    camposExibidos[index].exVolLiquidoAmni = false;
                    laudo.volumeLíquidoAmniotico = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <span
                  :class="
                    !camposExibidos[index].exPlacenta2 ? 'text--disabled' : ''
                  "
                  >Placenta:</span
                >
                <v-select
                  :items="[
                    'anterior',
                    'posterior',
                    'lateral direita',
                    'lateral esquerda',
                    'fúndica',
                    'bilobulada'
                  ]"
                  required
                  :disabled="!camposExibidos[index].exPlacenta2"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  dense
                  class="flex-grow-0 mx-2"
                  validate-on-blur
                  v-model="laudo.placenta"
                >
                </v-select>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPlacenta2"
                  @click="
                    camposExibidos[index].exPlacenta2 = true;
                    laudo.placenta = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPlacenta2"
                  @click="
                    camposExibidos[index].exPlacenta2 = false;
                    laudo.placenta = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="
                    !camposExibidos[index].exInsercao2 ? 'text--disabled' : ''
                  "
                >
                  , de inserção</span
                >
                <v-select
                  :items="[
                    'alta',
                    'baixa margeando o orifício cervical interno',
                    'baixa ultrapassando o orifício cervical interno',
                  ]"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  validate-on-blur
                  :disabled="!camposExibidos[index].exInsercao2"
                  dense
                  class="flex-grow-0 mx-2"
                  v-model="laudo.insercao"
                >
                </v-select>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exInsercao2"
                  @click="
                    camposExibidos[index].exInsercao2 = true;
                    laudo.insercao = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exInsercao2"
                  @click="
                    camposExibidos[index].exInsercao2 = false;
                    laudo.insercao = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <span
                  :class="
                    !camposExibidos[index].exPlacenta ? 'text--disabled' : ''
                  "
                  >, grau</span
                >
                <v-select
                  :items="['0', 'I', 'II', 'III']"
                  required
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :clearable="podeEditar"
                  :disabled="!camposExibidos[index].exPlacenta"
                  validate-on-blur
                  dense
                  class="flex-grow-0 mx-2"
                  v-model="laudo.grau"
                >
                </v-select>
                <span
                  :class="
                    !camposExibidos[index].exPlacenta ? 'text--disabled' : ''
                  "
                  >segundo a classificação de Grannum <span
                  :class="
                    !camposExibidos[index].exGrannum ? 'text--disabled' : ''
                  "
                  >( de acordo com a literatura científica, o grau placentário não tem importância clinica e não está relacionado a maturidade fetal e tampouco é sinal de sofrimento fetal )
                  <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exGrannum"
                  @click="
                    camposExibidos[index].exGrannum = true;

                    
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exGrannum"
                  @click="
                    camposExibidos[index].exGrannum = false;

                   
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                </span>
                
                </span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exPlacenta"
                  @click="
                    camposExibidos[index].exPlacenta = true;

                    laudo.grau = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="camposExibidos[index].exPlacenta"
                  @click="
                    camposExibidos[index].exPlacenta = false;

                    laudo.grau = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <v-textarea
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  filled
                  color="#074535"
                  outlined
                  no-resize
                  dense
                  label="Comentários adicionais:"
                  v-model="laudo.comentariosAdcionaisMeio1"
                >
                </v-textarea>
              </v-col>

              <div v-if="laudo.batimentosCardiacosFetais == 'ausentes'">
                <v-col cols="12" class="d-flex justify-start align-center">
                  <h3>Conclusão</h3>

                  <span>Óbito fetal.</span>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exAusentes ? 'text--disabled' : ''
                  "
                >
                  <span>Idade gestacional de</span>
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :disabled="!camposExibidos[index].exAusentes"
                    required
                    v-mask="''"
                    dense
                    class="mx-2 flex-grow-0"
                    validate-on-blur
                    v-model.number="laudo.idadeGestacional"
                  ></v-text-field>
                  <span>semanas.</span>
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exAusentes"
                    @click="
                      camposExibidos[index].exAusentes = true;
                      laudo.idadeGestacional = undefined;
                      laudo.comentariosAdcionaisFinal1 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exAusentes"
                    @click="
                      camposExibidos[index].exAusentes = false;
                      laudo.idadeGestacional = undefined;
                      laudo.comentariosAdcionaisFinal1 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exAusentes ? 'text--disabled' : ''
                  "
                >
                  <v-textarea
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exAusentes"
                    validate-on-blur
                    filled
                    outlined
                    no-resize
                    dense
                    label="Comentários adicionais:"
                    v-model="laudo.comentariosAdcionaisFinal1"
                  >
                  </v-textarea>
                </v-col>
              </div>

              <div v-if="laudo.batimentosCardiacosFetais === 'presentes'">
                <v-col cols="12" class="d-flex justify-start align-center">
                  <h3>Conclusão</h3>
                </v-col>

                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="!camposExibidos[index].exGest ? 'text--disabled' : ''"
                >
                  <span>Gestação tópica</span>
                  <v-select
                    class="mx-2 flex-grow-0"
                    :items="[
                      ...tipoGemeo,
                      {
                        text: 'Quíntuplo',
                        value: 5,
                      },
                    ]"
                    validate-on-blur
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :disabled="!camposExibidos[index].exGest"
                    dense
                    v-model="infosCompartilhadas.gestacao"
                  />

                  <v-select
                    v-if="infosCompartilhadas.gestacao === 2"
                    class="mx-2 flex-grow-0"
                    :items="['dicoriônica', 'monocoriônica']"
                    dense
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    validate-on-blur
                    v-model="laudo.gestacaoTres"
                  />

                  <v-select
                    v-if="infosCompartilhadas.gestacao === 2"
                    class="mx-2 flex-grow-0"
                    :items="['diamniótica ', 'monoamniótica']"
                    dense
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    validate-on-blur
                    v-model="laudo.gestacaoQuatro"
                  />

                  <v-textarea
                    v-if="[3, 4, 5].includes(infosCompartilhadas.gestacao)"
                    class="mx-2 flex-grow-0"
                    dense
                    rows="3"
                    no-resize
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    validate-on-blur
                    v-model="laudo.gestacaoCinco"
                  /><span>, feto</span>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :items="['vivo','morto']"
                  :disabled="!camposExibidos[index].exibeGestacao"
                  required
                  validate-on-blur
                  dense
                  class="flex-grow-0 mx-2"
                  v-model="laudo.estadoFeto"
                />

                  <span>.</span>

                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exGest"
                    @click="
                      camposExibidos[index].exGest = true;

                      laudo.gestacaoTres = undefined;
                      laudo.gestacaoQuatro = undefined;
                      laudo.gestacaoCinco = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exGest"
                    @click="
                      camposExibidos[index].exGest = false;

                      laudo.gestacaoTres = undefined;
                      laudo.gestacaoQuatro = undefined;
                      laudo.gestacaoCinco = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>
                <v-col cols="12" class="d-flex justify-start align-center">
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGestcorrigida2
                        ? 'text--disabled'
                        : ''
                    "
                    >Idade gestacional corrigida</span
                  >
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exIdadeGestcorrigida2"
                    v-mask="'##'"
                    validate-on-blur
                    dense
                    class="flex-grow-0 mx-2"
                    v-model="laudo.idadeGestacionalCorrigida2"
                  ></v-text-field>
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exIdadeGestcorrigida2"
                    @click="
                      camposExibidos[index].exIdadeGestcorrigida2 = true;

                      laudo.idadeGestacionalCorrigida2 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exIdadeGestcorrigida2"
                    @click="
                      camposExibidos[index].exIdadeGestcorrigida2 = false;

                      laudo.idadeGestacionalCorrigida2 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGestcorrigida2
                        ? 'text--disabled'
                        : ''
                    "
                    >semanas
                  </span>
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGestDiascorrigida2 ? 'text--disabled' : ''
                    "
                    >&nbsp; e</span
                  >
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exIdadeGestDiascorrigida2"
                    v-mask="'##'"
                    validate-on-blur
                    dense
                    class="flex-grow-0 mx-2"
                    v-model="laudo.semanasCorrigida11"
                  ></v-text-field>
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGestDiascorrigida2 ? 'text--disabled' : ''
                    "
                    >dias.
                  </span>

                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exIdadeGestDiascorrigida2"
                    @click="
                      camposExibidos[index].exIdadeGestDiascorrigida2 = true;

                      laudo.semanasCorrigida11 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exIdadeGestDiascorrigida2"
                    @click="
                      camposExibidos[index].exIdadeGestDiascorrigida2 = false;

                      laudo.semanasCorrigida11 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>

                <v-col cols="12" class="d-flex justify-start align-center">
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGest2
                        ? 'text--disabled'
                        : ''
                    "
                    >Biometria fetal atual compatível com </span
                  >
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exIdadeGest2"
                    v-mask="'##'"
                    validate-on-blur
                    dense
                    class="flex-grow-0 mx-2"
                    v-model="laudo.idadeGestacional2"
                  ></v-text-field>
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exIdadeGest2"
                    @click="
                      camposExibidos[index].exIdadeGest2 = true;

                      laudo.idadeGestacional2 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exIdadeGest2"
                    @click="
                      camposExibidos[index].exIdadeGest2 = false;

                      laudo.idadeGestacional2 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGest2
                        ? 'text--disabled'
                        : ''
                    "
                    >semanas
                  </span>
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGest ? 'text--disabled' : ''
                    "
                    >&nbsp; e</span
                  >
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exIdadeGest"
                    v-mask="'##'"
                    validate-on-blur
                    dense
                    class="flex-grow-0 mx-2"
                    v-model="laudo.semanas11"
                  ></v-text-field>
                  <span
                    :class="
                      !camposExibidos[index].exIdadeGest ? 'text--disabled' : ''
                    "
                    >dias.
                  </span>

                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exIdadeGest"
                    @click="
                      camposExibidos[index].exIdadeGest = true;

                      laudo.semanas11 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exIdadeGest"
                    @click="
                      camposExibidos[index].exIdadeGest = false;

                      laudo.semanas11 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>

                <!-- <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exDataProvavelParto
                      ? 'text--disabled'
                      : ''
                  "
                >
                  <span>
                    Data provável do parto pela biometria fetal atual (40
                    semanas):
                  </span>
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="!camposExibidos[index].exDataProvavelParto"
                    type="date"
                    dense
                    class="flex-grow-0 mx-2"
                    validate-on-blur
                    v-model="laudo.dataProvavelParto"
                  ></v-text-field>
                  <span>.</span>

                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exDataProvavelParto"
                    @click="
                      camposExibidos[index].exDataProvavelParto = true;

                      laudo.dataProvavelParto = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exDataProvavelParto"
                    @click="
                      camposExibidos[index].exDataProvavelParto = false;

                      laudo.dataProvavelParto = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col> -->

                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exDataProvavelPartoPrimTrim
                      ? 'text--disabled'
                      : ''
                  "
                >
                  <span
                    >Data provável do parto por ultrassonografia corrigida:</span
                  >
                  <v-text-field
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="
                      !camposExibidos[index].exDataProvavelPartoPrimTrim
                    "
                    type="date"
                    dense
                    class="flex-grow-0 mx-2"
                    validate-on-blur
                    v-model="laudo.dataProvavelParto1Trimestre"
                  ></v-text-field>
                  <span>.</span>

                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exDataProvavelPartoPrimTrim"
                    @click="
                      camposExibidos[index].exDataProvavelPartoPrimTrim = true;

                      laudo.dataProvavelParto1Trimestre = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exDataProvavelPartoPrimTrim"
                    @click="
                      camposExibidos[index].exDataProvavelPartoPrimTrim = false;

                      laudo.dataProvavelParto1Trimestre = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>

                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exGestacaoConclusao
                      ? 'text--disabled'
                      : ''
                  "
                >
                  <v-select
                    :items="[
                      
                      'Crescimento fetal adequado.',
                      'Crescimento fetal adequado, porém, com circunferência abdominal acima do percentil 75. Convém monitorar glicemia materna.',
                      'Crescimento fetal adequado, porém, com peso fetal próximo ao percentil 10. Convém monitorar crescimento fetal.',
                      'Peso fetal acima do percentil 90, compatível com macrossomia. Convém monitorar glicemia materna e ganho ponderal.',
                      'Peso fetal abaixo do percentil 5, compatível com CIUR, estágio '
                    ]"
                    required
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    :disabled="!camposExibidos[index].exGestacaoConclusao"
                    dense
                    class="flex-grow-0 mx-2"
                    validate-on-blur
                    v-model="laudo.gestacaoConclusao"
                  >
                  </v-select>
                  <v-text-field
                  v-if="laudo.gestacaoConclusao == 'Peso fetal abaixo do percentil 5, compatível com CIUR, estágio '"
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    :disabled="
                      !camposExibidos[index].exGestacaoConclusao
                    "
                    
                    dense
                    class="flex-grow-0 mx-2"
                    validate-on-blur
                    v-model="laudo.conclusaoObs"
                  ></v-text-field>
                  <span
                    v-if="
                      laudo.gestacaoConclusao ==
                      'Observamos discordância entre a idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual'
                    "
                    >, sugerindo</span
                  >

                  <span
                    v-if="
                      laudo.gestacaoConclusao ==
                      'Observamos discordância entre a idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual'
                    "
                  >
                    <v-select
                      :items="['restrição de crescimento fetal', 'macrossomia']"
                      required
                      :readonly="carregamentoDoLaudo || !podeEditar"
                      :clearable="podeEditar"
                      :disabled="!camposExibidos[index].exGestacaoConclusao"
                      dense
                      class="flex-grow-0 mx-2"
                      validate-on-blur
                      v-model="laudo.gestacaoConclusao2"
                    >
                    </v-select>
                  </span>
                  <span
                    v-if="
                      laudo.gestacaoConclusao ==
                      'Observamos discordância entre a idade gestacional baseada na ultrassonografia do 1º trimestre e na biometria fetal atual'
                    "
                  ></span>
                  <span>.</span>
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exGestacaoConclusao"
                    @click="
                      camposExibidos[index].exGestacaoConclusao = true;

                      laudo.gestacaoConclusao = undefined;
                      laudo.gestacaoConclusao2 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exGestacaoConclusao"
                    @click="
                      camposExibidos[index].exGestacaoConclusao = false;

                      laudo.gestacaoConclusao = undefined;
                      laudo.gestacaoConclusao2 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                  :class="
                    !camposExibidos[index].exGestacaoConclusao3
                      ? 'text--disabled'
                      : ''
                  "
                >
                  <v-select
                    :items="[
                      'Exame limitado pela posição fetal',
                      'Exame limitado pela quantidade de líquido amniótico',
                      'Exame limitado pela atenuação acústica',
                      'Exame limitado pela idade gestacional avançada',
                     
                    ]"
                    required
                    dense
                    multiple
                    class="flex-grow-0 mx-2"
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                    :disabled="!camposExibidos[index].exGestacaoConclusao3"
                    validate-on-blur
                    v-model="laudo.gestacaoConclusao3"
                  >
                  </v-select>
                  <span>.</span>
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="!camposExibidos[index].exGestacaoConclusao3"
                    @click="
                      camposExibidos[index].exGestacaoConclusao3 = true;

                      laudo.gestacaoConclusao3 = undefined;
                    "
                    ><v-icon>mdi-eye-off</v-icon></v-btn
                  >
                  <v-btn
                    dense
                    depressed
                    text
                    icon
                    v-if="camposExibidos[index].exGestacaoConclusao3"
                    @click="
                      camposExibidos[index].exGestacaoConclusao3 = false;

                      laudo.gestacaoConclusao3 = undefined;
                    "
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-start align-center"
                 
                >
                  <v-select
                    :items="[
                      
                      'Sugiro nova avaliação em uma semana',
                      'Sugiro nova avaliação em duas semanas',
                      
                    ]"
                    required
                    dense
                    class="flex-grow-0 mx-2"
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    :clearable="podeEditar"
                   
                    validate-on-blur
                    v-model="laudo.gestacaoConclusao4"
                  >
                  </v-select>
                  <span>.</span>
                  
                </v-col>

                <v-col cols="12" class="d-flex justify-start align-center pt-0">
                  <v-textarea
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    required
                    validate-on-blur
                    filled
                    outlined
                    no-resize
                    dense
                    label="Comentários adicionais:"
                    v-model="laudo.comentariosAdcionaisFinal2"
                  >
                  </v-textarea>
                </v-col>
              </div>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h4>Perfil cervical</h4>
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeColoUterino ? '' : 'text--disabled'
                }`"
              >
                <span>Colo uterino</span>
                <v-select
                  :clearable="podeEditar"
                  required
                  :items="['fechado', 'entreaberto', 'aberto']"
                  validate-on-blur
                  v-model="laudo.coloUterino"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeColoUterino"
                ></v-select>
                <span>, com menor comprimento de  </span>
                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeColoUterino"
                  v-mask="''"
                  validate-on-blur
                  v-model="laudo.coloMedidaEmRepouco"
                >
                </v-text-field>
                <span>mm (colo avaliado por três minutos).</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeColoUterino"
                  @click="
                    camposExibidos[index].exibeColoUterino = true;
                    laudo.coloUterino = undefined;
                    laudo.coloMedidaEmRepouco = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeColoUterino = false;
                    laudo.coloUterino = undefined;
                    laudo.coloMedidaEmRepouco = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <!-- <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeColoPassouAMedir
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Após três minutos de avaliação, o colo</span>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  :clearable="podeEditar"
                  :items="['passou a medir', 'continuou a medir']"
                  validate-on-blur
                  v-model="laudo.coloPassouAMedir"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeColoPassouAMedir"
                ></v-select>

                <v-text-field
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  v-mask="''"
                  v-model="laudo.cmdois"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeColoPassouAMedir"
                ></v-text-field>
                <span>cm.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeColoPassouAMedir"
                  @click="
                    camposExibidos[index].exibeColoPassouAMedir = true;
                    laudo.coloPassouAMedir = undefined;
                    laudo.cmdois = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeColoPassouAMedir = false;
                    laudo.coloPassouAMedir = undefined;
                    laudo.cmdois = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col> -->

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeOrificioCervicalInterno
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Orifício cervical interno</span>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :items="['fechado', 'aberto']"
                  required
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.orificioCervicalInterno"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="
                    !camposExibidos[index].exibeOrificioCervicalInterno
                  "
                ></v-select>

                <span v-if="laudo.orificioCervicalInterno == 'aberto'">
                  com
                </span>

                <v-text-field
                  v-if="laudo.orificioCervicalInterno == 'aberto'"
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  v-mask="''"
                  v-model="laudo.orificioCervicalInterno2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="
                    !camposExibidos[index].exibeOrificioCervicalInterno
                  "
                ></v-text-field>
                <span v-if="laudo.orificioCervicalInterno == 'aberto'">
                  mm
                </span>
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeOrificioCervicalInterno"
                  @click="
                    camposExibidos[index].exibeOrificioCervicalInterno = true;
                    laudo.orificioCervicalInterno = undefined;
                    laudo.orificioCervicalInterno2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeOrificioCervicalInterno = false;
                    laudo.orificioCervicalInterno = undefined;
                    laudo.orificioCervicalInterno2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeProtusaoBolsaAmniotica
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :items="['Ausência', 'Presença']"
                  :clearable="podeEditar"
                  required
                  validate-on-blur
                  v-model="laudo.protusaoBolsaAmniotica"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeProtusaoBolsaAmniotica"
                ></v-select>
                <span
                  >de protrusão da bolsa amniótica no momento do exame.</span
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeProtusaoBolsaAmniotica"
                  @click="
                    camposExibidos[index].exibeProtusaoBolsaAmniotica = true;
                    laudo.protusaoBolsaAmniotica = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeProtusaoBolsaAmniotica = false;
                    laudo.protusaoBolsaAmniotica = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeSinalDeSludge
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Sinal de sludge</span>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :items="['presente', 'ausente']"
                  required
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.sinalDeSludge"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeSinalDeSludge"
                ></v-select>
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeSinalDeSludge"
                  @click="
                    camposExibidos[index].exibeSinalDeSludge = true;
                    laudo.sinalDeSludge = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeSinalDeSludge = false;
                    laudo.sinalDeSludge = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibePolipoEndocervicalMedindo
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  :items="[
                    'Observamos pólipo endocervical medindo',
                    '-------------------------------------',
                  ]"
                  required
                  :clearable="podeEditar"
                  validate-on-blur
                  v-model="laudo.polipoEndocervicalMedindo"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="
                    !camposExibidos[index].exibePolipoEndocervicalMedindo
                  "
                ></v-select>

                <v-text-field
                  v-if="
                    laudo.polipoEndocervicalMedindo ==
                    'Observamos pólipo endocervical medindo'
                  "
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  v-mask="''"
                  v-model="laudo.polipoEndocervicalMedindoCm"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="
                    !camposExibidos[index].exibePolipoEndocervicalMedindo
                  "
                ></v-text-field>
                <span
                  v-if="
                    laudo.polipoEndocervicalMedindo ==
                    'Observamos pólipo endocervical medindo'
                  "
                >
                  mm
                </span>
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibePolipoEndocervicalMedindo"
                  @click="
                    camposExibidos[index].exibePolipoEndocervicalMedindo = true;
                    laudo.polipoEndocervicalMedindo = undefined;
                    laudo.polipoEndocervicalMedindoCm = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[
                      index
                    ].exibePolipoEndocervicalMedindo = false;
                    laudo.polipoEndocervicalMedindo = undefined;
                    laudo.polipoEndocervicalMedindoCm = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <h3>Conclusão</h3>
              </v-col>

              <v-col
                cols="12"
                :class="`d-flex justify-start align-center ${
                  camposExibidos[index].exibeColoUterino2
                    ? ''
                    : 'text--disabled'
                }`"
              >
                <span>Colo uterino</span>
                <v-select
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  :clearable="podeEditar"
                  :items="[
                    'de aspecto ecográfico habitual para a idade gestacional',
                    'entreaberto, porém, com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
                    'entreaberto, com menos de 25 mm de comprimento',
                    'fechado, com menos de 25 mm de comprimento',
                  ]"
                  validate-on-blur
                  v-model="laudo.coloUterino2"
                  class="mx-2 flex-grow-0"
                  dense
                  :disabled="!camposExibidos[index].exibeColoUterino2"
                ></v-select>
                <span>.</span>
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-if="!camposExibidos[index].exibeColoUterino2"
                  @click="
                    camposExibidos[index].exibeColoUterino2 = true;
                    laudo.coloUterino2 = undefined;
                  "
                  ><v-icon>mdi-eye-off</v-icon></v-btn
                >
                <v-btn
                  dense
                  depressed
                  text
                  icon
                  v-else
                  @click="
                    camposExibidos[index].exibeColoUterino2 = false;
                    laudo.coloUterino2 = undefined;
                  "
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
              </v-col>

              <v-col cols="12" class="d-flex justify-start align-center">
                <v-textarea
                  :readonly="carregamentoDoLaudo || !podeEditar"
                  required
                  validate-on-blur
                  filled
                  outlined
                  no-resize
                  label="Comentários adicionais"
                  v-model="laudo.comentarioAdcionalPerfilCervical"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>

      <component
        :is="modal.modalComponent"
        v-bind="{ show: modal.active }"
        @close="closeModal"
      />
    </template>

    <v-col cols="12" class="d-flex justify-start align-center pt-4">
      <v-btn
        v-if="podeEditar"
        color="primary"
        :loading="carregamentoDoLaudo"
        @click="
          () => {
            laudoId ? editarLaudo() : salvarLaudo();
          }
        "
      >
        salvar laudo
      </v-btn>

      <v-btn
        color="primary"
        v-if="temLaudo && !podeEditar"
        @click="imprimirLaudo"
        :loading="loadingImprimir"
        class="ml-5"
      >
        Imprimir
      </v-btn>

      <v-btn
        color="primary"
        v-if="!podeEditar"
        class="ml-5"
        @click="podeEditar = true"
      >
        Editar
      </v-btn>

      <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        v-if="temLaudo"
        @click="adcionarConteudoEditorPersonalizado"
        class="ml-5"
      >
        {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
      </v-btn>
    </v-col>
  </section>
</template>

<script>
import moment from 'moment';
import { VueEditor } from 'vue2-editor';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';
import TNCalcs from '@/helpers/calculosTn';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    VueEditor,
    barraDeBuscaExames,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      carregamentoDoLaudo: false,
      loadingImprimir: false,
      clicouNoPersonalizado: false,
      indicacao: ["Rotina pré-natal",'Colo curto','Diabetes gestacional','Hipertensão', 'Controle de crescimento fetal',
      'Avaliação de bem estar fetal','Polidrâmnio','Oligodrâmnio','Outros'],

      conteudoEditorPersonalizado: [],
      quantidadeFolhasEditorPersonalizado: 0,
      laudoId: null,
      podeEditar: true,
      voltando: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,
      gestacaoText: [
        '',
        'única',
        'gemelar',
        'trigemelar',
        'quadrigemelar',
        'quíntuplo',
      ],
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      camposExibidos: [
        {
          exInsercao2: true,
          exIndCef: true,
          exibeIndicacaoExame: true,
          exPlacenta2: true,
          exibeIndicePulsatilidadeArteriaUmbilical: true,
          exibeDiastole: true,
          exibeIndicePulsatilidadeRelacaoCerebroPla: true,
          exibeVelocidadePicoSistolico: true,
          exibeIndicePulsatilidadeArteriaUterinaD: true,
          exibeDuctoVenoso: true,
          exGest: true,
          exIdadeGestDiascorrigida2: true,
          exibeApresentacao: true,
          exPosFetal: true,
          exDiamBiparietal: true,
          exIdadeGestcorrigida2: true,
          exCircCranio: true,
          exCircAbdomen: true,
          exComprimentoFemoral: true,
          exPesoFetal: true,
          exBatimentoFeto: true,
          exMovCorporais: true,
          exTonus: true,
          exVolLiquidoAmni: true,
          exPlacenta: true,
          exAusentes: true,
          exIdadeGest: true,
          exIdadeGest2: true,
          exDataProvavelParto: true,
          exDataProvavelPartoPrimTrim: true,
          exGestacaoConclusao: true,
          exGestacaoConclusao3: true,
          exPercentil1: true,
          exibeSituacao: true,
          exibeSituacao2: true,
          exPercentil2: true,
          exPercentil3: true,
          exibeGestacao: true,
          exPercentil4: true,
          exibeIndicePulsatilidadeArteriaCerebralMedia: true,
          exibeIndicePulsatilidadeArteriaUterinaE: true,
          exibeFluxoUmbilicalOpcao1: true,
          exibeCentralizacaoFetal: true,
          exibeRelacaoCerebroPlacentaria: true,
          exibeDuctoVenosoIndice: true,
          exibeArteriasUterinas: true,
          exibeVelocidadeAcm: true,
          exPercentil5: true,
          exibirBotaoEditar: false,
          exibirLaudo: true,
          exibeColoUterino: true,
          exibeColoPassouAMedir: true,
          exibeOrificioCervicalInterno: true,
          exibePolipoEndocervicalMedindo: true,
          exibeColoUterino2: true,
          exibeProtusaoBolsaAmniotica: true,
          exibeSinalDeSludge: true,
          exGrannum: true,
        exibeccca: true,
          exibecfcc: true,
          exibecfca: true,
          exDOF: true,
        },
      ],

      items: ['feto 1', 'feto 2'],

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,
      tipoGemeo: [
        {
          text: 'única',
          value: 1,
        },
        {
          text: 'gemelar',
          value: 2,
        },
        {
          text: 'trigemelar',
          value: 3,
        },
        {
          text: 'quadrigemelar',
          value: 4,
        },
        {
          text: 'quíntuplo',
          value: 5,
        },
      ],
    };
  },

  watch: {
    'infosCompartilhadas.gestacao'(value) {
      const quantidadeDeLaudos = this.laudos.length;
      if (value === quantidadeDeLaudos) return;

      const diff = value - quantidadeDeLaudos;

      const novaQuantidade = [...Array(diff > 0 ? diff : diff * -1).keys()];

      if (diff > 0) novaQuantidade.forEach(() => this.laudos.push({}));
      else novaQuantidade.forEach(() => this.laudos.pop());
    },
    'infosCompartilhadas.dataUltimaMestrucao'(dataUltimaMestrucao) {
      this.$store.commit('Laudo/SET_INFO_COMPARTILHADAS', {
        semanasDUM:
          TNCalcs.idadeGestacionalMenstrualSemanas(dataUltimaMestrucao),
        diasDUM: TNCalcs.idadeGestacionalMenstrualDias(dataUltimaMestrucao),
      });
    },
  },

  computed: {
    temLaudo() {
      this.laudoId = this.$store.getters['Laudo/laudoId'];
      return !!this.$store.getters['Laudo/laudoId'];
    },

    infosCompartilhadas() {
      return this.$store.state.Laudo.infosCompartilhadas;
    },
    laudos() {
      return this.$store.state.Laudo.laudos;
    },
    loading() {
      return this.$store.getters['Laudo/loading'];
    },
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    calculaImc(){
      
      
      if(!this.laudos[0].altura || !this.laudos[0].peso) {
        return}
      let altura = this.laudos[0].altura
      let peso = this.laudos[0].peso
      altura = altura / 100; // converter para metros

    // Calculando o IMC
      const imc = peso / (altura * altura);
      this.laudos[0].imc = imc.toFixed(2)
      
      this.$forceUpdate()

    },
    calcularIdadeEMeses() {
    const dataNascimento = moment(this.laudos[0].dataNascimento);
    const hoje = moment();
    

    const anos = hoje.diff(dataNascimento, 'years');
    dataNascimento.add(anos, 'years');
    const meses = hoje.diff(dataNascimento, 'months');
    
      this.laudos[0].anos = anos
      this.laudos[0].meses = meses
      
      this.$forceUpdate()
    },
    removeFolha(value) {
      let indice = value - 1;

      if (this.laudos[0].folhas.length != 1) {
        this.conteudoEditorPersonalizado.splice(indice, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.$forceUpdate();
    },

    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudos[0].folhas) {
        this.conteudoEditorPersonalizado = this.laudos[0].folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }
      let todos = [];
      let todosPC = [];
      for (let i = 0; i < this.laudos.length; i++) {
        todos.push(`<h4>História materna</h4>`);
      if (this.laudos[i].dataNascimento)
        todos.push(`Data de nascimento : ${this.dataFormatada(this.laudos[i].dataNascimento)}.\n`);
        if (this.laudos[i].anos || this.laudos[i].meses)
        todos.push(`${this.laudos[i].anos?this.laudos[i].anos + (this.laudos[i].anos > 1 ? ' anos':' ano'):''}${this.laudos[i].anos && this.laudos[i].meses? ' e ':''}${this.laudos[i].meses?this.laudos[i].meses + (this.laudos[i].meses > 1 ? ' meses':' mês'):''}.\n`);
        if (this.laudos[i].peso)
        todos.push(`${this.laudos[i].peso?"Peso: " + this.laudos[i].peso + ' kg.':""}${
      this.laudos[i].altura?" Altura: " + this.laudos[i].altura + ' cm.':""}${
      this.laudos[i].imc?" IMC: " + this.laudos[i].imc :""}.\n`);

      if (this.laudos[i].metodoConcepcao)
        todos.push(`Método de concepção : ${this.laudos[i].metodoConcepcao}.\n`);
        if (this.laudos[i].fuma)
        todos.push(`Tabagismo durante a gravidez: ${this.laudos[i].fuma}.\n`);

        if (this.laudos[i].hipertensao)
        todos.push(`Hipertensão: ${this.laudos[i].hipertensao}.\n`);
        if (this.laudos[i].diabetes)
        todos.push(`Diabetes : ${this.laudos[i].diabetes}${
      this.laudos[i].diabetes == 'Sim'? ', '+ this.laudos[i].tipoDiabetes :''}.\n`);
      
      if (this.laudos[i].intecorrencia)
        todos.push(`Outras intercorrências: ${this.laudos[i].intecorrencia}.\n`);
      if (this.laudos[i].medicacao)
        todos.push(`Uso de medicações: ${this.laudos[i].medicacao}${
      this.laudos[i].medicacao == 'Sim'? ', '+ (this.laudos[i].usoMedicacao == 'outros'?
      this.laudos[i].outroUsoMedicacao
    :this.laudos[i].usoMedicacao
    ) :''}.\n`);

        if (this.laudos[i].indicacaoExame) {
          todos.push(`Indicação do exame: ${this.laudos[i].indicacaoExame == 'Outros'? this.laudos[i].indicacaoExameOutros : this.laudos[i].indicacaoExame}.\n`);
        }
        if (this.laudos[i].gestacao == 1) {
          todos.push(`Gestação única.\n`);
        }
        if (this.laudos[i].gestacao == 2) {
          todos.push(`Gestação gemelar.\n`);
        }
        if (this.laudos[i].gestacao == 3) {
          todos.push(`Gestação trigemelar.\n`);
        }
        if (this.laudos[i].gestacao == 4) {
          todos.push(`Gestação quadrigemelar.\n`);
        }
        if (this.laudos[i].gestacao == 5) {
          todos.push(`Gestação quíntuplo.\n`);
        }
        if (this.laudos[i].situacao) {
          todos.push(`\n<strong>Posição fetal</strong>`);
        }
        if (this.laudos[i].situacao) {
          let resultadoSituacao = `Situação ${this.laudos[i].situacao}`;
          if (this.laudos[i].apresentacao)
            resultadoSituacao += `, apresentação ${this.laudos[i].apresentacao}`;
          if (this.laudos[i].dorso)
            resultadoSituacao += `, com dorso ${this.laudos[i].dorso}${
              this.laudos[i].dorso2 ? `, ${this.laudos[i].dorso2}` : ''
            }`;
          resultadoSituacao += '.\n';
          todos.push(resultadoSituacao);
        }
        if (this.laudos[i].situacao) {
          todos.push(`\n<strong>Biometria fetal</strong>`);
        }
        
        if (this.laudos[i].diametroBiparietal) {
          todos.push(
            `DBP : ${
              this.laudos[i].diametroBiparietal
            } mm  ${
              this.laudos[i].percentil1
                ? '(percentil ' + this.laudos[i].percentil1 + ')'
                : ''
            }.\n`
          );
        }
        if (this.laudos[i].dof) {
          todos.push(
            `DOF : ${
              this.laudos[i].dof
            } mm.\n`
          );
        }
        if (this.laudos[i].circunferenciaCraniana) {
          todos.push(
            `CC : ${
              this.laudos[i].circunferenciaCraniana
            } mm  ${
              this.laudos[i].percentil2
                ? '(percentil ' + this.laudos[i].percentil2 + ')'
                : ''
            }.\n`
          );
        }
        if (this.laudos[i].circunferenciaAbdominal) {
          todos.push(
            `CA : ${
              this.laudos[i].circunferenciaAbdominal
            } mm  ${
              this.laudos[i].percentil3
                ? '(percentil ' + this.laudos[i].percentil3 + ')'
                : ''
            }.\n`
          );
        }
        if (this.laudos[i].comprimentoFemoral) {
          todos.push(
            `CF : ${this.laudos[i].comprimentoFemoral} mm  ${
              this.laudos[i].percentil4
                ? '(percentil ' + this.laudos[i].percentil4 + ')'
                : ''
            }.\n`
          );
        }
        if (this.laudos[i].pesoFetal) {
          let resultadoPesoFetal = `Peso fetal estimado de ${this.laudos[i].pesoFetal} gramas`;
          if (this.laudos[i].percentil5)
            resultadoPesoFetal += `, correspondendo ao percentil ${this.laudos[i].percentil5}`;
          resultadoPesoFetal += '.\n';
          todos.push(resultadoPesoFetal);
        }
       
        if (this.laudos[i].indiceCefalico) {
          todos.push(
            `Índice cefálico (DBP/DOF): ${this.laudos[i].indiceCefalico}.\n`
          );
        }
        todos.push(`\n<strong>Vitalidade fetal</strong>`);
       
        if (this.laudos[i].batimentosCardiacosFetais) {
          let resultadoBatimentosCardiacos = `Batimentos cardíacos fetais ${this.laudos[i].batimentosCardiacosFetais}`;
          if (this.laudos[i].batimentosCardiacosFetais === 'presentes') {
            if (this.laudos[i].batimentosCardiacosFetais2)
              resultadoBatimentosCardiacos += `, ${this.laudos[i].batimentosCardiacosFetais2}`;

            if (
              this.laudos[i].batimentosCardiacosFetais2 ===
                'com ritmo cardíaco irregular' &&
              this.laudos[i].caracterizacaoArritimia
            )
              resultadoBatimentosCardiacos += `, ${this.laudos[i].caracterizacaoArritimia}`;
            if (this.laudos[i].frequenciabpm)
              resultadoBatimentosCardiacos += `, com frequência cardíaca de ${this.laudos[i].frequenciabpm} bpm`;
          }
          resultadoBatimentosCardiacos += '.\n';
          todos.push(resultadoBatimentosCardiacos);
        }
        if (this.laudos[i].movimentosCorporais) {
          todos.push(
            `Movimentos corporais ${this.laudos[i].movimentosCorporais}.\n`
          );
        }
        if (this.laudos[i].tonus) {
          todos.push(`Tônus ${this.laudos[i].tonus}.\n`);
        }
        if (this.laudos[i].volumeLíquidoAmniotico) {
          todos.push(
            `Volume de líquido amniótico ${
              this.laudos[i].volumeLíquidoAmniotico
            }${
              this.laudos[i].percentil6
                ? ' com maior bolsão medindo ' +
                  this.laudos[i].percentil6 +
                  ' cm'
                : ''
            }.\n`
          );
        }
        if (this.laudos[i].placenta) {
          let resultadoPlacenta = `Placenta ${this.laudos[i].placenta}`;
          if (this.laudos[i].insercao)
            resultadoPlacenta += `, de inserção ${this.laudos[i].insercao}`;
          if (this.laudos[i].grau)
            resultadoPlacenta += `, grau ${this.laudos[i].grau} segundo a classificação de Grannum`;
          resultadoPlacenta += '.\n';
          todos.push(resultadoPlacenta);
        }
        if (this.laudos[i].comentariosAdcionaisMeio1) {
          todos.push(`${this.laudos[i].comentariosAdcionaisMeio1}.\n`);
        }

        todos.push(`\n<h4>Conclusão</h4>`);
        todos.push(`\n`);
        if (this.laudos[i].batimentosCardiacosFetais == 'ausentes') {
          todos.push(`Óbito fetal.\n`);
          todos.push(
            `Idade gestacional de ${this.laudos[i].idadeGestacional} semanas.\n`
          );
          todos.push(
            ` ${
              this.laudos[i].comentariosAdcionaisFinal1
                ? this.laudos[i].comentariosAdcionaisFinal1
                : ''
            }\n`
          );
        }
        if (this.laudos[i].batimentosCardiacosFetais == 'presentes') {
          
          todos.push(
            `Gestação tópica ${
              this.gestacaoText[this.infosCompartilhadas.gestacao]
            }${
              this.infosCompartilhadas.gestacao == 2
                ? this.laudos[i].gestacaoTres +
                  ' ' +
                  this.laudos[i].gestacaoQuatro
                : ''
            }${
              this.infosCompartilhadas.gestacao > 2
                ? this.laudos[i].gestacaoCinco
                : ''
            }, feto ${this.laudos[i].estadoFeto}.\n`
          );
          todos.push(
            `Idade gestacional corrigida ${
              this.laudos[i].idadeGestacionalCorrigida2
                ? this.laudos[i].idadeGestacionalCorrigida2 > 1
                  ? this.laudos[i].idadeGestacionalCorrigida2 + ' semanas '
                  : this.laudos[i].idadeGestacionalCorrigida2 + ' semana '
                : ''
            }${
              this.laudos[i].semanasCorrigida11
                ? this.laudos[i].semanasCorrigida11 > 1
                  ? this.laudos[i].semanasCorrigida11 + ' dias'
                  : this.laudos[i].semanasCorrigida11 + ' dia'
                : ''
            }.\n`
          );
          todos.push(
            `Biometria fetal atual compatível com ${
              this.laudos[i].idadeGestacional2
                ? this.laudos[i].idadeGestacional2 > 1
                  ? this.laudos[i].idadeGestacional2 + ' semanas '
                  : this.laudos[i].idadeGestacional2 + ' semana '
                : ''
            }${
              this.laudos[i].semanas11
                ? this.laudos[i].semanas11 > 1
                  ? this.laudos[i].semanas11 + ' dias'
                  : this.laudos[i].semanas11 + ' dia'
                : ''
            }.\n`
          );
          
          if (this.laudos[i].dataProvavelParto)
            todos.push(
              `Data provável do parto pela biometria fetal atual (40 semanas): ${this.dataFormatada(
                this.laudos[i].dataProvavelParto
              )}\n`
            );
          if (this.laudos[i].dataProvavelParto1Trimestre)
            todos.push(
              `Data provável do parto por ultrassonografia corrigida: ${this.dataFormatada(
                this.laudos[i].dataProvavelParto1Trimestre
              )}.\n`
            );
          if (this.laudos[i].gestacaoConclusao)
            todos.push(
              `${this.laudos[i].gestacaoConclusao}${
                this.laudos[i].gestacaoConclusao ==
                'Peso fetal abaixo do percentil 5, compatível com CIUR, estágio '
                  ? this.laudos[i].conclusaoObs
                  : ''
              }\n`
            );

          if (this.laudos[i].gestacaoConclusao3) {
            todos.push(`${typeof this.laudos[i].gestacaoConclusao3 =='string'? this.laudos[i].gestacaoConclusao3: this.laudos[i].gestacaoConclusao3.join('. ')}.\n`);
          }
          if (this.laudos[i].gestacaoConclusao4) {
            todos.push(`${this.laudos[i].gestacaoConclusao4}.\n`);
          }

          if (this.laudos[i].comentariosAdcionaisFinal2)
            todos.push(`${this.laudos[i].comentariosAdcionaisFinal2}\n`);
        }

        // if (this.laudos[i].coloUterino) {
        //   todos.push(
        //     `Colo uterino ${this.laudos[i].coloUterino}, medindo ${this.laudos[i].coloMedidaEmRepouco} cm em repouso.\n`
        //   );
        // }

        
        todosPC.push(`<strong>PERFIL CERVICAL</strong>`);
        todosPC.push(`\n`);
        

        if (this.laudos[i].coloUterino) {
          todosPC.push(
            `Colo uterino ${this.laudos[i].coloUterino}${
              this.laudos[i].coloMedidaEmRepouco
                ? ', com menor comprimento de ' +
                  this.laudos[i].coloMedidaEmRepouco +
                  ' mm (colo avaliado por três minutos)'
                : ''
            } .\n`
          );
        }

        // if (this.laudos[i].coloPassouAMedir) {
        //   todosPC.push(
        //     `Após três minutos de avaliação, o colo ${
        //       this.laudos[i].coloPassouAMedir
        //     } ${this.laudos[i].cmdois ? this.laudos[i].cmdois + ' cm' : ''} .\n`
        //   );
        // }

        // if (this.laudos[i].orificioCervicalInterno) {
        //   todosPC.push(
        //     `Orifício cervical interno ${
        //       this.laudos[i].orificioCervicalInterno
        //     }${this.laudos[i].ductoVenoso === 'aberto' ? ', ' : ''}${
        //       this.laudos[i].ductoVenoso === 'aberto'
        //         ? '' + this.laudos[i].orificioCervicalInterno2
        //         : ' com ' + this.laudos[i].orificioCervicalInterno2 + ' cm'
        //     }.\n`
        //   );
        // }

        if (this.laudos[i].orificioCervicalInterno) {
          todosPC.push(
            `Orifício cervical interno ${
              this.laudos[i].orificioCervicalInterno
            }${
              this.laudos[i].orificioCervicalInterno === 'aberto'
                ? ` com ${this.laudos[i].orificioCervicalInterno2} mm`
                : ''
            }.\n`
          );
        }

        if (this.laudos[i].protusaoBolsaAmniotica) {
          todosPC.push(
            `${this.laudos[i].protusaoBolsaAmniotica} de protrusão da bolsa amniótica no momento do exame.\n`
          );
        }
        if (this.laudos[i].sinalDeSludge) {
          todosPC.push(`Sinal de sludge ${this.laudos[i].sinalDeSludge}.\n`);
        }

        // if (this.laudos[i].polipoEndocervicalMedindo) {
        //   todosPC.push(
        //     `${this.laudos[i].polipoEndocervicalMedindo}${
        //       this.laudos[i].polipoEndocervicalMedindo ===
        //       'Observamos pólipo endocervical medindo'
        //         ? ', '
        //         : ''
        //     }${
        //       this.laudos[i].polipoEndocervicalMedindo ===
        //       'Observamos pólipo endocervical medindo'
        //         ? '' + this.laudos[i].polipoEndocervicalMedindoCm
        //         : ''
        //     }${
        //       this.laudos[i].polipoEndocervicalMedindo ===
        //       'Observamos pólipo endocervical medindo'
        //         ? ' mm' + ''
        //         : ''
        //     }.\n`
        //   );
        // }

        if (this.laudos[i].polipoEndocervicalMedindo) {
          todosPC.push(
            `${this.laudos[i].polipoEndocervicalMedindo}${
              this.laudos[i].polipoEndocervicalMedindo ===
              'Observamos pólipo endocervical medindo'
                ? ` ${this.laudos[i].polipoEndocervicalMedindoCm} mm`
                : ''
            }.\n`
          );
        }

        todosPC.push(`\n<h4>Conclusão</h4>\n`);

        if (this.laudos[i].coloUterino2) {
          todosPC.push(`Colo uterino ${this.laudos[i].coloUterino2}.\n`);
        }
        if (this.laudos[i].comentarioAdcionalPerfilCervical) {
          todosPC.push(`${this.laudos[i].comentarioAdcionalPerfilCervical}.\n`);
        }

       
      }

      this.conteudoEditorPersonalizado = [];

      while (todos.length > 0) {
        const teste = todos.splice(0, 36);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }
      while (todosPC.length > 0) {
        const teste = todosPC.splice(0, 30);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },
    juntaPersonalizadoAoLaudo() {
      this.laudos[0].folhas = this.conteudoEditorPersonalizado;
      this.laudos[0].titulo = 'ULTRASSONOGRAFIA OBSTÉTRICA COM PERFIL CERVICAL';
    },
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
    mediaIndice(i) {
      if (
        !this.laudos ||
        !this.laudos[i] ||
        !this.laudos[i].indicePulsatilidadeArteriaUterinaD ||
        !this.laudos[i].indicePulsatilidadeArteriaUterinaE
      )
        return '';
      return (
        (parseFloat(this.laudos[i].indicePulsatilidadeArteriaUterinaD) +
          parseFloat(this.laudos[i].indicePulsatilidadeArteriaUterinaE)) /
        2.0
      ).toFixed(2);
    },

    forceReRender() {
      this.componentKey++;
    },
    ...mapActions('Laudo', ['getLaudos']),
    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            this.$store.commit('Laudo/SET_LOADING', true);
            if (!this.temLaudo) {
              this.$store.commit(
                'Laudo/SET_CAMPOS_EXIBIDOS',
                this.camposExibidos
              );
              await this.$store.dispatch(
                'Laudo/postLaudos',
                this.$route.query.exame
              );
            }
            await this.$store.dispatch('Consulta/patchConsulta', {
              consultaID: this.$route.query.consulta,
              path: 'status',
              value: 'LAUDADO',
            });
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: async (store, exame, consulta) => {
                await store.dispatch('Laudo/postLaudos', exame);
                await store.dispatch('Consulta/patchConsulta', {
                  consultaID: consulta,
                  path: 'status',
                  value: 'LAUDADO',
                });
              },
              exame: this.$route.query.exame,
              consulta: this.$route.query.consulta,
              codigo: 'POST_LAUDO_TN',
            });
            this.$store.commit('offline/SET_DADOS', this.laudos);
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.$store.commit('Laudo/SET_LOADING', false);
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    getPercentilFemur(valIndice) {
      //verificar se o valor tá entre 20 e 42
      let x;
      let semanas;
      if (!!this.laudos[valIndice].comprimentoFemoral) {
        x = this.laudos[valIndice].comprimentoFemoral;
        x = x / 10;
      } else {
        return;
      }
      if (!!this.laudos[valIndice].idadeGestacional2) {
        semanas = parseInt(this.laudos[valIndice].idadeGestacional2);
      } else {
        return;
      }
      if (semanas >= 12 && semanas <= 42) {
        let valores = this.getTabelaFemur(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudos[valIndice].percentil4 = resultado;
      } else {
        this.laudos[valIndice].percentil4 = '';
      }

      this.$forceUpdate();
    },
    getTabelaFemur(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[12] = [0.5, 1.1];
      tabelaIndices[13] = [0.8, 1.4];
      tabelaIndices[14] = [1.1, 1.7];
      tabelaIndices[15] = [1.4, 2.0];
      tabelaIndices[16] = [1.7, 2.4];
      tabelaIndices[17] = [2.0, 2.7];
      tabelaIndices[18] = [2.3, 3.0];
      tabelaIndices[19] = [2.6, 3.3];
      tabelaIndices[20] = [2.8, 3.6];
      tabelaIndices[21] = [3.1, 3.9];
      tabelaIndices[22] = [3.4, 4.1];
      tabelaIndices[23] = [3.6, 4.4];
      tabelaIndices[24] = [3.9, 4.7];
      tabelaIndices[25] = [4.1, 5.0];
      tabelaIndices[26] = [4.4, 5.2];
      tabelaIndices[27] = [4.6, 5.5];
      tabelaIndices[28] = [4.8, 5.7];
      tabelaIndices[29] = [5.0, 6.0];
      tabelaIndices[30] = [5.2, 6.2];
      tabelaIndices[31] = [5.4, 6.4];
      tabelaIndices[32] = [5.6, 6.6];
      tabelaIndices[33] = [5.8, 6.8];
      tabelaIndices[34] = [6.0, 7.0];
      tabelaIndices[35] = [6.1, 7.2];
      tabelaIndices[36] = [6.3, 7.3];
      tabelaIndices[37] = [6.4, 7.5];
      tabelaIndices[38] = [6.6, 7.7];
      tabelaIndices[39] = [6.7, 7.8];
      tabelaIndices[40] = [6.8, 7.9];
      tabelaIndices[41] = [6.9, 8.0];
      tabelaIndices[42] = [7.0, 8.1];
      return tabelaIndices[valSemanas];
    },
    getIndicePulsatilidade(valIndice) {
      //verificar se o valor tá entre 20 e 42
      let x;
      let semanas;
      if (!!this.laudos[valIndice].indicePulsatilidadeArteriaUmbilical) {
        x = this.laudos[valIndice].indicePulsatilidadeArteriaUmbilical;
      } else {
        return;
      }
      if (!!this.laudos[valIndice].idadeGestacional2) {
        semanas = parseInt(this.laudos[valIndice].idadeGestacional2);
      } else {
        return;
      }

      if (semanas >= 20 && semanas <= 42) {
        let valores = this.getValoresIndice(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudos[valIndice].percentil18 = resultado;
      } else {
        this.laudos[valIndice].percentil18 = '';
      }

      this.$forceUpdate();
    },

    getPercentilCC(valIndice) {
      //verificar se o valor tá entre 20 e 42
      let x;
      let semanas;
      if (!!this.laudos[valIndice].circunferenciaCraniana) {
        x = this.laudos[valIndice].circunferenciaCraniana;
        x = x / 10;
      } else {
        return;
      }
      if (!!this.laudos[valIndice].idadeGestacional2) {
        semanas = parseInt(this.laudos[valIndice].idadeGestacional2);
      } else {
        return;
      }
      if (semanas >= 12 && semanas <= 40) {
        let valores = this.getTabelaCC(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          0.5 + ((97.5 - 0.5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudos[valIndice].percentil2 = resultado;
      } else {
        this.laudos[valIndice].percentil2 = '';
      }

      this.$forceUpdate();
    },
    getTabelaCC(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[13] = [5.6, 10.2];
      tabelaIndices[14] = [7.1, 11.7];
      tabelaIndices[15] = [8.4, 13.0];
      tabelaIndices[16] = [9.8, 14.4];
      tabelaIndices[17] = [11.2, 15.8];
      tabelaIndices[18] = [12.5, 17.1];
      tabelaIndices[19] = [13.8, 18.4];
      tabelaIndices[20] = [15.1, 19.7];
      tabelaIndices[21] = [16.3, 20.9];
      tabelaIndices[22] = [17.5, 22.1];
      tabelaIndices[23] = [18.7, 23.3];
      tabelaIndices[24] = [19.8, 24.4];
      tabelaIndices[25] = [20.9, 25.5];
      tabelaIndices[26] = [22.0, 26.6];
      tabelaIndices[27] = [23.0, 27.6];
      tabelaIndices[28] = [24.0, 28.6];
      tabelaIndices[29] = [24.9, 29.5];
      tabelaIndices[30] = [25.8, 30.4];
      tabelaIndices[31] = [26.7, 31.3];
      tabelaIndices[32] = [27.5, 32.1];
      tabelaIndices[33] = [28.2, 32.8];
      tabelaIndices[34] = [28.9, 33.5];
      tabelaIndices[35] = [29.6, 34.2];
      tabelaIndices[36] = [30.2, 34.8];
      tabelaIndices[37] = [30.7, 35.3];
      tabelaIndices[38] = [31.2, 35.8];
      tabelaIndices[39] = [31.6, 36.2];
      tabelaIndices[40] = [32.0, 36.6];
      return tabelaIndices[valSemanas];
    },
    getACM(valIndice) {
      //verificar se o valor tá entre 20 e 42
      let x;
      let semanas;
      if (!!this.laudos[valIndice].indicePulsatilidadeArteriaCerebralMedia) {
        x = this.laudos[valIndice].indicePulsatilidadeArteriaCerebralMedia;
      } else {
        return;
      }
      if (!!this.laudos[valIndice].idadeGestacional2) {
        semanas = parseInt(this.laudos[valIndice].idadeGestacional2);
      } else {
        return;
      }

      if (semanas >= 20 && semanas <= 42) {
        let valores = this.getValoresACM(semanas);
        let menorvalor = valores[0];
        let maiorvalor = valores[1];
        let resultado =
          5 + ((95 - 5) * (x - menorvalor)) / (maiorvalor - menorvalor);
        resultado = resultado.toFixed(0);
        resultado = resultado < 1 ? ' < 1' : resultado;
        resultado = resultado > 99 ? ' > 99' : resultado;
        this.laudos[valIndice].percentil34 = resultado;
      } else {
        this.laudos[valIndice].percentil34 = '';
      }

      this.$forceUpdate();
    },
    getValoresIndice(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[20] = [1.04, 2.03];
      tabelaIndices[21] = [0.98, 1.96];
      tabelaIndices[22] = [0.92, 1.9];
      tabelaIndices[23] = [0.86, 1.85];
      tabelaIndices[24] = [0.81, 1.79];
      tabelaIndices[25] = [0.76, 1.74];
      tabelaIndices[26] = [0.71, 1.69];
      tabelaIndices[27] = [0.67, 1.65];
      tabelaIndices[28] = [0.63, 1.61];
      tabelaIndices[29] = [0.59, 1.57];
      tabelaIndices[30] = [0.56, 1.54];
      tabelaIndices[31] = [0.53, 1.51];
      tabelaIndices[32] = [0.5, 1.48];
      tabelaIndices[33] = [0.48, 1.46];
      tabelaIndices[34] = [0.46, 1.44];
      tabelaIndices[35] = [0.44, 1.43];
      tabelaIndices[36] = [0.43, 1.42];
      tabelaIndices[37] = [0.42, 1.41];
      tabelaIndices[38] = [0.42, 1.4];
      tabelaIndices[39] = [0.42, 1.4];
      tabelaIndices[40] = [0.42, 1.4];
      tabelaIndices[41] = [0.42, 1.41];
      tabelaIndices[42] = [0.43, 1.42];
      return tabelaIndices[valSemanas];
    },
    getValoresACM(valSemanas) {
      let tabelaIndices = {};

      tabelaIndices[20] = [1.36, 2.31];
      tabelaIndices[21] = [1.4, 2.34];
      tabelaIndices[22] = [1.44, 2.37];
      tabelaIndices[23] = [1.47, 2.4];
      tabelaIndices[24] = [1.49, 2.42];
      tabelaIndices[25] = [1.51, 2.44];
      tabelaIndices[26] = [1.52, 2.45];
      tabelaIndices[27] = [1.53, 2.45];
      tabelaIndices[28] = [1.53, 2.46];
      tabelaIndices[29] = [1.53, 2.45];
      tabelaIndices[30] = [1.52, 2.44];
      tabelaIndices[31] = [1.51, 2.43];
      tabelaIndices[32] = [1.49, 2.41];
      tabelaIndices[33] = [1.46, 2.39];
      tabelaIndices[34] = [1.43, 2.36];
      tabelaIndices[35] = [1.4, 2.32];
      tabelaIndices[36] = [1.36, 2.28];
      tabelaIndices[37] = [1.32, 2.24];
      tabelaIndices[38] = [1.27, 2.19];
      tabelaIndices[39] = [1.21, 2.14];
      tabelaIndices[40] = [1.15, 2.08];
      tabelaIndices[41] = [1.08, 2.01];
      tabelaIndices[42] = [1.01, 1.94];
      return tabelaIndices[valSemanas];
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();
        this.carregamentoDoLaudo = false;

        return;
      } finally {
        try {
          this.carregamentoDoLaudo = false;
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            this.$store.commit(
              'Laudo/SET_CAMPOS_EXIBIDOS',
              this.camposExibidos
            );
            await this.$store.dispatch('Laudo/pathLaudos');
            this.patchLaudoTn(this.laudoId, this.laudos);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoTnOffline.bind({}),
              laudoId: this.laudoId,
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO_TN',
            });
            this.$store.commit('offline/SET_DADOS', this.laudos);
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      this.loadingImprimir = true;
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.obstetricaPerfilCervical',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },
    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },

    formataLaudo() {
      if (
        this.exibeTransdutor &&
        (!!this.infosCompartilhadas.transdutor ||
          this.infosCompartilhadas.transdutor == null)
      ) {
        this.infosCompartilhadas.transdutor = 'convexo';
      }
      if (
        this.exibeOssoNasal &&
        (this.laudos[0].ossoNasal || this.laudos[0].ossoNasal == null)
      ) {
        this.laudos[0].ossoNasal = 'presente';
      }
      if (
        this.exibeRisco &&
        (!!this.laudos[0].riscoTrissomia ||
          this.laudos[0].riscoTrissomia == null)
      ) {
        this.laudos[0].riscoTrissomia = 'diminuiu';
      }
      if (
        this.exibeRisco &&
        (!!this.laudos[0].taxaRisco || this.laudos[0].taxaRisco == null)
      ) {
        this.laudos[0].taxaRisco = 'baixo risco';
      }
      if (
        this.exibeDataMens &&
        (!!this.laudos[0].compativelIncompativel ||
          this.laudos[0].compativelIncompativel == null)
      ) {
        this.laudos[0].compativelIncompativel = 'compatível';
      }
      if (
        this.exibePlacenta &&
        (!!this.laudos[0].placenta || this.laudos[0].placenta == null)
      ) {
        this.laudos[0].placenta = 'próxima ao colo uterino';
      }
      if (this.exibeMediaIP) {
        this.laudos[0].MediaIp = parseFloat(
          (parseFloat(this.laudos[0].arteriaUterinaEsquerda) +
            parseFloat(this.laudos[0].arteriaUterinaDireita)) /
            2.0
        ).toFixed(1);
      }
      if (this.visibilidadeCriMed) {
        this.laudos[0].CriMed = true;
      } else {
        this.laudos[0].CriMed = false;
      }
      //alterei
      if (!this.preEclampsia) {
        this.laudos[0].MediaIp = undefined;
      }

      if (this.exibeFetal) {
        this.laudos[0].exibeFetal = true;
      } else {
        this.laudos[0].exibeFetal = false;
      }
      if (this.exibeFrase1) {
        this.laudos[0].exibeFrase1 = true;
      } else {
        this.laudos[0].exibeFrase1 = false;
      }
      this.laudos[0].semanasDUM = this.infosCompartilhadas.semanasDUM;
      this.laudos[0].diasDUM = this.infosCompartilhadas.diasDUM;
    },

    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },

  async mounted() {
    this.$store.commit('Laudo/RESET_LAUDOS');

    if (!!this.$route.query?.exame) {
      this.$store.dispatch('Laudo/getLaudos', this.$route.query.exame);
    }
    this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;
    if (!this.temLaudo) {
    }
    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.length > 0
    ) {
      this.camposExibidos = [];
      for (
        let i = 0;
        i < this.$store.getters['laudoSelecionado'].laudoJson.length;
        i++
      ) {
        this.camposExibidos.push(
          this.$store.getters['laudoSelecionado'].laudoJson[i].camposExibidos
        );
      }
    }

    for (let i = this.camposExibidos.length; i < 5; i++) {
      this.camposExibidos.push({
        exInsercao2: true,
        exPlacenta2: true,
        exibeIndicePulsatilidadeArteriaUmbilical: true,
        exibeDiastole: true,
        exibeIndicePulsatilidadeRelacaoCerebroPla: true,
        exibeVelocidadePicoSistolico: true,
        exibeIndicePulsatilidadeArteriaUterinaD: true,
        exibeDuctoVenoso: true,
        exGest: true,
        exPosFetal: true,
        exDiamBiparietal: true,
        exCircCranio: true,
        exCircAbdomen: true,
        exibeApresentacao: true,
        exComprimentoFemoral: true,
        exPesoFetal: true,
        exBatimentoFeto: true,
        exMovCorporais: true,
        exTonus: true,
        exVolLiquidoAmni: true,
        exPlacenta: true,
        exAusentes: true,
        exIdadeGest: true,
        exIndCef: true,
        exibeIndicacaoExame: true,
        exIdadeGest2: true,
        exDataProvavelParto: true,
        exDataProvavelPartoPrimTrim: true,
        exGestacaoConclusao: true,
        exGestacaoConclusao3: true,
        exPercentil1: true,
        exibeSituacao: true,
        exibeSituacao2: true,
        exPercentil2: true,
        exPercentil3: true,
        exibeGestacao: true,
        exPercentil4: true,
        exibeIndicePulsatilidadeArteriaCerebralMedia: true,
        exibeIndicePulsatilidadeArteriaUterinaE: true,
        exibeFluxoUmbilicalOpcao1: true,
        exibeCentralizacaoFetal: true,
        exibeRelacaoCerebroPlacentaria: true,
        exibeDuctoVenosoIndice: true,
        exibeArteriasUterinas: true,
        exibeVelocidadeAcm: true,
        exPercentil5: true,
        exibirBotaoEditar: false,
        exibirLaudo: true,
        exibeColoUterino: true,
        exibeColoPassouAMedir: true,
        exibeOrificioCervicalInterno: true,
        exibePolipoEndocervicalMedindo: true,
        exIdadeGestcorrigida2: true,
        exibeColoUterino2: true,
        exibeProtusaoBolsaAmniotica: true,
        exibeSinalDeSludge: true,
        exIdadeGestDiascorrigida2: true,
        exGrannum: true,
        exibeccca: true,
          exibecfcc: true,
          exibecfca: true,
          exDOF: true,
      });
    }
    for (let i = 0; i < this.camposExibidos.length; i++){
      console.log(this.camposExibidos[i].exibeColoUterino)
      if(this.camposExibidos[i].exibeColoUterino == undefined) {
        this.camposExibidos[i].exibeColoUterino = true
      }
      if(this.camposExibidos[i].exibeColoPassouAMedir == undefined) {
        this.camposExibidos[i].exibeColoPassouAMedir = true
      }
      if(this.camposExibidos[i].exibeOrificioCervicalInterno == undefined) {
        this.camposExibidos[i].exibeOrificioCervicalInterno = true
      }
      if(this.camposExibidos[i].exibePolipoEndocervicalMedindo == undefined) {
        this.camposExibidos[i].exibePolipoEndocervicalMedindo = true
      }
      if(this.camposExibidos[i].exibeColoUterino2 == undefined) {
        this.camposExibidos[i].exibeColoUterino2 = true
      }
      if(this.camposExibidos[i].exibeProtusaoBolsaAmniotica == undefined) {
        this.camposExibidos[i].exibeProtusaoBolsaAmniotica = true
      }
      if(this.camposExibidos[i].exibeSinalDeSludge == undefined) {
        this.camposExibidos[i].exibeSinalDeSludge = true
      }
    }
    
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      //   this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
