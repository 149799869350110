<template>
  <div>
    <v-container fluid style="max-width: 95%">
      <v-card class="pa-5 ma-5" elevation="3">
        <v-form
          ref="formularioDeProcedimento"
          @submit.prevent="salvarProcedimento"
          v-model="formValidado"
        >
          <h2 class="pb-8 text-center">PROCEDIMENTOS</h2>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                class="maiuscula"
                label="Cod° do procedimento"
                validate-on-blur
                filled
                outlined
                required
                v-model="model.procedimento"
                :rules="[value => !!value || 'Campo obrigatório']"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                class="maiuscula"
                label="Exame"
                required
                multiple
                validate-on-blur
                filled
                outlined
                v-model="model.exames"
                :items="nomesExames"
                :rules="[value => !!value || 'Campo obrigatório']"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                class="maiuscula"
                label="Convênio"
                validate-on-blur
                filled
                outlined
                required
                v-model="model.convenio"
                @change="handleConvenioChange"
                :items="nomesConvenios"
                :rules="[value => !!value || 'Campo obrigatório']"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                class="maiuscula"
                label="Valor (R$)"
                required
                validate-on-blur
                filled
                outlined
                @change="formatarValor"
                v-model="model.valor"
                :rules="[value => !!value || 'Campo obrigatório']"
              />
            </v-col>
          </v-row>
          <v-col cols="12" class="d-flex justify-start align-center pt-6">
            <v-btn color="primary" @click="consultarProcedimento"
              >CONSULTAR PROCEDIMENTOS</v-btn
            >
            <v-btn type="submit" color="primary" class="ml-5">Salvar</v-btn>
            <!-- <v-btn color="primary"  class="ml-5" @click="editarProcedimento"
              >EDITAR PROCEDIMENTOS</v-btn
            > -->
          </v-col>
          <v-card-title>
            <v-text-field
              v-if="mostrarCampoPesquisa"
              v-model="search"
              append-icon="mdi-magnify"
              label="Procurar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-form>
      </v-card>
      <v-data-table
        v-if="exibirProcedimentos"
        :search="search"
        :headers="headers1"
        :items="procedimentos"
        :items-per-page="-1"
        :loading="loadingProc"
        class="elevation-1 maiuscula pa-0 ma-5"
        @click:row="handleClick"
        
        no-data-text="Nenhum procedimento"
        loading-text="Buscando procedimentos..."
      ></v-data-table>
    </v-container>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      :modalProps="procSelecionado"
      @close="closeModal"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ConsultasMixin from '../../mixins/ConsultasMixin';
import PagamentosMixin from '@/mixins/PagamentosMixin';
import ModalEditarProc from '@components/financeiro/ModalEditarProcedimento.vue'
export default {
  mixins: [ConsultasMixin, PagamentosMixin],
  props: ['exibirConsulta', 'exibirBotao', 'exibirMenu'],
  data: () => ({
    formValidado: true,
    loadingSalvar: false,
    loadingProc: false,
    exibirProcedimentos: false,
    procSelecionado: null,
    model: { procedimento: '', convenio: '', exames: [], valor: '' },
    procedimentos: [],
    idSelecionado: null,
    search: '',
    modal: {
        active: false,
        modalComponent: ModalEditarProc,
      },
    mostrarCampoPesquisa: false,
    headers1: [
      {
        text: 'Cod. Procedimento',
        align: 'start',
        sortable: false,
        value: 'codigoProcedimento',
      },
      { text: 'Exames', value: 'exames' },
      { text: 'Valor', value: 'valor' },
    ],
  }),
  computed: {},

  methods: {
    async salvarProcedimento() {
      await this.criarMapaAssociacaoExames(this.model).then(resp => {
        if (resp.status === 200) {
          alert('Procedimento cadastrado');
          this.model.procedimento = '';
          this.model.convenio = '';
          this.model.exames = [];
          this.model.valor = '';

          this.formValidado = true;
          if (this.model.convenio) {
            this.consultarProcedimento();
          }
        }
        if (resp.status === 400) {
          alert('Procedimento já está cadastrado');
          this.model.procedimento = '';
          this.model.convenio = '';
          this.model.exames = [];
          this.model.valor = '';

          this.formValidado = true;
          if (this.model.convenio) {
            this.consultarProcedimento();
          }
        }
      }).catch(()=>{
        alert('Procedimento já existe');
      });
    },
    abreModal() {
      
      this.modal.active = true;
      
    
    },
    closeModal() {
      this.modal.active = false;
      this.consultarProcedimento();
      
     
      
    },
    handleClick(event){
      event.exames = event.exames.split(',')
      this.procSelecionado = event
      
      

      this.abreModal()
      
    },
    formatarValor(){
      let valorFormatado = parseFloat(this.model.valor.replace(/[^\d.]/g, '')).toFixed(2);
      this.model.valor= valorFormatado;
    },
    
    async consultarProcedimento() {
      if (this.model.convenio) {
        this.loadingProc = true;
        this.exibirProcedimentos = true;

        this.getProcedimentoPorConvenio(this.convenioSelecionado.id).then(
          resp => {
            this.procedimentos = resp.data;
            this.loadingProc = false;
            this.mostrarCampoPesquisa = true;
          }
        );
      } else {
        alert('Selecione um convênio antes de realizar a consulta.');
      }
    },
    handleConvenioChange(convenioNome) {
      this.setaConvenioSelecionado(convenioNome).then(() => {});
    },
  },
  mounted() {
    this.getNomesConvenios();
    this.getNomesExames();
  },
};
</script>

<style></style>
