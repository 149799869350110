<template lang="">
  <div>
    <LaudoAxila></LaudoAxila>
  </div>
</template>
<script>
import LaudoAxila from "../components/laudos/formularios/exames/axila/LaudoAxila.vue"
export default {
  components: {
    LaudoAxila,
  },
};
</script>
<style lang=""></style>
