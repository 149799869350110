<template>
  <v-container fluid>
    <v-card class="borda ma-5" elevation="3">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busque por paciente ou cpf"
          single-line
          hide-details
        ></v-text-field>
        <v-btn class="mx-2" text plain large router :to="'cadastrar-paciente'">
          <v-icon>mdi-account-plus-outline </v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        class="clique maiuscula cursor"
        :headers="headers2"
        :loading="loading"
        loading-text="Carregando lista de pacientes"
        :items="listaPacientes"
        :search="search"
        no-data-text="Nenhum paciente cadastrado"
        @click:row="handleClick"
      >
        <router-view></router-view>
      </v-data-table>
    </v-card>

    <!-- <v-card class="borda ma-5" elevation="3">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busque por paciente, cpf ou médico"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :loading="loading"
        loading-text="Carregando laudos"
        :search="search"
        :items="lista"
        class="clique maiuscula cursor"
        :headers="headers"
        no-data-text="Nenhuma consulta cadastrada"
        @click:row="handleClick"
      >
        <template #item.status="{ item }">
          <v-chip
            v-if="item.status == 'Atendido' || item.status == 'ATENDIDO'"
            color="green accent-2"
            small
            width="50px"
            class="tamanho"
          >
            <span>{{ item.status }}</span>
          </v-chip>
          <v-chip
            v-if="item.status == 'Laudado' || item.status == 'LAUDADO'"
            color="blue darken-1"
            small
            width="50px"
            class="tamanho"
          >
            <span>{{ item.status }}</span>
          </v-chip>
        </template>
      </v-data-table>
    </v-card> -->
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="700"
      scrollable
    >
      <v-card class="py-4 px-2">
        <v-card-actions class="d-flex justify-end">
          <v-btn depressed plain small class="text-end">
            <v-icon @click="dialog = false">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
        <template class="text-center">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">EXAME</th>
                  <th class="text-left">MÉDICO</th>
                  <th class="text-left">CONVÊNIO</th>
                  <th class="text-left">DATA</th>

                  <th class="text-left">AÇÕES</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, indexHistorico) in historicoFinalizado"
                  :key="item.name"
                  class="text-left pointer"
                >
                  <!--@click="irParaOsLaudos(item) -->
                  <td>
                    <v-checkbox
                      v-for="(exame, index) in item.exames"
                      v-model="laudoCheckbox[indexHistorico][exame.nome]"
                      class="mt-0 mb-0 pt-0 pb-0"
                      hide-details
                      :label="exame.nome"
                      validate-on-blur
                      :key="index"
                    ></v-checkbox>
                    <!-- <p
                                v-for="(exame, index) in item.exames"
                                :key="index"
                                class="py-0 my-0"
                              >
                                &bull; {{ exame.exame }}
                              </p> -->
                  </td>
                  <td>{{ item.nomeMedico }}</td>
                  <td>{{ item.convenio }}</td>
                  <td>{{ item.data }}</td>
                  <td>
                    <v-btn
                      class="ml-3 mb-0 pt-0 pb-0"
                      icon
                      @click="() => imprimirLaudo(item, indexHistorico)"
                    >
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                    <v-btn
                      class="ml-3 mb-0 pt-0 pb-0"
                      icon
                      @click="() => irParaOsLaudos(item, indexHistorico)"
                    >
                      <v-icon>mdi-text-box-edit</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p
            class="text--disabled text-center maiuscula"
            v-if="historicoFinalizado.length === 0 && loading === false"
          >
            {{ consultaSelecionada.paciente.nome }} ainda não possui laudos.
          </p>

          <v-progress-linear v-show="loading" indeterminate></v-progress-linear>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ConsultasMixin from '@mixins/ConsultasMixin';
import PacienteMixin from '../../mixins/PacienteMixin';
import LaudosMixin from '../../mixins/LaudosMixin';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [ConsultasMixin, PacienteMixin, LaudosMixin],
  data() {
    return {
      lista: [],
      loading: false,
      dialog: false,
      historicoFinalizado: [],
      historico: [],
      laudoCheckbox: [],

      search: '',
      headers1: [
        { text: 'Data', value: 'data' },
        { text: 'Médico', value: 'medicoExecutante' },
        { text: 'Exames', value: 'exame' },
        { text: '', value: 'action' },
      ],
      headers2: [
        {
          text: 'Nome',
          align: 'start',
          filterable: true,
          value: 'nome',
        },
        { text: 'CPF', value: 'cpf' },
      ],
    };
  },
  computed: {
    ...mapGetters('pacientes', ['pacienteSelecionado']),
  },

  methods: {
    async handleClick(event) {
      this.resetaConsultaSelecionada();

      this.setPacienteSelecionado(event);
      // await this.setPacienteByNome(event.nome).then(() => {

      //     // this.getPacienteById(event.id);
      // });
      await this.getLaudosHistorico(event.id)
        .then(() => {})
        .catch(e => (this.loading = false));
      this.dialog = true;

      this.$forceUpdate;
      // event.exames = [];
      // this.setConsultaSelecionada(event);

      // this.$router.push({
      //   name: 'laudo',
      //   query: { consulta: this.consultaSelecionada.id },
      // });
    },
    corStatus() {
      if (
        item.status == 'atendido' ||
        item.status == 'Atendido' ||
        item.status == 'ATENDIDO'
      ) {
        return (this.cor = 'green accent-2');
      } else if (
        item.status == 'laudado' ||
        item.status == 'Laudado' ||
        item.status == 'LAUDADO'
      ) {
        return (this.cor = 'blue darken-2');
      }
    },

    async listaLaudos() {
      this.loading = true;

      const consultas = await this.getTodasConsultas();

      const consultasFiltradas = [];
      for (var i = 0; i < Object.keys(consultas).length; i++) {
        consultas[i].paciente.nome = consultas[i].paciente.nome
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        if (consultas[i].status) {
          if (
            consultas[i].status == 'atendido' ||
            consultas[i].status == 'Atendido' ||
            consultas[i].status == 'ATENDIDO'
          ) {
            consultasFiltradas.push(consultas[i]);
          }
          if (
            consultas[i].status == 'laudado' ||
            consultas[i].status == 'Laudado' ||
            consultas[i].status == 'LAUDADO'
          ) {
            consultasFiltradas.push(consultas[i]);
          }
        }
      }

      this.lista = consultasFiltradas;
      this.loading = false;
    },
    async getLaudosHistorico(id) {
      this.loading = true;
      this.historicoFinalizado = [];
      // await -> esperar

      const { data } = await this.getLaudoPorPaciente(id);

      this.historico = data.map(exames => {
        return {
          consultaId: exames.consultaComExames.consultaId,
          examesId: exames.consultaComExames.exames?.map(
            e => e.idExamePorConsulta
          ),
          convenio: exames.consultaComExames.convenio,
          exames: exames.consultaComExames.exames,
          nomeMedico: exames.consultaComExames.medicoExecutante.nome,
          data: moment(exames.consultaComExames.data).format('DD/MM/YYYY'),
          exame: exames.consultaComExames.exames?.map(e => e.nome).join(', '),
          medicoExecutante: exames.consultaComExames.medicoExecutante,
          medicoSolicitante: exames.consultaComExames.medicoSolicitante,
          paciente: this.pacienteById,
        };
      }); //teste

      this.historico.sort((a, b) => {
        const dataFormatadaA = a.data.split('/');
        const dataFormatadaB = b.data.split('/');
        const dataA = new Date(
          dataFormatadaA[2],
          dataFormatadaA[1],
          dataFormatadaA[0]
        );
        const dataB = new Date(
          dataFormatadaB[2],
          dataFormatadaB[1],
          dataFormatadaB[0]
        );
        return dataB - dataA; //caso queira inverter as datas colocar dataA - dataB;
      });

      // await this.ordenaConsultasMedico();
      this.loading = false;
      this.historicoFinalizado = this.historico;
      this.laudoCheckbox = [];

      this.historico.forEach(h => {
        const obj = {};
        h.exame.split(', ').forEach(e => (obj[e] = false));
        this.laudoCheckbox.push(obj);
      });

      const historicoFormatado = [];
      for (const index in this.historico) {
        // O meu prox não pode ser apenas o prox index, mas sim todos os que estão na mesma data, então .find?;
        // const teste = this.historico.find((h)=>h.data === atual.data);
        const atual = this.historico[index];
        const prox = this.historico[index + 1];
        if (!prox) return;
        if (atual.data === prox.data && atual.nomeMedico === prox.nomeMedico) {
          const exameFormatado = `${atual.exame}, ${prox.exame}`;
          historicoFormatado.push({ ...atual, exame: exameFormatado });
        }
      }
    },
    // Laudos que não devem abrir sozinhos, AXILA, OBSTETRICADOPPLER,
    async imprimirLaudo(item, index) {
      const historico = this.laudoCheckbox[index];

      await this.setConsultaSelecionada(item);
      // await this.setConsultaSelecionada(this.consultaSelecionada);

      let ids = [];
      let nomes = [];
      for (const chave in historico) {
        if (historico[chave]) {
          nomes.push(chave.toLowerCase());
          ids.push(item.examesId[item.exames.findIndex(e => e.nome === chave)]);
        }
      }

      if (nomes.length === 0) {
        alert('SELECIONE PELO MENOS UMA OPÇÃO VÁLIDA');
        return;
      }
      let id;
      let nome;
      const indexComposto = nomes.findIndex(
        exame =>
          exame === 'translucência nucal' ||
          exame === 'axila' ||
          exame === 'morfologica' ||
          exame === 'obstetrica' ||
          exame === 'transvaginal'
      );
      let rota;
      if (nomes.includes('axila') && !nomes.includes('mama')) {
        alert('A VARIAÇÃO AXILA SÓ EXISTE ACOMPANHADA DE MAMA');
        return;
      }
      if (
        nomes.length === 2 &&
        nomes.includes('perfil cervical') &&
        nomes.includes('doppler')
      ) {
        id = ids[0];
        rota = { query: { exameId: id, via: '2' } };
        rota.name = `imprecoes.perfilCervicalComDoppler`;
      } else if (nomes.length > 1) {
        id = ids[indexComposto];
        rota = { query: { exameId: id, via: '2' } };

        if (nomes.includes('axila') && nomes.includes('mama')) {
          rota.name = `imprecoes.axila`;
          // } else if (nomes.includes('axila') && !nomes.includes('mama')) {
          //   alert('A VARIAÇÃO AXILA SÓ EXISTE ACOMPANHADA DE MAMA');
          //   return;
        } else if (
          nomes.includes('morfologica') &&
          nomes.includes('perfil cervical') &&
          nomes.length === 2
        ) {
          rota.name = `imprecoes.morfologicaComCervical`;
        } else if (
          nomes.includes('transvaginal') &&
          nomes.includes('doppler') &&
          nomes.length === 2
        ) {
          rota.name = `imprecoes.transvaginalUteroComDoppler`;
        } else if (
          nomes.includes('obstetrica') &&
          nomes.includes('doppler') &&
          nomes.length === 2
        ) {
          rota.name = `imprecoes.obstetricaDoppler`;
        } else if (
          nomes.includes('morfologica') &&
          nomes.includes('doppler') &&
          nomes.length === 2
        ) {
          rota.name = `imprecoes.morfologicaComDoppler`;
        } else if (
          nomes.includes('obstetrica') &&
          nomes.includes('perfil cervical') &&
          nomes.length === 2
        ) {
          rota.name = `imprecoes.obstetricaPerfilCervical`;
        } else if (
          nomes.includes('obstetrica') &&
          nomes.includes('perfil cervical') &&
          nomes.includes('doppler') &&
          nomes.length === 3
        ) {
          rota.name = `imprecoes.obstetricaDopplerPerfilCervical`;
        } else if (
          nomes.includes('morfologica') &&
          nomes.includes('perfil cervical') &&
          nomes.includes('doppler') &&
          nomes.length === 3
        ) {
          rota.name = `imprecoes.morfologicaComDopplerCervical`;
        } else if (
          nomes.includes('perfil cervical') &&
          nomes.includes('doppler') &&
          nomes.length === 2
        ) {
          rota.name = `imprecoes.perfilCervicalComDoppler`;
        } else if (
          nomes.includes('obstetrica') &&
          nomes.includes('doppler') &&
          nomes.includes('ecocardiograma') &&
          nomes.length === 3
        ) {
          rota.name = `imprecoes.obstetricaComDopplerEEcocardiodoppler`;
        } else if (nomes.includes('translucência nucal')) {
          rota.name = `imprecoes.translucencianucal`;
        } else {
          alert('ESSA COMBINAÇÃO NÃO É SUPORTADA');
          return;
        }
      } else {
        id = ids[0];
        nome = nomes[0].replace(/\s+/g, '');
        rota = { query: { exameId: id, exame: id, via: '2' } };
        rota.name = `imprecoes.${nome}`;
        if (nomes[0] === 'avaliação morfológica do primeiro trimestre') {
          rota.name = `imprecoes.avaliacaomorfologicadoprimeirotrimestre`;
        }
        if (nomes[0] === 'ecocardiograma') {
          rota.name = `imprecoes.ecocardiodopplerfetal`;
        }
        if (
          nomes[0] === 'pélvica transvaginal com doppler e preparo intestinal'
        ) {
          rota.name = `imprecoes.pelvicatransvaginaldopplerpreparointestinal`;
        }
        if (nomes[0] === 'transvaginal') {
          rota.name = `imprecoes.pelvicaTransvaginalUtero`;
        }
        if (nomes[0] === 'vilo corial') {
          rota.name = `imprecoes.viloCorial`;
        }
        if (nomes[0] === 'retorno') {
          rota.name = `imprecoes.retorno`;
        }
        if (nomes[0] === '3d') {
          rota.name = `imprecoes.3d`;
        }
        if (nomes[0] === '4d') {
          rota.name = `imprecoes.4d`;
        }
        if (nomes[0] === 'insercaoDeDiuGuiadoPorTransvaginal') {
          rota.name = `imprecoes.insercaodediuguiadoportransvaginal`;
        }
        if (nomes[0] === 'pessário') {
          rota.name = `imprecoes.colocacaoDoPessario`;
        }
        if (nomes[0] === 'amniocentese') {
          rota.name = `imprecoes.amniocentese`;
        }
        if (nomes[0] === 'perfil biofísico fetal ') {
          rota.name = `imprecoes.perfilBiofisicoFetal`;
        }
        if (nomes[0] === 'pélvica') {
          rota.name = `imprecoes.pelvica`;
        }
        if (nomes[0] === 'seriada') {
          rota.name = `imprecoes.seriada`;
        }
        if (nomes[0] === 'consulta ginecologica') {
          rota.name = `imprecoes.consultaGinecologica`;
        }
        if (nomes[0] === 'neurosonografia') {
          rota.name = `imprecoes.neurosonografia`;
        }
        if (nomes[0] === 'transvaginal tridimensional') {
          rota.name = `imprecoes.transvaginalUteroComTridimensional`;
        }
        if (nomes[0] === 'avaliação de reserva ovariana') {
          rota.name = `imprecoes.transvaginalUteroParaAvaliacaoDeReservaOvariana`;
        }
        if (nomes[0] === 'endometriose') {
          rota.name = `imprecoes.transvaginalUteroParaPesquisaDeEndometriose`;
        }
        if (nomes[0] === 'obstetrica 1ª trimestre') {
          rota.name = `imprecoes.obstetrica1trimestre`;
        }
        if (nomes[0] === 'pélvica transabdominal') {
          rota.name = `imprecoes.pelvicatransabdominal`;
        }
        if (nomes[0] === 'translucência nucal') {
          rota.name = `imprecoes.translucencianucal`;
        }
      }

      for (const key in historico) {
        historico[key] = false;
      }

      this.$forceUpdate;
      let verify;
      try {
        verify = !(await this.laudoExists(id));
      } catch {}
      if (verify) {
        alert(
          'O exame selecionado foi laudado com variações.\nSelecione mais exames para visualizar o laudo corretamente'
        );
        return;
      }

      await this.getLaudoByExamePorConsultaId(id);

      const novaAba = this.$router.resolve(rota);
      window.open(novaAba.href, '_blank');
    },

    async irParaOsLaudos(laudo, index) {
      const historico = this.laudoCheckbox[index];

      this.consultaSelecionada = laudo;
      await this.setConsultaSelecionada(laudo);
      const ids = [];
      const nomes = [];

      for (const chave in historico) {
        if (historico[chave]) {
          nomes.push(chave.toLowerCase());
          ids.push(
            laudo.examesId[laudo.exames.findIndex(e => e.nome === chave)]
          );
        }
      }

      if (nomes.length === 0) {
        alert('SELECIONE PELO MENOS UMA OPÇÃO VÁLIDA');
        return;
      }
      // laudo.exames.map(exame => exame.exame);

      let variacoes = nomes;
      let passouPorTn = false;
      let variacoesTn = [];
      let exameMinusculo;

      if (nomes.includes('axila') && !nomes.includes('mama')) {
        alert('A VARIAÇÃO AXILA SÓ EXISTE ACOMPANHADA DE MAMA');
        return;
      }
      if (
        variacoes.includes('perfil cervical') &&
        variacoes.includes('doppler') &&
        variacoes.length === 2
      ) {
        exameMinusculo = 'perfil cervical com doppler';
        const indexPc = variacoes.findIndex(
          exame => exame === 'perfil cervical'
        );
        laudo.exameId = laudo.examesId[indexPc];
      } else if (variacoes.length > 1) {
        const indexComposto = variacoes.findIndex(
          exame =>
            exame === 'translucência nucal' ||
            exame === 'axila' ||
            exame === 'morfologica' ||
            exame === 'obstetrica' ||
            exame === 'transvaginal'
        );
        exameMinusculo = variacoes[indexComposto];
        laudo.exameId = ids[indexComposto];

        if (
          variacoes.includes('morfologica') &&
          variacoes.includes('doppler') &&
          variacoes.includes('perfil cervical')
        ) {
          exameMinusculo = 'morfologica com cervical e doppler';
        } else if (
          variacoes.includes('morfologica') &&
          variacoes.includes('doppler')
        ) {
          exameMinusculo = 'morfologica com doppler';
        } else if (
          variacoes.includes('obstetrica') &&
          variacoes.includes('ecocardiograma') &&
          variacoes.includes('doppler')
        ) {
          exameMinusculo = 'obstetrica com doppler e ecocardiodoppler';
        } else if (
          variacoes.includes('obstetrica') &&
          variacoes.includes('perfil cervical') &&
          variacoes.includes('doppler')
        ) {
          exameMinusculo = 'obstetricaDopplerPerfilCervical';
        } else if (
          variacoes.includes('morfologica') &&
          variacoes.includes('perfil cervical')
        ) {
          exameMinusculo = 'morfologica com cervical';
        } else if (
          variacoes.includes('obstetrica') &&
          variacoes.includes('doppler')
        ) {
          exameMinusculo = 'obstetricaDoppler';
        } else if (
          variacoes.includes('obstetrica') &&
          variacoes.includes('perfil cervical')
        ) {
          exameMinusculo = 'obstetricaPerfilCervical';
        } else if (
          variacoes.includes('transvaginal') &&
          variacoes.includes('doppler')
        ) {
          exameMinusculo = 'transvaginal com doppler';
        } else if (
          variacoes.includes('perfil cervical') &&
          variacoes.includes('doppler') &&
          variacoes.includes('translucência nucal') &&
          variacoes.includes('avaliação morfológica do primeiro trimestre')
        ) {
          variacoesTn = [
            'doppler',
            'perfil cervical',
            'avaliação morfológica do primeiro trimestre',
          ];
          exameMinusculo = 'translucência nucal';
          passouPorTn = true;
        } else if (
          variacoes.includes('perfil cervical') &&
          variacoes.includes('doppler') &&
          variacoes.includes('translucência nucal')
        ) {
          variacoesTn = ['doppler', 'perfil cervical'];
          exameMinusculo = 'translucência nucal';
          passouPorTn = true;
        } else if (
          variacoes.includes('translucência nucal') &&
          variacoes.includes('avaliação morfológica do primeiro trimestre') &&
          variacoes.includes('doppler')
        ) {
          variacoesTn = [
            'doppler',
            'avaliação morfológica do primeiro trimestre',
          ];
          exameMinusculo = 'translucência nucal';
          passouPorTn = true;
        } else if (
          variacoes.includes('translucência nucal') &&
          variacoes.includes('avaliação morfológica do primeiro trimestre') &&
          variacoes.includes('perfil cervical')
        ) {
          variacoesTn = [
            'perfil cervical',
            'avaliação morfológica do primeiro trimestre',
          ];
          exameMinusculo = 'translucência nucal';
          passouPorTn = true;
        } else if (
          variacoes.includes('translucência nucal') &&
          variacoes.includes('doppler')
        ) {
          variacoesTn = ['doppler'];
          exameMinusculo = 'translucência nucal';
          passouPorTn = true;
        } else if (
          variacoes.includes('translucência nucal') &&
          variacoes.includes('perfil cervical')
        ) {
          variacoesTn = ['perfil cervical'];
          exameMinusculo = 'translucência nucal';
          passouPorTn = true;
        } else if (
          variacoes.includes('translucência nucal') &&
          variacoes.includes('avaliação morfológica do primeiro trimestre')
        ) {
          variacoesTn = ['avaliação morfológica do primeiro trimestre'];
          exameMinusculo = 'translucência nucal';
          passouPorTn = true;
        } else if (variacoes.includes('mama') && variacoes.includes('axila')) {
          exameMinusculo = 'axila';
        } else {
          alert('ESSA COMBINAÇÃO NÃO É SUPORTADA');
          return;
        }
      } else {
        exameMinusculo = variacoes[0];
        exameMinusculo = exameMinusculo;
        laudo.exameId = ids[0];
      }

      this.consultaSelecionada.liberada = true;
      this.consultaSelecionada.id = laudo.consultaId;
      this.consultaSelecionada.data = laudo.data;
      this.consultaSelecionada.medicoSolicitante = laudo.medicoSolicitante;
      this.consultaSelecionada.medicoExecutante = laudo.medicoExecutante;

      let query;

      if (passouPorTn) {
        query = {
          variacoes: variacoesTn,
          consulta: laudo.consultaId,
          exame: laudo.exameId,
          editar: true,
        };
      } else {
        query = {
          consulta: laudo.consultaId,
          exame: laudo.exameId,
          editar: true,
        };
      }
      this.$router.push({ name: `laudo.${exameMinusculo}`, query });
    },
  },
  async mounted() {
    // await this.listaLaudos();
  },
  async created() {
    try {
      this.loading = true;

      await this.getPacientes();
    } finally {
      this.loading = false;
    }
  },
};
</script>
