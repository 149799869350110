var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",staticStyle:{"max-width":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex mt-2 justify-space-between",attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.nomesMedicosExecutantes,"label":"Selecione um médico","clear-icon":"$clear","clearable":"","filled":"","outlined":""},on:{"change":_vm.handleMedicoChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-title',{attrs:{"id":attrs['aria-labelledby']},domProps:{"textContent":_vm._s(item)}})],1)]}},{key:"item.calories",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.medicoSelect),callback:function ($$v) {_vm.medicoSelect=$$v},expression:"medicoSelect"}})],1)],1),_c('v-card',{staticClass:"grey lighten-4 pa-0 ma-0"},[_c('v-container',{staticClass:"fluid grey lighten-4 pa-0 ma-0"},[(_vm.dataSelecionadaBloqueada)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" Data bloqueada, por favor desbloqueie antes para marcar uma consulta ")])])],1):_c('v-data-table',_vm._g(_vm._b({staticClass:"grey lighten-4 pt-2 px-0 clique maiuscula",attrs:{"height":"450px","hide-default-footer":"","headers":_vm.headersTable,"items":_vm.consultasSelecionadas,"items-per-page":-1,"dense":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.hora",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hora)+" "),(item.gemelar === 'true')?_c('v-chip',{staticClass:"ma-2",staticStyle:{"font-size":"10px","width":"70px","height":"25px"},attrs:{"color":"green"}},[_vm._v(" Gemelar ")]):_vm._e()]}},{key:"item.convenio",fn:function(ref){
var item = ref.item;
return [(item.nome !== '')?_c('div',[(item.pagamento.formaDePagamento == 'Cortesia')?_c('span',[_vm._v(" cortesia ")]):_c('span',[_vm._v(" "+_vm._s(_vm.extrairNomesConvenio(item.exames).join(', '))+" ")])]):_c('div',[_vm._v(" "+_vm._s(item.convenio)+" ")])]}},{key:"item.exames",fn:function(ref){
var item = ref.item;
return _vm._l((item.exames),function(exame,index){return _c('span',{key:index,attrs:{"title":("" + exame)}},[_vm._v(" "+_vm._s(exame.valorExameConvenio.exame.nome)+" "),_c('br')])})}},(_vm.valorStatus === 'status')?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"600","scrollasable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","depressed":"","plain":"","router":"","to":''},on:{"click":function($event){_vm.setConsultaSelecionada(item);
                    _vm.modal.active = true;}}},'v-btn',attrs,false),on),[_c('v-icon',{},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c(_vm.modal.modalComponent,_vm._b({tag:"component",on:{"close":_vm.closeModal}},'component',{ show: _vm.modal.active },false))],1)]}}:null,{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [(item.status || item.statusPagamento)?_c('v-chip',{staticClass:"tamanho",staticStyle:{"width":"75px"},attrs:{"color":_vm.getColor(item.status, item.statusPagamento),"small":"","width":"50px"}},[_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(item.status))])]):_vm._e()]}}],null,true)},'v-data-table',_vm.attrs,false),_vm.on))],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-5 px-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return undefined}},{key:"default",fn:function(dialog){return [_c('v-card',{staticStyle:{"overflow":"hidden"}},[_c('span',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"pa-3",attrs:{"router":"","to":'/recepcao'},on:{"click":function($event){dialog.value = false}}},[_vm._v("mdi-close-circle-outline")])],1),_c('v-card-text',[_c('div',{staticClass:"text-h5 pb-2 pt-2 text-center font-weight-bold"},[_vm._v(" Novo Agendamento: ")]),_c('v-row',{staticClass:"d-flex justify-center pt-6"},[_c('v-col',{attrs:{"cols":"12","md":"8","sm":"12"}},[_c('VAutocomplete',{ref:"pacientesAgendamento",attrs:{"label":"Paciente","items":_vm.listaPacientes,"item-text":"nome","item-value":"id","search-input":_vm.searchPaciente,"filled":"","outlined":"","clearable":"","disabled":_vm.loading,"loading":_vm.loading},on:{"update:searchInput":function($event){_vm.searchPaciente=$event},"update:search-input":function($event){_vm.searchPaciente=$event}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center pb-12"},[(_vm.mostrarBotaoNovoCadastro)?_c('v-btn',{staticClass:"grey darken-1 grey--text text--lighten-4 text-center mx-2 pa-5 font-weight-bold",attrs:{"text":"","depressed":"","width":"430px","disabled":_vm.loading,"large":""},on:{"click":_vm.redirecionaNovoCadastro}},[_vm._v(" Novo Cadastro ")]):_vm._e()],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1),_c('LoadingDialog',{attrs:{"loading":_vm.loadingDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }