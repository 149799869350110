import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import MainLayout from '@views/layout/MainLayout.vue';

import defaultRoutes from './default';
import imprecao from './imprecao';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'root',
    component: MainLayout,
    redirect: { name: 'Login' },
    children: defaultRoutes,
  },
  {
    path: '/imprecoes',
    name: 'imprecoes',
    component: () => import('@views/layout/ImprecoesLayout.vue'),
    children: imprecao,
  },
  // {
  //   path: '*',
  //   name: 'not-found',
  //   component: () => import('@views/ScreenNotFound.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // depois verificar se está online?
  const requisicaoPendente = store.getters['offline/requisicao'];
  const dadosPendentes = store.getters['offline/dados'];
  if (requisicaoPendente && requisicaoPendente.codigo) {
    switch (requisicaoPendente.codigo) {
      case 'POST_LAUDO': {
        await requisicaoPendente.requisicaoAlterarStatus(
          dadosPendentes.consulta,
          requisicaoPendente.urlBase,
          requisicaoPendente.headers
        );
        await requisicaoPendente.requisicaoSalvar(
          dadosPendentes.laudo,
          requisicaoPendente.urlBase,
          requisicaoPendente.headers
        );
        break;
      }
      case 'POST_LAUDO_TN': {
        await requisicaoPendente.requisicao(
          store,
          requisicaoPendente.exame,
          requisicaoPendente.consulta
        );
        break;
      }
      case 'POST_CONSULTA': {
        await requisicaoPendente.requisicao(
          dadosPendentes,
          dadosPendentes,
          requisicaoPendente.urlBase,
          requisicaoPendente.headers
        );
        break;
      }
      case 'POST_PACIENTE': {
        await requisicaoPendente.requisicao(
          dadosPendentes,
          requisicaoPendente.urlBase,
          requisicaoPendente.headers
        );
        break;
      }
      case 'PATCH_LAUDO': {
        await requisicaoPendente.requisicao(
          dadosPendentes,
          requisicaoPendente.urlBase,
          requisicaoPendente.headers
        );
        break;
      }
      case 'PATCH_CONSULTA': {
        await requisicaoPendente.requisicao(
          dadosPendentes,
          dadosPendentes,
          requisicaoPendente.urlBase,
          requisicaoPendente.headers
        );
        break;
      }
      case 'PATCH_PACIENTE': {
        await requisicaoPendente.requisicao(
          dadosPendentes.patchBody,
          dadosPendentes.paciente,
          requisicaoPendente.urlBase,
          requisicaoPendente.headers
        );
        break;
      }
      case 'PATCH_LAUDO_TN': {
        await requisicaoPendente.requisicao(
          dadosPendentes,
          requisicaoPendente.laudoId,
          requisicaoPendente.urlBase,
          requisicaoPendente.headers
        );
        break;
      }
    }
    store.commit('offline/RESET_STORE');
  }
  next();
});

export default router;
