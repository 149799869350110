const routes = [
  {
    path: 'doppler',
    name: 'laudo.doppler',
    component: () =>
      import('@/components/laudos/formularios/exames/doppler/LaudoDoppler.vue'),
  },
  {
    path: 'axila',
    name: 'laudo.axila',
    component: () =>
      import('@/components/laudos/formularios/exames/axila/LaudoAxila.vue'),
  },

  {
    path: 'ecocardiodopplerFetal',
    name: 'laudo.ecocardiograma',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/ecocardiodopplerFetal/LaudoEcocardiodopplerFetal.vue'
      ),
  },

  {
    path: 'morfologica',
    name: 'laudo.morfologica',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/morfologica/LaudoMorfologica.vue'
      ),
  },
  {
    path: 'mama',
    name: 'laudo.mama',
    component: () =>
      import('@/components/laudos/formularios/exames/mama/LaudoMama.vue'),
  },
  {
    path: 'morfologica1Trimestre',
    name: 'laudo.avaliação morfológica do primeiro trimestre',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/morfologica1Trimestre/LaudoMorfológica1Trimestre.vue'
      ),
  },

  {
    path: 'obstetrica1Trimestre',
    name: 'laudo.obstetrica 1ª trimestre',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/obstetrica1Trimestre/LaudoObstetrica1Trimestre.vue'
      ),
  },

  {
    path: 'obstetrica',
    name: 'laudo.obstetrica',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/obstetrica/LaudoObstetrica.vue'
      ),
  },

  {
    path: 'obstetricaDoppler',
    name: 'laudo.obstetricaDoppler',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/obstetricaComDoppler/LaudoObstetricaDoppler.vue'
      ),
  },
  {
    path: 'obstetricaPerfilCervical',
    name: 'laudo.obstetricaPerfilCervical',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/obstetricaComPerfilCervical/LaudoObstetricaPerfilCervical.vue'
      ),
  },
  {
    path: 'obstetricaDopplerPerfilCervical',
    name: 'laudo.obstetricaDopplerPerfilCervical',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/obstetricaDopplerPerfilCervical/LaudoObstetricaDopplerPerfilCervical.vue'
      ),
  },

  {
    path: 'pelvicaTransabdominal',
    name: 'laudo.pélvica transabdominal',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/pelvicaTransabdominal/LaudoPelvicaTransabdominal.vue'
      ),
  },
  {
    path: 'pelvicaTransvaginalDopplerPreparoIntestinal',
    name: 'laudo.pélvica transvaginal com doppler e preparo intestinal',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/pelvicaTransvaginalDopplerPreparoIntestinal/LaudoPelvicaTransvaginalDopplerPreparoIntestinal.vue'
      ),
  },
  {
    path: 'perfilCervical',
    name: 'laudo.perfil cervical',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/perfilCervical/LaudoPerfilCervical.vue'
      ),
  },
  {
    path: 'personalizado',
    name: 'laudo.personalizado',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/personalizado/LaudoPersonalizado.vue'
      ),
  },
  {
    path: 'translucenciaNucal',
    name: 'laudo.translucência nucal',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/translucenciaNucal/LaudoTranslucenciaNucalNew.vue'
      ),
  },
  {
    path: 'transvaginalHisterectomia',
    name: 'laudo.transvaginal histerectomia',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/transvaginalHisterectomia/LaudoTransvaginalHisterectomia.vue'
      ),
  },
  {
    path: 'transvaginal',
    name: 'laudo.transvaginal',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/transvaginalUtero/LaudoTransvaginalUtero.vue'
      ),
  },
  {
    path: 'transvaginal',
    name: 'laudo.transvaginal com utero',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/transvaginalUtero/LaudoTransvaginalUtero.vue'
      ),
  },
  {
    path: 'transvaginalUteroComDoppler',
    name: 'laudo.transvaginal com doppler',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/transvaginalUteroComDoppler/LaudoTransvaginalUteroComDoppler.vue'
      ),
  },
  {
    path: 'transvaginalUteroComTridimensional',
    name: 'laudo.transvaginal tridimensional',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/transvaginalUteroComTridimensional/LaudoTransvaginalUteroComTridimensional.vue'
      ),
  },
  {
    path: 'transvaginalUteroParaAvaliacaoDeReservaOvariana',
    name: 'laudo.avaliação de reserva ovariana',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/transvaginalUteroParaAvaliacaoDeReservaOvariana/LaudoTransvaginalUteroParaAvaliacaoDeReservaOvariana.vue'
      ),
  },
  {
    path: 'transvaginalUteroParaPesquisaDeEndometriose',
    name: 'laudo.endometriose',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/transvaginalUteroParaPesquisaDeEndometriose/LaudoTransvaginalUteroParaPesquisaDeEndometriose.vue'
      ),
  },
  {
    path: 'viloCorial',
    name: 'laudo.vilo corial',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/viloCorial/LaudoViloCorial.vue'
      ),
  },
  {
    path: 'retorno',
    name: 'laudo.retorno',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/retorno/LaudoRetorno.vue'
      ),
  },
  {
    path: 'insercaoDeDiuGuiadoPorTransvaginal',
    name: 'laudo.insercao de diu guiado por transvaginal',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/insercaoDeDiuGuiadoPorTransvaginal/LaudoInsercaoDeDiuGuiadoPorTransvaginal.vue'
      ),
  },
  {
    path: 'colocacaoDoPessario',
    name: 'laudo.pessário',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/colocacaoDoPessario/LaudoColocacaoDoPessario.vue'
      ),
  },
  {
    path: 'amniocentes',
    name: 'laudo.amniocentese',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/amniocentes/LaudoAmniocentese.vue'
      ),
  },
  {
    path: 'perfilBiofisico',
    name: 'laudo.perfil biofísico fetal ',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/perfilBiofisicoFetal/LaudoPerfilBiofisicoFetal.vue'
      ),
  },
  {
    path: 'seriadaAvaliacaoOvulacao',
    name: 'laudo.seriada',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/seriadaAvaliacaoOvulacao/LaudoSeriadaAvaliacaoOvulacao.vue'
      ),
  },
  {
    path: 'neurosonografia',
    name: 'laudo.neurosonografia',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/neurosonografia/LaudoNeurosonografia.vue'
      ),
  },
  {
    path: 'pelvica',
    name: 'laudo.pélvica',
    component: () =>
      import('@/components/laudos/formularios/exames/pelvica/LaudoPelvica.vue'),
  },
  {
    path: 'perfilBiofisicoFetal',
    name: 'laudo.perfil biofísico fetal ',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/perfilBiofisicoFetal/LaudoPerfilBiofisicoFetal.vue'
      ),
  },
  {
    path: 'morfologicaDoppler',
    name: 'laudo.morfologica com doppler',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/morfologica/MorfologicaComDoppler.vue'
      ),
  },
  {
    path: 'morfologicaComDopplerCervical',
    name: 'laudo.morfologica com cervical e doppler',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/morfologica/MorfologicaComDopplerPerfilCervical.vue'
      ),
  },
  {
    path: 'perfilCervicalComDoppler',
    name: 'laudo.perfil cervical com doppler',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/perfilCervical/LaudoPerfilCervicalComDoppler.vue'
      ),
  },
  {
    path: 'morfologicaComCervical',
    name: 'laudo.morfologica com cervical',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/morfologica/MorfologicaPerfilCervical.vue'
      ),
  },
  {
    path: 'obstetricaComDopplerEEcocardiodoppler',
    name: 'laudo.obstetrica com doppler e ecocardiodoppler',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/obstetrica/LaudoObstetricaComDopplerEEcocardiodoppler.vue'
      ),
  },
  {
    path: 'consultaGinecologica',
    name: 'laudo.consulta ginecologica',
    component: () =>
      import(
        '@/components/laudos/formularios/exames/consultaGinecologica/LaudoConsultaGinecologica.vue'
      ),
  },
];

export default routes;
