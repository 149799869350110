import { render, staticRenderFns } from "./ScreenLaudoTranslucenciaNucal.vue?vue&type=template&id=17456820&lang=true&"
import script from "./ScreenLaudoTranslucenciaNucal.vue?vue&type=script&lang=js&"
export * from "./ScreenLaudoTranslucenciaNucal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports