import Folha from '@components/imprecoes/Folha.vue';
import LaudosMixin from '@mixins/LaudosMixin';
import moment from 'moment';

export default {
  mixins: [LaudosMixin],
  components: { Folha },
  computed: {
    laudo() {
      return JSON.parse(this.laudoSelecionado.laudo);
    },
    infosCompartilhadas() {
      return this.$store.state.Laudo.infosCompartilhadas;
    },
    consulta() {
      
      return this.laudoSelecionado.examePorConsulta.consulta;
    },
    paciente() {
      return this.laudoSelecionado.examePorConsulta.consulta.paciente;
    },
    medico() {
      return this.laudoSelecionado.examePorConsulta.consulta.medicoExecutante;
    },
    convenio() {
      return this.laudoSelecionado.examePorConsulta.valorExameConvenio.convenio;
    },
  },
  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);
    

    print();
  },
};
