<template lang="">
  <v-container fluid>
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">
        ULTRASSONOGRAFIA DE MAMA COM AXILA
      </h2>
    </v-card>
    <v-alert
      v-if="!!laudoSelecionado.laudoJson.folhas"
      outlined
      type="warning"
      border="left"
      text=""
    >
      Esse <strong>laudo</strong> teve a impressão
      <strong>personalizada.</strong>
      <v-btn text @click="adcionarConteudoEditorPersonalizado"
        >Clique aqui para visualizar</v-btn
      >
    </v-alert>
    <template v-if="clicouNoPersonalizado">
      <v-col
        cols="12"
        class="form-group"
        v-for="(index, folha) in quantidadeFolhasEditorPersonalizado"
        :key="index"
      >
        <h2>Folha {{ folha + 1 }}</h2>
        <v-btn plain small @click="removeFolha(index)" class="pl-0">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <vue-editor v-model="conteudoEditorPersonalizado[folha]" />
      </v-col>

      <!-- <v-col cols="12" class="form-group">
          <vue-editor v-model="conteudoEditorPersonalizado[0]" />
        </v-col> -->
    </template>
    <template v-else>
      <div class="pt-5">
        <v-col
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exibeTransdutor ? '' : 'text--disabled'
          }`"
        >
          <span
            >Exame realizado com transdutor linear de alta resolução
            multifrequencial (
          </span>
          <v-text-field
            v-mask="''"
            class="mx-2 flex-grow-0"
            dense
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeTransdutor"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.transdutorLinear"
          ></v-text-field>
          <span> a </span>
          <v-text-field
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeTransdutor"
            class="mx-2 flex-grow-0"
            dense
            v-mask="''"
            v-model="laudoSelecionado.laudoJson.transdutorLinear2"
          ></v-text-field>
          <span> MHz).</span>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeTransdutor"
            @click="
              camposExibidos.exibeTransdutor = true;
              laudoSelecionado.laudoJson.transdutorLinear = undefined;
              laudoSelecionado.laudoJson.transdutorLinear2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exibeTransdutor = false;
              laudoSelecionado.laudoJson.transdutorLinear = undefined;
              laudoSelecionado.laudoJson.transdutorLinear2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
      <h3>Mama direita:</h3>

      <v-col cols="12" class="d-flex justify-start align-center">
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.mamaDireitaPrevia0"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Mastectomia total prévia sem evidências de nódulos."
          validate-on-blur
        ></v-checkbox>
      </v-col>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <span
          :class="
            !camposExibidos.exibePeleSubcutaneoEsq ? 'text--disabled' : ''
          "
          >Pele e subcutâneo
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibePeleSubcutaneoEsq"
          :items="[
            'plana, sem abaulamentos ou retrações. Espessura normal',
            'presença de edema ',
            'presença de retração ',
            'presença de cisto ',
            'outros',
          ]"
          v-model="laudoSelecionado.laudoJson.peleSubcutaneoEsq"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <span v-if="
            [
              'presença de edema ',
              'presença de retração ',
              'presença de cisto ',
              'outros',
            ].includes(laudoSelecionado.laudoJson.peleSubcutaneoEsq)
          ">
          medindo
        </span>
        <v-text-field
          v-if="
            [
              'presença de edema ',
              'presença de retração ',
              'presença de cisto ',
              'outros',
            ].includes(laudoSelecionado.laudoJson.peleSubcutaneoEsq)
          "
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibePeleSubcutaneoEsq"
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.peleSubcutaneo2Esq"
        />
        <span v-if="
            [
              'presença de edema ',
              'presença de retração ',
              'presença de cisto ',
              'outros',
            ].includes(laudoSelecionado.laudoJson.peleSubcutaneoEsq)
          ">
          cm.
        </span>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibePeleSubcutaneoEsq"
          @click="
            camposExibidos.exibePeleSubcutaneoEsq = true;
            laudoSelecionado.laudoJson.peleSubcutaneoEsq = undefined;
            laudoSelecionado.laudoJson.peleSubcutaneo2Esq = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibePeleSubcutaneoEsq"
          @click="
            camposExibidos.exibePeleSubcutaneoEsq = false;
            laudoSelecionado.laudoJson.peleSubcutaneoEsq = undefined;
            laudoSelecionado.laudoJson.peleSubcutaneo2Esq = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <span
          :class="!camposExibidos.exibeAreolaePapilaEsq ? 'text--disabled' : ''"
          >Aréola e papila
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeAreolaePapilaEsq"
          :items="[
            'sem alterações ecográficas evidentes',
            'presença de edema',
            'presença de retração',
            'presença de cisto',
            'outros',
          ]"
          v-model="laudoSelecionado.laudoJson.areolaPapilaEsq"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <span  v-if="
            [
              'presença de edema',
              'presença de retração',
              'presença de cisto',
              'outros',
            ].includes(laudoSelecionado.laudoJson.areolaPapilaEsq)
          ">
            medindo
        </span>
        <v-text-field
          v-if="
            [
              'presença de edema',
              'presença de retração',
              'presença de cisto',
              'outros',
            ].includes(laudoSelecionado.laudoJson.areolaPapilaEsq)
          "
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeAreolaePapilaEsq"
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.areolaPapila2Esq"
        />
        <span  v-if="
            [
              'presença de edema',
              'presença de retração',
              'presença de cisto',
              'outros',
            ].includes(laudoSelecionado.laudoJson.areolaPapilaEsq)
          ">
            cm.
        </span>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeAreolaePapilaEsq"
          @click="
            camposExibidos.exibeAreolaePapilaEsq = true;
            laudoSelecionado.laudoJson.areolaPapilaEsq = undefined;
            laudoSelecionado.laudoJson.areolaPapila2Esq = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeAreolaePapilaEsq"
          @click="
            camposExibidos.exibeAreolaePapilaEsq = false;
            laudoSelecionado.laudoJson.areolaPapilaEsq = undefined;
            laudoSelecionado.laudoJson.areolaPapila2Esq = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <span
          :class="
            !camposExibidos.exibeComposicaoMamariaEsq ? 'text--disabled' : ''
          "
          >Composição mamária
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeComposicaoMamariaEsq"
          :items="[
            'homogênea adiposa',
            'homogênea fibroglandular',
            'predominantemente ecogênico, com tecido gorduroso de permeio',
            'predominantemente adiposo, com tecido fibroglandular de permeio'
          ]"
          v-model="laudoSelecionado.laudoJson.composicaoMamariaEsq"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeComposicaoMamariaEsq"
          @click="
            camposExibidos.exibeComposicaoMamariaEsq = true;
            laudoSelecionado.laudoJson.composicaoMamariaEsq = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeComposicaoMamariaEsq"
          @click="
            camposExibidos.exibeComposicaoMamariaEsq = false;
            laudoSelecionado.laudoJson.composicaoMamariaEsq = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.mamaEsqPrevia1"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Ausência de imagens nodulares de etiologia sólida ou cística."
          validate-on-blur
        ></v-checkbox>
      </v-col>
      
      <template v-if="!laudoSelecionado.laudoJson.mamaEsqPrevia1 ">
        <v-col
       
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.noduloEsq"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Nódulo sólido"
          validate-on-blur
        ></v-checkbox>
      </v-col>
        <v-col
          v-if="laudoSelecionado.laudoJson.noduloEsq"
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exPresencaEsq ? '' : 'text--disabled'
          }`"
        >
          <span>Presença de</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exPresencaEsq"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            @change="criaListaForEsq(laudoSelecionado.laudoJson.presencaEsq)"
            v-model="laudoSelecionado.laudoJson.presencaEsq"
          >
          </v-text-field>
          <!-- <v-select
          :items="['miometrial', 'miometriais']"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          class="mx-2 flex-grow-0"
          dense
          :disabled="!camposExibidos.exDistImg"
          v-model="laudoSelecionado.laudoJson.presencaOpc"
        >
        </v-select> -->

          <span
            >{{
              laudoSelecionado.laudoJson.presencaEsq > 1
                ? 'nódulos, assim caracterizados'
                : 'nódulo, assim caracterizado'
            }}:
          </span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exPresencaEsq"
            @click="
              camposExibidos.exPresencaEsq = true;
              laudoSelecionado.laudoJson.presencaEsq = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exPresencaEsq = false;
              laudoSelecionado.laudoJson.presencaEsq = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-row
          class="ma-0"
          v-for="(n, index) in laudoSelecionado.laudoJson.listaPresencasEsq"
          :key="index"
        >
          <v-col cols="12" :class="`d-flex justify-start align-center `">
            <span
              :class="`${
                camposExibidos.exDistImg[index] ? '' : 'text--disabled'
              }`"
              >&#149; N{{ index + 1 }}:</span
            >

            <v-select
              :items="['oval, ', 'redondo, ', 'irregular, ']"
              required
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              class="mx-2 flex-grow-0"
              dense
              :disabled="!camposExibidos.exDistImg[index]"
              v-model="n.presenca.opc1"
            >
            </v-select>

            <v-select
              :items="[
                'margem circunscrita, ',
                'não circunscrita indistinta, ',
                'não circunscrita espiculada, ',
                'não circunscrita microlobulada, ',
                'não circunscrita angulada, ',
                'não circunscrita obscurecida, ',
              ]"
              :disabled="!camposExibidos.exDistImg[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="n.presenca.opc2"
            >
            </v-select>
            <!-- <span :class="`${
          camposExibidos.exDistImg[index] ? '' : 'text--disabled'
        }`"
        >medindo</span> -->
            <v-select
              :items="[
                'paralelo, ',
                'não paralelo à pele, ',
                ' paralelo à pele, ',
              ]"
              :disabled="!camposExibidos.exDistImg[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="n.presenca.opc3"
            ></v-select>
            <v-select
              :items="[
                'anecóico, ',
                'hiperecóico, ',
                'hipoecóico, ',
                'complexo cisto - sólido, ',
                'isoecóico, ',
                'heterogêneo, ',
              ]"
              :disabled="!camposExibidos.exDistImg[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="n.presenca.opc4"
            >
            </v-select>

            <v-select
              :items="[
                'sem efeitos sonoros, ',
                'com reforço acústico, ',
                'com sombra acústica, ',
              ]"
              :disabled="!camposExibidos.exDistImg[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="n.presenca.opc5"
            >
            </v-select>
          </v-col>

          <v-col cols="12" :class="`d-flex justify-start align-center `">
            <span
              :class="!camposExibidos.exDistImg[index] ? 'text--disabled' : ''"
              >medindo
            </span>
            <v-text-field
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exDistImg[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              validate-on-blur
              v-model="n.presenca.medida1Esq"
            >
            </v-text-field>
            <span :class="!camposExibidos.exibeMedidas ? 'text--disabled' : ''">
              x
            </span>
            <v-text-field
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exDistImg[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              validate-on-blur
              v-model="n.presenca.medida2Esq"
            >
            </v-text-field>
            <span
              :class="!camposExibidos.exDistImg[index] ? 'text--disabled' : ''"
            >
              x
            </span>
            <v-text-field
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exDistImg[index]"
              required
              class="mx-2 flex-grow-0"
              width="30px"
              dense
              validate-on-blur
              v-model="n.presenca.medida3Esq"
            >
            </v-text-field>

            <span
              :class="!camposExibidos.exDistImg[index] ? 'text--disabled' : ''"
            >
              cm,
            </span>
            <v-btn
                dense
                depressed
                text
                icon
                v-if="!camposExibidos.exDistImg[index]"
                @click="
                  camposExibidos.exDistImg[index] = true;
                  
                  n.presenca.opc1 = undefined;
                  n.presenca.opc2 = undefined;
                  n.presenca.opc3 = undefined;
                  n.presenca.opc4 = undefined;
                  n.presenca.opc5 = undefined;
                  n.presenca.medida1Esq = undefined;
                  n.presenca.medida2Esq = undefined;
                  n.presenca.medida3Esq = undefined;

                  $forceUpdate();
                "
                ><v-icon>mdi-eye-off</v-icon></v-btn
              >
              <v-btn
                dense
                depressed
                text
                icon
                v-else
                @click="
                  camposExibidos.exDistImg[index] = false;
                  n.presenca.opc1 = undefined;
                  n.presenca.opc2 = undefined;
                  n.presenca.opc3 = undefined;
                  n.presenca.opc4 = undefined;
                  n.presenca.opc5 = undefined;
                  n.presenca.medida1Esq = undefined;
                  n.presenca.medida2Esq = undefined;
                  n.presenca.medida3Esq = undefined;

                  $forceUpdate();
                "
                ><v-icon>mdi-eye</v-icon></v-btn
              >
            <span
              :class="!camposExibidos.exPele[index] ? 'text--disabled' : ''"
            >
              &nbsp; distando
            </span>
            <v-text-field
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exPele[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              validate-on-blur
              v-model="n.presenca.medidaCmPeleEsq"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" :class="`d-flex justify-start align-center `">
            <span
              :class="!camposExibidos.exPele[index] ? 'text--disabled' : ''"
            >
              cm da pele
            </span>
            <span
              :class="!camposExibidos.exPele[index] ? 'text--disabled' : ''"
            >
              &nbsp;e
            </span>
            <v-btn
              dense
              depressed
              text
              icon
              v-if="!camposExibidos.exPele[index]"
              @click="
                camposExibidos.exPele[index] = true;
                n.presenca.medidaCmPeleEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              text
              icon
              v-else
              @click="
                camposExibidos.exPele[index] = false;
                n.presenca.medidaCmPeleEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye</v-icon></v-btn
            >
            <v-text-field
              v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exAureola[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              validate-on-blur
              v-model="n.presenca.medidaCmAreolaEsq"
            >
            </v-text-field>
            <span
              :class="!camposExibidos.exAureola[index] ? 'text--disabled' : ''"
            >
              cm da aréola
            </span>
            <v-btn
              dense
              depressed
              text
              icon
              v-if="!camposExibidos.exAureola[index]"
              @click="
                camposExibidos.exAureola[index] = true;
                n.presenca.medidaCmAreolaEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              text
              icon
              v-else
              @click="
                camposExibidos.exAureola[index] = false;
                n.presenca.medidaCmAreolaEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye</v-icon></v-btn
            >

            <span
              :class="!camposExibidos.exDistImg[index] ? 'text--disabled' : ''"
            >
              , localizado 
            </span>
            <v-select
              :readonly="carregamentoDoLaudo || !podeEditar"
              required
              validate-on-blur
              :disabled="!camposExibidos.exDistImg[index]"
              :items="[
                'no quadrante supero-lateral',
                'no quadrante supero-medial',
                'no quadrante ínfero-lateral',
                'no quadrante ínfero-medial',
                'na junção dos quadrantes superiores',
                'na junção dos quadrantes inferiores',
                'na junção dos quadrantes laterais',
                'na junção dos quadrantes mediais',
                'na região retroareolar',
              ]"
              v-model="n.presenca.locPeleAreoEsq"
              :clearable="podeEditar"
              class="mx-2 flex-grow-0"
              dense
            ></v-select>
            <span
              :class="!camposExibidos.exDistImgHora[index] ? 'text--disabled' : ''"
            >
              {{ n.presenca.horaAreolaEsq > 1 ? 'as ' : 'a' }}
            </span>
            <v-text-field
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exDistImgHora[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              validate-on-blur
              v-model="n.presenca.horaAreolaEsq"
            >
            </v-text-field>
            <span
              :class="!camposExibidos.exDistImgHora[index] ? 'text--disabled' : ''"
            >
              {{ n.presenca.horaAreolaEsq > 1 ? ' horas ' : ' hora ' }}.
            </span>

            <v-btn
              dense
              depressed
              text
              icon
              v-if="!camposExibidos.exDistImgHora[index]"
              @click="
                camposExibidos.exDistImgHora[index] = true;
                
                n.presenca.horaAreolaEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              text
              icon
              v-else
              @click="
                camposExibidos.exDistImgHora[index] = false;
               
                n.presenca.horaAreolaEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye</v-icon></v-btn
            >
          </v-col>
        </v-row>

        <!-- <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
      <span :class="!camposExibidos.exibeMedidasEsq ? 'text--disabled' : ''"
          >medindo
        </span>

        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidasEsq"
          required
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.medida1Esq"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidas ? 'text--disabled' : ''">
          x
        </span>
        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidasEsq"
          required
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.medida2Esq"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidasEsq ? 'text--disabled' : ''">
          x
        </span>
        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidasEsq"
          required
          class="mx-2 flex-grow-0"
          width="30px"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.medida3Esq"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidasEsq ? 'text--disabled' : ''">
          cm, distando
        </span>
        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidasEsq"
          required
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.medidaCmPeleEsq"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidasEsq ? 'text--disabled' : ''">
          cm da pele e
        </span>
      </v-col>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidasEsq"
          required
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.medidaCmAreolaEsq"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidasEsq ? 'text--disabled' : ''">
          cm da aréola, localizada
        </span>
        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeMedidasEsq"
          :items="[
            'no quadrante supero-lateral',
            'no quadrante supero-medial',
            'no quadrante ínfero-lateral',
            'no quadrante ínfero-medial',
            'na junção dos quadrantes superiores',
            'na junção dos quadrantes inferiores',
            'na junção dos quadrantes laterais',
            'na junção dos quadrantes mediais',
            'na região retroareolar',
          ]"
          v-model="laudoSelecionado.laudoJson.locPeleAreoEsq"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <span :class="!camposExibidos.exibeMedidasEsq ? 'text--disabled' : ''">
          {{ laudoSelecionado.laudoJson.horaAreolaEsq > 1 ? 'as ' : 'a' }}
        </span>
        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidasEsq"
          required
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.horaAreolaEsq"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidasEsq ? 'text--disabled' : ''">
          {{
            laudoSelecionado.laudoJson.horaAreolaEsq > 1 ? ' horas ' : ' hora '
          }}.
        </span>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeMedidasEsq"
          @click="
            camposExibidos.exibeMedidasEsq = true;
            laudoSelecionado.laudoJson.medida1Esq = undefined;
            laudoSelecionado.laudoJson.medida2Esq = undefined;
            laudoSelecionado.laudoJson.medida3Esq = undefined;
            laudoSelecionado.laudoJson.medidaCmPeleEsq = undefined;
            laudoSelecionado.laudoJson.medidaCmAreolaEsq = undefined;
            laudoSelecionado.laudoJson.locPeleAreoEsq = undefined;
            laudoSelecionado.laudoJson.horaAreolaEsq = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeMedidasEsq"
          @click="
            camposExibidos.exibeMedidasEsq = false;
            laudoSelecionado.laudoJson.composicaoMamariaEsq = undefined;
            laudoSelecionado.laudoJson.medida1Esq = undefined;
            laudoSelecionado.laudoJson.medida2Esq = undefined;
            laudoSelecionado.laudoJson.medida3Esq = undefined;
            laudoSelecionado.laudoJson.medidaCmPeleEsq = undefined;
            laudoSelecionado.laudoJson.medidaCmAreolaEsq = undefined;
            laudoSelecionado.laudoJson.locPeleAreoEsq = undefined;
            laudoSelecionado.laudoJson.horaAreolaEsq = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->
      <v-col
       
       cols="12"
       class="d-flex justify-start align-center"
     >
       <v-checkbox
         :readonly="carregamentoDoLaudo || !podeEditar"
         v-model="laudoSelecionado.laudoJson.cistoIsolado"
         class="d-flex justify-content-start align-items-center mt-n1"
         label="Cisto isolado"
         validate-on-blur
       ></v-checkbox>
     </v-col>

        <v-col
          v-if="laudoSelecionado.laudoJson.cistoIsolado"
          cols="12"
          class="d-flex justify-start align-center"
        >
          <span :class="!camposExibidos.exibePresImgEsq ? 'text--disabled' : ''"
            >Presença de imagem anecóica medindo
          </span>

          <v-text-field
            class="mx-2 flex-grow-0"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exibePresImgEsq"
            validate-on-blur
            required
            dense
            v-model="laudoSelecionado.laudoJson.presImgcmEsq"
          />
          <span
            :class="!camposExibidos.exibePresImgEsq ? 'text--disabled' : ''"
          >
            cm, localizada
          </span>

          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibePresImgEsq"
            :items="[
              'no quadrante supero-lateral',
              'no quadrante supero-medial',
              'no quadrante ínfero-lateral',
              'no quadrante ínfero-medial',
              'na junção dos quadrantes superiores',
              'na junção dos quadrantes inferiores',
              'na junção dos quadrantes laterais',
              'na junção dos quadrantes mediais',
              'na região retroareolar',
            ]"
            v-model="laudoSelecionado.laudoJson.locPeleAreoEsq1"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>

          <span>, </span>

          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibePresImgEsq"
            :items="[
              'sem conteúdo espesso',
              'com conteúdo espesso',
              'com área sólida medindo ',
            ]"
            v-model="laudoSelecionado.laudoJson.presImgOpEsq"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>
          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.presImgOpEsq ==
              'com área sólida medindo '
            "
            class="mx-2 flex-grow-0"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exibePresImgEsq"
            validate-on-blur
            required
            dense
            v-model="laudoSelecionado.laudoJson.presImgOpOutrosEsq"
          />
          <span
            v-if="
              laudoSelecionado.laudoJson.presImgOpEsq ==
              'com área sólida medindo '
            "
            :class="!camposExibidos.exibePresImgEsq ? 'text--disabled' : ''"
          >
            cm.</span
          >
          <span
            v-else
            :class="!camposExibidos.exibePresImgEsq ? 'text--disabled' : ''"
          >
            .</span
          >

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibePresImgEsq"
            @click="
              camposExibidos.exibePresImgEsq = true;
              laudoSelecionado.laudoJson.presImgcmEsq = undefined;

              laudoSelecionado.laudoJson.presImgOpEsq = undefined;
              laudoSelecionado.laudoJson.presImgOpOutrosEsq = undefined;
              laudoSelecionado.laudoJson.locPeleAreoEsq1 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exibePresImgEsq"
            @click="
              camposExibidos.exibePresImgEsq = false;
              laudoSelecionado.laudoJson.presImgcmEsq = undefined;
              laudoSelecionado.laudoJson.presImgOpEsq = undefined;
              laudoSelecionado.laudoJson.presImgOpOutrosEsq = undefined;
              laudoSelecionado.laudoJson.locPeleAreoEsq1 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col
       
       cols="12"
       class="d-flex justify-start align-center"
     >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.conglomeradoDeCistos"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Conglomerado de cistos"
          validate-on-blur
        ></v-checkbox>
      </v-col>
        <v-col
          v-if="laudoSelecionado.laudoJson.conglomeradoDeCistos"
          cols="12"
          class="d-flex justify-start align-center"
        >
          <span
            :class="
              !camposExibidos.exibeCongDeCistosEsq ? 'text--disabled' : ''
            "
            >Conglomerado de cistos medindo
          </span>

          <v-text-field
            class="mx-2 flex-grow-0"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exibeCongDeCistosEsq"
            validate-on-blur
            required
            dense
            v-model="laudoSelecionado.laudoJson.CongCistoCmEsq"
          />
          <span
            :class="
              !camposExibidos.exibeCongDeCistosEsq ? 'text--disabled' : ''
            "
          >
            cm, localizado
          </span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeCongDeCistosEsq"
            :items="[
              'no quadrante supero-lateral',
              'no quadrante supero-medial',
              'no quadrante ínfero-lateral',
              'no quadrante ínfero-medial',
              'na junção dos quadrantes superiores',
              'na junção dos quadrantes inferiores',
              'na junção dos quadrantes externos',
              'na junção dos quadrantes mediais',
              'na região retroareolar',
              'sem conteúdo espesso',
              'Alguns com conteúdo espesso',
            ]"
            v-model="laudoSelecionado.laudoJson.CongCistoOpcEsq"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeCongDeCistosEsq"
            @click="
              camposExibidos.exibeCongDeCistosEsq = true;
              laudoSelecionado.laudoJson.CongCistoCmEsq = undefined;
              laudoSelecionado.laudoJson.CongCistoOpcEsq = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exibeCongDeCistosEsq"
            @click="
              camposExibidos.exibeCongDeCistosEsq = false;
              laudoSelecionado.laudoJson.CongCistoCmEsq = undefined;
              laudoSelecionado.laudoJson.CongCistoOpcEsq = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col
       
       cols="12"
       class="d-flex justify-start align-center"
     >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.multiplosCistos"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Múltiplos cistos"
          validate-on-blur
        ></v-checkbox>
      </v-col>
        <v-col
          v-if="laudoSelecionado.laudoJson.multiplosCistos"
          cols="12"
          class="d-flex justify-start align-center"
        >
          <span
            :class="!camposExibidos.exibeMultiplasEsq ? 'text--disabled' : ''"
            > Imagens anecóicas, com a maior medindo
          </span>

          <v-text-field
            class="mx-2 flex-grow-0"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exibeMultiplasEsq"
            validate-on-blur
            required
            dense
            v-model="laudoSelecionado.laudoJson.mutlImgcmEsq"
          />
          <span
            :class="!camposExibidos.exibeMultiplasEsq ? 'text--disabled' : ''"
          >
            cm, localizada
          </span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeMultiplasEsq"
            :items="[
              'no quadrante supero-lateral',
              'no quadrante supero-medial',
              'no quadrante ínfero-lateral',
              'no quadrante ínfero-medial',
              'na junção dos quadrantes superiores',
              'na junção dos quadrantes inferiores',
              'na junção dos quadrantes externos',
              'na junção dos quadrantes mediais',
              'na região retroareolar',
              'sem conteúdo espesso',
              'Alguns com conteúdo espesso',
            ]"
            v-model="laudoSelecionado.laudoJson.mutlImgOpcEsq"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeMultiplasEsq"
            @click="
              camposExibidos.exibeMultiplasEsq = true;
              laudoSelecionado.laudoJson.mutlImgcmEsq = undefined;
              laudoSelecionado.laudoJson.mutlImgOpcEsq = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exibeMultiplasEsq"
            @click="
              camposExibidos.exibeMultiplasEsq = false;
              laudoSelecionado.laudoJson.mutlImgcmEsq = undefined;
              laudoSelecionado.laudoJson.mutlImgOpcEsq = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
      </template>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.temProteseEsq"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Prótese"
          validate-on-blur
        ></v-checkbox>
      </v-col>

      <!-- <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <span :class="!camposExibidos.exibeAxila1 ? 'text--disabled' : ''"
          >Axila
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeAxila1"
          :items="[
            'Presença de formações ovaladas, hipoecogênicas, com hilos hiperecogênicos, sugestivas de linfonodos de aspecto e dimensões dentro da normalidade',
            'livre',
          ]"
          v-model="laudoSelecionado.laudoJson.axila1"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeAxila1"
          @click="
            camposExibidos.exibeAxila1 = true;
            laudoSelecionado.laudoJson.axila1 = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeAxila1"
          @click="
            camposExibidos.exibeAxila1 = false;
            laudoSelecionado.laudoJson.axila1 = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->

      <v-col
        cols="12"
        class="d-flex justify-start align-center"
        v-if="laudoSelecionado.laudoJson.temProteseEsq"
      >
        <span :class="!camposExibidos.exibeTexturaEsq ? 'text--disabled' : ''"
          >Textura
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeTexturaEsq"
          :items="[
            'homogênea',
            'perda da homogeneidade',
            'rotura intracapsular',
          ]"
          v-model="laudoSelecionado.laudoJson.texturaEsq"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeTexturaEsq"
          @click="
            camposExibidos.exibeTexturaEsq = true;
            laudoSelecionado.laudoJson.texturaEsq = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeTexturaEsq"
          @click="
            camposExibidos.exibeTexturaEsq = false;
            laudoSelecionado.laudoJson.texturaEsq = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-start align-center"
        v-if="laudoSelecionado.laudoJson.temProteseEsq"
      >
        <span
          :class="!camposExibidos.exibeIntegridadeEsq ? 'text--disabled' : ''"
          >Integridade da superfície:
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeIntegridadeEsq"
          :items="['regular', 'descontinuidade do elastômero']"
          v-model="laudoSelecionado.laudoJson.integridadeDaSuperEsq"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeIntegridadeEsq"
          @click="
            camposExibidos.exibeIntegridadeEsq = true;
            laudoSelecionado.laudoJson.integridadeDaSuperEsq = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeIntegridadeEsq"
          @click="
            camposExibidos.exibeIntegridadeEsq = false;
            laudoSelecionado.laudoJson.integridadeDaSuperEsq = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-start align-center"
        v-if="laudoSelecionado.laudoJson.temProteseEsq"
      >
        <span
          :class="!camposExibidos.exibeMassaInternaEsq ? 'text--disabled' : ''"
          >Massa interna:
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeMassaInternaEsq"
          :items="['não', 'sim, medindo']"
          v-model="laudoSelecionado.laudoJson.massaInternaEsq"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <v-text-field
          v-if="laudoSelecionado.laudoJson.massaInternaEsq == 'sim, medindo'"
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibePresImgEsq"
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.massaInternaCmEsq"
        />
        <span
          v-if="laudoSelecionado.laudoJson.massaInternaEsq == 'sim, medindo'"
          >cm</span
        >.

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeMassaInternaEsq"
          @click="
            camposExibidos.exibeMassaInternaEsq = true;
            laudoSelecionado.laudoJson.massaInternaEsq = undefined;
            laudoSelecionado.laudoJson.massaInternaCmEsq = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeMassaInternaEsq"
          @click="
            camposExibidos.exibeMassaInternaEsq = false;
            laudoSelecionado.laudoJson.massaInternaEsq = undefined;
            laudoSelecionado.laudoJson.massaInternaCmEsq = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-start align-center"
        v-if="laudoSelecionado.laudoJson.temProteseEsq"
      >
        <span :class="!camposExibidos.exibeRotacaoEsq ? 'text--disabled' : ''"
          >Rotação
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeRotacaoEsq"
          :items="['não', 'sim']"
          v-model="laudoSelecionado.laudoJson.rotacaoEsq"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        .

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeRotacao"
          @click="
            camposExibidos.exibeRotacaoEsq = true;
            laudoSelecionado.laudoJson.rotacaoEsq = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeRotacao"
          @click="
            camposExibidos.exibeRotacaoEsq = false;
            laudoSelecionado.laudoJson.rotacaoEsq = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-start align-center"
        v-if="laudoSelecionado.laudoJson.temProteseEsq"
      >
        <v-textarea
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          filled
          outlined
          full-width
          no-resize
          label="Observações "
          v-model="laudoSelecionado.laudoJson.comentariosAdicionaisEsq"
        ></v-textarea>
      </v-col>
      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.temDilatacaoDuctos"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Dilatação de ductos"
          validate-on-blur
        ></v-checkbox>
      </v-col>
      <v-col
        v-if="laudoSelecionado.laudoJson.temDilatacaoDuctos"
        cols="12"
        class="d-flex justify-start align-center"
      >
      <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
         
          :items="[
            'Ausência',
            'Presença',
          ]"
          v-model="laudoSelecionado.laudoJson.presAusDilatacao"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <span class="mx-2 flex-grow-0">
          de dilatação ductal
        </span>
        <v-select
        v-if="laudoSelecionado.laudoJson.presAusDilatacao == 'Presença'"
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
         
          :items="[
              'no quadrante ínfero-lateral',
              'no quadrante ínfero-medial',
              'na junção dos quadrantes superiores',
              'na junção dos quadrantes inferiores',
              'na junção dos quadrantes externos',
              'na junção dos quadrantes mediais',
              'na região retroareolar',
            ]"
          v-model="laudoSelecionado.laudoJson.presAusDilatacao2"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <v-select
        v-if="laudoSelecionado.laudoJson.presAusDilatacao == 'Presença'"
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          
          :items="[
              'sem conteúdo espesso',
              'com conteúdo espesso',
              'com projeção sólida medindo ',
              
            ]"
          v-model="laudoSelecionado.laudoJson.presAusDilatacao3"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <v-text-field
          v-if="laudoSelecionado.laudoJson.presAusDilatacao3 == 'com projeção sólida medindo '"
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.presAusDilatacao4"
        />
        <span class="mx-2 flex-grow-0" v-if="laudoSelecionado.laudoJson.presAusDilatacao3 == 'com projeção sólida medindo '">
          cm.
        </span>
    
    </v-col>
    <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.temLinfonodo"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Linfonodo intramamário"
          validate-on-blur
        ></v-checkbox>
    </v-col>
    <v-col
        v-if="laudoSelecionado.laudoJson.temLinfonodo"
        cols="12"
        class="d-flex justify-start align-center"
      >
      <span>
        Presença de 
      </span>
      <v-text-field
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.presencaDeLinfonodoImgs"
        />
        <span class="mx-2 flex-grow-0" v-if="laudoSelecionado.laudoJson.presencaDeLinfonodoImgs > 1">
          imagens hipoecóicas com centro hipoecóico, localizadas
        </span>
        <span class="mx-2 flex-grow-0" v-else>
          imagem hipoecóica com centro hipoecóico, localizada
        </span>
      
      <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          
          :items="[
            'no quadrante ínfero-lateral',
            'no quadrante ínfero-medial',
            'na junção dos quadrantes superiores',
            'na junção dos quadrantes inferiores',
            'na junção dos quadrantes externos',
            'na junção dos quadrantes interno',
            'na região retroareolar',


          ]"
          v-model="laudoSelecionado.laudoJson.presencaDeLinfonodo"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <span v-if="laudoSelecionado.laudoJson.presencaDeLinfonodo == 'na região retroareolar'">
          , medindo
        </span>
        <v-text-field
          v-if="laudoSelecionado.laudoJson.presencaDeLinfonodo == 'na região retroareolar'"
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.presencaDeLinfonodoMed"
        />
        <span v-if="laudoSelecionado.laudoJson.presencaDeLinfonodo == 'na região retroareolar'">
          cm.
        </span>
    
    </v-col>

      <h3>Mama esquerda:</h3>

      <v-col cols="12" class="d-flex justify-start align-center">
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.mamaDireitaPrevia2"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Mastectomia total prévia sem evidências de nódulos."
          validate-on-blur
        ></v-checkbox>
      </v-col>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia2"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <span
          :class="!camposExibidos.exibePeleSubcutaneo ? 'text--disabled' : ''"
          >Pele e subcutâneo
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibePeleSubcutaneo"
          :items="[
            ' plana, sem abaulamentos ou retrações. Espessura normal',
            'presença de edema ',
            'presença de retração ',
            'presença de cisto ',
            'outros',
          ]"
          v-model="laudoSelecionado.laudoJson.peleSubcutaneo"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>

        <span
        v-if="
            [
              'presença de edema ',
              'presença de retração ',
              'presença de cisto ',
              'outros',
            ].includes(laudoSelecionado.laudoJson.peleSubcutaneo)
          "
           class="mx-2 flex-grow-0"
        >
          medindo 
        </span>
        <v-text-field
          v-if="
            [
              'presença de edema ',
              'presença de retração ',
              'presença de cisto ',
              'outros',
            ].includes(laudoSelecionado.laudoJson.peleSubcutaneo)
          "
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibePeleSubcutaneo"
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.peleSubcutaneo2"
        />
        <span
         class="mx-2 flex-grow-0"
        v-if="
            [
              'presença de edema ',
              'presença de retração ',
              'presença de cisto ',
              'outros',
            ].includes(laudoSelecionado.laudoJson.peleSubcutaneo)
          "
        >
          cm.
        </span>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibePeleSubcutaneo"
          @click="
            camposExibidos.exibePeleSubcutaneo = true;
            laudoSelecionado.laudoJson.peleSubcutaneo = undefined;
            laudoSelecionado.laudoJson.peleSubcutaneo2 = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibePeleSubcutaneo"
          @click="
            camposExibidos.exibePeleSubcutaneo = false;
            laudoSelecionado.laudoJson.peleSubcutaneo = undefined;
            laudoSelecionado.laudoJson.peleSubcutaneo2 = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia2"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <span
          :class="!camposExibidos.exibeAreolaePapila ? 'text--disabled' : ''"
          >Aréola e papila
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeAreolaePapila"
          :items="[
            'sem alterações ecográficas evidentes',
            'presença de edema',
            'presença de retração',
            'presença de cisto',
            'outros',
          ]"
          v-model="laudoSelecionado.laudoJson.areolaPapila"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <span v-if="
            [
              'presença de edema',
              'presença de retração',
              'presença de cisto',
              'outros',
            ].includes(laudoSelecionado.laudoJson.areolaPapila)
          "> medindo </span>
        <v-text-field
          v-if="
            [
              'presença de edema',
              'presença de retração',
              'presença de cisto',
              'outros',
            ].includes(laudoSelecionado.laudoJson.areolaPapila)
          "
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeAreolaePapila"
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.areolaPapila2"
        />
        <span v-if="
            [
              'presença de edema',
              'presença de retração',
              'presença de cisto',
              'outros',
            ].includes(laudoSelecionado.laudoJson.areolaPapila)
          "> cm. </span>
        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeAreolaePapila"
          @click="
            camposExibidos.exibeAreolaePapila = true;
            laudoSelecionado.laudoJson.areolaPapila = undefined;
            laudoSelecionado.laudoJson.areolaPapila2 = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeAreolaePapila"
          @click="
            camposExibidos.exibeAreolaePapila = false;
            laudoSelecionado.laudoJson.areolaPapila = undefined;
            laudoSelecionado.laudoJson.areolaPapila2 = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia2"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <span
          :class="
            !camposExibidos.exibeComposicaoMamaria ? 'text--disabled' : ''
          "
          >Composição mamária
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeComposicaoMamaria"
          :items="[
            'homogênea adiposa',
            'homogênea fibroglandular ',
            'predominantemente ecogênico, com tecido gorduroso de permeio',
            'predominantemente adiposo, com tecido fibroglandular de permeio'
          ]"
          v-model="laudoSelecionado.laudoJson.composicaoMamaria"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeComposicaoMamaria"
          @click="
            camposExibidos.exibeComposicaoMamaria = true;
            laudoSelecionado.laudoJson.composicaoMamaria = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeComposicaoMamaria"
          @click="
            camposExibidos.exibeComposicaoMamaria = false;
            laudoSelecionado.laudoJson.composicaoMamaria = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia2"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.mamaDireitaPrevia1"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Ausência de imagens nodulares de etiologia sólida ou cística."
          validate-on-blur
        ></v-checkbox>
      </v-col>
      <template v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia1">
        <v-col
      
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.noduloDir"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Nódulo sólido"
          validate-on-blur
        ></v-checkbox>
      </v-col>
        <v-col
          v-if="laudoSelecionado.laudoJson.noduloDir"
          cols="12"
          :class="`d-flex justify-start align-center ${
            camposExibidos.exPresenca ? '' : 'text--disabled'
          }`"
        >
          <span>Presença de</span>
          <v-text-field
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exPresenca"
            required
            class="mx-2 flex-grow-0"
            dense
            validate-on-blur
            @change="criaListaFor(laudoSelecionado.laudoJson.presenca)"
            v-model="laudoSelecionado.laudoJson.presenca"
          >
          </v-text-field>
          <!-- <v-select
          :items="['miometrial', 'miometriais']"
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          validate-on-blur
          class="mx-2 flex-grow-0"
          dense
          :disabled="!camposExibidos.exDistImg"
          v-model="laudoSelecionado.laudoJson.presencaOpc"
        >
        </v-select> -->

          <span
            >{{
              laudoSelecionado.laudoJson.presenca > 1
                ? 'nódulos, assim caracterizados:'
                : 'nódulo, assim caracterizado:'
            }}
          </span>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exPresenca"
            @click="
              camposExibidos.exPresenca = true;
              laudoSelecionado.laudoJson.presenca = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-else
            @click="
              camposExibidos.exPresenca = false;
              laudoSelecionado.laudoJson.presenca = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>

        <v-row
          class="ma-0"
          :key="index"
          v-for="(n, index) in laudoSelecionado.laudoJson.listaPresencas"
        >
          <v-col cols="12" :class="`d-flex justify-start align-center `">
            <span
              :class="`${
                camposExibidos.exDistImgEsq[index] ? '' : 'text--disabled'
              }`"
              >&#149; N{{ index + 1 }}:</span
            >

            <v-select
              :items="['oval, ', 'redondo, ', 'irregular, ']"
              required
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              class="mx-2 flex-grow-0"
              dense
              :disabled="!camposExibidos.exDistImgEsq[index]"
              v-model="n.presenca.opc1"
            >
            </v-select>

            <v-select
              :items="[
                'margem circunscrita, ',
                'não circunscrita indistinta, ',
                'não circunscrita espiculada, ',
                'não circunscrita microlobulada, ',
                'não circunscrita angulada, ',
                'não circunscrita obscurecida, ',
              ]"
              :disabled="!camposExibidos.exDistImgEsq[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="n.presenca.opc2"
            >
            </v-select>
            <!-- <span :class="`${
          camposExibidos.exDistImg[index] ? '' : 'text--disabled'
        }`"
        >medindo</span> -->
            <v-select
              :items="[
                'paralelo, ',
                'não paralelo à pele, ',
                ' paralelo à pele, ',
              ]"
              :disabled="!camposExibidos.exDistImgEsq[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="n.presenca.opc3"
            ></v-select>
            <v-select
              :items="[
                'anecóico, ',
                'hiperecóico, ',
                'hipoecóico, ',
                'complexo cisto - sólido, ',
                'isoecóico, ',
                'heterogêneo, ',
              ]"
              :disabled="!camposExibidos.exDistImgEsq[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="n.presenca.opc4"
            >
            </v-select>

            <v-select
              :items="[
                'sem efeitos sonoros, ',
                'com reforço acústico, ',
                'com sombra acústica, ',
              ]"
              :disabled="!camposExibidos.exDistImgEsq[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="n.presenca.opc5"
            >
            </v-select>
          </v-col>

          <v-col cols="12" :class="`d-flex justify-start align-center `">
            <span
              :class="
                !camposExibidos.exDistImgEsq[index] ? 'text--disabled' : ''
              "
              >medindo
            </span>
            <v-text-field
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exDistImgEsq[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              validate-on-blur
              v-model="n.presenca.medida1Esq"
            >
            </v-text-field>
            <span :class="!camposExibidos.exibeMedidas ? 'text--disabled' : ''">
              x
            </span>
            <v-text-field
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exDistImgEsq[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              validate-on-blur
              v-model="n.presenca.medida2Esq"
            >
            </v-text-field>
            <span
              :class="
                !camposExibidos.exDistImgEsq[index] ? 'text--disabled' : ''
              "
            >
              x
            </span>
            <v-text-field
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exDistImgEsq[index]"
              required
              class="mx-2 flex-grow-0"
              width="30px"
              dense
              validate-on-blur
              v-model="n.presenca.medida3Esq"
            >
            </v-text-field>
            <span
              :class="
                !camposExibidos.exDistImgEsq[index] ? 'text--disabled' : ''
              "
            >
              cm,
            </span>
            <v-btn
                dense
                depressed
                text
                icon
                v-if="!camposExibidos.exDistImgEsq[index]"
                @click="
                  camposExibidos.exDistImgEsq[index] = true;
                  
                  n.presenca.opc1 = undefined;
                  n.presenca.opc2 = undefined;
                  n.presenca.opc3 = undefined;
                  n.presenca.opc4 = undefined;
                  n.presenca.opc5 = undefined;
                  n.presenca.medida1Esq = undefined;
                  n.presenca.medida2Esq = undefined;
                  n.presenca.medida3Esq = undefined;

                  $forceUpdate();
                "
                ><v-icon>mdi-eye-off</v-icon></v-btn
              >
              <v-btn
                dense
                depressed
                text
                icon
                v-else
                @click="
                  camposExibidos.exDistImgEsq[index] = false;
                  n.presenca.opc1 = undefined;
                  n.presenca.opc2 = undefined;
                  n.presenca.opc3 = undefined;
                  n.presenca.opc4 = undefined;
                  n.presenca.opc5 = undefined;
                  n.presenca.medida1Esq = undefined;
                  n.presenca.medida2Esq = undefined;
                  n.presenca.medida3Esq = undefined;

                  $forceUpdate();
                "
                ><v-icon>mdi-eye</v-icon></v-btn
              >
            <span
              :class="!camposExibidos.exPeleEsq[index] ? 'text--disabled' : ''"
            >
              &nbsp; distando
            </span>
            <v-text-field
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exPeleEsq[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              validate-on-blur
              v-model="n.presenca.medidaCmPeleEsq"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" :class="`d-flex justify-start align-center `">
            <span
              :class="!camposExibidos.exPeleEsq[index] ? 'text--disabled' : ''"
            >
              cm da pele
            </span>
            <span
              :class="!camposExibidos.exPeleEsq[index] ? 'text--disabled' : ''"
            >
              &nbsp;e
            </span>
            <v-btn
              dense
              depressed
              text
              icon
              v-if="!camposExibidos.exPeleEsq[index]"
              @click="
                camposExibidos.exPeleEsq[index] = true;
                n.presenca.medidaCmPeleEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              text
              icon
              v-else
              @click="
                camposExibidos.exPeleEsq[index] = false;
                n.presenca.medidaCmPeleEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye</v-icon></v-btn
            >

            <v-text-field
              v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exAureolaEsq[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              validate-on-blur
              v-model="n.presenca.medidaCmAreolaEsq"
            >
            </v-text-field>
            <span
              :class="
                !camposExibidos.exAureolaEsq[index] ? 'text--disabled' : ''
              "
            >
              cm da aréola
            </span>
            <v-btn
              dense
              depressed
              text
              icon
              v-if="!camposExibidos.exAureolaEsq[index]"
              @click="
                camposExibidos.exAureolaEsq[index] = true;
                n.presenca.medidaCmPeleEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              text
              icon
              v-else
              @click="
                camposExibidos.exAureolaEsq[index] = false;
                n.presenca.medidaCmAreolaEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye</v-icon></v-btn
            >

            <span
              :class="
                !camposExibidos.exDistImgEsq[index] ? 'text--disabled' : ''
              "
            >
              , localizado  
            </span>
            <v-select
              :readonly="carregamentoDoLaudo || !podeEditar"
              required
              validate-on-blur
              :disabled="!camposExibidos.exDistImgEsq[index]"
              :items="[
                'no quadrante supero-lateral',
                'no quadrante supero-medial',
                'no quadrante ínfero-lateral',
                'no quadrante ínfero-medial',
                'na junção dos quadrantes superiores',
                'na junção dos quadrantes inferiores',
                'na junção dos quadrantes laterais',
                'na junção dos quadrantes mediais',
                'na região retroareolar',
              ]"
              v-model="n.presenca.locPeleAreoEsq"
              :clearable="podeEditar"
              class="mx-2 flex-grow-0"
              dense
            ></v-select>
            <span
              :class="
                !camposExibidos.exDistImgHoraEsq[index] ? 'text--disabled' : ''
              "
            >
              {{ n.presenca.horaAreolaEsq > 1 ? 'as ' : 'a' }}
            </span>
            <v-text-field
              v-mask="''"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              :disabled="!camposExibidos.exDistImgHoraEsq[index]"
              required
              class="mx-2 flex-grow-0"
              dense
              validate-on-blur
              v-model="n.presenca.horaAreolaEsq"
            >
            </v-text-field>
            <span
              :class="
                !camposExibidos.exDistImgHoraEsq[index] ? 'text--disabled' : ''
              "
            >
              {{ n.presenca.horaAreolaEsq > 1 ? ' horas ' : ' hora ' }}.
            </span>

            <v-btn
              dense
              depressed
              text
              icon
              v-if="!camposExibidos.exDistImgHoraEsq[index]"
              @click="
                camposExibidos.exDistImgHoraEsq[index] = true;
               
              
                n.presenca.horaAreolaEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye-off</v-icon></v-btn
            >
            <v-btn
              dense
              depressed
              text
              icon
              v-else
              @click="
                camposExibidos.exDistImgHoraEsq[index] = false;
                
                
                n.presenca.horaAreolaEsq = undefined;

                $forceUpdate();
              "
              ><v-icon>mdi-eye</v-icon></v-btn
            >
          </v-col>
        </v-row>

        <!-- <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia2"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <span :class="!camposExibidos.exibeMedidas ? 'text--disabled' : ''"
          >medindo
        </span>

        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidas"
          required
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.medida1"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidas ? 'text--disabled' : ''">
          x
        </span>
        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidas"
          required
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.medida2"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidas ? 'text--disabled' : ''">
          x
        </span>
        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidas"
          required
          class="mx-2 flex-grow-0"
          width="30px"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.medida3"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidas ? 'text--disabled' : ''">
          cm, distando
        </span>
        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidas"
          required
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.medidaCmPele"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidas ? 'text--disabled' : ''">
          cm da pele e
        </span>
      </v-col>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia2"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidas"
          required
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.medidaCmAreola"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidas ? 'text--disabled' : ''">
          cm da aréola, localizada
        </span>
        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required'
          validate-on-blur
          :disabled="!camposExibidos.exibeMedidas"
          :items="[
            'no quadrante supero-lateral',
            'no quadrante supero-medial',
            'no quadrante ínfero-lateral',
            'no quadrante ínfero-medial',
            'na junção dos quadrantes superiores',
            'na junção dos quadrantes inferiores',
            'na junção dos quadrantes laterais',
            'na junção dos quadrantes mediais',
            'na região retroareolar',
          ]"
          v-model="laudoSelecionado.laudoJson.locPeleAreo"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <span :class="!camposExibidos.exibeMedidas ? 'text--disabled' : ''">
          {{ laudoSelecionado.laudoJson.horaAreola > 1 ? 'as ' : 'a' }}
        </span>
        <v-text-field
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMedidas"
          required
          class="mx-2 flex-grow-0"
          dense
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.horaAreola"
        >
        </v-text-field>
        <span :class="!camposExibidos.exibeMedidas ? 'text--disabled' : ''">
          {{
            laudoSelecionado.laudoJson.horaAreola > 1 ? ' horas ' : ' hora '
          }}.
        </span>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeMedidas"
          @click="
            camposExibidos.exibeMedidas = true;
            laudoSelecionado.laudoJson.medida1 = undefined;
            laudoSelecionado.laudoJson.medida2 = undefined;
            laudoSelecionado.laudoJson.medida3 = undefined;
            laudoSelecionado.laudoJson.medidaCmPele = undefined;
            laudoSelecionado.laudoJson.medidaCmAreola = undefined;
            laudoSelecionado.laudoJson.locPeleAreo = undefined;
            laudoSelecionado.laudoJson.horaAreola = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeMedidas"
          @click="
            camposExibidos.exibeMedidas = false;
            laudoSelecionado.laudoJson.medida1 = undefined;
            laudoSelecionado.laudoJson.medida2 = undefined;
            laudoSelecionado.laudoJson.medida3 = undefined;
            laudoSelecionado.laudoJson.medidaCmPele = undefined;
            laudoSelecionado.laudoJson.medidaCmAreola = undefined;
            laudoSelecionado.laudoJson.locPeleAreo = undefined;
            laudoSelecionado.laudoJson.horaAreola = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->
      <v-col
       
       cols="12"
       class="d-flex justify-start align-center"
     >
       <v-checkbox
         :readonly="carregamentoDoLaudo || !podeEditar"
         v-model="laudoSelecionado.laudoJson.cistoIsolado2"
         class="d-flex justify-content-start align-items-center mt-n1"
         label="Cisto isolado"
         validate-on-blur
       ></v-checkbox>
     </v-col>

        <v-col
          v-if="laudoSelecionado.laudoJson.cistoIsolado2"
          cols="12"
          class="d-flex justify-start align-center"
        >
          <span :class="!camposExibidos.exibePresImg ? 'text--disabled' : ''"
            >Presença de imagem anecóica medindo
          </span>

          <v-text-field
            class="mx-2 flex-grow-0"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exibePresImg"
            validate-on-blur
            required
            dense
            v-model="laudoSelecionado.laudoJson.presImgcm"
          />
          <span :class="!camposExibidos.exibePresImg ? 'text--disabled' : ''">
            cm, localizada
          </span>

          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibePresImg"
            :items="[
              'no quadrante supero-lateral',
              'no quadrante supero-medial',
              'no quadrante ínfero-lateral',
              'no quadrante ínfero-medial',
              'na junção dos quadrantes superiores',
              'na junção dos quadrantes inferiores',
              'na junção dos quadrantes laterais',
              'na junção dos quadrantes mediais',
              'na região retroareolar',
            ]"
            v-model="laudoSelecionado.laudoJson.locPeleAreoEsq2"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>
          <span>, </span>

          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibePresImg"
            :items="[
              'sem conteúdo espesso',
              'com conteúdo espesso',
              'com área sólida medindo',
            ]"
            v-model="laudoSelecionado.laudoJson.presImgOp"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>
          <v-text-field
            v-if="
              laudoSelecionado.laudoJson.presImgOp == 'com área sólida medindo '
            "
            class="mx-2 flex-grow-0"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exibePresImg"
            validate-on-blur
            required
            dense
            v-model="laudoSelecionado.laudoJson.presImgOpOutros"
          />
          <span
            v-if="
              laudoSelecionado.laudoJson.presImgOp == 'com área sólida medindo '
            "
            :class="!camposExibidos.exibePresImg ? 'text--disabled' : ''"
          >
            cm.</span
          >
          <span
            v-else
            :class="!camposExibidos.exibePresImg ? 'text--disabled' : ''"
          >
            .</span
          >

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibePresImg"
            @click="
              camposExibidos.exibePresImg = true;
              laudoSelecionado.laudoJson.presImgcm = undefined;
              laudoSelecionado.laudoJson.presImgOp = undefined;
              laudoSelecionado.laudoJson.presImgOpOutros = undefined;
              laudoSelecionado.laudoJson.locPeleAreoEsq2 = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exibePresImg"
            @click="
              camposExibidos.exibePresImg = false;
              laudoSelecionado.laudoJson.presImgcm = undefined;
              laudoSelecionado.laudoJson.presImgOp = undefined;
              laudoSelecionado.laudoJson.presImgOpOutros = undefined;
              laudoSelecionado.laudoJson.locPeleAreoEsq2 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col
       
       cols="12"
       class="d-flex justify-start align-center"
     >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.conglomeradoDeCistos2"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Conglomerado de cistos"
          validate-on-blur
        ></v-checkbox>
      </v-col>

        <v-col
          v-if="laudoSelecionado.laudoJson.conglomeradoDeCistos2"
          cols="12"
          class="d-flex justify-start align-center"
        >
          <span
            :class="!camposExibidos.exibeCongDeCistos ? 'text--disabled' : ''"
            >Conglomerado de cistos medindo
          </span>

          <v-text-field
            class="mx-2 flex-grow-0"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exibeCongDeCistos"
            validate-on-blur
            required
            dense
            v-model="laudoSelecionado.laudoJson.CongCistoCm"
          />
          <span
            :class="!camposExibidos.exibeCongDeCistos ? 'text--disabled' : ''"
          >
            cm, localizado
          </span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeCongDeCistos"
            :items="[
              'no quadrante supero-lateral',
              'no quadrante supero-medial',
              'no quadrante ínfero-lateral',
              'no quadrante ínfero-medial',
              'na junção dos quadrantes superiores',
              'na junção dos quadrantes inferiores',
              'na junção dos quadrantes externos',
              'na junção dos quadrantes mediais',
              'na região retroareolar',
              'sem conteúdo espesso',
              'Alguns com conteúdo espesso',
            ]"
            v-model="laudoSelecionado.laudoJson.CongCistoOpc"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeCongDeCistos"
            @click="
              camposExibidos.exibeCongDeCistos = true;
              laudoSelecionado.laudoJson.CongCistoCm = undefined;
              laudoSelecionado.laudoJson.CongCistoOpc = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exibeCongDeCistos"
            @click="
              camposExibidos.exibeCongDeCistos = false;
              laudoSelecionado.laudoJson.CongCistoCm = undefined;
              laudoSelecionado.laudoJson.CongCistoOpc = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
        <v-col
       
       cols="12"
       class="d-flex justify-start align-center"
     >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.multiplosCistos2"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Múltiplos cistos"
          validate-on-blur
        ></v-checkbox>
      </v-col>

        <v-col
          v-if="laudoSelecionado.laudoJson.multiplosCistos2"
          cols="12"
          class="d-flex justify-start align-center"
        >
          <span :class="!camposExibidos.exibeMultiplas ? 'text--disabled' : ''"
            > Imagens anecóicas, com a maior medindo
          </span>

          <v-text-field
            class="mx-2 flex-grow-0"
            v-mask="''"
            :readonly="carregamentoDoLaudo || !podeEditar"
            :clearable="podeEditar"
            :disabled="!camposExibidos.exibeMultiplas"
            validate-on-blur
            required
            dense
            v-model="laudoSelecionado.laudoJson.mutlImgcm"
          />
          <span :class="!camposExibidos.exibeMultiplas ? 'text--disabled' : ''">
            cm, localizada
          </span>
          <v-select
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            :disabled="!camposExibidos.exibeMultiplas"
            :items="[
              'no quadrante supero-lateral',
              'no quadrante supero-medial',
              'no quadrante ínfero-lateral',
              'no quadrante ínfero-medial',
              'na junção dos quadrantes superiores',
              'na junção dos quadrantes inferiores',
              'na junção dos quadrantes externos',
              'na junção dos quadrantes mediais',
              'na região retroareolar',
              'sem conteúdo espesso',
              'Alguns com conteúdo espesso',
            ]"
            v-model="laudoSelecionado.laudoJson.mutlImgOpc"
            :clearable="podeEditar"
            class="mx-2 flex-grow-0"
            dense
          ></v-select>

          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exibeMultiplas"
            @click="
              camposExibidos.exibeMultiplas = true;
              laudoSelecionado.laudoJson.mutlImgcm = undefined;
              laudoSelecionado.laudoJson.mutlImgOpc = undefined;
            "
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exibeMultiplas"
            @click="
              camposExibidos.exibeMultiplas = false;
              laudoSelecionado.laudoJson.mutlImgcm = undefined;
              laudoSelecionado.laudoJson.mutlImgOpc = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-col>
      </template>

      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia2"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.temProtese"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Prótese"
          validate-on-blur
        ></v-checkbox>
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-start align-center"
        v-if="laudoSelecionado.laudoJson.temProtese"
      >
        <span :class="!camposExibidos.exibeTextura ? 'text--disabled' : ''"
          >Textura
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeTextura"
          :items="[
            'homogênea',
            'perda da homogeneidade',
            'rotura intracapsular',
          ]"
          v-model="laudoSelecionado.laudoJson.textura"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeTextura"
          @click="
            camposExibidos.exibeTextura = true;
            laudoSelecionado.laudoJson.textura = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeTextura"
          @click="
            camposExibidos.exibeTextura = false;
            laudoSelecionado.laudoJson.textura = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>
     

      <v-col
        cols="12"
        class="d-flex justify-start align-center"
        v-if="laudoSelecionado.laudoJson.temProtese"
      >
        <span :class="!camposExibidos.exibeIntegridade ? 'text--disabled' : ''"
          >Integridade da superfície:
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeIntegridade"
          :items="['regular', 'descontinuidade do elastômero']"
          v-model="laudoSelecionado.laudoJson.integridadeDaSuper"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeIntegridade"
          @click="
            camposExibidos.exibeIntegridade = true;
            laudoSelecionado.laudoJson.integridadeDaSuper = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeIntegridade"
          @click="
            camposExibidos.exibeIntegridade = false;
            laudoSelecionado.laudoJson.integridadeDaSuper = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-start align-center"
        v-if="laudoSelecionado.laudoJson.temProtese"
      >
        <span :class="!camposExibidos.exibeMassaInterna ? 'text--disabled' : ''"
          >Massa interna:
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeMassaInterna"
          :items="['não', 'sim, medindo']"
          v-model="laudoSelecionado.laudoJson.massaInterna"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <v-text-field
          v-if="laudoSelecionado.laudoJson.massaInterna == 'sim, medindo'"
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          :disabled="!camposExibidos.exibeMassaInterna"
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.massaInternaCm"
        />
        <span v-if="laudoSelecionado.laudoJson.massaInterna == 'sim, medindo'"
          >cm</span
        >.

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeMassaInterna"
          @click="
            camposExibidos.exibeMassaInterna = true;
            laudoSelecionado.laudoJson.massaInterna = undefined;
            laudoSelecionado.laudoJson.massaInternaCm = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeMassaInterna"
          @click="
            camposExibidos.exibeMassaInterna = false;
            laudoSelecionado.laudoJson.massaInterna = undefined;
            laudoSelecionado.laudoJson.massaInternaCm = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-start align-center"
        v-if="laudoSelecionado.laudoJson.temProtese"
      >
        <span :class="!camposExibidos.exibeRotacao ? 'text--disabled' : ''"
          >Rotação
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeRotacao"
          :items="['não', 'sim']"
          v-model="laudoSelecionado.laudoJson.rotacao"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        .

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeRotacao"
          @click="
            camposExibidos.exibeRotacao = true;
            laudoSelecionado.laudoJson.rotacao = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeRotacao"
          @click="
            camposExibidos.exibeRotacao = false;
            laudoSelecionado.laudoJson.rotacao = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-start align-center"
        v-if="laudoSelecionado.laudoJson.temProtese"
      >
        <v-textarea
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          filled
          outlined
          full-width
          no-resize
          label="Observações "
          v-model="laudoSelecionado.laudoJson.comentariosAdicionais"
        ></v-textarea>
      </v-col>

      <!-- <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia2"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <span :class="!camposExibidos.exibeAxila ? 'text--disabled' : ''"
          >Axila
        </span>

        <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          :disabled="!camposExibidos.exibeAxila"
          :items="[
            'Presença de formações ovaladas, hipoecogênicas, com hilos hiperecogênicos, sugestivas de linfonodos de aspecto e dimensões dentro da normalidade',
            'livre',
          ]"
          v-model="laudoSelecionado.laudoJson.axila"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>

        <v-btn
          dense
          depressed
          text
          icon
          v-if="!camposExibidos.exibeAxila"
          @click="
            camposExibidos.exibeAxila = true;
            laudoSelecionado.laudoJson.axila = undefined;
          "
          ><v-icon>mdi-eye-off</v-icon></v-btn
        >
        <v-btn
          dense
          depressed
          text
          icon
          v-if="camposExibidos.exibeAxila"
          @click="
            camposExibidos.exibeAxila = false;
            laudoSelecionado.laudoJson.axila = undefined;
          "
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </v-col> -->
      <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.temDilatacaoDuctosB"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Dilatação de ductos"
          validate-on-blur
        ></v-checkbox>
    </v-col>
      <v-col
        v-if="laudoSelecionado.laudoJson.temDilatacaoDuctosB"
        cols="12"
        class="d-flex justify-start align-center"
      >
      <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
         
          :items="[
            'Ausência',
            'Presença',
          ]"
          v-model="laudoSelecionado.laudoJson.presAusDilatacaoB"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <span class="mx-2 flex-grow-0">
          de dilatação ductal
        </span>
        <v-select
        v-if="laudoSelecionado.laudoJson.presAusDilatacaoB == 'Presença'"
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
         
          :items="[
              'no quadrante ínfero-lateral',
              'no quadrante ínfero-medial',
              'na junção dos quadrantes superiores',
              'na junção dos quadrantes inferiores',
              'na junção dos quadrantes externos',
              'na junção dos quadrantes mediais',
              'na região retroareolar',
            ]"
          v-model="laudoSelecionado.laudoJson.presAusDilatacao2B"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <v-select
        v-if="laudoSelecionado.laudoJson.presAusDilatacaoB == 'Presença'"
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          
          :items="[
              'sem conteúdo espesso',
              'com conteúdo espesso',
              'com projeção sólida medindo ',
              
            ]"
          v-model="laudoSelecionado.laudoJson.presAusDilatacao3B"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <v-text-field
          v-if="laudoSelecionado.laudoJson.presAusDilatacao3B == 'com projeção sólida medindo '"
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.presAusDilatacao4B"
        />
        <span class="mx-2 flex-grow-0" v-if="laudoSelecionado.laudoJson.presAusDilatacao3B == 'com projeção sólida medindo '">
          cm.
        </span>
    
    </v-col>
    <v-col
        v-if="!laudoSelecionado.laudoJson.mamaDireitaPrevia0"
        cols="12"
        class="d-flex justify-start align-center"
      >
        <v-checkbox
          :readonly="carregamentoDoLaudo || !podeEditar"
          v-model="laudoSelecionado.laudoJson.temLinfonodoB"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Linfonodo intramamário"
          validate-on-blur
        ></v-checkbox>
    </v-col>
    <v-col
        v-if="laudoSelecionado.laudoJson.temLinfonodoB"
        cols="12"
        class="d-flex justify-start align-center"
      >
      <span>
        Presença de 
      </span>
      <v-text-field
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.presencaDeLinfonodoImgsB"
        />
        <span class="mx-2 flex-grow-0" v-if="laudoSelecionado.laudoJson.presencaDeLinfonodoImgsB > 1">
          imagens hipoecóicas com centro hipoecóico, localizadas
        </span>
        <span class="mx-2 flex-grow-0" v-else>
          imagem hipoecóica com centro hipoecóico, localizada
        </span>
      
      <v-select
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          
          :items="[
            'no quadrante ínfero-lateral',
            'no quadrante ínfero-medial',
            'na junção dos quadrantes superiores',
            'na junção dos quadrantes inferiores',
            'na junção dos quadrantes externos',
            'na junção dos quadrantes interno',
            'na região retroareolar',


          ]"
          v-model="laudoSelecionado.laudoJson.presencaDeLinfonodoB"
          :clearable="podeEditar"
          class="mx-2 flex-grow-0"
          dense
        ></v-select>
        <span v-if="laudoSelecionado.laudoJson.presencaDeLinfonodoB == 'na região retroareolar'">
          , medindo
        </span>
        <v-text-field
          v-if="laudoSelecionado.laudoJson.presencaDeLinfonodoB == 'na região retroareolar'"
          class="mx-2 flex-grow-0"
          v-mask="''"
          :readonly="carregamentoDoLaudo || !podeEditar"
          :clearable="podeEditar"
          
          validate-on-blur
          required
          dense
          v-model="laudoSelecionado.laudoJson.presencaDeLinfonodoMedB"
        />
        <span v-if="laudoSelecionado.laudoJson.presencaDeLinfonodoB == 'na região retroareolar'">
          cm.
        </span>
    
    </v-col>
    
        <v-row class="align-center px-10 pt-3 mb-3">
          <h3>Axila direita:</h3>
        </v-row>

        <v-checkbox
          v-model="laudoSelecionado.laudoJson.axilaDireitaLivre1"
          :readonly="carregamentoDoLaudo || !podeEditar"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Prolongamento axilar livre de linfonodomegalias suspeitas."
          validate-on-blur
        ></v-checkbox>

        <div class="d-flex justify-content-start align-items-center mt-n5">
          <v-checkbox
            label="Presença de"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.checkAxilaDireita"
            validate-on-blur
          />
          <v-text-field
            v-model="laudoSelecionado.laudoJson.checkAxilaDireita33"
            :disabled="!laudoSelecionado.laudoJson.checkAxilaDireita"
            style="width: 1rem"
          />
          <span class="align-self-center"
            >linfonodos de aspecto habitual medindo</span
          >
          <v-text-field
            v-model="laudoSelecionado.laudoJson.checkAxilaDireita34"
            :disabled="!laudoSelecionado.laudoJson.checkAxilaDireita"
            style="width: 1rem"
          />
          <span class="align-self-center flex-grow-1">cm.</span>
        </div>

        <v-textarea
          required
          :readonly="carregamentoDoLaudo || !podeEditar"
          validate-on-blur
          filled
          outlined
          no-resize
          v-model="laudoSelecionado.laudoJson.comentariosAdcionaisMedio22"
          label="Comentários adicionais:"
        >
        </v-textarea>

        <v-col class="align-center px-10 pt-3 mb-3">
          <h3>Axila esquerda:</h3>
        </v-col>
        <v-checkbox
          v-model="laudoSelecionado.laudoJson.axilaEsquerdaLivre1"
          :readonly="carregamentoDoLaudo || !podeEditar"
          class="d-flex justify-content-start align-items-center mt-n1"
          label="Prolongamento axilar livre de linfonodomegalias suspeitas."
          validate-on-blur
        ></v-checkbox>

        <div class="d-flex justify-content-start align-items-center mt-n5">
          <v-checkbox
            label="Presença de"
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.checkAxilaEsquerda"
            validate-on-blur
          />
          <v-text-field
            v-model="laudoSelecionado.laudoJson.checkAxilaEsquerda33"
            :disabled="!laudoSelecionado.laudoJson.checkAxilaEsquerda"
            style="width: 1rem"
          />
          <span class="align-self-center"
            >linfonodos de aspecto habitual medindo</span
          >
          <v-text-field
            v-model="laudoSelecionado.laudoJson.checkAxilaEsquerda34"
            :disabled="!laudoSelecionado.laudoJson.checkAxilaEsquerda"
            style="width: 1rem"
          />
          <span class="align-self-center flex-grow-1">cm.</span>
        </div>

        <v-textarea
          v-model="laudoSelecionado.laudoJson.comentariosAdcionaisMeio3"
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          validate-on-blur
          filled
          outlined
          no-resize
          label="Comentários adicionais:"
       />
       

        <h3>IMPRESSÃO DIAGNOSTICA:</h3>
        
        <div>
          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.temMama"
              class="d-flex justify-content-start align-items-center mt-n1"
              label="Mama"
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag1"
              class="d-flex justify-content-start align-items-center mt-n1"
              label="Ultrassonografia mamária dentro dos padrões da normalidade.
    "
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama"  >
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag2"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Nódulos sólidos bilaterais."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="10" md="3" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag3"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Nódulo sólido na mama direita."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <!-- <v-col
              cols="12"
              class="d-flex justify-start align-center">
                    <v-checkbox
                    :readonly="carregamentoDoLaudo || !podeEditar"
                    v-model="laudoSelecionado.laudoJson.impDiag4"
                    class="d-flex justify-content-start align-items-center mt-n1"
                    label="Nódulo sólido na mama direita."
                    validate-on-blur
                  ></v-checkbox>

              </v-col> -->
          <v-col cols="12" md="3" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag5"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Nódulo sólido na mama esquerda."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag6"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Cistos mamários."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag7"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Cistos na mama direita."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag8"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Cistos na mama esquerda."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiagN1"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Cisto na mama direita."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiagN2"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Cisto na mama esquerda."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiagN3"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Ectasia ductal sem conteúdo sólido."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiagN4"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Ectasia ductal com conteúdo sólido (papiloma?)."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiagN5"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Linfonodo intra-mamário à direita."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiagN6"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Linfonodo intra-mamário à esquerda."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiagN7"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Mastectomia prévia à esquerda."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temMama">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiagN8"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Mastectomia prévia à direita."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          
        <br>



          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.temProteseIMP"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Prótese"
              validate-on-blur
            ></v-checkbox>
          </v-col>


          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temProteseIMP">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag9"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Prótese de silicone de aspecto ecográfico normal."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temProteseIMP">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag10"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Prótese de silicone à direita de aspecto ecográfico normal."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temProteseIMP">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag11"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Prótese de silicone à esquerda de aspecto ecográfico normal."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temProteseIMP">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag12"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Sinais de rotura intracapsular à direita."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temProteseIMP">
            <v-checkbox
              :readonly="carregamentoDoLaudo || !podeEditar"
              v-model="laudoSelecionado.laudoJson.impDiag13"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Sinais de rotura intracapsular à esquerda."
              validate-on-blur
            ></v-checkbox>
          </v-col>
        </div>
        <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temProteseIMP">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.impDiag14"
            class="d-flex justify-content-start align-items-center mt-n5"
            label="Sinais de rotura intracapsular bilateral."
            validate-on-blur
          ></v-checkbox>
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temProteseIMP">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.impDiagN15"
            class="d-flex justify-content-start align-items-center mt-n5"
            label="Sinais de rotura extracapsular à direita."
            validate-on-blur
          ></v-checkbox>
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temProteseIMP">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.impDiagN16"
            class="d-flex justify-content-start align-items-center mt-n5"
            label="Sinais de rotura extracapsular à esquerda."
            validate-on-blur
          ></v-checkbox>
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temProteseIMP">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.impDiagN17"
            class="d-flex justify-content-start align-items-center mt-n5"
            label="Sinais de rotura extracapsular bilateral."
            validate-on-blur
          ></v-checkbox>
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center" v-if="laudoSelecionado.laudoJson.temProteseIMP">
          <v-checkbox
            :readonly="carregamentoDoLaudo || !podeEditar"
            v-model="laudoSelecionado.laudoJson.impDiagN18"
            class="d-flex justify-content-start align-items-center mt-n5"
            label="Rotação da prótese."
            validate-on-blur
          ></v-checkbox>
        </v-col>

        <v-row
          class="align-center px-10 pt-3 mb-7"
          :class="!camposExibidos.exCatAx ? 'text--disabled' : ''"
        >
          <h4>Categoria</h4>
          <v-col cols="12" md="1" class="py-0">
            <v-select
              :items="['0', '1', '2', '3', '4', '5', '6']"
              required
              :disabled="!camposExibidos.exCatAx"
              :readonly="carregamentoDoLaudo || !podeEditar"
              :clearable="podeEditar"
              validate-on-blur
              v-model="laudoSelecionado.laudoJson.categoria1"
            ></v-select>
          </v-col>
          <h4>pelo sistema de padronização BI-RADS ultrassonográfico.</h4>
          <v-btn
            dense
            depressed
            text
            icon
            v-if="!camposExibidos.exCatAx"
            @click="camposExibidos.exCatAx = true"
            ><v-icon>mdi-eye-off</v-icon></v-btn
          >
          <v-btn
            dense
            depressed
            text
            icon
            v-if="camposExibidos.exCatAx"
            @click="
              camposExibidos.exCatAx = false;
              laudoSelecionado.laudoJson.categoria1 = undefined;
            "
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </v-row>

        <v-col>
          <v-textarea
            v-model="laudoSelecionado.laudoJson.comentariosAdcionaisFinal2"
            :readonly="carregamentoDoLaudo || !podeEditar"
            required
            validate-on-blur
            filled
            outlined
            no-resize
            label="Comentários adicionais:"
          >
          </v-textarea>
        </v-col>
        <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.temAxila"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="AXILAS"
              validate-on-blur
            ></v-checkbox>
          </v-col>
        <h3 v-if="laudoSelecionado.laudoJson.temAxila">Axilas</h3>
        <div v-if="laudoSelecionado.laudoJson.temAxila">
          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre2"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Axila direita livre"
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre3"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Axila esquerda livre"
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre4"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Axilas livres"
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre5"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Linfonodos de aspecto ecográfico habitual nas axilas"
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre6"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Linfonodos de aspecto habitual na axila direita"
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre7"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Linfonodos de aspecto habitual na axila esquerda"
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre8"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Linfonodo de aspecto habitual nas axilas"
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre9"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Linfonodo de aspecto habitual na axila direita"
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre10"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Linfonodo de aspecto habitual na axila esquerda"
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre11"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Linfonodos de aspecto suspeito nas axilas"
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre12"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Linfonodos de aspecto suspeito na axila direita"
              validate-on-blur
            ></v-checkbox>
          </v-col>

          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivre13"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Linfonodos de aspecto suspeito na axila esquerda"
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivreN14"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Mama axilar acessória à direita."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivreN15"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Mama axilar acessória à esquerda."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivreN16"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Mama axilar acessória bilateral."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivreN17"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Mama axilar acessória lipossubstituída à direita."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivreN18"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Mama axilar acessória lipossubstituída à esquerda."
              validate-on-blur
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-start align-center">
            <v-checkbox
              v-model="laudoSelecionado.laudoJson.axilaDireitaLivreN19"
              :readonly="carregamentoDoLaudo || !podeEditar"
              class="d-flex justify-content-start align-items-center mt-n5"
              label="Mama axilar acessória lipossubstituída."
              validate-on-blur
            ></v-checkbox>
          </v-col>
        </div>
      </div>
    </template>
    <v-col cols="12" class="d-flex justify-start align-center pt-6">
      <v-btn
        v-if="podeEditar"
        color="primary"
        :loading="carregamentoDoLaudo"
        @click="
          () => {
            laudoId ? editarLaudo() : salvarLaudo();
          }
        "
      >
        salvar laudo
      </v-btn>

      <v-btn
        color="primary"
        v-if="podeImprimir && !podeEditar"
        @click="imprimirLaudo"
        class="ml-5"
        :loading="loadingImprimir"
      >
        Imprimir
      </v-btn>

      <v-btn
        color="primary"
        v-if="!podeEditar"
        class="ml-5"
        @click="podeEditar = true"
      >
        Editar
      </v-btn>

      <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
        Cancelar
      </v-btn>

      <v-btn
        color="primary"
        v-if="podePersonalizar"
        @click="adcionarConteudoEditorPersonalizado"
        class="ml-5"
      >
        {{ clicouNoPersonalizado ? 'Formulário' : 'Personalizar' }}
      </v-btn>
    </v-col>
    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { VueEditor } from 'vue2-editor';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';
import PerfilCervical from '../../exames/translucenciaNucal/PerfilCervical.vue';
import PreEclampsia from '../../exames/translucenciaNucal/PreEclampsia.vue';
import MorfologicaPrimeiroTrimestre from '../../exames/translucenciaNucal/MorfologicaPrimeiroTrimestre.vue';
import ModalLogin from '@/components/laudos/ModalLogin.vue';
import axios from 'axios';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    barraDeBuscaExames,
    VueEditor,
    FormInfoLaudo,
    PerfilCervical,
    PreEclampsia,
    MorfologicaPrimeiroTrimestre,
  },
  props: ['exibeLaudo', 'editaLaudo'],
  data() {
    return {
      clicouNoPersonalizado: false,
      conteudoEditorPersonalizado: [],
      numText: ['zero', 'um', 'dois', 'três', 'quatro', 'cinco', 'seis'],
      quantidadeFolhasEditorPersonalizado: 0,
      carregamentoDoLaudo: false,
      loadingImprimir: false,
      laudoId: null,
      podeEditar: true,
      voltando: false,
      componentKey: 0,
      nomeBotao: '',
      criaLaudo: true,
      exibeTitulo: false,
      tab: null,
      laudoPrint: '',
      feto: '',
      tipo: '',
      dialog: false,
      checkMamaDireita: false,
      checkMamaDireitaMicrocistos: false,
      checkMamaDireitaReforcoAnecoico: false,
      checkMamaDireitaFormacoes: false,
      checkMamaDireitaContornoIrregular: false,
      checkMamaEsquerda: false,
      checkMamaEsquerdaMicrocistos: false,
      checkMamaEsquerdaReforcoAnecoico: false,
      checkMamaEsquerdaFormacoes: false,
      checkMamaEsquerdaContornoIrregular: false,
      checkAxilaDireita: false,
      checkAxilaEsquerda: false,
      camposExibidos: {
        exCatAx: true,
        exCat: true,
        exibeIntegridade: true,
        exibeTransdutor: true,
        exibeIndicacaoExame: true,
        exibePeleSubcutaneo: true,
        exibeComposicaoMamaria: true,
        exPresenca: true,
        exDistImg: [],
        exPele: [],
        exAureola: [],
        exPeleEsq: [],
        exAureolaEsq: [],
        exDistImgHoraEsq: [],
        exDistImgHora: [],
        exibeMedidas: true,
        exibePresImg: true,
        exibeMultiplas: true,
        exibeCongDeCistos: true,
        exibeMassaInterna: true,
        exibeTextura: true,
        exibeRotacao: true,

        exibeIntegridadeEsq: true,
        exibeAreolaePapilaEsq: true,
        exibePeleSubcutaneoEsq: true,
        exibeComposicaoMamariaEsq: true,
        exPresencaEsq: true,
        exDistImgEsq: [],
        exibeMedidasEsq: true,
        exibePresImgEsq: true,
        exibeMultiplasEsq: true,
        exibeCongDeCistosEsq: true,
        exibeMassaInternaEsq: true,
        exibeTexturaEsq: true,
        exibeRotacaoEsq: true,

        exibeAreolaePapila: true,

        exibeAxila: true,
        exCatAx: true,
      },
      modal: {
        active: false,
        modalComponent: ModalLogin,
      },

      exibirBotaoEditar: false,
      exibirLaudo: true,
      items: ['feto 1', 'feto 2'],

      dataMenstruacao: '',
      salvoOuEditado: '',
      qntdEmbrioes: 0,

      colodeutero: false,

      coloUterinoConclusao: [
        'de aspecto ecográfico habitual para a idade gestacional',
        'entreaberto, porém com comprimento normal. Convém nova avaliação do colo uterino por via transvaginal em duas semanas',
        'fechado, com menos de 2,5 cm de comprimento',
      ],
      classificacaoRisco: [
        'a gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
        'a gestante foi classificada como alto risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)',
      ],

      laudoTranslucenciaNucal: {
        transdutor: '',
        dataUltimaMenstruacao: '',
        idadeGestacionalMenstrual: '',
        idadeGestacionalSemanas: '',
        idadeGestacionalDias: '',

        frequenciaCardiacaFetal: '',
        placenta: '',
        coloUterino: '',
        medidaColoUterino: '',
        comprimentoCabecaNadegas: '',
        transulucenciaNucal: '',
        ossoNasal: '',
        trissomia21Materna: '',
        trissomia21MaternaTN: '',
        fetosVivosMortos: '',
        idadeGestacionalBiometriaFetalSemanas: '',
        idadeGestacionalBiometriaFetalDias: '',
        compativelIncompativel: '',
        dataProvavelParto: '',
      },
    };
  },
  computed: {
    temIdDoLaudo() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    podeImprimir() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    temIdDoLaudo() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
    podePersonalizar() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    removeFolha(value) {
      let indice = value - 1;

      if (this.laudoSelecionado.laudoJson.folhas.length != 1) {
        this.conteudoEditorPersonalizado.splice(indice, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.$forceUpdate();
    },
    criaListaFor(value) {
      this.laudoSelecionado.laudoJson.listaPresencas = [];
      let limite = parseFloat(value);

      for (let i = 0; i < limite; i++) {
        this.laudoSelecionado.laudoJson.listaPresencas.push({
          presenca: {
            opc1: '',
            opc2: '',
            opc3: '',
            opc4: '',
            opc5: '',
            medida1Esq: '',
            medida2Esq: '',
            medida3Esq: '',
            medidaCmPeleEsq: '',
            medidaCmAreolaEsq: '',
            locPeleAreoEsq: '',
            horaAreolaEsq: '',
          },
        });
        this.camposExibidos.exDistImgEsq.push(true);
        this.camposExibidos.exPeleEsq.push(true);
        this.camposExibidos.exAureolaEsq.push(true);
        this.camposExibidos.exDistImgHoraEsq.push(true)
        // this.camposExibidos.exDistImgFigo.push(true)
      }
      this.$forceUpdate();
    },
    criaListaForEsq(value) {
      this.laudoSelecionado.laudoJson.listaPresencasEsq = [];
      let limite = parseFloat(value);

      for (let i = 0; i < limite; i++) {
        this.laudoSelecionado.laudoJson.listaPresencasEsq.push({
          presenca: {
            opc1: '',
            opc2: '',
            opc3: '',
            opc4: '',
            opc5: '',
            
            medida1Esq: '',
            medida2Esq: '',
            medida3Esq: '',
            medidaCmPeleEsq: '',
            medidaCmAreolaEsq: '',
            locPeleAreoEsq: '',
            horaAreolaEsq: '',
          },
        });
        this.camposExibidos.exDistImg.push(true);
        this.camposExibidos.exPele.push(true);
        this.camposExibidos.exAureola.push(true);
        this.camposExibidos.exDistImgHora.push(true)
      }
      this.$forceUpdate();
    },
    adcionarConteudoEditorPersonalizado() {
      this.podeEditar = true;
      if (this.clicouNoPersonalizado) {
        this.clicouNoPersonalizado = false;
        return;
      }
      if (!!this.laudoSelecionado.laudoJson.folhas) {
        this.conteudoEditorPersonalizado =
          this.laudoSelecionado.laudoJson.folhas;
        this.clicouNoPersonalizado = true;

        this.quantidadeFolhasEditorPersonalizado =
          this.conteudoEditorPersonalizado.length;

        return;
      }

      let folha = [];
      this.conteudoEditorPersonalizado = [];
      if (this.laudoSelecionado.laudoJson.transdutorLinear)
        folha.push(
          `Exame realizado com transdutor linear de alta resolução multifrequencial ( ${this.laudoSelecionado.laudoJson.transdutorLinear} a ${this.laudoSelecionado.laudoJson.transdutorLinear2} MHz).\n`
        );
      if (this.laudoSelecionado.laudoJson.indicacaoExame)
        folha.push(
          `Indicação do exame ${this.laudoSelecionado.laudoJson.indicacaoExame}.\n`
        );

      
        folha.push('\n<h4>Mama direita</h4>');
      if (this.laudoSelecionado.laudoJson.mamaDireitaPrevia0) {
        folha.push(`Mastectomia total prévia sem evidências de nódulos.\n`);
      }

      if (this.laudoSelecionado.laudoJson.transdutorLinear)
        folha.push(
          `Exame realizado com transdutor linear de alta resolução multifrequencial ( ${this.laudoSelecionado.laudoJson.transdutorLinear} a ${this.laudoSelecionado.laudoJson.transdutorLinear2} MHz).\n`
        );
      if (this.laudoSelecionado.laudoJson.indicacaoExame)
        folha.push(
          `Indicação do exame ${this.laudoSelecionado.laudoJson.indicacaoExame}.\n`
        );

      if (this.laudoSelecionado.laudoJson.peleSubcutaneoEsq)
        folha.push(
          `Pele e subcutâneo ${
            this.laudoSelecionado.laudoJson.peleSubcutaneoEsq
          }${
            [
              'presença de edema ',
              'presença de retração ',
              'presença de cisto ',
              'outros',
            ].includes(this.laudoSelecionado.laudoJson.peleSubcutaneoEsq) &&
            this.laudoSelecionado.laudoJson.peleSubcutaneo2Esq
              ? ' medindo ' + this.laudoSelecionado.laudoJson.peleSubcutaneo2Esq
              : ' cm'
          }.\n`
        );
      if (this.laudoSelecionado.laudoJson.areolaPapilaEsq)
        folha.push(
          `Aréola e papila  ${this.laudoSelecionado.laudoJson.areolaPapilaEsq}${
            [
              'presença de edema',
              'presença de retração',
              'presença de cisto',
              'outros',
            ].includes(this.laudoSelecionado.laudoJson.areolaPapilaEsq) &&
            this.laudoSelecionado.laudoJson.areolaPapila2Esq
              ? ' medindo' + this.laudoSelecionado.laudoJson.areolaPapila2Esq
              : ' cm'
          }.\n`
        );
      if (this.laudoSelecionado.laudoJson.composicaoMamariaEsq)
        folha.push(
          `Composição mamária  ${this.laudoSelecionado.laudoJson.composicaoMamariaEsq}.\n`
        );
      if (this.laudoSelecionado.laudoJson.mamaEsqPrevia1)
        folha.push(
          `Ausência de imagens nodulares de etiologia sólida ou cística.\n`
        );

      if (this.laudoSelecionado.laudoJson.presencaEsq)
        folha.push(
          `Presença de ${
            this.numText[this.laudoSelecionado.laudoJson.presencaEsq]
          }${
            this.laudoSelecionado.laudoJson.presencaEsq > 1
              ? ' nódulos, assim caracterizados:'
              : ' nódulo, assim caracterizado:'
          }\n`
        );
      if (!!this.laudoSelecionado.laudoJson.listaPresencasEsq) {
        if (this.laudoSelecionado.laudoJson.listaPresencasEsq.length > 0)
          for (
            var i = 0;
            i < this.laudoSelecionado.laudoJson.listaPresencasEsq.length;
            i++
          ) {
            folha.push(
              `Nódulo ${i + 1} - ${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .opc1
                  ? this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.opc1
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .opc2
                  ? this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.opc2
                  : '.'
              }${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .opc3
                  ? this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.opc3
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .opc4
                  ? this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.opc4
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .opc5
                  ? this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.opc5
                  : ''
              }medindo ${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .medida1Esq
                  ? this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.medida1Esq
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .medida2Esq
                  ? ' x ' +
                    this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.medida2Esq
                  : ''
              } ${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .medida3Esq
                  ? ' x ' +
                    this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.medida3Esq
                  : ''
              } cm, ${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .medidaCmPeleEsq
                  ? 'distando ' +
                    this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.medidaCmPeleEsq +
                    ' cm da pele'
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .medidaCmAreolaEsq
                  ? ( ' e ' + this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.medidaCmAreolaEsq + ' cm da aréola')
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .locPeleAreoEsq
                  ? ( ', localizado ' +this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.locPeleAreoEsq)
                  : ''
              } (${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .horaAreolaEsq
                  ? this.laudoSelecionado.laudoJson.listaPresencasEsq[i]
                      .presenca.horaAreolaEsq
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencasEsq[i].presenca
                  .horaAreolaEsq > 1
                  ? ' horas'
                  : ' hora'
              }).\n`
            );
          }
      }
      if (this.laudoSelecionado.laudoJson.medida1Esq)
        folha.push(
          `medindo  ${this.laudoSelecionado.laudoJson.medida1Esq} x ${
            this.laudoSelecionado.laudoJson.medida2Esq
          } x ${this.laudoSelecionado.laudoJson.medida3Esq} cm, distando ${
            this.laudoSelecionado.laudoJson.medidaCmPeleEsq
          } cm da pele e ${
            this.laudoSelecionado.laudoJson.medidaCmAreolaEsq
          }  cm da aréola, localizado ${
            this.laudoSelecionado.laudoJson.locPeleAreoEsq
          } ${this.laudoSelecionado.laudoJson.horaAreolaEsq > 1 ? 'as ' : 'a'}${
            this.laudoSelecionado.laudoJson.horaAreolaEsq
          } ${
            this.laudoSelecionado.laudoJson.horaAreolaEsq > 1
              ? ' horas '
              : ' hora '
          }\n`
        );
      if (this.laudoSelecionado.laudoJson.presImgcmEsq)
        folha.push(
          `Presença de imagem anecóica medindo ${
            this.laudoSelecionado.laudoJson.presImgcmEsq
          } cm, localizada ${this.laudoSelecionado.laudoJson.locPeleAreoEsq1} ${this.laudoSelecionado.laudoJson.presImgOpEsq}${
            this.laudoSelecionado.laudoJson.presImgOpEsq ==
            'com área sólida medindo '
              ?" " + this.laudoSelecionado.laudoJson.presImgOpOutrosEsq + ' cm.'
              : '.'
          }\n`
        );
      if (this.laudoSelecionado.laudoJson.CongCistoCmEsq)
        folha.push(
          `Conglomerado de cistos medindo ${this.laudoSelecionado.laudoJson.CongCistoCmEsq} cm, localizado ${this.laudoSelecionado.laudoJson.CongCistoOpcEsq}.\n`
        );
      if (this.laudoSelecionado.laudoJson.mutlImgcmEsq)
        folha.push(
          `Imagens anecóicas, com a maior medindo ${this.laudoSelecionado.laudoJson.mutlImgcmEsq} cm, localizada  ${this.laudoSelecionado.laudoJson.mutlImgOpcEsq}.\n`
        );

      if (this.laudoSelecionado.laudoJson.temProteseEsq) {
        folha.push(`\n<h4>Prótese direita</h4>`);
        if (this.laudoSelecionado.laudoJson.texturaEsq)
          folha.push(
            `Textura  ${this.laudoSelecionado.laudoJson.texturaEsq}.\n`
          );
        if (this.laudoSelecionado.laudoJson.texturaEsq)
          folha.push(
            `Integridade da superfície  ${this.laudoSelecionado.laudoJson.integridadeDaSuperEsq}.\n`
          );
        if (this.laudoSelecionado.laudoJson.massaInternaEsq)
          folha.push(
            `Massa interna ${this.laudoSelecionado.laudoJson.massaInternaEsq}${
              this.laudoSelecionado.laudoJson.massaInternaEsq == 'sim, medindo'
                ? ' ' + this.laudoSelecionado.laudoJson.massaInternaCmEsq
                : ''
            }.\n`
          );
        if (this.laudoSelecionado.laudoJson.texturaEsq)
          folha.push(
            `Rotação ${this.laudoSelecionado.laudoJson.rotacaoEsq}.\n`
          );
        if (this.laudoSelecionado.laudoJson.comentariosAdicionaisEsq)
          folha.push(
            `${this.laudoSelecionado.laudoJson.comentariosAdicionaisEsq}.\n`
          );
      }
     
        if (this.laudoSelecionado.laudoJson.presAusDilatacao) 
    folha.push(`${this.laudoSelecionado.laudoJson.presAusDilatacao == 'Presença'?
    (this.laudoSelecionado.laudoJson.presAusDilatacao + ' de dilatação ductal' )
    : this.laudoSelecionado.laudoJson.presAusDilatacao + ' de dilatação ductal'
  }${this.laudoSelecionado.laudoJson.presAusDilatacao2?
    ( ' ' + this.laudoSelecionado.laudoJson.presAusDilatacao2  )
    : ''
    }${this.laudoSelecionado.laudoJson.presAusDilatacao3?
    ( ' ' + this.laudoSelecionado.laudoJson.presAusDilatacao3  )
    : ''
    }${this.laudoSelecionado.laudoJson.presAusDilatacao3 == 'com projeção sólida medindo ' && this.laudoSelecionado.laudoJson.presAusDilatacao4?
    ( this.laudoSelecionado.laudoJson.presAusDilatacao4)
    : ''
  }.\n`);
  if (this.laudoSelecionado.laudoJson.presencaDeLinfonodo) 
  folha.push(`Presença de  ${this.laudoSelecionado.laudoJson.presencaDeLinfonodo}${
this.laudoSelecionado.laudoJson.presencaDeLinfonodo == 'na região retroareolar' && this.laudoSelecionado.laudoJson.presencaDeLinfonodo?
   ', medindo ' + this.laudoSelecionado.laudoJson.presencaDeLinfonodo2 + ' cm': ''}.\n`);
   if (this.laudoSelecionado.laudoJson.presencaDeLinfonodoImgs) 
  folha.push(`Presença de ${this.laudoSelecionado.laudoJson.presencaDeLinfonodoImgs}${
this.laudoSelecionado.laudoJson.presencaDeLinfonodoImgs > 1? ' imagens hipoecóicas com centro hipoecóico, localizadas ':' imagem hipoecóica com centro hipoecóico, localizada ' }${
this.laudoSelecionado.laudoJson.presencaDeLinfonodo }${this.laudoSelecionado.laudoJson.presencaDeLinfonodo == 'na região retroareolar' && this.laudoSelecionado.laudoJson.presencaDeLinfonodo?', medindo ' 
+ this.laudoSelecionado.laudoJson.presencaDeLinfonodo2 + ' cm': ''}.\n`);

      folha.push(`\n<h4>Mama esquerda</h4>`);
      if (this.laudoSelecionado.laudoJson.mamaDireitaPrevia2) {
        folha.push(`Mastectomia total prévia sem evidências de nódulos.\n`);
      }
      if (this.laudoSelecionado.laudoJson.peleSubcutaneo)
        folha.push(
          `Pele e subcutâneo${this.laudoSelecionado.laudoJson.peleSubcutaneo}${
            [
              'presença de edema ',
              'presença de retração ',
              'presença de cisto ',
              'outros',
            ].includes(this.laudoSelecionado.laudoJson.peleSubcutaneo)
              ? ' medindo ' + this.laudoSelecionado.laudoJson.peleSubcutaneo2
              : ' cm'
          }.\n`
        );
      if (this.laudoSelecionado.laudoJson.areolaPapila)
        folha.push(
          `Aréola e papila  ${this.laudoSelecionado.laudoJson.areolaPapila}${
            [
              'presença de edema',
              'presença de retração',
              'presença de cisto',
              'outros',
            ].includes(this.laudoSelecionado.laudoJson.areolaPapila)
              ? ' medindo ' + this.laudoSelecionado.laudoJson.areolaPapila2
              : ' cm'
          }.\n`
        );
      if (this.laudoSelecionado.laudoJson.composicaoMamaria)
        folha.push(
          `Composição mamária  ${this.laudoSelecionado.laudoJson.composicaoMamaria}.\n`
        );
      if (this.laudoSelecionado.laudoJson.mamaDireitaPrevia1)
        folha.push(
          `Ausência de imagens nodulares de etiologia sólida ou cística.\n`
        );
      if (this.laudoSelecionado.laudoJson.presenca)
        folha.push(
          `Presença de ${
            this.numText[this.laudoSelecionado.laudoJson.presenca]
          }${
            this.laudoSelecionado.laudoJson.presenca > 1
              ? ' nódulos, assim caracterizados:'
              : ' nódulo, assim caracterizado:'
          }\n`
        );
      if (!!this.laudoSelecionado.laudoJson.listaPresencas) {
        if (this.laudoSelecionado.laudoJson.listaPresencas.length > 0)
          for (
            var i = 0;
            i < this.laudoSelecionado.laudoJson.listaPresencas.length;
            i++
          ) {
            folha.push(
              `Nódulo ${i + 1} - ${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.opc1
                  ? this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                      .opc1
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.opc2
                  ? this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                      .opc2
                  : '.'
              }${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.opc3
                  ? this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                      .opc3
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.opc4
                  ? this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                      .opc4
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.opc5
                  ? this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                      .opc5
                  : ''
              }medindo ${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                  .medida1Esq
                  ? this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                      .medida1Esq
                  : ''
              } ${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                  .medida2Esq
                  ? ' x ' +
                    this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                      .medida2Esq
                  : ''
              } ${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                  .medida3Esq
                  ? (
                      ' x ' +
                      this.laudoSelecionado.laudoJson.listaPresencas[i].presenca.medida3Esq
                    )
                  : ''
              } cm, ${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                  .medidaCmPeleEsq
                  ? 'distando ' +
                    this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                      .medidaCmPeleEsq +
                    ' cm da pele'
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                  .medidaCmAreolaEsq
                  ? (' e ' + this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                      .medidaCmAreolaEsq + ' cm da aréola')
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                  .locPeleAreoEsq
                  ?(', localizado ' +  this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                      .locPeleAreoEsq+ ' ')
                  : ''
              } (${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                  .horaAreolaEsq
                  ? this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                      .horaAreolaEsq
                  : ''
              }${
                this.laudoSelecionado.laudoJson.listaPresencas[i].presenca
                  .horaAreolaEsq > 1
                  ? ' horas'
                  : ' hora'
              }).\n`
            );
          }
      }
      if (this.laudoSelecionado.laudoJson.medida1)
        folha.push(
          `medindo  ${this.laudoSelecionado.laudoJson.medida1} x ${
            this.laudoSelecionado.laudoJson.medida2
          } x ${this.laudoSelecionado.laudoJson.medida3} cm, distando ${
            this.laudoSelecionado.laudoJson.medidaCmPele
          } cm da pele e ${
            this.laudoSelecionado.laudoJson.medidaCmAreola
          }  cm da aréola, localizado ${
            this.laudoSelecionado.laudoJson.locPeleAreo
          } ${this.laudoSelecionado.laudoJson.horaAreola > 1 ? 'as ' : 'a'}${
            this.laudoSelecionado.laudoJson.horaAreola
          } ${
            this.laudoSelecionado.laudoJson.horaAreola > 1
              ? ' horas '
              : ' hora '
          }\n`
        );
      if (this.laudoSelecionado.laudoJson.presImgcm)
        folha.push(
          `Presença de imagem anecóica medindo ${
            this.laudoSelecionado.laudoJson.presImgcm
          } cm, localizada ${this.laudoSelecionado.laudoJson.locPeleAreoEsq2}${this.laudoSelecionado.laudoJson.presImgOp}${
            this.laudoSelecionado.laudoJson.presImgOp ==
            'com área sólida medindo '
              ?" " + this.laudoSelecionado.laudoJson.presImgOpOutros + ' cm.'
              : '.'
          }\n`
        );
      if (this.laudoSelecionado.laudoJson.CongCistoCm)
        folha.push(
          `Conglomerado de cistos medindo ${this.laudoSelecionado.laudoJson.CongCistoCm} cm, localizado ${this.laudoSelecionado.laudoJson.CongCistoOpc}.\n`
        );
      if (this.laudoSelecionado.laudoJson.mutlImgcm)
        folha.push(
          `Imagens anecóicas, com a maior medindo ${this.laudoSelecionado.laudoJson.mutlImgcm} cm, localizada  ${this.laudoSelecionado.laudoJson.mutlImgOpc}.\n`
        );

      if (this.laudoSelecionado.laudoJson.temProtese) {
        folha.push(`\n<h4>Prótese esquerda</h4>`);
        if (this.laudoSelecionado.laudoJson.textura)
          folha.push(`Textura ${this.laudoSelecionado.laudoJson.textura}.\n`);
        if (this.laudoSelecionado.laudoJson.textura)
          folha.push(
            `Integridade da superfície ${this.laudoSelecionado.laudoJson.integridadeDaSuper}.\n`
          );
        if (this.laudoSelecionado.laudoJson.massaInterna)
          folha.push(
            `Massa interna ${this.laudoSelecionado.laudoJson.massaInterna}${
              this.laudoSelecionado.laudoJson.massaInterna == 'sim, medindo'
                ? ' ' + this.laudoSelecionado.laudoJson.massaInternaCm
                : ''
            }.\n`
          );
        if (this.laudoSelecionado.laudoJson.textura)
          folha.push(`Rotação ${this.laudoSelecionado.laudoJson.rotacao}.\n`);
        if (this.laudoSelecionado.laudoJson.comentariosAdicionais)
          folha.push(
            `${this.laudoSelecionado.laudoJson.comentariosAdicionais}.\n`
          );
      }

      if (this.laudoSelecionado.laudoJson.comentariosAdcionais1)
        folha.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionais1}\n`
        );

      if (this.laudoSelecionado.laudoJson.proteseSiliconeAspecto)
        folha.push(`Prótese de silicone de aspecto ecográfico normal.\n`);

      if (this.laudoSelecionado.laudoJson.cistoMamario10)
        folha.push(` Cisto mamário.\n`);
      if (this.laudoSelecionado.laudoJson.cistoMamario11)
        folha.push(` Cistos mamários.\n`);
      if (this.laudoSelecionado.laudoJson.microcistosAgrupados1)
        folha.push(` Microcistos agrupados.\n`);
      if (this.laudoSelecionado.laudoJson.ectasiaDuctal1)
        folha.push(` Ectasia ductal.\n`);
      if (this.laudoSelecionado.laudoJson.linfonodoIntramamario2)
        folha.push(`Linfonodo intramamário.\n`);
      if (this.laudoSelecionado.laudoJson.noduloSolidoMamario2)
        folha.push(`Nódulos sólidos mamário.\n`);
      if (this.laudoSelecionado.laudoJson.noduloSolidoMamario3)
        folha.push(`Nódulos sólidos mamários.\n`);

      if (this.laudoSelecionado.laudoJson.cistoMamario13)
        folha.push(` Cisto mamário.\n`);
      if (this.laudoSelecionado.laudoJson.cistoMamario20)
        folha.push(` Cistos mamários.\n`);
      if (this.laudoSelecionado.laudoJson.microcistosAgrupados100)
        folha.push(`Microcistos agrupados.\n`);
      if (this.laudoSelecionado.laudoJson.ectasiaDuctal20)
        folha.push(` Ectasia ductal.\n`);
      if (this.laudoSelecionado.laudoJson.linfonodoIntramamario40)
        folha.push(`Linfonodo intramamário.\n`);
      if (this.laudoSelecionado.laudoJson.noduloSolidoMamario300)
        folha.push(`Nódulo sólido mamário.\n`);
      if (this.laudoSelecionado.laudoJson.noduloSolidoMamario3012)
        folha.push(`Nódulos sólidos mamários.\n`);
      
      
  
      if (this.laudoSelecionado.laudoJson.presAusDilatacaoB) 
    folha.push(`${this.laudoSelecionado.laudoJson.presAusDilatacaoB == 'Presença'?
    (this.laudoSelecionado.laudoJson.presAusDilatacaoB + ' de dilatação ductal' )
    : this.laudoSelecionado.laudoJson.presAusDilatacaoB + ' de dilatação ductal'
  }${this.laudoSelecionado.laudoJson.presAusDilatacao2B?
    ( ' ' + this.laudoSelecionado.laudoJson.presAusDilatacao2B  )
    : ''
    }${this.laudoSelecionado.laudoJson.presAusDilatacao3B?
    ( ' ' + this.laudoSelecionado.laudoJson.presAusDilatacao3B  )
    : ''
    }${this.laudoSelecionado.laudoJson.presAusDilatacao3B == 'com projeção sólida medindo ' && this.laudoSelecionado.laudoJson.presAusDilatacao4B?
    ( this.laudoSelecionado.laudoJson.presAusDilatacao4B)
    : ''
  }.`);
 
   if (this.laudoSelecionado.laudoJson.presencaDeLinfonodoImgsB) 
  folha.push(`Presença de ${this.laudoSelecionado.laudoJson.presencaDeLinfonodoImgsB}${
this.laudoSelecionado.laudoJson.presencaDeLinfonodoImgsB > 1? ' imagens hipoecóicas com centro hipoecóico, localizadas ':' imagem hipoecóica com centro hipoecóico, localizada ' }${
this.laudoSelecionado.laudoJson.presencaDeLinfonodoB }${this.laudoSelecionado.laudoJson.presencaDeLinfonodoB == 'na região retroareolar' && this.laudoSelecionado.laudoJson.presencaDeLinfonodoB?', medindo ' 
+ this.laudoSelecionado.laudoJson.presencaDeLinfonodo2B + ' cm': ''}.\n`);
        

      folha.push('\n<h4>Impressão Diagnóstica</h4>');
      if (this.laudoSelecionado.laudoJson.impressaoDiagnostica1)
        folha.push(
          `Ultrassonografia mamária dentro dos padrões da normalidade.\n`
        );
      if (this.laudoSelecionado.laudoJson.impressaoDiagnostica2)
        folha.push(`Prótese de silicone de aspecto ecográfico normal.\n`);
      if (this.laudoSelecionado.laudoJson.impDiag1)
        folha.push(
          `Ultrassonografia mamária dentro dos padrões da normalidade.\n`
        );
      if (this.laudoSelecionado.laudoJson.impDiag2)
        folha.push(`Nódulos sólidos bilaterais.\n`);
      if (this.laudoSelecionado.laudoJson.impDiag3)
        folha.push(`Nódulo sólido na mama direita.\n`);
      if (this.laudoSelecionado.laudoJson.impDiag5)
        folha.push(`Nódulo sólido na mama esquerda.\n`);
      if (this.laudoSelecionado.laudoJson.impDiag6)
        folha.push(`Cistos mamários.\n`);
      if (this.laudoSelecionado.laudoJson.impDiag7)
        folha.push(`Cistos na mama direita.\n`);
      if (this.laudoSelecionado.laudoJson.impDiag8)
        folha.push(`Cistos na mama esquerda.\n`);

       
        if (this.laudoSelecionado.laudoJson.impDiagN1)
        folha.push(`Cisto na mama direita. \n`);
        if (this.laudoSelecionado.laudoJson.impDiagN2)
        folha.push(`Cisto na mama esquerda. \n`);
        if (this.laudoSelecionado.laudoJson.impDiagN3)
        folha.push(`Ectasia ductal sem conteúdo sólido.\n`);
        if (this.laudoSelecionado.laudoJson.impDiagN4)
        folha.push(`Ectasia ductal com conteúdo sólido (papiloma?).\n`);
        if (this.laudoSelecionado.laudoJson.impDiagN5)
        folha.push(`Linfonodo intra-mamário à direita.\n`);
        if (this.laudoSelecionado.laudoJson.impDiagN6)
        folha.push(`Linfonodo intra-mamário à esquerda.\n`);
      if (this.laudoSelecionado.laudoJson.impDiagN7)
      folha.push(`Mastectomia prévia à esquerda.\n`);
      if (this.laudoSelecionado.laudoJson.impDiagN8)
        folha.push(`Mastectomia prévia à direita.\n`);







        if (this.laudoSelecionado.laudoJson.impDiag9)
      folha.push(`Prótese de silicone de aspecto ecográfico normal.\n`);
      if (this.laudoSelecionado.laudoJson.impDiag10)
      folha.push(
        `Prótese de silicone à direita de aspecto ecográfico normal.\n`
      );
    if (this.laudoSelecionado.laudoJson.impDiag11)
      folha.push(
        `Prótese de silicone à esquerda de aspecto ecográfico normal.\n`
      );
    if (this.laudoSelecionado.laudoJson.impDiag12)
      folha.push(`Sinais de rotura intracapsular à direita.\n`);
    if (this.laudoSelecionado.laudoJson.impDiag13)
      folha.push(`Sinais de rotura intracapsular à esquerda.\n`);
    if (this.laudoSelecionado.laudoJson.impDiag14)
      folha.push(`Sinais de rotura intracapsular bilateral.\n`);
      
      if (this.laudoSelecionado.laudoJson.impDiagN15)
      folha.push(`Sinais de rotura extracapsular à direita.\n`);
      if (this.laudoSelecionado.laudoJson.impDiagN16)
      folha.push(`Sinais de rotura extracapsular à esquerda.\n`);
      if (this.laudoSelecionado.laudoJson.impDiagN17)
      folha.push(`Sinais de rotura extracapsular bilateral.\n`);
      if (this.laudoSelecionado.laudoJson.impDiagN18)
      folha.push(`Rotação da prótese.\n`);

      if (this.laudoSelecionado.laudoJson.comentariosAdicionaisAnaliseComp)
        folha.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdicionaisAnaliseComp}\n`
        );
      // if (this.laudoSelecionado.laudoJson.categoria1)
      //   folha.push(` Categoria ${this.laudoSelecionado.laudoJson.categoria1} pelo sistema de padronização BI-RADS
      //     ultrassonográfico.`);
      if (this.laudoSelecionado.laudoJson.comentariosAdicionaisAnaliseComp)
        folha.push(
          ` ${this.laudoSelecionado.laudoJson.comentariosAdicionaisAnaliseComp}\n`
        );

      //termina impressao diagnostica

      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaDireita1)
        folha.push(`Cisto mamário.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaDireita2)
        folha.push(`Cistos mamários.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaDireita3)
        folha.push(`Cistos Microcistos agrupados.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaDireita4)
        folha.push(`Ectasia ductal.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaDireita5)
        folha.push(`Linfonodo intramamário.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaDireita6)
        folha.push(`Nódulo sólido mamário.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaDireita7)
        folha.push(`Nódulos sólidos mamários.\n`);

      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaEsquerda1)
        folha.push(`Cisto mamário.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaEsquerda2)
        folha.push(`Cistos mamários.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaEsquerda3)
        folha.push(`Cistos Microcistos agrupados.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaEsquerda4)
        folha.push(`Ectasia ductal.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaEsquerda5)
        folha.push(`Linfonodo intramamário.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaEsquerda6)
        folha.push(`Nódulo sólido mamário.\n`);
      if (this.laudoSelecionado.laudoJson.impressaoDiagnosticaMamaEsquerda7)
        folha.push(`Nódulos sólidos mamários.\n`);

      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisFinal1)
        folha.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisFinal1}\n`
        );
      if (this.laudoSelecionado.laudoJson.categoria1)
        folha.push(
          `Categoria ${this.laudoSelecionado.laudoJson.categoria1} pelo sistema de padronização BI-RADS ultrassonográfico.\n`
        );
      if (this.laudoSelecionado.laudoJson.comentariosAdcionaisFinal2)
        folha.push(
          `${this.laudoSelecionado.laudoJson.comentariosAdcionaisFinal2}\n`
        );

      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre2)
        folha.push(`Axila direita livre.\n`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre3)
        folha.push(`Axila esquerda livre.\n`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre4)
        folha.push(`Axilas livres.\n`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre5)
        folha.push(`Linfonodos de aspecto ecográfico habitual nas axilas.\n`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre6)
        folha.push(`Linfonodos de aspecto habitual na axila direita.\n`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre7)
        folha.push(`Linfonodos de aspecto habitual na axila esquerda.\n`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre8)
        folha.push(`Linfonodo de aspecto habitual nas axilas.\n`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre9)
        folha.push(`Linfonodo de aspecto habitual na axila direita.\n`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre10)
        folha.push(`Linfonodo de aspecto habitual na axila esquerda.\n`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre11)
        folha.push(`Linfonodos de aspecto suspeito nas axilas.\n`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre12)
        folha.push(`Linfonodos de aspecto suspeito na axila direita.\n`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivre13)
        folha.push(`Linfonodos de aspecto suspeito na axila esquerda.\n`);
        if (this.laudoSelecionado.laudoJson.axilaDireitaLivreN14)
      folha.push(`Mama axilar acessória à direita.`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivreN15)
      folha.push(`Mama axilar acessória à esquerda.`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivreN16)
      folha.push(`Mama axilar acessória bilateral.`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivreN17)
      folha.push(`Mama axilar acessória lipossubstituída à direita.`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivreN18)
      folha.push(`Mama axilar acessória lipossubstituída à esquerda.`);
      if (this.laudoSelecionado.laudoJson.axilaDireitaLivreN19)
      folha.push(`Mama axilar acessória lipossubstituída.`);

      while (folha.length > 0) {
        const teste = folha.splice(0, 22);

        this.conteudoEditorPersonalizado.push(teste.join(''));
      }
      this.quantidadeFolhasEditorPersonalizado =
        this.conteudoEditorPersonalizado.length;

      this.clicouNoPersonalizado = true;
    },
    forceReRender() {
      this.componentKey++;
    },

    ...mapGetters('Offline', ['requisicao', 'dados']),
    juntaPersonalizadoAoLaudo() {
      this.laudoSelecionado.laudoJson.folhas = this.conteudoEditorPersonalizado;
      this.laudoSelecionado.laudoJson.titulo =
        'ULTRASSONOGRAFIA DE MAMA COM AXILA';
    },

    forceReRender() {
      this.componentKey++;
    },

    ...mapGetters('Offline', ['requisicao', 'dados']),

    async salvarLaudo() {
      try {
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.abreModal();

        return;
      } finally {
        try {
          this.carregamentoDoLaudo = true;

          const exameId = this.$route.query.exame;

          this.laudoSelecionado.examePorConsultaId = exameId;
          this.consultaSelecionada.status = 'LAUDADO';

          if (navigator.onLine) {
            const { data } = await this.postLaudo();
            this.laudoId = data;
            await this.alterarStatus();

            if (!Number.isInteger(data)) throw new Error(data);

            this.getLaudoByExamePorConsultaId(exameId);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
              requisicaoSalvar: this.postLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'POST_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', {
              laudo: this.laudoSelecionado,
              consulta: this.consultaSelecionada,
            });
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    juntaPersonalizadoAoLaudo() {
      this.laudoSelecionado.laudoJson.folhas = this.conteudoEditorPersonalizado;
      this.laudoSelecionado.laudoJson.titulo =
        'ULTRASSONOGRAFIA DE MAMA COM AXILA';
    },

    async editarLaudo() {
      if (
        this.conteudoEditorPersonalizado.length > 0 &&
        this.clicouNoPersonalizado
      ) {
        this.juntaPersonalizadoAoLaudo();
      } else {
      }
      try {
        this.carregamentoDoLaudo = true;
        if (navigator.onLine) {
          let resp = await axios.get(`${this.urlBase}Convenio`, {
            headers: this.headers,
          });
        }
      } catch (e) {
        this.carregamentoDoLaudo = false;
        this.abreModal();
        return;
      } finally {
        this.carregamentoDoLaudo = false;
        try {
          if (navigator.onLine) {
            this.carregamentoDoLaudo = true;
            await this.patchLaudo(this.laudoSelecionado);
          } else {
            this.$store.commit('offline/SET_REQUISICAO', {
              requisicao: this.patchLaudoOffline.bind({}),
              headers: { ...this.headers },
              urlBase: `${this.urlBase}`,
              codigo: 'PATCH_LAUDO',
            });
            this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
            // this.podeImprimir = false;
          }
        } catch (error) {
          alert('Error ao salvar o laudo');
        } finally {
          this.carregamentoDoLaudo = false;
          this.podeEditar = false;
        }
      }
    },
    desativaLoading() {
      this.loadingImprimir = false;
    },

    async imprimirLaudo() {
      this.loadingImprimir = true;
      this.laudoPrint = 'impresso';
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);
      if (this.clicouNoPersonalizado) {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.personalizado',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      } else {
        const novaAba = this.$router.resolve({
          name: 'imprecoes.axila',
          query: { exame: this.$route.query.exame },
        });
        window.open(novaAba.href, '_blank');
      }
      this.loadingImprimir = false;
    },
    formataLaudo() {
      if (this.exibeObs) {
        this.laudoSelecionado.laudoJson.exibeObs = false;
      } else {
        this.laudoSelecionado.laudoJson.exibeObs = true;
      }
    },
    abreModal() {
      this.modal.active = true;
    },
    closeModal() {
      this.modal.active = false;
    },
    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
  },
  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;

    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      // this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
