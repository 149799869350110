<template>
  <v-container fluid width="800px">
    <v-card class="my-2 py-4 text-center teal darken-4">
      <h2 class="grey--text text--lighten-4">
        ULTRASSONOGRAFIA TRANSVAGINAL PARA PESQUISA DE ENDOMETRIOSE
      </h2>
    </v-card>

    <v-row class="pt-5" no-gutters>
      <v-col
        cols="12"
        :class="`d-flex justify-start align-center ${
          camposExibidos.exibeTitulo ? '' : 'text--disabled'
        }`"
      >
        <!-- <v-spacer></v-spacer> -->
        <!-- <span>Título: </span>
        <v-text-field
          :readonly="carregamentoDoLaudo || !podeEditar"
          required
          class="mx-2 flex-grow-0"
          dense
          :disabled="!camposExibidos.exibeTitulo"
          v-mask="''"
          validate-on-blur
          v-model="laudoSelecionado.laudoJson.titulo"
        >
        </v-text-field> -->
        <v-btn
          color="primary"
          v-if="podeEditar"
          class="ml-5"
          @click="adicionaFolha()"
        >
          + folha
        </v-btn>
        <!-- <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer> -->
      </v-col>

      <v-col
        cols="12"
        class="form-group"
        v-if="!!laudoSelecionado.laudoJson.personalizado"
      >
        <vue-editor
          v-model="laudoSelecionado.laudoJson.personalizado"
          :disabled="!podeEditar"
        />
      </v-col>
      <!-- <v-spacer></v-spacer> -->

      <v-col
        justify-center
        cols="12"
        class="form-group"
        v-for="(exame, index) in laudoSelecionado.laudoJson.folhas"
        :key="index"
      >
        <v-spacer></v-spacer>
        <div>
          <h3>
            Folha {{ index + 1 }}
            <v-btn plain small @click="removeFolha(index)" class="pl-0">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </h3>

          <vue-editor
            v-model="laudoSelecionado.laudoJson.folhas[index]"
            :disabled="!podeEditar"
          />
        </div>
        <!-- <v-spacer></v-spacer> -->
      </v-col>

      <v-col cols="12" class="d-flex justify-start align-center pt-6">
        <v-spacer></v-spacer>
        <v-btn
          v-if="podeEditar"
          color="primary"
          :loading="carregamentoDoLaudo"
          @click="
            () => {
              laudoId ? editarLaudo() : salvarLaudo();
            }
          "
        >
          salvar laudo
        </v-btn>

        <v-btn
          color="primary"
          v-if="podeImprimir && !podeEditar"
          @click="imprimirLaudo"
          class="ml-5"
        >
          Imprimir
        </v-btn>

        <v-btn
          color="primary"
          v-if="!podeEditar"
          class="ml-5"
          @click="podeEditar = true"
        >
          Editar
        </v-btn>

        <v-btn color="primary" v-else class="ml-5" @click="podeEditar = false">
          Cancelar
        </v-btn>
        <!-- <v-btn color="primary" v-if="podeEditar" class="ml-5" @click="adicionaFolha()">
          + folha
        </v-btn> -->
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import barraDeBuscaExames from '../../../barraDeBuscaExames.vue';
import ConsultasMixin from '../../../../../mixins/ConsultasMixin';
import FormInfoLaudo from '../../FormInfoLaudo.vue';
import LaudosMixin from '../../../../../mixins/LaudosMixin';

export default {
  mixins: [ConsultasMixin, LaudosMixin],
  components: {
    barraDeBuscaExames,
    FormInfoLaudo,
    VueEditor,
  },
  data() {
    return {
      carregamentoDoLaudo: false,
      podeEditar: true,
      laudoId: null,
      voltando: false,
      componentKey: 0,
      laudoPrint: '',
      carregado: true,
      camposExibidos: {
        exibeTitulo: true,
      },
      folhas: [],
    };
  },
  computed: {
    podeImprimir() {
      return !!this.$store.getters['laudoSelecionado'].id;
    },
  },
  methods: {
    forceReRender() {
      this.componentKey++;
    },
    ...mapGetters('Offline', ['requisicao', 'dados']),

    async salvarLaudo() {
      try {
        this.carregamentoDoLaudo = true;

        const exameId = this.$route.query.exame;

        this.laudoSelecionado.examePorConsultaId = exameId;
        this.consultaSelecionada.status = 'LAUDADO';

        if (navigator.onLine) {
          const { data } = await this.postLaudo();
          this.laudoId = data;

          await this.alterarStatus();

          if (!Number.isInteger(data)) throw new Error(data);

          this.getLaudoByExamePorConsultaId(exameId);
        } else {
          this.$store.commit('offline/SET_REQUISICAO', {
            requisicaoAlterarStatus: this.alterarStatusOffline.bind({}),
            requisicaoSalvar: this.postLaudoOffline.bind({}),
            headers: { ...this.headers },
            urlBase: `${this.urlBase}`,
            codigo: 'POST_LAUDO',
          });
          this.$store.commit('offline/SET_DADOS', {
            laudo: this.laudoSelecionado,
            consulta: this.consultaSelecionada,
          });
        }
      } catch (error) {
        alert('Error ao salvar o laudo');
      } finally {
        this.carregamentoDoLaudo = false;
        this.podeEditar = false;
      }
    },

    async editarLaudo() {
      try {
        this.podeEditar = true;
        if (navigator.onLine) {
          this.carregamentoDoLaudo = true;
          await this.patchLaudo(this.laudoSelecionado);
        } else {
          this.$store.commit('offline/SET_REQUISICAO', {
            requisicao: this.patchLaudoOffline.bind({}),
            headers: { ...this.headers },
            urlBase: `${this.urlBase}`,
            codigo: 'PATCH_LAUDO',
          });
          this.$store.commit('offline/SET_DADOS', this.laudoSelecionado);
          // this.podeImprimir = false;
        }
      } catch (error) {
        alert('Error ao salvar o laudo');
      } finally {
        this.carregamentoDoLaudo = false;
        this.podeEditar = false;
      }
    },

    async imprimirLaudo() {
      this.laudoPrint = 'impresso';
      const exameId = this.$route.query.exame;
      await this.getLaudoByExamePorConsultaId(exameId);

      const novaAba = this.$router.resolve({
        name: 'imprecoes.transvaginalUteroParaPesquisaDeEndometriose',
        query: { exame: this.$route.query.exame },
      });
      window.open(novaAba.href, '_blank');
    },
    voltar() {
      this.voltando = true;
      this.$router.push(`/laudo/?consulta=${this.consultaSelecionada.id}`);
    },
    adicionaFolha() {
      if (!this.laudoSelecionado.laudoJson.folhas) {
        this.laudoSelecionado.laudoJson.folhas = ['  '];
      } else {
        this.laudoSelecionado.laudoJson.folhas.push(' ');
      }

      this.$forceUpdate();
    },
    removeFolha(value) {
      if (this.laudoSelecionado.laudoJson.folhas.length != 1) {
        this.laudoSelecionado.laudoJson.folhas.splice(value, 1);
      } else {
        alert('Você não pode remover todas as folhas!');
      }

      this.$forceUpdate();
    },
  },
  async mounted() {
    await this.getLaudoByExamePorConsultaId(this.$route.query.exame);

    this.podeEditar = !this.$store.getters['laudoSelecionado'].id;

    this.laudoId = this.$store.getters['laudoSelecionado'].id;
    if (
      this.$store.getters['laudoSelecionado'].laudoJson &&
      this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos
    ) {
      this.camposExibidos =
        this.$store.getters['laudoSelecionado'].laudoJson.camposExibidos;
    }
    if (!this.laudoId) {
      this.laudoSelecionado.laudoJson.folhas = ['  '];
      this.$forceUpdate();
    } else {
    }
    if (!!this.$route.query.editar) {
      if (this.$route.query.editar == true) {
        this.podeEditar = true;
      }
    }
  },
  beforeDestroy() {
    if (!this.voltando) {
      this.resetaLaudoSelecionado();
      // this.resetaConsultaSelecionada();
    }
    this.impressaoOpcoes = [];
  },
};
</script>

<style lang="scss">
.fonte {
  font-weight: normal;
}
</style>
