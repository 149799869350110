import PacienteEmbrion from '@/services/PacienteEmbrion';

const initialState = () => ({
  pacientes: [],
});

const SET_PACIENTES = 'SET_PACIENTES';

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    pacientes: state => state.pacientes,
  },
  mutations: {
    [SET_PACIENTES](state, payload) {
      state.pacientes = [...payload];
    },
  },
  actions: {
    async getPacientes({ commit, rootState }) {
      const { token } = rootState.Auth.usuarioLogado;
      const { data } = await PacienteEmbrion.getPacientes({ token });

      commit(SET_PACIENTES, data);
    },
    async postProximoPaciente(
      { commit, rootState },
      { pacienteName, salaNumber }
    ) {
      const { token } = rootState.Auth.usuarioLogado;
      await PacienteEmbrion.postProximoPaciente({
        token,
        data: {
          pacienteName,
          salaNumber,
        },
      });
    },
  },
};
