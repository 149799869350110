<template lang="">
  <div>
    <LaudoTransvaginalUteroParaPesquisaDeEndometriose></LaudoTransvaginalUteroParaPesquisaDeEndometriose>
  </div>
</template>
<script>
import LaudoTransvaginalUteroParaPesquisaDeEndometriose from '../components/laudos/formularios/exames/transvaginalUteroParaPesquisaDeEndometriose/LaudoTransvaginalUteroParaPesquisaDeEndometriose.vue';
export default {
  components: {
    LaudoTransvaginalUteroParaPesquisaDeEndometriose,
  },
};
</script>
<style lang=""></style>
